import React from "react"
import { NodeExecutionResult } from "src/types"
import { TokenWithdrawFromReaper, getWithdrawValueFromPoolReaper } from "../nestedLogHelpers"
import { useSelector } from "react-redux"
import usePromise from "src/hooks/usePromise"
import { tokenNameToAddress } from "src/components/Diagram/nodes/nodesLogsHelper"
import { getTokenDecimals } from "src/contracts/TokensDecimalsHelpers"

interface EndReaperLeftLogProps {
  previousData: any
  nodeEvents: NodeExecutionResult[]
  depositPriceUSD: string
  rfTokensDeposited: string
  tokenDepositedName: string
  depositAmount: number
}
export const EndReaperLeftLog = ({ previousData, nodeEvents, depositPriceUSD, tokenDepositedName, depositAmount }: EndReaperLeftLogProps) => {
  const DECIMAL_USD_ROUNDED: number = 3
  const withdrawal: TokenWithdrawFromReaper = getWithdrawValueFromPoolReaper(nodeEvents, previousData.data.reaper)

  const networkId: string = useSelector((s: any) => s.network.network)
  const decimals: number = Number(usePromise(getTokenDecimals, tokenNameToAddress(tokenDepositedName, networkId)))
  const earnedPriceUSD: number = parseFloat(withdrawal.totalValueUSD) - parseFloat(depositPriceUSD)
  const tokenDepositedAmount: number = parseFloat(withdrawal.amount) - depositAmount
  return (
    <>
      {/* FINAL VALUE */}
      <div className="logs-group-rows">
        <p className="main-text-logs">$ {(Number(withdrawal.totalValueUSD)).toFixed(DECIMAL_USD_ROUNDED)} </p>
        <p> {Number(withdrawal.amount).toFixed(decimals)} {tokenDepositedName}</p>
      </div>
      {/* EARNED VALUE */}
      <div className="logs-group-rows">
        <p className="main-text-logs">$ {(Number(earnedPriceUSD)).toFixed(DECIMAL_USD_ROUNDED)} </p>
        <p> {tokenDepositedAmount.toFixed(decimals)} {tokenDepositedName}</p>
      </div>
    </>
  )
}
