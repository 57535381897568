import React from 'react'
import { FormattedMessage } from 'react-intl'
import { useRecipePerformance } from "src/api/recipes"
import { RecipeDetails } from 'src/types'
import './APRSideViewDescription.sass'

interface ROISideViewDescriptionProps {
  recipeDetails: RecipeDetails
}
export const ROISideViewDescription = ({ recipeDetails }: ROISideViewDescriptionProps) => {
  const { performance } = useRecipePerformance(recipeDetails?.id)
  const roi: number = +(performance?.roi * 100).toFixed(2)
  return (
    <>
      {(recipeDetails?.status === "active" || recipeDetails?.status === "finished")
        && roi && <div className={`apr-side-descrption ${roi > 0 ? '' : 'negative'}`}>
          <span> <FormattedMessage id="mobile-card.strategy-info.pnl" values={{ n: roi }} /></span>
        </div>}
    </>
  )
}
