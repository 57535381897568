import React from "react";
import { FormattedMessage } from 'react-intl'
import { Link } from 'react-router-dom'

import "./ErrorRecipes.sass"

const ErrorRecipes = () => {
  return (
    <div id="error-recipes">
      <div className="wrapper-recipes">
        <div className="wrapper">
          <article>
            <h1>
              <FormattedMessage id="error-side-bar-text-1" />
            </h1>
            <p>
              <FormattedMessage id="error-side-bar-text-2" />
            </p>
            <div className="buttons">
              <Link className="button create-recipe" to="/recipe">
                <FormattedMessage id="create-recipe" />
              </Link>
            </div>
          </article>
        </div>
      </div>
    </div>
  )
}

export default ErrorRecipes
