const initialState = {
    focused: undefined
}

export default (state = initialState, action) => {
    switch (action.type) {
        case "focusNode":
            return { ...state, focused: action.payload }
        default:
            return state
    }
}