import React from "react"
import { useParams } from 'react-router-dom'
import { useRecipeDetails } from "src/api/recipes"
import ComboTriggerItem from "./ComboTriggerItem/ComboTriggerItem"
import { FormattedMessage } from 'react-intl'

import "./ConfigComboTrigger.sass"

export interface ConfigComboTriggerProps {
  combosInfo: Map<string, any>
  setCombosInfo: (s) => void
}

const ConfigComboTrigger = (props: ConfigComboTriggerProps) => {
  const { id } = useParams()
  const recipeDetails = useRecipeDetails(+id)
  const comboTriggerNodes = () => {
    if (!recipeDetails?.code) return []
    return recipeDetails?.code.filter(e => e.type === "comboTriggerNode")
  }
  return (
    <div className="config-combo-trigger">
      {comboTriggerNodes().length
        ? comboTriggerNodes()?.map((n, i) => <ComboTriggerItem key={n.id} index={i + 1} node={n} combos={props} />)
        : <FormattedMessage id="no-combo-triggers" />
      }
    </div>
  )
}

export default ConfigComboTrigger
