import React from 'react'
import { FormattedMessage } from 'react-intl'
import { useRecipePerformance } from 'src/api/recipes'
import { PerformanceInfo, Recipe, RecipePerformance } from 'src/types'

import './RecipeCardInfo.sass'

const RecipeCardInfo = ({ recipe }: { recipe: Recipe}) => {
  const id: number = recipe?.id
  const recipeIdIfNotApr: number = recipe?.performance?.apr ? null : id
  const resultPerformance: RecipePerformance = useRecipePerformance(recipeIdIfNotApr)
  const performance: PerformanceInfo = !resultPerformance ? recipe?.performance : resultPerformance?.performance
  const earned: string = performance?.earned?.toFixed(3)
  const roi: number = +((performance?.roi * 100).toFixed(2))

  return (
    <section className="recipe-card-info">
      <div className={`item item-apr ${roi > 0 ? 'success' : 'faliure'}`}>
        <FormattedMessage id="mobile-card.strategy-info.pnl" values={{ n: roi }} />
      </div>
      <div className="item item-earned">
        <em><FormattedMessage id="mobile-card.recipe-info.earned" values={{ n: earned }} /></em>
      </div>
    </section>
  )
}

export default RecipeCardInfo
