import React from "react"
import { SushiSwapFarmsData } from "src/api/farms/types"
import { useFarmsOneDay } from "src/api/recipes"
import { NodeExecutionResult } from "src/types"
import { useAuxLPEarned } from "src/hooks/useFarmsLogs"
import { saneBigNumber } from "src/utils/bigNumberHelper"
import web3 from "src/utils/web3"
import { useSelector } from "react-redux"
import { NoExecutedFarmModalLog } from "../NoExecutedFarmModalLog"
import { FormattedMessage } from "react-intl"
import { nodesLogsNumber } from "src/constants"
import ActiveFarmModalDataArbitrum from "./ActiveFarmModalDataArbitrum"
import FinishedFarmModalDataArbitrum from "./FinishedFarmModalDataArbitrum"
import "../FarmLogField.sass"
import { ModalDataErrorBoundary } from "src/components/ErrorBoundary/ErrorBoundary"

interface FarmModalLogDataArbitrumProps {
  myFarmAddress: string
  depositEvent: NodeExecutionResult
  withdrawEvent: NodeExecutionResult
  data: any
  executionSteps: number
  nodeId: string
}

const FarmModalLogDataArbitrum = ({ myFarmAddress, depositEvent, withdrawEvent, data, executionSteps, nodeId }: FarmModalLogDataArbitrumProps) => {
  const { NO_EXECUTED_NODE, ACTIVE_NODE } = nodesLogsNumber
  const DECIMAL_USD_ROUNDED: number = 3
  const networkId: string = useSelector((s: any) => s.network.network)
  const farmsInfo: SushiSwapFarmsData[] = useFarmsOneDay()
  const myFarm: SushiSwapFarmsData = farmsInfo.find(f => f.id === myFarmAddress)
  const { reserveUSD: liquidity, totalApr: apr } = myFarm

  const shares: string = depositEvent?.extraData?.ttAmount
  const slpDepositedWei: number = Number(depositEvent?.extraData?.outputTokenWithPrice?.amount)
  const slpDepositUSDValue: number = Number(depositEvent?.extraData?.outputTokenWithPrice?.totalValueUSD)
  const slpDepositedEther: number = parseFloat(web3.utils.fromWei(saneBigNumber(slpDepositedWei).toString(), 'ether'))
  const slpEarnedEther = useAuxLPEarned(myFarm?.token0?.symbol, myFarm?.token1?.symbol, shares, slpDepositedWei.toString(), executionSteps, networkId, data?.contracts?.strategy)
  const totalSLPAmount: number = slpDepositedEther + Number(slpEarnedEther)

  return (
    <>
      {executionSteps === NO_EXECUTED_NODE &&
        <section className="farm-modal-currency">
          <NoExecutedFarmModalLog />
        </section>}

      {executionSteps !== NO_EXECUTED_NODE &&
        <section className="farm-modal-currency">
          <div id="farm-log-field">
            <h3><FormattedMessage id="farm-modal-log.deposited" /></h3>
            <strong className="span-coin">$ {slpDepositUSDValue.toFixed(DECIMAL_USD_ROUNDED)}</strong>
            <div><span className="lp-amount-row">{slpDepositedEther.toFixed(10)} <strong>SLPs</strong></span></div>
          </div>
          {executionSteps === ACTIVE_NODE
            ? <ModalDataErrorBoundary recipeStatus="active" >
              <ActiveFarmModalDataArbitrum totalSLPAmount={totalSLPAmount} myFarm={myFarm} depositSLPAmount={slpDepositedEther} nodeId={nodeId} depositedSLPValueUSD={slpDepositUSDValue} />
            </ModalDataErrorBoundary>
            : <ModalDataErrorBoundary recipeStatus="finished" >
              <FinishedFarmModalDataArbitrum withdrawEvent={withdrawEvent} depositedSLPEther={slpDepositedEther} totalSLPAmount={totalSLPAmount} depositedSLPValueUSD={slpDepositUSDValue} />
            </ModalDataErrorBoundary>
          }
          <div id="farm-log-field">
            <h3><FormattedMessage id={"farm-modal-log.apr"} /></h3>
            <strong className="span-coin">{`${apr.toFixed(2)} %`}</strong>
          </div>
          <div id="farm-log-field">
            <h3><FormattedMessage id={"farm-modal-log.liquidity"} /></h3>
            <strong className="span-coin">{`$ ${parseFloat(liquidity).toLocaleString()}`}</strong>
          </div>
        </section>}
    </>

  )
}
export default FarmModalLogDataArbitrum
