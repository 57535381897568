import React from "react"
import './LeverageBar.sass'
import { useSelector } from "react-redux"
import { getItemByNetwork } from "src/utils/networkHelper"
interface LeverageBarProps {
  leverage: string
  setLeverage: (value: string) => void
}
const LeverageBar = ({ leverage, setLeverage }: LeverageBarProps) => {
  const networkId: string = useSelector((s: any) => s.network.network)
  const FANTOM_VALUES = {
    min: "1.1",
    max: "100",
    medium: "50",
    interval: ["1.1x", "20x", "40x", "60x", "80x", "100x"]
  }

  const ARBITRUM_VALUES = {
    min: "1.1",
    max: "50",
    medium: "25",
    interval: ["1.1x", "10x", "20x", "30x", "40x", "50x"]
  }

  const values = getItemByNetwork(networkId, FANTOM_VALUES, ARBITRUM_VALUES, FANTOM_VALUES)

  const sliderLeverageChange = (e) => setLeverage(e.target.value)
  return (
    <>
      <div className="leverage-slider-container">
        <input type="range" value={leverage ?? values.medium} min={values.min} max={values.max} step="0.1" onChange={sliderLeverageChange} />
        <div className="leverage-bar-borders">
          <div className="item-left-middle"></div>
          <div className="item-left-middle"></div>
          <div className="item-left-middle"></div>
          <div className="item-left-middle"></div>
          <div className="item-left-middle"></div>
          <div className="item-right"></div>
        </div>
      </div>
      <div className="numbers-container">
        {values.interval.map(v => <div key={v} className="numbers-label">{v}</div>)}
      </div>
    </>
  )
}

export default LeverageBar
