import React from 'react'
import MarkdownMessage from "@gluedigital/markdown-message"

import tortleChar from 'src/static/images/only-desktop/only-desktop-char.png'
import './OnlyDesktopVeil.sass'

const OnlyDesktopVeil = () => {
  return (
    <div id="only-desktop-veil">
      <div className="bg-only-desktop">
        <h1><MarkdownMessage id="only-desktop-veil" /></h1>
        <img src={tortleChar} alt="hanzo illustration" className="tortle-char" />
      </div>
    </div>
  )
}

export default OnlyDesktopVeil
