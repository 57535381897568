import { FormattedMessage } from "react-intl"
import React from "react"
import { CloseModal } from '@gluedigital/modal'

export interface HeaderDepositOnLpModalProps {
  chosenOption: any
  saveHandler: () => void
}
const HeaderDepositOnLpModal = ({ chosenOption, saveHandler }: HeaderDepositOnLpModalProps) => {
  return (
    <header className="header-buttons">
      <CloseModal>
        <button className="modal-close-button">
          <span className="icon icon-close" />
          <span><FormattedMessage id="close" /></span>
        </button>
      </CloseModal>
      <h1><FormattedMessage id="deposit-lp-modal.title" /></h1>
      <CloseModal>
        <button
          disabled={!chosenOption}
          onClick={saveHandler}
          className="filled"
        >
          <FormattedMessage id="save" />
        </button>
      </CloseModal>
    </header>)
}

export default HeaderDepositOnLpModal
