
import { RecipeExecutionLog } from "src/types"
import { useNodesRecipe } from "src/routes/RecipeDiagram/helpers/nodesAndEdgesContext"
import { getNetworkParams } from "src/components/modals/SelectWalletModal/helpers"
import { Networks } from "src/utils/networkHelper"
import { TokensList } from "src/hooks/useOrderTokensInModals"

export const tokenNameToAddress = (tokenName: string, networkId: string): string => {
  const networkParams = getNetworkParams(networkId)
  const tokens: TokensList[] = networkParams.tokens
  const targetToken = tokens.find((token) => token.id === tokenName)
  if (targetToken === undefined) {
    console.log(`There is no address data for token ${tokenName}. Returning mock address.`)
    return '0x'
  }
  return targetToken.address
}

export const tokenAddressToName = (tokenAddress: string, networkId: string): string => {
  const networkParams = getNetworkParams(networkId)
  const tokens: TokensList[] = networkParams.tokens
  const yearnTokens = networkParams?.yearnTokens
  const targetToken = tokens?.find(t => t.address.toLowerCase() === tokenAddress.toLowerCase()) || yearnTokens?.find(yt => yt.address.toLowerCase() === tokenAddress.toLowerCase())
  if (targetToken) return targetToken.id
  else if (targetToken === undefined) {
    console.log(`There is no name data for token ${tokenAddress}. Searching vault list.`)
    const farmPair = networkParams.contracts?.vaults?.find((vault) => vault.address.toLowerCase() === tokenAddress.toLowerCase())
    if (farmPair) return farmPair?.token0 + '-' + farmPair?.token1
    else if (!farmPair && networkId === Networks.fantom) {
      const farmPairV3 = networkParams.contracts?.vaultsV3?.find((vault) => vault.address.toLowerCase() === tokenAddress.toLowerCase())
      if (!farmPairV3) {
        console.log(`There is no name data for vaultV3 ${tokenAddress}. Returning mock value.`)
        return 'Unknown'
      }
      return farmPairV3?.token0 + '-' + farmPairV3?.token1
    }
    return 'Unknown'
  }
}

// Permit know if a node has been executed or is waiting in the recipe execution
export const findNodeInActiveRecipe = (recipeLogs, id): boolean => {
  let nodeExecuted;
  let nodeFound: boolean = false;
  recipeLogs.forEach((logs) => {
    if (!nodeFound) {
      nodeExecuted = (logs?.events)?.find((event) => event.nodeID === id)
      if (nodeExecuted !== undefined) nodeFound = true;
    }
  })
  return nodeFound
}

export const getCurrentEventForModals = (recipeLogs, id) => {
  let currentEvent: any;
  let eventFound: boolean = false;
  recipeLogs.forEach((logs) => {
    const recipeEvents = logs?.events
    if (!eventFound) {
      currentEvent = recipeEvents?.find(event => event.nodeID === id);
      if (currentEvent !== undefined) eventFound = true;
    }
  })
  return currentEvent
}

export const getNumberOfLogsForDepositAndFarms = (recipeLogs, nodeId) => {
  let numberOfLogs: number = 0
  recipeLogs.forEach((logRow) => {
    const logsForNode = logRow.events.filter((event) => event.nodeID === nodeId)
    numberOfLogs += logsForNode.length
  })
  return numberOfLogs
}

export const getCurrentRowLog = (recipeLogs: RecipeExecutionLog[], id) => {
  let currentLog: any;
  let logFound: boolean = false;
  recipeLogs.forEach((logs) => {
    const recipeEvents = logs?.events
    if (!logFound) {
      const currentEvent = recipeEvents?.find(event => event.nodeID === id);
      if (currentEvent !== undefined) {
        logFound = true;
        currentLog = logs
      }
    }
  })
  return currentLog
}

export const generateShowingTime = (specificRowOfLogs): string => {
  const executionDate: string = specificRowOfLogs?.timestamp || specificRowOfLogs?.date
  if (executionDate !== undefined) {
    const day: string = executionDate.slice(8, 10)
    let month: string = executionDate.slice(5, 7)
    month = convertMonth(month)
    const time: string = executionDate.slice(11, 16)
    const showingDate: string = day + ' ' + month + ', ' + time
    return showingDate;
  }
  return ''
}

export const getEndingLogForFarmsDeposit = (nodeId: string, recipeLogs) => {
  let numberOfLogs: number = 0
  let endingLog: any
  recipeLogs.forEach((logRow) => {
    const logsForNode = logRow.events.filter((event) => event.nodeID === nodeId)
    numberOfLogs += logsForNode.length
    if (numberOfLogs === 4) {
      endingLog = logRow
    } else if (numberOfLogs === 5) endingLog = logRow
  })
  return endingLog
}

export const obtainUTCTimestampFromDate = (depositedDate): number => {
  const localDate = new Date(depositedDate)
  const UTCYear: number = localDate.getUTCFullYear()
  const UTCMonth: number = localDate.getUTCMonth()
  const UTCDay: number = localDate.getUTCDate()
  const UTCHour: number = localDate.getUTCHours()
  const UTCMinutes: number = localDate.getUTCMinutes()
  const UTCSecond: number = localDate.getUTCSeconds()
  const epochToRequest: any = Date.UTC(UTCYear, UTCMonth, UTCDay, UTCHour, UTCMinutes, UTCSecond)
  return (Math.floor((epochToRequest) / 1000))
}

export const getAmountPercentage = (amountInput: number, percentage: number): number => {
  const amount: number = amountInput * (percentage / 100)
  return Number(parseFloat(amount.toString()).toFixed(8))
}

export const useCompareAccounts = (userAddress: string): boolean => {
  const recipeDetails = useNodesRecipe()
  if (!userAddress) return false
  const address: string = userAddress.length === 42 ? userAddress.slice(2) : userAddress
  if (!recipeDetails) {
    return true
  }
  const owner: string = recipeDetails?.owner;
  return owner === address
}

export const convertMonth = (month: string): string => {
  switch (month) {
    case ('01'):
      return 'Jan';
    case ('02'):
      return 'Feb';
    case ('03'):
      return 'Mar';
    case ('04'):
      return 'Apr';
    case ('05'):
      return 'May';
    case ('06'):
      return 'Jun';
    case ('07'):
      return 'Jul';
    case ('08'):
      return 'Aug';
    case ('09'):
      return 'Sep';
    case ('10'):
      return 'Oct';
    case ('11'):
      return 'Nov';
    case ('12'):
      return 'Dec';
    default:
      return '';
  }
}
