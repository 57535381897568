
import React from "react"
import { FormattedMessage } from "react-intl"
import { getNestedStrategyImage, getTokenImage } from "src/api/farms/helpers"
import { ReaperSummaryVaultsData } from "src/api/reaperFinance/reaperTypes"
import { YearnSummaryVaultsData } from "src/api/yearnVaults/yearnTypes"
import { useSelector } from "react-redux"
import { Networks } from "src/utils/networkHelper"
import './ListNestedStrategiesModal.sass'

// import { NestedVaultsInfo } from "./nestedModalHelper"

interface ListNestedStrategiesModalProps {
  vault: YearnSummaryVaultsData | ReaperSummaryVaultsData
  isChosen: boolean
  setChosenOption: (option: YearnSummaryVaultsData | ReaperSummaryVaultsData) => void
  featured?: boolean
}

export const ListNestedStrategiesModal = ({ vault, isChosen, setChosenOption, featured }: ListNestedStrategiesModalProps) => {
  const isLongName: boolean = (vault.name).length > 15
  const networkId: string = useSelector((s: any) => (s.network.network))

  if (networkId === Networks.arbitrum) {
    return (
      <li className={`nested-strategies-info-card ${featured ? 'featured' : ''}`}>
        {featured && <span className="featured-nested-strategy-tag"><FormattedMessage id="farm-modal.featured" /></span>}
        <section className="token-container">
          {vault.provider === "Reaper" &&
            <div className="logos">
             <img className={"logo"} key={vault.token[0].address} src={getTokenImage(vault.token[0].name)} />
            </div>}
          <h1 className={`pair-names-container ${isLongName ? 'long' : ''}`}>
            {vault.name}
          </h1>
        </section>
        <section className="section-data">
          <h2 className="data-title">
            <FormattedMessage id="nested-strategies-modal.APY" />
          </h2>
          <strong>{(vault.apy).toFixed(2)} %</strong>
        </section>
        <section className="section-data">
          <h2 className="data-title">
            <span><FormattedMessage id="nested-strategies-modal.Total-assets" /></span>
          </h2>
          <strong >$ {vault.tvl.toLocaleString()}</strong>
        </section>
        <section className="section-data">
          <h2 className="data-title">
            <span><FormattedMessage id="deposit-lp-modal.provider" /></span>
          </h2>
          <strong>{vault.provider.toUpperCase()}</strong>
        </section>
        <section className="button-section">
          <button
            className={isChosen ? 'filled' : ''}
            onClick={() => { setChosenOption(vault) }}>
            {!isChosen ? <FormattedMessage id="choose" /> : <FormattedMessage id="chosen" />}
          </button>
        </section>
      </li>
    )
  }

  return (
    <li className={`nested-strategies-info-card ${featured ? 'featured' : ''}`}>
      {featured && <span className="featured-nested-strategy-tag"><FormattedMessage id="farm-modal.featured" /></span>}
      <section className="token-container">
        {vault.provider === "Yearn" &&
          <div className="logos">
            <img className="logo" src={getNestedStrategyImage(vault.symbol)} />
          </div>}
        {vault.provider === "Reaper" &&
          <div className="logos">
            {vault.token.map(token => <img className={vault.token.length === 1 ? "logo" : `several-logos-${vault.token.length}`} key={token.name} src={getTokenImage(token.name)} />)}
          </div>}
        <h1 className={`pair-names-container ${isLongName ? 'long' : ''}`}>
          {vault.name}
        </h1>
      </section>
      <section className="section-data">
        <h2 className="data-title">
          <FormattedMessage id="nested-strategies-modal.APY" />
        </h2>
        <strong>{(vault.apy).toFixed(2)} %</strong>
      </section>
      <section className="section-data">
        <h2 className="data-title">
          <span><FormattedMessage id="nested-strategies-modal.Total-assets" /></span>
        </h2>
        <strong >$ {vault.tvl.toLocaleString()}</strong>
      </section>
      <section className="section-data">
        <h2 className="data-title">
          <span><FormattedMessage id="deposit-lp-modal.provider" /></span>
        </h2>
        <strong>{vault.provider.toUpperCase()}</strong>
      </section>
      <section className="button-section">
        <button
          className={isChosen ? 'filled' : ''}
          onClick={() => { setChosenOption(vault) }}>
          {!isChosen ? <FormattedMessage id="choose" /> : <FormattedMessage id="chosen" />}
        </button>
      </section>
    </li>

  )
}
