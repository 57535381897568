export interface MobileBrowseProps extends MobileBrowseInputProps, MobileBrowseFilterProps, MobileBrowseSortProps {}
export interface MobileBrowseInputProps {
  searchTerm: string
  handleSearch: (e: React.ChangeEvent<HTMLInputElement>) => void
}
export interface MobileBrowseFilterProps {
  showOnlyVerified: boolean
  handleShowOnlyVerified: (v: boolean) => void
}

export interface MobileBrowseSortProps {
  sortBy: SortByEnum
  handleSortBy: (s: SortByEnum) => void
}

export enum SortByEnum {
  // TODO enable this when API is ready
  // hVAPY = 'h-vAPY',
  // lVAPY = 'l-vAPY',
  recent = 'recent',
  older = 'older'
}
