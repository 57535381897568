import React, { useCallback, useEffect, useMemo, useState } from "react"
import { ApprovalStatus, TokenForApproval } from "src/routes/RecipeDiagram/helpers/types"
import { useSelector } from "react-redux"
import { newAllowance, amountIsAlreadyApproved } from "../../contracts/ApproveHelpers"
import { FormattedMessage } from "react-intl"
import { getNetworkParams } from "../modals/SelectWalletModal/helpers"
import { DOCS_TROUBLESHOOTING } from "src/constants"
import './ApprovesDepositList.sass'

interface ApprovesListProps {
  tokens: TokenForApproval[]
  setTokenStatus?: (address: string, status: ApprovalStatus) => void
  hasInsufficientBalance: boolean
  tokensState: Map<String, ApprovalStatus>
  setTokensState: any
  isAllApproved?: boolean
}

const ApprovesList = ({ tokens, tokensState, setTokensState, isAllApproved }: ApprovesListProps) => {
  const user = useSelector((s: any) => s.user)
  const networkId: string = useSelector((s: any) => (s.network.network))
  const networkParams = getNetworkParams(networkId)
  const userAddress = user.account.account
  const [showRejectedInfo, setShowRejectedInfo] = useState<boolean>(false)

  // To disabled the button when some token has pending status
  const detectPending = () => {
    let thereIsPending: boolean = false
    tokensState.forEach((value: ApprovalStatus) => {
      if (value === "pending") thereIsPending = true
    })
    return thereIsPending
  }

  // To know is there is any token rejected.
  useEffect(() => {
    tokens.forEach((token: TokenForApproval) => {
      if (tokensState.has(token.address)) {
        const status: ApprovalStatus = tokensState.get(token.address)
        if (status === "rejected") setShowRejectedInfo(true)
      }
    })
  }, [tokens, tokensState])

  const pendingTokens: TokenForApproval[] = useMemo(() => {
    const pendingTokens: TokenForApproval[] = []
    tokens.forEach((token: TokenForApproval) => {
      if (tokensState?.get(token.address) !== "approved") pendingTokens.push(token)
    })
    return pendingTokens
  }, [tokens, tokensState])

  useEffect(() => {
    amountIsAlreadyApproved(userAddress, pendingTokens, null, networkParams, setTokensState)
      .catch(err => console.error(err.message))
  }, [userAddress, setTokensState, pendingTokens, networkParams])

  // Used when approve button is clicked
  const handleAllowance = useCallback(() => {
    newAllowance(userAddress, tokens, tokensState, setTokensState, networkParams)
      .catch(err => console.error(err.message))
  }, [setTokensState, tokens, tokensState, userAddress, networkParams])

  const chooseGapSize = (token: string): string => {
    if (token.length <= 3) return ''
    else if (token.length === 4) return 'short'
    else if (token.length === 5) return 'mid'
    else if (token.length > 5) return 'long'
  }

  return <div className="approval-list">
    <h3 className="approval-header"> <FormattedMessage id="recipe-summary.approves.header" /></h3>
    <div className="tokens-list">
      {
        tokens.map((token, index) => (
          <div className={`token-item ${chooseGapSize(token.id)}`} key={`${token.id}-${index}`}>
            <span className={`status-icon ${tokensState.get(token.address)}`} />
            <span className="token-tag">{token.id}</span>
            {tokensState.get(token?.address) === "rejected" && <button className="icon-pregunta" onClick={() => window.open("https://docs.tortle.ninja/troubleshooting")}> </button>}
          </div>))
      }
    </div>
    {showRejectedInfo &&
      <div className="gas-info-div">
        <span className="gas-info"><FormattedMessage id="recipe-summary.gas-info" />
          <a
            href={DOCS_TROUBLESHOOTING}
            target="_blank"
            rel="noopener noreferrer"
            className="help-link"
          >here.</a> </span>
      </div>}
    <button
      disabled={ detectPending() || isAllApproved }
      onClick={handleAllowance}
    >
      <FormattedMessage id="recipe-summary.approves.button" />
    </button>
  </div>
}

export default ApprovesList
