interface PoolsToken {
    id: string
    symbol: string
    name: string
    derivedETH: string
}

export interface Pools {
    id: string
    pairAddress: string
    date: number
    dailyVolumeUSD: string
    reserve0: string
    reserve1: string
    reserveUSD: string
    token0: PoolsToken
    token1: PoolsToken
    totalSupply: string
}

export const adaptPoolInfo = (poolsInfo: any[]): Pools[] => {
    const newPools = poolsInfo.map(pool => {
        const newPool = {
            id: pool.id,
            pairAddress: pool.pair.id,
            date: pool.date,
            dailyVolumeUSD: pool.volumeUSD,
            reserve0: pool.reserve0,
            reserve1: pool.reserve1,
            reserveUSD: pool.reserveUSD,
            token0: { ...pool.token0 },
            token1: { ...pool.token1 },
            totalSupply: pool.totalSupply
        }
        return newPool
    })

    return newPools
}
