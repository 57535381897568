import React, { Suspense, useState } from "react"
import { FormattedMessage } from "react-intl"
import { useSelector } from "react-redux"
import ErrorBoundary from "../ErrorBoundary/ErrorBoundary"
import Loading from "../common/Loading/Loading"
import { getNetworkParams } from "../modals/SelectWalletModal/helpers"
import TokenSearch from "./TokenSearch/TokenSearch"
import TokenSelectorList from "./TokenSelectorList/TokenSelectorList"
import { TokensList } from "src/hooks/useOrderTokensInModals"
import "./TokenSelector.sass"

const MINIMUN_VALUE: number = 0

interface TokenSelectorProps {
  previousData: {outputCoin: string, amount: number}
  onChange: Function
  searchMode?: boolean
  setSearchMode?: Function | null
  blocked?: boolean
}

const TokenSelector = ({ previousData, onChange, searchMode = false, setSearchMode, blocked = true }: TokenSelectorProps) => {
  const networkId: string = useSelector((s: any) => (s.network.network))
  const networkParams = getNetworkParams(networkId)
  const coins: TokensList[] = networkParams.tokens
  const [search, setSearch] = useState<string>('')
  const { amount, outputCoin } = previousData
  const setCoin = (coin) => {
    onChange({ ...previousData, outputCoin: coin })
  }

  return (
    <div className={`token-selector ${blocked ? 'blocked' : ''}`}>
      <section className="token-selector-info" onClick={() => setSearchMode?.(false)}>
        <label className="input-container">
          <h2>
            <FormattedMessage id="token-selector.deposit" />
          </h2>
          <input
            readOnly={blocked}
            className={`amount-input ${('' + amount).length < 10 ? 'big' : ''}`}
            disabled={amount < MINIMUN_VALUE}
            type="number"
            inputMode="decimal"
            onFocus={() => setSearchMode?.(false)}
            onChange={(e) => {
              e.preventDefault();
              onChange({ ...previousData, amount: Number(parseFloat(e.target.value).toFixed(6)) })
            }}
            placeholder="0"
            value={amount}
            min={MINIMUN_VALUE}
            step="any"
          />
        </label>
        <h1
          className={outputCoin?.length > 4 ? (outputCoin?.length > 6 ? 'micro' : 'mini') : ''}
          onClick={(e) => {
            e.stopPropagation()
            if (!blocked) {
              setSearchMode?.(!searchMode)
            }
          }}
        >
          <span>{outputCoin || coins[0].id}</span>
          <span className={`icon icon-angle_${searchMode ? 'right' : 'down'}`} />
        </h1>
      </section>
      <section className={`token-selector-select ${searchMode ? 'open' : ''}`}>
        <TokenSearch search={search} setSearch={setSearch} />
        <section className="token-selector-list-wrapper">
          <ul className="token-selector-list" onClick={() => setSearchMode?.(false)}>
            <ErrorBoundary fallback={<></>}>
              <Suspense fallback={<Loading />}>
                <TokenSelectorList search={search} outputCoin={outputCoin} setCoin={setCoin} />
              </Suspense>
            </ErrorBoundary>
          </ul>
        </section>
      </section>
    </div>
  )
}

export default TokenSelector
