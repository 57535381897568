import React, { Suspense } from "react"
import { ShortLongNodeData } from "src/routes/RecipeDiagram/helpers/types"
import { ViewRecipeLogsProps } from "../ViewRecipeLogs/ViewRecipeLogs"
import { FormattedMessage } from "react-intl"
import { NodeExecutionResult } from "src/types"
import { DepositShortLongDataInfo, WithdrawShortLongDataInfo } from "src/api/mummyFinance/mummyTypes"
import LiquidationPriceLeftLog from "./LiquidationPriceLeftLog"
import ErrorBoundary from "src/components/ErrorBoundary/ErrorBoundary"
import CurrentPriceLeftLog from "./CurrentPriceLeftLog"
import NetValueLeftLog from "./NetValueLeftLog"
import 'src/routes/RecipeDiagram/SideBarViewLogs/SidebarViewLogs.sass'

interface ShortLongExtraDataLogProps extends ViewRecipeLogsProps {
  data: ShortLongNodeData
  events: NodeExecutionResult[]
}
const ShortLongExtraDataLog = ({ data, events }: ShortLongExtraDataLogProps) => {
  const depositEvent: NodeExecutionResult = events.find((ev) => ev.functionName === "depositOnShortLong")
  const depositShortLongData: DepositShortLongDataInfo = depositEvent?.extraData?.depositShortLongData
  const withdrawEvent: NodeExecutionResult = events.find((ev) => ev?.functionName === "withdrawFromShortLong")
  const withdrawShortLongData: WithdrawShortLongDataInfo = withdrawEvent?.extraData?.withdrawShortLongData
  const collateralUSD: string = depositShortLongData?.collateralUSD

  const isLong: boolean = data.operation === "long"
  let pnl, pnlPercentage: number
  if (withdrawShortLongData) {
    pnl = withdrawShortLongData?.netValueAtWithdraw - depositShortLongData?.netValueAtDeposit
    pnlPercentage = pnl * 100 / depositShortLongData?.netValueAtDeposit
  }

  return (
    <div className="div-extra-info">
      <div className="first-column">
        <div className="logs-group-rows">
          <p><FormattedMessage id="logs-short-long.net-value" />  </p>
          <div className="left-empty-position"></div>
        </div>
        <div className="logs-group-rows">
          <p><FormattedMessage id="logs-short-long.size" />  </p>
          <p><FormattedMessage id="logs-short-long.colateral" /> </p>
        </div>
        <p><FormattedMessage id="logs-short-long.mark-price" /> </p>
        <p><FormattedMessage id="logs-short-long.entry-price" /> </p>
        <p><FormattedMessage id="logs-short-long.liq-price" /></p>
      </div>
      <div className="second-column">
        <Suspense fallback={<p className="main-text-logs"> <FormattedMessage id="waiting-for-data" /></p>} >
          <ErrorBoundary fallback={<p className="main-text-logs"> <FormattedMessage id="error-getting-data" /></p>} >
            {withdrawShortLongData ?
              <>
                <p className="main-text-logs"> {withdrawShortLongData?.netValueAtWithdraw?.toFixed(3)} $</p>
                <p> {pnl?.toFixed(3)} $  ({pnlPercentage?.toFixed(2)} %)</p>
              </>
              : <NetValueLeftLog data={data} depositShortLongData={depositShortLongData} />}
          </ErrorBoundary>
        </Suspense>
        <div className="logs-group-rows">
          <p className="main-text-logs">  {depositShortLongData?.positionSize.toFixed(3)} $</p>
          <p className="main-text-logs"> {collateralUSD} $</p>
        </div>
        <Suspense fallback={<p className="main-text-logs"> <FormattedMessage id="waiting-for-data" /></p>} >
          <ErrorBoundary fallback={<p className="main-text-logs"> <FormattedMessage id="error-getting-data" /></p>} >
            {withdrawShortLongData ? <p className="main-text-logs"> {withdrawShortLongData?.exitOpCoinPriceUSD} $</p> : <CurrentPriceLeftLog data={data} />}
          </ErrorBoundary>
        </Suspense>
        <p className="main-text-logs"> {parseFloat(depositShortLongData?.depositOpCoinPriceUSD).toFixed(4)} $</p>
        <Suspense fallback={<p className="main-text-logs"> <FormattedMessage id="waiting-for-data" /></p>} >
          <ErrorBoundary fallback={<p className="main-text-logs"> <FormattedMessage id="error-getting-data" /></p>} >
            {withdrawShortLongData ? <p className="main-text-logs"> {withdrawShortLongData?.liquidationPrice} $</p> : <LiquidationPriceLeftLog isLong={isLong} depositShortLongData={depositShortLongData} data={data} />}
          </ErrorBoundary>
        </Suspense>
      </div>
    </div>
  )
}

export default ShortLongExtraDataLog
