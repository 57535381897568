import React from 'react'
import MarkdownMessage from '@gluedigital/markdown-message'
import { usePeriodicLiquidatedRecipes } from 'src/api/recipes'
import NoRecipesMobile from '../NoRecipesMobile/NoRecipesMobile'
import ButtonsCardMobile from '../ButtonCardMobile/ButtonsCardMobile'
import RecipeCardMobile from '../RecipeCardMobile/RecipeCardMobile'

const LiquidatedRecipes = () => {
  const recipes = usePeriodicLiquidatedRecipes()
  if (recipes.length === 0) return <NoRecipesMobile />
  return (
    <section className="mobile-section-recipes" id="liquidated-recipes">
      <h1>
        <span><MarkdownMessage id="mobile-section-recipes.liquidated-title" /></span>
        <span className="mobile-icon mobile-icon-wallet" />
      </h1>
      <div className="mobile-recipe-grid">
        {recipes.map(r =>
          <RecipeCardMobile key={r.id} recipe={r} withEarned>
            <ButtonsCardMobile recipe={r} mode="again" />
          </RecipeCardMobile>
        )}
      </div>
    </section>
  )
}

export default LiquidatedRecipes
