
import { NodeType } from "src/routes/RecipeDiagram/helpers/types"
import { RecipeDetails } from "src/types"

export interface CodeNodeRecipe {
  data?: any
  id: string
  position: any
  type: string
  source?: string
  target?: string
  sourceHandle?: string
  targetHandle?: string
}

export const getRootNodes = (code: CodeNodeRecipe[]): CodeNodeRecipe[] => {
  const edges: CodeNodeRecipe[] = code.filter((element) => element.type === "myCustomEdge")
  const nodes: CodeNodeRecipe[] = code.filter((element) => element.type !== "myCustomEdge")
  const targetIds: string[] = edges.map((edge) => edge.target)
  const rootNodes: CodeNodeRecipe[] = nodes.filter((nodeId) => !targetIds.includes(nodeId.id))
  return rootNodes
}

export const getNodesOrderedMap = (recipeDetails: RecipeDetails): WeakMap<CodeNodeRecipe, CodeNodeRecipe[]> => {
  if (!recipeDetails) return null
  const code: CodeNodeRecipe[] = recipeDetails?.code
  const nodes: CodeNodeRecipe[] = code.filter((element) => element.type !== "myCustomEdge")
  const nodesMap = new WeakMap<CodeNodeRecipe, CodeNodeRecipe[]>()
  nodes.forEach((node) => {
    if (node?.type !== "sendToWalletNode" && node?.type !== "liquidateNode") {
      if (!nodesMap.get(node)) {
        const nextNodeId: CodeNodeRecipe[] = getNextNode(code, node.id)
        nodesMap.set(node, [])
        if (nextNodeId.length === 1) nodesMap.get(node).push(nextNodeId[0])
        else if (nextNodeId.length === 2) {
          nodesMap.get(node).push(nextNodeId[0])
          nodesMap.get(node).push(nextNodeId[1])
        }
      }
    }
  })
  return nodesMap
}

const getNextNode = (code: CodeNodeRecipe[], currentNodeId: string): CodeNodeRecipe[] => {
  const nextEdge: CodeNodeRecipe[] = code.filter((element) => element.type === "myCustomEdge" && element?.source === currentNodeId)
  if (!nextEdge) return null
  if (nextEdge.length === 1) {
    const nextNodeId: string = nextEdge[0]?.target
    const nextNode: CodeNodeRecipe[] = code.filter((node) => node.id === nextNodeId)
    return nextNode
  } else { // the currentNode is Split
    const output1: string = nextEdge.find((edge) => edge.sourceHandle === "split-output-1").target
    const output2: string = nextEdge.find((edge) => edge.sourceHandle === "split-output-2").target
    const nextNodes: CodeNodeRecipe[] = code.filter((node) => node.id === output1 || node.id === output2)
    return nextNodes
  }
}

export const inverseNodesOrder = (recipeDetails: RecipeDetails) => {
  if (!recipeDetails) return null
  const code = recipeDetails?.code
  const nodes = code.filter((node) => node.type !== "myCustomEdge")
  const edges = code.filter((node) => node.type === "myCustomEdge")
  const endNodes = nodes.filter((node) => node.type === "sendToWalletNode" || node.type === "liquidateNode")
  const result: any[] = []
  endNodes.forEach((ending) => {
    const temp: any[] = []
    temp.push(ending)
    while (temp[temp.length - 1].type !== "addFundsNode") {
      const nextPrevious = getPreviousNode(nodes, edges, temp[temp.length - 1].id)
      if (nextPrevious) temp.push(nextPrevious)
    }
    result.push(temp)
  })
  return result
}

export const getPreviousNode = (nodes, edges, nodeId: string) => {
  const previousEdge = edges.find((edge) => edge?.target === nodeId)
  if (!previousEdge) return null
  const previousNode = nodes.find((node) => previousEdge?.source === node?.id)
  let percentageUsed: any
  if (previousNode?.type === "splitNode") {
    if (previousEdge.sourceHandle === "split-output-1") percentageUsed = previousNode.data.firstPercentage
    if (previousEdge.sourceHandle === "split-output-2") percentageUsed = previousNode.data.secondPercentage
    const newPreviousNode = { ...previousNode, percentageUsed }
    return newPreviousNode
  }

  return previousNode
}

// Permit know if a node has a previousNode (in all the branch) which is shortLongNode
export const isPreviousShortLongNode = (recipeDetails: RecipeDetails, nodeId: string): boolean => {
  const nodes = recipeDetails.code.filter(node => node.type !== "myCustomEdge")
  const edges = recipeDetails.code.filter(node => node.type === "myCustomEdge")
  let previousNodeType: NodeType
  let newNodeId: string = ""
  while (previousNodeType !== "addFundsNode" && previousNodeType !== "shortLongNode") {
    const previousNode = newNodeId === "" ? getPreviousNode(nodes, edges, nodeId) : getPreviousNode(nodes, edges, newNodeId)
    previousNodeType = previousNode?.type
    newNodeId = previousNode?.id
  }
  const previousShortLong: boolean = previousNodeType === "shortLongNode"
  return previousShortLong
}
