
export const FETCH_BEETS_POOLS = (): string => {
  // const category: string = isIncentivizedPools ? "categoryIn: INCENTIVIZED" : "categoryNotIn: INCENTIVIZED"
  const query: string = `
  {
    poolGetPools (where: {chainIn: FANTOM}, orderBy: apr, orderDirection: desc) {
      name
      address
      id
      type
      allTokens {
        symbol
        address
        isMainToken
        isNested
        weight
      }
      dynamicData {
        apr {
          apr {
            __typename ... on GqlPoolAprTotal {
              total
            } 
          }
        }
        totalLiquidity24hAgo
        volume24h
        swapFee
      }
    }
  }`
  return query
}

export const FETCH_ONLY_TOKENS_BEETS_POOLS = (): string => {
  const query: string = `
   {
     poolGetPools (where: {chainIn: FANTOM}, orderBy: apr, orderDirection: desc) {
       id
       allTokens {
         symbol
         address
         isMainToken
         isNested
         weight
       }
     }
   }`
  return query
}

export const FETCH_SINGLE_BEETS_POOL = (beetsId: string): string => {
  //  const category: string = isIncentivizedPools ? "categoryIn: INCENTIVIZED" : "categoryNotIn: INCENTIVIZED"
  // const idFilter: string = `idIn: ["${beetsId}"]`
  const query: string = `
  {
    poolGetPool (id: "${beetsId}") {
      name
      address
      id
      allTokens {
        symbol
        address
        isMainToken
        isNested
        weight
      }
      dynamicData {
        apr {
          apr {
            __typename ... on GqlPoolAprTotal {
              total
            } 
          }
        }
        totalShares
        volume24h
        totalLiquidity
      }
    }
  }`
  return query
}

export const FETCH_BEETS_FARMS = (): string => {
  const category: string = "categoryIn: INCENTIVIZED"
  const query: string = `
  {
    poolGetPools (where: {${category}}, orderBy: apr, orderDirection: desc) {
      name
      address
      id
      type
      allTokens {
        symbol
        address
        isMainToken
        isNested
        weight
      }
      dynamicData {
        apr {
          total
        }
        totalLiquidity
        volume24h
        swapFee
      }
      staking {
        address
        id
        type
        farm {
          beetsPerBlock
          id
          rewarders {
            address
            tokenAddress
            rewardPerSecond
            id
          }
        }
      }
    }
  }`
  return query
}

export const FETCH_TOKEN_DATA_BEETS = (tokenAddress: string): string => {
  const query: string = `
  {
    tokenGetTokenDynamicData (address: "${tokenAddress}") {
      ath
      atl
      fdv
      high24h
      id
      low24h
      marketCap
      price
      priceChange24h
      priceChangePercent7d
      priceChangePercent14d
      priceChangePercent24h
      priceChangePercent30d
      tokenAddress
      updatedAt
    }
  }`
  return query
}
