import { useEffect, useState } from "react"

const useUnload = (condition = true) => {
  const [showPrompt, setShowPrompt] = useState(condition)

  useEffect(() => {
    const onUnload = (e: BeforeUnloadEvent) => {
      e.preventDefault()
      if (showPrompt) {
        e.returnValue = ''
      }
    }
    window.addEventListener('beforeunload', onUnload)
    return () => window.removeEventListener('beforeunload', onUnload)
  }, [showPrompt])

  useEffect(() => {
    setShowPrompt(condition)
  }, [condition])
}
export default useUnload
