
import { FTM_PRICE_BY_BLOCK_NUMBER, TOKEN_PRICE_USD } from '../api/farms/thegraph/tokenQueries'
import usePromise from "./usePromise"
import web3 from "src/utils/web3"
import { TokenInfoSpooky } from "src/api/farms/types"
import { masterchefTheGraphUriFromUniswap } from "src/constants"
import fetchGraph from "src/api/farms/thegraph/fetchGraph"
import { useSelector } from 'react-redux'
import { getPairsAPI } from 'src/api/farms/helpers'

export const getFTMPriceUSDByBlock = async (blockNumber: number, graphLastBlock: number, networkId: string) => {
  const usefulBlockNumber: number = blockNumber > graphLastBlock ? graphLastBlock : blockNumber
  if (usefulBlockNumber !== 0 && usefulBlockNumber !== null && usefulBlockNumber !== undefined) {
    const graphUrl = masterchefTheGraphUriFromUniswap[networkId]
    const query = FTM_PRICE_BY_BLOCK_NUMBER(usefulBlockNumber)
    const tokenDataPromise = await fetchGraph(graphUrl, query)
    return tokenDataPromise
  }
  return undefined
}

export const useFTMPriceUSDByBlock = (blockNumber: number, graphLastBlock: number, networkId: string): number => {
  const price = usePromise(getFTMPriceUSDByBlock, blockNumber, graphLastBlock, networkId)
  return price?.data?.bundle?.ethPrice
}

export const useTokenDerivedFTMByBlock = (token: string, blockNumber: number, graphLastBlock: number): number => {
  const networkId: string = useSelector((s: any) => (s.network.network))
  const pairsAPI = getPairsAPI(networkId)
  const info = usePromise(pairsAPI.getTokenDerivedETHByBlock, token, blockNumber, graphLastBlock)
  return info?.data?.token?.derivedETH
}

export const useTokenPriceByBlock = (address: string, blockNumber: number, graphLastBlock: number, networkId: string): number => {
  const tokenDerived: number = useTokenDerivedFTMByBlock(address, blockNumber, graphLastBlock)
  const ftmPrice: number = useFTMPriceUSDByBlock(blockNumber, graphLastBlock, networkId)
  const tokenPrice: number = tokenDerived * ftmPrice
  return tokenPrice
}

export const useTokenUSDPriceByTimestamp = (address: string, timestamp: number) => {
  const networkId: string = useSelector((s: any) => (s.network.network))
  const pairsAPI = getPairsAPI(networkId)
  return usePromise(pairsAPI.getTokenUSDPriceByTimestamp, address, timestamp)
}

const getTokenPrice = async (networkId: string, pairAddress: string, blockNumber: number = undefined, timestamp: number = undefined) => {
  const graphUrl = masterchefTheGraphUriFromUniswap[networkId]
  let query: string

  if (pairAddress.length !== 42) pairAddress = pairAddress.slice(0, -6)

  if (pairAddress) {
    if (timestamp) {
      query = TOKEN_PRICE_USD(pairAddress, timestamp)
      const res = await fetchGraph(graphUrl, query)
      return res
    } else if (blockNumber) {
      const block = await web3.eth.getBlock(blockNumber)
      const blockTimestamp: number = block.timestamp as number
      query = TOKEN_PRICE_USD(pairAddress, blockTimestamp)
      const res = await fetchGraph(graphUrl, query)
      return res
    } else if (!blockNumber && !timestamp) return undefined
  } else return null
}

export const useGetTokenPriceUsd = (networkId: string, pairAddress: string, blockNumber: number = undefined, timestamp: number = undefined): TokenInfoSpooky => {
  const result = usePromise(getTokenPrice, networkId, pairAddress, blockNumber, timestamp)
  const tokenData: TokenInfoSpooky = result === null ? null : result?.data?.tokenDayDatas[0]
  return tokenData
}
