import React, { Suspense, useEffect } from 'react'
import { useRecipeEstimation } from "src/api/recipes"
import { EstimateGasResponse } from "src/components/RecipeSummary/EstimateGasLabel"
import { useParams } from 'react-router-dom'
import ErrorBoundary from 'src/components/ErrorBoundary/ErrorBoundary'
import MarkdownMessage from "@gluedigital/markdown-message"
import { FormattedMessage } from 'react-intl'
import Spinner from 'src/components/common/Loading/Spinner/Spinner'
import { FailSteps } from '../types'
import { Networks } from 'src/utils/networkHelper'
import { useSelector } from 'react-redux'

interface GasEstimationProps {
  step: number
  setFailStep: (step: number) => void
  setQuoted: (q: boolean) => void
}

const EstimationFailFallback = () => (
  <div className="deposit-step-item">
    <div className="row">
      <div className="circle failed" />
      <p><FormattedMessage id="estimate-gas.failed" /></p>
    </div>
  </div>)

const EstimationLoadingFallback = () => (
  <div className="deposit-step-item">
    <div className="row">
      <Spinner />
      <p><FormattedMessage id="deposit-step-item.state-quoting.running" /></p>
    </div>
  </div>)

const EstimationGasMessage = ({ step, setFailStep, setQuoted }: GasEstimationProps) => {
  const { id } = useParams()
  const networkId: string = useSelector((s: any) => s.network.network)
  let token: string
  if (networkId === Networks.fantom) token = "FTM"
  else if (networkId === Networks.arbitrum) token = "ETH"
  else if (networkId === Networks.hardhat) token = "FTM"
  const gasEstimation: EstimateGasResponse = useRecipeEstimation(step > 2 ? id : undefined)
  // There is a problem with GasEstimation. Because when an error happen in the estimation we can get the error with boundary, because the status that it returns is 200.
  // So we must catch the message, in this case is error. If we receive this value we have that the estimation fails.
  useEffect(() => {
    setQuoted(true)
  }, [setQuoted])

  let content =
    <div className="row">
      <div className="circle done" />
      <p>
        <MarkdownMessage id="estimate-gas.success"
          values={{
            estimationAmount: Number(gasEstimation?.estimationAmount).toFixed(4),
            token,
            estimationUSD: Number(gasEstimation?.estimationUSD).toFixed(3)
          }}
        />
      </p>
    </div>
  if (gasEstimation?.estimationAmount === "error") {
    content =
      <div className="row">
        <div className="circle failed" />
        <p><FormattedMessage id="estimate-gas.failed" /></p>
      </div>
      setFailStep(FailSteps.estimationFailed)
  }

  return (
    <div className="deposit-step-item">
      {content}
    </div>
  )
}

const GasEstimationWrapper = ({ step, setFailStep, setQuoted }: GasEstimationProps) => (
  <ErrorBoundary fallback={EstimationFailFallback()}>
    <Suspense fallback={EstimationLoadingFallback()}>
      <EstimationGasMessage setQuoted={setQuoted} setFailStep={setFailStep} step={step} />
    </Suspense>
  </ErrorBoundary>
)

export default GasEstimationWrapper
