import React, { Suspense } from 'react'
import Loading from 'src/components/common/Loading/Loading'
import { EarnedFinalV3FarmModalLog } from './MCv3FarmModalLog/EarnedFinalV3FarmModalLog'
import { EarnedCurrentFarmModalLog } from './MCv2FarmModalLog/EarnedCurrentFarmModalLog'
import { DepositedFarmModalLog } from './DepositedFarmModalLog'
import { EarnedFinalFarmModalLog } from './MCv2FarmModalLog/EarnedFinalFarmModalLog'
import { NoExecutedFarmModalLog } from '../NoExecutedFarmModalLog'
import { AprV3FarmModalLog } from './MCv3FarmModalLog/AprV3FarmModalLog'
import { FormattedMessage } from 'react-intl'
import { nodesLogsNumber } from 'src/constants'
import { useAllFarmsIdFromApi, useFarmsOneDay } from 'src/api/recipes'
import { FarmGraphData, PairDayData, PairInfoSpooky } from 'src/api/farms/types'
import { EarnedCurrentV3FarmModalLog } from './MCv3FarmModalLog/EarnedCurrentV3FarmModalLog'
import { useGetLPInfo, useLPUsdPrice } from 'src/hooks/useLPsLogs'
import { LPPair } from 'src/routes/RecipeDiagram/helpers/types'
import { useSelector } from 'react-redux'
import { NodeExecutionResult } from 'src/types'
import "../FarmLogField.sass"

interface FarmModalLogDataFantomProps {
  executionSteps: number
  blockNumberAtOtherMoment: number
  blockToUse: number
  previousData: any
  lpDepositUSDValue: number
  withdrawEvent: NodeExecutionResult
}

const FarmModalLogDataFantom = ({ executionSteps, blockNumberAtOtherMoment, blockToUse, previousData, lpDepositUSDValue, withdrawEvent }: FarmModalLogDataFantomProps) => {
  const { NO_EXECUTED_NODE, ACTIVE_NODE } = nodesLogsNumber
  const MASTERCHEF_VERSION: number = 2
  const pair: LPPair = previousData.pair
  const myFarmAddressWithTimestamp: string = pair.id
  const myFarmAddress: string = myFarmAddressWithTimestamp.slice(0, -6)
  const networkId: string = useSelector((s: any) => (s.network.network))

  const allFarms: FarmGraphData = useFarmsOneDay()
  const masterchefInfo: PairDayData = allFarms?.pools?.data.find((pair) => pair?.pairAddress === myFarmAddress)
  const allFarmLPs: string[] = useAllFarmsIdFromApi(MASTERCHEF_VERSION)
  const myFarm: PairInfoSpooky = useGetLPInfo(myFarmAddress, blockToUse, networkId)
  const splitDecimals = myFarm?.reserveUSD.split('.')
  const liquidity = `${splitDecimals[0]}.${splitDecimals[1].slice(0, 2)}`
  const lpUSDPriceAtOtherMoment: number = useLPUsdPrice(myFarmAddressWithTimestamp, blockNumberAtOtherMoment, networkId)

  return (
    <section className="farm-modal-currency">
      {executionSteps !== NO_EXECUTED_NODE ?
        (
          (masterchefInfo?.poolIdV3 || masterchefInfo?.poolIdV3 === 0 ?
            <>
              <DepositedFarmModalLog lpUSDDepositedValue={lpDepositUSDValue} executionSteps={executionSteps} previousData={previousData} />
              {executionSteps === ACTIVE_NODE
                ?
                <>
                  <Suspense fallback={<Loading />} >
                    <EarnedCurrentV3FarmModalLog lpDepositUSDValue={lpDepositUSDValue} executionSteps={executionSteps} previousData={previousData} masterchefInfo={masterchefInfo} withdrawEvent={withdrawEvent} />
                  </Suspense>
                </>
                :
                <>
                  <Suspense fallback={<Loading />} >
                    <EarnedFinalV3FarmModalLog lpDepositUSDValue={lpDepositUSDValue} executionSteps={executionSteps} previousData={previousData} masterchefInfo={masterchefInfo} withdrawEvent={withdrawEvent} />
                  </Suspense>
                </>
              }
            </>
            :
            <>
              <DepositedFarmModalLog lpUSDDepositedValue={lpDepositUSDValue} executionSteps={executionSteps} previousData={previousData} />
              {executionSteps === ACTIVE_NODE
                ?
                <>
                  <Suspense fallback={<Loading />} >
                    <EarnedCurrentFarmModalLog lpUSDPriceAtOtherMoment={lpUSDPriceAtOtherMoment} lpDepositUSDValue={lpDepositUSDValue} executionSteps={executionSteps} previousData={previousData} allFarmLPs={allFarmLPs} masterchefInfo={masterchefInfo} />
                  </Suspense>
                </>
                :
                <>
                  <Suspense fallback={<Loading />} >
                    <EarnedFinalFarmModalLog lpUSDPriceAtOtherMoment={lpUSDPriceAtOtherMoment} lpDepositUSDValue={lpDepositUSDValue} executionSteps={executionSteps} previousData={previousData} allFarmLPs={allFarmLPs} withdrawEvent={withdrawEvent} masterchefInfo={masterchefInfo} />
                  </Suspense>
                </>
              }
            </>
          )
        )
        :
        <NoExecutedFarmModalLog />
      }
      <Suspense fallback={<Loading />} >
        <AprV3FarmModalLog title="farm-modal-log.apr" myFarm={myFarm} masterchefInfo={masterchefInfo} blockNumberAtOtherMoment={blockNumberAtOtherMoment} />
      </Suspense>
      <div id="farm-log-field">
        <h3><FormattedMessage id={"farm-modal-log.liquidity"} /></h3>
        <strong className="span-coin">{`$ ${parseFloat(liquidity).toLocaleString()}`}</strong>
      </div>
    </section>
  )
}

export default FarmModalLogDataFantom
