import { useEffect, useRef, useState } from "react"
import { TokenCondition } from "src/routes/RecipeDiagram/helpers/types";
import { setInput } from "./utils";
import { TokenPrice, usePricesCombo } from "src/api/comboTrigger/comboApi";

export const useTriggerTokenInput = (triggerData) => {
  const condition = triggerData ? triggerData.condition as TokenCondition : {}
  const [inputRange, setInputRange] = useState([1, 1])
  const [inputConditionValue, setInputConditionValue] = useState(condition?.value || '')
  const inputCondition = useRef<HTMLInputElement>(null)

  const coinPrices: TokenPrice[] = usePricesCombo()
  const [currentPrice, setCurrentPrice] = useState<string>("")

  const getValue = () => setInput(inputCondition, inputRange, setInputRange, inputConditionValue, setInputConditionValue)

  useEffect(() => {
    inputCondition.current.setSelectionRange(inputRange[0], inputRange[1])
  }, [inputConditionValue, inputRange])

  useEffect(() => {
    const targetTokenPrice = coinPrices.find((tokenPrice) => tokenPrice.token === condition?.coinToCompare)?.price
    setCurrentPrice(targetTokenPrice)
  }, [coinPrices, condition?.coinToCompare])

  return {
    getValue,
    inputCondition,
    inputConditionValue,
    currentPrice
  }
}
