import React from "react"
import { useSelector } from "react-redux"
import { tokenNameToAddress } from "src/components/Diagram/nodes/nodesLogsHelper"
import { getPrice } from "src/contracts/GetPriceHelpers"
import { getEtherStringAmountFromAddressFixed15, getTokenDecimals } from "src/contracts/TokensDecimalsHelpers"
import usePromise from "src/hooks/usePromise"
import { useGetPricePerShare } from "src/hooks/useYearn"

interface ActiveYearnLeftLogProps {
  yVaultAddress: string
  vaultTokenName: string
  depositPriceUSD: string
  amountYTokensDepositedEth: string
  depositTokenAmountEth: number
}
export const ActiveYearnLeftLog = ({ yVaultAddress, vaultTokenName, depositPriceUSD, amountYTokensDepositedEth, depositTokenAmountEth }: ActiveYearnLeftLogProps) => {
  const networkId: string = useSelector((s: any) => (s.network.network))
  const DECIMAL_USD_ROUNDED: number = 4
  const vaultTokenDecimals: number = Number(usePromise(getTokenDecimals, tokenNameToAddress(vaultTokenName, networkId)))
  const pricePerShareWei: number = useGetPricePerShare(yVaultAddress)
  const pricePerShareEth: string = usePromise(getEtherStringAmountFromAddressFixed15, yVaultAddress, pricePerShareWei.toString())
  const priceCurrentUSD: string = usePromise(getPrice, vaultTokenName, "USDC_AXL", networkId)

  const amountCurrent: number = Number(amountYTokensDepositedEth) * Number(pricePerShareEth)
  const priceCurrentEarnedUSD: number = Number(amountCurrent) * Number(priceCurrentUSD)

  const earnedPriceUSD: number = priceCurrentEarnedUSD - parseFloat(depositPriceUSD)
  const earnedTokenAmount: number = amountCurrent - depositTokenAmountEth

  return (
    <>
    {/* CURRENT VALUE */}
      <div className="logs-group-rows">
        <p className="main-text-logs">$ {(Number(priceCurrentEarnedUSD)).toFixed(DECIMAL_USD_ROUNDED)} </p>
        <p> {(Number(amountCurrent.toFixed(vaultTokenDecimals === 18 ? 12 : vaultTokenDecimals)))} {vaultTokenName}</p>
      </div>

      {/* EARNED VALUE */}
      <div className="logs-group-rows">
        <p className="main-text-logs">$ {(Number(earnedPriceUSD)).toFixed(DECIMAL_USD_ROUNDED)} </p>
        <p> {(Number(earnedTokenAmount)).toFixed(vaultTokenDecimals === 18 ? 12 : vaultTokenDecimals)} {vaultTokenName}</p>
      </div>
    </>
  )
}
