import React from 'react'
import ErrorBoundary from "./ErrorBoundary";
import { FormattedMessage } from 'react-intl';
import { DOCS_TROUBLESHOOTING } from 'src/constants';
import 'src/routes/RecipeDiagram/SideBarViewLogs/SidebarViewLogs.sass'
import './FarmLeftLogErrorBoundary.sass'

interface FarmLeftLogErrorBoundaryProps {
  children: React.ReactNode
  type: string
}
export const FarmLeftLogErrorBoundary = ({ children, type }: FarmLeftLogErrorBoundaryProps) =>
  <ErrorBoundary fallback={<FarmLeftLogErrorFallback type={type} />}>{children}</ErrorBoundary>

interface FarmLeftLogErrorFallbackProps {
  type: string
}
const FarmLeftLogErrorFallback = ({ type }: FarmLeftLogErrorFallbackProps) => {
  if (type === "final-earned") {
    return (
      <>
        {/* FINAL VALUE */}
        <div className="error-left-container">
          <p className="error-text-left-log"><FormattedMessage id="error-retrieving-final" /></p>
          <p><a className="troubleshooting-text"
            href={DOCS_TROUBLESHOOTING}
            target="_blank"
            rel="noreferrer noopener"><FormattedMessage id="text-troubleshooting" /></a></p>
        </div>
        {/* EARNED */}
        <div className="error-left-container">
          <p className="error-text-left-log"><FormattedMessage id="error-retrieving-earned" /></p>
          <p><a className="troubleshooting-text"
            href={DOCS_TROUBLESHOOTING}
            target="_blank"
            rel="noreferrer noopener"><FormattedMessage id="text-troubleshooting" /></a></p>
        </div>
      </>
    )
  } else if (type === "current-earned") {
    return (
      <>
        {/* CURRENT VALUE */}
        <div className="error-left-container">
          <p className="error-text-left-log"><FormattedMessage id="error-retrieving-current" /></p>
          <p><a className="troubleshooting-text"
          href={DOCS_TROUBLESHOOTING}
            target="_blank"
            rel="noreferrer noopener"><FormattedMessage id="text-troubleshooting" /></a></p>
        </div>
        {/* EARNED */}
        <div className="error-left-container">
          <p className="error-text-left-log"><FormattedMessage id="error-retrieving-earned" /></p>
          <p><a className="troubleshooting-text"
           href={DOCS_TROUBLESHOOTING}
            target="_blank"
            rel="noreferrer noopener"><FormattedMessage id="text-troubleshooting" /></a></p>
        </div>
      </>
    )
  } else if (type === "apr") {
    return (
      <div className="error-left-container">
        <p className="error-text-left-log"> <FormattedMessage id="error-retrieving-apr" /></p>
        <p><a className="troubleshooting-text"
            href={DOCS_TROUBLESHOOTING}
            target="_blank"
            rel="noreferrer noopener"><FormattedMessage id="text-troubleshooting" /></a></p>
      </div>
    )
  } else if (type === "deposited") {
    return (
      <div className="error-left-container">
        <p className="error-text-left-log"> <FormattedMessage id="error-retrieving-deposited" /></p>
        <p><a className="troubleshooting-text"
           href={DOCS_TROUBLESHOOTING}
            target="_blank"
            rel="noreferrer noopener"><FormattedMessage id="text-troubleshooting" /></a></p>
      </div>
    )
  }
}
