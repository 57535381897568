import React from 'react'
import { FormattedMessage } from 'react-intl'
import { useSelector } from 'react-redux'
import { useParams, Link } from 'react-router-dom'
import { useRecipeDetails } from 'src/api/recipes'

import './MobileDetailsHeader.sass'

const MobileDetailsHeader = () => {
  const user = useSelector((s: any) => s.user)
  const { id } = useParams()
  const account = user?.account
  const recipeDetails = useRecipeDetails(+id)
  let back = 'explore'
  if (recipeDetails.status === 'active') {
    back = 'active'
  } else if (recipeDetails.status === 'finished') {
    back = 'liquidated'
  }
  return (
    <header id="mobile-details-masthead">
      <div className="mobile-container">
        <Link className="button" to={`/mobile/${back}`}>
          <span className="icon icon-angle_left" />
          <span>
            <FormattedMessage id="back" />
          </span>
        </Link>
        {account &&
          <div className="account-number">
            {account.account.substr(0, 6) + "..." + account.account.slice(-4)}
          </div>
        }
      </div>
    </header>
  )
}

export default MobileDetailsHeader
