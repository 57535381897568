import React, { useRef } from "react"
import { FormattedMessage } from "react-intl"
import { registerModal, CloseModal } from "@gluedigital/modal"

import "./SendToWalletModal.sass"
import { findNodeInActiveRecipe, generateShowingTime, getCurrentRowLog } from "src/components/Diagram/nodes/nodesLogsHelper"
import NodeStateAndDate from "src/components/modals/modalsViewLogs/NodeStateAndDate/NodeStateAndDate"
import { RecipeDetails } from "src/types"

interface SendToWalletModalProps {
  previousData: {
    data: {
      inputCoin: string
      address: string
    }
    id: string
    recipeLogs: any
    recipeDetails: RecipeDetails

  }
}
const SendToWalletModal = (props: SendToWalletModalProps) => {
  const { inputCoin, address } = props.previousData.data
  const inputRef = useRef(null)
  const selectText = () => {
    inputRef.current.select()
  }
  let currentRowLog: any;
  let showingDate: string;
  let isNodeExecuted: boolean;

  if (props.previousData.recipeLogs !== undefined) {
    const recipeLogs = props.previousData?.recipeLogs
    const nodeId = props.previousData.id
    if (recipeLogs !== null) isNodeExecuted = findNodeInActiveRecipe(recipeLogs, nodeId)
    if (isNodeExecuted) {
      currentRowLog = getCurrentRowLog(recipeLogs, nodeId)
      showingDate = generateShowingTime(currentRowLog)
    }
  }

  return (
    <div className="send-to-wallet-modal modal-content">
      <div className="modal-wrapper">
        <header className="header-buttons">
          <CloseModal>
            <button className="modal-close-button">
              <span className="icon icon-close" />
              <span><FormattedMessage id="close" /></span>
            </button>
          </CloseModal>
          <h1><FormattedMessage id="send-to-wallet-modal.title" /></h1>
          {props.previousData.recipeDetails && <NodeStateAndDate isNodeExecuted={isNodeExecuted} showingDate={showingDate} recipeDetails={props.previousData.recipeDetails} />}
        </header>
        <section>
          <div className="currency-wrapper">
            <div className="wallet-address">
              <span className="coin-text"><FormattedMessage id="send-to-wallet-modal.wallet" /></span>
              <input value={address} ref={inputRef} onClick={selectText} size={90} readOnly />
            </div>
            <div className="wallet-token">
              <span className="coin-text"><FormattedMessage id="send-to-wallet-modal.token" /></span>
              <h2>{inputCoin}</h2>
            </div>
          </div>
        </section>
      </div>
    </div>
  )
}

registerModal("send-to-wallet-modal", SendToWalletModal)
