import React from 'react'
import { FormattedMessage } from 'react-intl'
import { NodeExecutionResult, WithdrawFarmData } from 'src/types'
import { saneBigNumber } from 'src/utils/bigNumberHelper'
import web3 from 'src/utils/web3'

interface FinishedFarmModalDataArbitrumProps {
  withdrawEvent: NodeExecutionResult
  depositedSLPValueUSD: number
  totalSLPAmount: number
  depositedSLPEther: number
}
const FinishedFarmModalDataArbitrum = ({ withdrawEvent, depositedSLPValueUSD, totalSLPAmount, depositedSLPEther }: FinishedFarmModalDataArbitrumProps) => {
  const DECIMAL_USD_ROUNDED: number = 3
  const DECIMAL_TOKEN_ROUNDED: number = 10
  const withdrawFarmData: WithdrawFarmData = withdrawEvent?.extraData?.withdrawFarmData
  const lpWithdrawnEther: number = Number(web3.utils.fromWei(saneBigNumber(Number(withdrawFarmData.lpAmountWithdrawn)).toString(), 'ether'))

  const totalLPUSDValueAtOtherMoment: number = parseFloat(withdrawFarmData.oneLPUSDValue) * totalSLPAmount
  const totalUSD: number = totalLPUSDValueAtOtherMoment + parseFloat(withdrawFarmData.rewardTokenUSDValue)
  let earnedUSDTotal: string = (totalUSD - depositedSLPValueUSD).toFixed(DECIMAL_USD_ROUNDED)
  if (earnedUSDTotal === "-0.000") earnedUSDTotal = "0.000"
  let earnedLPTotal: string = (lpWithdrawnEther - depositedSLPEther).toFixed(DECIMAL_TOKEN_ROUNDED)
  if (earnedLPTotal.startsWith("-0.000000000")) earnedLPTotal = "0.000000000000"

  return (
    <>
      <div id="farm-log-field">
        <h3><FormattedMessage id="farm-modal-log.final-value" /></h3>
        <strong className="span-coin">$ {totalUSD.toFixed(DECIMAL_USD_ROUNDED)} </strong>
        <div className="div-2column-lps">
          <span className="lp-amount-row first-column">{totalSLPAmount.toFixed(DECIMAL_TOKEN_ROUNDED)} <strong>SLPs</strong></span>
        </div>
      </div>
      <div id="farm-log-field">
        <h3><FormattedMessage id="farm-modal-log.earned" /></h3>
        <strong className="span-coin">$ {((totalUSD - depositedSLPValueUSD)).toFixed(DECIMAL_USD_ROUNDED)} </strong>
        <div className="div-2column-lps">
          <span className="lp-amount-row first-column">{(totalSLPAmount - depositedSLPEther).toFixed(DECIMAL_TOKEN_ROUNDED)} <strong>SLPs</strong></span>
        </div>
      </div>
    </>
  )
}

export default FinishedFarmModalDataArbitrum
