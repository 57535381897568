import { saneBigNumber } from "src/utils/bigNumberHelper";

export const getLiquidationPriceFromDelta = ({ liquidationAmount, size, collateral, averagePrice, isLong }) => {
    if (!size || size.eq(0)) {
        return;
    }
    liquidationAmount = saneBigNumber(liquidationAmount)
    averagePrice = saneBigNumber(averagePrice)
    if (liquidationAmount.gt(collateral)) {
        const liquidationDelta = saneBigNumber(liquidationAmount.sub(collateral))
        const priceDelta = liquidationDelta.mul(averagePrice).div(size);
        return isLong ? averagePrice.add(priceDelta) : averagePrice.sub(priceDelta);
    }

    const liquidationDelta = saneBigNumber(collateral.sub(liquidationAmount))
    const priceDelta = liquidationDelta.mul(averagePrice).div(size);

    return isLong ? averagePrice.sub(priceDelta) : averagePrice.add(priceDelta);
}
