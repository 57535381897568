import { useCallback, useEffect } from "react"
import { useRecipeCRUD } from "src/api/recipes"
import { useRecipeDetailsLive, useRecipeLogsLive } from "src/context/SocketContext"
import { useToast } from "./useToast"
import { useSelector } from "react-redux"
import { getNetworkParams } from "src/components/modals/SelectWalletModal/helpers"

export const useAbort = (recipe: any) => {
  const networkId: string = useSelector((s: any) => (s.network.network))
  const networkParams = getNetworkParams(networkId)
  const sendToast = useToast()
  const recipeCRUD = useRecipeCRUD()
  const liveRecipeLogs = useRecipeLogsLive(recipe?.id?.toString())
  const liveRecipeDetails = useRecipeDetailsLive(recipe?.id?.toString())

  useEffect(() => {
    const abortLogs = liveRecipeLogs.filter((log) => log.logtype === 'aborted')
    if (abortLogs.length > 0) {
      const lastAbortLog = abortLogs[abortLogs.length - 1]
      if (lastAbortLog.events.length <= 0) {
        sendToast(recipe.title, 'abort', `${networkParams.blockExplorerUrls}tx/${lastAbortLog.trans}`)
      } else {
        sendToast(recipe.title, 'abort-success', `${networkParams.blockExplorerUrls}tx/${lastAbortLog.trans}`)
      }
    }
  }, [liveRecipeLogs, networkParams])

  const abortHandler = useCallback(async () => {
        await recipeCRUD.abort(recipe.id)
  }, [recipe, liveRecipeDetails])

  return abortHandler
}
