import React, { Suspense } from "react"
import { registerModal, CloseModal } from "@gluedigital/modal"
import { FormattedMessage } from "react-intl"
import { ShortLongModalData } from "src/routes/RecipeDiagram/helpers/types"
import { NodeExecutionResult, RecipeDetails, RecipeExecutionLog } from "src/types"
import { generateShowingTime } from "src/components/Diagram/nodes/nodesLogsHelper"
import { nodesLogsNumber } from "src/constants"
import NodeStateAndDate from "../NodeStateAndDate/NodeStateAndDate"
import { DepositShortLongDataInfo } from "src/api/mummyFinance/mummyTypes"

import "./ShortLongModalLog.sass"
import { LiqPricePerpetualModalLog, MarkPricePerpetualModalLog } from "./MarkAndLiquidationPrice"
import { NetValuePerpetualFallback, NetValuePerpetualModal } from "./NetValueModalLog"

export interface ShortLongModalProps {
  previousData: {
    data: ShortLongModalData
    id: string
    recipeLogs: RecipeExecutionLog[]
    recipeDetails: RecipeDetails
  }
}

const ShortLongModalLog = (props: ShortLongModalProps) => {
  const { data, id: nodeID, recipeDetails, recipeLogs } = props?.previousData
  const { leverage, operationCoin, operation } = data
  const isLong: boolean = operation === "long"

  const emptyNodeExecutionResult = []
  let logEvents: NodeExecutionResult[] = emptyNodeExecutionResult
  let executionStep: number = 0
  let showingDate: string
  let depositRowLog: RecipeExecutionLog
  let endRowLog: RecipeExecutionLog
  let depositShortLongData: DepositShortLongDataInfo

  if (recipeLogs !== null) {
    recipeLogs.forEach((log) => { logEvents = logEvents.concat(log.events.filter((event) => event.nodeID === nodeID)) })
    if (logEvents.length > nodesLogsNumber.NO_EXECUTED_NODE) {
      executionStep = logEvents.filter(ev => ev.functionName === "withdrawFromShortLong").length > 0 ? 5 : 2
      depositRowLog = recipeLogs.find(log => log.events.find(ev => ev.functionName === "depositOnShortLong" && ev.nodeID === nodeID))
      endRowLog = recipeLogs.find(log => log.events.find(ev => ev.functionName === "withdrawFromShortLong" && ev.nodeID === nodeID))
      showingDate = executionStep === 2 ? generateShowingTime(depositRowLog) : generateShowingTime(endRowLog)
      depositShortLongData = logEvents.find((ev) => ev.functionName === "depositOnShortLong")?.extraData?.depositShortLongData
    }
  }

  return (
    <div className="swap-modal modal-content">
      <div className="modal-wrapper">
        <header className="header-buttons">
          <CloseModal>
            <button>
              <span className="icon icon-close" />
              <span><FormattedMessage id="close" /></span>
            </button>
          </CloseModal>
          <h1><FormattedMessage id="short-long-modal.title" /></h1>
          <NodeStateAndDate isNodeExecuted={logEvents.length > 0} showingDate={showingDate} recipeDetails={recipeDetails} numberOfLogs={executionStep} isShortLong={true}/>
        </header>

        <div className="deposit-modal-log-wrapper">
          <section className="perpetual-data-modal-container">
            <div className="upper-row-continuos">
              <article className="upper-row-item">
                <span className="upper-item-title"> <FormattedMessage id="logs-short-long.position" /></span>
                <article className="first-upper-cell-data">
                  <span className="op-coin">{operationCoin}</span>
                  <span className={isLong ? "long" : "short"}>{isLong ? "LONG" : "SHORT"}</span>
                  <span className="upper-item-values-background">{leverage} X</span>
                </article>
              </article>
              <Suspense fallback={<NetValuePerpetualFallback logsEvents={logEvents} />}>
                <NetValuePerpetualModal data={data} logsEvents={logEvents} />
              </Suspense>
              <article className="upper-row-item">
                <span className="upper-item-title"><FormattedMessage id="logs-short-long.size" /></span>
                <span className="upper-item-values-background">$ {depositShortLongData?.positionSize.toFixed(4)}</span>
              </article>
              <article className="upper-row-item">
                <span className="upper-item-title"><FormattedMessage id="logs-short-long.colateral" /></span>
                <span className="upper-item-values-background">$ {depositShortLongData?.collateralUSD}</span>
              </article>
            </div>

            <div className="lower-row-separated">
              <article className="lower-row-item">
                <span className="title-usd-prices"><FormattedMessage id="logs-short-long.entry-price" /></span>
                <span className="usd-values-prices">$ {parseFloat(depositShortLongData?.depositOpCoinPriceUSD).toFixed(3)}</span>
              </article>
              <MarkPricePerpetualModalLog logsEvents={logEvents} operationCoin={operationCoin} />
              <LiqPricePerpetualModalLog logsEvents={logEvents} data={data} firstTypePerpContractAddress={depositShortLongData?.firstTypePerpContractAddress} />
            </div>
          </section>
          <section className="div-provider">
            <span className="span-title-provider"><FormattedMessage id="farm-modal-log.provider" /> </span>
            <span className="span-provider">{data?.provider}</span>
          </section>
        </div>
      </div>
    </div>
  )
}

const ShortLongModalLogWrapper = (props: ShortLongModalProps) => {
  const suspenseModal = <Suspense fallback={<div />}>
    <ShortLongModalLog {...props} />
  </Suspense>
  return suspenseModal
}
registerModal("short-long-modal-log", ShortLongModalLogWrapper)
