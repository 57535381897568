import { BeetsPoolsInfo } from 'src/api/beets/beetsTypes'
import { PairDayData } from 'src/api/farms/types'
import { tokenNameToAddress } from 'src/components/Diagram/nodes/nodesLogsHelper'
import tokensBlackListFantom from 'src/data/tokensBlackListFantom.json'
import poolsAddressBlackList from 'src/data/poolsBlackList.json'
import { Networks } from 'src/utils/networkHelper'
import { getNetworkParams } from '../../SelectWalletModal/helpers'

export interface depositPoolsData {
  tokens: any[]
  apr: string
  address: string
  poolId?: string
  addressTimestamp?: string
  volume: string
  liquidity: string
  provider: string
  name?: string
}

interface BlackList {
  id: string
}

interface PoolAddressBlackList {
  name: string
  address: string
}

export const filterPoolsForBeets = (beetsPools: BeetsPoolsInfo[]): BeetsPoolsInfo[] => {
  const tokensBlacklisted = tokensBlackListFantom.map((token: BlackList) => token.id)
  const poolAddressesBlacklisted = poolsAddressBlackList.map((pool: PoolAddressBlackList) => pool.address.toLowerCase())

  const filteredBeetsPools = beetsPools.filter((pool) => {
    return (
      !pool.allTokens.some(token => tokensBlacklisted.includes(token.symbol)) &&
      !poolAddressesBlacklisted.includes(pool.address)
    )
  })
  const x = filteredBeetsPools.filter((pool) =>
   (pool.name !== 'A Late Quartet ')
   && (pool.name !== 'A Late Quartet, Yearn Boosted')
   && (pool.name !== 'Dante Symphony')
   && (pool.name !== 'Beardhemian Brushsody')
   && (pool.name !== 'A Song of Ice and Fire')
   && (pool.name !== 'Treeb Me Bro')
   && (pool.name !== 'Steady Beets')
  )
  return x
}

export const filterPoolsForSpooky = (spookyPools: PairDayData[]): PairDayData[] => {
  const tokensBlacklisted = tokensBlackListFantom.map((item: BlackList) => item.id)

  return spookyPools.filter((pool) => {
    return !tokensBlacklisted.includes(pool.token0.symbol) && !tokensBlacklisted.includes(pool.token1.symbol)
  })
}

export const combinePools = (spookyPools: PairDayData[], beetsPools: BeetsPoolsInfo[], networkId: string): depositPoolsData[] => {
  const newUniArray: depositPoolsData[] = spookyPools.map((pool) => {
    const tokens = [
      {
        symbol: pool.token0.symbol,
        address: tokenNameToAddress(pool.token0.symbol, networkId)
      },
      {
        symbol: pool.token1.symbol,
        address: tokenNameToAddress(pool.token1.symbol, networkId)
      },
    ]
    const newElement: depositPoolsData = {
      tokens,
      addressTimestamp: pool.id,
      address: pool.pairAddress,
      apr: pool.aprFees.toFixed(2),
      volume: pool.dailyVolumeUSD,
      liquidity: pool.reserveUSD,
      provider: String(pool.provider)
    }
    return newElement
  })

  const newBalancerArray: depositPoolsData[] = beetsPools.map((pool) => {
    let newElement: depositPoolsData
    const tokens = pool.allTokens
    const isBBToken = tokens.some((token) => token.symbol.startsWith("bb-"))
    const isPool: boolean = pool.address === "0x98c3c3a426dc2c2a7017809f134ca9dab151877a"
    if (isPool || !isBBToken) {
      const newTokens = tokens.map((token) => {
        if (token.weight === null || token.weight === undefined) return { ...token, weight: undefined }
        return token
      })

      let provider: string
      let apr: string
      if (networkId === Networks.fantom) {
        apr = pool.dynamicData.apr.apr.total
        provider = 'BeethovenX'
      } else {
        apr = pool.dynamicData.apr.swapApr
        provider = 'BeethovenX'
      }

      newElement = {
        tokens: newTokens,
        address: pool.address,
        poolId: pool.id,
        apr: (Number(apr) * 100).toFixed(2),
        volume: pool.dynamicData.volume24h,
        liquidity: pool.dynamicData.totalLiquidity24hAgo,
        provider,
        name: pool.name
      }
    }
    return newElement
  })

  const newArray: depositPoolsData[] = newUniArray.concat(newBalancerArray).filter(pool => pool)

  return filterPoolsByToken(newArray, networkId)
}

const filterPoolsByToken = (pools: depositPoolsData[], networkId: string): depositPoolsData[] => {
  const networkParams = getNetworkParams(networkId)
  const tokensList = networkParams.tokens
  const tokenAddresses: string[] = tokensList.map(token => token.address.toLowerCase())
  const filteredPools: depositPoolsData[] = pools.filter(pool => pool.tokens.every(token => tokenAddresses.includes(token?.address?.toLowerCase())))
  return filteredPools
}
