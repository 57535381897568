import { useEffect, useRef } from "react"

const useInfiniteScroll = (callback: () => void, useParent = false) => {
  const ref = useRef<HTMLElement>()

  useEffect(() => {
    const node = useParent ? ref.current.parentElement : ref.current

    const handleScroll = () => {
      const remainingPx = node.scrollHeight - node.clientHeight - node.scrollTop
      if (remainingPx <= 50) {
        callback?.()
      }
    }

    node.addEventListener('scroll', handleScroll)
    return () => node.removeEventListener('scroll', handleScroll)
  }, [callback, useParent])

  return ref
}

export default useInfiniteScroll
