import React, { Suspense, useRef } from 'react'
import { FormattedMessage } from 'react-intl'
import { useScrollOver } from '@gluedigital/scrollastic'
import MarkdownMessage from "@gluedigital/markdown-message"
import Footer from '../../components/Landing/Footer/Footer'
import Header from 'src/components/Landing/Header/Header'
import HeroBanner from './HeroBanner/HeroBanner'
import Partners from './Partners/Partners'
import YoutubeVideo from 'src/components/YoutubeVideo/YoutubeVideo'
import Features from './Features/Features'
import Explore from './Explore/Explore'
import community from 'src/data/community.json'
import social from 'src/data/social.json'
import Loading from 'src/components/common/Loading/Loading'
import ErrorBoundary from 'src/components/ErrorBoundary/ErrorBoundary'

import './Landing.sass'

const Landing = () => {
  const scrollable = useRef(null)
  const introSection = useScrollOver('#intro', scrollable)
  const communityGrid = useScrollOver('#community', scrollable)

  return (
    <div id="landing" className="page" ref={scrollable}>
      <Header scrollable={scrollable} />
      <HeroBanner />
      <section id="intro" className={ 'landing-section ' + (introSection > 0.3 ? 'enter' : '')}>
        <div className="container">
          <div className="split">
            <div className="item left">
              <h2 className="tortle-subtitle"><MarkdownMessage id="landing.subtitle" /></h2>
              <h1><MarkdownMessage id="landing.intro.title" /></h1>
              <p><MarkdownMessage id="landing.intro.text" /></p>
            </div>
            <div className="item right">
              <YoutubeVideo poster="landing" id="2WPhpCUhzw8" />
            </div>
          </div>
        </div>
      </section>
      <ErrorBoundary fallback={<div />}>
        <Suspense fallback={<Loading />} >
          <Explore scrollable={scrollable} />
        </Suspense>
      </ErrorBoundary>

      <Features scrollable={scrollable} />
      <section id="community" className={ 'landing-section ' + (communityGrid > 0.3 ? 'enter' : '')}>
        <div className="container">
          <h2 className="tortle-subtitle"><MarkdownMessage id="landing.subtitle" /></h2>
          <h1 className="section-title">
            <span>
              <FormattedMessage id="landing.community.title" />
            </span>
          </h1>
          <p className="recipes-text">
            <FormattedMessage id="landing.community.text" />
          </p>
          <div className="community-grid">
            {community.map((c) => (
              <a
                key={c.name}
                target="_blank"
                rel="noreferrer noopener"
                className={`community-card ${c.name}`}
                href={c.href}
              >
                <span className={`icon icon-${c.name}`} />
                <h2>
                  <FormattedMessage id={`home.community.card-${c.name}.title`} />
                </h2>
                <p>{c.name === 'telegram' ? '@tortleninjaTG' : '@tortleninja'}</p>
              </a>
            ))}
          </div>
          <div className="social-grid">
            {social.map((s) => (
              <a
                key={s.name}
                target="_blank"
                rel="noreferrer noopener"
                className={`social-card ${s.name}`}
                href={s.href}
              >
                <span className={`icon icon-${s.name}`} />
              </a>
            ))}
          </div>
        </div>
      </section>
      <Partners scrollable={scrollable} />
      <Footer />
    </div>
  )
}

export default Landing
