import React from 'react'
import { FormattedMessage } from 'react-intl'
import { useScrollOver } from '@gluedigital/scrollastic'
import MarkdownMessage from "@gluedigital/markdown-message"

import './Features.sass'

const Features = ({ scrollable }: { scrollable?: any }) => {
  const features = ['composability', 'real-data', 'evolve', 'one-place']
  const positions = {
    composability: useScrollOver('#landing-feature-composability', scrollable),
    'real-data': useScrollOver('#landing-feature-real-data', scrollable),
    evolve: useScrollOver('#landing-feature-evolve', scrollable),
    'one-place': useScrollOver('#landing-feature-one-place', scrollable),
  }
  return (
    <section id="landing-features" className="landing-section">
      <div className="container">
        {features.map((feature, i) => {
          const position = positions[feature]
          return (
            <article
              id={`landing-feature-${feature}`}
              key={feature}
              className={`landing-feature ${feature} ${position > 0.2 ? 'enter' : ''}`}
            >
              <div className="split">
                <div className="item left">
                  <h2><MarkdownMessage id={`landing-feature.${feature}.title`} /></h2>
                  <p><FormattedMessage id={`landing-feature.${feature}.text`} /></p>
                </div>
                <div className="item right">
                  <img
                    loading="lazy"
                    className="feature-background"
                    style={{ top: (position * 5) + '%' }}
                    src={`/images/landing/sections/item-${i + 1}-bg.png?`}
                    alt={`${feature} illustration image`}
                  />
                  <img
                    loading="lazy"
                    className="feature-foreground"
                    style={{ top: (-position * 20) + '%' }}
                    src={`/images/landing/sections/item-${i + 1}.png?`}
                    alt={`${feature} illustration image`}
                  />
                </div>
              </div>
            </article>
          )
        })}
      </div>
    </section>
  )
}

export default Features
