import { SpookyVaultsInfo } from "src/api/farms/types"

export const getElementsForAPIFromInstance = (reactFlowInstance, networkParams: any) => {
  const newElements = reactFlowInstance.nodes.map((node) => {
    const { data, id, type, position } = node
    if (type === "farmNode") {
      let desiredVault: SpookyVaultsInfo
      const vaultsV2: SpookyVaultsInfo[] = networkParams.contracts.vaults
      desiredVault = vaultsV2.find((vault) => vault.token0.toUpperCase() === data?.pair?.token0.toUpperCase() && vault.token1.toUpperCase() === data?.pair?.token1.toUpperCase())
      if (desiredVault === undefined) {
        const vaultsV3: SpookyVaultsInfo[] = networkParams.contracts?.vaultsV3
        desiredVault = vaultsV3.find((vault) => vault.token0 === data?.pair?.token0 && vault.token1 === data?.pair?.token1)
      }
      const newData = {
        ...data,
        contracts: {
          batch: networkParams.contracts.batch,
          nodes: networkParams.contracts.nodes,
          vault: desiredVault.address,
          strategy: desiredVault.strategy
        }
      }
      const newElem = { data: newData, id, type, position }
      return newElem;
    }
    const newData = {
      ...data,
      contracts: {
        batch: networkParams.contracts.batch,
        nodes: networkParams.contracts.nodes
      }
    }
    const newElem = { data: newData, id, type, position }
    return newElem;
  });
  reactFlowInstance.edges.forEach((edge) => {
    const { style, ...newElem } = edge
    newElements.push(newElem)
  })
  return newElements;
};
