import React, { memo, useState } from "react";
import { FormattedMessage } from "react-intl";
import { Handle } from "react-flow-renderer";
import { StakeNodeData } from "src/routes/RecipeDiagram/helpers/types";
import Buttons from "./Buttons";

import "./StakeLPNode.sass";

interface StakeNodeProps {
  data: StakeNodeData;
  id: any;
}

export default memo(({ id, data }: StakeNodeProps) => {
  const [showButtons, setShowButtons] = useState(false);

  const positionTarget: any = "left";
  const positionSource: any = "right";

  const handleBlur = (e : React.FocusEvent<HTMLDivElement>) => {
    if(!e.currentTarget.contains(e.relatedTarget as Node)){
      setShowButtons(false)
    }
  }

  return (
    <div className="node" onClick={() => setShowButtons(true)} onBlur={handleBlur} tabIndex={0}>
      <div className={"stake-lp"}>
        <Handle style={{ top: 50 }} type="target" position={positionTarget} id="stake-input" />
        <Handle style={{ top: 50 }} type="source" position={positionSource} id="stake-output" />
      </div>
      <div className="node-info">
        <FormattedMessage id="node.stakeLPNode" /> {data.coin} to {data.outputCoin}
      </div>
      {showButtons && <Buttons data={data} id={id} />}
    </div>
  );
});
