import web3 from "src/utils/web3"
import { AbiItem } from 'web3-utils'
import UniswapV2Pair from './abis/UniswapV2Pair.json'
import { getPrice } from "./GetPriceHelpers"
import { tokenAddressToName } from "src/components/Diagram/nodes/nodesLogsHelper"
import { getEtherNumberAmountFromAddress } from "./TokensDecimalsHelpers"

// Permite calculate the unitary price of the lpToken using only blockchain Info
export const calculateLpPriceToReaperVaults = async (lpAddress: string, networkId: string): Promise<string> => {
    const lpContract = new web3.eth.Contract(UniswapV2Pair as AbiItem[], lpAddress)
    const token0Address: Promise<string> = lpContract.methods.token0().call()
    const token1Address: Promise<string> = lpContract.methods.token1().call()
    const reserves: Promise<any> = lpContract.methods.getReserves().call()
    const supply: Promise<any> = lpContract.methods.totalSupply().call()
    const lpContractResults = await Promise.all([token0Address, token1Address, reserves, supply])
    const totalSupplyEther: string = web3.utils.fromWei(lpContractResults[3])
    const liquidityInfoWei = lpContractResults[2]
    const liquidityInfo: string[] = await Promise.all([getEtherNumberAmountFromAddress(lpContractResults[0].toLowerCase(), liquidityInfoWei[0]), getEtherNumberAmountFromAddress(lpContractResults[1].toLowerCase(), liquidityInfoWei[1])])
    const tokensPrices: string[] = await Promise.all([getPrice(tokenAddressToName(lpContractResults[0].toLowerCase(), networkId), "USDC_AXL", networkId), getPrice(tokenAddressToName(lpContractResults[1].toLowerCase(), networkId), "USDC", networkId)])
    const token0USD: number = Number(tokensPrices[0]) * Number(liquidityInfo[0])
    const token1USD: number = Number(tokensPrices[1]) * Number(liquidityInfo[1])
    const liquidity: number = token0USD + token1USD
    const lpPrice: number = liquidity / Number(totalSupplyEther)
    return lpPrice.toString()
}
