import React, { Suspense, useState } from 'react'
import FeaturedRecipes from '../Recipes/FeaturedRecipes'
import TrendingRecipes from '../Recipes/TrendingRecipes'
import MobileBrowseList from './MobileBrowse/MobileBrowseList/MobileBrowseList'
import MobileBrowse from './MobileBrowse/MobileBrowse'
import Loading from 'src/components/common/Loading/Loading'
import { SortByEnum } from './MobileBrowse/types'

const Explore = () => {
  const [searchTerm, setSearch] = useState('')
  const [showOnlyVerified, setshowOnlyVerified] = useState(false)
  const [sortBy, setSortBy] = useState<SortByEnum>(null)

  let searchTimeout
  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    clearTimeout(searchTimeout)
    if (!e.target.value) {
      return setSearch('')
    }
    searchTimeout = setTimeout(() => {
      setSearch(e.target.value)
    }, 500)
  }
  return (
    <>
      <MobileBrowse
        searchTerm={searchTerm}
        handleSearch={handleSearch}
        showOnlyVerified={showOnlyVerified}
        handleShowOnlyVerified={setshowOnlyVerified}
        sortBy={sortBy}
        handleSortBy={setSortBy}
      />
      <Suspense fallback={<Loading />}>
        {
          ((searchTerm && searchTerm !== '') || showOnlyVerified || sortBy)
            ? <MobileBrowseList showOnlyVerified={showOnlyVerified} searchTerm={searchTerm} sortBy={sortBy} />
            : <><FeaturedRecipes /><TrendingRecipes /></>
        }
      </Suspense>
    </>
  )
}

export default Explore
