import { FETCH_ONLY_TOKENS_BEETS_POOLS, FETCH_SINGLE_BEETS_POOL, FETCH_TOKEN_DATA_BEETS } from 'src/api/beets/beetsQueries'
import usePromise from './usePromise'
import { balancerSubgraph } from 'src/constants'
import { BeetsPoolsInfo, SubgraphBeetsPoolsInfo } from 'src/api/beets/beetsTypes'
import { fetchAllBeetsPools } from 'src/api/beets/beetsHelper'
import fetchGraph from 'src/api/farms/thegraph/fetchGraph'
import { fetchAllBalancerPools } from 'src/api/beets/balancerHelper'
import { Networks } from 'src/utils/networkHelper'

export const fetchTokenDataBeets = async (tokenAddress: string, networkId: string) => {
  const query = FETCH_TOKEN_DATA_BEETS(tokenAddress)
  return await fetchGraph(balancerSubgraph[networkId], query)
}

export const useFetchSingleBeetsPool = (poolBeetsId: string, networkId: string): BeetsPoolsInfo => {
  const query = FETCH_SINGLE_BEETS_POOL(poolBeetsId.toLowerCase())
  const result: SubgraphBeetsPoolsInfo = usePromise(fetchGraph, balancerSubgraph[networkId], query)
  const data: BeetsPoolsInfo = result?.data?.poolGetPool
  return { ...data, provider: "BeethovenX" }
}

const getBalancerPools = async (networkId: string) => {
    if (networkId === Networks.fantom) return await fetchAllBeetsPools(networkId)
    else return await fetchAllBalancerPools(networkId)
}

export const useFetchAllBeetsPools = (networkId: string): BeetsPoolsInfo[] => {
  const result = usePromise(getBalancerPools, networkId)
  return result
}

export const useFetchAllBeetsPoolsOnlyWithTokens = (networkId: string): BeetsPoolsInfo[] => {
  const result: SubgraphBeetsPoolsInfo = usePromise(fetchGraph, balancerSubgraph[networkId], FETCH_ONLY_TOKENS_BEETS_POOLS())
  const data: BeetsPoolsInfo[] = result.data.poolGetPools
  return data
}
