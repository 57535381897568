import React, { useEffect, useRef, useState } from "react"
import './DetailsHeader.sass'
import { useModal } from "@gluedigital/modal"
import { useHistory } from "react-router-dom"
import { RecipeDetails } from "src/types"
import { FormattedMessage } from "react-intl"
import { useSelector } from "react-redux"

interface DetailsHeaderProps {
  recipeDetails?: RecipeDetails
}
const DetailsHeader = (props: DetailsHeaderProps) => {
  const { recipeDetails } = props
  const [isDialogOpen, setDialog] = useState(false)
  const toggleDialog = () => setDialog(prev => !prev)
  const modal = useModal()
  const history = useHistory()
  const dialogRef = useRef(null)
  const account = useSelector((s: any) => (s.user ? s.user.account : undefined))
  const userAddress = account?.account.slice(2)
  const isOwner = userAddress === recipeDetails.owner

  useEffect(() => {
    if (isDialogOpen) {
      dialogRef.current.focus()
    }
  }, [isDialogOpen])
  const viewDescriptionHandler = (event, recipeDetails) => {
    event.preventDefault()
    modal.show("view-description-modal", recipeDetails)
  }
  const viewRecipeAPRHandler = (event, recipeDetails) => {
    event.preventDefault()
    if (recipeDetails) {
      history.push(('/recipe/apr-details/' + recipeDetails?.id))
    }
  }
  const handleBlur = (e: React.FocusEvent<HTMLDivElement>) => {
    if (!e.currentTarget.contains(e.relatedTarget as Node)) {
      setDialog(false)
    }
  }
  return <div id="details-header" >
    <button onClick={toggleDialog}>
      <span className="icon icon-angle_down" />
    </button>
    {isDialogOpen && <div className="details-dialog" ref={dialogRef} onBlur={handleBlur} tabIndex={9}>
      <button onClick={(e) => viewDescriptionHandler(e, recipeDetails)}>
        <FormattedMessage id={"details-header.description"} />
      </button>
      {isOwner && <button onClick={(e) => viewRecipeAPRHandler(e, recipeDetails)}>
        <FormattedMessage id={"details-header.apr"} />
      </button>}
    </div>}
  </div>
}

export default DetailsHeader
