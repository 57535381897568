import React from "react"
import { useSelector } from "react-redux"
import { tokenNameToAddress } from "src/components/Diagram/nodes/nodesLogsHelper"
import { getTokenDecimals } from "src/contracts/TokensDecimalsHelpers"
import usePromise from "src/hooks/usePromise"

interface DepositedReaperLeftLogProps {
    depositPriceUSD: string
    rfTokensDeposited: string
    depositAmount: number
    tokenDepositedName: string
}

const DepositedReaperLeftLog = ({ depositPriceUSD, rfTokensDeposited, depositAmount, tokenDepositedName }: DepositedReaperLeftLogProps) => {
    const DECIMAL_USD_ROUNDED: number = 3
    const RFToken: string = "RFT"
    const networkId: string = useSelector((s: any) => s.network.network)
    const decimals: number = Number(usePromise(getTokenDecimals, tokenNameToAddress(tokenDepositedName, networkId)))
    return (
        <div className="logs-group-rows">
            <p className="main-text-logs">$ {(parseFloat(depositPriceUSD)).toFixed(DECIMAL_USD_ROUNDED)} </p>
            <p> {(Number(rfTokensDeposited).toFixed(18))} {RFToken}</p>
            <p> {(Number(depositAmount).toFixed(decimals))} {tokenDepositedName}</p>
        </div>
    )
}

export default DepositedReaperLeftLog
