import React from "react"
import { FormattedMessage } from "react-intl"
import { registerModal, CloseModal } from "@gluedigital/modal"
import { Link } from "react-router-dom"

import logo from "src/static/images/logo.png"
import "./CompleteModal.sass"

const CompleteModal = () => {
  return (
    <div className="complete-modal modal-content">
      <img className="logo" src={logo} alt="Tortle Ninja logo" />
      <h1>
        <FormattedMessage id="complete-modal.title" />
      </h1>
      <CloseModal tagName="button">
        <Link to="/dashboard/active">
          <FormattedMessage id="complete-modal.back" />
        </Link>
      </CloseModal>
    </div>
  )
}

registerModal("complete-modal", CompleteModal)
