
import React, { Suspense, useEffect, useState } from "react"
import { FormattedMessage } from "react-intl"
import { CodeNodeRecipe } from "src/routes/Mobile/helpers/getNodesOrderedMap"
import ManualPerformanceType from "./PerformanceType/ManualPerformanceType/ManualPerformanceType"
import TimePerformanceType from "./PerformanceType/TimePerformanceType/TimePerformanceType"
import SpookyPerformanceType from "./PerformanceType/SpookyPerformanceType/SpookyPerformanceType"
import TortlePerformanceType from "./PerformanceType/TortlePerformanceType/TortlePerformanceType"
import ChainLinkPerformanceType from "./PerformanceType/ChainLinkPerformanceType/ChainLinkPerformanceType"
import Spinner from "src/components/common/Loading/Spinner/Spinner"
import ErrorBoundary from "src/components/ErrorBoundary/ErrorBoundary"
import { ConfigComboTriggerProps } from "../ConfigComboTrigger"
import { getOriginalNodeId } from "src/routes/Mobile/helpers/addNewComboConfig"

import "./ComboTriggerItem.sass"

interface ComboTriggerItemProps {
  node: CodeNodeRecipe
  index: number
  combos: ConfigComboTriggerProps

}

const ComboTriggerItem = ({ node, index, combos }: ComboTriggerItemProps) => {
  const [open, setopen] = useState(false)
  const previousData = node?.data
  const { setCombosInfo } = combos
  const [triggerData, setTriggerData] = useState(previousData)
  const conditionProviders = {
    tortle: <TortlePerformanceType triggerData={triggerData} setTriggerData={setTriggerData} />,
    user: <ManualPerformanceType />,
    spookyswap: <SpookyPerformanceType triggerData={triggerData} setTriggerData={setTriggerData} />,
    chainlink: <ChainLinkPerformanceType triggerData={triggerData} setTriggerData={setTriggerData} />,
    time: <TimePerformanceType triggerData={triggerData} setTriggerData={setTriggerData} />
  }

  useEffect(() => {
    const newNodeIdPatter: RegExp = /^\d+_/;
    if (newNodeIdPatter.test(node.id)) {
      setCombosInfo((s: Map<string, any>) => new Map(s.set(getOriginalNodeId(node.id), triggerData)))
    } else {
      setCombosInfo((s: Map<string, any>) => new Map(s.set(node.id, triggerData)))
    }
  }, [triggerData, node.id, setCombosInfo])

  return (
    <article className={`combo-trigger-item type-${node?.data?.conditionProvider?.toLowerCase()}`}>
      <header className="combo-trigger-header" onClick={() => setopen(!open)}>
        <h1>
          <FormattedMessage id="combo-trigger-item.title" />
        </h1>
        <section>
          <strong className="combo-trigger-index">({index})</strong>
          <span className={`icon ${!open ? 'icon-angle_right' : 'icon-angle_down'}`} />
        </section>
      </header>
      {open &&
        <section className="combo-trigger-details">
          <p>{node?.data?.motivation}</p>
          <div className="combo-trigger-data">
            <ErrorBoundary fallback={'error'}>
              <Suspense fallback={<Spinner />}>
                {conditionProviders[previousData?.conditionProvider?.toLowerCase()] || <ManualPerformanceType />}
              </Suspense>
            </ErrorBoundary>
          </div>
        </section>
      }
    </article>
  )
}

export default ComboTriggerItem
