import React from 'react'
import { Link } from 'react-router-dom'
import LanguageSelector from 'src/components/Landing/LanguageSelector/LanguageSelector'
import community from 'src/data/community.json'
import social from 'src/data/social.json'

import mobilelogo from "src/static/images/logos/logo-3.png"
import './MobileFooter.sass'

const MobileFooter = () => {
  return (
    <footer id="mobile-colophon">
      <div className="mobile-container">
        <Link to="/mobile">
          <img className="logo" src={mobilelogo} alt="Tortle Ninja logo" />
        </Link>
        <LanguageSelector />
        <section className="community-grid">
          {community.map((c) => (
            <a
              key={c.name}
              target="_blank"
              rel="noreferrer noopener"
              className={`community-card ${c.name}`}
              href={c.href}
            >
              <span className={`icon icon-${c.name}`} />
            </a>
          ))}
        </section>
        <section className="social-grid">
          {social.map((s) => (
            <a
              key={s.name}
              target="_blank"
              rel="noreferrer noopener"
              className={`social-card ${s.name}`}
              href={s.href}
            >
              <span className={`icon icon-${s.name}`} />
            </a>
          ))}
        </section>
      </div>
    </footer>
  )
}

export default MobileFooter
