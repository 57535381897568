import React, { useState } from "react"
import { FormattedMessage } from "react-intl"
import { getTokenImage } from "src/api/farms/helpers"
import { compactNumber } from "src/utils/compactNumbers"
import { useSelector } from "react-redux"
import { getSingleTokenBalance, getTokenBalanceList } from "src/utils/balanceHelper"
import { getNetworkParams } from "src/components/modals/SelectWalletModal/helpers"
import usePromise from "src/hooks/usePromise"

import "./TokenSelectorList.sass"
import { TokensList } from "src/hooks/useOrderTokensInModals"
import { tokenNameToAddress } from "src/components/Diagram/nodes/nodesLogsHelper"

interface TokenListProps {
  outputCoin: string
  setCoin: Function
  search?: string
}

interface TokenListItemProps {
  outputCoin: string
  setCoin: Function
  token: TokenProps
}
interface TokenProps extends TokensList {
  balance?: number
}

const TokenListItem = ({ outputCoin, setCoin, token }: TokenListItemProps) => {
  const [showCopied, setShowCopied] = useState<boolean>(false)
  const copyClipBoard = (e, text: string) => {
    e.stopPropagation()
    setShowCopied(true)
    navigator?.clipboard?.writeText(text).catch(err => console.error(err.message))
    setTimeout(() => setShowCopied(false), 1500)
  }
  const { balance } = token
  return (
    <li
      className={`token-item ${outputCoin === token.id ? 'selected' : ''}`}
      onClick={() => setCoin(token.id)}
    >
      <section>
        <img title={token.id} className="token-logo" src={getTokenImage(token.id)} />
        <section>
          <p><strong>{token.name || token.id}</strong></p>
          <p>
            <span>{token.id}</span>
            {balance > 0 && <span className="compact-number">{`(${compactNumber(balance, 5)})`}</span>}
          </p>
        </section>
      </section>
      <div className="account-number">
        <span>{token.address.substr(0, 5) + "..." + token.address.slice(-3)}</span>
        {
          showCopied &&
          <div className="copied-bubble">
            <span><FormattedMessage id="copied-bubble.text" /></span>
          </div>
        }
        <button type="button" className="mobile-icon mobile-icon-copy" onClick={e => copyClipBoard(e, token.address)} />
      </div>
    </li>
  )
}

const TokenSelectorList = ({ outputCoin, setCoin, search }: TokenListProps) => {
  const userInfoAccount = useSelector((s: any) => s.user.account)
  const networkId: string = useSelector((s: any) => (s.network.network))
  const networkParams = getNetworkParams(networkId)
  const coins = networkParams.tokens
  const balanceCoins: TokenProps[] = usePromise(getTokenBalanceList, userInfoAccount, networkId)
  const balanceOutputCoin: string = usePromise(getSingleTokenBalance, outputCoin, userInfoAccount, networkId)
  if (balanceOutputCoin) {
    const index: number = balanceCoins.findIndex(token => token?.id === outputCoin)
    if (index === -1) { // it means that token is not inside balanceList. We must add the value
      const newItem: TokenProps = {
        balance: parseFloat(balanceOutputCoin),
        id: outputCoin,
        address: tokenNameToAddress(outputCoin, networkId)
      }
      balanceCoins.push(newItem)
    }
  }
  const ids = new Set(balanceCoins.map(t => t.id));
  const sortedBalanceCoins = [...balanceCoins].sort((a, b) => +b.balance - (+a.balance))
  const merged = [...sortedBalanceCoins, ...coins.filter(c => !ids.has(c.id))]

  const filterCoins: TokenProps[] = merged.filter(c => c.id.toLowerCase().includes(search.toLowerCase()) || c.name?.toLowerCase().includes(search.toLowerCase()))
  return (
    <>
      {filterCoins.map(c => (
        <TokenListItem key={c.id} token={c} outputCoin={outputCoin} setCoin={setCoin} />
      ))}
    </>
  )
}

export default TokenSelectorList
