import React from 'react'

import './ToggleSwitch.sass'

interface ToggleSwitchProps {
  value: boolean
  onChange: (newValue: boolean) => void
}

const ToggleSwitch = ({ value, onChange }: ToggleSwitchProps) => {
  return (
    <div className={`mobile-switcher ${!value ? 'right' : 'left'}`}>
      <div className="toggle" onClick={() => onChange(!value)} />
    </div>
  )
}

export default ToggleSwitch
