import React from "react"
import { FormattedMessage } from "react-intl"
import { useModal } from '@gluedigital/modal'
import logo from 'src/static/images/logos/logo-3.png'

import './NoLoginConnectWallet.sass'

const NoLoginConnectWallet = () => {
  const modal = useModal()
  const handleLogin = () => {
    modal.show('select-wallet-modal')
  }

  return (
    <div id="no-login-connect-wallet">
      <img src={logo} alt="tortle logo" />
      <div className="visible-background">
        <p><FormattedMessage id="text-connect-wallet" /></p>
        <h3><FormattedMessage id="connect-your-wallet" /></h3>
        <button id="no-login-metamask" className="connect-btn" onClick={handleLogin}><FormattedMessage id="connect-your-wallet" /></button>
      </div>
    </div>
  )
}

export default NoLoginConnectWallet
