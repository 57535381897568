import React, { Suspense } from 'react'
import { Switch, Route } from 'react-router-dom'
import Loading from 'src/components/common/Loading/Loading'
import MobileFooter from './components/MobileFooter/MobileFooter'
import MobileHeader from './components/MobileHeader/MobileHeader'
import SectionSelector from './components/SectionSelector/SectionSelector'
import LiquidatedRecipes from './components/Recipes/LiquidatedRecipes'
import ActiveRecipes from './components/Recipes/ActiveRecipes'
import FailedRecipes from './components/Recipes/FailedRecipes'
import Explore from './components/Explore/Explore'

import './Mobile.sass'

const Mobile = () => {
  return (
    <div id="mobile" className="page">
      <MobileHeader />
      <SectionSelector />
      <div className="mobile-container">
        <Suspense fallback={<Loading />}>
          <Switch>
            <Route path="/mobile/active"><ActiveRecipes /></Route>
          </Switch>
          <Switch>
            <Route path="/mobile/liquidated"><LiquidatedRecipes /></Route>
          </Switch>
          <Switch>
            <Route path="/mobile/failed"><FailedRecipes /></Route>
          </Switch>
          <Switch>
            <Route path="/mobile/explore">
              <section className="mobile-section-recipes" >
                <Explore />
              </section>
            </Route>
          </Switch>
        </Suspense>
      </div>
      <MobileFooter />
    </div>
  )
}

export default Mobile
