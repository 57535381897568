import copy from "fast-copy"
import { masterchefTheGraphUriFromUniswap, pairTokensFarms, pairTokensPools } from "src/constants"
import { PoolProvider } from "src/routes/RecipeDiagram/helpers/types"
import { getDeltaTimestamps } from "src/utils/dates"
import { PAIR_5DAY_DATA_BULK, PAIR_DATA_IN_SPECIFIC_TIMESTAMP, PAIR_DAY_DATA_BULK_NO_TOKENS, getPairDayDataBulk } from "./thegraph/pairsQueriesV2Sushi"
import { GET_LAST_BLOCK } from "./thegraph/pairsQueriesHelper"
import { getTimestampFromBlockNumber } from "./helpers"
import { Networks } from "src/utils/networkHelper"
import fetchGraph from "./thegraph/fetchGraph"
import { adaptPoolInfo } from "./arbitrumHelpers/poolHelpers"
import { NATIVE_TOKEN_PRICE_CURRENT, TOKEN_DERIVED_ETH_BY_BLOCK_NUMBER, TOKEN_PRICE_USD } from "./thegraph/tokenQueries"
import { tokenNameToAddress } from "src/components/Diagram/nodes/nodesLogsHelper"
import { calculateFeeApr } from "./aprHelpers"

const pairsAPIArbitrum = {
  async getPairsDayData (ids: string[], graphUrl: string, isPools = false) {
    let pairs = null
    let query: string
    let timestamp: number = getDeltaTimestamps().t24h
    let isTokensInfoIntheQuery: boolean = true
    const pairsIdentifier = !isPools ? pairTokensFarms : pairTokensPools
    const pairTokens = JSON.parse(localStorage.getItem(pairsIdentifier))
    if (
      !isPools && pairTokens && pairTokens.spooky &&
      new Date().getSeconds() - new Date(parseInt(pairTokens.spooky?.updated, 10)).getSeconds() < 259200
    ) {
      query = PAIR_DAY_DATA_BULK_NO_TOKENS(timestamp)
      pairs = await fetchGraph(graphUrl, query)
      isTokensInfoIntheQuery = false
    } else {
      query = getPairDayDataBulk(ids, timestamp)
      pairs = await fetchGraph(graphUrl, query)
    }
    if (pairs.data.pairDayDatas.length === 0) {
      const res = await fetchGraph(graphUrl, GET_LAST_BLOCK)
      const lastBlock = res.data._meta.block.number
      const lastBlockTimestamp = await getTimestampFromBlockNumber(lastBlock)
      timestamp = lastBlockTimestamp - 86400
      query = getPairDayDataBulk(ids, timestamp)
      pairs = await fetchGraph(graphUrl, query)
    }
    const newPools = adaptPoolInfo(pairs.data.pairDayDatas)
    const result = { data: newPools, hasTokens: isTokensInfoIntheQuery }
    return result
  },
  async getPoolPairs (pools: any) {
    if (new Date().getSeconds() - pools.update?.getSeconds() < 180) return pools

    const graphUrl = masterchefTheGraphUriFromUniswap[Networks.arbitrum]
    const pairsData = await this.getPairsDayData([], graphUrl, true)
    const pairsWithProvider = []
    for (const pair of pairsData.data) {
      const pairWithProvider = copy(pair)
      pairWithProvider.provider = PoolProvider.spooky
      pairWithProvider.aprFees = calculateFeeApr(pair.dailyVolumeUSD, pair.reserveUSD, Networks.arbitrum)
      pairsWithProvider.push(pairWithProvider)
    }
    return {
      pools: {
        data: pairsWithProvider,
        updated: new Date(),
      },
    }
  },
  // Permite obtener informacion de un pair en un determinado instante de tiempo a traves del timestamp.
  // Se utiliza para los logs de las farms y los nodos de deposit. Para mostrar el apr y liquidez en el instante de tiempo cuando termino la ejecucion.
  async getPairDataByBlockNumber (pairId: string, timestamp: number) {
    const graphUrl = masterchefTheGraphUriFromUniswap[Networks.arbitrum]
    const query = PAIR_DATA_IN_SPECIFIC_TIMESTAMP(pairId, timestamp)
    const result = await fetchGraph(graphUrl, query)
    return result
  },
  // Function utiliza para mostrar la info de los logs del pair que estamos utilizando en un nodo de deposit. En este caso solo obtiene la info de este par en concreto.
  async getSinglePairForLogs (pairID: string, blockNumber: number) {
    const graphUrl = masterchefTheGraphUriFromUniswap[Networks.arbitrum]

    if (blockNumber === null) {
      const res = await fetchGraph(graphUrl, GET_LAST_BLOCK)
      blockNumber = res.data._meta.block.number
    }
    let timestamp = await getTimestampFromBlockNumber(blockNumber)
    let pairData = await this.getPairDataByBlockNumber(pairID, timestamp)
    while (pairData.data.pairDayDatas.length === 0) {
      const res = await fetchGraph(graphUrl, GET_LAST_BLOCK)
      const lastBlock = res.data._meta.block.number
      const lastBlockTimestamp = await getTimestampFromBlockNumber(lastBlock)
      timestamp = lastBlockTimestamp - 86400
      pairData = await this.getPairDataByBlockNumber(pairID, timestamp)
    }
    const pair = pairData.data.pairDayDatas[0]
   const poolAPR = calculateFeeApr(pair.volumeUSD, pair.reserveUSD, Networks.arbitrum)
    return { ...pair, poolAPR }
  },
  // Obtiene la información de los ultimos 5 dias de los pares que mostramos en el modal de farms mediante una query al grafo de spooky.
  async get5DaysPairsDayData (ids: string[], graphUrl: string) {
    const tokensInfoInTheQuery: boolean = true
    const query = PAIR_5DAY_DATA_BULK(ids)
    const pairs = await fetchGraph(graphUrl, query)
    const newPools = adaptPoolInfo(pairs.data.pairDayDatas)
    const result = { data: newPools, hasTokens: tokensInfoInTheQuery }
    return result
  },
  // Obtiene el precio actual en USD de Arbitrum. La fuente de informacion es el grafo.
  async getCurrentETHPrice (graphUrl: string) {
    const result = await fetchGraph(graphUrl, NATIVE_TOKEN_PRICE_CURRENT())
    return result?.data?.bundles[0]?.ethPrice
  },
  // Obtiene el precio actual de un token expresado en USD.
  async getTokenUSDPriceByTimestamp (address: string, timestamp: number) {
    const graphUrl = masterchefTheGraphUriFromUniswap[Networks.arbitrum]
    const query = TOKEN_PRICE_USD(address, timestamp)
    const result = await fetchGraph(graphUrl, query)
    return result
  },
  async getTokenDerivedETHByBlock (token: string, blockNumber: number, graphLastBlock: number) {
    const usefulBlockNumber: number = blockNumber > graphLastBlock ? graphLastBlock : blockNumber
    let address: string = null
    if (token.length === 42) {
      address = token
    } else {
      address = token !== '' ? tokenNameToAddress(token, Networks.arbitrum).toLowerCase() : null
    }
    if (usefulBlockNumber !== 0 && usefulBlockNumber !== null && usefulBlockNumber !== undefined) {
      const graphUrl = masterchefTheGraphUriFromUniswap[Networks.arbitrum]
      const query = TOKEN_DERIVED_ETH_BY_BLOCK_NUMBER(address, usefulBlockNumber)
      const tokenData = await fetchGraph(graphUrl, query)
      return tokenData
    }
    return undefined
  }
}

export default pairsAPIArbitrum
