import React, { useEffect, useState } from 'react'

const easeOutQuad = (t) => t * (2 - t)
const frames = 3600
const frameDuration = frames / 60

const Counter = ({ amount, decimals = 0 }: { amount: number, decimals?: number }) => {
  const [count, setCount] = useState(0)
  const countTo = parseInt('' + amount, 10)

  useEffect(() => {
    let frame = 0
    const totalFrames = Math.round(frames / frameDuration)
    const counter = setInterval(() => {
      frame++
      const progress = easeOutQuad(frame / totalFrames)
      setCount(+(countTo * progress).toFixed(decimals))

      if (frame === totalFrames) {
        clearInterval(counter)
        setCount(amount)
      }
    }, frameDuration)
  }, [amount, countTo, decimals])

  return <span className="stats-number">{count}</span>
}

export default Counter
