import web3 from "./web3";
import { tokensABI } from "src/data/ABIs";
import { AbiItem } from 'web3-utils'
import { getEtherNumberAmountFromAddress } from "src/contracts/TokensDecimalsHelpers";
import { Networks } from "./networkHelper";
import balanceTokens from '../data/tokensBalanceList.json'
import { tokenNameToAddress } from "src/components/Diagram/nodes/nodesLogsHelper";

// userInfoAccount is obtained from store (const userInfoAccount = useSelector((s: any) => s.user.account))

interface UserInfoAccountData {
  account: string
  balance: string
}

interface BalanceResult {
  balance: string
  id: string
  name: string
  address: string
}

export const getTokenBalanceList = async (userInfoAccount: UserInfoAccountData, networkId: string) => {
  const networkCoin: string = networkId === Networks.fantom ? "FTM" : "ETH"
  const userAddress: string = userInfoAccount.account
  const networkCoinBalanceInEthers: string = parseFloat(userInfoAccount.balance).toString()
  const weiBalance = []
  const balancesEthPromise = []

  const tokensList: any[] = balanceTokens.filter(t => t.network === networkId)
  for (const token of tokensList) {
    if (token.id === networkCoin) {
      weiBalance.push(null)
      continue
    }
    const tokenContract = new web3.eth.Contract(tokensABI as AbiItem[], token?.address)
    weiBalance.push(tokenContract.methods.balanceOf(userAddress).call())
  }

  const balancesWei = await Promise.all(weiBalance)

  for (let i = 0; i < balancesWei.length; i++) {
    if (tokensList[i].id === networkCoin) balancesEthPromise.push(networkCoinBalanceInEthers)
    else balancesEthPromise.push(getEtherNumberAmountFromAddress(tokensList[i].address, balancesWei[i]))
  }

  const balancesEth = await Promise.all(balancesEthPromise)

  const balancesResult: any[] = tokensList.map((token, i) => {
    const info: BalanceResult = {
      balance: balancesEth[i],
      id: token.id,
      name: token.name,
      address: token.address
    }
    return info
  })
  return balancesResult
}

export const getSingleTokenBalance = async (tokenSymbol: string, userInfoAccount: UserInfoAccountData, networkId: string): Promise<string> => {
  const isInBalanceList = balanceTokens.find(t => t.id === tokenSymbol)
  if (isInBalanceList) return undefined
  const userAddress: string = userInfoAccount.account
  const tokenAddress: string = tokenNameToAddress(tokenSymbol, networkId)
  const tokenContract = new web3.eth.Contract(tokensABI as AbiItem[], tokenAddress)
  const weiBalance: string = await tokenContract.methods.balanceOf(userAddress).call()
  const ethBalance: string = await getEtherNumberAmountFromAddress(tokenAddress, weiBalance)
  return ethBalance
}
