import React from "react"
import { CloseModal } from "@gluedigital/modal"
import { FormattedMessage } from "react-intl"
import NodeStateAndDate from "../NodeStateAndDate/NodeStateAndDate"
import { getTokenImage } from "src/api/farms/helpers"
import { useSelector } from "react-redux"
import { getNetworkParams } from "../../SelectWalletModal/helpers"
import { Networks } from "src/utils/networkHelper"

interface FarmModalLogHeaderProps {
  isExecutionStarted: boolean
  showingDate: string
  previousData: any
  executionSteps: number
}
export const FarmModalLogHeader = ({ isExecutionStarted, showingDate, previousData, executionSteps }: FarmModalLogHeaderProps) => {
  return (
    <header className="header-buttons">
      <CloseModal>
        <button>
          <span className="icon icon-close" />
          <span><FormattedMessage id="close" /></span>
        </button>
      </CloseModal>
      <h1><FormattedMessage id="farm-modal.title" /></h1>
      <NodeStateAndDate isNodeExecuted={isExecutionStarted} showingDate={showingDate} recipeDetails={previousData.recipeDetails} numberOfLogs={executionSteps} />
    </header>)
}

export const FarmSelectedInModalLog = ({ pair }: any) => {
  const networkId: string = useSelector((s: any) => (s.network.network))
  const networkParams = getNetworkParams(networkId)
  const scanName: string = networkId === Networks.arbitrum ? "arbscan.com" : "ftmscan.com"
  return (
    <div className="farm-chosen-div">
      <div className="div-tokens">
        <span className="div-token-images">
          <img className="logo img-token-farm" src={getTokenImage(pair?.token0)} />
          <img className="logo img-token-farm-right" src={getTokenImage(pair?.token1)} />
        </span>
        <span className="span-token-farm">{pair?.token0} - {pair?.token1}</span>
      </div>
      <div className="div-link-ftmscan" >
        <a className="link-ftmscan"
          href={`${networkParams.blockExplorerUrls}/address/${pair?.id.split('-')[0]}`}
          target="_blank"
          rel="noreferrer noopener">
          <FormattedMessage id="farm-modal.view-contract" /> <strong>{scanName}</strong></a>
      </div>
    </div>
  )
}

interface ProviderProps {
  provider: string
}
export const FarmModalLogProvider = ({ provider }: ProviderProps) => {
  return (
    <div className="div-provider">
      <span className="span-title-provider"><FormattedMessage id="farm-modal-log.provider" /> </span>
      <span className="span-provider">{provider}</span>
    </div>
  )
}
