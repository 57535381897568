import React from "react"
import { useHistory } from "react-router-dom"
import ErrorBoundary from "src/components/ErrorBoundary/ErrorBoundary"
import RecipeCard from "src/components/Landing/RecipeCard/RecipeCard"
import { formatRecipeTitle } from "src/routes/routesHelper"
import { Recipe } from "src/types"

const ExploreItem = ({ recipe, imageUrl }: { recipe: Recipe, imageUrl?: string }) => {
  const history = useHistory()
  const viewRecipeHandler = () => {
    const adaptTitle: string = formatRecipeTitle(recipe?.title)
    history.push("/recipe/" + recipe?.id + '/' + adaptTitle)
  }
  return (
    <ErrorBoundary fallback={<></>} >
      <div className="explore-item" onClick={viewRecipeHandler}>
        <RecipeCard imageUrl={imageUrl} type="strategy" recipe={recipe} />
      </div>
    </ErrorBoundary>
  )
}

export default ExploreItem
