import React from "react"
import { useSelector } from "react-redux"
import { tokenNameToAddress } from "src/components/Diagram/nodes/nodesLogsHelper"
import { getTokenDecimals } from "src/contracts/TokensDecimalsHelpers"
import usePromise from "src/hooks/usePromise"
import { useGetCorrectEtherAmount, useGetPricesForReaper, useReaperGetPricePerFullShare } from "src/hooks/useReaperFinance"
// import web3 from "src/utils/web3"

interface ActiveReaperLeftLogProps {
  reaperVaultAddress: string
  depositPriceUSD: string
  rfTokensDepositedEther: string
  depositTokenAmountEth: number
  tokenDepositedAddress: string
  tokenDepositedName: string
}
export const ActiveReaperLeftLog = ({ reaperVaultAddress, depositPriceUSD, rfTokensDepositedEther, tokenDepositedAddress, tokenDepositedName, depositTokenAmountEth }: ActiveReaperLeftLogProps) => {
  const networkId: string = useSelector((s: any) => (s.network.network))
  const DECIMAL_USD_ROUNDED: number = 3
  // const RFToken: string = "RFT"

  // Amount of BPT/ LP / TOKEN corresponding to each RF Token (shares)
  const pricePerShareAmountWei: number = useReaperGetPricePerFullShare(reaperVaultAddress)

  // We must select if we use the address of BPT/LP or if it is a token. The decimal number to convert to ethers depends on it
  const addressToUse: string = tokenDepositedName !== "BPT" && tokenDepositedName !== "LP" ? tokenNameToAddress(tokenDepositedName, networkId) : reaperVaultAddress
  const pricePerShareEth: string = useGetCorrectEtherAmount(addressToUse, pricePerShareAmountWei.toString())

  // This is the current amount of BPT/ LP / TOKEN --> It increases or decrease along the time
  const amountCurrent: number = Number(rfTokensDepositedEther) * Number(pricePerShareEth)

  // This is the unitary price for the current unit (LP / BPT / TOKEN)
  const priceCurrentUSD = useGetPricesForReaper(tokenDepositedName, tokenDepositedAddress, networkId)

  const priceCurrentEarnedUSD: number = Number(amountCurrent) * Number(priceCurrentUSD)

  const earnedPriceUSD: number = priceCurrentEarnedUSD - parseFloat(depositPriceUSD)
  const earnedTokenAmountEth: number = amountCurrent - Number(depositTokenAmountEth)

  // This is a format strategy to avoid negative values when the difference is too small. It is applied to USD value and LP value
  let earnedValueUSD: string = earnedPriceUSD.toFixed(DECIMAL_USD_ROUNDED)
  if (earnedValueUSD === "-0.000" || (Number(earnedValueUSD) > -0.001 && Number(earnedValueUSD) < 0)) earnedValueUSD = "0.000"

  let formatEarnedTokenAmount: string = earnedTokenAmountEth.toFixed(12)
  if (formatEarnedTokenAmount.startsWith("-0.00000000") || (Number(formatEarnedTokenAmount) > -0.000001 && Number(formatEarnedTokenAmount) < 0)) formatEarnedTokenAmount = "0.000000000000"

  const decimals: number = Number(usePromise(getTokenDecimals, addressToUse))
  return (
    <>
      {/* CURRENT VALUE */}
      <div className="logs-group-rows">
        <p className="main-text-logs">$ {(Number(priceCurrentEarnedUSD)).toFixed(DECIMAL_USD_ROUNDED)} </p>
        <p> {amountCurrent.toFixed(decimals > 15 ? 15 : decimals)} {tokenDepositedName}</p>
      </div>

      {/* EARNED VALUE */}
      <div className="logs-group-rows">
        <p className="main-text-logs">$ {(Number(earnedValueUSD)).toFixed(DECIMAL_USD_ROUNDED)} </p>
        {/* <p> {formatEarnedTokenAmount} {tokenDepositedName}</p> */}
      </div>
    </>
  )
}
