import { useEffect, useRef, useState } from "react"
import { ProfitCondition } from "src/routes/RecipeDiagram/helpers/types"
import { setInput } from './utils'

export const useTriggerProfitInput = (triggerData) => {
  const condition = triggerData ? triggerData.condition as ProfitCondition : {}
  const [inputRange, setInputRange] = useState([1, 1])
  const [inputConditionValue, setInputConditionValue] = useState(condition?.value || '')
  const inputCondition = useRef<HTMLInputElement>(null)

  const getValue = () => setInput(inputCondition, inputRange, setInputRange, inputConditionValue, setInputConditionValue)

  useEffect(() => {
    inputCondition.current.setSelectionRange(inputRange[0], inputRange[1])
  }, [inputConditionValue, inputRange])

  return {
    getValue,
    inputCondition,
    inputConditionValue
  }
}
