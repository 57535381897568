import React from "react";
import { FormattedMessage } from 'react-intl'
import { Link } from 'react-router-dom'

import "./NoRecipesMobile.sass"

const NoRecipesMobile = () => {
  return (
    <div id="no-recipes-mobile">
      <article>
        <h1>
          <FormattedMessage id="no-recipes.title" />
        </h1>
        <p>
          <FormattedMessage id="no-recipes.text" />
        </p>
        <Link className="button browse-recipe" to="./explore">
          <FormattedMessage id="browse-recipe" />
        </Link>
      </article>
    </div>
  )
}

export default NoRecipesMobile
