import React from "react"
import { NodeExecutionResult, WithdrawFarmData } from "src/types"
import { saneBigNumber } from "src/utils/bigNumberHelper"
import web3 from "src/utils/web3"

interface FinishedFarmInfoLeftLogProps {
  totalSLPAmount: number
  depositedSLPValueUSD: number
  depositedSLPEther: number
  withdrawEvent: NodeExecutionResult
}

const FinishedFarmInfoLeftLog = ({ withdrawEvent, totalSLPAmount, depositedSLPValueUSD, depositedSLPEther }: FinishedFarmInfoLeftLogProps) => {
  const withdrawFarmData: WithdrawFarmData = withdrawEvent?.extraData?.withdrawFarmData
  const lpWithdrawnEther: number = Number(web3.utils.fromWei(saneBigNumber(Number(withdrawFarmData.lpAmountWithdrawn)).toString(), 'ether'))

  const totalLPUSDValueAtOtherMoment: number = parseFloat(withdrawFarmData.oneLPUSDValue) * totalSLPAmount
  const totalUSD: number = totalLPUSDValueAtOtherMoment + parseFloat(withdrawFarmData.rewardTokenUSDValue)
  let earnedUSDTotal: string = (totalUSD - depositedSLPValueUSD).toFixed(3)
  if (earnedUSDTotal === "-0.000") earnedUSDTotal = "0.000"
  let earnedLPTotal: string = (lpWithdrawnEther - depositedSLPEther).toFixed(12)
  if (earnedLPTotal.startsWith("-0.000000000")) earnedLPTotal = "0.000000000000"

  return (<>
    {/* FINAL VALUE */}
    <div className="logs-group-rows">
      <p className="main-text-logs">$ {totalLPUSDValueAtOtherMoment.toFixed(3)} </p>
      <p>{lpWithdrawnEther.toFixed(12)}  SLPs</p>
    </div>
    {/* EARNED */}
    <div className="logs-group-rows">
      <p className="main-text-logs">$ {earnedUSDTotal}</p>
      <p>{earnedLPTotal} SLPs</p>
    </div>
  </>)
}

export default FinishedFarmInfoLeftLog
