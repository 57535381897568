import React, { Suspense } from "react"
import { FormattedMessage } from 'react-intl'
import { usePeriodicLiquidatedRecipes } from "src/api/recipes"
import Loading from "src/components/common/Loading/Loading"
import RecipeList from "src/components/RecipeList/RecipeList"
import NoRecipes from "../NoRecipes/NoRecipes"

const LiquidatedRecipes = () => {
  const recipes = usePeriodicLiquidatedRecipes()
  if (recipes.length === 0) return <NoRecipes />

  return (
    <section id="liquidated-recipes" className="recipes-section">
      <h1>
        <FormattedMessage id="recipes-section.liquidated-recipes.title" values={{ n: recipes.length }} />
      </h1>
      <RecipeList recipes={recipes} type={"finished"} />
    </section>
  );
};

const LiquidatedRecipesWrapper = (props) => (
  <Suspense fallback={<Loading />}>
    <LiquidatedRecipes {...props} />
  </Suspense>
)

export default LiquidatedRecipesWrapper
