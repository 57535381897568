import React from 'react'
import { PairDayData } from 'src/api/farms/types'
import { useGetLpPriceFromApi, useGetTokenPriceUSDFromApi } from 'src/api/recipes'
import { useFarmRewardsAmountFromStrategy } from 'src/hooks/useFarmsLogs'
import { LPTokenWithPrice } from 'src/routes/RecipeDiagram/helpers/types'
interface EarnedFarmLeftLogProps {
  lpUSDValueAtDeposit: number
  lpDeposited: number
  totalLpAmount: number
  masterchefInfo: PairDayData
  nodeId?: string
}
export const EarnedFarmLeftLog = (props: EarnedFarmLeftLogProps) => {
  const { lpUSDValueAtDeposit, lpDeposited, totalLpAmount, masterchefInfo, nodeId } = props
  const lpPriceActiveNode: LPTokenWithPrice = useGetLpPriceFromApi(masterchefInfo?.pairAddress, totalLpAmount.toString())
  const tokenRewardPriceUSD: string = useGetTokenPriceUSDFromApi(masterchefInfo.tokenReward)

  const rewardToken = 'BOO'
  const amountBOOWei: number[] = useFarmRewardsAmountFromStrategy(nodeId)
  const amountBOOEther: number = amountBOOWei[0] / 10 ** 18

  const totalLPUSDValueAtOtherMoment: number = parseFloat((lpPriceActiveNode?.priceUSD)) * totalLpAmount
  const booUSDValueAtOtherMoment: number = parseFloat(tokenRewardPriceUSD) * amountBOOEther
  const totalUSD: number = totalLPUSDValueAtOtherMoment + booUSDValueAtOtherMoment
  let earnedUSDTotal: string = (totalUSD - lpUSDValueAtDeposit).toFixed(3)
  if (earnedUSDTotal === "-0.000") earnedUSDTotal = "0.000"

  return (
    <>
         {/* CURRENT VALUE */}
        <div className="logs-group-rows">
          <p className="main-text-logs">$ {totalUSD.toFixed(3)} </p>
          <p>{totalLpAmount.toFixed(12)}  LPs<span className="left-logs-usd-price">$ {(totalLPUSDValueAtOtherMoment).toFixed(3)}</span></p>
          <p>{amountBOOEther.toFixed(10)} {rewardToken} <span className="left-logs-usd-price">$ {(booUSDValueAtOtherMoment).toFixed(3)}</span></p>
        </div>
        {/* EARNED */}
      <div className="logs-group-rows">
        <p className="main-text-logs">$ {earnedUSDTotal}</p>
        <p>{(totalLpAmount - lpDeposited).toFixed(12)} LPs</p>
      </div>
    </>
  )
}

interface LoadingEarnedLeftLogProps {
  recipeStatus: string
}

export const LoadingEarnedLeftLog = ({ recipeStatus }: LoadingEarnedLeftLogProps) => {
  return (
    <>
      {recipeStatus === "active"
        ?
        <div className="logs-group-rows">
          <p>Loading current value</p>
          <div className="left-empty-position"></div>
          <div className="left-empty-position"></div>
        </div>
        :
        <div className="logs-group-rows">
          <p>Loading final value</p>
          <div className="left-empty-position"></div>
        </div>}

      <div className="logs-group-rows">
        <p>Loading earned</p>
        <div className="left-empty-position"></div>
      </div>
    </>
  )
}
