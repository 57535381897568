import { Connection } from "react-flow-renderer";
import { updateComboTrigger } from "./editNodeHelpers";
import { ComboTriggerElement, EdgeElement, NodeElement } from "./types";

const getOutputCoin = (connection: Connection, sourceNode: NodeElement) => {
  if (sourceNode.type === 'sendToWalletNode') {
    console.error('Send to wallet node detected as the source of a connection')
    throw new Error()
  }
  if (sourceNode.type === "splitNode") {
    if (connection.sourceHandle === 'split-output-1') {
      return sourceNode.data.firstCoin
    } else {
      return sourceNode.data.secondCoin;
    }
  }
  if (sourceNode.type === 'farmNode') {
    return sourceNode.data.outputMain;
  }
  return sourceNode.data.outputCoin;
}

export const modifyCurrentElementsBeforeAddingEdge = (nodes: NodeElement[], edges: EdgeElement[], connection: Connection): NodeElement[] => {
  let updatedNodes: any[] = [...nodes]
  const sourceNode = updatedNodes.find((el) => el.id === connection.source);
  const targetNode = updatedNodes.find((el) => el.id === connection.target);

  const newSource = { ...sourceNode }
  if (connection.sourceHandle === "split-output-2") {
    newSource.data = { ...sourceNode.data, secondNextConnected: true }
  } else {
    newSource.data = { ...sourceNode.data, nextConnected: true }
  }
  updatedNodes = [...updatedNodes.filter((node) => node.id !== sourceNode.id), newSource]
  try {
    const previousNodeOutputCoin: string = getOutputCoin(connection, sourceNode)

    if (connection.targetHandle === "deposit-input") {
      const newTarget = {
        ...targetNode,
        data: {
          ...targetNode.data,
          inputCoin: previousNodeOutputCoin,
          outputCoin: previousNodeOutputCoin
        },
      };
      updatedNodes = [...updatedNodes.filter((node) => node.id !== targetNode.id), newTarget]

      const arrowFarmCombo = edges.find((el) => el.source === newTarget.id)
      const nextComboNode = updatedNodes.find((el) => arrowFarmCombo.target === el.id)
      if (nextComboNode) {
        updatedNodes = updateComboTrigger(updatedNodes, edges, nextComboNode, previousNodeOutputCoin)
      }

      return updatedNodes;
    }

    if (connection.targetHandle === "farm-input") {
      const newTarget = {
        ...targetNode,
        data: {
          ...targetNode.data,
          inputCoin: previousNodeOutputCoin,
          outputMain: previousNodeOutputCoin,
          outputReward: previousNodeOutputCoin
        },
      };
      updatedNodes = [...updatedNodes.filter((node) => node.id !== targetNode.id), newTarget]

      const arrowFarmCombo: EdgeElement = edges.find((el) => el.source === newTarget.id)
      const nextComboNode: ComboTriggerElement = updatedNodes.find((el) => arrowFarmCombo.target === el.id)
      if (nextComboNode) {
        updatedNodes = updateComboTrigger(updatedNodes, edges, nextComboNode, previousNodeOutputCoin)
      }
      return updatedNodes;
    }

    if (connection.targetHandle === "nested-strategies-input") {
      const newTarget = {
        ...targetNode,
        data: {
          ...targetNode.data,
          inputCoin: previousNodeOutputCoin,
          outputCoin: previousNodeOutputCoin,
        },
      };
      updatedNodes = [...updatedNodes.filter((node) => node.id !== targetNode.id), newTarget]

      const arrowFarmCombo: EdgeElement = edges.find((el) => el.source === newTarget.id)
      const nextComboNode: ComboTriggerElement = updatedNodes.find((el) => arrowFarmCombo.target === el.id)
      if (nextComboNode) {
        updatedNodes = updateComboTrigger(updatedNodes, edges, nextComboNode, previousNodeOutputCoin)
      }
      return updatedNodes;
    }

    if (connection.targetHandle === "short-long-input") {
      const newTarget = {
        ...targetNode,
        data: {
          ...targetNode.data,
          inputCoin: previousNodeOutputCoin,
          outputCoin: previousNodeOutputCoin,
        },
      };
      updatedNodes = [...updatedNodes.filter((node) => node.id !== targetNode.id), newTarget]

      const arrowFarmCombo: EdgeElement = edges.find((el) => el.source === newTarget.id)
      const nextComboNode: ComboTriggerElement = updatedNodes.find((el) => arrowFarmCombo.target === el.id)
      if (nextComboNode) {
        updatedNodes = updateComboTrigger(updatedNodes, edges, nextComboNode, previousNodeOutputCoin)
      }
      return updatedNodes;
    }

    if (connection.targetHandle === "split-input") {
      const newTarget = {
        ...targetNode,
        data: {
          ...targetNode.data,
          inputCoin: previousNodeOutputCoin,
        },
      };
      updatedNodes = [...updatedNodes.filter((node) => node.id !== targetNode.id), newTarget]

      return updatedNodes;
    }

    if (connection.targetHandle === "combo-input") {
      updatedNodes = updateComboTrigger(updatedNodes, edges, targetNode, previousNodeOutputCoin)

      return updatedNodes;
    }

    if (connection.targetHandle === 'liquidate-input' || connection.targetHandle === 'send-to-wallet-input' || connection.targetHandle === 'swap-input') {
      const newTarget = {
        ...targetNode,
        data: {
          ...targetNode.data,
          inputCoin: previousNodeOutputCoin,
        },
      };

      updatedNodes = [...updatedNodes.filter((node) => node.id !== targetNode.id), newTarget]
      return updatedNodes;
    }
  } catch (e) {
    console.error('Error detected on connection: ', e)
    throw new Error(e)
  }
};
