import React from "react"
import { FormattedMessage } from "react-intl"

const ManualTriggerInfo = () => {
  return <div className="manual-trigger-info-container">
    <span className="icon icon-exclamation" />
    <p className="manual-trigger-info-body"><FormattedMessage id={'combo-trigger-modal.manual-info'} /></p>
    <div className="manual-trigger-info-navigation">
      <span>
        <FormattedMessage id={'combo-trigger-modal.manual-navigation'} />
      </span>
    </div>
  </div>
}

export default ManualTriggerInfo
