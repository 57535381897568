import React from 'react'
import { FormattedMessage } from 'react-intl'

import './Earned.sass'

const Earned = ({ earned }: { earned: string}) => {
  return (
    <section className="recipe-card-earned">
      <h2><FormattedMessage id="nested-modal-log.earned" /></h2>
      <div className="item item-earned">
        { earned } $
      </div>
    </section>
  )
}

export default Earned
