import React from "react"
import { FormattedMessage } from "react-intl"
import { rewardsSushiData } from "src/api/farms/types"
import { useFarmRewardsAmountFromStrategy } from "src/hooks/useFarmsLogs"
import { usePrices } from "src/hooks/usePrices"
import web3 from "src/utils/web3"
interface ActiveFarmModalDataArbitrumProps {
  totalSLPAmount: number
  myFarm: any
  nodeId: string
  depositedSLPValueUSD: number
  depositSLPAmount: number
}
const ActiveFarmModalDataArbitrum = ({ totalSLPAmount, myFarm, nodeId, depositedSLPValueUSD, depositSLPAmount }: ActiveFarmModalDataArbitrumProps) => {
  const DECIMAL_USD_ROUNDED: number = 3
  const DECIMAL_TOKEN_ROUNDED: number = 10

  const lpPriceActiveNode: number = Number(myFarm?.reserveUSD) / Number(web3.utils.fromWei(myFarm?.totalSupply))
  const totalSLPUSDValue: number = lpPriceActiveNode * totalSLPAmount
  const amountRewardsWei: number[] = useFarmRewardsAmountFromStrategy(nodeId)
  const rewardsUSDValue: number[] = []
  let rewardTokensNames: string[] = []
  let amountRewardsEther: number[] = []
  let totalRewardUSDValue: number
  const rewards: rewardsSushiData[] = myFarm?.rewards
  if (rewards.length === 1) {
    rewardTokensNames.push(rewards[0].name)
    amountRewardsEther.push(amountRewardsWei[0] / 10 ** 18)
  } else {
    if (rewards[1].name === "SUSHI") {
      rewardTokensNames = rewards.map(r => r.name)
      amountRewardsEther = amountRewardsWei.map(amount => amount / (10 ** 18))
    } else { // from useFarmRewardsAmountFromStrategy SUSHI reward is always the second in case there are two rewards
      rewardTokensNames.push(rewards[1].name)
      amountRewardsEther.push(amountRewardsWei[0] / 10 ** 18)
      rewardTokensNames.push(rewards[0].name)
      amountRewardsEther.push(amountRewardsWei[1] / 10 ** 18)
    }
  }

  const reward0PriceUSD: string = (usePrices(rewards[0].name))
  const reward1PriceUSD: string = usePrices(rewards[1]?.name)
  rewardsUSDValue.push(parseFloat(reward0PriceUSD) * amountRewardsEther[0])

  if (reward1PriceUSD && reward1PriceUSD !== '0') {
    rewardsUSDValue.push(parseFloat(reward1PriceUSD) * amountRewardsEther[1])
    totalRewardUSDValue = rewardsUSDValue[1] + rewardsUSDValue[0]
  } else totalRewardUSDValue = rewardsUSDValue[0]

  const totalUSD: number = totalSLPUSDValue + totalRewardUSDValue

  let earnedUSDTotal: string = (totalUSD - depositedSLPValueUSD).toFixed(3)
  if (earnedUSDTotal === "-0.000") earnedUSDTotal = "0.000"

  const firstRow: JSX.Element = <strong className="span-coin">${totalUSD.toFixed(DECIMAL_USD_ROUNDED)} </strong>

  const secondRow: JSX.Element = <div className="div-2column-lps">
    <span className="lp-amount-row first-column">{totalSLPAmount.toFixed(DECIMAL_TOKEN_ROUNDED)} <strong>SLPs</strong></span>
    <span className="label-lp-price second-column">${totalSLPUSDValue.toFixed(DECIMAL_USD_ROUNDED)} </span>
  </div>

  return (
    <>
      <div id="farm-log-field">
        <h3><FormattedMessage id="farm-modal-log.current-value" /></h3>
        {firstRow}
        <> {secondRow} </>
        <div className="div-2column-lps">
          {rewards.map((r, i) =>
            <>
              <span className="lp-amount-row first-column">{amountRewardsEther[i].toFixed(DECIMAL_TOKEN_ROUNDED)} <strong>{r.name}</strong> </span>
              <span className="label-lp-price second-column">$ {rewardsUSDValue[i].toFixed(DECIMAL_USD_ROUNDED)}</span>
            </>
          )}
        </div>
      </div>
      <div id="farm-log-field">
        <h3><FormattedMessage id="farm-modal-log.earned" /></h3>
        <strong className="span-coin">$ {earnedUSDTotal} </strong>
        <div className="div-2column-lps">
          <span className="lp-amount-row first-column">{(totalSLPAmount - depositSLPAmount).toFixed(DECIMAL_TOKEN_ROUNDED)} <strong>SLPs</strong></span>
        </div>
      </div>
    </>
  )
}

export default ActiveFarmModalDataArbitrum
