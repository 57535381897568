import React from "react"
import { CloseModal } from "@gluedigital/modal"
import { ModalErrorBoundary } from "src/components/ErrorBoundary/ErrorBoundary"
import { FormattedMessage } from "react-intl"
import { NestedStrategiesModalData } from "src/routes/RecipeDiagram/helpers/types"
import { RecipeDetails, RecipeExecutionLog } from "src/types"
import { getNestedStrategyImage, getTokenImage } from "src/api/farms/helpers"
import { YearnSummaryVaultsData } from "src/api/yearnVaults/yearnTypes"
import Loading from "src/components/common/Loading/Loading"
import { ReaperSummaryVaultsData } from "src/api/reaperFinance/reaperTypes"
import { useSelector } from "react-redux"
import { getNetworkParams } from "../../SelectWalletModal/helpers"

import './NestedStrategiesModalLog.sass'

interface NestedStrategiesModalLogProps {
  previousData: {
    data: NestedStrategiesModalData
    id: string
    recipeLogs: RecipeExecutionLog[]
    recipeDetails: RecipeDetails
  }
}

export const LoadingNestedStrategiesModalLog = ({ previousData }: NestedStrategiesModalLogProps) => {
  const { data } = previousData
  const oldVaultData: YearnSummaryVaultsData | ReaperSummaryVaultsData = data?.yearn ? data.yearn : data.reaper
  const networkId: string = useSelector((s: any) => (s.network.network))
  const networkParams = getNetworkParams(networkId)

  return (
    <ModalErrorBoundary>
      <div className="nested-strategies-modal modal-content" >
        <div className="modal-wrapper">
          <header className="header-buttons">
            <CloseModal>
              <button>
                <span className="icon icon-close" />
                <span><FormattedMessage id="close" /></span>
              </button>
            </CloseModal>
            <h1><FormattedMessage id="nested-strategies-modal.title" /></h1>
          </header>
          <div className="nested-strategies-modal-wrapper">
            <main id="main-nested-strategies-modal-log">
              <div className="farm-chosen-div">
                {oldVaultData.provider === "Yearn" && <div className="div-tokens">
                  <span className="div-token-images">
                    <img className="logo img-token-farm" src={getNestedStrategyImage(oldVaultData.symbol)} />
                  </span>
                  <span className="span-token-farm">{oldVaultData.name}</span>
                </div>}
                {oldVaultData.provider === "Reaper" && <div className="div-tokens">
                  <span className="div-token-images">
                  {oldVaultData.token.map(token => <img className="logo" key={token.name} src={getTokenImage(token.name)} />)}
                  </span>
                  <span className="span-token-farm">{oldVaultData.name}</span>
                </div>}
                <div className="div-link-ftmscan" >
                  <a className="link-ftmscan"
                    href={`${networkParams.blockExplorerUrls}/address/${oldVaultData.address}`}
                    target="_blank"
                    rel="noreferrer noopener">
                    <FormattedMessage id="farm-modal.view-contract" /> <strong>ftmscan.com</strong></a>
                </div>
              </div>
              <section className="nested-strategies-modal-log-data">
                <div id="nested-strategy-data-1">
                  <h3><FormattedMessage id="nested-modal-log.deposited" /></h3>
                  <Loading />
                </div>

                <div id="nested-strategy-data-2">
                  <h3><FormattedMessage id={"nested-modal-log.current-value"} /></h3>
                  <Loading />
                </div>

                <div id="nested-strategy-data-3">
                  <h3><FormattedMessage id={"nested-modal-log.earned"} /></h3>
                  <Loading />
                </div>

                <div id="nested-strategy-data-4">
                  <h3><FormattedMessage id={"nested-strategies-modal.APY"} /></h3>
                  <Loading />
                </div>

                <div id="nested-strategy-data-5">
                  <h3><FormattedMessage id={"farm-modal-log.liquidity"} /></h3>
                  <Loading />
                </div>
              </section>
            </main>
            {/* <div className="div-provider">
              <span className="span-title-provider"><FormattedMessage id="farm-modal-log.provider" /> </span>
              <span className="span-provider">YEARN</span>
            </div> */}
          </div>
        </div>
      </div>

    </ModalErrorBoundary>
  )
}
