import React, { Suspense } from 'react'
import { useParams, Switch, Route } from 'react-router-dom'
import { useRecipeDetails } from 'src/api/recipes'
import MobileDetailsHeader from './MobileDetailsHeader/MobileDetailsHeader'
import Loading from 'src/components/common/Loading/Loading'
import LogFlowSelector from '../components/LogFlowSelector/LogFlowSelector'
import ViewLogs from '../components/ViewLogs/ViewLogs'
import ViewFlow from '../components/ViewFlow/ViewFlow'

import './MobileDetails.sass'
import ErrorBoundary from 'src/components/ErrorBoundary/ErrorBoundary'

const MobileDetails = () => {
  const { id } = useParams()
  const recipeDetails = useRecipeDetails(+id)
  const fixNodes = () => {
    if (!recipeDetails?.code) return []
    return recipeDetails?.code.filter(e => e.type !== 'myCustomEdge')
  }

  return (
    <Switch>
      <Route path="/mobile/details/:id/log">
        <ViewLogs nodes={fixNodes()} recipeDetails={recipeDetails} />
      </Route>
      <Route path="/mobile/details/:id/flow">
        <ViewFlow nodes={recipeDetails?.code} recipeDetails={recipeDetails} />
      </Route>
    </Switch>
  )
}

const MobileDetailsWrapper = () => {
  return (
    <div id="mobile-details" className="page">
      <Suspense fallback={<div />}>
        <MobileDetailsHeader />
      </Suspense>
      <LogFlowSelector />
      <ErrorBoundary fallback="error">
        <Suspense fallback={<Loading />}>
          <MobileDetails />
        </Suspense>
      </ErrorBoundary>
    </div>
  )
}

export default MobileDetailsWrapper
