import web3 from "src/utils/web3"
import { tokensABI } from "src/data/ABIs"
import { getTokenAddress } from '../routes/RecipeDiagram/helpers/nodeHelpers'
import { BigNumber } from 'ethers'
import { AbiItem } from 'web3-utils'

export const getWeiNumberAmountDependOnDecimals = async (tokenName: string, amount: number, networkId: string): Promise<string> => {
  const token: any = new web3.eth.Contract(tokensABI as any, getTokenAddress(tokenName, networkId))
  const decimals: string = await token.methods.decimals().call()
  let convertedAmount: any
  if (decimals !== '18') {
    const FINAL_DECIMALS = 10 ** 6 // 6 is the number of decimals that we permit use in the addFunds Modal
    const valueDifference: BigNumber = BigNumber.from(10).pow(decimals)
    let bnAmount: BigNumber;
    if (!Number.isInteger(amount)) {
      bnAmount = BigNumber.from(amount * FINAL_DECIMALS)
    } else {
      bnAmount = BigNumber.from(amount)
      bnAmount = bnAmount.mul(FINAL_DECIMALS)
    }
    convertedAmount = BigNumber.from(bnAmount.mul(valueDifference))
    convertedAmount = BigNumber.from(convertedAmount.div(FINAL_DECIMALS))
  } else {
    const stringAmount: string = numberScientificNotationToString(amount)
    convertedAmount = web3.utils.toWei(stringAmount, 'ether')
  }
  return convertedAmount.toString()
}

export const getEtherNumberAmountFromAddress = async (tokenAddress: string, amount: string): Promise<string> => {
  if (!amount || !tokenAddress) return '0'
  const token: any = new web3.eth.Contract(tokensABI as any, tokenAddress);
  const decimals: string = await token.methods.decimals().call()
  const FINAL_DECIMALS = 10 ** 6
  let convertedAmount: any
  if (decimals !== '18') {
    const valueDifference: any = BigNumber.from(10).pow(decimals)
    convertedAmount = BigNumber.from(amount).mul(FINAL_DECIMALS).div(valueDifference)
    convertedAmount = Number(convertedAmount) / FINAL_DECIMALS
    return parseFloat(convertedAmount).toFixed(8)
  }
  const bnAmount: BigNumber = BigNumber.from(amount)
  convertedAmount = web3.utils.fromWei(bnAmount.toString(), 'ether')
  return (parseFloat(convertedAmount)).toFixed(15)
}

export const numberScientificNotationToString = (amount: number): string => {
  if (Math.abs(amount) < 1.0) {
    const e: number = parseInt(amount.toString().split('e-')[1]);
    if (e) {
      let correctAmount: string = String(amount * Math.pow(10, e - 1));
      correctAmount = '0.' + (new Array(e)).join('0') + correctAmount.toString().substring(2);
      return correctAmount
    } else {
      return String(amount)
    }
  } else {
    let e: number = parseInt(amount.toString().split('+')[1])
    if (e > 20) {
      e -= 20;
      let correctAmount: string = String(amount / Math.pow(10, e))
      correctAmount += (new Array(e + 1)).join('0');
      return correctAmount
    }
    return String(amount)
  }
}

export const getEtherStringAmountFromAddressFixed15 = async (tokenAddress: string, amount: string): Promise<string> => {
  if (!amount) return '0'
  const token: any = new web3.eth.Contract(tokensABI as any, tokenAddress)
  const decimals: string = await token.methods.decimals().call()
  const FINAL_DECIMALS = 10 ** Number(decimals)
  let convertedAmount: any
  if (decimals !== '18') {
    const valueDifference: any = BigNumber.from(10).pow(decimals)
    convertedAmount = BigNumber.from(amount).mul(FINAL_DECIMALS).div(valueDifference)
    convertedAmount = Number(convertedAmount) / FINAL_DECIMALS
    return convertedAmount.toString()
  }
  convertedAmount = web3.utils.fromWei(amount.toString(), 'ether')
  return (parseFloat(convertedAmount)).toFixed(15)
}

// In this case we round to 8 decimals only because in the addFunds we have as limit 8 decimals.
export const getInputAmountStringFromAddressDecimals = async (tokenAddress: string, amount: string): Promise<string> => {
  if (!amount) return '0'
  const token: any = new web3.eth.Contract(tokensABI as any, tokenAddress)
  const decimals: string = await token.methods.decimals().call()
  const FINAL_DECIMALS = 10 ** 6
  let convertedAmount: any
  if (decimals !== '18') {
    const valueDifference: any = BigNumber.from(10).pow(decimals)
    convertedAmount = BigNumber.from(amount).mul(FINAL_DECIMALS).div(valueDifference)
    convertedAmount = Number(convertedAmount) / FINAL_DECIMALS
  } else {
    convertedAmount = web3.utils.fromWei(amount.toString(), 'ether')
  }
  return (parseFloat(convertedAmount)).toFixed(8)
}

export const getTokenDecimals = async (tokenAddress: string): Promise<string> => {
  if (!tokenAddress) return null
  const token = new web3.eth.Contract(tokensABI as AbiItem[], tokenAddress)
  const decimals: string = await token.methods.decimals().call()
  return decimals
}
