import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'
import { useRecipeDetailsLive, useRecipeLogsLive } from 'src/context/SocketContext'
import { RecipeDetails, RecipeExecutionLog } from 'src/types'
import DepositStepMessages from './DepositStepItem/DepositStepMessages'
import { useModal } from '@gluedigital/modal'

import 'src/routes/Mobile/components/modals/WithdrawModal/WithdrawModal'
import './DepositStepList.sass'

interface DepositListStepProps {
  step: number
  setStep: (step) => void
  failStep: number
  setFailStep: (step) => void
  setQuoted: (q: boolean) => void
  addFundsCoin: string
  approvedClicked: boolean
  executionClicked: boolean
}
const DepositStepList = ({ step, failStep, setFailStep, setStep, addFundsCoin, approvedClicked, setQuoted, executionClicked }: DepositListStepProps) => {
  const modal = useModal();
  const [transactionHash, setTransactionHash] = useState<string>(undefined)
  const [retryCount, setRetryCount] = useState<number>(undefined)
  const { id } = useParams()
  const liveRecipeLogs: RecipeExecutionLog[] = useRecipeLogsLive(id.toString())
  const recipeDetails: RecipeDetails = useRecipeDetailsLive(id?.toString())
  const recipeStatus = recipeDetails?.status

  useEffect(() => {
    if (liveRecipeLogs.length > 0) {
      const lastLiveLog: RecipeExecutionLog = liveRecipeLogs[liveRecipeLogs.length - 1]
      if (lastLiveLog?.logtype === 'fail') {
        setRetryCount(lastLiveLog?.retrycount)
        setFailStep(4)
      } else if (lastLiveLog?.logtype === "execution") {
        if (lastLiveLog?.trans.startsWith("0x")) {
          setTransactionHash(lastLiveLog?.trans)
          setStep(4)
        }
      }
    }
  }, [liveRecipeLogs, setFailStep, setStep])

  const recoverFunds = async (e) => {
    e.preventDefault()
    modal.show('mobile-withdraw-modal', { recipe: recipeDetails })
  }

  return (
    <div className="deposit-step-list">
      <h1><FormattedMessage id="steps-list-container.title" /></h1>
      {step > 0 &&
        <DepositStepMessages
          executionClicked={executionClicked}
          approvedClicked={approvedClicked}
          recipeStatus={recipeStatus}
          numberStepsDone={step}
          failStep={failStep}
          setFailStep={setFailStep}
          txHash={transactionHash}
          retryCount={retryCount}
          addFundsCoin={addFundsCoin}
          setQuoted={setQuoted}
        />
      }
      {
        recipeStatus === "failed" &&
        <div className="recover-funds-container">
          <button
            onClick={(e) => recoverFunds(e) as any}
            className="recover-funds"
          > <FormattedMessage id="deposit-step-item.recover-funds" />
          </button>
        </div >
      }
    </div>)
}

export default DepositStepList
