import React from 'react'
import { FormattedMessage } from 'react-intl'

import './AuditBarAdvice.sass'

const AuditBarAdvice = () => {
  return (
    <div id="audit-bar-advice">
      <FormattedMessage id="audit-bar-advice.text" />
    </div>
  )
}

export default AuditBarAdvice
