import React from "react"
import { FormattedMessage } from "react-intl"
import { getAmountPercentage } from '../../Diagram/nodes/nodesLogsHelper'
import 'src/routes/RecipeDiagram/SideBarViewLogs/SidebarViewLogs.sass'

interface ExtraDataSideBarLogsProps {
  events: any
  tokenInput: string
  tokenOutput: string
  inputAmount: number
  inputFeeAmount: number
  outputAmount: number
}

const ExtraDataSideBarLogs = ({ tokenInput, tokenOutput, inputAmount, inputFeeAmount, outputAmount, events }: ExtraDataSideBarLogsProps) => {
  const DECIMALS_FOR_TOKEN_AMOUNT: number = 10
  let priceInfo: string
  if (events.nodeType === 'splitNode') {
    priceInfo = (inputAmount / outputAmount).toFixed(DECIMALS_FOR_TOKEN_AMOUNT)
  } else {
    priceInfo = (inputAmount / outputAmount).toFixed(DECIMALS_FOR_TOKEN_AMOUNT)
  }


  return (
    <div className="div-extra-info">
      <div className="first-column">
        <p><FormattedMessage id="view-logs-price" />  </p>
        {/* <p><FormattedMessage id="view-logs-slippage" />   </p> */}
        {/* <p><FormattedMessage id="view-logs-price-impact" />  </p> */}
        <p><FormattedMessage id="view-logs-fee" />  </p>
      </div>
      <div className="second-column">
        <p> {` 1 ${tokenInput} = ${priceInfo || 'Loading...'} ${tokenOutput} `} </p>
        {/* <p> 1% </p> */}
        {/* <p>0.01% </p> */}
        <p> {` 0.20% = ${getAmountPercentage(inputFeeAmount, 0.20)} ${tokenInput} `} </p>
      </div>
    </div>
  )
}

export default ExtraDataSideBarLogs
