import React from "react"
import { FormattedMessage } from "react-intl"
import { useSelector } from "react-redux"
import { tokenNameToAddress } from "src/components/Diagram/nodes/nodesLogsHelper"
import { useLiquidationPrice, usePerpetualsCurrentPrice } from "src/hooks/usePerpetuals"
import { NodeExecutionResult } from "src/types"
import { nodesLogsNumber } from "src/constants"
import { WithdrawShortLongDataInfo } from "src/api/mummyFinance/mummyTypes"
import { Networks } from "src/utils/networkHelper"
import { ShortLongModalData } from "src/routes/RecipeDiagram/helpers/types"

interface MarkAndLiquidationPriceProps {
  logsEvents: NodeExecutionResult[]
  operationCoin?: string
  data?: ShortLongModalData
  firstTypePerpContractAddress?: string
}

export const MarkPricePerpetualModalLog = ({ logsEvents, operationCoin }: MarkAndLiquidationPriceProps) => {
  const { ACTIVE_NODE, SHORT_LONG_FINISHED_NODE, NO_EXECUTED_NODE } = nodesLogsNumber
  const networkId: string = useSelector((s: any) => (s.network.network))
  const currentPriceOpCoin: number = usePerpetualsCurrentPrice(tokenNameToAddress(operationCoin, networkId))
  const withdrawEvent: NodeExecutionResult = logsEvents.find(ev => ev.functionName === "withdrawFromShortLong")
  let opCoinPriceWhenWithdraw
  if (withdrawEvent) {
    const withdrawShortLongData: WithdrawShortLongDataInfo = withdrawEvent?.extraData?.withdrawShortLongData
    opCoinPriceWhenWithdraw = withdrawShortLongData.exitOpCoinPriceUSD
  }
  return (
    <article className="lower-row-item">
      <span className="title-usd-prices"><FormattedMessage id="logs-short-long.mark-price" /></span>
      {logsEvents.length === NO_EXECUTED_NODE && <span className="usd-values-prices">{!isNaN(currentPriceOpCoin) ? `$ ${currentPriceOpCoin?.toFixed(3)}` : <FormattedMessage id="waiting-for-data" />}</span>}
      {logsEvents.length === ACTIVE_NODE && <span className="usd-values-prices">{!isNaN(currentPriceOpCoin) ? `$ ${currentPriceOpCoin?.toFixed(3)}` : <FormattedMessage id="waiting-for-data" />}</span>}
      {logsEvents.length === SHORT_LONG_FINISHED_NODE && <span className="usd-values-prices">$ {parseFloat(opCoinPriceWhenWithdraw).toFixed(3)}</span>}
    </article>
  )
}

export const LiqPricePerpetualModalLog = ({ logsEvents, data, firstTypePerpContractAddress }: MarkAndLiquidationPriceProps) => {
  const { ACTIVE_NODE, SHORT_LONG_FINISHED_NODE, NO_EXECUTED_NODE } = nodesLogsNumber
  const networkId: string = useSelector((s: any) => (s.network.network))
  const isLong: boolean = data.operation === "long"
  const tokenForCollateralInShortByNetwork: string = networkId === Networks.arbitrum ? "USDT" : "USDC_AXL"
  const collateralTokenAddress: string = !isLong ? tokenNameToAddress(tokenForCollateralInShortByNetwork, networkId) : tokenNameToAddress(data?.operationCoin, networkId)
  const liquidationPrice: string = useLiquidationPrice(isLong, firstTypePerpContractAddress, collateralTokenAddress, tokenNameToAddress(data?.operationCoin, networkId))
  const liqPrice: number = parseFloat(liquidationPrice)
  const withdrawEvent: NodeExecutionResult = logsEvents.find(ev => ev.functionName === "withdrawFromShortLong")
  let liqPriceWithdraw
  if (withdrawEvent) {
    const withdrawShortLongData: WithdrawShortLongDataInfo = withdrawEvent?.extraData?.withdrawShortLongData
    liqPriceWithdraw = withdrawShortLongData.liquidationPrice
  }
  return (
    <article className="lower-row-item">
      <span className="title-usd-prices"><FormattedMessage id="logs-short-long.liq-price" /></span>
      {logsEvents.length === NO_EXECUTED_NODE && <span className="usd-values-prices"> --- </span>}
      {logsEvents.length === ACTIVE_NODE && <span className="usd-values-prices">{!isNaN(liqPrice) ? ` $ ${liqPrice?.toFixed(3)} ` : <FormattedMessage id="waiting-for-data" />} </span>}
      {logsEvents.length === SHORT_LONG_FINISHED_NODE && <span className="usd-values-prices">$ {parseFloat(liqPriceWithdraw).toFixed(3)}</span>}
    </article>
  )
}
