import { useParams } from 'react-router-dom'
import { useRecipeDetails, useRecipeLogs } from 'src/api/recipes'
import { useRecipeDetailsLive, useRecipeLogsLive } from 'src/context/SocketContext'
import { useState, useEffect } from 'react'
import { RecipeDetails, RecipeExecutionLog } from 'src/types'

export const useAllRecipeDetails = () => {
    const { id } = useParams()
    const recipeDetails: RecipeDetails = useRecipeDetails(id)
    const recipeDetailsLive: RecipeDetails = useRecipeDetailsLive(id)
    return recipeDetailsLive || recipeDetails
}

export const useAllRecipeLogs = () => {
    const { id } = useParams();
    const recipeLogs: RecipeExecutionLog[] = useRecipeLogs(id);
    const liveRecipeLogs: RecipeExecutionLog[] = useRecipeLogsLive(id)
    const [aggregatedLogs, setLogs] = useState<RecipeExecutionLog[]>([])
    useEffect(() => {
        if (recipeLogs) {
            setLogs([...recipeLogs, ...liveRecipeLogs])
        }
    }, [liveRecipeLogs, recipeLogs])
    return aggregatedLogs;
}
