import React, { useState, useEffect } from "react"
import { registerModal, useModal } from "@gluedigital/modal"
import { usePeriodicFetch } from '@gluedigital/ruse-fetch-extras'
import { RecipeLogs } from 'src/types'
import "src/components/modals/RecipeWorkingModal"
import "src/components/modals/RecipeNotWorkingModal"
import "./WaitRecipeWorkingModal.sass"
import { FormattedMessage } from "react-intl"
import useNetworkBaseUrl from "src/hooks/useNetworkBaseUrl"
interface RecipeWorkingModalProps {
  recipeID: number
}

const WaitRecipeWorkingModal = (props: RecipeWorkingModalProps) => {
  const { recipeID } = props

  const modal = useModal()

  const base: string = useNetworkBaseUrl()
  const [logsURL, setLogsURL] = useState(base + '/recipes/' + recipeID + '/logs')
  const interval = 6000

  const fetchLogs = usePeriodicFetch<RecipeLogs[]>(interval, logsURL)

  useEffect(() => {
    if (!fetchLogs) {
      return
    }
    if (Object.keys(fetchLogs).length > 0) {
      setLogsURL(null)
      modal.hide()
      modal.show('recipe-working-modal', { recipeID })
    }
  }, [fetchLogs, modal, recipeID])

  return (
    <div className="recipe-working-modal modal-content">
      <h1><FormattedMessage id="waiting" /></h1>
    </div>
  )
}

registerModal('wait-recipe-working-modal', WaitRecipeWorkingModal)
