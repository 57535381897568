import React, { useEffect, useState } from "react"
import { FormattedMessage } from "react-intl"
import { CloseModal, useModal } from "@gluedigital/modal"
import whiteCheckIcon from 'src/static/images/whiteCheckIcon.svg'
import whiteCrossIcon from 'src/static/images/whiteCrossIcon.svg'
import { RecipeExecutionLog, TemporaryExecutionStatus } from "src/types"
import { useBlockerExecutionLive, useRecipeLogsLive } from "src/context/SocketContext"
import ProgressBarBlocker from "./ProgressBarBlocker/ProgressBarBlocker"
import { useSelector } from "react-redux"
import { getNetworkParams } from "../modals/SelectWalletModal/helpers"
import MarkdownMessage from "@gluedigital/markdown-message"
import { getItemByNetwork } from "src/utils/networkHelper"
import { DOCS_TROUBLESHOOTING } from "src/constants"
import './ExecutionBlocker.sass'

interface BlockerModalProps {
  recipeId: number
  startingAction?: TemporaryExecutionStatus
  staticLogs?: RecipeExecutionLog[]
}
const ExecutionBlocker = ({ recipeId, startingAction, staticLogs }: BlockerModalProps) => {
  const TIME_TO_SHOW_MESSAGE_MS: number = 30000
  const networkId: string = useSelector((s: any) => (s.network.network))
  const networkParams = getNetworkParams(networkId)
  const modal = useModal()
  const [retryCount, setRetryCount] = useState<number>(null)
  const [networkScanUrl, setNetworkScanUrl] = useState<string>(null)
  const [showTimeMessage, setShowTimeMessage] = useState<boolean>(false);
  const temporaryState: TemporaryExecutionStatus = useBlockerExecutionLive(recipeId.toString())
  const logsLive: RecipeExecutionLog[] = useRecipeLogsLive(recipeId.toString())
  const scanName: string = getItemByNetwork(networkId, "FTMScan", "ARBIScan", "FTMScan")
  useEffect(() => {
    const timer = setTimeout(() => {
      setShowTimeMessage(true);
    }, TIME_TO_SHOW_MESSAGE_MS);
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    if (logsLive.length > 0) {
      const failedLogsLive: RecipeExecutionLog[] = logsLive.filter((log) => log.logtype === "fail")
      if (failedLogsLive?.length > 0) setRetryCount(failedLogsLive[failedLogsLive.length - 1]?.retrycount)
    } else if (staticLogs) {
      const failedStaticLogs: RecipeExecutionLog[] = staticLogs.filter((log) => log.logtype === "fail")
      if (failedStaticLogs && failedStaticLogs?.length > 0) {
        const lastLogRetryCount: number = failedStaticLogs[failedStaticLogs.length - 1]?.retrycount
        if (!retryCount || lastLogRetryCount >= retryCount) setRetryCount(lastLogRetryCount)
      }
    }
  }, [logsLive, staticLogs, retryCount])

  useEffect(() => {
    if (logsLive) {
      if (startingAction === "Aborting") {
        const abortLogs: RecipeExecutionLog[] = logsLive.filter((log) => log?.logtype === 'aborted')
        const lastAbortLog = abortLogs[abortLogs?.length - 1]
        if (lastAbortLog?.events?.length > 0) setNetworkScanUrl(`${networkParams.blockExplorerUrls[0]}tx/${lastAbortLog?.trans}`)
      } else if (startingAction === "ManualExecuting") {
        const executionLogs: RecipeExecutionLog[] = logsLive.filter((log) => log?.logtype === 'execution' && log?.trans !== "no-transaction")
        const lastExecutionLog = executionLogs[executionLogs?.length - 1]
        if (lastExecutionLog?.status === "active") setNetworkScanUrl(`${networkParams.blockExplorerUrls[0]}tx/${lastExecutionLog.trans}`)
      }
    }
  }, [logsLive, startingAction, networkParams])

  const textClassName: string = temporaryState === "FinishedOperation" || temporaryState === "Failed" || logsLive[logsLive?.length - 1]?.logtype === "fail" || retryCount !== null ? "status-text inactive" : "status-text"
  const retryingTextClassName: string = temporaryState === "FinishedOperation" || temporaryState === "Failed" ? "status-text inactive" : "status-text"
  const retryHandler = () => { modal.hide() }

  return (
    <div id="execution-blocker">
      <section className="blocker-container">
        <h2 className="executing-text">
          <span className="icon icon-clock" />
          <span><FormattedMessage id="blocker-execution.executing" /></span>
        </h2>
        <div className="loadingBar-container">
          {startingAction === "ManualExecuting"
            && <h1 className="loadingBar-title">
              <FormattedMessage id="blocker-execution.combo-on-execution" />
            </h1>
          }
          {startingAction === "Aborting"
            && <h1 className="loadingBar-title">
              <FormattedMessage id="blocker-execution.withdraw-on-execution" />
            </h1>
          }
          {startingAction === "RecipeExecuting"
            && <h1 className="loadingBar-title">
              <FormattedMessage id="blocker-execution.recipe-on-execution" />
            </h1>}
          <ProgressBarBlocker complete={temporaryState === "FinishedOperation" || temporaryState === "Failed"} />
        </div>
        {showTimeMessage && temporaryState !== "FinishedOperation" &&
          <h4>
            <span className="icon icon-exclamation" />
            <span><FormattedMessage id="blocker-execution.why-too-much-time" /></span>
            <a target="_blank"
              rel="noopener noreferrer"
              href={DOCS_TROUBLESHOOTING}
              className="see-more-info"><FormattedMessage id="blocker-execution.more-info" />
            </a>
          </h4>
        }
      </section>
      <section className="execution-status-container">
        <span className={textClassName}>
          <img className="done-icon" src={whiteCheckIcon} />
          <span><FormattedMessage id="blocker-execution.transaction-sent" /></span>
        </span>
        <span className={textClassName}>
          <FormattedMessage id="blocker-execution.transaction-processed" />
          {networkScanUrl
            && <a
              href={networkScanUrl}
              target="_blank"
              rel="noreferrer noopener"
            >
              <strong> <MarkdownMessage
                id="blocker-execution.see-ftm-scan"
                values={{ scanName }} /></strong>
            </a>}
        </span>
        {/* {logsLive[logsLive?.length - 1]?.logtype === "fail" && <span className={retryingTextClassName}> {`Retrying execution. Retry ${retryCount}/3`} </span>} */}
        {retryCount !== null
          && <>
            <span className={textClassName}>
              <img className="done-icon" src={whiteCrossIcon} />
              <FormattedMessage id="blocker-execution.transaction-reverted" />
            </span>
            <span className={retryingTextClassName}>
              <FormattedMessage id="blocker-execution.transaction-retrying" />
              {` ${retryCount}/3`}
            </span>
          </>}
        {temporaryState === "FinishedOperation"
          && <span className="status-text">
            <img className="done-icon" src={whiteCheckIcon} />
            <span><FormattedMessage id="blocker-execution.transaction-complete" /></span>
          </span>}
        {temporaryState === "Failed"
          && <span className="status-text">
            <img className="done-icon" src={whiteCrossIcon} />
            <span><FormattedMessage id="blocker-execution.transaction-failed" /></span>
          </span>}
      </section>

      <footer>
        {temporaryState === "FinishedOperation"
          && <CloseModal>
            <button><FormattedMessage id="blocker-execution.continue" /></button>
          </CloseModal>
        }
        {temporaryState === "Failed" &&
          <section className="failed-container">
            <p><FormattedMessage id="blocker-execution.fail" /></p>
            <div className="failed-buttons">
              <button className="retry-button" onClick={retryHandler}><FormattedMessage id="recipe-item.retry" /></button>
              <CloseModal>
                <button><FormattedMessage id="cancel" /></button>
              </CloseModal>
            </div>
          </section>
        }
      </footer>
    </div>
  )
}

export default ExecutionBlocker
