import React, { Suspense, useEffect, useState } from 'react'
import { FormattedDate, FormattedMessage, FormattedTime } from 'react-intl'
import { Link, useParams } from 'react-router-dom'
import { useRecipeDetails, useRecipeLogs, useRecipePerformance, useRecipePerformanceRefresh } from 'src/api/recipes'
import TableEvents from './TableEvents'
import { dhm } from 'src/utils/miliToDHMS'
import { PerformanceInfo, RecipeExecutionLog, RecipePerformance } from 'src/types'
import LoadingAprDetails from './LoadingAprDetails'
import logo from 'src/static/images/apr-bg.png'
import arrow from 'src/static/images/back-arrow-white.svg'
import './AprDetails.sass'
import { useTableEvents } from 'src/hooks/useTableEvents'
export interface TableData {
  input?: string
  output?: string
  date?: JSX.Element
  value?: string
}

const emptyLogs: RecipeExecutionLog[] = []

const AprDetails = () => {
  const { id } = useParams()
  const recipeLogs = useRecipeLogs(+id) || emptyLogs
  const recipeDetails = useRecipeDetails(+id)
  const { outputTableData, inputTableData } = useTableEvents(+id, recipeLogs, recipeDetails)
  const status = recipeDetails?.status
  const startingLog: RecipeExecutionLog = recipeLogs.find((log) => log?.events.find((event) => event?.functionName === "addFundsForFTM" || event?.functionName === "addFundsForTokens"))
  const endingLog: RecipeExecutionLog = recipeLogs.find((log) => (log?.logtype === "aborted" || log?.logtype === "execution") && log?.status === "finished" && status === "finished")
  const startDate: Date = startingLog?.date ?? new Date()
  const endDate: Date = endingLog?.date ?? new Date()
  const start: number = new Date(startDate).getTime()
  const [end, setEndTime] = useState(new Date(endDate).getTime())
  const now: number = Date.now()
  const elapsed: number = (status === 'finished' ? end : now) - start
  let comeBackRoute = 'your'
  if (recipeDetails?.status === 'finished') {
    comeBackRoute = 'liquidated'
  } else if (recipeDetails?.status === 'active') {
    comeBackRoute = 'active'
  }

  useEffect(() => {
    let endInterval
    if (status !== 'finished') {
      endInterval = setInterval(() => {
        setEndTime((prevTime) => prevTime + 1000)
      }, 1000)
    }
    return () => clearInterval(endInterval)
  }, [status])

  const recipeIdIfNotApr: number = recipeDetails?.performance?.apr ? null : id
  const resultPerformance: RecipePerformance = useRecipePerformance(recipeIdIfNotApr)
  const performance: PerformanceInfo = !resultPerformance ? recipeDetails?.performance : resultPerformance?.performance
  const refresh = useRecipePerformanceRefresh()
  const earned: string = performance?.earned ? performance?.earned?.toFixed(3) : undefined
  const roi: number = performance?.roi

  const [isRefreshLoading, setRefreshLoading] = useState(false)
  const handleRefresh = () => {
    setRefreshLoading(true)
    void refresh(id)
      .then(() => setRefreshLoading(false))
      .catch(() => setRefreshLoading(false))
  }

  return (
    <div id="apr-details" className="page">
      <img className="apr-image" src={logo} alt="apr illustration" />
      <div className="container">
        <header>
          <Link className="back-link" to={"/dashboard/" + comeBackRoute}>
            <img src={arrow} />
            <span><FormattedMessage id="back" /></span>
          </Link>
        </header>
        <section className="recipe-details">
          <h1>{recipeDetails?.title || ''}</h1>
          <span className="status">
            <FormattedMessage id={`apr-details.status-${status}`} />
          </span>
        </section>
        <section className="recipe-date">
          <h2>
            <span><FormattedMessage id="apr-details.start-date" /></span>
            <strong><FormattedDate value={startDate} /></strong>
            <span><FormattedTime value={startDate} /></span>
          </h2>
          {status === 'finished' &&
            <h2>
              <span><FormattedMessage id="apr-details.end-date" /></span>
              <strong><FormattedDate value={endDate} /></strong>
              <span><FormattedTime value={endDate} /></span>
            </h2>
          }
        </section>
        <section className="recipe-specific">
          <h3 className={`apr-info ${roi > 0 ? 'positive' : ''}`}>
            <strong><FormattedMessage id="apr-details.roi-info" /></strong>
            <span>{(roi * 100).toFixed(2)} %</span>
          </h3>
          <h3 className="earned-info">
            <strong><FormattedMessage id="apr-details.earned-info" /></strong>
            <span>$ {earned}</span>
          </h3>
          <h3 className="elapsed-info">
            <strong><FormattedMessage id="apr-details.elapsed-info" /></strong>
            <span>{!!elapsed && dhm(elapsed)}</span>
          </h3>
          <div className="refresh-wrapper">
            <button className="refresh-button" onClick={handleRefresh}>
              <FormattedMessage id="apr-details.refresh-data" />
            </button>
            <span className={`refresh-status ${isRefreshLoading ? 'status-loading' : 'status-loaded'}`} />
          </div>
        </section>
        <section className="events">
          <div className="input-table">
            <h3><FormattedMessage id="apr-details.input-title" /></h3>
            <TableEvents name="input" data={inputTableData} />
          </div>
          <div className="output-table">
            <h3><FormattedMessage id="apr-details.output-title" /></h3>
            <TableEvents name="output" data={outputTableData} />
          </div>
        </section>
      </div>
    </div>
  )
}

const AprDetailsWrapper = () =>
  <Suspense fallback={<LoadingAprDetails />}>
    <AprDetails />
  </Suspense>

export default AprDetailsWrapper
