import React from "react"
import { FormattedMessage } from "react-intl"
import { useSelector } from "react-redux"
import { YearnSummaryVaultsData } from "src/api/yearnVaults/yearnTypes"
import { tokenAddressToName } from "src/components/Diagram/nodes/nodesLogsHelper"
import { getPrice } from "src/contracts/GetPriceHelpers"
import { getEtherStringAmountFromAddressFixed15, getTokenDecimals } from "src/contracts/TokensDecimalsHelpers"
import usePromise from "src/hooks/usePromise"
import { useGetPricePerShare } from "src/hooks/useYearn"

interface EarnedCurrentYearnNestedModalProp {
  oldVaultData: YearnSummaryVaultsData
  depositAmountWei: string
  depositPriceUSD: string
  amountYTokensDepositedWei: string
}

export const EarnedCurrentYearnNestedModalLog = ({ oldVaultData, depositAmountWei, depositPriceUSD, amountYTokensDepositedWei }: EarnedCurrentYearnNestedModalProp) => {
  const networkId: string = useSelector((s: any) => (s.network.network))
  const DECIMAL_USD_ROUNDED: number = 4
  const yVaultAddress: string = oldVaultData?.address
  const vaultTokenAddress: string = oldVaultData?.token?.address
  const vaultTokenDecimals: number = Number(usePromise(getTokenDecimals, vaultTokenAddress))
  const vaultTokenName: string = tokenAddressToName(vaultTokenAddress, networkId)
  const depositAmountYTokens: string = usePromise(getEtherStringAmountFromAddressFixed15, yVaultAddress, amountYTokensDepositedWei)
  const depositAmountEther: string = usePromise(getEtherStringAmountFromAddressFixed15, vaultTokenAddress, depositAmountWei)

  const pricePerShareAmountWei: string = useGetPricePerShare(yVaultAddress)
  const pricePerShareEth: string = usePromise(getEtherStringAmountFromAddressFixed15, yVaultAddress, pricePerShareAmountWei)

  const amountCurrent = Number(depositAmountYTokens) * Number(pricePerShareEth)
  const priceCurrentUSD: string = usePromise(getPrice, vaultTokenName, "USDC_AXL", networkId)
  const priceCurrentEarnedUSD: number = Number(amountCurrent) * Number(priceCurrentUSD)

  let earnedPrice: string = (priceCurrentEarnedUSD - parseFloat(depositPriceUSD)).toFixed(DECIMAL_USD_ROUNDED)
  if (earnedPrice === "-0.0000" || (Number(earnedPrice) > -0.001 && Number(earnedPrice) < 0)) earnedPrice = "0.0000"
  const earnedTokenAmountEth: number = amountCurrent - Number(depositAmountEther)

  return (
    <>
    {/* CURRENT VALUE */}
      <div id="nested-log-field">
        <h3><FormattedMessage id="nested-modal-log.current-value" /></h3>
        <strong className="span-coin">${priceCurrentEarnedUSD.toFixed(DECIMAL_USD_ROUNDED)} </strong>
        <div className="div-2column-lps">
          <span className="lp-amount-row first-column">{amountCurrent.toFixed(vaultTokenDecimals === 18 ? 12 : vaultTokenDecimals)} <strong>{vaultTokenName}</strong></span>
        </div>
      </div>
       {/* EARNED VALUE */}
      <div id="nested-log-field">
        <h3><FormattedMessage id="nested-modal-log.earned" /></h3>
        <strong className="span-coin">$ {earnedPrice} </strong>
        <div className="div-2column-lps">
          <span className="lp-amount-row first-column">{earnedTokenAmountEth.toFixed(vaultTokenDecimals === 18 ? 12 : vaultTokenDecimals)} <strong>{vaultTokenName}</strong></span>
        </div>
      </div>
    </>
  )
}
