import React, { Suspense } from "react"
import { registerModal } from "@gluedigital/modal"
import { findNodeInActiveRecipe, generateShowingTime, getCurrentRowLog, getEndingLogForFarmsDeposit, getNumberOfLogsForDepositAndFarms } from "src/components/Diagram/nodes/nodesLogsHelper"
import { FarmModalData } from "src/routes/RecipeDiagram/helpers/types"
import { NodeExecutionResult, RecipeDetails, RecipeExecutionLog } from "src/types"
import { ModalErrorBoundary } from "src/components/ErrorBoundary/ErrorBoundary"
import { LoadingFarmModalLog } from "./LoadingFarmModalLog"
import { useLastSubgraphBlockFromApi } from "src/api/recipes"
import { useOtherMomentBlockNumberFromTxHash } from "src/hooks/useWeb3"
import { useSelector } from "react-redux"
import { FarmModalLogHeader, FarmModalLogProvider, FarmSelectedInModalLog } from "./FarmModalLogHelpers"
import { nodesLogsNumber } from "src/constants"
import { Networks } from "src/utils/networkHelper"
import FarmModalLogDataFantom from "./Fantom/FarmModalLogDataFantom"
import FarmModalLogDataArbitrum from "./Arbitrum/FarmModalLogDataArbitrum"
import Loading from "src/components/common/Loading/Loading"
import "./FarmModalLog.sass"

export interface FarmLogModalProps {
  previousData: {
    data: FarmModalData
    id: string
    recipeLogs: RecipeExecutionLog[]
    recipeDetails: RecipeDetails
  }
}

const FarmModalLogFantom = (props: FarmLogModalProps) => {
  const { NO_EXECUTED_NODE, ACTIVE_NODE, FINISHED_NODE, ABORTED_NODE } = nodesLogsNumber
  const { previousData } = props
  const { recipeLogs, id: nodeID, data } = previousData
  const pair = data?.pair

  let showingDate: string
  let executionSteps: number
  let isExecutionStarted: boolean = false
  let blockNumberAtOtherMoment: number = null
  let withdrawEvent: NodeExecutionResult

  if (recipeLogs !== null) {
    executionSteps = getNumberOfLogsForDepositAndFarms(recipeLogs, nodeID)
    isExecutionStarted = findNodeInActiveRecipe(recipeLogs, nodeID)
  }

  const graphLastBlock: number = useLastSubgraphBlockFromApi()
  const depositRowLog = getCurrentRowLog(recipeLogs, nodeID)
  const endingLog = getEndingLogForFarmsDeposit(nodeID, recipeLogs)

  blockNumberAtOtherMoment = useOtherMomentBlockNumberFromTxHash(depositRowLog?.trans, endingLog?.trans, executionSteps)
  if (executionSteps === ACTIVE_NODE || executionSteps === FINISHED_NODE || executionSteps === ABORTED_NODE) {
    showingDate = generateShowingTime(depositRowLog)
    if (endingLog) {
      withdrawEvent = endingLog.events.find((ev) => ev.functionName === "withdrawFromFarm" && nodeID === ev.nodeID)
    }
    if (executionSteps === ACTIVE_NODE || executionSteps === ABORTED_NODE) blockNumberAtOtherMoment = graphLastBlock
  } else if (executionSteps === NO_EXECUTED_NODE) {
    blockNumberAtOtherMoment = graphLastBlock
  }

  const depositExtraData = depositRowLog?.events.find((ev) => ev.functionName === "depositOnFarm" && ev.nodeID === nodeID).extraData
  const lpDepositUSDValue: number = Number(depositExtraData?.outputTokenWithPrice?.totalValueUSD)
  const blockToUse: number = blockNumberAtOtherMoment > graphLastBlock ? graphLastBlock : blockNumberAtOtherMoment
  return (
    <ModalErrorBoundary>
      <div className="swap-modal modal-content">
        <div className="modal-wrapper">
          <FarmModalLogHeader isExecutionStarted={isExecutionStarted} previousData={previousData} showingDate={showingDate} executionSteps={executionSteps} />
          <div className="farm-modal-wrapper">
            <div className="main-div">
              <FarmSelectedInModalLog pair={pair} />
              <Suspense fallback={<Loading />} >
                <FarmModalLogDataFantom previousData={previousData} withdrawEvent={withdrawEvent} executionSteps={executionSteps} lpDepositUSDValue={lpDepositUSDValue} blockNumberAtOtherMoment={blockNumberAtOtherMoment} blockToUse={blockToUse} />
              </Suspense>
            </div>
            <FarmModalLogProvider provider={pair.provider} />
          </div>
        </div>
      </div>
    </ModalErrorBoundary>
  )
}

const FarmModalLogArbitrum = (props: FarmLogModalProps) => {
  const { ACTIVE_NODE, FINISHED_NODE, ABORTED_NODE } = nodesLogsNumber
  const { previousData } = props
  const { recipeLogs, id: nodeID, data } = previousData
  const pair = data?.pair

  let showingDate: string
  let executionSteps: number
  let isExecutionStarted: boolean = false
  let withdrawEvent: NodeExecutionResult

  if (recipeLogs !== null) {
    executionSteps = getNumberOfLogsForDepositAndFarms(recipeLogs, nodeID)
    isExecutionStarted = findNodeInActiveRecipe(recipeLogs, nodeID)
  }

  const depositRowLog = getCurrentRowLog(recipeLogs, nodeID)
  const endingLog = getEndingLogForFarmsDeposit(nodeID, recipeLogs)

  if (executionSteps === ACTIVE_NODE || executionSteps === FINISHED_NODE || executionSteps === ABORTED_NODE) {
    showingDate = generateShowingTime(depositRowLog)
    if (endingLog) withdrawEvent = endingLog.events.find((ev) => ev.functionName === "withdrawFromFarm" && nodeID === ev.nodeID)
  }
  const depositEvent: NodeExecutionResult = depositRowLog?.events.find((ev) => ev.functionName === "depositOnFarm" && ev.nodeID === nodeID)

  return (
    <ModalErrorBoundary>
      <div className="swap-modal modal-content">
        <div className="modal-wrapper">
          <FarmModalLogHeader isExecutionStarted={isExecutionStarted} previousData={previousData} showingDate={showingDate} executionSteps={executionSteps} />
          <div className="farm-modal-wrapper">
            <div className="main-div">
              <FarmSelectedInModalLog pair={pair} />
              <Suspense fallback={<Loading />} >
                <FarmModalLogDataArbitrum depositEvent={depositEvent} withdrawEvent={withdrawEvent} myFarmAddress={pair.id} data={data} executionSteps={executionSteps} nodeId={nodeID} />
              </Suspense>
            </div>
            <FarmModalLogProvider provider={"Sushiswap"} />
          </div>
        </div>
      </div>
    </ModalErrorBoundary>
  )
}

const FarmModalLogWrapper = (props: FarmLogModalProps) => {
  const networkId: string = useSelector((s: any) => s.network.network)
  return (
    <ModalErrorBoundary>
      <Suspense fallback={<LoadingFarmModalLog {...props} />}>
        {networkId === Networks.fantom && <FarmModalLogFantom {...props} />}
        {networkId === Networks.arbitrum && <FarmModalLogArbitrum {...props} />}
      </Suspense>
    </ModalErrorBoundary>
  )
}

registerModal("farm-modal-log", FarmModalLogWrapper)
