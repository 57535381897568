import React from 'react'
import { FormattedMessage } from 'react-intl'

import './NoVapy.sass'

const NoVapy = () => {
  return (
    <div className="recipe-item-performance-bottom">
      <FormattedMessage id="recipe-item.apr-error" />
    </div>
  )
}

export default NoVapy
