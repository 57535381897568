import React from "react"
import { FormattedMessage } from "react-intl"
import MarkdownMessage from '@gluedigital/markdown-message'
import { version } from 'package.json'

import "./VersionApp.sass"

const VersionApp = () => {
  return (
    <div id="version-app">
      <div className="version-label"><FormattedMessage id="version.text" values={{ n: version }} /></div>
      <a target="_blank" rel="noopener noreferrer" href="https://v1.tortle.ninja/">
        <MarkdownMessage id="version.version-1.link" />
      </a>
    </div>
  )
}

export default VersionApp
