import React from 'react'
import { useSelector } from 'react-redux'
import { calculateFarmApr, calculateFeeApr } from 'src/api/farms/aprHelpers'
import { PairDayData, TokenInfoSpooky } from 'src/api/farms/types'
import { useGetTokenPriceUsd } from 'src/hooks/useTokensLogsFromGraph'

interface AprFarmV3LeftLogProps {
    myFarm: any
    blockNumberAtOtherMoment: number
    masterchefInfo: PairDayData
}
export const AprFarmV3LeftLog = ({ myFarm, blockNumberAtOtherMoment, masterchefInfo }: AprFarmV3LeftLogProps) => {
    const networkId: string = useSelector((s: any) => (s.network.network))

    const splitDecimals = myFarm.reserveUSD.split('.')
    const liquidity = `${splitDecimals[0]}.${splitDecimals[1].slice(0, 2)}`
    const allocPoint: number = Number(masterchefInfo.allocPoint)
    const totalAllocPoint: number = Number(masterchefInfo.totalAllocPoint)
    const rewardPerSecond: number = masterchefInfo.rewardPerSecond === null ? 0 : Number(masterchefInfo.rewardPerSecond)
    const rewardTokenData: TokenInfoSpooky = useGetTokenPriceUsd(networkId, masterchefInfo.tokenReward, blockNumberAtOtherMoment)
    const feeApr: number = calculateFeeApr(myFarm.dailyVolumeUSD, liquidity, networkId)
    let farmApr: number = 0
    if (totalAllocPoint !== 0) {
        farmApr = calculateFarmApr(rewardPerSecond, allocPoint, totalAllocPoint, parseFloat(rewardTokenData.priceUSD), liquidity)
    }

    const apr: string = (feeApr + farmApr).toFixed(2)
    return (
        <>
            <p>{apr || 'Loading...'} % </p>
        </>
    )
}
