import React from "react"
import { FormattedMessage } from "react-intl"
import { registerModal, CloseModal, useModal } from "@gluedigital/modal"
import { useHistory } from 'react-router-dom';
import "./EarlyAccessModal.sass"
import '../FormEarlyAccessModal/FormEarlyAccessModal'

const EarlyAccessModal = () => {
  const history = useHistory()
  const modal = useModal()
  const goBackHome = () => { history.push('/') }

  const applyHandler = () => {
    modal.hide()
    modal.show('form-early-access-modal')
  }
  return (
    <div className="early-access-modal modal-content">
      <div className="modal-wrapper">
        <header className="header-buttons">
          <CloseModal>
            <button onClick={goBackHome} className="back-button">
              <span className="icon icon-close" />
              <span><FormattedMessage id="close" /></span>
            </button>
          </CloseModal>
        </header>
        <div className="title-div">
          <h2 className="early-access-title"><FormattedMessage id="get-early-access-modal.title" /></h2>
        </div>
        <div className="text-wrapper-early-access">
          <p> <FormattedMessage id="get-early-access-text" /> </p>
          <button onClick={applyHandler}><FormattedMessage id="get-early-access-modal.apply" /></button>
        </div>
      </div>
    </div>
  )
}

registerModal("early-access-modal", EarlyAccessModal)
