import React, { useMemo } from "react"
import { PairDayData } from "src/api/farms/types"

interface APRTriggerOptionsProps {
  pairs: PairDayData[]
}

const APRTriggerOptions = ({ pairs }: APRTriggerOptionsProps) => {
  const orderedPairs: PairDayData[] = useMemo(() => {
    const aprSortedPairs = pairs.sort((a, b) => Number(b.aprFees + b.aprFarm) - Number(a.aprFees + a.aprFarm))
    const aprFilterPairs = aprSortedPairs.filter((pair) => pair?.token0?.symbol !== "DEI" && pair?.token1?.symbol !== "DEI")
    return aprFilterPairs
  }, [pairs])
  return (
    <>
      {orderedPairs.map((pair) => (
        <option key={pair.pairAddress} value={pair.pairAddress}>{pair.token0.symbol} - {pair.token1.symbol} ({(pair.aprFees + pair.aprFarm).toFixed(2)}%)</option>
      ))}
    </>
  )
}
export default APRTriggerOptions
