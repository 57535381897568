import React from "react"
import { FormattedMessage } from "react-intl"
import { useSelector } from "react-redux"
import { ReaperSummaryVaultsData } from "src/api/reaperFinance/reaperTypes"
import { getEtherStringAmountFromAddressFixed15 } from "src/contracts/TokensDecimalsHelpers"
import usePromise from "src/hooks/usePromise"
import { useGetPricesForReaper, useReaperGetPricePerFullShare } from "src/hooks/useReaperFinance"
import { NodeToken } from "src/routes/RecipeDiagram/helpers/types"
import web3 from "src/utils/web3"

// rfTokensDepositedWei = Shares
// tokenDepositedName = LP / BPT / TokenName
interface EarnedCurrentReaperNestedModalProp {
  oldVaultData: ReaperSummaryVaultsData
  tokenDepositedName: string
  depositPriceUSD: string
  rfTokensDepositedWei: string
  infoDepositAmountWei: NodeToken
}

const EarnedCurrentReaperNestedModalLog = ({ oldVaultData, tokenDepositedName, infoDepositAmountWei, depositPriceUSD, rfTokensDepositedWei }: EarnedCurrentReaperNestedModalProp) => {
  const networkId: string = useSelector((s: any) => (s.network.network))
  const DECIMAL_USD_ROUNDED: number = 3
  const reaperVaultAddress: string = oldVaultData?.address
  const tokenAddress = infoDepositAmountWei.token.toLowerCase()
  const rfTokensDepositedEther: string = web3.utils.fromWei(rfTokensDepositedWei)
  const depositAmountEther: string = usePromise(getEtherStringAmountFromAddressFixed15, infoDepositAmountWei.token.toLowerCase(), infoDepositAmountWei.amount)

  // Amount of BPT/ LP / TOKEN corresponding to each RF Token (shares)
  const pricePerShareAmountWei: number = useReaperGetPricePerFullShare(reaperVaultAddress)
  const pricePerShareEth: string = usePromise(getEtherStringAmountFromAddressFixed15, reaperVaultAddress, pricePerShareAmountWei.toString())

  // This is the current amount of BPT/ LP / TOKEN --> It increases or decrease along the time
  const amountCurrent = Number(rfTokensDepositedEther) * Number(pricePerShareEth)

  const priceCurrentUSD = useGetPricesForReaper(tokenDepositedName, tokenAddress, networkId)
  const priceCurrentEarnedUSD: number = Number(amountCurrent) * Number(priceCurrentUSD)
  const earnedTokenAmountEth: number = amountCurrent - Number(depositAmountEther)

  let earnedPrice: string = (priceCurrentEarnedUSD - parseFloat(depositPriceUSD)).toFixed(DECIMAL_USD_ROUNDED)
  if (earnedPrice === "-0.000" || (Number(earnedPrice) > -0.001 && Number(earnedPrice) < 0)) earnedPrice = "0.000"

  return (
    <>
    {/* CURRENT VALUE */}
      <div id="nested-log-field">
        <h3><FormattedMessage id="nested-modal-log.current-value" /></h3>
        <strong className="span-coin">${priceCurrentEarnedUSD.toFixed(DECIMAL_USD_ROUNDED)} </strong>
        <div className="div-2column-lps">
          <span className="lp-amount-row first-column">{amountCurrent.toFixed(12)} <strong>{tokenDepositedName}</strong></span>
        </div>
      </div>
       {/* EARNED VALUE */}
      <div id="nested-log-field">
        <h3><FormattedMessage id="nested-modal-log.earned" /></h3>
        <strong className="span-coin">$ {earnedPrice} </strong>
        <div className="div-2column-lps">
          <span className="lp-amount-row first-column">{earnedTokenAmountEth.toFixed(12)} <strong>{tokenDepositedName}</strong></span>
        </div>
      </div>
    </>
  )
}

export default EarnedCurrentReaperNestedModalLog
