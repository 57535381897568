import React, { useState } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import { registerModal, CloseModal } from "@gluedigital/modal"
import { SwapModalData } from "src/routes/RecipeDiagram/helpers/types"
import { BaseNodeModal } from "../BaseNodeModal"
import { useSelector } from "react-redux"
import { useOrderTokens } from "src/hooks/useOrderTokensInModals"
import "./SwapModal.sass"

interface SwapModalPropTypes extends BaseNodeModal<SwapModalData> { }

const SwapModal = (props: SwapModalPropTypes) => {
  const { onSave, previousData } = props

  const networkId: string = useSelector((s: any) => (s.network.network))

  const sortedCoins = useOrderTokens(networkId)

  const [outputCoin, setOutputCoin] = useState(previousData?.outputCoin)
  const intl = useIntl()
  const inputCoin = previousData?.inputCoin || intl.formatMessage({ id: 'swap-modal.connect' })

  return (
    <div className="swap-modal modal-content">

      <div className="modal-wrapper">
        <header className="header-buttons">
          <CloseModal>
            <button className="modal-close-button">
              <span className="icon icon-close" />
              <span><FormattedMessage id="close" /></span>
            </button>
          </CloseModal>
          <h1><FormattedMessage id="swap-modal.title" /></h1>
          <CloseModal>
            <button
              disabled={!outputCoin}
              onClick={() => onSave({ outputCoin })}
              className="filled">
              <FormattedMessage id="save" />
            </button>
          </CloseModal>
        </header>
        <div className="swap-modal-wrapper">
          <h3><FormattedMessage id={'swap-modal.subtitle'} /></h3>
          <section className="swap-modal-currency">
            <div className="from">
              <h2><FormattedMessage id="swap-modal.amount.from" /></h2>
              <strong>{inputCoin}</strong>
            </div>
            <div className="to">
              <h2><FormattedMessage id="swap-modal.amount.to" /></h2>
              <select onChange={e => setOutputCoin(e.target.value)} defaultValue={outputCoin}>
                <FormattedMessage id="select.currency">
                  {text => <option value="" hidden>{text}</option>}
                </FormattedMessage>
                {sortedCoins.map(c => (
                  <option key={c.id} value={c.id}>{c.id}</option>
                ))}
              </select>
            </div>
          </section>
        </div>
      </div>
    </div>
  )
}

registerModal("swap-modal", SwapModal)
