import React, { useState } from "react"
import { FormattedMessage } from "react-intl"
import { registerModal, CloseModal } from "@gluedigital/modal"
import emojis from "src/data/emojisSummary.json"
import "./RecipeNameModal.sass"

interface RecipeNameModalPropsType {
  setters: {
    setTitle: (title: string) => void
    setShortDescription: (short: string) => void
    setExtendedDescription: (extended: string) => void
    setEnableSaveWithTitle: (value: boolean) => void
  }
  currentValues: {
    title: string
    short: string
    extended: string
  }
}

const RecipeNameModal = (props: RecipeNameModalPropsType) => {
  const { setters, currentValues } = props
  const [recipeName, setRecipeName] = useState<string>(currentValues.title || "")
  const [shortDescription, setShortDescription] = useState<string>(currentValues.short || "")
  const [extendedDescription, setExtendedDescription] = useState<string>(currentValues.extended || "")
  const [extendedDescriptionCharCount, setExtendedDescriptionCharCount] = useState<number>(currentValues.extended.length || 0)
  const [showEmojis, setShowEmojis] = useState<boolean>(false)

  const changeShortDescriptionHandler = (event) => { setShortDescription(event.target.value) }

  const changeExtendedDescriptionHandler = (event) => {
    setExtendedDescriptionCharCount(event.target.value.length)
    setExtendedDescription(event.target.value)
  }

  const changeNameHandler = (event) => { setRecipeName(event.target.value) }

  const showEmojisHandler = () => { setShowEmojis(!showEmojis) }

  const emojiValueHandler = (emoji: string) => { setRecipeName(recipeName + emoji) }

  const saveHandler = () => {
    setters.setTitle(recipeName)
    setters.setShortDescription(shortDescription)
    setters.setExtendedDescription(extendedDescription)
    setters.setEnableSaveWithTitle(true)
  }
  return (
    <div className="recipe-name-modal modal-content">
      <div className="modal-wrapper">
        <header className="header-buttons">
          <CloseModal>
            <button>
              <span className="icon icon-close" />
              <span><FormattedMessage id="close" /></span>
            </button>
          </CloseModal>
          <h2><FormattedMessage id="new-recipe.name.description" /></h2>
          <CloseModal>
            <button
              disabled={!recipeName}
              onClick={saveHandler}
              className="filled">
              <FormattedMessage id="save" />
            </button>
          </CloseModal>
        </header>

        <div className="recipe-name-modal-wrapper">
          <label className="feature-name" ><FormattedMessage id="new-recipe.name" /></label>
          <div className="general-div">
            <FormattedMessage id="new-recipe.name-placeholder">
              {(text) =>
                <input
                  type="text"
                  placeholder={text as string}
                  onChange={changeNameHandler}
                  value={recipeName}
                  maxLength={512}
                />
              }
            </FormattedMessage>
            <button className="smile-button" onClick={showEmojisHandler}><span className="icon icon-emoji" /></button>
            {showEmojis && <div className="emojis-list">
              {emojis.map(emoji =>
                <button className="emojis-buttons" key={emoji} onClick={() => emojiValueHandler(emoji)}>{emoji}</button>)}
            </div>}
          </div>
          <label className="feature-name">
            <FormattedMessage id="new-recipe.short-description" />
            <span className="optional-feature"><FormattedMessage id="new-recipe.description-optional" /></span>
          </label>
          <FormattedMessage id="new-recipe.name-placeholder">
            {(text) =>
              <input
                placeholder={text as string}
                maxLength={120}
                onChange={changeShortDescriptionHandler}
                value={shortDescription}
              />}
          </FormattedMessage>
          <label className="feature-name">
            <FormattedMessage id="new-recipe.extended-description" />
            <span className="optional-feature"><FormattedMessage id="new-recipe.description-optional" /></span>
          </label>
          <FormattedMessage id="new-recipe.name-placeholder">
            {(text) =>
              <textarea
                placeholder={text as string}
                onChange={changeExtendedDescriptionHandler}
                value={extendedDescription}
                maxLength={512}
              />
            }
          </FormattedMessage>
          <span
            className={`char-counter-${extendedDescriptionCharCount === 0 ? 'inactive' : extendedDescriptionCharCount >= 512 ? 'max' : 'default'}`}>
            {extendedDescriptionCharCount}/512
          </span>
        </div>
      </div>
    </div>
  )
}

registerModal("recipe-name-modal", RecipeNameModal)
