import en from './en.json'
// import es from './es.json'
import zh from './zh.json'

import vi from './vi.json'

export default {
  en: en,
  // es: es,
  zh: zh,
  vi: vi
}
