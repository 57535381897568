import React from "react"
import { FormattedMessage } from "react-intl"
import { ViewRecipeLogsProps } from "src/components/Log/ViewRecipeLogs/ViewRecipeLogs"
import { RecipeDetails } from "src/types"

interface LeftLogsNodeStateLabelProps {
  recipeDetails: RecipeDetails
  node: ViewRecipeLogsProps
  check: any
}

export const LeftLogsNodeStateLabel = (props: LeftLogsNodeStateLabelProps) => {
  const { recipeDetails, node, check } = props
  const recipeStatus = recipeDetails?.status
  const eventsLength: number = node.events.length

  const done: JSX.Element = <span className="span-status done"><img className="logo" src={check} alt="check" /> <FormattedMessage id="view-log-node-done" />  </span>
  const aborted: JSX.Element = <span className="span-status aborted"><FormattedMessage id="view-log-node-aborted" /> </span>
  const failed: JSX.Element = <span className="span-status failed"><FormattedMessage id="view-log-node-failed" /> </span>
  const inProgress: JSX.Element = <span className="span-status inprogress"><FormattedMessage id="view-log-node-inprogress" /></span>
  const inQueue: JSX.Element = <span className="span-status inqueue"><FormattedMessage id="view-log-node-inqueue" /> </span>

  const farmDepositNodeAborted: JSX.Element = (eventsLength === 5) && aborted
  const restNodesAbortedWhenNoFarmInRecipe: JSX.Element = (recipeStatus === "finished") && (eventsLength === 0) && aborted
  const restNodesAbortedWhenFarmInRecipe: JSX.Element = (recipeStatus === "finished") && (eventsLength === 0) && aborted
  return (
    <>
      {(recipeStatus === "failed") && (eventsLength === 0) && failed}

      {(eventsLength > 0) && (node.type !== "farmNode" && node.type !== "depositOnLPNode") && done}

      {(eventsLength === 4) && done}

      {(recipeStatus === "active") && (eventsLength === 2) && (node.type === "farmNode" || node.type === "depositOnLPNode") && inProgress}

      {(recipeStatus === "active") && (eventsLength === 0) && inQueue}

      {farmDepositNodeAborted}
      {recipeDetails?.step?.length > 0 ? restNodesAbortedWhenNoFarmInRecipe : restNodesAbortedWhenFarmInRecipe}
    </>
  )
}
