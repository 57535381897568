const initialState = {
  toasts: []
}

export default (state = initialState, action) => {
  switch (action.type) {
    case "showToast":
      return { ...state, toasts: [...state.toasts, action.payload] }
    case "hideToast":
      return { ...state, toasts: state.toasts.filter(toast => toast.toastTitle !== action.payload.toastTitle) }
    default:
      return state
  }
}
