import React, { Suspense } from 'react'
import { useRecipePerformance } from 'src/api/recipes'
import { PerformanceInfo, Recipe, RecipePerformance } from 'src/types'
import Earned from './Earned/Earned'
import PnLMobile from './Vapy/PnLMobile'
import TokenList from '../TokenList/TokenList'
import Spinner from '../common/Loading/Spinner/Spinner'
import './VapyEarnedInfo.sass'

const VapyEarnedInfo = ({ recipe, withEarned = false, withTokens = false }: { recipe: Recipe, withEarned?: boolean, withTokens?: boolean}) => {
  const id: number = recipe?.id
  const recipeIdIfNotApr: number = recipe?.performance?.apr ? null : id
  const resultPerformance: RecipePerformance = useRecipePerformance(recipeIdIfNotApr)
  const performance: PerformanceInfo = !resultPerformance ? recipe?.performance : resultPerformance?.performance
  const earned: string = performance?.earned?.toFixed(3)
  const roi: number = +performance?.roi?.toFixed(2)

  return (
    <section className="vapy-earned-info">
      {!!roi && <PnLMobile roi={roi} />}
      {withEarned && earned && <Earned earned={earned} />}
      { withTokens &&
        <section className="recipe-card-tokens">
          <h2>Coins</h2>
          <Suspense fallback={<Spinner />}>
            <TokenList lite tokenList={recipe.tokenList || []} />
          </Suspense>
        </section>
      }
    </section>
  )
}

export default VapyEarnedInfo
