import React, { useState } from 'react'
import { registerModal, useModal } from '@gluedigital/modal'
import { FormattedMessage } from 'react-intl'
import { useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { login } from "src/store/actions/user"
import { setNetwork } from 'src/store/actions/network'
import providers from './providers'
import { web3Login } from './helpers'
import web3 from 'src/utils/web3'

import './SelectWalletModal.sass'
import '../WhiteListModal/WhiteListModal'
import "../EarlyAccessModal/EarlyAccessModal"
import "../TermsConditionsModal/TermsConditionsModal"
import metamask from 'src/static/images/wallet-icons/metamask.png'
import coinwallet from 'src/static/images/wallet-icons/coinwallet.png'
import walletconnect from 'src/static/images/wallet-icons/walletconnect.png'
import logo from 'src/static/images/logos/logo-3.png'
import psh from 'src/static/images/psh-2.svg'
// import { showInstallPrompt } from 'src/utils/pwaInstall'

type WalletModalStatus = 'picker' | 'connecting' | 'signing'

const SelectWalletModal = () => {
  const [status, setStatus] = useState<WalletModalStatus>('picker')
  const [provider, setProvider] = useState<string>()
  const [name, setName] = useState<string>()

  const history = useHistory()
  const modal = useModal()

  const dispatch = useDispatch()

  /*
  useEffect(() => {
    showInstallPrompt()
  }, [])
  */

  const doLogin = (providerName: string, force?: boolean) => {
    (async () => {
      console.log('* Setting up provider...')
      setProvider(providerName)
      setStatus('connecting')
      const providerLoader = providers[providerName]
      const provider = await providerLoader()
      const conf = await provider.setup(force)
      console.log('* Provider Setup Ready. Signing...')
      const chainId = await web3.eth.getChainId()
      const chainIdInHex = web3.utils.numberToHex(chainId)
      const peerName = conf?.session?.peer?.metadata?.name
      if (peerName) setName(peerName)
      setStatus('signing')
      const loginResult = {
        ...await web3Login(chainIdInHex),
        provider: providerName
      }
      console.log('* Sign complete')
      await dispatch(login(loginResult))
      const deviceAccepted = localStorage.getItem("deviceAccepted")
      if (deviceAccepted === "true") {
        history.push('/dashboard/home')
        modal.hide()
      } else {
        modal.show("terms-and-conditions-modal")
      }
      dispatch(setNetwork(chainIdInHex))
    })()
      .catch(err => {
        setStatus('picker')
        console.error(err)
        if (err.status === 403) {
          modal.hide()
          modal.show('early-access-modal')
        } else {
          alert(err.message)
          // modal.hide()
        }
      })
  }

  return (
    <div className="select-wallet-modal modal-content">
      <div className="tortle-logo">
        <img src={logo} alt="modal liquidate icon" />
      </div>
      <div className="modal-wrapper">
        {status === 'picker' && <WalletPicker doLogin={doLogin} />}
        {status === 'connecting' && <WalletConnecting />}
        {status === 'signing' && <WalletSigning provider={provider} name={name} doLogin={doLogin} />}
      </div>
      <div className="extra">
        <span className="audited-by"><FormattedMessage id="footer.audited-by" /></span>
        <img className="peckShield-logo" src={psh} alt="peckShield logo" />
      </div>
    </div>
  )
}

interface WalletPickerProps {
  doLogin: (provider: string, force?: boolean) => void
}

const WalletPicker = ({ doLogin }: WalletPickerProps) => {
  return (
    <>
      <h3><FormattedMessage id="select-wallet-modal.connect-wallet" /></h3>
      <button id="second-metamask" className="justify-between" onClick={() => doLogin('metamask')}>
        <div className="wallet-logo-container">
          <img className="wallet-logo" src={metamask} alt="metamask" />
        </div>
        <span>Metamask</span>
        <div className="coming-soon"></div>
      </button>
      <button className="justify-between" onClick={() => doLogin('walletConnect')}>
        <div className="wallet-logo-container">
          <img className="wallet-logo" src={walletconnect} alt="wallet connect" />
        </div>
        <span>WalletConnect</span>
        <div className="coming-soon"></div>
      </button>
      <button className="justify-between btn-disabled">
        <div className="wallet-logo-container">
          <img className="wallet-logo" src={coinwallet} alt="coinwallet" />
        </div>
        <span>CoinbaseWallet</span>
        <div className="coming-soon"><FormattedMessage id="select-wallet-modal.coming-soon" /></div>
      </button>
    </>
  )
}

const WalletConnecting = () => {
  return (
    <div className="status-wrapper">
      <p className="status">
        <span className="icon icon-clock" />
        <FormattedMessage id="select-wallet-modal.waiting.connecting" />
      </p>
      <p className="message">
        <FormattedMessage id="select-wallet-modal.waiting.confirm" />
      </p>
    </div>
  )
}

interface WalletSigningProps {
  doLogin: (provider: string, force?: boolean) => void
  provider?: string
  name?: string
}

const WalletSigning = ({ doLogin, provider, name }: WalletSigningProps) => {
  return (
    <div className="status-wrapper">
      <p className="status">
        <span className="icon icon-clock" />
        <FormattedMessage id="select-wallet-modal.waiting.signing" />
      </p>
      <p className="message">
        <FormattedMessage id={'select-wallet-modal.waiting.confirm' + (name ? '-name' : '')} values={{ name }} />
      </p>
      {provider === 'walletConnect' &&
        <p className="choose" onClick={() => doLogin(provider, true)}>
          <FormattedMessage id="select-wallet-modal.waiting.pick-another" />
        </p>
      }
    </div>
  )
}

registerModal('select-wallet-modal', SelectWalletModal)
