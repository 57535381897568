import React from 'react'
import { FormattedMessage } from 'react-intl'

import './MotivationLog.sass'

interface MotivationLogProps {
    motivation: string
}

const MotivationLog = ({ motivation }: MotivationLogProps) => {
return (
    <div id="motivation-log">
        <section className="container-motivation-log">
            <h2><FormattedMessage id="combo-trigger-motivation" /></h2>
            {motivation}
        </section>

    </div>

)
}

export default MotivationLog
