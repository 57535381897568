import React, { memo, useEffect, useMemo, useState } from "react";
import { FormattedMessage } from "react-intl";
import { Handle } from "react-flow-renderer";
import { AddFundsNodeData } from "src/routes/RecipeDiagram/helpers/types";
import "./AddFundsNode.sass";
import Buttons from "./Buttons";
import { useCompareAccounts, tokenNameToAddress } from './nodesLogsHelper';
import { useDispatch, useSelector } from "react-redux";
import { getEtherNumberAmountFromAddress } from '../../../contracts/TokensDecimalsHelpers';
import { useNodeValidator } from "src/hooks/useValidator";
import { focusNode } from "src/store/actions/diagram";
import NodeExecutionTag from "./NodeExecutionTag";
import { useAllRecipeDetails, useAllRecipeLogs } from "src/hooks/useRecipeInfo";
interface AddFundsNodeProps {
  data: AddFundsNodeData
  id: string
}

export default memo(({ id, data }: AddFundsNodeProps) => {
  const networkId: string = useSelector((s: any) => (s.network.network))
  const account = useSelector((s: any) => (s.user ? s.user.account : undefined))
  const focusedNode = useSelector((state: any) => state.diagram.focused)
  const dispatch = useDispatch()
  const userAddress = account?.account.slice(2)
  const diagramisValid = useSelector((s: any) => s.validation.validated)

  const [showButtons, setShowButtons] = useState(false);
  const [amount, setAmount] = useState(0)
  const isValidConnection = () => true;
  const handleBlur = (e: React.FocusEvent<HTMLDivElement>) => {
    if (!e.currentTarget.contains(e.relatedTarget as Node)) {
      setShowButtons(false)
      dispatch(focusNode())
    }
  }

  const handleShowButtons = () => {
    dispatch(focusNode(id))
    if (compareAddresses) {
      setShowButtons(true)
    }
  }

  const position: any = "right";
  const recipeLogs = useAllRecipeLogs()
  const recipeDetails = useAllRecipeDetails()
  const isValid = useNodeValidator(id, data, 'addFundsNode', recipeDetails, networkId)
  const compareAddresses: boolean = useCompareAccounts(userAddress)

  const handleNodeStyle = useMemo(() => {
    if (!compareAddresses) {
      return ''
    } else if (recipeDetails?.status === 'failed') {
      return 'node-handle-failed'
    } else if (diagramisValid || recipeDetails?.status === 'active') {
      return 'handle-valid'
    } else if (data.nextConnected) {
      return 'handle-connected'
    } else {
      return 'node-handle-private'
    }
  }, [compareAddresses, diagramisValid, data.nextConnected])

  useEffect(() => {
    getEtherNumberAmountFromAddress(tokenNameToAddress(data.outputCoin, networkId), data.amount.toString()).then((amount) => setAmount(Number(amount)))
  }, [data.amount, data.outputCoin, networkId])

  return (
    <div className={`node ${focusedNode && focusedNode !== id ? 'node-unfocused' : ''}`} onClick={handleShowButtons} onBlur={handleBlur} tabIndex={0}>
     {(recipeDetails?.status === "finished" || recipeDetails?.status === "active" || recipeDetails?.status === "failed") &&
      <NodeExecutionTag nodeID={id.toString()} /> }
      <div className={"add-funds"}>
        {compareAddresses && !isValid && <span className="icon icon-exclamation" />}
        <Handle
          style={{ top: 50 }}
          className={`node-handle ${handleNodeStyle}`}
          isValidConnection={isValidConnection}
          type="source"
          position={position}
          id="add-funds-output"
        />
      </div>
      <div className="node-info">
        <FormattedMessage id="node.addFundsNode" /> {amount} - {data.outputCoin}
      </div>
      {
        showButtons &&
        <Buttons
          data={data} id={id}
          editModalType="add-funds-modal"
          recipeLogs={recipeLogs}
          recipeDetails={recipeDetails}
          viewDetailsModalType={"add-funds-modal-log"}
        />
      }
    </div>
  );
});
