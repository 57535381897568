interface DataToast {
  toastTitle: string
  typeOfMessage: string
  titleURL?: string
}

export const showToast = (data: DataToast) => {
  return {
    type: "showToast",
    payload: { visible: true, toastTitle: data.toastTitle, typeOfMessage: data.typeOfMessage, titleURL: data.titleURL }
  }
}

export const hideToast = (toastTitle: string) => {
  return {
    type: "hideToast",
    payload: { visible: false, toastTitle }
  }
}
