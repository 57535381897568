import React, { useState } from "react"
import { FormattedMessage } from 'react-intl'
import { useDispatch } from "react-redux"
import { hideToast } from "src/store/actions/toasts"
import './ToastItem.sass'

export interface ToastProps {
  toastTitle: string
  typeOfMessage: string
  visible?: boolean
  titleURL?: string
  darkmode?: boolean
}

const ToastItem = ({ visible, toastTitle, typeOfMessage, darkmode, titleURL }: ToastProps) => {
  const dispatch = useDispatch()

  const [showButton, setShowButton] = useState(false)

  if (!visible) {
    return <></>
  }

  let message: string
  switch (typeOfMessage) {
    case 'create':
      message = 'warning-create-recipe'
      break
    case 'delete':
      message = 'warning-delete-recipe'
      break
    case 'fork':
      message = 'warning-fork-recipe'
      break
    case 'abort':
      message = 'warning-abort-recipe'
      break
    case 'abort-success':
      message = 'warning-abort-success'
      break
    case 'abort-fail':
      message = 'warning-abort-fail'
      break
    case 'execute':
      message = 'warning-execute'
      break
    case 'execute-success':
      message = 'warning-execute-success'
      break
    case 'execute-fail':
      message = 'warning-execute-fail'
      break
    default:
      break
  }

  return (
    <div
      className={`toast-item ${darkmode ? 'dark' : 'light'}`}
      onMouseEnter={() => setShowButton(true)}
      onMouseLeave={() => setShowButton(false)}
    >
      <span className={`toast-item-${typeOfMessage}`} />
      <span><FormattedMessage id={message} /> {' '}
        {titleURL ? <a
          className={"transaction-link"}
          href={titleURL}
          target="_blank"
          rel="noreferrer noopener">{toastTitle}</a>
          : toastTitle}
      </span>

      <span className={`icon icon-close ${!showButton ? 'hide-button' : ''}`} onClick={() => dispatch(hideToast(toastTitle))} />
    </div>
  )
}

export default ToastItem
