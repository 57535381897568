import React, { Suspense, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Switch, Route, useLocation } from 'react-router-dom'
import Dashboard from './Dashboard/Dashboard'
import InternalApp from './App/App'
import NotFound from './NotFound/NotFound'
import ErrorBoundary from 'src/components/ErrorBoundary/ErrorBoundary'
import { ModalWrapper, Modal } from '@gluedigital/modal'
import Diagram from './RecipeDiagram/Diagram'
import RecipeSummary from './RecipeSummary/RecipeSummary'
import Meta from 'src/components/Meta/Meta'
import { SocketProvider } from 'src/context/SocketContext'
import AprDetails from './AprDetails/AprDetails'
import MetaRecipeWrapper from 'src/components/Meta/MetaRecipe'
import MobileDetails from './Mobile/MobileDetails/MobileDetails'
import Mobile from './Mobile/Mobile'
import MobileDeposit from './Mobile/MobileDeposit/MobileDeposit'
import Landing from './Landing/Landing'
import '../utils/pwaInstall' // Needed for PWA event capture
// import DiagramViewLogs from './RecipeDiagram/DiagramViewLogs'

declare const __CLIENT__: boolean

const App = () => {
  const dispatch = useDispatch()

  const location = useLocation()
  useEffect(() => {
    dispatch({ type: '@@_AFTER_RENDER' })
  }, [dispatch])
  const currentLang = useSelector((s: any) => s.intl.locale)

  return (
    <ModalWrapper>
      <SocketProvider>
        <Meta />
        <main id="app" className={`language-${currentLang}`}>
          <ErrorBoundary>
            <div className="pages-transition-group">
              <Switch location={location}>
                <Route path="/" exact component={Landing} />
                {__CLIENT__ ? (
                  <InternalApp>
                    <Switch>
                      <Route path="/mobile/deposit/:id" component={MobileDeposit} />
                      <Route path="/mobile/details/:id" component={MobileDetails} />
                      <Route path="/mobile" component={Mobile} />
                      <Route path="/recipe" exact component={Diagram} />
                      <Route path="/recipe/apr-details/:id" exact component={AprDetails} />
                      <Route path="/recipe/:id/:recipeName?" exact component={Diagram} />
                      <Route path="/recipe-summary/:id/:recipeName?" exact component={RecipeSummary} />
                      <Route path="/dashboard/:pathParam1?" exact component={Dashboard} />
                      <Route component={NotFound} />
                    </Switch>
                  </InternalApp>
                ) : (
                  <Route path="/recipe/:id/:recipeName?" exact component={MetaRecipeWrapper} />
                )}
              </Switch>
            </div>
          </ErrorBoundary>
          <Modal />
        </main>
      </SocketProvider>
    </ModalWrapper>
  )
}

const AppWrapper = (props) => (
  <Suspense fallback={<div />}>
    <App {...props} />
  </Suspense>
)

export default AppWrapper
