import React, { useEffect, useState } from "react"
import { FormattedMessage } from "react-intl"
import { registerModal, CloseModal } from "@gluedigital/modal"
import { findNodeInActiveRecipe, generateShowingTime, getCurrentRowLog, tokenNameToAddress } from 'src/components/Diagram/nodes/nodesLogsHelper';
import { AddFundsModalData } from "src/routes/RecipeDiagram/helpers/types"
import NodeStateAndDate from "../NodeStateAndDate/NodeStateAndDate"
import { RecipeDetails, RecipeExecutionLog } from "src/types"
import { getEtherNumberAmountFromAddress } from "src/contracts/TokensDecimalsHelpers"
import { useSelector } from "react-redux"
import { getNetworkParams } from "../../SelectWalletModal/helpers"

import "./AddFundsModalLog.sass"

interface AddFundsLogModalProps {
  previousData: {
    data: AddFundsModalData
    id: string
    recipeLogs: RecipeExecutionLog[]
    recipeDetails: RecipeDetails
  }
}

const AddFundsModalLog = (props: AddFundsLogModalProps) => {
  let isNodeExecuted: boolean;
  const { previousData } = props
  const networkId: string = useSelector((s: any) => (s.network.network))
  const networkParams = getNetworkParams(networkId)
  const coins = networkParams.tokens
  const recipeLogs = previousData.recipeLogs
  const nodeId = previousData.id
  const [amount, setAmount] = useState('0')
  const outputCoin = previousData?.data.outputCoin || coins[0].id
  const recipeDetails = previousData?.recipeDetails
  const currentRowLog = getCurrentRowLog(recipeLogs, nodeId)
  const showingDate = generateShowingTime(currentRowLog)

  if (recipeLogs !== null) isNodeExecuted = findNodeInActiveRecipe(recipeLogs, nodeId)

  useEffect(() => {
    if (previousData?.data.amount) {
      getEtherNumberAmountFromAddress(
        tokenNameToAddress(previousData?.data.outputCoin, networkId),
        previousData?.data.amount.toString()
      )
        .then((amount) => setAmount(amount))
        .catch((err) => console.error(err.message))
    }
  }, [previousData?.data.amount, previousData?.data.outputCoin, networkId])

  return (
    <div className="add-funds-modal modal-content">
      <div className="modal-wrapper">
        <header className="header-buttons">
          <CloseModal>
            <button>
              <span className="icon icon-close" />
              <span><FormattedMessage id="close" /></span>
            </button>
          </CloseModal>
          <h1><FormattedMessage id="add-funds-modal.title" /></h1>
          <NodeStateAndDate isNodeExecuted={isNodeExecuted} showingDate={showingDate} recipeDetails={recipeDetails} />
        </header>
        <section>
          <div className="input-container">
            <label>
              <span className="span-coin">{outputCoin}</span>
              <span className="span-amount">{amount}</span>
            </label>
          </div>
        </section>
      </div>
    </div>
  )
}

registerModal("add-funds-modal-log", AddFundsModalLog)
