import React from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

import mobilelogo from "src/static/images/logos/logo-3.png"
import './MobileHeader.sass'

const MobileHeader = () => {
  const user = useSelector((s: any) => s.user)
  const account = user?.account
  return (
    <header id="mobile-masthead">
      <div className="mobile-container">
        <Link className="logo" to="/mobile">
          <img src={mobilelogo} alt="Tortle Ninja logo" />
        </Link>
        {account &&
          <div className="account-number">
            {account.account.substr(0, 6) + "..." + account.account.slice(-4)}
          </div>
        }
      </div>
    </header>
  )
}

export default MobileHeader
