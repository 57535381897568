import { BigNumber } from "ethers"

export const saneBigNumber = (value: number): BigNumber => {
    if (value > 1e12) {
      const exp = Math.floor(Math.log10(value)) - 12
      const base = Math.round(value / 10 ** exp)
      return BigNumber.from(base).mul(BigNumber.from(10).pow(exp))
    }
    return BigNumber.from(Math.round(value))
  }
