import React, { useEffect, useState } from "react"
import { FormattedMessage } from "react-intl"
import { RecipeStatus, TemporaryExecutionStatus } from "src/types"
import { useModal } from "@gluedigital/modal"
import { useSelector } from "react-redux"
import { useRecipeDetailsLive } from "src/context/SocketContext"
import { ValidationWarning } from "src/hooks/useValidator"
import { isNotWarning, isRedWarning } from "../helpers/validationWarningHelper"

interface DiagramButtonsProps {
    recipeDetailsStep: any
    staticStatus: RecipeStatus
    isRecipeOwner: boolean
    recipeExists: boolean
    isEmpty: boolean
    isValid: boolean
    recipeId: number
    validateRecipe: () => void
    onSave: () => void
    onRun: () => void
    onAbort: () => Promise<void>
    validationWarnings: ValidationWarning[]
}

const DiagramButtons = (props: DiagramButtonsProps) => {
    const { recipeDetailsStep, staticStatus, isRecipeOwner, recipeExists, isValid, recipeId, validationWarnings } = props
    const modal = useModal()
    const [canAbort, setCanAbort] = useState<boolean>(true)
    const [canAbortBecauseExecution, setCanAbortBecauseExecution] = useState<boolean>(true)
    const [moreThanTwoGMXNodes, setMoreThanTwoGMXNodes] = useState<boolean>(false)
    const [recipeStatus, setRecipeStatus] = useState<RecipeStatus>(staticStatus)
    const liveRecipeDetails = useRecipeDetailsLive(recipeId?.toString())
    const recipesTemporaryMap: TemporaryExecutionStatus = useSelector((s: any) => {
        if (typeof s.blockers.recipesState === "object") return s.blockers.recipesState.get(recipeId)
        else return undefined
    })

    useEffect(() => {
        if (recipesTemporaryMap) setCanAbortBecauseExecution(false)
        else setCanAbortBecauseExecution(true)
    }, [recipesTemporaryMap])

    useEffect(() => {
        if (liveRecipeDetails) setRecipeStatus(liveRecipeDetails?.status)
        else if (staticStatus !== recipeStatus) setRecipeStatus(staticStatus)
    }, [staticStatus, liveRecipeDetails, recipeStatus])

    useEffect(() => {
        const numBlockedShortLong = recipeDetailsStep?.filter(step => (step?.type === 'shortLongNode')).length
        if (numBlockedShortLong > 2) setMoreThanTwoGMXNodes(true)
    }, [recipeDetailsStep])

    const abortHandler = () => {
        const cancelHandler = () => modal.hide('alert-modal')
        const confirmHandler = async () => {
            setCanAbort(false)
            void props.onAbort()
                .then(() => { // promise resolved when abort starts in API
                    setCanAbort(true)
                })
            modal.hide('alert-modal')
        }
        modal.show('alert-modal', { bodyTextID: 'recipe-item.confirmAbort', confirmHandler, cancelHandler })
    }

    if (!isRecipeOwner && recipeExists) {
        return <></>
    }

    let buttons: JSX.Element

    switch (recipeStatus) {
        case "active":
            buttons = (<div className="abort-button-wrapper">
                <div className="circles-div">
                    <span className="running-icon small" />
                    <span className="running-icon medium" />
                    <span className="running-icon large" />
                </div>
                <span><FormattedMessage id="dnd-flow.recipe-running" /></span>
                <button disabled={!canAbort || !canAbortBecauseExecution || moreThanTwoGMXNodes} onClick={abortHandler}><FormattedMessage id="withdraw-funds" /></button>
            </div>)
            break
        case "failed":
            buttons = (<>
                <button disabled={!canAbort} onClick={abortHandler}><FormattedMessage id="dnd-flow.cancel" /></button>
                <button className={`button ${isValid ? '' : 'disabled'}`} disabled={!isValid} onClick={props.onRun} >
                    <FormattedMessage id="dnd-flow.retry" />
                </button>
            </>)
            break
        case "draft":
        case "ready":
            buttons = (<>
                <button onClick={props.onSave}>
                    <FormattedMessage id="dnd-flow.update" />
                </button>
                <button onClick={props.validateRecipe}>
                    <FormattedMessage id="dnd-flow.validate" />
                </button>
                {!isNotWarning(validationWarnings) && <span className="icon warning icon-exclamation" />}
                <button className={`button ${isValid ? '' : 'disabled'}`} disabled={!isValid || isRedWarning(validationWarnings)} onClick={props.onRun} >
                    <FormattedMessage id="dnd-flow.run" />
                </button>
            </>)
            break
        default:
            break
    }

    if (!recipeExists) {
        buttons = <button disabled={props.isEmpty} onClick={props.onSave}>
            <FormattedMessage id="dnd-flow.add" />
        </button>
    }

    return <footer className="dnd-controls">{buttons}</footer>
}

export default DiagramButtons
