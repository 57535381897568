import { format } from "date-fns"
import React from "react"
import { FormattedMessage } from "react-intl"
import { SECONDS_IN_A_DAY, SECONDS_IN_A_MONTH, SECONDS_IN_A_WEEK } from "src/constants"
import { ComboTriggerModalData, ConditionProvider } from "src/routes/RecipeDiagram/helpers/types"
import { useTriggerTimeInput } from "../hooks/useTriggerTimeInput"

interface TriggerTimeInputProps {
  triggerData: ComboTriggerModalData
  setTriggerData: (data: ComboTriggerModalData) => void
  avoidLiquidation: boolean
}

const TriggerTimeInput = (props: TriggerTimeInputProps) => {
  const triggerData: any = props.triggerData
  const setTriggerData = props.setTriggerData
  const avoidLiquidation = props.avoidLiquidation
  const value: number = triggerData?.condition?.value
  const timeComparison = triggerData?.condition?.timeComparison
  const conditionProvider = ConditionProvider.time

  const {
    getCurrentDate,
    dateChange,
    hourChange,
    dateToShowForExactType
  } = useTriggerTimeInput(value)

  const handleDate = e => {
    const date = dateChange(e)
    setTriggerData({ ...triggerData, condition: { timeComparison: "exact", value: date.getTime() }, conditionProvider, avoidLiquidation })
  }
  const handleHour = e => {
    const date = hourChange(e)
    setTriggerData({ ...triggerData, condition: { timeComparison: "exact", value: date.getTime() }, conditionProvider, avoidLiquidation })
  }
  const handlePeriod = data => {
    setTriggerData({ ...triggerData, condition: { timeComparison: "period", value: data }, conditionProvider, avoidLiquidation })
  }

  return (
    <div className="combo-trigger-grid">
      <section className={!timeComparison || timeComparison === "exact" ? "section0" : "section0 sectionfalse"} >
        <h2><FormattedMessage id="timeframe-after" /></h2>
        <div className={ "time-frames"}>
          <button
            className={value === SECONDS_IN_A_DAY ? "timeframe-24h selected" : "timeframe-24h"}
            onClick={() => handlePeriod(SECONDS_IN_A_DAY)}
          >
            <FormattedMessage id="timeframe-24h" />
          </button>
          <button
            className={value === SECONDS_IN_A_WEEK ? "timeframe-7d selected" : "timeframe-7d"}
            onClick={() => handlePeriod(SECONDS_IN_A_WEEK)}
          >
            <FormattedMessage id="timeframe-7d" />
          </button>
          <button
            className={value === SECONDS_IN_A_MONTH ? "timeframe-1m selected" : "timeframe-1m" }
            onClick={() => handlePeriod(SECONDS_IN_A_MONTH)}
          >
            <FormattedMessage id="timeframe-1m" />
          </button>
        </div>
      </section>
      <section className={!timeComparison || timeComparison === "period" ? "section1" : "section1 sectionfalse"} >
        <h2><FormattedMessage id="timeframe-on" /></h2>
        <div className={"time-frames"}>
          <label>
            <input
              className={timeComparison === "exact" ? "timeframe-date selected" : "timeframe-date"}
              type="date"
              name="date"
              value={format(dateToShowForExactType, 'yyyy-MM-dd')}
              onChange={handleDate}
              min={getCurrentDate()}
            />
          </label>
          <label>
            <input
              className={timeComparison === "exact" ? "timeframe-hour selected" : "timeframe-date"}
              type="time"
              name="time"
              value={format(dateToShowForExactType, 'HH:mm')}
              onChange={handleHour}
              placeholder="00:00"
            />
          </label>
        </div>
      </section>
    </div>
  )
}
export default TriggerTimeInput
