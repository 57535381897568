import React from "react"
import { FormattedMessage } from 'react-intl'
import { useFeaturedTrendingRecipes } from "src/api/recipes"
import RecipeItem from "src/components/RecipeItem/RecipeItem"
import featuredRecipes from 'src/data/featuredRecipes.json'
import featuredRecipesCandidate from 'src/data/featuredRecipesCandidates.json'
import trendingRecipes from 'src/data/trendingRecipes.json'
import trendingRecipesCandidate from 'src/data/trendingRecipesCandidate.json'
import { ENV_ENVIROMENT } from "src/constants"
import youtube from 'src/data/youtube.json'
import YoutubeVideo from "src/components/YoutubeVideo/YoutubeVideo"
import ErrorBoundary from "src/components/ErrorBoundary/ErrorBoundary"
import { useSelector } from "react-redux"
import "./HomeList.sass"

const HomeList = () => {
  const networkId: string = useSelector((s: any) => s.network.network)
  const { videos, moreSrc } = youtube
  const trendingToUse = ENV_ENVIROMENT === "MAINNET_PRO"
    ? trendingRecipes.filter(r => r.network === networkId)
    : trendingRecipesCandidate.filter(r => r.network === networkId)
  const featuredToUse = ENV_ENVIROMENT === "MAINNET_PRO"
    ? featuredRecipes.filter(r => r.network === networkId)
    : featuredRecipesCandidate.filter(r => r.network === networkId)

  const featured = useFeaturedTrendingRecipes(featuredToUse?.map(f => f?.id))
  const trending = useFeaturedTrendingRecipes(trendingToUse?.map(t => t?.id))
  return (
    <section id="home-list">
      <section id="featured-section">
        <h2><FormattedMessage id="home-recipes.featured-section.title" /></h2>
        <ErrorBoundary fallback={<div className="recipe-grid"></div>}>
          <div className="recipe-grid">
            {featured.map((recipe) =>
              <RecipeItem key={recipe?.id} recipe={recipe} type="public" status={recipe.status} />
            )}
          </div>
        </ErrorBoundary>
      </section>
      <section id="trending-section">
        <h2><FormattedMessage id="home-recipes.trending-section.title" /></h2>
        <ErrorBoundary fallback={<div className="recipe-grid"></div>}>
          <div className="recipe-grid">
            {trending.map((recipe) =>
              <RecipeItem key={recipe?.id} recipe={recipe} type="public" status={recipe.status} />
            )}
          </div>
        </ErrorBoundary>
      </section>

      <section id="videos-section">
        <h2><FormattedMessage id="home-recipes.videos-section.title" /></h2>
        <div className="video-grid">
          {videos.map((video) => <YoutubeVideo key={video} id={video} />)}
          <a target="_blank" rel="noopener noreferrer" className="tutorial-card" href={moreSrc}>
            <span><FormattedMessage id="home-recipes.videos-section.tutorials" /></span>
          </a>
        </div>
      </section>
    </section>
  );
};

export default HomeList
