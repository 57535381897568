import React from "react"
import { FormattedMessage } from "react-intl"

import "./ManualPerformanceType.sass"

const ManualPerformanceType = () => {
  return (
    <div className="performance-type performance-type-manual">
      <p><FormattedMessage id="combo-trigger-modal.manual-execution" /></p>
    </div>
  )
}

export default ManualPerformanceType
