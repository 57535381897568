import React from "react"
import { FormattedMessage } from "react-intl"
import { registerModal, CloseModal } from "@gluedigital/modal"
import { useHistory } from 'react-router-dom';
import "./ThanksEarlyAccessModal.sass"

const ThanksEarlyAccessModal = () => {
  const history = useHistory()
  const goBackHome = () => { history.push('/') }

  return (
    <div className="early-access-modal modal-content">
      <div className="modal-wrapper">
        <header className="header-buttons">
          <CloseModal>
            <button onClick={goBackHome} className="back-button">
              <span className="icon icon-close" />
              <span><FormattedMessage id="close" /></span>
            </button>
          </CloseModal>
        </header>
        <div className="title-div">
          <h2 className="early-access-title"><FormattedMessage id="get-early-access-thanks-modal.title" /></h2>
        </div>
        <div className="text-wrapper-early-access">
          <p> Tortle team will contact you soon. Meanwhile do no forget to follow us on social media.</p>
        </div>
        <div className="social-links-thanks-wrapper">
          <a
            target="_blank"
            rel="noreferrer noopener"
            href="https://twitter.com/tortle_ninja"
            className="icon icon-twitter"
            title="twitter link"
          />
          <a
            target="_blank"
            rel="noreferrer noopener"
            href="https://discord.gg/UwPAd7VE4h"
            className="icon icon-discord"
            title="discord link"
          />

          <a
            target="_blank"
            rel="noreferrer noopener"
            href="https://t.me/tortleninjaTG"
            className="icon icon-telegram"
            title="telegram link"
          />
          <a
            target="_blank"
            rel="noreferrer noopener"
            href="https://tortle-ninja.medium.com/"
            className="icon icon-medium"
            title="medium link"
          />

        </div>
      </div>
    </div>
  )
}

registerModal("thanks-early-access-modal", ThanksEarlyAccessModal)
