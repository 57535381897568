import web3 from "src/utils/web3"
import usePromise from "./usePromise"

const getBlockNumberFromTxHash = async (txHashArray: string[], executionSteps: number): Promise<any> => {
    if (executionSteps === 0 || executionSteps === 2) return undefined
    else if (executionSteps === 4 || executionSteps === 5) {
        if (txHashArray.length === 1) return await web3.eth.getTransaction(txHashArray[0])
        else return await web3.eth.getTransaction(txHashArray[1])
    }
}

export const useBlockNumberFromTxHash = (txHashArray: string[], executionSteps: number): number => {
    const txInfo = usePromise(getBlockNumberFromTxHash, txHashArray, executionSteps)
    return txInfo?.blockNumber
}

const getOtherMomentBlockNumberFromTxHash = async (txHashDeposit, txHashEnd, executionSteps: number): Promise<any> => {
    const NO_EXECUTED_NODE: number = 0
    const ACTIVE_NODE: number = 2
    const FINISHED_NODE: number = 4
    const ABORTED_NODE: number = 5
    if (executionSteps === NO_EXECUTED_NODE || executionSteps === ACTIVE_NODE) return undefined
    if (executionSteps === FINISHED_NODE || executionSteps === ABORTED_NODE) {
        if (txHashEnd === txHashDeposit) return await web3.eth.getTransaction(txHashDeposit)
        else return await web3.eth.getTransaction(txHashEnd)
    }
}

export const useOtherMomentBlockNumberFromTxHash = (txHashDeposit, txHashEnd, executionSteps: number): number => {
    const txInfo = usePromise(getOtherMomentBlockNumberFromTxHash, txHashDeposit, txHashEnd, executionSteps)
    return txInfo?.blockNumber || undefined
}

const getDepositBlockNumberFromTxHash = async (txHash: string): Promise<any> => {
    if (txHash === undefined) return undefined
    const txInfoPromise = web3.eth.getTransaction(txHash)
    return await txInfoPromise
}

export const useDepositBlockNumberFromTxHash = (txHash: string): number => {
    const txInfo = usePromise(getDepositBlockNumberFromTxHash, txHash)
    return txInfo?.blockNumber
}

const getTimestampFromBlockNumber = async (blockNumber: number): Promise<any> => {
    const timestampPromise = web3.eth.getBlock(blockNumber)
    return await timestampPromise
}

export const useTimestampFromBlockNumber = (blockNumber: number): number => {
    const block = usePromise(getTimestampFromBlockNumber, blockNumber)
    return block?.timestamp
}
