import React from "react"
import { useModal } from "@gluedigital/modal"

import 'src/components/modals/YoutubeModal/YoutubeModal'

interface YoutubeVideoProps {
  id: string
  poster?: string | null
}

const YoutubeVideo = ({ id, poster = null }: YoutubeVideoProps) => {
  const modal = useModal()
  const backgroundImage =
    !poster
      ? `url(http://img.youtube.com/vi/${id}/0.jpg)`
      : `url(/poster/${poster}.jpg)`
  return (
    <article
      onClick={() => modal.show('youtube-modal', { id })}
      className="tutorial-card"
      style={{ backgroundImage }}
    />
  )
}

export default YoutubeVideo
