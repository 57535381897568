import React from 'react'
import { FormattedMessage } from 'react-intl'
import { NavLink } from 'react-router-dom'

import './SectionSelector.sass'

const SectionSelector = () => {
  const sections = ['explore', 'active', 'liquidated', 'failed']

  return (
    <div id="mobile-section-selector">
      <div className="mobile-container">
        {sections.map(s => (
          <NavLink key={s} to={`/mobile/${s}`} className="section-selector" activeClassName="current">
            <span className={`mobile-icon mobile-icon-${s}`} />
            <span><FormattedMessage id={`mobile-section-selector.${s}`} /></span>
          </NavLink>
        ))}
      </div>
    </div>
  )
}

export default SectionSelector
