import React from 'react'
import MarkdownMessage from '@gluedigital/markdown-message'
import { usePeriodicRecipes } from 'src/api/recipes'
import NoRecipesMobile from '../NoRecipesMobile/NoRecipesMobile'
import ButtonsCardMobile from '../ButtonCardMobile/ButtonsCardMobile'
import RecipeCardMobile from '../RecipeCardMobile/RecipeCardMobile'

const FailedRecipes = () => {
  const recipes = usePeriodicRecipes('failed')
  if (recipes.length === 0) return <NoRecipesMobile />
  return (
    <section className="mobile-section-recipes" id="failed-recipes">
      <h1>
        <span><MarkdownMessage id="mobile-section-recipes.failed-title" /></span>
        <span className="mobile-icon mobile-icon-failed" />
      </h1>
      <div className="mobile-recipe-grid">
        {recipes.map(r =>
          <RecipeCardMobile key={r.id} recipe={r} withEarned>
            <ButtonsCardMobile recipe={r} mode="withdraw" />
          </RecipeCardMobile>
        )}
      </div>
    </section>
  )
}

export default FailedRecipes
