import React, { useState } from 'react'
import { registerModal, CloseModal } from "@gluedigital/modal"
import { FormattedMessage } from 'react-intl'
import Loading from 'src/components/common/Loading/Loading'
import './AlertModal.sass'

interface AlertProps {
  bodyTextID: string
  cancelHandler: () => Promise<void>
  confirmHandler: () => Promise<void>
}

const AlertModal = (props: AlertProps) => {
  const [isPending, setPending] = useState(false)

  const onConfirm = async () => {
    setPending(true)
    try {
      await props.confirmHandler()
    } catch {
      setPending(false)
    }
  }

  return <div className="modal-content">
    <div className="modal-wrapper">
      <header className="header-buttons">
        <CloseModal>
          <button>
            <span className="icon icon-close" />
            <span><FormattedMessage id="close" /></span>
          </button>
        </CloseModal>
      </header>
      <div className="alert-modal-wrapper">
        {isPending && <Loading />}
        <span><FormattedMessage id={props.bodyTextID} /></span>
        <div className="alert-modal-buttons">
          <button className="cancel-button" onClick={() => { void props.cancelHandler() }}>
           { props?.bodyTextID === "recipe-item.confirmAbort" ? <FormattedMessage id="alert-modal-not-withdraw" /> : <FormattedMessage id="alert-modal-exit" /> }
          </button>
          <button className="confirm-button" onClick={() => (onConfirm as () => void)()}>
          { props?.bodyTextID === "recipe-item.confirmAbort" ? <FormattedMessage id="alert-modal-withdraw" /> : <FormattedMessage id="save" /> }
          </button>
        </div>
      </div>
    </div>
  </div>
}

registerModal('alert-modal', AlertModal)
