import React, { useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { useHistory, useParams } from 'react-router-dom'

import './LogFlowSelector.sass'
import ToggleSwitch from '../ToggleSwitch/ToggleSwitch'

const LogFlowSelector = () => {
  const { id } = useParams()
  const [stateLog, setStateLog] = useState(true)
  const history = useHistory()

  useEffect(() => {
    history.push(`/mobile/details/${id}/${stateLog ? 'log' : 'flow'}`)
  }, [history, id, stateLog])

  return (
    <div id="mobile-details-selector">
      <div className="mobile-container">
        <div className={`details-selector ${stateLog ? 'current' : ''}`} onClick={() => setStateLog(true)}>
          <span><FormattedMessage id="mobile-details-selector.log" /></span>
        </div>
        <ToggleSwitch value={stateLog} onChange={setStateLog} />
        <div className={`details-selector ${!stateLog ? 'current' : ''}`} onClick={() => setStateLog(false)}>
          <span><FormattedMessage id="mobile-details-selector.flow" /></span>
        </div>
      </div>
    </div>
  )
}

export default LogFlowSelector
