const initialState = {
    validated: false
}

export default (state = initialState, action) => {
    switch (action.type) {
        case "validateDiagram":
            return { ...state, validated: action.payload }
        default:
            return state
    }
}
