import React from "react"
import { DepositOnLPNodeData } from "src/routes/RecipeDiagram/helpers/types"
import { ViewRecipeLogsProps } from "../ViewRecipeLogs/ViewRecipeLogs"
import { useBlockNumberFromTxHash } from "src/hooks/useWeb3"
import { SpookyDespositLeftLog } from "./SpookyDepositLeftLog"
import { BeetsFTMDepositLeftLog } from "./BeetsFTMDepositLeftLog"
import { useLastSubgraphBlockFromApi } from "src/api/recipes"
import { NodeExecutionResult } from "src/types"
import { useSelector } from "react-redux"
import { Networks } from "src/utils/networkHelper"
import { BalancerArbDepositLeftLog } from "./BalancerArbDepositLeftLog"
import { nodesLogsNumber } from "src/constants"
import 'src/routes/RecipeDiagram/SideBarViewLogs/SidebarViewLogs.sass'

interface DepositExtraDataLogProps extends ViewRecipeLogsProps {
  data: DepositOnLPNodeData
}
const DepositExtraDataLog = (props: DepositExtraDataLogProps) => {
  const { data, events, trans } = props
  const networkId: string = useSelector((s: any) => (s.network.network))
  const oldRecipePair: any = data.pair
  const myPoolId: string = data?.pair?.address ? data?.pair?.address : oldRecipePair.id
  const { FINISHED_NODE, ABORTED_NODE, ACTIVE_NODE } = nodesLogsNumber
  let executionSteps: number = 0
  let blockNumberAtOtherMoment: number = 0
  let depositEvent: NodeExecutionResult
  let withdrawEvent: NodeExecutionResult = null
  if (events !== undefined && events !== null) {
    executionSteps = events.length
    depositEvent = events.find((ev) => ev.functionName === "depositOnLp")
    if (executionSteps === FINISHED_NODE || executionSteps === ABORTED_NODE) withdrawEvent = events.find((ev) => ev.functionName === "withdrawFromLp")
  }

  const graphLastBlock: number = useLastSubgraphBlockFromApi()
  blockNumberAtOtherMoment = useBlockNumberFromTxHash(trans, executionSteps)
  if (executionSteps === ACTIVE_NODE) blockNumberAtOtherMoment = graphLastBlock

  return (
    <div className="div-extra-info">
      {(data.pair.provider === 'SpookySwap' || data.pair.provider === 'SushiSwap')
        && <SpookyDespositLeftLog
          blockNumberAtOtherMoment={blockNumberAtOtherMoment}
          executionSteps={executionSteps}
          myPoolId={myPoolId}
          depositEvent={depositEvent}
          withdrawEvent={withdrawEvent}
        />}

      {(data.pair.provider === 'BeethovenX' || data.pair.provider === 'Balancer') && networkId === Networks.fantom && <BeetsFTMDepositLeftLog events={events} data={data}/>}
      {(data.pair.provider === 'BeethovenX' || data.pair.provider === 'Balancer') && networkId === Networks.arbitrum && <BalancerArbDepositLeftLog events={events} data={data}/>}
    </div>
  )
}

export default DepositExtraDataLog
