import React from "react"
import Spinner from "src/components/common/Loading/Spinner/Spinner"
import MarkdownMessage from "@gluedigital/markdown-message"
import { FormattedMessage } from "react-intl"
import { ShowMessageExecutionRevertedProps, ShowStepMessageProps } from "../types"

export const ShowMessageExecutionReverted = ({ recipeStatus, retryCount }: ShowMessageExecutionRevertedProps) => (
  <>
    <div className="deposit-step-item">
      <div className="row">
        <div className="circle failed" />
        <p><FormattedMessage id={`blocker-execution.transaction-reverted`} />.</p>
      </div>
    </div>
    <div className="deposit-step-item">
      {recipeStatus === "active" &&
        <div className="row">
          <Spinner />
          <p><FormattedMessage id="deposit-step-item.state-execution.retry" /> <b>({retryCount + 1}/4)</b></p>
        </div>}
      {recipeStatus === "failed" &&
        <div className="row">
          <div className="circle failed" />
          <p><FormattedMessage id="blocker-execution.transaction-failed" /></p>
        </div>}
    </div>
  </>
)

const ShowStepMessage = ({ stepName, state, failStep }: ShowStepMessageProps) =>
(
  <div className="deposit-step-item">
    <div className="row">
      {state === "done" && <div className={`circle done`} />}
      {failStep && state === "failed" && <div className={`circle failed`} />}
      {failStep && state === "running" && <div className={`circle failed`} />}
      {!failStep && state === "running" && <Spinner />}
      <p><span><MarkdownMessage id={`deposit-step-item.state-${stepName}.${state}`} /></span></p>
    </div>
  </div>)

export default ShowStepMessage
