import React from "react"
import { FormattedMessage } from "react-intl"
import { useSelector } from "react-redux"
import { DepositShortLongDataInfo } from "src/api/mummyFinance/mummyTypes"
import { tokenNameToAddress } from "src/components/Diagram/nodes/nodesLogsHelper"
import { usePerpetualsCurrentPrice } from "src/hooks/usePerpetuals"
import { ShortLongNodeData } from "src/routes/RecipeDiagram/helpers/types"

interface NetValueLeftLogProps {
  data: ShortLongNodeData
  depositShortLongData: DepositShortLongDataInfo
}
const NetValueLeftLog = ({ data, depositShortLongData }: NetValueLeftLogProps) => {
  const networkId: string = useSelector((s: any) => (s.network.network))
  const currentPriceOpCoin: number = usePerpetualsCurrentPrice(tokenNameToAddress(data?.operationCoin, networkId))
  const netValueAtDeposit: number = depositShortLongData?.netValueAtDeposit
  const collateralUSD: string = depositShortLongData?.collateralUSD
  const positionSize: number = depositShortLongData?.positionSize
  const amountOpTokenAfterLeverage: string = depositShortLongData?.amountOpTokenAfterLeverage

  const currentUSDValue: number = currentPriceOpCoin * parseFloat(amountOpTokenAfterLeverage)
  const differenceValue: number = currentUSDValue - positionSize
  const netValue: number = (data.operation === "long") ? parseFloat(collateralUSD) + differenceValue : parseFloat(collateralUSD) - differenceValue // to calculate netvalue we considerer openClose Fees
  const pnl: number = netValue - (netValueAtDeposit)
  const pnlPercentage: number = pnl * 100 / netValueAtDeposit

  return (
    <>
      {!isNaN(netValue) ?
        <>
          <p className="main-text-logs">  {netValue?.toFixed(3)} $ </p>
          <p> {pnl?.toFixed(3)} $  ({pnlPercentage?.toFixed(2)} %)</p>
        </>
        :
        <>
          <p className="main-text-logs"><FormattedMessage id="waiting-for-data" /></p>
          <div className="left-empty-position" />
        </>
      }
    </>)
}

export default NetValueLeftLog
