export const formatRecipeTitle = (title: string): string => {
    if (title) {
        const removeSpace: string = (title.replace(/ /g, '-')).toLowerCase()
        let newString: string = ''
        const reg: RegExp = /^[\w\d\s-]+$/
        for (let i = 0; i < removeSpace.length; i++) {
            if (reg.test(removeSpace[i])) newString += removeSpace[i]
        }
        if (newString.endsWith('-')) newString = newString.slice(0, newString.length - 1)
        return newString
    } else return null
}
