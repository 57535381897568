import React from "react"
import MarkdownMessage from '@gluedigital/markdown-message'
import { FormattedMessage } from "react-intl"

import './EstimateGasLabel.sass'
import { useSelector } from "react-redux"
import { Networks } from "src/utils/networkHelper"

export interface EstimateGasResponse {
  estimationAmount: string
  estimationUSD: string
}
interface EstimateGasLabelProps {
  result?: EstimateGasResponse
}

const EstimateGasLabel = ({ result }: EstimateGasLabelProps) => {
  const networkId: string = useSelector((s: any) => s.network.network)
  let token: string
  if (networkId === Networks.fantom) token = "FTM"
  else if (networkId === Networks.arbitrum) token = "ETH"
  else if (networkId === Networks.hardhat) token = "FTM"
  const componentLoading = <div className="estimate-gas-container loading-container">
    <span className="icon-loading" />
    <span><FormattedMessage id="estimate-gas.loading" /></span>
  </div>
  if (!result) {
    return componentLoading
  }
  if (result.estimationAmount === 'error') {
    return <div className="estimate-gas-container failed-container">
      <span className="icon icon-exclamation" />
      <span><FormattedMessage id="estimate-gas.failed" /></span>
    </div>
  }
  return <div className="estimate-gas-container success-container">
    <span className="icon-done" />
    <MarkdownMessage
      id="estimate-gas.success"
      values={{ estimationAmount: result?.estimationAmount, token, estimationUSD: result?.estimationUSD }}
    />

  </div>
}

export default EstimateGasLabel
