import React from "react"
import { useHistory } from 'react-router-dom'
import { FormattedMessage } from "react-intl"
import { CloseModal, registerModal } from "@gluedigital/modal"
import { RecipeExecutionLog, TemporaryExecutionStatus } from "src/types"
import ExecutionBlocker from "src/components/ExecutionBlocker/ExecutionBlocker"

import './BlockerModal.sass'

interface BlockerModalProps {
  recipeId: number
  startingAction?: TemporaryExecutionStatus
  staticLogs?: RecipeExecutionLog[]
}
const BlockerModal = ({ recipeId, startingAction, staticLogs }: BlockerModalProps) => {
  const history = useHistory()
  return (
    <div className="blocker-modal modal-content">
      <div className="modal-wrapper">
        <header>
          <CloseModal>
            <button onClick={() => history.push('/dashboard/home')}> <span className="icon icon-angle_left" /><FormattedMessage id="back-to-dashboard" /></button>
          </CloseModal>
        </header>
        <ExecutionBlocker recipeId={recipeId} startingAction={startingAction} staticLogs={staticLogs} />
      </div>
    </div>
  )
}

registerModal("blocker-modal", BlockerModal)
