import { FormattedMessage } from "react-intl"
import React from 'react'
import { NodeExecutionResult } from "src/types"
import { DepositShortLongDataInfo, WithdrawShortLongDataInfo } from "src/api/mummyFinance/mummyTypes"
import { ShortLongModalData } from "src/routes/RecipeDiagram/helpers/types"
import { useSelector } from "react-redux"
import { usePerpetualsCurrentPrice } from "src/hooks/usePerpetuals"
import { tokenNameToAddress } from "src/components/Diagram/nodes/nodesLogsHelper"
import { nodesLogsNumber } from "src/constants"

interface NetValuePerpetualProps {
  logsEvents: NodeExecutionResult[]
  data?: ShortLongModalData
}
export const NetValuePerpetualModal = ({ logsEvents, data }: NetValuePerpetualProps) => {
  const { ACTIVE_NODE, SHORT_LONG_FINISHED_NODE, NO_EXECUTED_NODE } = nodesLogsNumber
  const withdrawEvent: NodeExecutionResult = logsEvents.find(ev => ev.functionName === "withdrawFromShortLong")
  const withdrawShortLongData: WithdrawShortLongDataInfo = withdrawEvent?.extraData?.withdrawShortLongData

  const depositShortLongData: DepositShortLongDataInfo = logsEvents.find((ev) => ev.functionName === "depositOnShortLong")?.extraData?.depositShortLongData
  let pnlWithdraw, pnlPercentageWithdraw: number
  if (withdrawShortLongData) {
    pnlWithdraw = withdrawShortLongData?.netValueAtWithdraw - depositShortLongData?.netValueAtDeposit
    pnlPercentageWithdraw = pnlWithdraw * 100 / depositShortLongData?.netValueAtDeposit
  }

  const netValueAtDeposit: number = depositShortLongData?.netValueAtDeposit
  const collateralUSD: string = depositShortLongData?.collateralUSD
  const positionSize: number = depositShortLongData?.positionSize
  const amountOpTokenAfterLeverage: string = depositShortLongData?.amountOpTokenAfterLeverage

  const networkId: string = useSelector((s: any) => (s.network.network))
  const currentPriceOpCoin: number = usePerpetualsCurrentPrice(tokenNameToAddress(data?.operationCoin, networkId))
  const currentUSDValue: number = currentPriceOpCoin * parseFloat(amountOpTokenAfterLeverage)
  const differenceValue: number = currentUSDValue - positionSize
  const netValue: number = (data.operation === "long") ? parseFloat(collateralUSD) + differenceValue : parseFloat(collateralUSD) - differenceValue // to calculate netvalue we considerer openClose Fees
  const pnl: number = netValue - (netValueAtDeposit)
  const pnlPercentage: number = pnl * 100 / netValueAtDeposit
  return (
    <article className="upper-row-item">
      <span className="upper-item-title"><FormattedMessage id="logs-short-long.net-value" /></span>
      {logsEvents.length === NO_EXECUTED_NODE && <article className="first-upper-cell-data">
        <span className="upper-item-values-background">  --- </span>
      </article>}
      {logsEvents.length === ACTIVE_NODE && <article className="first-upper-cell-data">
        {!isNaN(netValue) ? <>
          <span className="upper-item-values-background"> ${netValue.toFixed(3)}</span>
          <span className={pnl > 0 ? "positive-value" : "negative-value"} >${pnl?.toFixed(4)} ({pnlPercentage?.toFixed(2)}%)</span>
        </>
          : <span className="upper-item-values-background"><FormattedMessage id="waiting-for-data" /></span>}
      </article>}
      {logsEvents.length === SHORT_LONG_FINISHED_NODE && <article className="first-upper-cell-data">
        <span className="upper-item-values-background"> $ {withdrawShortLongData?.netValueAtWithdraw.toFixed(3)}</span>
        <span className={pnlWithdraw > 0 ? "positive-value" : "negative-value"}>${pnlWithdraw?.toFixed(4)} ({pnlPercentageWithdraw?.toFixed(2)}%)</span>
      </article>}
    </article>
  )
}

export const NetValuePerpetualFallback = ({ logsEvents }: NetValuePerpetualProps) => {
  return (
    <article className="upper-row-item">
      <span className="upper-item-title"><FormattedMessage id="logs-short-long.net-value" /></span>
      {logsEvents.length === nodesLogsNumber.NO_EXECUTED_NODE && <article className="first-upper-cell-data">
        <span className="upper-item-values-background">  --- </span>
      </article>}
      {logsEvents.length !== nodesLogsNumber.NO_EXECUTED_NODE && <article className="first-upper-cell-data">
        <span className="upper-item-values-background"> <FormattedMessage id="waiting-for-data" /></span>
      </article>}
    </article>
  )
}
