import { ExtendedRequestInit, useFetch as useFetchInner, useFetchUncachedCb } from '@gluedigital/ruse-fetch-extras'
import useNetworkBaseUrl from 'src/hooks/useNetworkBaseUrl'
import { TokenSentiment } from 'src/routes/RecipeDiagram/helpers/types'

export interface TokenPrice {
  token: string
  price: string
}

const useFetch = <T,>(url: string, opts?: ExtendedRequestInit, key?: KeyType): T => {
  const base: string = useNetworkBaseUrl()
  return useFetchInner<T>(url && (base + url), opts, key)
}
export const usePricesCombo = () => useFetch<TokenPrice[]>('/combo/')
export const useSentimentCombo = () => useFetch<TokenSentiment[]>('/combo/sentiment')

export const useTriggerControl = () => {
  const base: string = useNetworkBaseUrl()
  const fetch = useFetchUncachedCb()
  return async (id: number, nodeID: string) =>
    await fetch(base + '/combo/' + id + '/unblock', { method: 'POST', body: { id, nodeID } })
}
