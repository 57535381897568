import React, { useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { useSelector } from 'react-redux'
import { getPairsAPI } from 'src/api/farms/helpers'
import { useGetLpPriceFromApi } from 'src/api/recipes'
import { nodesLogsNumber } from 'src/constants'
import { NodeExecutionResult } from 'src/types'

interface SpookyDespositLeftLogProps {
  blockNumberAtOtherMoment: number
  executionSteps: number
  myPoolId: string
  withdrawEvent: NodeExecutionResult
  depositEvent: NodeExecutionResult
}
export const SpookyDespositLeftLog = ({ executionSteps, depositEvent, withdrawEvent, blockNumberAtOtherMoment, myPoolId }: SpookyDespositLeftLogProps) => {
  const networkId: string = useSelector((s: any) => (s.network.network))
  const [poolAPR, setPoolAPR] = useState<number | string>()
  const [poolLiquidity, setPoolLiquidity] = useState<string>('?')
  const { FINISHED_NODE, ABORTED_NODE, ACTIVE_NODE, NO_EXECUTED_NODE } = nodesLogsNumber
  const poolAddress: string = myPoolId.length === 42 ? myPoolId : myPoolId.slice(0, -6)

  useEffect(() => {
    if (blockNumberAtOtherMoment !== 0) {
      const pairsAPI = getPairsAPI(networkId)
      pairsAPI.getSinglePairForLogs(poolAddress, blockNumberAtOtherMoment)
        .then((poolData) => {
          setPoolAPR((poolData.poolAPR).toFixed(2))
          setPoolLiquidity(parseInt(poolData.reserveUSD).toLocaleString())
        }).catch((err) => {
          console.log("Error in depositExtraDataLog while getting singlePairInfo:", err)
          setPoolAPR("Not enough info")
          setPoolLiquidity("Not enough info")
        })
    }
  }, [poolAddress, blockNumberAtOtherMoment, networkId])

  const lpAmountDeposited: string = depositEvent?.output?.amount
  const lpDepositUSDValue: number = parseFloat(depositEvent?.extraData?.outputTokenWithPrice?.totalValueUSD)
  let earnedUSDValue: number = 0
  let lpWithdrawUSDValue: string = ''
  const lpInfo = useGetLpPriceFromApi(poolAddress, lpAmountDeposited)
  if (withdrawEvent) {
    lpWithdrawUSDValue = parseFloat(withdrawEvent?.extraData?.withdrawnLpInfo?.totalValueUSD).toFixed(3)
    earnedUSDValue = parseFloat(lpWithdrawUSDValue) - lpDepositUSDValue
  }

  return (
    <>
      <div className="first-column">
        <div className="logs-group-rows">
          <p><FormattedMessage id="farm-modal-log.deposited" />  </p>
          <div className="left-empty-position"></div>
        </div>
        <div className="logs-group-rows">
          {executionSteps === NO_EXECUTED_NODE && <p><FormattedMessage id="farm-modal-log.earned" />  </p>}
          {executionSteps === ACTIVE_NODE && <p><FormattedMessage id="farm-modal-log.current" />  </p>}
          {(executionSteps === FINISHED_NODE || executionSteps === ABORTED_NODE) && <p><FormattedMessage id="farm-modal-log.earned" />  </p>}
          <div className="left-empty-position"></div>
        </div>
        <p><FormattedMessage id="farm-modal-log.apr" />   </p>
        <p><FormattedMessage id="farm-modal-log.liquidity" />  </p>
      </div>
      <div className="second-column">
        <div className="logs-group-rows">
          <p className="main-text-logs"> $ {lpDepositUSDValue.toFixed(3)}</p>
          <p>{lpAmountDeposited} LP</p>
        </div>
        <div className="logs-group-rows">
          {executionSteps === NO_EXECUTED_NODE && <p className="main-text-logs">$ --- </p>}
          {executionSteps === ACTIVE_NODE && <p className="main-text-logs">$ {Number(lpInfo?.totalValueUSD).toFixed(3)}</p>}
          {(executionSteps === FINISHED_NODE || executionSteps === ABORTED_NODE) && <p className="main-text-logs">$ {earnedUSDValue.toFixed(3) === "-0.000" ? "0.000" : earnedUSDValue.toFixed(3)}</p>}
          <p>{lpAmountDeposited} LP</p>
        </div>
        <p>{poolAPR} % </p>
        <p> $ {poolLiquidity}</p>
      </div>
    </>

  )
}
