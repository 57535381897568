import web3 from "src/utils/web3"
import usePromise from "./usePromise"
import { tokensABI } from "src/data/ABIs"
import { BigNumber } from 'ethers'

const getEtherNumberAmountFromAddress = async (tokenAddress: string, amount: string): Promise<string> => {
    if (!amount || !tokenAddress) return '0'
    const token: any = new web3.eth.Contract(tokensABI as any, tokenAddress);
    const decimals: string = await token.methods.decimals().call()
    const FINAL_DECIMALS = 10 ** 6
    let convertedAmount: any
    if (decimals !== '18') {
      const valueDifference: any = BigNumber.from(10).pow(decimals)
      convertedAmount = BigNumber.from(amount).mul(FINAL_DECIMALS).div(valueDifference)
      convertedAmount = Number(convertedAmount) / FINAL_DECIMALS
      return parseFloat(convertedAmount).toFixed(8)
    }
    const bnAmount: BigNumber = BigNumber.from(amount)
    convertedAmount = web3.utils.fromWei(bnAmount.toString(), 'ether')
    return (parseFloat(convertedAmount)).toFixed(15)
  }

export const useEtherAmountFromAddress = (tokenAddress: string, amount: string): string => {
    const etherAmount = usePromise(getEtherNumberAmountFromAddress, tokenAddress, amount)
    return etherAmount
}
