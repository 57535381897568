import React from "react"
import { FormattedMessage } from "react-intl"
import { FarmData, PairDayData } from "src/api/farms/types"
import { useGetLpPriceFromApi, useGetTokenPriceUSDFromApi } from "src/api/recipes"
import { useEndBooAmountFromWithdrawEvent, useLpEarned } from "src/hooks/useFarmsLogs"
import { LPTokenWithPrice } from "src/routes/RecipeDiagram/helpers/types"
import { WithdrawFarmData } from "src/types"

interface EarnedFarmModalLogProps {
  masterchefInfo: PairDayData
  previousData: any
  executionSteps: number
  allFarmLPs: string[]
  lpUSDPriceAtOtherMoment: number
  lpDepositUSDValue: number
  withdrawEvent: any
}
export const EarnedFinalFarmModalLog = ({ previousData, executionSteps, allFarmLPs, lpDepositUSDValue, withdrawEvent, masterchefInfo }: EarnedFarmModalLogProps) => {
  const farmAddress: string = previousData?.data?.pair?.id.slice(0, -6)
  const DECIMAL_USD_ROUNDED: number = 3

  const farmData: FarmData = useLpEarned(previousData.id, previousData.date, executionSteps) || { state: 'Pending', lpDeposited: 'No Info', earned: 'No Info' }
  const totalLpAmount: number = Number(farmData.lpDeposited.slice(0, -2)) + Number(farmData.earned.slice(0, -2))
  const lpDifference: number = totalLpAmount - Number(farmData.lpDeposited.slice(0, -2))

  const lpPriceActiveNode: LPTokenWithPrice = useGetLpPriceFromApi(farmAddress, totalLpAmount.toString())
  const tokenRewardPriceUSD: string = useGetTokenPriceUSDFromApi(masterchefInfo.tokenReward)

  const withdrawFarmData: WithdrawFarmData = withdrawEvent?.extraData?.withdrawFarmData
  const oneLPUSDValue: string = withdrawFarmData?.oneLPUSDValue
  const oneRewardTokenUSDValue: string = withdrawFarmData?.oneRewardTokenUSDValue
  const amountBOOWei: number = useEndBooAmountFromWithdrawEvent(farmAddress, allFarmLPs)
  const amountBOOEther: number = amountBOOWei / 10 ** 18

  const totalLPUSDValueAtOtherMoment: number = executionSteps === 4
    ? parseFloat(oneLPUSDValue) * totalLpAmount
    : Number(lpPriceActiveNode?.totalValueUSD)

  const rewardPriceUsd: number = executionSteps === 4 ? parseFloat(oneRewardTokenUSDValue) : parseFloat(tokenRewardPriceUSD)
  const rewardUSDValue: number = amountBOOEther * rewardPriceUsd
  const totalUSDValue: number = totalLPUSDValueAtOtherMoment + rewardUSDValue
  const differenceEarnedValue: number = totalUSDValue - lpDepositUSDValue

  return (
    <>
      <div id="farm-log-field">
      <h3><FormattedMessage id="farm-modal-log.final-value" /></h3>
      <strong className="span-coin">$ {totalUSDValue.toFixed(DECIMAL_USD_ROUNDED)} </strong>
      <div className="div-2column-lps">
        <span className="lp-amount-row first-column">{totalLpAmount.toFixed(12)} <strong>LPs</strong></span>
      </div>
    </div>
      <div id="farm-log-field">
        <h3><FormattedMessage id="farm-modal-log.earned" /></h3>
        <strong className="span-coin">$ {differenceEarnedValue.toFixed(DECIMAL_USD_ROUNDED)} </strong>
        <div className="div-2column-lps">
          <span className="lp-amount-row first-column">{lpDifference.toFixed(12)} <strong>LPs</strong></span>
        </div>
      </div>
    </>
  )
}
