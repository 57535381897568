import React from 'react'
import { useSelector } from 'react-redux'
import { tokenNameToAddress } from 'src/components/Diagram/nodes/nodesLogsHelper'
import { useGetLPInfo } from 'src/hooks/useLPsLogs'
interface TokenEndFarmLeftLogProps {
  tokenName: string
  totalLP: number
  blockNumberAtOtherMoment: number
  myFarmId: string
}
export const TokenEndFarmLeftLog = ({ tokenName, totalLP, blockNumberAtOtherMoment, myFarmId }: TokenEndFarmLeftLogProps) => {
  const networkId: string = useSelector((s: any) => (s.network.network))
  const DECIMALS_FOR_TOKEN_AMOUNT: number = 12
  const tokenAddress: string = tokenNameToAddress(tokenName, networkId)
  const lpInfo = useGetLPInfo(myFarmId, blockNumberAtOtherMoment, networkId)
  const token0Address: string = tokenNameToAddress(lpInfo?.token0?.symbol, networkId)
  const lpProportion: number = totalLP / Number(lpInfo?.totalSupply)
  let token0Amount: number = null
  let token1Amount: number = null
  if (token0Address === tokenAddress) {
    token0Amount = lpProportion * Number(lpInfo?.reserve0)
  } else {
    token1Amount = lpProportion * Number(lpInfo?.reserve1)
  }

  return (
    <p>{token0Amount === null ? token1Amount.toFixed(DECIMALS_FOR_TOKEN_AMOUNT) : token0Amount.toFixed(DECIMALS_FOR_TOKEN_AMOUNT)} {tokenName}</p>
  )
}
