import { PairDayData } from "src/api/farms/types";

export const fantomFarmModalFilters = (pairs: PairDayData[]): PairDayData[] => {
  const pairsWithoutDEI: PairDayData[] = pairs.filter((pair) => pair?.token0?.symbol !== 'DEI' && pair?.token1?.symbol !== 'DEI')
  const workingFilteredPair: PairDayData[] = pairsWithoutDEI.filter(pair =>
    (pair.token1?.id.toLowerCase() !== "0x04068da6c83afcfa0e13ba15a6696662335d5b75")
    && (pair.token0?.id.toLowerCase() !== "0x04068da6c83afcfa0e13ba15a6696662335d5b75")
    && (pair?.token1?.symbol !== 'sFTMX')
    && (pair?.token0?.symbol !== 'CRV')
    && (pair?.pairAddress !== "0xa196c7754f4ec79de55bb5db82187bbe82275f7f")
    && (pair?.pairAddress.toLowerCase() !== "0x564F85DB8Ba3DbA1F4edEef69EE333f6cf4F79Cd".toLowerCase())
    && (pair?.pairAddress.toLowerCase() !== "0xd7F1dd1b6edbAfd9A2Bd7Fc3CAF39a13b8307375".toLowerCase())
  )
  return workingFilteredPair
}

export const farmValidationCondition = (pair: PairDayData): boolean => {
  return (
    pair?.id !== undefined &&
    pair?.allocPoint !== undefined &&
    pair?.aprFarm !== undefined &&
    pair?.aprFees !== undefined &&
    pair?.pairAddress !== undefined &&
    pair?.provider !== undefined &&
    pair?.reserveUSD !== undefined &&
    pair?.token0 !== undefined &&
    pair?.token1 !== undefined
  )
}
