import React from "react";
import { useSelector } from "react-redux";
import { ApprovalStatus } from "src/routes/RecipeDiagram/helpers/types";
import { getNetworkParams } from "../modals/SelectWalletModal/helpers";

interface RetryDepositListProps {
  amount: string
  fantomStatus: ApprovalStatus
}

const RetryDepositList = (props: RetryDepositListProps) => {
  const { amount, fantomStatus } = props
  const networkId: string = useSelector((s: any) => s.network.network)
  const networkParams = getNetworkParams(networkId)
  return <div className="approval-list">
    <div className="tokens-list">
      <div>
        <span className={`status-icon ${fantomStatus}`} />
        <span className="token-tag"><span className="amount-tag">{amount}</span> {networkParams?.nativeCurrency?.symbol}</span>
        <span className="token-name">{networkParams?.nativeCurrency?.name}</span>
      </div>
    </div>
  </div>
}

export default RetryDepositList
