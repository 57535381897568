import { useDispatch } from "react-redux"
import { showToast, hideToast } from "src/store/actions/toasts"

export const useToast = () => {
  const dispatch = useDispatch()

  return (toastTitle: string, message: string, titleURL?: string) => {
    dispatch(showToast({ toastTitle, typeOfMessage: message, titleURL }))
    setTimeout(() => {
      dispatch(hideToast(toastTitle))
    }, 3000)
  }
}
