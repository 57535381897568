import { BigNumber } from "ethers"
import { tokensABI } from "src/data/ABIs"
import { TokenForApproval } from "src/routes/RecipeDiagram/helpers/types"
import web3 from "src/utils/web3"
import { AbiItem } from 'web3-utils'

export const checkIfTokenIsApproved = async (userAddress: string, tokenForApproval: TokenForApproval, networkParams: any): Promise<boolean> => {
  let isApproved: boolean
  const token = new web3.eth.Contract(tokensABI as AbiItem[], tokenForApproval?.address)
  const result = await token.methods.allowance(userAddress, networkParams.contracts.nodes).call()
  if (BigNumber.from(result).gte(BigNumber.from(tokenForApproval.amount))) isApproved = true
  else isApproved = false
  return isApproved
}

export const simpleTokenApprove = async (
  userAddress: string,
  tokenForApproval: TokenForApproval,
  setStep: (step) => void,
  setFailStep: (step) => void,
  setApprovedClicked: (clicked) => void,
  networkParams: any
): Promise<void> => {
  const token: any = new web3.eth.Contract(tokensABI as AbiItem[], tokenForApproval?.address)
  await token.methods.approve(networkParams.contracts.nodes, tokenForApproval.amount.toString()).send({
    from: userAddress,
    maxPriorityFeePerGas: null,
    maxFeePerGas: null,
  })
    .once('transactionHash', () => { // Invoked when confirm button is pressed in Metamask for approval.
      setApprovedClicked(true)
    })
    .once('confirmation', () => {
      setStep(3)
    })
    .once('error', () => {
      setFailStep(3)
    })
}
