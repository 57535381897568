import React, { memo, useMemo, useState } from "react";
import { Handle } from "react-flow-renderer"
import Buttons from "./Buttons"
import NodeExecutionTag from "./NodeExecutionTag"
import { useCompareAccounts } from "./nodesLogsHelper"
import { useDispatch, useSelector } from "react-redux"
import { useNodeValidator } from "src/hooks/useValidator"
import { focusNode } from "src/store/actions/diagram"
import { ShortLongNodeData } from "src/routes/RecipeDiagram/helpers/types"
import { useAllRecipeDetails, useAllRecipeLogs } from "src/hooks/useRecipeInfo"

import "./ShortLongNode.sass"

interface ShortLongProps {
  data: ShortLongNodeData
  id: string
}
export default memo(({ id, data }: ShortLongProps) => {

  const [showButtons, setShowButtons] = useState<boolean>(false)
  const recipeDetails = useAllRecipeDetails()

  const focusedNode = useSelector((state: any) => state.diagram.focused)
  const dispatch = useDispatch()

  const positionTarget: any = "left"
  const positionSource: any = "right"

  const networkId: string = useSelector((s: any) => (s.network.network))
  const account = useSelector((s: any) => (s.user ? s.user.account : undefined))
  const userAddress = account?.account.slice(2)
  const diagramisValid = useSelector((s: any) => s.validation.validated)

  const recipeLogs = useAllRecipeLogs()
  const compareAddresses: boolean = useCompareAccounts(userAddress)
  const handleBlur = (e: React.FocusEvent<HTMLDivElement>) => {
    if (!e.currentTarget.contains(e.relatedTarget as Node)) {
      setShowButtons(false)
      dispatch(focusNode())
    }
  }

  const handleShowButtons = () => {
    dispatch(focusNode(id))
    if (compareAddresses) {
      setShowButtons(true)
    }
  }

  const isValid = useNodeValidator(id, data, "shortLongNode", recipeDetails, networkId)

  const handleNodeStyle = useMemo(() => {
    if (!compareAddresses) {
      return ''
    } else if (recipeDetails?.status === 'failed') {
      return 'node-handle-failed'
    } else if (diagramisValid || recipeDetails?.status === 'active') {
      return 'handle-valid'
    } else if (data.inputCoin) {
      return 'handle-connected'
    } else {
      return 'node-handle-private'
    }
  }, [compareAddresses, diagramisValid, data.inputCoin])

  return (
    <div className={`node ${focusedNode && focusedNode !== id ? 'node-unfocused' : ''}`} onClick={handleShowButtons} onBlur={handleBlur} tabIndex={0}>
      {(recipeDetails?.status === "finished" || recipeDetails?.status === "active" || recipeDetails?.status === "failed") &&
      <NodeExecutionTag nodeID={id} maxExecutionSteps={3} />}
      <div className={"short-long"} >
        {compareAddresses && !isValid && <span className="icon icon-exclamation" />}
        <Handle style={{ top: 50 }} className={`node-handle ${handleNodeStyle}`} type="target" position={positionTarget} id="short-long-input" />
        <Handle style={{ top: 50 }} className={`node-handle ${handleNodeStyle}`} type="source" position={positionSource} id="short-long-output" />
      </div>
      <div className="node-info">
        <span>  {data.operation} to {data.operationCoin}</span>
      </div>
      {
        showButtons &&
        <Buttons
          data={data} id={id}
          editModalType={"short-long-modal"}
          recipeLogs={recipeLogs}
          recipeDetails={recipeDetails}
          viewDetailsModalType={"short-long-modal-log"}
        />
      }
    </div>
  );
});
