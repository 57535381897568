import React from "react"
import { FormattedMessage } from "react-intl"
import { TokenPrice, usePricesCombo } from "src/api/comboTrigger/comboApi"
import { ComboTriggerModalData, ConditionProvider, ProfitTriggerData, TokenCondition } from "src/routes/RecipeDiagram/helpers/types"

import "./ChainLinkPerformanceType.sass"
import { useTriggerTokenInput } from "src/components/modals/nodeModals/ComboTriggerModal/hooks/useTriggerTokenInput"

interface ChainLinkPerformanceTypeProps {
  triggerData: ProfitTriggerData
  setTriggerData: (data: ComboTriggerModalData) => void
}

const ChainLinkPerformanceType = ({ setTriggerData, triggerData }: ChainLinkPerformanceTypeProps) => {
  const condition = triggerData ? triggerData.condition as TokenCondition : {}
  const conditionProvider = ConditionProvider.chainlink
  const coinPrices: TokenPrice[] = usePricesCombo()

  const {
    getValue,
    inputCondition,
    inputConditionValue,
    // currentPrice
  } = useTriggerTokenInput(triggerData)

  const handleInput = () => {
    const value = getValue()
    setTriggerData({ condition: { ...condition, value }, conditionProvider })
  }
  const handleConditionType = type => {
    setTriggerData({ condition: { ...condition, type }, conditionProvider })
  }
  const handleCoinToCompare = coinToCompare => {
    setTriggerData({ condition: { ...condition, coinToCompare }, conditionProvider })
  }
  return (
    <div className="performance-type performance-type-chainlink">
      <div className="row">
        <section>
          <h2>
            {/* <FormattedMessage id="deposit-lp-modal.provider" />
            <FormattedMessage id="chainlink" />
            <FormattedMessage id="combo-trigger-modal.says" /> */}
            <FormattedMessage id="if" />
          </h2>
          <select value={condition?.coinToCompare} onChange={(e) => handleCoinToCompare(e.target.value)}>
            <FormattedMessage id="select.currency">
              {text => <option value="" hidden>{text}</option>}
            </FormattedMessage>
            {coinPrices.map((tokenPrice) => (
              <option key={tokenPrice.token} value={tokenPrice.token}>{`${tokenPrice.token} $${tokenPrice.price}`}</option>
            ))}
          </select>
        </section>
      </div>
      <div className="row">
        <section>
          <h2><FormattedMessage id="combo-trigger-modal.is" /></h2>
          <select
            value={condition?.type}
            onChange={(e) => {
              const type: any = e.target.value
              handleConditionType(type)
            }}
          >
            <FormattedMessage id="select.currency">
              {text => <option value="" hidden>{text}</option>}
            </FormattedMessage>
            <FormattedMessage id="combo-trigger-modal.lower">
              {(text: string) => <option value="lower">{text}</option>}
            </FormattedMessage>
            <FormattedMessage id="combo-trigger-modal.higher">
              {(text: string) => <option value="higher">{text}</option>}
            </FormattedMessage>
          </select>
        </section>
        <section>
          <h2><FormattedMessage id="combo-trigger-modal.than" /></h2>
          <label>
            <input
              ref={inputCondition}
              value={inputConditionValue}
              onChange={() => {
                handleInput()
              }}
              placeholder="Value..."
              min={0}
              step={0.1}
              pattern="\d*"
              inputMode="decimal"
            />
            <span>USD</span>
          </label>
        </section>
      </div>
      {/* <span className="span-current">
        <FormattedMessage id="combo-trigger-modal.current-price" />
      </span>
      <span className="span-coin-price">{' '}{condition?.coinToCompare} {currentPrice}$</span> */}
    </div>
  )
}

export default ChainLinkPerformanceType
