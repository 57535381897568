import React, { Suspense } from 'react'
import { Recipe } from 'src/types'
import ErrorBoundary from '../../ErrorBoundary/ErrorBoundary'
import NoApr from '../../RecipeCardInfo/NoApr/NoApr'
import RecipeInfo from '../../RecipeCardInfo/RecipeCardInfo'
import StrategyInfo from '../../RecipeCardInfo/StrategyInfo/StrategyInfo'
import TokenList from '../../TokenList/TokenList'
import Spinner from '../../common/Loading/Spinner/Spinner'

import './RecipeCard.sass'

interface RecipeCardProps {
  recipe: Recipe
  type?: 'strategy' | 'recipe'
  onlyInfo?: boolean
  imageUrl?: string
}

const RecipeCard = ({ recipe, type = "recipe", onlyInfo = false, imageUrl }: RecipeCardProps) => {
  const { title, short } = recipe
  return (
    <article className="recipe-card">
      <header style={{ backgroundImage: "url(" + (imageUrl || "/images/landing/recipe-card/default.png") + ")" }}>
        <h1 title={title}>{title.length > 53 ? `${title.slice(0, 50)}...` : title}</h1>
      </header>
      {!onlyInfo && <p title={short}>{short.length > 73 ? `${short.slice(0, 70)}...` : short}</p>}
      <footer>
        <ErrorBoundary fallback={<NoApr />}>
          <Suspense fallback={<Spinner />}>
            {type === 'recipe' ? <RecipeInfo recipe={recipe} /> : <StrategyInfo recipe={recipe} />}
          </Suspense>
        </ErrorBoundary>
        <Suspense fallback={<Spinner />}>
          <TokenList tokenList={recipe.tokenList} />
        </Suspense>
      </footer>
    </article>
  )
}

export default RecipeCard
