import React, { useEffect, useState } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import { registerModal, CloseModal } from "@gluedigital/modal"
import { findNodeInActiveRecipe, generateShowingTime, getAmountPercentage, getCurrentEventForModals, getCurrentRowLog } from "src/components/Diagram/nodes/nodesLogsHelper"
import { SplitModalData } from "src/routes/RecipeDiagram/helpers/types"
import NodeStateAndDate from "../NodeStateAndDate/NodeStateAndDate"
import { RecipeDetails, RecipeExecutionLog } from "src/types"
import { getEtherNumberAmountFromAddress } from "src/contracts/TokensDecimalsHelpers"
import { tokenNameToAddress } from '../../../Diagram/nodes/nodesLogsHelper'
import PriceModalLogs from "../../nodeModals/PriceModalLogs"

import "./SplitModalLog.sass"
import { ModalErrorBoundary } from "src/components/ErrorBoundary/ErrorBoundary"
import { useSelector } from "react-redux"

interface SplitLogModalProps {
  previousData: {
    data: SplitModalData
    id: string
    recipeLogs: RecipeExecutionLog[]
    recipeDetails: RecipeDetails
  }
}

const SplitModalLog = ({ previousData }: SplitLogModalProps) => {
  const DECIMALS_FOR_TOKEN_AMOUNT: number = 10
  const intl = useIntl()
  const currency: string = previousData?.data.inputCoin || intl.formatMessage({ id: 'split-modal.connect' })
  const [inputAmount, setInputAmount] = useState(0)
  const firstCoin: string = previousData?.data.firstCoin || "BTC"
  const secondCoin: string = previousData?.data.secondCoin || "FTM"
  const firstPercentage: number = previousData?.data.firstPercentage || 50
  const secondPercentage: number = previousData?.data.secondPercentage || 50
  const recipeDetails = previousData?.recipeDetails
  const recipeLogs = previousData.recipeLogs
  const nodeId = previousData.id
  const currentEvent = getCurrentEventForModals(recipeLogs, nodeId)
  const networkId: string = useSelector((s: any) => (s.network.network))

  const [outputAmount1, setOutputAmount1] = useState<string>()
  const [outputAmount2, setOutputAmount2] = useState<string>()

  useEffect(() => {
    const currentEvent = getCurrentEventForModals(recipeLogs, nodeId)
    getEtherNumberAmountFromAddress(tokenNameToAddress(previousData?.data.firstCoin, networkId), currentEvent?.extraData?.outputTokenWithPrice[0]?.amount.split('.').join('')).then((amount) => setOutputAmount1(Number(amount).toFixed(DECIMALS_FOR_TOKEN_AMOUNT))).catch(err => console.error(err.message));
    getEtherNumberAmountFromAddress(tokenNameToAddress(previousData?.data.secondCoin, networkId), currentEvent?.extraData?.outputTokenWithPrice[1]?.amount.split('.').join('')).then((amount) => setOutputAmount2(Number(amount).toFixed(DECIMALS_FOR_TOKEN_AMOUNT))).catch(err => console.error(err.message));
    getEtherNumberAmountFromAddress(tokenNameToAddress(previousData?.data.inputCoin, networkId), currentEvent?.input?.amount)
      .then((amount) => setInputAmount(Number(amount)))
      .catch(err => console.error(err.message))
  }, [recipeLogs, nodeId, firstCoin, previousData?.data.inputCoin, previousData?.data.firstCoin, previousData?.data.secondCoin, networkId])

  let isNodeExecuted: boolean;
  let currentRowLog: any;
  let showingDate: string;

  if (recipeLogs !== null) isNodeExecuted = findNodeInActiveRecipe(recipeLogs, nodeId)
  if (isNodeExecuted) {
    currentRowLog = getCurrentRowLog(recipeLogs, nodeId)
    showingDate = generateShowingTime(currentRowLog)
  }

  return (
    <ModalErrorBoundary>
    <div className="split-modal modal-content">
      <div className="modal-wrapper">
        <header className="header-buttons">
          <CloseModal>
            <button>
              <span className="icon icon-close" />
              <span><FormattedMessage id="close" /></span>
            </button>
          </CloseModal>
          <h1><FormattedMessage id="split-modal.title" /></h1>
          <NodeStateAndDate isNodeExecuted={isNodeExecuted} showingDate={showingDate} recipeDetails={recipeDetails} />
        </header>

        <section className="split-modal-data">
          <div className="from">
            <h2><FormattedMessage id="swap-modal.amount.from" /></h2>
            <strong>{currency}</strong>
          </div>
          <div className="to">
            <h2><FormattedMessage id="swap-modal.amount.title" /></h2>
            <strong>{inputAmount}</strong>
          </div>
        </section>
        <div className="split-modal-grid">
          <section className="split-modal-currency">
            <div className="input-wrapper">
              <span className="result-coins">{firstCoin} {firstPercentage} %</span>
            </div>
          </section>

          <section className="split-modal-currency">
            <div className="input-wrapper">
              <span className="result-coins">{secondCoin} {secondPercentage} %</span>
            </div>
          </section>
          <div className="div-received">
            <span className="span-received"><FormattedMessage id="view-logs-received" />  {outputAmount1 || '?'} {firstCoin}</span>
          </div>
          <div className="div-received">
            <span className="span-received"><FormattedMessage id="view-logs-received" />   {outputAmount2 || '?'} {secondCoin}</span>
          </div>
            {isNodeExecuted &&
              <div className="second-column">
                <PriceModalLogs
                  tokenInput={currency}
                  tokenOutput={firstCoin}
                  inputFeeAmount={getAmountPercentage(inputAmount, firstPercentage)}
                  inputAmount={currentEvent?.extraData?.inputTokenWithPrice?.priceUSD}
                  outputAmount={currentEvent?.extraData?.outputTokenWithPrice[0]?.priceUSD}
                  events={currentEvent} />
              </div>
            }
            {isNodeExecuted &&
              <div className="second-column">
                <PriceModalLogs
                  tokenInput={currency}
                  tokenOutput={secondCoin}
                  inputFeeAmount={getAmountPercentage(inputAmount, secondPercentage)}
                  inputAmount={currentEvent?.extraData?.inputTokenWithPrice?.priceUSD}
                  outputAmount={currentEvent?.extraData?.outputTokenWithPrice[1]?.priceUSD}
                  events={currentEvent} />
              </div>
            }
        </div>
      </div>
    </div>
    </ModalErrorBoundary>
  )
}

registerModal("split-modal-log", SplitModalLog)
