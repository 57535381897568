import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Link } from 'react-router-dom'
import Loading from 'src/components/common/Loading/Loading'
import logo from 'src/static/images/apr-bg.png'
import arrow from 'src/static/images/back-arrow-white.svg'
import './AprDetails.sass'

const LoadingAprDetails = () => {
  const emptyTable: JSX.Element = <>
    <table cellPadding={10} id={`table-events-input`} className="table-events">
      <thead>
        <tr>
          <th> <FormattedMessage id="apr-details.table-input" /> </th>
          <th> <FormattedMessage id="apr-details.table-date" />  </th>
          <th> <FormattedMessage id="apr-details.table-value" /> </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td> -------- </td>
          <td> -------- </td>
          <td> -------- </td>
        </tr>
      </tbody>
    </table>
  </>
  return (
    <div id="apr-details" className="page">
      <img className="apr-image" src={logo} alt="apr illustration" />
      <div className="container">
        <header>
          <Link className="back-link" to={"/dashboard/liquidated"}>
            <img src={arrow} />
            <span><FormattedMessage id="back" /></span>
          </Link>
        </header>
        <section className="recipe-details">
          <h1><FormattedMessage id="apr-details.loading-title" /> </h1>
        </section>
        <section className="recipe-specific">
          <h3 className={`apr-info`}>
            <strong><FormattedMessage id="apr-details.roi-info" /></strong>
            <span>... %</span>
          </h3>
          <h3 className="earned-info">
            <strong><FormattedMessage id="apr-details.earned-info" /></strong>
            <span>$ ...</span>
          </h3>
          <h3 className="elapsed-info">
            <strong><FormattedMessage id="apr-details.elapsed-info" /></strong>
          </h3>
          <div className="refresh-wrapper">
          </div>
        </section>
        <section className="events">
          <div className="input-table">
            <h3><FormattedMessage id="apr-details.input-title" /></h3>
            {emptyTable}
          </div>
          <div className="output-table">
            <h3><FormattedMessage id="apr-details.output-title" /></h3>
            {emptyTable}
          </div>
        </section>
        <section>
          <Loading />
        </section>
      </div>
    </div>
  )
}

export default LoadingAprDetails
