import { useSelector } from "react-redux"
import getAPIbyNetwork from "src/utils/networkHelper"

const useNetworkBaseUrl = (): string => {
  const networkId: string = useSelector((s: any) => (s?.network?.network))
  const baseUrl: string = getAPIbyNetwork(networkId)
  return baseUrl
}

export default useNetworkBaseUrl
