import React from "react"
import { getEtherStringAmountFromAddressFixed15, getTokenDecimals } from "src/contracts/TokensDecimalsHelpers"
import usePromise from "src/hooks/usePromise"
import { NodeExecutionResult } from "src/types"

interface EndYearnLeftLogProps {
  previousData: any
  nodeEvents: NodeExecutionResult[]
  depositPriceUSD: string
  amountYTokensDepositedEth: string
  yVaultAddress: string
  vaultTokenName: string
  depositTokenAmountEth: number

}
export const EndYearnLeftLog = ({ previousData, nodeEvents, depositPriceUSD, depositTokenAmountEth, yVaultAddress, vaultTokenName }: EndYearnLeftLogProps) => {
  const DECIMAL_USD_ROUNDED: number = 4

  const withdrawEvent = nodeEvents.find((event) => event.functionName === "withdrawFromNestedStrategy" && event.nodeID === previousData?.id)
  const finalTokenAddress: string = withdrawEvent?.output?.token
  const vaultTokenDecimals: number = Number(usePromise(getTokenDecimals, finalTokenAddress))
  const endSwapEvent: NodeExecutionResult = nodeEvents.find((ev) => ev.functionName === 'swapTokens' && ev.input.token.toLowerCase() === finalTokenAddress.toLowerCase())
  const finalTotalInUSD: number = endSwapEvent.extraData?.outputTokenWithPrice.totalValueUSD
  const finalTokensAmountWei: number = withdrawEvent?.extraData?.pricePerShare?.amount
  const finalTokensAmountEth: string = usePromise(getEtherStringAmountFromAddressFixed15, yVaultAddress, finalTokensAmountWei.toString())
  const earnedPriceUSD: number = finalTotalInUSD - parseFloat(depositPriceUSD)
  let earnedTokenAmount: number = Number(finalTokensAmountEth) - depositTokenAmountEth
  if (earnedTokenAmount < 0.00001) earnedTokenAmount = 0

  return (
    <>
       {/* FINAL VALUE */}
       <div className="logs-group-rows">
        <p className="main-text-logs">$ {(Number(finalTotalInUSD)).toFixed(DECIMAL_USD_ROUNDED)} </p>
        <p> {Number(finalTokensAmountEth).toFixed(vaultTokenDecimals === 18 ? 12 : vaultTokenDecimals)} {vaultTokenName}</p>
      </div>
      {/* EARNED VALUE */}
      <div className="logs-group-rows">
        <p className="main-text-logs">$ {(Number(earnedPriceUSD)).toFixed(DECIMAL_USD_ROUNDED)} </p>
        <p> {earnedTokenAmount.toFixed(vaultTokenDecimals === 18 ? 12 : vaultTokenDecimals)} {vaultTokenName}</p>
      </div>
    </>
  )
}
