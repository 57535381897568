import React from 'react'
import { FormattedMessage } from 'react-intl'

import "./ComboTimeModalLog.sass"

export interface ComboTimeModalLogProps {
  data: any
  isNodeExecuted: any
  recipeLogs: any
}
const ComboTimeModalLog = ({ data, isNodeExecuted, recipeLogs }: ComboTimeModalLogProps) => {
    let period: boolean = false
    let exact: boolean = false
    let date, hour, minutes, timeframe
    let one: boolean = false
    let seven: boolean = false
    let month: boolean = false
    let exactText: string

    let executableDate
    if (!isNodeExecuted) {
       const unixDate = Math.floor(new Date(recipeLogs[0].date).getTime() / 1000) + data.condition.value
       executableDate = new Intl.DateTimeFormat('en-US', { year: 'numeric', month: '2-digit', day: '2-digit' }).format(new Date(unixDate * 1000))
    }

    if (data.condition.timeComparison === 'period') {
        period = true
        timeframe = data.condition.value / 3600 / 24
        if (timeframe === 1) {
            one = true
        } else if (timeframe === 7) {
            seven = true
        } else if (timeframe > 30) {
            month = true
        }
    }
    if (data.condition.timeComparison === 'exact') {
        exact = true
        date = new Intl.DateTimeFormat('en-US', { year: 'numeric', month: '2-digit', day: '2-digit' }).format(data.condition.value)
        hour = new Date(data.condition.value).getHours()
        minutes = new Date(data.condition.value).getMinutes()
        exactText = date.toString() + ' at ' + hour.toString() + ':' + minutes.toString()
    }

    return (
    <>
        <div className="combo-grid" id="combo-trigger-time-modal-log">
        { !isNodeExecuted &&
        <span className="exectuable-time">
            {exact
            ? <p className="exectuable-time"><FormattedMessage id="combo-type-DCA.text" /> {exactText} </p>
            : <p className="exectuable-time"><FormattedMessage id="combo-type-DCA.text" />{executableDate.toString()} </p>
            }
        </span>
        }
        <section className={"section-0" + period}>
            <h2><FormattedMessage id="timeframe-after" /></h2>
            <div className={"time-frames-period-" + period}>
                <div className={"timeframe-24h" + one}>
                    <FormattedMessage id={"timeframe-24h"} />
                </div>
                <div className={"timeframe-7d" + seven}>
                    <FormattedMessage id={"timeframe-7d"} />
                </div>
                <div className={"timeframe-1m" + month}>
                    <FormattedMessage id={"timeframe-1m"} />
                </div>
            </div>
        </section>
        <section className={"section-1" + exact}>
            <h2><FormattedMessage id="timeframe-on" /></h2>
            <div className={"time-frames-exact-" + exact}>
            <label>
                { exact
                ? <div className="timeframe-dates"> {date} </div>
                : <div className="timeframe-dates"> {'00/00/00'} </div>
                }
            </label>
            <label>
                {exact
                ? <div className="timeframe-hours"> {hour + ':' + minutes} </div>
                : <div className="timeframe-hours"> {'00:00'} </div>
                }
                <span className="icon icon-clock inqueue-clock" />
            </label>
            </div>
        </section>
        </div>
    </>
    )
}

export default ComboTimeModalLog
