import React from "react"
import { FormattedMessage } from "react-intl"
export const NoExecutedFarmModalLog = () => {
  return (
    <>
      <div id="farm-log-field">
        <h3><FormattedMessage id="farm-modal-log.deposited" /></h3>
        <strong className="span-coin">No executed</strong>
      </div>
      <div id="farm-log-field">
        <h3><FormattedMessage id="farm-modal-log.current-value" /></h3>
        <strong className="span-coin">No executed</strong>
      </div>
      <div id="farm-log-field">
        <h3><FormattedMessage id="farm-modal-log.earned" /></h3>
        <strong className="span-coin">No executed</strong>
      </div>
    </>
  )
}
