import React from 'react'
import { FormattedMessage } from 'react-intl'
import { useFeaturedTrendingRecipes } from 'src/api/recipes'
import trendingRecipes from 'src/data/trendingRecipes.json'
import trendingRecipesCandidate from 'src/data/trendingRecipesCandidate.json'
import { ENV_ENVIROMENT } from "src/constants"
import ButtonsCardMobile from '../ButtonCardMobile/ButtonsCardMobile'
import NoRecipesMobile from '../NoRecipesMobile/NoRecipesMobile'
import RecipeCardMobile from '../RecipeCardMobile/RecipeCardMobile'
import { useSelector } from 'react-redux'

const TrendingRecipes = () => {
  const networkId: string = useSelector((s: any) => s.network.network)
  const trendingToUse = ENV_ENVIROMENT === "MAINNET_PRO"
    ? trendingRecipes.filter(r => r.network === networkId)
    : trendingRecipesCandidate.filter(r => r.network === networkId)
  const recipes = useFeaturedTrendingRecipes(trendingToUse.map(t => t?.id))
  if (recipes.length === 0) return <NoRecipesMobile />

  return (
    <section className="mobile-subsection-recipes" id="trending-recipes">
      <h2>
        <span><FormattedMessage id="mobile-section-recipes.trending-title" /></span>
        <span className="mobile-icon mobile-icon-ribbon" />
      </h2>
      <div className="mobile-recipe-grid">
        {recipes.map(r =>
          <RecipeCardMobile key={r.id} recipe={r} withEarned={false}>
            <ButtonsCardMobile recipe={r} mode="deposit" />
          </RecipeCardMobile>
        )}
      </div>
    </section>
  )
}

export default TrendingRecipes
