import React, { useState } from "react"
import { useSelector } from "react-redux"
import { FormattedMessage } from "react-intl"
import { registerModal, CloseModal } from "@gluedigital/modal"
import { LiquidateModalData } from "src/routes/RecipeDiagram/helpers/types"
import { BaseNodeModal } from "../BaseNodeModal"
import { useOrderTokens } from "src/hooks/useOrderTokensInModals"
import "./LiquidateModal.sass"

interface LiquidateModalPropTypes extends BaseNodeModal<LiquidateModalData> { }

const LiquidateModal = (props: LiquidateModalPropTypes) => {
  const { onSave, previousData } = props

  const [outputCoin, setOutputCoin] = useState(previousData?.outputCoin || "")
  const networkId: string = useSelector((s: any) => (s.network.network))

  const sortedCoins = useOrderTokens(networkId)

  return (
    <div className="liquidate-modal modal-content">
      <div className="modal-wrapper">
        <header className="header-buttons">
          <CloseModal>
            <button className="modal-close-button">
              <span className="icon icon-close" />
              <span><FormattedMessage id="close" /></span>
            </button>
          </CloseModal>
          <h1><FormattedMessage id="liquidate-modal.title" /></h1>
          <CloseModal>
            <button
              disabled={!outputCoin}
              onClick={() => onSave({ outputCoin })}
              className="filled"
            >
              <FormattedMessage id="save" />
            </button>
          </CloseModal>
        </header>
        <section>

          <div className="currency-wrapper">
            <strong><FormattedMessage id="liquidate-modal.currency" /></strong>
            <div className="div-select-coin">
              <span className="span-coin">Coin</span>
              <select onChange={e => setOutputCoin(e.target.value)} defaultValue={outputCoin}>
                <FormattedMessage id="select.currency">
                  {text => <option value="" hidden>{text}</option>}
                </FormattedMessage>
                {sortedCoins.map(c => (
                  <option key={c.id} value={c.id}>{c.id}</option>
                ))}
              </select>
            </div>
          </div>
        </section>
      </div>
    </div>
  )
}

registerModal("liquidate-modal", LiquidateModal)
