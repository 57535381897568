import React from "react"
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import { usePairTokensByAddress } from "src/hooks/useLPsLogs";

const SpookySwapComboExtraDataLog = ({ data }: any) => {
    const farmId: string = (data.condition.farmID).toLowerCase()
    const networkId: string = useSelector((s: any) => (s.network.network))
    const tokens = usePairTokensByAddress(farmId, networkId)
    const token0: string = tokens.data.pair.token0.symbol
    const token1: string = tokens.data.pair.token1.symbol
    return (
        <> <div className="first-column">
            <p><FormattedMessage id="view-logs-combo-provider" />  </p>
            <p><FormattedMessage id="view-logs-combo-farm-compare" />  </p>
            <p><FormattedMessage id="view-logs-combo-condition" />   </p>
        </div>
            <div className="second-column">
                <p>{data.conditionProvider}</p>
                <p>{`${token0}-${token1}`}</p>
                <p> Apr {data.condition.type} <FormattedMessage id="combo-trigger-modal.than" /> {data.condition.value} % </p>
            </div></>
    )
}

export default SpookySwapComboExtraDataLog
