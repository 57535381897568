import React, { useState } from "react"
import { usePopperTooltip } from "react-popper-tooltip"
import { TokensBeetsPools } from "src/api/beets/beetsTypes"
import { getBeetsPoolsTokenImage, getTokenImage } from "src/api/farms/helpers"
import './TokensTooltip.sass'

interface TokensToolTipProps {
  tokens: any[]
}
export const TokensToolTip = ({ tokens }: TokensToolTipProps) => {
  const [isVisible, setIsVisible] = useState<boolean>(false)
  const {
    getArrowProps,
    getTooltipProps,
    setTooltipRef,
    setTriggerRef,
    visible,
  } = usePopperTooltip({
    trigger: 'hover',
    placement: tokens.length > 1 ? 'right' : 'top-end',
    closeOnOutsideClick: false,
    visible: isVisible,
    onVisibleChange: setIsVisible
  })
  const sortWeightTokens: TokensBeetsPools[] = tokens.sort((a, b) => Number(b?.weight) - Number(a?.weight))
  return (
    tokens.length > 2 ?
      (
        <div className="tooltip-component">
          <div className="question-content" ref={setTriggerRef}><img className="logo" src={getBeetsPoolsTokenImage(tokens.length)} /></div>

          {visible && (
            <div ref={setTooltipRef} {...getTooltipProps({ className: 'tooltip-container' })}>
              {sortWeightTokens.map((token, i) => (
                <div key={i} className="tokens-tooltip-data">
                  <img className="logo" src={getTokenImage(token.symbol)} />
                  <span>{token.symbol} : {token?.weight ? (Number(token?.weight) * 100).toFixed(2) : "--"} %</span>
                </div>
              ))}
              <div {...getArrowProps({ className: 'tooltip-arrow' })} />
            </div>
          )}
        </div>)
      :
      (
        <div className="tooltip-component">
          <div className="question-content" ref={setTriggerRef}><img className="logo" src={getTokenImage(tokens[0].symbol)} /></div>

          {visible && (
            <div ref={setTooltipRef} {...getTooltipProps({ className: 'tooltip-container' })}>
              <div className="tokens-tooltip-data">
                {/* <img className="logo" src={getTokenImage(token.symbol)} /> */}
                <span>{tokens[0].symbol} : {tokens[0].weight ? (Number(tokens[0].weight) * 100).toFixed(2) : "--"} %</span>
              </div>
              <div {...getArrowProps({ className: 'tooltip-arrow' })} />
            </div>
          )}
        </div>)

  )
}
