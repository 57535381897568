import React, { Suspense } from 'react'
import { Recipe } from 'src/types'
import Spinner from '../../../../components/common/Loading/Spinner/Spinner'
import ErrorBoundary from '../../../../components/ErrorBoundary/ErrorBoundary'
import VapyEarnedInfo from 'src/components/VapyEarnedInfo/VapyEarnedInfo'
import NoVapy from 'src/components/VapyEarnedInfo/NoVapy/NoVapy'
import VerifiedCheck from 'src/components/VerifiedCheck/VerifiedCheck'

import './RecipeCardMobile.sass'

interface RecipeCardMobileProps {
  recipe: Recipe
  withEarned?: boolean
  onlyInfo?: boolean
  children?: React.ReactNode
}

const RecipeCardMobile = ({ recipe, onlyInfo = false, children, withEarned = false }: RecipeCardMobileProps) => {
  const { title, short } = recipe
  return (
    <article className="recipe-card-mobile">
      <h1 title={title}>
        {recipe?.verified && <span><VerifiedCheck colors={{ check: 'white', bg: '#00B412' }} /></span>}

        <span>{title.length > 53 ? `${title.slice(0, 50)}...` : title}</span>
      </h1>
      {!onlyInfo && !!short.length && <p title={short}>{short.length > 73 ? `${short.slice(0, 70)}...` : short}</p>}
      <section className="recipe-card-info-section">
        <ErrorBoundary fallback={<NoVapy />}>
          <Suspense fallback={<Spinner />}>
            <VapyEarnedInfo withEarned={withEarned} withTokens recipe={recipe} />
          </Suspense>
        </ErrorBoundary>
      </section>
      <footer>
        {children}
      </footer>
    </article>
  )
}

export default RecipeCardMobile
