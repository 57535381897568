import React from "react"
import { FormattedMessage } from "react-intl"
import { registerModal, CloseModal } from "@gluedigital/modal"
import "./ViewDescriptionModal.sass"
import { RecipeDetails } from "src/types"

const ViewDescriptionModal = (props: RecipeDetails) => {
  const title: string = props.title;
  const short: string = props?.short;
  const extended: string = props?.extended;
  const extendedByLines = extended.split('\n')

  return (
    <div className="modal-content">
      <div className="modal-wrapper">
        <header className="header-buttons">
          <CloseModal>
            <button>
              <span className="icon icon-close icon-close-updated" />
              <span className="grey-color"><FormattedMessage id="close" /></span>
            </button>
          </CloseModal>
          <h2><FormattedMessage id="view-logs-recipe-details" /></h2>
        </header>
        <div className="recipe-name-modal-wrapper">
          <section>
            <h3 className="feature-name" ><FormattedMessage id="new-recipe.name" /></h3>
            <p className="modal-recipe-info">{title}</p>
          </section>
          <section>
            <h3 className="feature-name" ><FormattedMessage id="new-recipe.short-description" />
              <span className="optional-feature"><FormattedMessage id="new-recipe.description-optional" /></span>
            </h3>
            <p className={`modal-recipe-info${short === '' ? '-empty' : ''}`}>{short}</p>
          </section>
          <section>
            <h3 className="feature-name" ><FormattedMessage id="new-recipe.extended-description" />
              <span className="optional-feature"><FormattedMessage id="new-recipe.description-optional" /></span>
            </h3>
            <div className={`modal-recipe-info${extended === '' ? '-empty' : ''}`}>
              {extendedByLines.map((line, index) =>
                <p key={index}>{line}</p>
              )}
            </div>
          </section>
        </div>
      </div>
    </div>
  )
}

registerModal("view-description-modal", ViewDescriptionModal)
