const initialState = {
    network: undefined
}

export default (state = initialState, action) => {
    switch (action.type) {
        case 'setNetwork':
            return { ...state, network: action.payload }
        case 'loadUserData':
            return { ...state, network: action.payload.network }
        default:
            return state
    }
}
