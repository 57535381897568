import React from "react"
import { useSelector } from "react-redux"
import ToastItem, { ToastProps } from 'src/components/common/RecipeToasts/ToastItem';
import "./ToastList.sass"

interface ToastListProps {
  darkmode?: boolean
}

const ToastList = ({ darkmode }: ToastListProps) => {
  const toasts = useSelector((s: any) => s.toasts.toasts)

  return (
    <div className="toast-list" >
      {toasts.map((toast: ToastProps, index) => {
        return <ToastItem key={`${toast.toastTitle}-${toast.typeOfMessage}-${index}`} {...toast} darkmode={darkmode} />
      })}
    </div>
  )
}

export default ToastList
