import React from 'react'
import { WithdrawFarmData } from 'src/types'
import web3 from 'src/utils/web3'
interface EarnedFarmLeftLogProps {
  lpDeposited: number
  totalLPAmount: number
  lpUSDValueAtDeposit: number
  withdrawEvent: any
}
export const FinishedFarmLeftLog = ({ lpDeposited, totalLPAmount, withdrawEvent, lpUSDValueAtDeposit }: EarnedFarmLeftLogProps) => {
  const withdrawFarmData: WithdrawFarmData = withdrawEvent?.extraData?.withdrawFarmData
  const lpWithdrawnEther: number = Number(web3.utils.fromWei(withdrawFarmData?.lpAmountWithdrawn, 'ether'))

  const totalLPUSDValueAtOtherMoment: number = parseFloat(withdrawFarmData.oneLPUSDValue) * totalLPAmount

  const totalUSD: number = totalLPUSDValueAtOtherMoment + parseFloat(withdrawFarmData.rewardTokenUSDValue)
  let earnedUSDTotal: string = (totalUSD - lpUSDValueAtDeposit).toFixed(3)
  if (earnedUSDTotal === "-0.000") earnedUSDTotal = "0.000"
  let earnedLPTotal: string = (lpWithdrawnEther - lpDeposited).toFixed(12)
  if (earnedLPTotal.startsWith("-0.000000000")) earnedLPTotal = "0.000000000000"

  return (
    <>
      {/* FINAL VALUE */}
        <div className="logs-group-rows">
          <p className="main-text-logs">$ {totalLPUSDValueAtOtherMoment.toFixed(3)} </p>
          <p>{lpWithdrawnEther.toFixed(12)}  LPs</p>
        </div>

     {/* EARNED */}
      <div className="logs-group-rows">
        <p className="main-text-logs">$ {earnedUSDTotal}</p>
        <p>{earnedLPTotal} LPs</p>
      </div>
    </>
  )
}

interface LoadingEarnedLeftLogProps {
  recipeStatus: string
}

export const LoadingEarnedLeftLog = ({ recipeStatus }: LoadingEarnedLeftLogProps) => {
  return (
    <>
      {recipeStatus === "active"
        ?
        <div className="logs-group-rows">
          <p>Loading current value</p>
          <div className="left-empty-position"></div>
          <div className="left-empty-position"></div>
        </div>
        :
        <div className="logs-group-rows">
          <p>Loading final value</p>
          <div className="left-empty-position"></div>
        </div>}

      <div className="logs-group-rows">
        <p>Loading earned</p>
        <div className="left-empty-position"></div>
      </div>
    </>
  )
}
