import * as metamask from './Metamask'
// import walletConnect from './WalletConnect'

export interface Provider {
  setup: (force?: boolean) => Promise<any>
}
export type ProviderLoader = () => Promise<Provider>

const providers: { [name: string]: ProviderLoader } = {
  metamask: async () => metamask,
  walletConnect: async () => await import(/* webpackChunkName: "walletconnect" */ './WalletConnect'),
}

export default providers
