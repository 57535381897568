export const FETCH_BALANCER_POOLS = (): string => {
    const query: string = `
    {
        poolGetPools (where: {chainIn: ARBITRUM}, orderBy: apr, orderDirection: desc) {
            id
            name
            address
            type
            allTokens {
                symbol
                address
                isMainToken
                isNested
                weight
            }
            dynamicData {
                apr {
                    apr {
                        __typename ... on GqlPoolAprTotal {
                          total
                        }
                    }
                    swapApr
                }
                totalLiquidity24hAgo
                volume24h
                swapFee
            }
        }   
    }`
    return query
}
