import React from 'react'
import TableEvents from 'src/routes/AprDetails/TableEvents'
import { useTableEvents } from 'src/hooks/useTableEvents'

import './WithdrawOutputTable.sass'
import { useRecipeDetails, useRecipeLogs } from 'src/api/recipes'
import { RecipeExecutionLog } from 'src/types'

interface WithdrawModalTableProps {
  id: number
}

const WithdrawOutputTable = ({ id }: WithdrawModalTableProps) => {
  const recipeDetails = useRecipeDetails(+id)
  const logs: RecipeExecutionLog[] = useRecipeLogs(+id)
  const { outputTableData } = useTableEvents(id, logs, recipeDetails)
  return !!outputTableData
    && <div className="withdraw-output-table">
      <TableEvents name="output" data={outputTableData} />
    </div>
}

export default WithdrawOutputTable
