import React, { useEffect, useState } from "react"
import { FormattedMessage } from "react-intl"
// import { useRecipeCRUD } from "src/api/recipes"
import { registerModal, useModal } from "@gluedigital/modal"
import { Link, useHistory } from 'react-router-dom'
import arrow from 'src/static/images/back-arrow.svg'
import { useRecipeLogsLive } from "src/context/SocketContext"
import { RecipeExecutionLog } from "src/types"
import "./RecipeNotWorkingModal.sass"

interface RecipeWorkingModalProps {
  recipeID: number
}

const RecipeNotWorkingModal = ({ recipeID }: RecipeWorkingModalProps) => {
  const [retryCount, setRetryCount] = useState<number>(0)
  const history = useHistory()
  const modal = useModal()
  // const recipeCRUD = useRecipeCRUD()
  const liveLogs: RecipeExecutionLog[] = useRecipeLogsLive(recipeID.toString())

  useEffect(() => {
    if (liveLogs.length > 0) {
      if (liveLogs[liveLogs?.length - 1].logtype === 'fail') {
        setRetryCount(liveLogs[liveLogs.length - 1].retrycount + 1)
      }
    }
  }, [liveLogs])

  const handleViewRecipe = () => {
    history.push(`/recipe/${recipeID}`); modal.hide()
  }

  // const handleRetry = async (e) => {
  //   e.stopPropagation()
  //   try {
  //     await recipeCRUD.execute(recipeID)
  //   } catch (err) {
  //     console.error(err)
  //   }
  // }

  return (
    <div className="recipe-not-working-modal modal-content">
      <div className="not-working-buttons">
        <h1><strong><FormattedMessage id="recipe-working.not-working" /></strong></h1>
        <h4>
          <span className="retry-count"><FormattedMessage id="recipe-item.retry" /> {retryCount} / 4</span>
        </h4>
        <button onClick={handleViewRecipe}>
          <FormattedMessage id="recipe-working.go-recipe" />
        </button>
        {/* <button id="retry-button" onClick={() => handleRetry}>
          <FormattedMessage id="recipe-working.retry-recipe" />
        </button> */}
        <Link onClick={() => modal.hide()} to="/dashboard/active">
          <img src={arrow} />
          <span><FormattedMessage id="recipe-working.dashboard" /></span>
        </Link>
      </div>
    </div>
  )
}

registerModal('recipe-not-working-modal', RecipeNotWorkingModal)
