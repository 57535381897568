import React from "react"
import { registerModal } from "@gluedigital/modal"

import "./YoutubeModal.sass"

interface YoutubeModalProps {
  id: string
}

const YoutubeModal = ({ id }: YoutubeModalProps) => {
  return (
    <div className="youtube-modal-content modal-content">
      <iframe
        width="100%"
        height="100%"
        src={`https://www.youtube.com/embed/${id}?controls=1`}
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen>
      </iframe>
    </div>
  )
}

registerModal('youtube-modal', YoutubeModal)
