import React, { useEffect, useRef } from "react"
import { FormattedMessage } from 'react-intl'

import './Browse.sass'

interface BrowseProps {
  searchTerm: string
  handleSearch: (e: React.ChangeEvent<HTMLInputElement>) => void
}

const Browse = ({ handleSearch, searchTerm }: BrowseProps) => {
  const inputRef = useRef<HTMLInputElement>(null)
  useEffect(() => {
    inputRef.current.focus()
  }, [searchTerm])

  return (
    <div className="browse-input-wrapper">
      <FormattedMessage id="placeholder.search">
        {(text: string) => (
          <input
            ref={inputRef}
            autoFocus
            type="search"
            onChange={handleSearch}
            placeholder={text}
          />
        )}
      </FormattedMessage>
      <span className="icon icon-search" />
    </div>
  )
}

export default Browse
