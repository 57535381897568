import React from "react"
import { FormattedMessage } from "react-intl"
import { CloseModal } from '@gluedigital/modal'
export interface HeaderFarmModalProps {
  farmMode: string
  chosenOption: any
  saveHandler: () => void
}
const HeaderFarmModal = ({ farmMode, chosenOption, saveHandler }: HeaderFarmModalProps) => {
  return (
    <header className="header-buttons">
      <CloseModal>
        <button className="modal-close-button">
          <span className="icon icon-close" />
          <span><FormattedMessage id="close" /></span>
        </button>
      </CloseModal>
      <h1><FormattedMessage id="farm-modal.title" />
      </h1>
      <CloseModal>
        <button
          disabled={!chosenOption || !farmMode}
          onClick={saveHandler}
          className="filled"
        >
          <FormattedMessage id="save" />
        </button>
      </CloseModal>
    </header>
  )
}

export default HeaderFarmModal
