import React, { useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'

import './LoadingBar.sass'
import Progress from './Progress/Progress'

interface LoadingBarProps {
  completionPercentage?: number
}
const MIN_ID = 0
const MAX_ID = 2
const LoadingBar = (props: LoadingBarProps) => {
  const { completionPercentage } = props
  const [titleID, setTitleID] = useState(0)

  useEffect(() => {
    const titleInterval = setInterval(() => {
      setTitleID((oldID) => {
        if (oldID === MAX_ID) {
          return MIN_ID
        } else {
          return oldID + 1
        }
      })
    }, 6000)
    return () => {
      clearInterval(titleInterval)
    }
  }, [])

  return <div className="loadingBar-wrapper">
    <span className="loadingBar-subtitle">
      <FormattedMessage id="recipe-summary.loading.subtitle" />
    </span>
    <div className="loadingBar-container">
      <Progress complete={completionPercentage >= 100} />
    </div>
    <h1 className="loadingBar-title" key={titleID}>
      <FormattedMessage id={`recipe-summary.loading.title-${titleID}`} />
    </h1>
  </div>
}

export default LoadingBar
