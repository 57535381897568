
import { ENV_ENVIROMENT, localArbitrumDev, mainnetArbitrumDev, mainnetArbitrumDevLocal, mainnetArbitrumPro, mainnetFantomDev, mainnetFantomDevLocal, mainnetFantomPro } from "src/constants"
import { TokensList } from "src/hooks/useOrderTokensInModals"

export interface NetworkParamsData {
  chainId: string
  chainName: string
  nativeCurrency: any
  rpcUrls: any
  blockExplorerUrls: any
  tokens: TokensList[]
  api?: string
  yearnTokens?: any
  contracts?: any
}

export enum Networks {
  fantom = '0xfa',
  arbitrum = '0xa4b1',
  hardhat = '0x539'
}

export enum Environments {
  localDev = "LOCAL_DEV",
  fantomLocalDev = "MAINNET_FANTOM_DEV_LOCAL",
  arbitrumLocalDev = "MAINNET_ARBITRUM_DEV_LOCAL",
  mainnetDev = "MAINNET_DEV",
  mainnetPro = "MAINNET_PRO"
}

const getAPIbyNetwork = (networkId: string): string => {
  // if (!networkId) return mainnetFantomDevLocal.api
  let baseUrl: string
  if (ENV_ENVIROMENT === Environments.fantomLocalDev) {
    baseUrl = mainnetFantomDevLocal.api
  } else if (ENV_ENVIROMENT === Environments.arbitrumLocalDev) {
    baseUrl = mainnetArbitrumDevLocal.api
  } else if (ENV_ENVIROMENT === Environments.localDev) { // Use for FORKED NETWORK
    baseUrl = localArbitrumDev.api
  } else if (networkId === Networks.fantom) {
    baseUrl = ENV_ENVIROMENT === Environments.mainnetDev ? mainnetFantomDev.api : mainnetFantomPro.api
  } else /* if (networkId === Networks.arbitrum) */ {
    baseUrl = ENV_ENVIROMENT === Environments.mainnetDev ? mainnetArbitrumDev.api : mainnetArbitrumPro.api
  }
  return baseUrl
}

export const getItemByNetwork = (networkId: string, itemFantom: any, itemArbitrum: any, itemLocal: any): any => {
  if (networkId === Networks.fantom) return itemFantom
  else if (networkId === Networks.arbitrum) return itemArbitrum
  else if (networkId === Networks.hardhat) return itemLocal
}

export const isValidNetwork = (networkId: any): boolean => Object.values(Networks).includes(networkId)

export default getAPIbyNetwork
