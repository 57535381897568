import React, { Suspense } from "react"
import { registerModal, CloseModal } from "@gluedigital/modal"
import { ModalErrorBoundary } from "src/components/ErrorBoundary/ErrorBoundary"
import { FormattedMessage } from "react-intl"
// import { NestedStrategiesModalData } from "src/routes/RecipeDiagram/helpers/types"
import { NodeExecutionResult, RecipeDetails, RecipeExecutionLog } from "src/types"
import { YearnSummaryVaultsData } from "src/api/yearnVaults/yearnTypes"
import { LoadingNestedStrategiesModalLog } from "./LoadingNestedStrategiesModalLog"
import NodeStateAndDate from "../NodeStateAndDate/NodeStateAndDate"
import { getEventsForNode } from "src/api/yearnVaults/yearnHelpers"
import { generateShowingTime } from "src/components/Diagram/nodes/nodesLogsHelper"
import { YearnNestedModalLog } from "./Yearn/YearnNestedModalLog"
import { ReaperSummaryVaultsData } from "src/api/reaperFinance/reaperTypes"
import { findNestedProvider } from "src/components/Log/NestedExtraDataLog/nestedLogHelpers"
import { nodesLogsNumber } from "src/constants"

import './NestedStrategiesModalLog.sass'
import ReaperNestedModalLog from "./Reaper/ReaperNestedModalLog"

export interface NestedStrategiesModalLogProps {
  previousData: {
    data: any
    id: string
    recipeLogs: RecipeExecutionLog[]
    recipeDetails: RecipeDetails
  }
}

const NestedStrategiesModalLog = ({ previousData }: NestedStrategiesModalLogProps) => {
  const oldVaultData: ReaperSummaryVaultsData | YearnSummaryVaultsData = findNestedProvider(previousData?.data)
  const recipeLogs = previousData?.recipeLogs

  let showingDate: string
  let endingLog: RecipeExecutionLog
  let executionSteps: number
  let isExecutionStarted: boolean = false

  if (recipeLogs !== null) {
    const nodeEvents: NodeExecutionResult[] = getEventsForNode(recipeLogs, previousData.id)
    if (nodeEvents) {
      executionSteps = nodeEvents.length
      isExecutionStarted = true
    }

    if (nodeEvents) {
      if (executionSteps === nodesLogsNumber.FINISHED_NODE ||
        executionSteps === nodesLogsNumber.ABORTED_NODE ||
        executionSteps === nodesLogsNumber.REAPER_FINISHED_NODE ||
        executionSteps === nodesLogsNumber.REAPER_ABORTED_NODE) {
        recipeLogs.forEach((log) => {
          if (log.events.length === nodesLogsNumber.FINISHED_NODE || log.events.length === nodesLogsNumber.ABORTED_NODE) {
            const findEvent: NodeExecutionResult = log.events.find((ev) => ev.nodeID === previousData.id && ev.functionName === "withdrawFromNestedStrategy")
            if (findEvent) endingLog = log
          }
        })
      }
      showingDate = generateShowingTime(endingLog)
    }
  }

  return (
    <ModalErrorBoundary>
      <div className="nested-strategies-modal modal-content" >
        <div className="modal-wrapper">
          <header className="header-buttons">
            <CloseModal>
              <button>
                <span className="icon icon-close" />
                <span><FormattedMessage id="close" /></span>
              </button>
            </CloseModal>
            <h1><FormattedMessage id="nested-strategies-modal.title" /></h1>
            {<NodeStateAndDate isNodeExecuted={isExecutionStarted} showingDate={showingDate} recipeDetails={previousData.recipeDetails} numberOfLogs={executionSteps} />}
          </header>
          <div className="nested-strategies-modal-wrapper">
            {oldVaultData.provider === "Yearn" &&
              <YearnNestedModalLog
                oldVaultData={oldVaultData as YearnSummaryVaultsData}
                previousData={previousData}
              />}

            {oldVaultData.provider === "Reaper" &&
              <ReaperNestedModalLog
                oldVaultData={oldVaultData as ReaperSummaryVaultsData}
                previousData={previousData}
              />}
            <footer className="div-provider">
              <span className="span-title-provider"><FormattedMessage id="farm-modal-log.provider" /> </span>
              <span className="span-provider">{oldVaultData?.provider.toUpperCase()}</span>
            </footer>
          </div>
        </div>
      </div>

    </ModalErrorBoundary>
  )
}

const NestedStrategiesModalLogWrapper = (props: NestedStrategiesModalLogProps) => {
  const suspenseModal = <Suspense fallback={<LoadingNestedStrategiesModalLog {...props} />}>
    <NestedStrategiesModalLog {...props} />
  </Suspense>
  return suspenseModal
}

registerModal('nested-strategies-modal-log', NestedStrategiesModalLogWrapper)
