import React from 'react'

import './Vapy.sass'

const PnLMobile = ({ roi }: { roi: number}) => {
  return (
    <section className="recipe-card-vapy">
      <h2>PnL</h2>
      <div className={`item item-apr ${roi > 0 ? 'success' : 'faliure'}`}>
        {roi}%
      </div>
    </section>
  )
}

export default PnLMobile
