import React from "react";
import { FormattedMessage } from "react-intl";
import { TokenPrice, usePricesCombo } from "src/api/comboTrigger/comboApi";
import { ComboTriggerModalData, ConditionProvider, TokenCondition } from "src/routes/RecipeDiagram/helpers/types";
import { useTriggerTokenInput } from "../hooks/useTriggerTokenInput";

interface TriggerTokenInputProps {
  triggerData: ComboTriggerModalData
  setTriggerData: (data: ComboTriggerModalData) => void
  avoidLiquidation: boolean
}

const TriggerTokenInput = (props: TriggerTokenInputProps) => {
  const { triggerData, setTriggerData, avoidLiquidation } = props
  const condition = triggerData ? triggerData.condition as TokenCondition : {}
  const conditionProvider = ConditionProvider.chainlink
  const coinPrices: TokenPrice[] = usePricesCombo()

  const {
    getValue,
    inputCondition,
    inputConditionValue,
    currentPrice
  } = useTriggerTokenInput(triggerData)

  const handleInput = () => {
    const value = getValue()
    setTriggerData({ condition: { ...condition, value }, conditionProvider, avoidLiquidation })
  }
  const handleConditionType = type => {
    setTriggerData({ condition: { ...condition, type }, conditionProvider, avoidLiquidation })
  }
  const handleCoinToCompare = coinToCompare => {
    setTriggerData({ condition: { ...condition, coinToCompare }, conditionProvider, avoidLiquidation })
  }

  return (
    <>
      <div className="combo-trigger-grid">
        <section>
          <h2><FormattedMessage id="deposit-lp-modal.provider" /></h2>
          <div className="chainlink-provider">
            <FormattedMessage id="chainlink" />
          </div>
        </section>
        <section>
          <h2><FormattedMessage id="combo-trigger-modal.says" /></h2>
          <select value={condition?.coinToCompare} onChange={(e) => handleCoinToCompare(e.target.value)}>
            <FormattedMessage id="select.currency">
              {text => <option value="" hidden>{text}</option>}
            </FormattedMessage>
            {coinPrices.map((tokenPrice) => (
              <option key={tokenPrice.token} value={tokenPrice.token}>{`${tokenPrice.token} $${tokenPrice.price}`}</option>
            ))}
          </select>
        </section>
      </div>
      <section>
        <h2><FormattedMessage id="combo-trigger-modal.is" /></h2>
        <select
          value={condition?.type}
          onChange={(e) => {
            const type: any = e.target.value
            handleConditionType(type)
          }}
        >
          <FormattedMessage id="select.currency">
            {text => <option value="" hidden>{text}</option>}
          </FormattedMessage>
          <FormattedMessage id="lower">
            {(text: string) => <option value="lower">{text}</option>}
          </FormattedMessage>
          <FormattedMessage id="higher">
            {(text: string) => <option value="higher">{text}</option>}
          </FormattedMessage>
        </select>
        <div className="input-value">
          <FormattedMessage id="placeholder.amount">
            {() => (
              <input
                ref={inputCondition}
                value={inputConditionValue}
                onChange={() => {
                  handleInput()
                }}
                placeholder="Value..."
                min={0}
              />
            )}
          </FormattedMessage>
          <span>USD</span>
        </div>
        <span>
          < span className="span-current">
            <FormattedMessage id="combo-trigger-modal.current-price" />
          </span>
          <span className="span-coin-price">{' '}{condition?.coinToCompare} {currentPrice}$</span>
        </span>
      </section>
    </>
  )
}
export default TriggerTokenInput
