import { useRef } from "react"
import { EdgeElement, NodeElement, NodeType } from "./types"

const colorsArray = ['#FFB827', '#F26859', '#6F8EFF', '#AE51FF', '#FF57EE', '#32FFB5', '#434BFF', '#2DB8FF']

export const getRandomColor = (excludeColor?: string) => {
  const ran = Math.floor(Math.random() * 7)
  const randomColor = colorsArray[ran]
  return randomColor === excludeColor ? colorsArray[(ran + 1) % colorsArray.length] : randomColor
}

export const propagateColor = (sourceNode: NodeElement, nodes: NodeElement[], edges: EdgeElement[], colorsMap: Map<string, string[]>): EdgeElement[] => {
  let updatedEdges = [...edges]
  if (sourceNode.type === 'splitNode') {
    const firstBranchEdge = edges.find((edge) => edge.source === sourceNode.id && edge.sourceHandle === 'split-output-1')
    if (firstBranchEdge) {
      const firstBranchColor = colorsMap.get(sourceNode.id)?.[0] || ''
      const updatedFirstEdge = { ...firstBranchEdge, style: { ...firstBranchEdge.style, stroke: firstBranchColor } }
      updatedEdges[updatedEdges.indexOf(firstBranchEdge)] = updatedFirstEdge
      if (firstBranchEdge.targetHandle !== 'split-input') {
        const nextNode = nodes.find((node) => node.id === firstBranchEdge.target)
        colorsMap.set(nextNode.id, [firstBranchColor])
        updatedEdges = propagateColor(nextNode, nodes, updatedEdges, colorsMap)
      }
    }

    const secondBranchEdge = edges.find((edge) => edge.source === sourceNode.id && edge.sourceHandle === 'split-output-2')
    if (secondBranchEdge) {
      const secondBranchColor = colorsMap.get(sourceNode.id)?.[1] || ''
      const updatedSecondEdge = { ...secondBranchEdge, style: { ...secondBranchEdge.style, stroke: secondBranchColor } }
      updatedEdges[updatedEdges.indexOf(secondBranchEdge)] = updatedSecondEdge
      if (secondBranchEdge.targetHandle !== 'split-input') {
        const nextNode = nodes.find((node) => node.id === secondBranchEdge.target)
        colorsMap.set(nextNode.id, [secondBranchColor])
        updatedEdges = propagateColor(nextNode, nodes, updatedEdges, colorsMap)
      }
    }
  } else {
    const nextEdge = edges.find((edge) => edge.source === sourceNode.id)

    if (nextEdge) {
      const edgeColor = colorsMap.get(sourceNode.id)?.[0] || ''
      const updatedNextEdge = { ...nextEdge, style: { stroke: edgeColor } }
      updatedEdges[updatedEdges.indexOf(nextEdge)] = updatedNextEdge
      if (nextEdge.targetHandle !== 'split-input') {
        const nextNode = nodes.find((node) => node.id === nextEdge.target)
        colorsMap.set(nextNode.id, [edgeColor])
        updatedEdges = propagateColor(nextNode, nodes, updatedEdges, colorsMap)
      }
    }
  }
  return updatedEdges
}

export const handleNewNodeColor = (colorsMap: Map<string, string[]>, nodeType: NodeType, nodeID: string) => {
  if (nodeType === "addFundsNode") {
    colorsMap.set(nodeID, [getRandomColor()])
  } else if (nodeType === "splitNode") {
    const firstBranchColor = getRandomColor()
    colorsMap.set(nodeID, [firstBranchColor, getRandomColor(firstBranchColor)])
  }
}

export const useColorsMap = () => {
  const colorsMap = useRef<Map<string, string[]>>(new Map())

  return { colorsMap: colorsMap.current }
}
