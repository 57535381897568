import React from 'react'
import { useSelector } from 'react-redux'
import { FormattedMessage } from 'react-intl'
import { useLastSyncGraphBlock } from 'src/hooks/useGraph'
import { usePairDayDataByBlockNumber } from 'src/hooks/usePairInfoFromGraph'
import "./SummaryList.sass"

export interface SpookyRecipeSummaryLogProps {
  summaryInfo: any
  short?: boolean
}
const SpookyRecipeSummaryLog = ({ summaryInfo, short = false }: SpookyRecipeSummaryLogProps) => {
  const networkId: string = useSelector((s: any) => (s.network.network))

  const graphLastBlock: number = useLastSyncGraphBlock(networkId)
  const blockToUse: number = graphLastBlock
  const myFarmAddress: string = summaryInfo.data?.condition?.farmID
  const pairInfo: any = usePairDayDataByBlockNumber(myFarmAddress, blockToUse)
  const myFarm = pairInfo.data.pairDayDatas[0]

  const condition = summaryInfo?.data?.condition

  return (
    <span className="span-info">
      <FormattedMessage id="apr-in-farm" /> <span className="coin">{`${myFarm.token0.symbol} - ${myFarm.token1.symbol}  `}</span>
      {short ? (condition.type === 'lower' ? '<' : '>') : <FormattedMessage id={`combo-trigger-modal.${condition.type}`} />} {' '}
      {!short && <FormattedMessage id="combo-trigger-modal.than" />} {condition.value}%
    </span>
  )
}

export default SpookyRecipeSummaryLog
