import React from 'react'
import { FormattedMessage } from 'react-intl'
import { useSelector } from 'react-redux'
import { BeetsPoolsInfo, ExtraDataBeetsPools } from 'src/api/beets/beetsTypes'
import { ModalErrorBoundary } from "src/components/ErrorBoundary/ErrorBoundary"
import { nodesLogsNumber } from 'src/constants'
import { useFetchSingleBeetsPool } from 'src/hooks/useBalancerPools'
import { DepositOnLPModalData } from 'src/routes/RecipeDiagram/helpers/types'
import { NodeExecutionResult } from 'src/types'
import web3 from 'src/utils/web3'
import { NoExecutedDepositModalLog } from '../NoExecutedDepositModalLog'

interface BeetsDepositModalLogProps {
  events: NodeExecutionResult[]
  data: DepositOnLPModalData
}
export const BeetsDepositModalLog = ({ events, data }: BeetsDepositModalLogProps) => {
  const networkId: string = useSelector((s: any) => (s.network.network))
  const { NO_EXECUTED_NODE, ACTIVE_NODE, FINISHED_NODE, ABORTED_NODE } = nodesLogsNumber
  let amountDeposited: string = '?'
  let lpUSDPriceAtDeposit: number = 0
  let executionSteps: number

  if (events !== undefined && events !== null && events.length > 0) {
    executionSteps = events.length
    amountDeposited = events[1].output.amount
    amountDeposited = web3.utils.fromWei(amountDeposited, 'ether')
    amountDeposited = Number(amountDeposited).toFixed(15)
    const extraData: ExtraDataBeetsPools = events[1].extraData
    lpUSDPriceAtDeposit = Number(extraData.totalLiquidity) / Number(extraData.totalShares)
  } else { executionSteps = 0 }

  const currentBeetsPool: BeetsPoolsInfo = useFetchSingleBeetsPool(data.pair.poolId, networkId)

  const apr: string = executionSteps !== FINISHED_NODE
    ? (Number(currentBeetsPool.dynamicData?.apr?.apr?.total) * 100).toFixed(2)
    : (Number(events[2].extraData.apr) * 100).toFixed(2)
  const liquidity: string = executionSteps !== FINISHED_NODE
    ? (Number(currentBeetsPool.dynamicData?.totalLiquidity)).toFixed(2)
    : (Number(events[2].extraData.totalLiquidity)).toFixed(2)

  let lpUSDPriceAtOtherMoment: number = 0
  if (executionSteps === ACTIVE_NODE) {
    lpUSDPriceAtOtherMoment = Number(currentBeetsPool.dynamicData.totalLiquidity) / Number(currentBeetsPool.dynamicData.totalShares)
  } else if (executionSteps === FINISHED_NODE || executionSteps === ABORTED_NODE) {
    const extraData: ExtraDataBeetsPools = events[2].extraData
    lpUSDPriceAtOtherMoment = Number(extraData.totalLiquidity) / Number(extraData.totalShares)
  }
  let earnedUSDValue: string = (Number(amountDeposited) * lpUSDPriceAtOtherMoment - Number(amountDeposited) * lpUSDPriceAtDeposit).toFixed(3)
  if (earnedUSDValue === "-0.000") earnedUSDValue = "0.000"
  return (
    <ModalErrorBoundary>
      <section className="deposit-modal-currency">
        {executionSteps === NO_EXECUTED_NODE ? <NoExecutedDepositModalLog text="NoExecuted" />
          :
          <>
            <div id="first-slot">
              <h3><FormattedMessage id="farm-modal-log.deposited" /></h3>
              <strong className="span-coin"> $ {(Number(amountDeposited) * lpUSDPriceAtDeposit).toFixed(3)}</strong>
              <span className="span-amount-lp">{amountDeposited.toString()} <strong>LPs</strong></span>
            </div>

            <div id="second-slot">
              {executionSteps === ACTIVE_NODE &&
                <>
                  <h3><FormattedMessage id="farm-modal-log.current" /></h3>
                  <strong className="span-coin">$ {(Number(amountDeposited) * lpUSDPriceAtOtherMoment).toFixed(3)}</strong>
                </>}
              {(executionSteps === FINISHED_NODE || executionSteps === ABORTED_NODE) &&
                <>
                  <h3><FormattedMessage id="farm-modal-log.earned" /></h3>
                  <strong className="span-coin">$ {earnedUSDValue}</strong>
                </>
              }
              <span className="span-amount-lp">{amountDeposited.toString()} <strong>LPs</strong></span>
            </div>

            <div id="third-slot">
              <h3><FormattedMessage id="farm-modal-log.apr" /></h3>
              <strong className="span-coin">{apr} %</strong>
            </div>

            <div id="fourth-slot">
              <h3><FormattedMessage id="farm-modal-log.liquidity" /></h3>
              <strong className="span-coin">$ {Number(liquidity).toLocaleString()}</strong>
            </div>
          </>}
      </section>
    </ModalErrorBoundary>
  )
}
