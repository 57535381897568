import React, { Suspense, useCallback, useEffect, useState } from "react"
import { useHistory, useParams, Redirect } from "react-router-dom"
import { FormattedMessage, FormattedNumber } from "react-intl"
import { useDispatch, useSelector } from "react-redux"
import { useModal } from "@gluedigital/modal"
import ReactFlow, { Controls, ReactFlowProvider } from "react-flow-renderer"
import { useRecipeCRUD, useRecipeLogs } from "src/api/recipes"
import { useDiagram } from "../helpers/useDiagram"
import { getElementsForAPIFromInstance } from "../helpers/instanceHelper"
import Sidebar from "../Sidebar/Sidebar"
import "src/components/modals/nodeModals/AddFundsModal"
import "src/components/modals/nodeModals/LiquidateModal"
import "src/components/modals/nodeModals/ComboTriggerModal"
import "src/components/modals/nodeModals/DepositOnLPModal"
import "src/components/modals/nodeModals/SwapModal"
import "src/components/modals/nodeModals/SplitModal"
import "src/components/modals/nodeModals/FarmModal"
import "src/components/modals/nodeModals/NestedStrategiesModal"
import "src/components/modals/nodeModals/ShortLongModal"
import "src/components/modals/nodeModals/SendToWalletModal"
import "src/components/modals/ItWorksModal"
import "src/components/modals/RecipeNameModal"
import "src/components/modals/ViewDescriptionModal"
import "src/components/modals/ValidationModal"
import "src/components/modals/AlertModal"
import "src/components/modals/BlockerModal/BlockerModal"
import "src/components/modals/modalsViewLogs/AddFundsModalLogs/AddFundsModalLog"
import "src/components/modals/modalsViewLogs/SwapModalLogs/SwapModalLog"
import "src/components/modals/modalsViewLogs/LiquidateModalLogs/LiquidateModalLog"
import "src/components/modals/modalsViewLogs/SplitModalLogs/SplitModalLog"
import "src/components/modals/modalsViewLogs/ComboModalLog/ComboTriggerModalLog"
import "src/components/modals/modalsViewLogs/FarmModalLog/FarmModalLog"
import "src/components/modals/modalsViewLogs/DepositModalLog/DepositModalLog"
import "src/components/modals/modalsViewLogs/ShortLongModalLog/ShortLongModalLog"
import "src/components/modals/modalsViewLogs/NestedStrategiesModalLog/NestedStrategiesModalLog"
import "./DnDFlow.sass"
import "../RecipePrivacyToggle/RecipePrivacyToggle.sass"
import SidebarViewLogs from "../SideBarViewLogs/SidebarViewLogs"
import SidebarViewDescription from "../SideBarViewDescription/SidebarViewDescription"
import editPencilImg from "src/static/images/lapiz.png"
import DiagramButtons from "./DiagramButtons"
import { Recipe, RecipeExecutionLog, RecipeStatus, TemporaryExecutionStatus } from "src/types"
import { useToast } from "src/hooks/useToast"
import { validateDiagram } from 'src/store/actions/validation'
import { useDiagramValidator } from "src/hooks/useValidator"
import useUnload from "src/hooks/useUnload"
import { useBlockerExecutionLive, useRecipeDetailsLive } from "src/context/SocketContext"
import LanguageSelector from 'src/components/Landing/LanguageSelector/LanguageSelector'
import { edgeTypes, nodeTypes } from "../helpers/types"
import { useNodesDispatch, useNodesRecipe, useNodesState } from "../helpers/nodesAndEdgesContext"
import { useAbort } from "src/hooks/useAbort"
import DetailsHeader from "src/components/DnDFlow/DetailsHeader"
import { formatRecipeTitle } from "src/routes/routesHelper"
import MetaRecipe from "src/components/Meta/MetaRecipe"
import NoLoginConnectWallet from "src/components/Diagram/NoLoginConnectWallet/NoLoginConnectWallet"
import { ShareRecipe } from "src/components/DnDFlow/ShareRecipe"
import { isNotWarning, isRedWarning } from "../helpers/validationWarningHelper"
import { getNetworkParams } from "src/components/modals/SelectWalletModal/helpers"

const DnDFlowContent = () => {
  const modal = useModal()
  const networkId: string = useSelector((s: any) => (s.network.network))
  const networkParams = getNetworkParams(networkId)
  const account = useSelector((s: any) => (s.user ? s.user.account : undefined))
  const userAddress: string = account ? account?.account.slice(2) : undefined
  const recipeCRUD = useRecipeCRUD()
  const recipeDetails = useNodesRecipe()
  const { nodes, edges, hasUnsavedChanges } = useNodesState()
  const nodesDispatch = useNodesDispatch()
  const {
    reactFlowInstance,
    reactFlowWrapper,
    onConnect,
    onInit,
    onDragOver,
    onDrop,
    zoom,
    position,
    onNodesChange,
    onEdgesChange,
  } = useDiagram(nodes, edges);
  const history = useHistory()
  const { recipeName } = useParams()
  const [enable, setEnable] = useState(recipeDetails ? recipeDetails?.public : false)
  const isEnabled = enable ? 'public' : 'private'

  const recipeDetailsLive = useRecipeDetailsLive(recipeDetails?.id?.toString() || '')
  const [title, setTitle] = useState<string>(recipeDetails ? recipeDetails?.title : undefined);
  const [shortDescription, setShortDescription] = useState<string>(recipeDetails ? recipeDetails?.short : '');
  const [extendedDescription, setExtendedDescription] = useState<string>(recipeDetails ? recipeDetails?.extended : '');
  const [enableSaveWithTitle, setEnableSaveWithTitle] = useState<boolean>(false)
  const sendToast = useToast()
  const dispatch = useDispatch()
  const [validationErrors, validationWarnings] = useDiagramValidator(nodes, edges, recipeDetails, recipeDetailsLive, networkId)
  const logs: RecipeExecutionLog[] = useRecipeLogs(recipeDetails?.id)
  const blockerCallback = () => { modal.show("blocker-modal", { recipeId: recipeDetails?.id, startingAction: "Aborting", nonDismissible: true }) }
  const liveBlocker = useBlockerExecutionLive(recipeDetails?.id?.toString() || '', blockerCallback)

  const temporaryStatusFromMap: TemporaryExecutionStatus = useSelector((s: any) => s.blockers.recipesState.get(recipeDetails?.id))
  useEffect(() => {
    if (temporaryStatusFromMap && !liveBlocker && recipeDetails?.owner === userAddress) modal.show("blocker-modal", { recipeId: recipeDetails?.id, startingAction: temporaryStatusFromMap, staticLogs: logs, nonDismissible: true })
  }, [liveBlocker, temporaryStatusFromMap, modal, recipeDetails, userAddress, logs])

  const saveRecipe = useCallback(
    async () => {
      if (recipeDetails) {
        await recipeCRUD.update(recipeDetails.id, {
          title: title ?? recipeDetails.title,
          short: shortDescription,
          extended: extendedDescription,
          code: await getElementsForAPIFromInstance(reactFlowInstance.toObject(), networkParams),
          public: enable || false,
          status: validationErrors.length <= 0 ? 'ready' : 'draft',
        })
      } else {
        const res = await recipeCRUD.create({
          title: title ?? "Unnamed Recipe",
          short: shortDescription,
          extended: extendedDescription,
          code: await getElementsForAPIFromInstance(reactFlowInstance.toObject(), networkParams),
          public: enable || false,
          status: validationErrors.length <= 0 ? 'ready' : 'draft'
        })
        const adaptTitle: string = formatRecipeTitle(res.title)
        sendToast(res.title, 'create')
        if (adaptTitle === '') {
          history.push('/recipe/' + res.id)
        } else {
          history.push('/recipe/' + res.id + '/' + adaptTitle)
        }
      }
      nodesDispatch({ type: 'saveChanges' })
    },
    [title, validationErrors, shortDescription, enable, extendedDescription, history, reactFlowInstance, nodesDispatch, recipeCRUD, recipeDetails, sendToast, networkParams],
  )

  useEffect(() => {
    if (enableSaveWithTitle) {
      saveRecipe().catch(err => console.error(err.message))
      setEnableSaveWithTitle(false)
    }
  }, [enableSaveWithTitle, saveRecipe])

  const [isValid, setValid] = useState(false)
  const [hasWarnings, setHasWarnings] = useState(false)
  const [hasBeenValidated, setHasBeenValidated] = useState(true)

  useUnload(hasUnsavedChanges)

  useEffect(() => {
    if (!recipeDetailsLive) {
      return
    }

    if (recipeDetailsLive?.status === 'finished') {
      sendToast(recipeDetailsLive.title, 'execute-success')
    }
    if (recipeDetailsLive?.status === 'failed') {
      sendToast(recipeDetailsLive.title, 'execute-fail')
    }
  }, [recipeDetailsLive, recipeDetails, sendToast, title])

  let comeBackRoute = 'your'
  if (recipeDetails?.owner !== userAddress) {
    comeBackRoute = 'browse'
  } else if (recipeDetails?.status === 'failed') {
    comeBackRoute = 'failed'
  } else if (recipeDetails?.status === 'finished') {
    comeBackRoute = 'liquidated'
  } else if (recipeDetails?.status === 'active') {
    comeBackRoute = 'active'
  }

  useEffect(() => {
    const diagramValid = validationErrors.length <= 0 && !isRedWarning(validationWarnings) && hasBeenValidated
    setValid(diagramValid) // this is false -> should be true
    if (recipeDetails?.status === 'finished') dispatch(validateDiagram(diagramValid))
  }, [validationWarnings, validationErrors, dispatch, recipeDetails?.status, hasBeenValidated])

  const handlePrivacy = () => { if ((recipeDetails === null || userAddress === recipeDetails?.owner) && title !== '') setEnable(!enable) }

  const goBack = () => {
    if (hasUnsavedChanges) {
      modal.show('alert-modal', {
        bodyTextID: 'dnd-flow.unsaved-changes',
        cancelHandler: () => {
          modal.hide()
          history.push("/dashboard/" + comeBackRoute)
        },
        confirmHandler: () => {
          saveRecipe()
            .then(() => modal.hide())
            .then(() => history.push("/dashboard/" + comeBackRoute))
            .catch((e) => {
              console.error('Error on save: ', e)
              modal.hide()
            })
        },
      })
    } else {
      history.push("/dashboard/" + comeBackRoute)
    }
  }

  const validateRecipe = () => {
    setHasBeenValidated(true)
    const updatedValidity: boolean = validationErrors.length === 0
    setValid(updatedValidity)
    if (!updatedValidity || !hasWarnings) {
      modal.show('validation-modal', { errors: validationErrors, warnings: validationWarnings })
    } else {
      dispatch(validateDiagram(true))
      modal.show("it-works-modal", { type: "success" })
    }
  }

  useEffect(() => {
    const updatedWarnings: boolean = isNotWarning(validationWarnings)
    setHasWarnings(updatedWarnings)
    if (hasUnsavedChanges) {
      const updatedValidity: boolean = validationErrors.length <= 0 && !isRedWarning(validationWarnings) && hasBeenValidated
      setValid(updatedValidity)
    }
  }, [hasUnsavedChanges, validationErrors, validationWarnings, hasBeenValidated])

  useEffect(() => {
    setHasBeenValidated(false)
  }, [nodes])

  const onSave = () => {
    setValid(validationErrors.length <= 0)
    setHasWarnings(isNotWarning(validationWarnings))

    modal.show('alert-modal', {
      bodyTextID: 'dnd-flow.update-confirmation',
      cancelHandler: () => modal.hide(),
      confirmHandler: async () => await saveRecipe().then(() => modal.hide()),
    })
  }

  const onRun = () => {
    const adaptTitle: string = formatRecipeTitle(recipeDetails?.title)
    if (hasUnsavedChanges) {
      modal.show('alert-modal', {
        bodyTextID: 'dnd-flow.unsaved-changes',
        cancelHandler: () => modal.hide(),
        confirmHandler: async () => await saveRecipe().then(() => {
          modal.hide()
          history.push({ pathname: "/recipe-summary/" + recipeDetails?.id + '/' + adaptTitle })
        })
      })
    } else {
      history.push({ pathname: "/recipe-summary/" + recipeDetails.id + '/' + adaptTitle })
    }
  }

  const abort = useAbort(recipeDetails as Recipe)
  const abortHandler = async () => {
    await abort()
  }

  const editTitleHandler = (setTitle, setShortDescription, setExtendedDescription, setEnableSaveWithTitle, title, short, extended) => {
    modal.show("recipe-name-modal", { setters: { setTitle, setShortDescription, setExtendedDescription, setEnableSaveWithTitle }, currentValues: { title, short, extended }, })
  }

  if (recipeDetails) {
    const adaptTitle: string = formatRecipeTitle(recipeDetails?.title)
    const canonical: string = `/recipe/${recipeDetails?.id}/${adaptTitle}`
    if (recipeName && recipeName !== adaptTitle) return <Redirect to={canonical} />
  }
  return (
    <div className="dnd-layout">
      {!!recipeDetails && <MetaRecipe />}
      <header className="dnd-header">
        <section>
          <button className="button-back" onClick={goBack}>
            <span className="icon icon-angle_left" />
            <span><FormattedMessage id="back" /></span>
          </button>
          {recipeDetails?.public && <ShareRecipe recipeDetails={recipeDetails} />}
          {userAddress && <h2 className="account-number">{account.account.substr(0, 6) + "..." + account.account.slice(-4)}</h2>}
        </section>
        <section>
          {(recipeDetails?.status === "draft" || recipeDetails?.status === "ready" || recipeDetails === null)
            ?
            <div onClick={handlePrivacy} className={`recipe-privacy-toggle ${isEnabled}`}>
              <div className="text pr">
                <FormattedMessage id={`recipe-privacy-toggle.${isEnabled}`} />
              </div>
              <div className="switcher">
                <div className="switch" />
              </div>
            </div>
            :
            <div>
              {userAddress ?
                <span className={`privacy ${recipeDetails?.public ? 'public' : 'private'}-span`}>
                  {recipeDetails?.public ? 'Public' : 'Private'}
                </span>
                : <></>}
            </div>
          }
          <label>
            {!title
              ? <FormattedMessage id="dnd-flow.name-placeholder" />
              : title?.length > 53 ? `${title.slice(0, 50)}...` : title
            }
          </label>
          {(recipeDetails?.status !== "finished" && recipeDetails?.status !== "active" && recipeDetails?.status !== "failed") ?
            <button className="edit-button" onClick={() => editTitleHandler(setTitle, setShortDescription, setExtendedDescription, setEnableSaveWithTitle, title, shortDescription, extendedDescription)}>
              <img className="pencil-img" src={editPencilImg} alt="Edit name" /></button>
            :
            <DetailsHeader recipeDetails={recipeDetails} />
          }
        </section>
        <section className="balance">
          {userAddress
            ? <h1><FormattedNumber value={account.balance} style="decimal" /> <sup>{networkId === '0xfa' ? 'FTM' : 'ETH'}</sup> </h1>
            : <></>}
          <LanguageSelector contracted />
        </section>
      </header>
      {/* } <div className={`dnd-flow ${recipeDetails?.public && recipeDetails?.owner !== userAddress ? 'no-sidebar' : ''}`}> */}
      <div className="dnd-flow">
        <ReactFlowProvider>
          <Suspense fallback={<div />}>
            <div className="reactflow-wrapper" ref={reactFlowWrapper}>
              <ReactFlow
                nodes={nodes}
                edges={edges}
                nodeTypes={nodeTypes}
                edgeTypes={edgeTypes}
                onConnect={onConnect}
                onInit={onInit}
                defaultZoom={zoom}
                defaultPosition={position}
                onDrop={onDrop}
                onDragOver={onDragOver}
                onNodesChange={onNodesChange}
                onEdgesChange={onEdgesChange}
              >
                <Controls className={"controls-custom"} />
              </ReactFlow>
            </div>
          </Suspense>
          <Suspense fallback={<div />}>
            {userAddress === undefined && <>
              <SidebarViewDescription recipeDetails={recipeDetails} userAddress={userAddress} />
              <NoLoginConnectWallet />
            </>
            }
            {
              recipeDetails && userAddress && (recipeDetails?.owner === userAddress) ?
                <>
                  {(recipeDetails && (recipeDetails.status === 'active' || recipeDetails.status === 'finished' || recipeDetails.status === 'failed' as RecipeStatus)) ?
                    <SidebarViewLogs nodes={nodes} recipeDetails={recipeDetails} />
                    :
                    <Sidebar />
                  }
                </>
                :
                <>
                  {
                    recipeDetails === null ?
                      <Sidebar />
                      :
                      <Suspense fallback={<></>}>
                        <SidebarViewDescription recipeDetails={recipeDetails} userAddress={userAddress} />
                      </Suspense>
                  }
                </>
            }
          </Suspense>
        </ReactFlowProvider>
      </div>
      <DiagramButtons
        recipeDetailsStep={recipeDetails?.step}
        recipeId={recipeDetails?.id}
        staticStatus={recipeDetails?.status}
        isRecipeOwner={userAddress === recipeDetails?.owner}
        recipeExists={!!recipeDetails}
        isEmpty={nodes.length <= 0}
        isValid={isValid}
        validateRecipe={validateRecipe}
        onSave={onSave}
        onRun={onRun}
        onAbort={abortHandler}
        validationWarnings={validationWarnings}
      />
    </div>
  )
}

export const DnDFlow = () => {
  return <DnDFlowContent />
}
