import tokensMainnet from 'src/data/tokensMainnet.json'
import tokensArbitrumMainnet from 'src/data/tokensArbitrumMainnet.json'
import yearnTokens from 'src/data/yearnTokens.json'
import vaultsMainNetDev from 'src/data/vaultsMainNetDev.json'
import vaultsMainNetPro from 'src/data/vaultsMainNetPro.json'
import vaultsV3MainetDev from 'src/data/vaultsV3MainNetDev.json'
import vaultsV3MainNetPro from 'src/data/vaultsV3MainNetPro.json'
import vaultsArbitrumMainnetDev from 'src/data/vaultsArbitrumMainnetDev.json'
import vaultsArbitrumMainnerPRO from 'src/data/vaultsArbitrumMainnetPro.json'
import batchAbiDev from 'src/contracts/abis/batch.json'
import batchAbiPro from 'src/contracts/abis/batchPRO.json'
import nodesAbiPro from 'src/contracts/abis/nodesPRO.json'
import nodesAbiMainnetDev from 'src/contracts/abis/nodesMainnetDev.json'
import blackListedArbitrumTokens from './data/blackListTokenArbitrum.json'
import { Environments } from './utils/networkHelper'

export const METAMASK_ERROR_CODES = {
  USER_DENIED: 4001,
  TRANSACTION_UNDERPRICED: -32603
}

export const DOCS_TROUBLESHOOTING: string = "https://docs.tortle.ninja/build/troubleshooting"

export const REWARD_ADDRESS = {
  '0xfa': '0x841fad6eae12c286d1fd18d1d525dffa75c7effe',
  '0xa4b1': '0xd4d42F0b6DEF4CE0383636770eF773390d85c61A',
  '0x7A69': '0x841fad6eae12c286d1fd18d1d525dffa75c7effe',
  '0xfa2': '0x1CA68a01E2A8ff61b3FD2F0f6f00178699da26E7',
}

export const masterchefV2Address = {
  '0xfa': '0x18b4f774fdC7BF685daeeF66c2990b1dDd9ea6aD',
  '0xa4b1': '0xF4d73326C13a4Fc5FD7A064217e12780e9Bd62c3',
  '0xfa2': '0xfc750D03D27766a0592378D49A37eBA8D44BA306'
}

export const complexRewarderV2Address = {
  '0xfa': '0xDdB816c59200aF55b1Ca20735Ef086626a2C6a8D',
  '0xfa2': ''
}

export const masterchefV3Address = {
  '0xfa': '0x9C9C920E51778c4ABF727b8Bb223e78132F00aA4',
  '0xfa2': ''
}

export const multicallAddress = {
  '0xfa': '0xeaa5bc63466ce2359c2a13dce952bd0e88a5bef7',
  '0xa4b1': '0x842eC2c7D803033Edf55E478F461FC547Bc54EB2',
  '0xfa2': '0x84fa9C99F9f561dF37145b51cA97a84385917035'
}

export const routerV2Address = {
  '0xfa': '0xF491e7B69E4244ad4002BC14e878a34207E38c29',
  '0xfa2': '0x40Be9a4E29A16D089C487ec74D3644F4fCAc3dc0'
}

export const factoryV2Address = {
  '0xfa': '0x152eE697f2E276fA89E96742e9bB9aB1F2E61bE3',
  '0xfa2': '0xEE4bC42157cf65291Ba2FE839AE127e3Cc76f741'
}

export const mummyVaultAddress = {
  '0xfa': '0xA6D7D0e650aa40FFa42d845A354c12c2bc0aB15f',
  '0xa4b1': '0x489ee077994B6658eAfA855C308275EAd8097C4A'
}

export const mummyVaultUtilsAddress = {
  '0xfa': '0x08Dfcfb0bD6e7eAF828f60021C77d273DE56b1C5',
  '0xa4b1': '0xC504Cb49bcADfC8fD56F1de5674ba216aeaFeF01'
}

export const SPOOKY_FEE = 0.17
export const SUSHI_FEE = 0.30

export const masterchefTheGraphUriFromUniswap = {
  '0xfa': 'https://api.thegraph.com/subgraphs/name/eerieeight/spookyswap',
  '0xa4b1': 'https://api.thegraph.com/subgraphs/name/sushiswap/arbitrum-exchange',
  '0x7A69': 'https://api.thegraph.com/subgraphs/name/eerieeight/spookyswap',
  '0xfa2': 'https://graph-beta.tortle.ninja/subgraphs/name/alexpinilla/spookyswapv2',
}

export const masterchefTheGraphUriV3FromUniswap = {
  '0xa4b1': 'https://api.thegraph.com/subgraphs/name/sushi-v3/v3-arbitrum'
}

export const yearnVaultsSubgraph = {
  '0xfa': 'https://api.thegraph.com/subgraphs/name/yearn/yearn-vaults-v2-fantom',
  '0xa4b1': 'https://thegraph.com/hosted-service/subgraph/yearn/yearn-vaults-v2-arbitrum',
  '0x7A69': 'https://api.thegraph.com/subgraphs/name/yearn/yearn-vaults-v2-fantom'
}

export const yearnVaultsYDaemon = {
  '0xfa': 'https://ydaemon.yearn.finance/250/vaults/all?strategiesDetails=noDetails',
  '0xa4b1': 'https://ydaemon.yearn.finance/42161/vaults/all?strategiesDetails=noDetails',
  '0x7A69': 'https://ydaemon.yearn.finance/250/vaults/all?strategiesDetails=noDetails'
}

export const balancerSubgraph = {
  '0xfa': 'https://backend-v3.beets-ftm-node.com/graphql',
  '0xa4b1': 'https://api-v3.balancer.fi/graphql',
  '0x7A69': 'https://backend-v3.beets-ftm-node.com/graphql'
}

export const reaperExternalApi = {
  '0xfa': 'https://yzo0r3ahok.execute-api.us-east-1.amazonaws.com/dev/api/crypts',
  '0xa4b1': 'https://2ch9hbg8hh.execute-api.us-east-1.amazonaws.com/dev/api/vaults/0xa4b1',
  '0x7A69': 'https://2ch9hbg8hh.execute-api.us-east-1.amazonaws.com/dev/api/vaults/0xa4b1'
}

export const perpetualsAPIPrices = {
  '0xfa': "https://api.mummy.finance/api/prices",
  '0xa4b1': "https://gmx-server-mainnet.uw.r.appspot.com/prices"
}
export const AXIOS_TIMEOUT = 8000
export const pairTokensPools = 'pairTokens'
export const pairTokensFarms = 'pairTokensFarms'

const nativeCurrencys = {
  fantom: {
    name: 'Fantom',
    symbol: 'FTM',
    decimals: 18
  },
  arbitrum: {
    name: 'Ether',
    symbol: 'ETH',
    decimals: 18
  }
}

export const nodesLogsNumber = {
  NO_EXECUTED_NODE: 0,
  LIQUIDATED_NODE: 1,
  WITHDRAW_AFTER_PERPETUAL_NODE: 1,
  ACTIVE_NODE: 2,
  REAPER_ACTIVE_NODE: 3,
  FINISHED_NODE: 4,
  ABORTED_NODE: 5,
  SHORT_LONG_FINISHED_NODE: 5,
  REAPER_FINISHED_NODE: 6,
  REAPER_ABORTED_NODE: 7
}

const mainnetFantomDevContracts = {
  batch: "0x63a542c98421C633f4B6A388387D5C4F6B041E2D",
  nodes: "0xdD7679268127BD3084166a3D48eA4F5AaBA12D4C",
  batchAbi: batchAbiDev,
  nodesAbi: nodesAbiMainnetDev,
  vaults: vaultsMainNetDev,
  vaultsV3: vaultsV3MainetDev
}

const mainnetFantomProContracts = {
  batch: "0xe2dBa85d709756514f91ad56127a0aec1d26b0ba",
  nodes: "0xE97FBF28059fF6A4697fd4156EA2c3Ea638E9CFb",
  batchAbi: batchAbiPro,
  nodesAbi: nodesAbiPro,
  vaults: vaultsMainNetPro,
  vaultsV3: vaultsV3MainNetPro
}

const mainnetFantom = {
  chainId: "0xfa",
  chainName: "Fantom Opera",
  nativeCurrency: nativeCurrencys.fantom,
  rpcUrls: ["https://rpcapi.fantom.network"],
  blockExplorerUrls: ["https://ftmscan.com/"],
  tokens: tokensMainnet,
  yearnTokens,
}

export const mainnetFantomPro = { ...mainnetFantom, contracts: mainnetFantomProContracts, api: "https://apiv2.tortle.ninja" }

export const mainnetFantomDev = { ...mainnetFantom, contracts: mainnetFantomDevContracts, api: "https://api-candidate.tortle.ninja" }

export const mainnetFantomDevLocal = { ...mainnetFantom, contracts: mainnetFantomDevContracts, api: "http://localhost:5000" }

const localnet = {
  chainId: "0xa4b1",
  chainName: "LocalNet",
  nativeCurrency: nativeCurrencys.arbitrum,
  rpcUrls: ["http://localhost:8545"],
  blockExplorerUrls: ["https://ftmscan.com/"],
  tokens: tokensArbitrumMainnet,
  yearnTokens
}

export const localFantomDev = { ...localnet, contracts: mainnetFantomDevContracts }

export const localFantomPro = { ...localnet, contracts: mainnetFantomProContracts }

// Arbitrum

const mainnetArbitrumDevContracts = {
  batch: "0x6843083A810e34e782C12DfcAcd757bC9ed2C934",
  nodes: "0xe95493DbEde8bEAb6688c1dc5A522f4baae746EA",
  batchAbi: batchAbiDev,
  nodesAbi: nodesAbiMainnetDev,
  vaults: vaultsArbitrumMainnetDev
}

const mainnetArbitrumProContracts = {
  batch: "0x1B17C7d524a3f50Fb0661Cf534dB4404f0dA377C",
  nodes: "0x14D33B74Ed2ef1bdf8AF49B6C0f221877ccafCE3",
  batchAbi: batchAbiDev,
  nodesAbi: nodesAbiMainnetDev,
  vaults: vaultsArbitrumMainnerPRO
}

const mainnetArbitrum = {
  chainId: "0xa4b1",
  chainName: "Arbitrum One",
  nativeCurrency: nativeCurrencys.arbitrum,
  rpcUrls: ["https://arb1.arbitrum.io/rpc"],
  blockExplorerUrls: ["https://arbiscan.io/"],
  tokens: tokensArbitrumMainnet.filter(t => !blackListedArbitrumTokens.includes(t.id)),
}

export const mainnetArbitrumDev = { ...mainnetArbitrum, contracts: mainnetArbitrumDevContracts, api: "https://api-candidate.tortle.ninja" }

export const mainnetArbitrumPro = { ...mainnetArbitrum, contracts: mainnetArbitrumProContracts, api: "https://apiv2.tortle.ninja" }

export const mainnetArbitrumDevLocal = { ...mainnetArbitrum, contracts: mainnetArbitrumDevContracts, api: "http://localhost:5000" }

export const localArbitrumDev = { ...localnet, contracts: mainnetArbitrumDevContracts, api: "http://localhost:5000" }

export const ENV_ENVIROMENT = process.env.ENVIRONMENT || Environments.mainnetDev

export const SECONDS_IN_A_DAY = 86400
export const SECONDS_IN_A_WEEK = 604800
export const SECONDS_IN_A_MONTH = 2628000
