import contracts from './reducers/contracts'
import user from './reducers/user'
import ready from './reducers/ready'
import toasts from './reducers/toasts'
import validation from './reducers/validation'
import diagram from './reducers/diagram'
import blockers from './reducers/blockers'
import showOnlyVerified from './reducers/showOnlyVerified'
import network from './reducers/network'
import offline from './reducers/offline'

export const reducerList = {
  // Add your reducers here
  user,
  blockers,
  contract: contracts,
  ready,
  showOnlyVerified,
  toasts,
  validation,
  diagram,
  network,
  offline
}

export default reducerList
