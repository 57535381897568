import React, { useState, useEffect, useRef } from 'react'
import { FormattedMessage } from 'react-intl'
import { formatRecipeTitle } from 'src/routes/routesHelper'
import copyLink from "src/static/images/copyLink.png"
import shareSymbol from "src/static/images/share.png"
import { RecipeDetails } from 'src/types'
import { ENV_ENVIROMENT } from 'src/constants'
import './ShareRecipe.sass'

interface ShareRecipeProps {
  recipeDetails: RecipeDetails
}
export const ShareRecipe = ({ recipeDetails }: ShareRecipeProps) => {
  const [showShareInfo, setShowShareInfo] = useState<boolean>(false)
  const [showCopied, setShowCopied] = useState<boolean>(null)
  const shareRef = useRef(null)
  const tortleUrl: string = ENV_ENVIROMENT === "MAINNET_PRO" ? "https://tortle.ninja/recipe/" : "https://candidate.tortle.ninja/recipe/"
  const adaptTitle: string = formatRecipeTitle(recipeDetails?.title)
  const shareParameters: string = `${recipeDetails?.id}/${adaptTitle}`
  const recipeLink: string = tortleUrl + shareParameters

  const urlTwitter: string = `http://twitter.com/share?text=Tortle Recipe: ${recipeDetails?.title}&url=${recipeLink}`
  const urlTelegram: string = `https://t.me/share/url?url=${recipeLink}&text=Tortle Recipe: ${recipeDetails?.title}`

  const clickShareHandler = () => { setShowShareInfo(true) }

  useEffect(() => {
    if (showShareInfo) {
      shareRef.current.focus()
    }
  }, [showShareInfo, shareRef])

  const blurShareHandler = (e: any) => {
    if (!e.currentTarget.contains(e.relatedTarget as Node)) {
      setShowShareInfo(false)
    }
  }

  const copyClipBoard = async () => {
    setShowCopied(true)
    await navigator.clipboard.writeText(recipeLink)
  }

  useEffect(() => {
    const timeId = setTimeout(() => {
      setShowCopied(false)
    }, 3300)

    return () => { clearTimeout(timeId) }
  }, [showCopied]);

  return (
    <div id="share-recipe">
      <button className="button-share" onClick={clickShareHandler}>
        <img src={shareSymbol} alt="share" />
        <span> <FormattedMessage id="share" /></span>
      </button>
      {showShareInfo && <div className="share-info" ref={shareRef} onBlur={blurShareHandler}>
        <ul>
          <li><a href={urlTelegram} target="_blank" rel="noreferrer noopener"><span className="icon icon-telegram" />   <FormattedMessage id="share-telegram" /></a> </li>
          <li><a href={urlTwitter} target="_blank" rel="noopener noreferrer"><span className="icon icon-twitter" />  <FormattedMessage id="share-twitter" /> </a></li>
          <li onClick={copyClipBoard as () => void}><img src={copyLink} alt="share" /> <FormattedMessage id="share-link" /> </li>
        </ul>
      </div>}
      {showCopied &&
      <div className="copied">
          <span>Copied!</span>
        </div>}
    </div>
  )
}
