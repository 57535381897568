import React, { Suspense, useState, useEffect } from "react"
import { FormattedMessage } from "react-intl"
import { useModal, registerModal, CloseModal } from "@gluedigital/modal"
import { ComboTriggerModalPropTypes, ComboTriggerModalData, ConditionProvider } from "src/routes/RecipeDiagram/helpers/types"
import ManualTriggerInfo from "./ManualTriggerInfo"
import Loading from "src/components/common/Loading/Loading"
import Motivation from "./Motivation/Motivation"

import "./ComboTriggerManualModal.sass"

const ComboTriggerManualModal = (props: ComboTriggerModalPropTypes) => {
  const { onSave, previousData, avoidLiquidation } = props
  const [triggerData, setTriggerData] = useState<ComboTriggerModalData>(previousData)

  const modal = useModal();
  const InputComponent = ManualTriggerInfo

  useEffect(() => {
    setTriggerData({ condition: { receivedUserInput: false }, conditionProvider: ConditionProvider.user, avoidLiquidation })
  }, [])

  const handleClick = () => {
    modal.show('combo-trigger-modal', props)
  }

  return (
    <div className="combo-trigger-modal modal-content">
      <div className="modal-wrapper">
        <header className="header-buttons">
        <CloseModal>
            <div className="buttons">
              <button className="modal-close-button">
                <span className="icon icon-close" />
              </button>
            </div>
          </CloseModal>
          <button className="back-button" onClick={handleClick}>
            <span className="icon icon-angle_left" />
            <span><FormattedMessage id="back" /></span>
          </button>
          <h1><FormattedMessage id="combo-type-manualexecution" /></h1>
            <button
              onClick={() => {
                onSave(triggerData)
                modal.hide()
              }}
              className="buttom-filled"
            >
              <FormattedMessage id="save" />
            </button>
        </header>
        <Suspense fallback={<Loading />}>
          <InputComponent />
          <Motivation triggerData={triggerData} setTriggerData={setTriggerData} />
        </Suspense>
      </div>
    </div >
  )
}

registerModal("combo-trigger-modal-manualexecution", ComboTriggerManualModal)
