import React, { useState, useEffect } from "react"
import { usePaginatedPublic } from "src/api/recipes"
import MobileRecipeList from "../../../MobileRecipeList/MobileRecipeList"
import { SortByEnum } from "../types";

const MobileBrowseList = ({ searchTerm, showOnlyVerified = false, sortBy }: { searchTerm: string, showOnlyVerified?: boolean, sortBy?: SortByEnum }) => {
  const paginatedRecipes = usePaginatedPublic(searchTerm, showOnlyVerified, sortBy)
  const [recipes, setRecipes] = useState(paginatedRecipes.data)

  const infiniteScrollCb = () => {
    if (!paginatedRecipes.isLoadingMore) {
      paginatedRecipes.loadMore()
        .then((res) => setRecipes(res))
        .catch(err => err.message)
    }
  }
  useEffect(() => {
    setRecipes(paginatedRecipes.data)
  }, [paginatedRecipes])

  return (
    <MobileRecipeList recipes={recipes} type={"public"} loadMoreCb={infiniteScrollCb} showLoadMoreButton={paginatedRecipes.hasNext} />
  );
};

export default MobileBrowseList
