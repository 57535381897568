import React from "react"
import { registerModal, CloseModal, useModal } from "@gluedigital/modal"
import { FormattedMessage } from "react-intl"
import { ValidationError, ValidationWarning } from "src/hooks/useValidator"
import { useDispatch } from "react-redux"
import { focusNode } from "src/store/actions/diagram"
import "./ValidationModal.sass"
import { isNotWarning } from "src/routes/RecipeDiagram/helpers/validationWarningHelper"

interface ValidationModalProps {
  errors: ValidationError[]
  warnings: ValidationWarning[]
}
const ValidationModal = ({ errors, warnings }: ValidationModalProps) => {
  const dispatch = useDispatch()
  const modal = useModal()

  const handleClick = (nodeID: string) => {
    dispatch(focusNode(nodeID))
    modal.hide()
  }

  const nodeTypeConversionToMessage = {
    addFundsNode: "validation-modal-nodes.max-addfunds",
    splitNode: "validation-modal-nodes.max-splits",
    comboTriggerNode: "validation-modal-nodes.max-combos"
  }

  const nodeTypeConversionToMaxNumber = {
    addFundsNode: 1,
    splitNode: 2,
    comboTriggerNode: 5
  }

  return <div className="modal-content">
    <div className="modal-wrapper">
      <header className="header-buttons">
        <CloseModal>
          <button>
            <span className="icon icon-close" />
            <span><FormattedMessage id="close" /></span>
          </button>
        </CloseModal>
        <h2><FormattedMessage id="validation-modal.title" /></h2>
      </header>
      <div className="validation-modal-wrapper">
        <span><FormattedMessage id="validation-modal.subtitle" /></span>
        <div className="errors-list">
          {errors.map((error) =>
            <div className="error-item" key={`${error.errorType}/${error.node.id}`} onClick={() => handleClick(error.node.id)}>
              <span className="icon icon-exclamation" />
              <span><FormattedMessage id={`node.${error.node?.type}`} />{' '}
                {error.node.type === "sendToWalletNode" ? error.node.data.inputCoin : error.node.type !== "myCustomEdge" ? error.node.data.outputCoin : ' '}
              </span>
              <span className="error-type-label"><FormattedMessage id={`validation-modal.${error.errorType}`} /></span>
            </div>)}
        </div>
        {!isNotWarning(warnings) &&
          <div className="warnings-list">
            {warnings.map(w =>
              w.warningType !== undefined &&
              <div className="error-item" key={w.nodeType}>
                {w.nodeType === "farmNode" ?
                  <>
                    <span className={w.warningType === 'yellow' ? "icon icon-exclamation-warning" : "icon icon-exclamation"} />
                    <span>
                      {w.warningType === 'yellow' ? <FormattedMessage id="validation-modal-yellow-warning" /> : <FormattedMessage id="validation-modal-red-warning" />}
                    </span>
                  </>
                  :
                  <>
                    <span className={"icon icon-exclamation-warning"} />
                    <span> <FormattedMessage id="validation-modal-nodes.warning" />. <FormattedMessage id={nodeTypeConversionToMessage[w.nodeType]} /> {nodeTypeConversionToMaxNumber[w.nodeType]}</span>
                  </>
                }
              </div>
            )}

          </div>}
      </div>
    </div>
  </div>
}

registerModal('validation-modal', ValidationModal)
