import { useSelector } from 'react-redux'
import usePromise from './usePromise'
import web3 from 'src/utils/web3'
import masterchefV2SpookyAbi from 'src/contracts/abis/masterchefV2-spooky.json'
import complexRewarderV2Abi from 'src/contracts/abis/complexRewarderV2.json'
import masterChefSpookyV3Abi from 'src/contracts/abis/masterChefV3.json'
import { AbiItem } from 'web3-utils'
import { complexRewarderV2Address, masterchefV2Address, masterchefV3Address } from 'src/constants'

const getTotallAllocPoint = async (version: number, networkId: string): Promise<number> => {
    const contract = version === 2
        ? new web3.eth.Contract(masterchefV2SpookyAbi as AbiItem[], masterchefV2Address[networkId])
        : new web3.eth.Contract(masterChefSpookyV3Abi as AbiItem[], masterchefV3Address[networkId])
    return contract.methods.totalAllocPoint().call()
}

export const useTotalAllocPoint = (version: number): number => {
    const networkId: string = useSelector((s: any) => (s.network.network))
    return usePromise(getTotallAllocPoint, version, networkId)
}

const getFarmAllocPoint = async (farmId: number, version: number, networkId: string): Promise<number> => {
    if (farmId !== undefined) {
        const masterchefSpooky = version === 2
        ? new web3.eth.Contract(masterchefV2SpookyAbi as AbiItem[], masterchefV2Address[networkId])
        : new web3.eth.Contract(complexRewarderV2Abi as AbiItem[], complexRewarderV2Address[networkId])
        const poolInfo = await masterchefSpooky.methods.poolInfo([farmId]).call()
        return poolInfo.allocPoint
    }
    return undefined
}

export const useFarmAllocPoint = (farmId: number, version: number = 2): number => {
    const networkId: string = useSelector((s: any) => (s.network.network))
    return usePromise(getFarmAllocPoint, farmId, version, networkId)
}

const getBooPerSecond = async (version: number, networkId: string): Promise<number> => {
    if (version === 2) {
        const masterchefSpookyV2 = new web3.eth.Contract(masterchefV2SpookyAbi as AbiItem[], masterchefV2Address[networkId])
        return masterchefSpookyV2.methods.booPerSecond().call()
    }
    const complexRewarderV2 = new web3.eth.Contract(complexRewarderV2Abi as AbiItem[], complexRewarderV2Address[networkId])
    return complexRewarderV2.methods.rewardPerSecond().call()
}

export const useBooPerSecond = (version: number = 2): number => {
    const networkId: string = useSelector((s: any) => (s.network.network))
    return usePromise(getBooPerSecond, version, networkId)
}

const getRewardToken = async (networkId: string): Promise<string> => {
    const complexRewarderV2 = new web3.eth.Contract(complexRewarderV2Abi as AbiItem[], complexRewarderV2Address[networkId])
    return complexRewarderV2.methods.rewardToken().call()
}

export const useRewardToken = (): string => {
    const networkId: string = useSelector((s: any) => (s.network.network))
    return usePromise(getRewardToken, networkId)
}
