
import usePromise from './usePromise'
import { YearnYDaemonVaultsData } from 'src/api/yearnVaults/yearnTypes'
import { filterYearnYDaemonData } from 'src/api/yearnVaults/yearnHelpers'
import { yearnVaultsYDaemon } from 'src/constants'
import yearnVaults from 'src/contracts/abis/yearnVaults.json'
import { AbiItem } from 'web3-utils'
import { useFetch } from 'ruse-fetch'
import web3 from 'src/utils/web3'
import { Networks } from 'src/utils/networkHelper'

export const useFetchYearnDaemonAllVaults = (networkId: string): YearnYDaemonVaultsData[] => {
  const data: any = useFetch(yearnVaultsYDaemon[networkId])
  const filteredData: YearnYDaemonVaultsData[] = filterYearnYDaemonData(data).filter((data) => data.apy > 0 && !data.display_name.toString().includes("Curve"))
  return filteredData
}

export const useFetchYearnDaemonSingleVault = (vaultAddress: string, networkId: string): YearnYDaemonVaultsData => {
  const chainId = networkId === Networks.fantom ? 250 : 42161
  const data: any = useFetch(`https://ydaemon.yearn.finance/${chainId}/vaults/${vaultAddress}?strategiesDetails=noDetails`)
  const filteredData: YearnYDaemonVaultsData[] = filterYearnYDaemonData([data])
  return filteredData[0]
}

const getPricePerShare = async (vaultAddress: string) => {
  const yearnContract = new web3.eth.Contract(yearnVaults as AbiItem[], vaultAddress)
  const pricePerShare = await yearnContract.methods.pricePerShare().call()
  return pricePerShare
}

export const useGetPricePerShare = (vaultAddress: string) => {
  const pricePerShare = usePromise(getPricePerShare, vaultAddress)
  return pricePerShare
}
