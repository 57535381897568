
import getAPIbyNetwork from 'src/utils/networkHelper'

export interface LoginResult {
  address: string
  balance: string
  provider: string
  signature: string
  ts: number
  version: string
}

export const logout = () => ({ type: "logout" })

export const login = (params: LoginResult) => async (dispatch, getState) => { // Redux thunk by default has property dispatch and getState
  const state = getState()
  const networkId: string = state?.network?.network
  const baseUrlApi: string = getAPIbyNetwork(networkId)
  const res = await fetch(baseUrlApi + '/login', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      signature: params.signature,
      address: params.address,
      ts: params.ts,
      version: params.version,
    })
  })
  const data: any = await res.json()
  if (!res.ok) throw new Error('Login error: ' + (data?.error || res.status))
  dispatch({
    type: 'login',
    payload: {
      token: data.token,
      provider: params.provider,
      // We're keeping the old structure here for backwards compatibility,
      // but we should refactor this for clarity soon
      address: params.address.slice(2).toLowerCase(),
      account: {
        account: params.address.toLowerCase(),
        balance: params.balance,
      }
    }
  })
}

export const updateBalance = (newBalance: string) => {
  return {
    type: "updateBalance",
    payload: { newBalance }
  }
}
