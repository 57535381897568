import React from "react"
import MobileInputBrowse from "./MobileInputBrowse/MobileInputBrowse"
import MobileFilterBrowse from "./MobileFilterBrowse/MobileFilterBrowse"
import MobileSortBrowse from "./MobileSortBrowse/MobileSortBrowse"
import { MobileBrowseProps } from "./types"

import './MobileBrowse.sass'

const MobileBrowse = ({ handleSearch, searchTerm, showOnlyVerified, handleShowOnlyVerified, sortBy, handleSortBy }: MobileBrowseProps) => {
  return (
    <div className="mobile-browse">
      <MobileInputBrowse searchTerm={searchTerm} handleSearch={handleSearch} />
      <div className="row">
        <MobileFilterBrowse handleShowOnlyVerified={handleShowOnlyVerified} showOnlyVerified={showOnlyVerified} />
        <MobileSortBrowse sortBy={sortBy} handleSortBy={handleSortBy} />
      </div>
    </div>
  )
}

export default MobileBrowse
