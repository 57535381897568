import { useMemo } from "react"
import { useSelector } from "react-redux"
import { useRecipeDetails } from "src/api/recipes"
import { tokenNameToAddress } from "src/components/Diagram/nodes/nodesLogsHelper"
import { getNetworkParams } from "src/components/modals/SelectWalletModal/helpers"
import { AddFundsElement, TokenForApproval } from "src/routes/RecipeDiagram/helpers/types"
import { RecipeDetails } from "src/types"

const useTokensForApprove = (id: number) => {
  const recipeDetails: RecipeDetails = useRecipeDetails(id)
  const networkId: string = useSelector((s: any) => (s.network.network))
  const networkParams = getNetworkParams(networkId)
  const native_token = networkParams.nativeCurrency.symbol
  const [fantomsForDeposit, initialTokensForApproval] = useMemo(() => {
    if (recipeDetails) {
      const addFundsNodes = recipeDetails.code.filter((node) => node.type === 'addFundsNode') as AddFundsElement[]
      const [fantom, tokens] = addFundsNodes.reduce((acc, currentNode) => {
        const { data } = currentNode
        if (data.outputCoin === native_token) {
          const fantomForDeposit: TokenForApproval = {
            amount: data.amount,
            status: "ready",
            id: native_token,
            address: tokenNameToAddress(data.outputCoin, networkId)
          }
          acc[0].push(fantomForDeposit)
        } else {
          const tokenForApproval: TokenForApproval = {
            amount: data.amount,
            status: "ready",
            id: data.outputCoin,
            address: tokenNameToAddress(data.outputCoin, networkId)
          }
          const tokenAlreadyAdded: TokenForApproval = acc[1].find((token) => token.id === tokenForApproval.id)
          if (!tokenAlreadyAdded) acc[1].push(tokenForApproval)
          else {
            const tokenIndexInAcc: number = acc[1].indexOf(tokenAlreadyAdded)
            acc[1][tokenIndexInAcc].amount = (Number(acc[1][tokenIndexInAcc].amount) + Number(tokenForApproval.amount)).toString()
          }
        }
        return acc
      }, [[], []])
      if (fantom.length > 1) {
        const newFantom = {
          address: fantom[0].address,
          id: fantom[0].id,
          status: fantom[0].ready,
          amount: null
        }
        for (let i = 0; i < fantom.length; i++) {
          newFantom.amount += Number(fantom[i].amount)
        }
        newFantom.amount = newFantom.amount.toString()
        return [newFantom, tokens]
      }
      return [fantom?.[0], tokens]
    } else {
      return [undefined, undefined]
    }
  }, [recipeDetails, networkId, native_token])
  return [fantomsForDeposit, initialTokensForApproval]
}

export default useTokensForApprove
