import { BeetsPoolsInfo, TokensBeetsPools } from "src/api/beets/beetsTypes"
import { lpProviderReaper, ReaperNestedData, ReaperSummaryVaultsData, ReaperVaultsData } from "src/api/reaperFinance/reaperTypes"
import { YearnSummaryVaultsData, YearnYDaemonVaultsData } from "src/api/yearnVaults/yearnTypes"

export interface NestedVaultsInfo {
  yearn?: YearnSummaryVaultsData
  reaper?: ReaperSummaryVaultsData
}

export const adaptReaperV2Info = (reaperVaults: ReaperNestedData[]): NestedVaultsInfo[] => {
  const newData = reaperVaults.map(vault => {
    const newElement: NestedVaultsInfo = {
      reaper: {
        address: vault.address,
        apy: vault.yields.apy * 100,
        tvl: Number(vault.tvl.tvl),
        name: vault.name,
        token: [
          {
            address: vault.tokens.lpToken.address,
            name: vault.tokens.lpToken.symbol
          }
        ],
        symbol: vault.tokens.lpToken.symbol,
        provider: "Reaper"
      }
    }
    return newElement
  })
  return newData
}

export const combineReaperAndYearn = (reaperVaults: ReaperVaultsData[], yearnVaults: YearnYDaemonVaultsData[], beetsVaults: BeetsPoolsInfo[]) => {
  const newYearnVaults = yearnVaults.map((vault) => {
    const newElement: NestedVaultsInfo = {
      yearn: {
        address: vault.address,
        apy: vault.apy,
        tvl: Number(vault.tvl.total_assets_usd),
        name: vault.formated_name,
        token: vault.token,
        symbol: vault.display_symbol,
        provider: "Yearn"
      },
      reaper: undefined
    }
    return newElement
  })

  const newReaperVaults = reaperVaults.map((vault) => {
    let newElement: NestedVaultsInfo
    const name: string = vault.cryptContent.name.toLowerCase()
    let tokens: any = vault.cryptContent.tokens
    if (tokens.length === 1 || name.includes("spooky") || name.includes("beethoven")) {
      const lpProvider: lpProviderReaper = obtainLpProvider(name, tokens)
      const poolId: string = obtainBeetsPoolId(vault, lpProvider)
      if (lpProvider === "Spooky") tokens.sort((a, b) => a.address.localeCompare(b.address))
      if (poolId) tokens = fixBeetsTokens(beetsVaults, poolId, tokens)
      newElement = {
        reaper: {
          tvl: vault.analytics.tvl,
          name: vault.cryptContent.name,
          token: tokens,
          address: vault.cryptContent.vault.address.toLowerCase(), // Es la dirección de la vault de Reaper
          apy: (vault.analytics.yields.year) * 100,
          lpAddress: vault.cryptContent?.lpToken?.address.toLowerCase(),
          provider: "Reaper",
          lpProvider,
          poolId
        },
        yearn: undefined
      }
    }
    return newElement
  })

  const resultVaults: NestedVaultsInfo[] = newYearnVaults.concat(newReaperVaults).filter(pool => pool)
  return resultVaults
}

export const chooseNestedProvider = (nestedData: any): YearnSummaryVaultsData | ReaperSummaryVaultsData => {
  if (nestedData?.yearn) return nestedData.yearn
  else if (nestedData?.reaper) return nestedData.reaper
}

const obtainLpProvider = (vaultName: string, vaultTokens: any): lpProviderReaper => {
  let lpProvider: lpProviderReaper
  if (vaultName.includes("spooky")) lpProvider = "Spooky"
  else if (vaultName.includes("beethoven")) lpProvider = "Beets"
  else if (vaultTokens.length === 1) lpProvider = undefined
  return lpProvider
}

const obtainBeetsPoolId = (vault: ReaperVaultsData, lpProvider: lpProviderReaper): string => {
  let pooldId: string = undefined as string
  if (vault.cryptContent.url) {
    const urlSplit: string[] = vault.cryptContent?.url.split('/')
    pooldId = lpProvider === "Beets" ? urlSplit[urlSplit.length - 1] : undefined
  } else if (vault.cryptContent.addLiquidity) {
    const addLiquiditySplit: string[] = vault.cryptContent?.addLiquidity.split('/')
    pooldId = lpProvider === "Beets" ? addLiquiditySplit[addLiquiditySplit.length - 1] : undefined
  }
  return pooldId
}

const fixBeetsTokens = (beetsPools: BeetsPoolsInfo[], poolId: string, currentTokens: any) => {
  const pool: BeetsPoolsInfo = beetsPools.find((pool) => pool.id.toLowerCase() === poolId.toLowerCase())
  if (pool) {
    const newTokens = pool.allTokens.map((token: TokensBeetsPools) => {
      return {
        name: token.symbol,
        address: token.address,
        weight: token.weight ?? undefined
      }
    })
    return newTokens
  } else return currentTokens
}

export const ftmNestedFilter = (updatedStrategies: any[]): YearnSummaryVaultsData[] | ReaperSummaryVaultsData[] => {
  const filteredNested = updatedStrategies.filter(nested =>
    (nested.token[0]?.address !== '0x04068DA6C83AFCFA0e13ba15A6696662335D5B75')
    && (nested.name !== 'Ice_top')
    && (nested.token[1]?.address !== '0xae45a827625116d6c0c40b5d7359ecf68f8e9afd')
    && (nested.name !== 'Calm Sea & Prosperous Voyage')
    && (nested.name !== 'The Explosive PGUNK!')
    && (nested.name !== 'The Solana Sonata')
    && (nested.name !== 'Oath Candelabra')
  )
  return filteredNested
}
