import React from "react"
import { FormattedMessage } from "react-intl"
import { getAmountPercentage } from '../../Diagram/nodes/nodesLogsHelper'
import 'src/routes/RecipeDiagram/SideBarViewLogs/SidebarViewLogs.sass'

interface ExtraDataSideBarLogsProps {
  tokenInput: string
  tokenOutput: string
  inputFeeAmountLive: number
  inputFeeAmountNoLive: number
  inputAmount: number
  outputAmount: number
  live: boolean
}

const ExtraDataSideBarLogs = ({ tokenInput, tokenOutput, inputFeeAmountLive, inputFeeAmountNoLive, inputAmount, outputAmount, live }: ExtraDataSideBarLogsProps) => {
  const DECIMALS_FOR_TOKEN_AMOUNT: number = 10
  const priceInfo = (inputAmount / outputAmount).toFixed(DECIMALS_FOR_TOKEN_AMOUNT)
  if (live) {
    return (
      <div className="div-extra-info">
        <div className="first-column">
          <p><FormattedMessage id="view-logs-price" />  </p>
          <p><FormattedMessage id="view-logs-fee" />  </p>
        </div>
        <div className="second-column">
          <p> {` 1 ${tokenInput} = ${priceInfo || 'Loading...'} ${tokenOutput} `} </p>
          <p> {` 0.20% = ${getAmountPercentage(inputFeeAmountLive, 0.20).toFixed(DECIMALS_FOR_TOKEN_AMOUNT)} ${tokenInput} `} </p>
        </div>
      </div>
    )
  } else {
    return (
      <div className="div-extra-info">
        <div className="first-column">
          <p><FormattedMessage id="view-logs-price" />  </p>
          <p><FormattedMessage id="view-logs-fee" />  </p>
        </div>
        <div className="second-column">
          <p> {` 1 ${tokenInput} = ${priceInfo || 'Loading...'} ${tokenOutput} `} </p>
          <p> {` 0.20% = ${getAmountPercentage(inputFeeAmountNoLive, 0.20).toFixed(DECIMALS_FOR_TOKEN_AMOUNT)} ${tokenInput} `} </p>
        </div>
      </div>
    )
  }
}

export default ExtraDataSideBarLogs
