import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { logout } from "src/store/actions/user"
import { useHistory } from "react-router-dom"
import MarkdownMessage from "@gluedigital/markdown-message"
import { FormattedMessage } from 'react-intl'
import { getNetworkParams } from '../modals/SelectWalletModal/helpers'
import { getItemByNetwork } from 'src/utils/networkHelper'
import './AccountData.sass'

interface AccountDataProps {
  account: string
}

const AccountData = ({ account }: AccountDataProps) => {
  const [open, setOpen] = useState(false)
  const dispatch = useDispatch()
  const history = useHistory()
  const networkId: string = useSelector((s: any) => (s.network.network))
  const networkParams = getNetworkParams(networkId)

  const scanName: string = getItemByNetwork(networkId, "FTMScan", "ARBIScan", "FTMScan")

  return (
    <div id="account-data" onClick={() => setOpen(!open)} className={open ? 'opened' : 'closed'}>
      <div className="account-number">
        {account.substr(0, 6) + "..." + account.slice(-4)}
      </div>
      <div className="account-actions">
        <a
          href={`${networkParams.blockExplorerUrls}/address/${account}`}
          target="_blank"
          rel="noopener noreferrer"
          className="button"
        >
          <MarkdownMessage
           id="account-data.view"
           values={{ scan: scanName }} />
        </a>
        <button className="disconnect" onClick={() => {
          dispatch(logout())
          history.push('/')
        }}><FormattedMessage id="disconnect" /></button>
      </div>
    </div>
  )
}

export default AccountData
