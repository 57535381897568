import React, { useState, useMemo, Suspense } from 'react'
import { useSelector } from 'react-redux'
import { registerModal } from '@gluedigital/modal'
import { FormattedMessage } from 'react-intl'
import { DepositChosenPair, DepositOnLPModalData } from 'src/routes/RecipeDiagram/helpers/types'
import { BaseNodeModal } from "../BaseNodeModal"
import featuredPools from 'src/data/featuredPools.json'
import PoolInfo from './PoolInfo'
import useInfiniteScroll from 'src/hooks/useInfiniteScroll'
import { useGetPoolsPairs } from 'src/hooks/useFarmsLogs'
import { PairDayData } from 'src/api/farms/types'
import { useFetchAllBeetsPools } from 'src/hooks/useBalancerPools'
import { BeetsPoolsInfo } from 'src/api/beets/beetsTypes'
import { combinePools, depositPoolsData } from './depositModalHelper'
import { LoadingDepositOnLPModal } from './LoadingDepositOnLpModal'
import SearchBarFarmModal from '../FarmModal/SearchBarFarmModal'
import OrderBySelector from '../FarmModal/OrderBySelector'
import HeaderDepositOnLpModal from './HeaderDepositOnLpModal'
import { useSearchFilterDepositOnLp } from './hooks/useSearchFilter'
import { Networks } from 'src/utils/networkHelper'
import { useBalancerPoolsFromApi } from 'src/api/recipes'
import { adaptBalancerInfo } from 'src/api/beets/balancerHelper'
import { BalancerPoolsInfo } from 'src/api/beets/balancerTypes'
import './DepositOnLPModal.sass'

interface DepositOnLPNodePropTypes extends BaseNodeModal<DepositOnLPModalData> { }

const PAGE_SIZE = 9

const resumePair = (pair: depositPoolsData): DepositChosenPair => {
  return {
    address: pair.address,
    poolId: pair?.poolId ? pair?.poolId : '',
    tokens: pair.tokens,
    provider: pair.provider,
    name: pair?.name ? pair?.name : '',
    addressTimestamp: pair?.addressTimestamp ? pair?.addressTimestamp : ''
  }
}

const DepositOnLPFantomModal = (props: DepositOnLPNodePropTypes) => {
  const networkId: string = useSelector((s: any) => (s.network.network))

  const [currentPage, setCurrentPage] = useState<number>(1)
  const [showLoadMoreButton, setLoadMoreButton] = useState<boolean>(true)
  const [chosenOption, setChosenOption] = useState<DepositChosenPair>(props?.previousData?.pair || null)
  const [sort, setSort] = useState<string>('LIQUIDITY')
  const [search, setSearch] = useState<string>('')

  const spookyPools: PairDayData[] = useGetPoolsPairs(networkId)
  const beetsPools: BeetsPoolsInfo[] = useFetchAllBeetsPools(networkId)

  const pools: depositPoolsData[] = useMemo(() => combinePools(spookyPools, beetsPools, networkId), [spookyPools, beetsPools, networkId])

  const featuredPairs = useMemo(() => pools.filter((pair) => featuredPools.includes(pair?.address)), [pools])

  const allPairs = useMemo(() => pools.filter((pair) => !featuredPools.includes(pair?.address)), [pools])

  const readyPairs: depositPoolsData[] = useSearchFilterDepositOnLp(allPairs, search, sort, currentPage)

  const loadMoreHandler = () => {
    if (PAGE_SIZE * currentPage < allPairs.length) {
      setCurrentPage(currentPage => currentPage + 1)
    } else {
      setLoadMoreButton(false)
    }
  }
  const ref: any = useInfiniteScroll(loadMoreHandler)

  const saveHandler = () => {
    const pair: DepositChosenPair = {
      addressTimestamp: chosenOption?.addressTimestamp,
      address: chosenOption.address,
      poolId: chosenOption?.poolId,
      tokens: chosenOption.tokens,
      provider: chosenOption.provider,
      name: chosenOption?.name
    }
    props.onSave({ pair })
  }

  return (
    <div className="deposit-lp-modal modal-content">
      <div className="modal-wrapper" ref={ref}>
        <HeaderDepositOnLpModal chosenOption={chosenOption} saveHandler={saveHandler} />
        <section className="main-section">
          <header>
            <SearchBarFarmModal setSearch={setSearch} />
            <OrderBySelector sort={sort} setSort={setSort} />
          </header>
          <ul className="deposit-list" >
            {featuredPairs.map((pair, i) =>
              <PoolInfo key={i} pair={pair} isChosen={chosenOption?.address === pair?.address} setChosenOption={(pair: depositPoolsData) => setChosenOption(resumePair(pair))} featured/>
            )}
            {readyPairs.map((pair, i) => <PoolInfo key={i} pair={pair} isChosen={chosenOption?.address === pair?.address} setChosenOption={(pair) => setChosenOption(resumePair(pair))}/>)}
            {showLoadMoreButton &&
              <li className="load-more-wrapper">
                <button className="load-more-button" onClick={loadMoreHandler}><FormattedMessage id="load-more" /></button>
              </li>}
          </ul>
        </section>
      </div>
    </div>
  )
}

const DepositOnLPArbitrumModal = (props: DepositOnLPNodePropTypes) => {
  const networkId: string = useSelector((s: any) => (s.network.network))

  const [currentPage, setCurrentPage] = useState<number>(1)
  const [showLoadMoreButton, setLoadMoreButton] = useState<boolean>(true)
  const [chosenOption, setChosenOption] = useState<DepositChosenPair>(props?.previousData?.pair || null)
  const [sort, setSort] = useState<string>('LIQUIDITY')
  const [search, setSearch] = useState<string>('')

  const spookyPools: PairDayData[] = useGetPoolsPairs(networkId)
  const balancerPoolsFromApi: BalancerPoolsInfo[] = useBalancerPoolsFromApi()
  const adaptedBalancerPools: BeetsPoolsInfo[] = adaptBalancerInfo(balancerPoolsFromApi)

  const pools: depositPoolsData[] = useMemo(() => combinePools(spookyPools, adaptedBalancerPools, networkId), [spookyPools, adaptedBalancerPools, networkId])

  const featuredPairs = useMemo(() => pools.filter((pair) => featuredPools.includes(pair?.address)), [pools])

  const allPairs = useMemo(() => pools.filter((pair) => !featuredPools.includes(pair?.address)), [pools])

  const readyPairs: depositPoolsData[] = useSearchFilterDepositOnLp(allPairs, search, sort, currentPage)

  const loadMoreHandler = () => {
    if (PAGE_SIZE * currentPage < allPairs.length) {
      setCurrentPage(currentPage => currentPage + 1)
    } else {
      setLoadMoreButton(false)
    }
  }
  const ref: any = useInfiniteScroll(loadMoreHandler)

  const saveHandler = () => {
    const pair: DepositChosenPair = {
      addressTimestamp: chosenOption?.addressTimestamp,
      address: chosenOption.address,
      poolId: chosenOption?.poolId,
      tokens: chosenOption.tokens,
      provider: chosenOption.provider,
      name: chosenOption?.name
    }
    props.onSave({ pair })
  }

  return (
    <div className="deposit-lp-modal modal-content">
      <div className="modal-wrapper" ref={ref}>
        <HeaderDepositOnLpModal chosenOption={chosenOption} saveHandler={saveHandler} />
        <section className="main-section">
          <header>
            <SearchBarFarmModal setSearch={setSearch} />
            <OrderBySelector sort={sort} setSort={setSort} />
          </header>
          <ul className="deposit-list" >
            {featuredPairs.map((pair, i) =>
              <PoolInfo key={i} pair={pair} isChosen={chosenOption?.address === pair?.address} setChosenOption={(pair: depositPoolsData) => setChosenOption(resumePair(pair))} featured/>
            )}

            {readyPairs.map((pair, i) => <PoolInfo key={i} pair={pair} isChosen={chosenOption?.address === pair?.address} setChosenOption={(pair) => setChosenOption(resumePair(pair))}/>)}
            {showLoadMoreButton &&
              <li className="load-more-wrapper">
                <button className="load-more-button" onClick={loadMoreHandler}><FormattedMessage id="load-more" /></button>
              </li>}
          </ul>
        </section>
      </div>
    </div>
  )
}

const DepositOnLPModalWrapper = (props: DepositOnLPNodePropTypes) => {
  const networkId: string = useSelector((s: any) => (s.network.network))
  return (
    <Suspense fallback={<LoadingDepositOnLPModal />}>
      {networkId === Networks.fantom && <DepositOnLPFantomModal {...props} />}
      {networkId === Networks.arbitrum && <DepositOnLPArbitrumModal {...props} />}
    </Suspense>
  )
}

registerModal('deposit-lp-modal', DepositOnLPModalWrapper)
