import { useState, useEffect } from "react"
import { useParams } from "react-router-dom"
import { useRecipeLogs } from "src/api/recipes"
import { getEtherStringAmountFromAddressFixed15, getInputAmountStringFromAddressDecimals } from "src/contracts/TokensDecimalsHelpers";
import { NodeExecutionResult, RecipeExecutionLog } from "src/types"

const ADDRESS_LENGTH: Number = 42
export const useLogsWithTokenDecimals = (): RecipeExecutionLog[] => {
  const [recipeLogsDecimal, setRecipeLogsDecimals] = useState<RecipeExecutionLog[]>([])

  const { id } = useParams()
  const recipeLogs = useRecipeLogs(+id)

  useEffect(() => {
    const logsPromises = recipeLogs.map(async (log: RecipeExecutionLog): Promise<RecipeExecutionLog> => {
      const events: NodeExecutionResult[] = await Promise.all(log.events.map(auxEvents))
      return { ...log, events }
    })
    Promise.all(logsPromises.map(async (p: Promise<RecipeExecutionLog>) => await p.catch((e) => {
      console.log("Error in sidebar logs promise", e.message)
      return e
    })))
      .then((results) => {
        const fullfilledLogs = results.filter(r => !(r instanceof Error));
        // const rejected = results.filter(r => r instanceof Error);
        setRecipeLogsDecimals(fullfilledLogs)
      })
      .catch(e => console.log("Final error in sidebarLogs", e.message))

    // This approach doesn´t work always because promise.all fails when some of the promise to resolve fails
    // Use promise.allSettle fix it but in this JS version it doesn´t exist.

    // Promise.all(logsPromises)
    //   .then((finalLog) => setRecipeLogsDecimals(finalLog))
    //   .catch(err => console.error(err.message))
  }, [recipeLogs])
  return recipeLogsDecimal
}

export const auxEvents = async (event: NodeExecutionResult): Promise<NodeExecutionResult> => {
  const eventCloned: NodeExecutionResult = { ...event }
  if (event?.output) {
    if (Array.isArray(event?.output)) {
      for (let index = 0; index < event?.output?.length; index++) {
        const out = event.output[index]
        const cb = async () => {
          const newValue = await getEtherStringAmountFromAddressFixed15(out.token, out.amount)
          eventCloned.output[index] = { ...out, amount: newValue.toString() }
        }
        cb().catch(err => console.error(err.message))
      }
    } else {
      if (event?.output?.token && event.output?.token?.length === ADDRESS_LENGTH) {
        const newValue = await getEtherStringAmountFromAddressFixed15(event.output?.token, event.output?.amount)
        eventCloned.output = { ...event.output, amount: newValue.toString() }
      }
    }
  }

  if (event?.input) {
    if (event.input.token.length === ADDRESS_LENGTH) {
      const newValue = await getInputAmountStringFromAddressDecimals(event.input?.token, event.input?.amount)
      eventCloned.input = { ...event.input, amount: newValue.toString() }
    }
  }
  return (eventCloned)
}
