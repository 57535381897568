import React, { useState } from "react"
import { FormattedMessage } from "react-intl"
import { registerModal, CloseModal } from "@gluedigital/modal"
import { ShortLongModalData, shortLongOperation } from "src/routes/RecipeDiagram/helpers/types"
import { BaseNodeModal } from "../BaseNodeModal"
import LeverageBar from "./LeverageBar"
import shortArrow from '../../../../static/images/short.svg'
import longArrow from '../../../../static/images/long.svg'
import { useSelector } from "react-redux"
import { Networks } from "src/utils/networkHelper"
import "./ShortLongModal.sass"

interface ShortLongPropTypes extends BaseNodeModal<ShortLongModalData> { }

const ShortLongModal = ({ onSave, previousData }: ShortLongPropTypes) => {
  const networkId: string = useSelector((s: any) => s.network.network)
  const ASSETS_TO_OPERATE: string[] = networkId === Networks.arbitrum ? ["BTC", "ETH", "LINK", "UNI"] : ["BTC", "ETH", "FTM", "BNB"]
  const [leverage, setLeverage] = useState<string>(previousData?.leverage ?? "25")
  const [operation, setOperation] = useState<shortLongOperation>(previousData?.operation ?? "short")
  const [operationCoin, setOperationCoin] = useState<string>(previousData?.operationCoin ?? ASSETS_TO_OPERATE[0])

  return (
    <div className="short-long-modal modal-content">
      <div className="modal-wrapper">
        <header className="header-buttons">
          <CloseModal>
            <button className="modal-close-button">
              <span className="icon icon-close" />
              <span><FormattedMessage id="close" /></span>
            </button>
          </CloseModal>
          <h1><FormattedMessage id="short-long-modal.title" /></h1>
          <CloseModal>
            <button
              disabled={!leverage}
              onClick={() => onSave({
                leverage,
                operation,
                operationCoin,
                provider: networkId === Networks.arbitrum ? "GMX" : "mummy"
              })}
              className="filled">
              <FormattedMessage id="save" />
            </button>
          </CloseModal>
        </header>
        <main className="short-long-modal-wrapper">
          <div className="short-long-operation">
            <button className={operation === "long" ? " long-button" : ""} onClick={() => setOperation("long")} >
              <FormattedMessage id="short-long-modal.long" />
              <img src={longArrow} alt="Arrow up" />
            </button>
            <button className={operation === "short" ? " short-button" : ""} onClick={() => setOperation("short")}>
              <FormattedMessage id="short-long-modal.short" />
              <img src={shortArrow} alt="Arrow down" />
            </button>
          </div>
          <section className="short-long-modal-operation-coin">
            <h2><FormattedMessage id={operation === "long" ? "short-long-modal.long" : "short-long-modal.short"} /></h2>
            <select onChange={e => setOperationCoin(e.target.value)} defaultValue={previousData?.operationCoin ?? "BTC"}>
              <FormattedMessage id="select.currency">
                {text => <option value="" hidden>{text}</option>}
              </FormattedMessage>
              {ASSETS_TO_OPERATE.map(asset => <option key={asset} value={asset}>{asset}</option>)}
            </select>
          </section>
          <section className="leverage">
            <h4><FormattedMessage id="short-long-modal.leverage" />: <span className="color-leverage">{leverage}x</span></h4>
            <LeverageBar leverage={leverage} setLeverage={setLeverage} />
          </section>
        </main>
      </div>
    </div>
  )
}

registerModal("short-long-modal", ShortLongModal)
