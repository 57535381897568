import { tokenAddressToName } from "src/components/Diagram/nodes/nodesLogsHelper"
import { NodeToken } from "src/routes/RecipeDiagram/helpers/types"
import { NodeExecutionResult } from "src/types"
import { nodesLogsNumber } from "src/constants"

export const getDepositFarmInfo = (events: NodeExecutionResult[], networkId: string) => {
  let executionSteps: number
  let depositEvent: NodeExecutionResult
  let token0: NodeToken
  let token1: NodeToken
  if (events !== undefined && events !== null) {
    executionSteps = events.length
    const splitEvent = events.find((event) => event.functionName === 'split')
    if (splitEvent) {
      const token0Log = splitEvent.output[0]
      const token1Log = splitEvent.output[1]
      token0 = { token: tokenAddressToName(token0Log.token.toLowerCase(), networkId), amount: token0Log.amount }
      token1 = { token: tokenAddressToName(token1Log.token.toLowerCase(), networkId), amount: token1Log.amount }
    }
    depositEvent = events.find((event) => event.functionName === 'depositOnFarm')
  } else {
    executionSteps = 0
  }
  return { token0, token1, executionSteps, depositEvent }
}

export const getWithdrawFarmInfo = (recipeStatus: string, events: NodeExecutionResult[], executionSteps: number, graphLastBlock?: number) => {
  let withdrawEvent: NodeExecutionResult
  let blockNumberAtOtherMoment: number
  if (executionSteps === nodesLogsNumber.ACTIVE_NODE || executionSteps === nodesLogsNumber.FINISHED_NODE) {
    if (executionSteps === nodesLogsNumber.ACTIVE_NODE) {
      if (recipeStatus === "active" || recipeStatus === "failed" || recipeStatus === "finished") blockNumberAtOtherMoment = graphLastBlock
    } else if (executionSteps === nodesLogsNumber.FINISHED_NODE) withdrawEvent = events.find((event) => event.functionName === "withdrawFromFarm")
  } else if (executionSteps === nodesLogsNumber.NO_EXECUTED_NODE) {
    blockNumberAtOtherMoment = graphLastBlock
  } else if (executionSteps === nodesLogsNumber.ABORTED_NODE) {
    blockNumberAtOtherMoment = graphLastBlock
    withdrawEvent = events.find((event) => event.functionName === "withdrawFromFarm")
  }
  return { withdrawEvent, blockNumberAtOtherMoment }
}
