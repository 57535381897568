import React from 'react'
import { FormattedMessage } from 'react-intl'
import featuredRecipes from 'src/data/featuredRecipes.json'
import featuredRecipesCandidate from 'src/data/featuredRecipesCandidates.json'
import { useFeaturedTrendingRecipes } from 'src/api/recipes'
import ButtonsCardMobile from '../ButtonCardMobile/ButtonsCardMobile'
import NoRecipesMobile from '../NoRecipesMobile/NoRecipesMobile'
import { ENV_ENVIROMENT } from 'src/constants'
import RecipeCardMobile from '../RecipeCardMobile/RecipeCardMobile'
import { useSelector } from 'react-redux'

const FeaturedRecipes = () => {
  const networkId: string = useSelector((s: any) => s.network.network)
  const featuredToUse = ENV_ENVIROMENT === "MAINNET_PRO"
    ? featuredRecipes.filter(r => r.network === networkId)
    : featuredRecipesCandidate.filter(r => r.network === networkId)
  const recipes = useFeaturedTrendingRecipes(featuredToUse.map(f => f?.id))
  if (recipes.length === 0) return <NoRecipesMobile />

  return (
    <section className="mobile-subsection-recipes" id="featured-recipes">
      <h2>
        <span><FormattedMessage id="mobile-section-recipes.featured-title" /></span>
        <span className="mobile-icon mobile-icon-ribbon" />
      </h2>
      <div className="mobile-recipe-grid">
        {recipes?.map(r =>
          <RecipeCardMobile key={r.id} recipe={r} withEarned={false}>
            <ButtonsCardMobile recipe={r} mode="deposit" />
          </RecipeCardMobile>
        )}
      </div>
    </section>
  )
}

export default FeaturedRecipes
