import React from 'react'
import { useSelector } from 'react-redux'
import { FormattedMessage } from 'react-intl'
import { RecipeDetails } from 'src/types'
import { CurrentFarmInfo } from '../../nodeModals/ComboTriggerModal/inputs/APRTriggerInput/APRTriggerInput'
import { useLastSyncGraphBlock } from 'src/hooks/useGraph'
import { useDepositBlockNumberFromTxHash } from 'src/hooks/useWeb3'
import { useAllFarmAllocPointsFromApi, useAllFarmsIdFromApi } from 'src/api/recipes'
import { usePairDayDataByBlockNumber } from 'src/hooks/usePairInfoFromGraph'
import { useBooPerSecond, useRewardToken, useTotalAllocPoint } from 'src/hooks/useMasterchefV2'
import { useTokenPriceByBlock } from 'src/hooks/useTokensLogsFromGraph'
import { calculateFarmApr, calculateFeeApr } from 'src/api/farms/aprHelpers'
import { REWARD_ADDRESS } from 'src/constants'
import Loading from 'src/components/common/Loading/Loading'

export interface ComboSpookySwapLogProps {
  data: any
  recipeDetails: RecipeDetails
  currentRowLog: any
}
const ComboSpookySwapLog = ({ data, recipeDetails, currentRowLog }: ComboSpookySwapLogProps) => {
  const networkId: string = useSelector((s: any) => (s.network.network))

  let blockNumberAtOtherMoment: number = useDepositBlockNumberFromTxHash(currentRowLog?.trans)
  const graphLastBlock: number = useLastSyncGraphBlock(networkId)
  if (blockNumberAtOtherMoment === undefined) blockNumberAtOtherMoment = graphLastBlock // caso de que el combo este activo o no se haya ejecutado
  const blockToUse: number = blockNumberAtOtherMoment >= graphLastBlock ? graphLastBlock : blockNumberAtOtherMoment
  const myFarmAddress: string = data?.condition?.farmID
  const pairInfo: any = usePairDayDataByBlockNumber(myFarmAddress, blockToUse)
  let myFarm = pairInfo.data.pairDayDatas[0]
  const splitDecimals: string[] = myFarm.reserveUSD.split('.')
  const liquidity = `${splitDecimals[0]}.${splitDecimals[1].slice(0, 2)}`
  const allFarmLPs: string[] = useAllFarmsIdFromApi(2)
  const allFarmLPsV3: string[] = useAllFarmsIdFromApi(3)
  const poolId: number = allFarmLPs.indexOf(myFarmAddress)
  const rewardToken: string = useRewardToken()
  const booPerSecond: number = useBooPerSecond()
  const rewardPerSecond: number = useBooPerSecond(3)
  const totalAllocPoint: number = useTotalAllocPoint(2)
  const totalAllocPointV3: number = useTotalAllocPoint(3)
  const booTokenPrice: number = useTokenPriceByBlock(REWARD_ADDRESS[networkId], blockNumberAtOtherMoment, graphLastBlock, networkId)
  const rewardTokenPrice: number = useTokenPriceByBlock(rewardToken.toLowerCase(), blockNumberAtOtherMoment, graphLastBlock, networkId)
  const aprFees: number = calculateFeeApr(myFarm.dailyVolumeUSD, liquidity, networkId)
  const allFarmsAllocPointV2: string[] = useAllFarmAllocPointsFromApi(2)
  const allFarmsAllocPointV3: string[] = useAllFarmAllocPointsFromApi(3)
  const allocPoint: number = Number(allFarmsAllocPointV2[poolId])
  if (allocPoint !== 0 && !isNaN(allocPoint)) {
    const aprFarm: number = calculateFarmApr(booPerSecond, allocPoint, totalAllocPoint, booTokenPrice, liquidity)
    myFarm = { ...myFarm, aprFarm, aprFees }
  } else {
    const indexV3: number = allFarmLPsV3.indexOf(myFarmAddress)
    if (indexV3 === -1) {
      myFarm = { ...myFarm, aprFees }
    } else {
      const aprFarm: number = calculateFarmApr(rewardPerSecond, Number(allFarmsAllocPointV3[indexV3]), totalAllocPointV3, rewardTokenPrice, liquidity)
      myFarm = { ...myFarm, aprFarm, aprFees }
    }
  }
  const currentFarmInfo: CurrentFarmInfo = {
    label: `${myFarm.token0.symbol} - ${myFarm.token1.symbol}`,
    apr: myFarm.aprFarm ? (myFarm.aprFarm + myFarm.aprFees).toFixed(2) : (myFarm.aprFees).toFixed(2)
  }

  if (myFarm === undefined) {
    return (
      <div className="combo-trigger-modal modal-content">
        <div className="modal-wrapper">
          <Loading />
        </div>
      </div>
    )
  }

  return (
    <>
      <div className="combo-trigger-grid">
        <section>
          <h2><FormattedMessage id="combo-trigger-modal.if" /></h2>
          <span className="span-info">{data.conditionProvider}</span>
        </section>
        <section>
          <h2><FormattedMessage id="combo-trigger-modal.says" /></h2>
          <span className="span-info">{currentFarmInfo?.label}</span>
        </section>
      </div>
      <section>
        <h2><FormattedMessage id="combo-trigger-modal.is" /></h2>
        <span className="span-info">
          <FormattedMessage id={`combo-trigger-modal.${data.condition.type}`} />
          <FormattedMessage id="combo-trigger-modal.than" /> {data.condition.value} %
        </span>
        <div className="div-current">
          {(recipeDetails?.status === "active" || recipeDetails?.status === "finished") &&
            <>
              <span className="span-current">
                <FormattedMessage id="combo-trigger-modal.current-apr" />{' '}
              </span>
              <span className="span-coin-price">
                {currentFarmInfo?.label} {currentFarmInfo?.apr} %
              </span>
            </>
          }
        </div>
      </section>
    </>
  )
}

export default ComboSpookySwapLog
