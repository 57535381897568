import React from 'react'
import { FormattedMessage } from 'react-intl'
import { useSelector } from 'react-redux'
import { YearnSummaryVaultsData, YearnYDaemonVaultsData } from 'src/api/yearnVaults/yearnTypes'
import { useFetchYearnDaemonSingleVault } from 'src/hooks/useYearn'

interface ApyYearnNestedModalLogProps {
  oldVaultData: YearnSummaryVaultsData
}
export const ApyYearnNestedModalLog = ({ oldVaultData }: ApyYearnNestedModalLogProps) => {
  const networkId: string = useSelector((s: any) => (s.network.network))
  const currentVaultData: YearnYDaemonVaultsData = useFetchYearnDaemonSingleVault(oldVaultData?.address, networkId)

  return (
    <div id="nested-strategy-data-1">
      <h3><FormattedMessage id="nested-strategies-modal.APY" /></h3>
      <strong className="span-coin">{(currentVaultData?.apy).toFixed(2)} %</strong>
    </div>)
}
