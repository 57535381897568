import web3 from "src/utils/web3"
import tokensImages from 'src/data/tokensImages.json'
import numberImages from 'src/data/numberImages.json'
import nestedStrategiesImages from 'src/data/nestedStrategiesImages.json'
import { Networks } from "src/utils/networkHelper"
import pairsAPIFantom from "./pairsApiFantom"
import pairsAPIArbitrum from "./pairsApiArbitrum"

export const tokenAddress = (address: string) => {
  return web3.utils.toChecksumAddress(address.toLowerCase())
}

export const getTimestampFromBlockNumber = async (blockNumber: number): Promise<number> => {
  const timestamp = (await web3.eth.getBlock(blockNumber)).timestamp
  return Number(timestamp)
}

export const getTokenImage = (symbol: string) => {
  if (tokensImages.includes(`${symbol.toLowerCase()}.png`)) {
    return `/logos/tokens/tokensByName/${symbol.toLowerCase()}.png`
  }
  return `/logos/tokens/tokensByName/TOKEN.svg`
}

export const getBeetsPoolsTokenImage = (tokensNumber: number) => {
  const imgNumber: number = tokensNumber - 1
  const img: string = `+${imgNumber}.png`
  if (numberImages.includes(img)) {
    return `/logos/numberImages/${img}`
  }
  return `/logos/tokens/tokensByName/TOKEN.svg`
}

export const getNestedStrategyImage = (symbol: string) => {
  if (nestedStrategiesImages.includes(`${symbol.toLowerCase()}.png`)) {
    return `/logos/nestedStrategies/${symbol.toLowerCase()}.png`
  } else {
    return `/logos/tokens/tokensByName/TOKEN.svg`
  }
}

export const getPairsAPI = (networkId: string) => {
  if (Networks.fantom === networkId) {
    return pairsAPIFantom
  } else {
    return pairsAPIArbitrum
  }
}
