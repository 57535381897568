import { useEffect, useRef, useState } from "react"
import { ProfitCondition } from "src/routes/RecipeDiagram/helpers/types"
import { setInput } from "./utils"
import { FarmGraphData, PairDayData } from "src/api/farms/types"
import { useFarmsOneDay } from "src/api/recipes"

export const useTriggerAPRInput = (triggerData) => {
  const condition = triggerData ? triggerData.condition as ProfitCondition : {}
  const [inputRange, setInputRange] = useState([1, 1])
  const [inputConditionValue, setInputConditionValue] = useState(condition?.value || '')
  const inputCondition = useRef<HTMLInputElement>(null)

  const getValue = () => setInput(inputCondition, inputRange, setInputRange, inputConditionValue, setInputConditionValue)

  useEffect(() => {
    inputCondition.current.setSelectionRange(inputRange[0], inputRange[1])
  }, [inputConditionValue, inputRange])

  const [farmInfo, setFarmInfo] = useState<PairDayData[]>(undefined)

  const farmValidationCondition = (pair: PairDayData): boolean => {
    return (
      pair?.id !== undefined &&
      pair?.pairAddress !== undefined &&
      pair?.token0 !== undefined &&
      pair?.token1 !== undefined &&
      pair?.aprFarm !== undefined &&
      pair?.aprFees !== undefined
    )
  }
  const farmsTotalInfo: FarmGraphData = useFarmsOneDay()
  useEffect(() => {
    const farmData: PairDayData[] = farmsTotalInfo.pools.data
    setFarmInfo(farmData.filter((pair) => farmValidationCondition(pair)))
  }, [farmsTotalInfo])

  return {
    getValue,
    inputCondition,
    inputConditionValue,
    farmInfo
  }
}
