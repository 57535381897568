import { reaperExternalApi } from "src/constants"
import { useFetch } from "ruse-fetch"
import { ReaperDataFromExternalApiv2, ReaperNestedData } from "src/api/reaperFinance/reaperTypes"
import usePromise from "./usePromise"
import web3 from "src/utils/web3"
import { AbiItem } from 'web3-utils'
import reaperVaultsAbi from '../contracts/abis/reaperVaults.json'
import { calculateLpPriceToReaperVaults } from "src/contracts/spookyPricesHelper"
import { calculateBPTPriceToReaperVaults } from "src/contracts/beetsPricesHelper"
import { getEtherNumberAmountFromAddress } from "src/contracts/TokensDecimalsHelpers"
import { usePrices } from "./usePrices"
import { Networks } from "src/utils/networkHelper"

export const useReaperFinanceAllVaults = (networkId: string): ReaperNestedData[] => {
  // const providers = ["spooky", "beethoven", "liquidV3", "stader", "wigo", "geist", "granary", "liquidV2", "multistrat", "radiant"]
  const data: ReaperDataFromExternalApiv2 = useFetch(reaperExternalApi[networkId])
  const reaperVaults: ReaperNestedData[] = Object.keys(data.data).map(key => ({ ...data.data[key] }))
  if (networkId === Networks.arbitrum) {
    const activeVaultsSortedByTVL: ReaperNestedData[] = reaperVaults.sort((a, b) => b.yields.apy - a.yields.apy)
    return activeVaultsSortedByTVL
  }
}

const reaperGetPricePerFullShare = async (vaultAddress: string): Promise<number> => {
  const reaperVaultContract = new web3.eth.Contract(reaperVaultsAbi as AbiItem[], vaultAddress)
  const pricePerFullShare = await reaperVaultContract.methods.getPricePerFullShare().call()
  return pricePerFullShare
}

export const useReaperGetPricePerFullShare = (vaultAddress: string): number => {
  const weiAmount: number = usePromise(reaperGetPricePerFullShare, vaultAddress)
  return weiAmount
}

const getPricesForReaper = async (tokenDepositedName: string, tokenAddress: string, networkId: string, alreadyIsPrice: string): Promise<string> => {
  if (alreadyIsPrice) return alreadyIsPrice
  let lpPrice: string
  if (tokenDepositedName === "LP") lpPrice = await calculateLpPriceToReaperVaults(tokenAddress, networkId)
  else if (tokenDepositedName === "BPT") lpPrice = await calculateBPTPriceToReaperVaults(tokenAddress, networkId)
  // else lpPrice = await getPrice(tokenDepositedName, "USDC_AXL", networkId)
  return lpPrice
}

export const useGetPricesForReaper = (tokenDepositedName: string, tokenAddress: string, networkId: string): string => {
  const price: string = usePrices(tokenDepositedName)
  return usePromise(getPricesForReaper, tokenDepositedName, tokenAddress, networkId, price)
}

export const useGetCorrectEtherAmount = (tokenAddress: string, amountWei: string): string => {
  const amountETH: string = usePromise(getEtherNumberAmountFromAddress, tokenAddress, amountWei)
  return amountETH
}
