import React, { useEffect, useMemo, useState } from 'react'
import { FormattedDate, FormattedMessage, FormattedTime } from 'react-intl'
import { useRecipePerformance } from 'src/api/recipes'

import { tokenAddressToName, tokenNameToAddress } from 'src/components/Diagram/nodes/nodesLogsHelper'
import { getEtherNumberAmountFromAddress } from 'src/contracts/TokensDecimalsHelpers'
import { NodeExecutionResult, PerformanceInfo, RecipeExecutionLog, RecipePerformance } from 'src/types'

import { NodeTokenWithPrice, LPTokenWithPrice } from 'src/routes/RecipeDiagram/helpers/types'
import { useSelector } from 'react-redux'
import { getNetworkParams } from 'src/components/modals/SelectWalletModal/helpers'

export interface TableData {
  input?: string
  output?: string
  date?: JSX.Element
  value?: string
}

type NodeEventWithDate = NodeExecutionResult & { date: Date }

export const getActiveOutput = async (currentToken: NodeTokenWithPrice | LPTokenWithPrice, networkId: string) => {
  const networkParams = getNetworkParams(networkId)
  const tokensList: string[] = networkParams.tokens.map(t => t.id.toLowerCase())
  if (!currentToken) return { output: null, date: null, value: null }
  let amount: string
  if (currentToken?.amount === '0') {
    return
  }

  const tokenLength: number = currentToken?.token?.length
  const yToken = networkParams?.yearnTokens?.find((token) => token.address.toLowerCase() === currentToken.token.toLowerCase())
  let token: string = tokenLength < 42 ? currentToken.token : tokenAddressToName(currentToken?.token, networkId)
  // This is the name to show
  if ("token0" in currentToken && "token1" in currentToken) {
    token = tokenAddressToName(currentToken?.token0, networkId) + '-' + tokenAddressToName(currentToken?.token1, networkId)
  } else if (token === "BPT") token = "BPT"
  else if (yToken) token = yToken?.id
  else if (token === "Unknown" && currentToken.token === "RFT") token = "RFT"
  else if (token === "Unknown") token = "BPT"
  if (Number(currentToken.amount) < 1) amount = currentToken.amount // the amount is provided from the API in ether
  else if (token === "RFT") amount = currentToken.amount // When is reaper provider the amount received is in Ether
  else if (tokensList.includes(tokenAddressToName(currentToken?.token, networkId).toLowerCase())) amount = currentToken?.amount // this is for case blocked tokens (the amount is in ether)
  else { // The other cases, the amount of BPT/LP is in Wei
    amount = await getEtherNumberAmountFromAddress(tokenLength === 42 ? currentToken.token.toLowerCase() : tokenNameToAddress(currentToken.token, networkId), currentToken?.amount.toString())
  }

  const output = `${(+amount).toFixed(18)} ${token}`
  const date = <FormattedMessage id="apr-details.date-now" />
  const value = '$' + ((+currentToken.totalValueUSD).toFixed(3) || '???')
  return { output, date, value }
}

export const outputTableDataDefault: TableData[] = [
  { output: null, date: null }
]
export const inputTableDataDefault: TableData[] = [
  { input: null, date: null }
]
export const emptyLogs: RecipeExecutionLog[] = []

export const useTableEvents = (id, recipeLogs, recipeDetails) => {
  const networkId: string = useSelector((s: any) => (s.network.network))

  const [outputTableData, setOutputTableData] = useState(outputTableDataDefault)
  const [inputTableData, setInputTableData] = useState(inputTableDataDefault)

  const recipeIdIfNotApr: number = recipeDetails?.performance?.apr ? null : id
  const resultPerformance: RecipePerformance = useRecipePerformance(recipeIdIfNotApr)
  const performance: PerformanceInfo = !resultPerformance ? recipeDetails?.performance : resultPerformance?.performance

  const data = useMemo(() => {
    const events: NodeEventWithDate[] = recipeLogs.reduce((acc, logItem,) => {
      const eventsWithDate: NodeEventWithDate[] = logItem.events.map((event) => ({ ...event, date: logItem.date }))
      return acc.concat(eventsWithDate)
    }, [])
    const inputs = events.filter((event) => event.nodeType === 'addFundsNode')
    const outputs = events.filter((event) => event.nodeType === 'sendToWalletNode' || event.nodeType === 'liquidateNode')
    return ({
      outputs,
      inputs
    })
  }, [recipeLogs])
  useEffect(() => {
    Promise.all([...data.outputs?.map(async currentEvent => {
      const currentOutput = currentEvent.output
      const tokenLength = currentOutput?.token?.length
      const token = tokenLength < 42 ? currentOutput.token : tokenAddressToName(currentOutput.token, networkId)
      const amount = await getEtherNumberAmountFromAddress(tokenLength === 42 ? currentOutput.token : tokenNameToAddress(currentOutput.token, networkId), currentOutput?.amount.toString())
      const output = `${+amount} ${token}`
      const date = <><FormattedDate value={currentEvent?.date} /> <FormattedTime value={currentEvent?.date} /></>
      const usdValue: string = Number(currentEvent.extraData.outputTokenWithPrice?.totalValueUSD).toFixed(3)
      const value = '$' + (usdValue || '???')
      return { output, date, value }
    }), ...performance?.blockedTokens.map(async (currentToken) => await getActiveOutput(currentToken, networkId)),
    ...performance?.rewardsValue.map(async (reward) => await getActiveOutput(reward, networkId))
    ])
      .then((newOutput) => {
        const filteredUndefined = newOutput.filter((output) => !!output)
        setOutputTableData(filteredUndefined)
      })
      .catch(e => console.error(e.message))
  }, [data.outputs, performance, networkId])

  useEffect(() => {
    Promise.all(data.inputs?.map(async currentEvent => {
      const currentInput = currentEvent?.input
      const tokenLength = currentInput?.token?.length
      const token = tokenLength < 42 ? currentInput.token : tokenAddressToName(currentInput?.token, networkId)
      const amount = await getEtherNumberAmountFromAddress(tokenLength === 42 ? currentInput?.token : tokenNameToAddress(currentInput?.token, networkId), currentInput?.amount.toString())
      const input = `${(+amount).toFixed(12)} ${token}`
      const date = <><FormattedDate value={currentInput?.date} /> <FormattedTime value={currentInput?.date} /></>
      const usdValue: string = Number(currentEvent?.extraData.inputTokenWithPrice?.totalValueUSD).toFixed(3)
      const value = '$' + (usdValue || '???')
      return { input, date, value }
    })).then((newInput) => {
      setInputTableData(newInput)
    }).catch(e => console.error(e.message))
  }, [data.inputs, networkId])

  return { inputTableData, outputTableData }
}
