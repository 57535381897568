import React from "react"
import { Recipe } from "src/types"
import useInfiniteScroll from "src/hooks/useInfiniteScroll"
import { FormattedMessage } from "react-intl"
import RecipeCardMobile from "../RecipeCardMobile/RecipeCardMobile"
import ButtonsCardMobile from "../ButtonCardMobile/ButtonsCardMobile"

import './MobileRecipeList.sass'

interface MobileRecipeListProps {
  recipes: Recipe[]
  type: 'active' | 'liquidated' | 'public'
  loadMoreCb?: () => void
  showLoadMoreButton?: boolean
}

const MobileRecipeList = ({ recipes, type = 'public', loadMoreCb, showLoadMoreButton }: MobileRecipeListProps) => {
  const ref: any = useInfiniteScroll(loadMoreCb, false)
  let mode: 'deposit' | 'withdraw' | 'again' = 'deposit'
  if (type === 'active') mode = 'withdraw'
  if (type === 'liquidated') mode = 'again'

  return (
    <>
      <div className="mobile-recipe-list" id={`mobile-recipe-list-${type}`} ref={ref}>
        {recipes.length ? recipes.map((recipe) =>
          <RecipeCardMobile key={recipe.id} recipe={recipe} withEarned={type === 'active'}>
            <ButtonsCardMobile recipe={recipe} mode={mode} />
          </RecipeCardMobile>
        ) : <h3><FormattedMessage id="no-recipes-found.title" /></h3>}
        {showLoadMoreButton
          && <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <button onClick={() => loadMoreCb()}>
              <FormattedMessage id="load-more" />
            </button>
          </div>
        }
      </div>
    </>
  )
}

export default MobileRecipeList
