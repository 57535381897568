import React, { useState, Suspense } from "react"
import { FormattedMessage } from 'react-intl'
import Loading from "src/components/common/Loading/Loading"
import BrowseList from "../HeaderBrowse/Browse/BrowseList/BrowseList"
import HomeList from "./HomeList/HomeList"
import HeaderBrowse from "../HeaderBrowse/HeaderBrowse"
import { SortByEnum } from "src/routes/Mobile/components/Explore/MobileBrowse/types"
import { toggleVerifiedView } from "src/store/actions/showOnlyVerified"
import { useDispatch } from "react-redux"

import "./HomeRecipes.sass"

const HomeRecipes = () => {
  const [searchTerm, setSearch] = useState('')
  const [sortBy, setSortBy] = useState<SortByEnum>(null)
  const [showOnlyVerified, setShowOnlyVerified] = useState<boolean>(false)
  const dispatch = useDispatch()
  let searchTimeout
  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    clearTimeout(searchTimeout)
    if (!e.target.value) {
      return setSearch('')
    }
    searchTimeout = setTimeout(() => {
      setSearch(e.target.value)
    }, 500)
  }
  const handleToggleShowVerified = () => {
    setShowOnlyVerified(!showOnlyVerified)
    dispatch(toggleVerifiedView(!showOnlyVerified))
  }
  return (
    <section id="home-recipes" className="recipes-section">
      <h1>
        <FormattedMessage id="recipes-section.home-recipes.title" />
      </h1>
      <HeaderBrowse
        searchTerm={searchTerm}
        sortBy={sortBy}
        showOnlyVerified={showOnlyVerified}
        setSortBy={setSortBy}
        handleSearch={handleSearch}
        handleToggleShowVerified={handleToggleShowVerified}
      />
      <Suspense fallback={<Loading />}>
        {
          ((searchTerm && searchTerm !== '') || showOnlyVerified || sortBy)
            ? <BrowseList showOnlyVerified={showOnlyVerified} searchTerm={searchTerm} sortBy={sortBy} />
            : <HomeList />
        }
      </Suspense>
    </section>
  )
}

export default HomeRecipes
