import React, { Suspense } from "react"
import { NestedExtraDataLogProps } from "../NestedExtraDataLog"
import { ReaperSummaryVaultsData, lpProviderReaper } from "src/api/reaperFinance/reaperTypes"
import { findNestedProvider } from "../nestedLogHelpers"
import { NodeExecutionResult } from "src/types"
import { nodesLogsNumber } from "src/constants"
import { FormattedMessage } from "react-intl"
import { EndReaperLeftLog } from "./EndReaperLeftLog"
import { ActiveReaperLeftLog } from "./ActiveReaperLeftLog"
import { tokenAddressToName } from "src/components/Diagram/nodes/nodesLogsHelper"
import { FarmLeftLogErrorBoundary } from "src/components/ErrorBoundary/FarmLeftLogErrorBoundary"
import { useSelector } from "react-redux"
import DepositedReaperLeftLog from "./DepositedReaperLeftLog"

const ReaperExtraDataLog = ({ previousData }: NestedExtraDataLogProps) => {
  const networkId: string = useSelector((s: any) => (s.network.network))

  let executionSteps: number = 0
  let depositAmount: number
  let tokenDepositedName: string
  let tokenDepositedAddress: string
  let depositPriceUSD: string
  let rfTokensDeposited: string

  const oldVaultData: ReaperSummaryVaultsData = findNestedProvider(previousData.data, previousData.type)
  const lpProvider: lpProviderReaper = oldVaultData?.lpProvider
  const nodeEvents: NodeExecutionResult[] = previousData.events
  const reaperVaultAddress: string = oldVaultData.address

  if (nodeEvents.length > nodesLogsNumber.NO_EXECUTED_NODE) {
    executionSteps = nodeEvents.length
    if (lpProvider === "Beets") {
      const depositEvent: NodeExecutionResult = nodeEvents.find((event) => event.functionName === "depositOnNestedStrategy")
      rfTokensDeposited = depositEvent?.output?.amount
      const swapDepositEvent: NodeExecutionResult = nodeEvents.find((event) => event.functionName === 'swapTokens' && event.nodeID === previousData?.id)
      const BeetsPoolDepositEvent: NodeExecutionResult = nodeEvents.find((event) => event.functionName === "depositOnLp")
      depositAmount = BeetsPoolDepositEvent?.output?.amount
      tokenDepositedAddress = BeetsPoolDepositEvent?.output?.token.toLowerCase()
      depositPriceUSD = swapDepositEvent?.extraData?.outputTokenWithPrice?.totalValueUSD === "0.000000000"
        ? swapDepositEvent?.extraData?.inputTokenWithPrice?.totalValueUSD
        : swapDepositEvent?.extraData?.outputTokenWithPrice?.totalValueUSD
      tokenDepositedName = "BPT"
    } else if (lpProvider === "Spooky") {
      const splitDepositEvent: NodeExecutionResult = nodeEvents.find((event) => event.functionName === 'split')
      depositPriceUSD = splitDepositEvent.extraData?.inputTokenWithPrice?.totalValueUSD
      const reaperVaultDepositEvent: NodeExecutionResult = nodeEvents.find((event) => event.functionName === "depositOnNestedStrategy")
      rfTokensDeposited = reaperVaultDepositEvent?.output?.amount.toString()
      const spookyPoolDepositEvent: NodeExecutionResult = nodeEvents.find((event) => event.functionName === "depositOnLp")
      tokenDepositedName = "LP"
      depositAmount = spookyPoolDepositEvent?.output?.amount
      tokenDepositedAddress = (spookyPoolDepositEvent?.output?.token).toLowerCase()
    } else if (!lpProvider) { // For Arbitrum network always is this
      if (executionSteps === nodesLogsNumber.ACTIVE_NODE) executionSteps += 1
      else if (executionSteps === nodesLogsNumber.FINISHED_NODE || executionSteps === nodesLogsNumber.ABORTED_NODE) executionSteps += 2
      const depositEvent: NodeExecutionResult = nodeEvents.find((event) => event.functionName === "depositOnNestedStrategy")
      rfTokensDeposited = depositEvent?.output?.amount
      const swapDepositEvent: NodeExecutionResult[] = nodeEvents.filter((event) => event.functionName === 'swapTokens' && event.nodeID === previousData?.id)
      depositAmount = swapDepositEvent[0]?.output?.amount
      depositPriceUSD = swapDepositEvent[0]?.extraData?.outputTokenWithPrice?.totalValueUSD === "0.000000000"
        ? swapDepositEvent[0]?.extraData?.inputTokenWithPrice?.totalValueUSD
        : swapDepositEvent[0]?.extraData?.outputTokenWithPrice?.totalValueUSD
      tokenDepositedName = tokenAddressToName(swapDepositEvent[0]?.output?.token.toLowerCase(), networkId)
      tokenDepositedAddress = swapDepositEvent[0]?.output?.token.toLowerCase()
    }
  }
  return (
    <div className="div-extra-info">
      <div className="first-column">
        <div className="logs-group-rows">
          <p><FormattedMessage id="farm-modal-log.deposited" />  </p>
          <div className="left-empty-position"></div>
          <div className="left-empty-position"></div>
        </div>
        {executionSteps === nodesLogsNumber.REAPER_ACTIVE_NODE && <div className="logs-group-rows">
          <p><FormattedMessage id="farm-modal-log.current-value" />  </p>
          <div className="left-empty-position"></div>
        </div>}
        {(executionSteps === nodesLogsNumber.REAPER_FINISHED_NODE || executionSteps === nodesLogsNumber.REAPER_ABORTED_NODE) && <div className="logs-group-rows">
          <p><FormattedMessage id="farm-modal-log.final-value" />  </p>
          <div className="left-empty-position"></div>
        </div>}
        <div className="logs-group-rows">
          {executionSteps === nodesLogsNumber.NO_EXECUTED_NODE && <p><FormattedMessage id="farm-modal-log.earned" />  </p>}
          {(executionSteps === nodesLogsNumber.REAPER_FINISHED_NODE || executionSteps === nodesLogsNumber.REAPER_ABORTED_NODE || executionSteps === nodesLogsNumber.REAPER_ACTIVE_NODE) && <p><FormattedMessage id="farm-modal-log.earned" />  </p>}
          <div className="left-empty-position"></div>
        </div>
      </div>

      <div className="second-column">
        <FarmLeftLogErrorBoundary type="current-earned" >
          <Suspense fallback={"Loading values..."}>
            <DepositedReaperLeftLog depositPriceUSD={depositPriceUSD} rfTokensDeposited={rfTokensDeposited} depositAmount={depositAmount} tokenDepositedName={tokenDepositedName} />
          </Suspense>
        </FarmLeftLogErrorBoundary>
        {executionSteps === nodesLogsNumber.REAPER_ACTIVE_NODE &&
          <FarmLeftLogErrorBoundary type="current-earned" >
            <Suspense fallback={"Loading values..."}>
              <ActiveReaperLeftLog
                reaperVaultAddress={reaperVaultAddress}
                depositPriceUSD={depositPriceUSD}
                rfTokensDepositedEther={rfTokensDeposited}
                depositTokenAmountEth={depositAmount}
                tokenDepositedName={tokenDepositedName}
                tokenDepositedAddress={tokenDepositedAddress} />
            </Suspense>
          </FarmLeftLogErrorBoundary>
        }
        {(executionSteps === nodesLogsNumber.REAPER_FINISHED_NODE || executionSteps === nodesLogsNumber.REAPER_ABORTED_NODE) &&
          <Suspense fallback={"Loading values..."}>
            <EndReaperLeftLog depositPriceUSD={depositPriceUSD} rfTokensDeposited={rfTokensDeposited} depositAmount={depositAmount} previousData={previousData} tokenDepositedName={tokenDepositedName} nodeEvents={nodeEvents} />
          </Suspense>}
      </div>
    </div>
  )
}

export default ReaperExtraDataLog
