import React, { useState, useEffect } from "react"
import { registerModal, useModal } from "@gluedigital/modal"
import { FormattedMessage } from "react-intl"
import { Link, useHistory } from 'react-router-dom'
import { usePeriodicFetch } from '@gluedigital/ruse-fetch-extras'
import { RecipeDetails, RecipeLogs } from 'src/types'
import { formatRecipeTitle } from "src/routes/routesHelper"
import useNetworkBaseUrl from "src/hooks/useNetworkBaseUrl"
import { useSelector } from "react-redux"
import { getNetworkParams } from "../SelectWalletModal/helpers"
import { getItemByNetwork } from "src/utils/networkHelper"
import arrow from 'src/static/images/back-arrow.svg'
import "./RecipeWorkingModal.sass"

interface RecipeWorkingModalProps {
  recipeID: number
  transactionHash: string
}

const RecipeWorkingModal = (props: RecipeWorkingModalProps) => {
  const { recipeID, transactionHash } = props

  const networkId: string = useSelector((s: any) => (s.network.network))
  const networkParams = getNetworkParams(networkId)
  const scanName: string = getItemByNetwork(networkId, "FTMScan", "ARBIScan", "FTMScan")
  const history = useHistory()
  const modal = useModal()

  const base = useNetworkBaseUrl()
  const [logsURL, setLogsURL] = useState(base + '/recipes/' + recipeID + '/logs')
  const [recipeLogs, setLogs] = useState<RecipeLogs[]>([])
  const interval = 3000

  const fetchLogs = usePeriodicFetch<RecipeLogs[]>(interval, logsURL)

  const [canAbort, setCanAbort] = useState<boolean | undefined>(false)
  const recipeDetails = usePeriodicFetch<RecipeDetails>(interval, base + '/recipes/' + recipeID)

  useEffect(() => {
    if (!fetchLogs) {
      return
    }
    if (Object.keys(fetchLogs).length > 0) {
      setLogs(fetchLogs)
      setLogsURL(null)
    }
  }, [fetchLogs])

  useEffect(() => {
    if (recipeDetails?.step && canAbort !== undefined) {
      setCanAbort(recipeDetails.step.length > 0)
    }
  }, [canAbort, recipeDetails])

  const viewRecipeHandler = () => {
    const adaptTitle: string = formatRecipeTitle(recipeDetails.title)
    history.push(`/recipe/${recipeID}/${adaptTitle}`); modal.hide()
  }
  // console.log(transactionHash)

  return (
    <div className="recipe-working-modal modal-content">
      <h1>
        <FormattedMessage id="recipe-working.success" values={{ b: chunks => <b>{chunks}</b> }} />
      </h1>
      <span className={`transaction-link-container ${transactionHash ? '' : 'disabled-transaction'}`}>
        <span><FormattedMessage id="recipe-summary.transaction-link" /></span>
        <a
          className={"transaction-link"}
          href={transactionHash ? `${networkParams.blockExplorerUrls[0]}tx/${transactionHash}` : 'javascript:void(0)'}
          target="_blank"
          rel="noreferrer noopener">
            {scanName}
        </a>
        <span className="transaction-status-container">
          <FormattedMessage id="recipe-summary.transaction-success" />
          <span className="status-icon" />
        </span>
      </span>
      <button onClick={viewRecipeHandler} disabled={Object.keys(recipeLogs).length === 0}>
        <FormattedMessage id="recipe-working.view-recipe" />
      </button>
      <Link onClick={() => modal.hide()} to="/dashboard/your">
        <img src={arrow} />
        <span><FormattedMessage id="recipe-working.dashboard" /></span>
      </Link>
    </div>
  )
}

registerModal('recipe-working-modal', RecipeWorkingModal)
