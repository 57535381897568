import React from "react"
import { FormattedMessage } from "react-intl"
import { useSelector } from "react-redux"
import { tokenNameToAddress } from "src/components/Diagram/nodes/nodesLogsHelper"
import { usePerpetualsCurrentPrice } from "src/hooks/usePerpetuals"
import { ShortLongNodeData } from "src/routes/RecipeDiagram/helpers/types"

interface CurrentPriceLeftLogProps {
    data: ShortLongNodeData
}
const CurrentPriceLeftLog = ({ data }: CurrentPriceLeftLogProps) => {
    const networkId: string = useSelector((s: any) => (s.network.network))
    const currentPrice: number = usePerpetualsCurrentPrice(tokenNameToAddress(data?.operationCoin, networkId))

    return <p className="main-text-logs">  {!isNaN(currentPrice) ? currentPrice?.toFixed(3) : <FormattedMessage id="waiting-for-data" />} $</p>
}

export default CurrentPriceLeftLog
