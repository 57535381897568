import React from "react"
import { FormattedMessage } from "react-intl"
import OnlyDesktopVeil from "src/components/OnlyDesktopVeil/OnlyDesktopVeil"
import Loading from "src/components/common/Loading/Loading"
import "src/components/modals/RecipeWorkingModal"
import "src/components/modals/WaitRecipeWorkingModal"
import "src/components/modals/RetryApprovalModal/RetryApprovalModal"
import "src/components/modals/CompleteModal"
import "./RecipeSummary.sass"
import { useRecipeDetails } from "src/api/recipes"
import { getRecipeSummary } from "../RecipeDiagram/helpers/nodeHelpers"
import SummaryList from "./SummaryList/SummaryList"

interface LoadingRecipeSummaryProps {
  history: any
  location: any
  match: any
}

const LoadingRecipeSummary = (props: LoadingRecipeSummaryProps) => {
  const recipeId = props.match.params.id
  const recipeDetails = useRecipeDetails(recipeId)
  const summary: any[] = getRecipeSummary(recipeDetails.code)
  return (
    <div id="recipe-summary" className="page">
      <a className="back">
        <span className="icon icon-arrow-right" />
        <span><FormattedMessage id="recipe-summary.back" /></span>
      </a>
      <h1><FormattedMessage id="recipe-summary.title" /></h1>
      <div>
        <Loading />
        <SummaryList summary={summary} />
      </div>
      <OnlyDesktopVeil />
    </div>
  )
}

export default LoadingRecipeSummary
