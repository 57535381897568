import { useMemo } from "react"
import { getNetworkParams } from "src/components/modals/SelectWalletModal/helpers"
import { Networks } from "src/utils/networkHelper"

export interface TokensList {
address: string
id: string
name?: string
}

export const useOrderTokens = (networkId: string): TokensList[] => {
  const tokens: TokensList[] = getNetworkParams(networkId).tokens
  const sortedCoins: TokensList[] = useMemo(() => {
    const sortedTokens: any[] = tokens.sort((a, b) => (a.id > b.id) ? 1 : (b.id > a.id) ? -1 : 0)
    const withoutMainTokens: any[] = sortedTokens.filter((token) => {
      if (networkId === Networks.fantom) return token.id !== "FTM" && token.id !== "USDC_AXL"
      else return token.id !== "ETH" && token.id !== "USDT"
    })
    const mainTokens: any[] = sortedTokens.filter((token) => {
      if (networkId === Networks.fantom) return token.id === "FTM" || token.id === "USDC_AXL"
      else return token.id === "ETH" || token.id === "USDT"
    })
    const sortedList = mainTokens.concat(withoutMainTokens)
    return sortedList
  }, [tokens, networkId])
  return sortedCoins
}
