const initialState = false

export default (state = initialState, action) => {
  switch (action.type) {
    case 'loadUserData':
      return true
    default:
      return state
  }
}
