import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Link } from 'react-router-dom'

import toy from 'src/static/images/not-found_toy.png'
import logo from 'src/static/images/logos/logo-3.png'
import './NotFound.sass'

const NotFound = () => {
  return (
    <div id="not-found" className="page">
      <header>
        <div className="container">
          <img className="logo" src={logo} alt="tortle logo" />
        </div>
      </header>
      <div className="split">
        <div className="item left">
          <h1>
            <span><FormattedMessage id="error" /></span>
            <strong> 404</strong>
          </h1>
          <Link to="/" className="button">
            <span><FormattedMessage id="back-home" /></span>
          </Link>
        </div>
        <div className="item right" />
        <img className="toy" src={toy} alt="Tortle Ninja logo" />
      </div>
    </div>
  )
}

export default NotFound
