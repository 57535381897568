import React, { memo, useMemo, useState } from "react";
import { FormattedMessage } from "react-intl";
import { SendToWalletModalData, SendToWalletNodeData } from "src/routes/RecipeDiagram/helpers/types";
import { Handle } from "react-flow-renderer";
import { useDispatch, useSelector } from "react-redux";
import { useCompareAccounts } from "./nodesLogsHelper";
import ButtonsSendToWallet from "./ButtonsSendToWallet";
import { useNodeValidator } from "src/hooks/useValidator";
import NodeExecutionTag from "./NodeExecutionTag";
import { focusNode } from "src/store/actions/diagram";
import "./SendToWalletNode.sass";
import { useAllRecipeDetails } from "src/hooks/useRecipeInfo";

interface SendToWalletNodeProps {
  data: SendToWalletNodeData
  id: string
}

export default memo(({ id, data }: SendToWalletNodeProps) => {
  const [showButtons, setShowButtons] = useState(false);

  const focusedNode = useSelector((state: any) => state.diagram.focused)
  const dispatch = useDispatch()

  const networkId: string = useSelector((s: any) => (s.network.network))
  const user = useSelector((s: any) => s.user)
  const userAddress = user?.account?.account
  const diagramisValid = useSelector((s: any) => s.validation.validated)

  const positionTarget: any = "left";

  const handleBlur = (e: React.FocusEvent<HTMLDivElement>) => {
    if (!e.currentTarget.contains(e.relatedTarget as Node)) {
      setShowButtons(false)
      dispatch(focusNode())
    }
  }

  const handleShowButtons = () => {
    dispatch(focusNode(id))
    if (compareAddresses) {
      setShowButtons(true)
    }
  }

  const compareAddresses: boolean = useCompareAccounts(userAddress)
  const recipeDetails = useAllRecipeDetails()
  const modalData: SendToWalletModalData = {
    ...data,
    address: userAddress
  }

  const isValid = useNodeValidator(id, data, "sendToWalletNode", recipeDetails, networkId)

  const handleNodeStyle = useMemo(() => {
    if (!compareAddresses) {
      return ''
    } else if (recipeDetails?.status === 'failed') {
      return 'node-handle-failed'
    } else if (diagramisValid || recipeDetails?.status === 'active') {
      return 'handle-valid'
    } else if (data.inputCoin) {
      return 'handle-connected'
    } else {
      return 'node-handle-private'
    }
  }, [compareAddresses, diagramisValid, data.inputCoin])

  return (
    <div className={`node ${focusedNode && focusedNode !== id ? 'node-unfocused' : ''}`} onClick={handleShowButtons} onBlur={handleBlur} tabIndex={0}>
      {(recipeDetails?.status === "finished" || recipeDetails?.status === "active" || recipeDetails?.status === "failed") &&
      <NodeExecutionTag nodeID={id.toString()} /> }
      <div className={"send-to-wallet"}>
        {compareAddresses && !isValid && <span className="icon icon-exclamation" />}
        <Handle style={{ top: 50 }} className={`node-handle ${handleNodeStyle}`} type="target" position={positionTarget} id="send-to-wallet-input" />
      </div>
      <div className="node-info">
        <FormattedMessage id="node.sendToWalletNode" /> {data.inputCoin}
      </div>
      {recipeDetails?.status !== "failed" && (
          compareAddresses && showButtons && <ButtonsSendToWallet data={modalData} id={id} recipeDetails={recipeDetails} viewDetailsModalType={'send-to-wallet-modal'} />
      )}

    </div>
  );
});
