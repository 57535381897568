import React from "react"

import "./ExploreDefaultItem.sass"
import Spinner from "src/components/common/Loading/Spinner/Spinner"

const ExploreDefaultItem = () => {
  return (
    <div className="explore-item explore-default-item">
      <article className="recipe-card-default">
        <header />
        <p> <Spinner /></p>
        <footer />
      </article>
    </div>
  )
}

export default ExploreDefaultItem
