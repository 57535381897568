import React, { useState } from "react"
import { FormattedMessage } from "react-intl"
import { useModal, registerModal, CloseModal } from "@gluedigital/modal"
import { ComboTriggerModalPropTypes } from "src/routes/RecipeDiagram/helpers/types"

import pricefeeds from "../../../../static/images/price-feeds.png"
import timebased from "../../../../static/images/time-based.png"
import manualexecution from "../../../../static/images/manual-execution.png"

import "./ComboTriggerPriceModal"
import "./ComboTriggerAPRModal"
import "./ComboTriggerManualModal"
import "./ComboTriggerTimeModal"
import "./ComboTriggerSentimentModal"
import "./ComboTriggerProfitModal"

import "./ComboTriggerModal.sass"
import { DOCS_TROUBLESHOOTING } from "src/constants"

type ComboTriggerType = 'pricefeeds' | 'aprbased' | 'timebased' | 'manualexecution' | 'sentiment' | 'profit'

const ComboTriggerModal = (props: ComboTriggerModalPropTypes) => {
  const modal = useModal()
  const previousNodeType = props?.previousData?.previousNodeType
  const [isChecked, setIsChecked] = useState<boolean>((props?.avoidLiquidation || props?.previousData?.avoidLiquidation) ?? false)

  const handleAvoidLiquidationChange = () => setIsChecked(!isChecked)

  const handleClick = (type: ComboTriggerType) => {
    const props2 = { ...props, previousData: { condition: {}, previousNodeType }, avoidLiquidation: isChecked }
    switch (type) {
      case 'pricefeeds':
        modal.show('combo-trigger-modal-pricefeeds', props2)
        break
      case 'aprbased':
        modal.show('combo-trigger-modal-aprbased', props2)
        break
      case 'timebased':
        modal.show('combo-trigger-modal-timebased', props2)
        break
      case 'manualexecution':
        modal.show('combo-trigger-modal-manualexecution', props2)
        break
      case 'sentiment':
        modal.show('combo-trigger-modal-sentiment', props2)
        break
      case 'profit':
        modal.show('combo-trigger-modal-profit', props2)
        break
      default:
    }
  }

  return (
    <div className="combo-trigger-modal modal-content">
      <div className="modal-wrapper">
        <header className="header-buttons">
          <CloseModal>
            <button className="modal-close-button">
              <span className="icon icon-close" />
              <span><FormattedMessage id="close" /></span>
            </button>
          </CloseModal>
          <h1><FormattedMessage id="combo-trigger-modal.title" /></h1>
          <CloseModal>
          </CloseModal>
        </header>
        <div className="combo-types">
          <button className="combo-type-button" onClick={() => handleClick("pricefeeds")}>
            <img src={pricefeeds} alt="price feeds icon" />
            <span className="combo-type-text"><FormattedMessage id="combo-type-pricefeeds" /></span>
          </button>
          <button className="combo-type-button" onClick={() => handleClick("profit")} disabled={previousNodeType === "shortLongNode"}>
            <img src={manualexecution} alt="profits/losess icon" />
            <span className="combo-type-text"><FormattedMessage id="combo-type-profit" /></span>
          </button>
          <button className="combo-type-button" onClick={() => handleClick("timebased")}>
            <img src={timebased} alt="time based icon" />
            <span className="combo-type-text"><FormattedMessage id="combo-type-timebased" /></span>
          </button>
          <button className="combo-type-button" onClick={() => handleClick("manualexecution")}>
            <img src={manualexecution} alt="manual execution icon" />
            <span className="combo-type-text"><FormattedMessage id="combo-type-manualexecution" /></span>
          </button>
          {/* <button className="combo-type-button" onClick={() => handleClick("aprbased")}>
            <img src={aprbased} alt="apr based icon" />
            <span className="combo-type-text"><FormattedMessage id="combo-type-aprbased" /></span>
          </button> */}
          {/* <button className="combo-type-button" onClick={() => handleClick("sentiment")}>
            <img src={social} alt="social sentiment icon" />
            <span className="combo-type-text"><FormattedMessage id="combo-type-sentiment" /></span>
          </button> */}
        </div>
        {previousNodeType === 'shortLongNode' &&
          <div className="avoid-liquidation-container">
            <input
              type="checkbox"
              checked={isChecked}
              onChange={handleAvoidLiquidationChange}
            />
            <label><FormattedMessage id="try-to-avoid-liquidation" /> <a
              href={`${DOCS_TROUBLESHOOTING}/how-does-try-to-avoid-liquidation-work`}
              target="_blank"
              rel="noopener noreferrer"
              className="more-info"
            ><FormattedMessage id="more-info" /></a></label>
          </div>
        }
      </div>
    </div >
  )
}

registerModal("combo-trigger-modal", ComboTriggerModal)
