
import React, { useEffect, useState } from "react"
import { FormattedMessage } from "react-intl"
import ViewRecipeLogs, { ViewRecipeLogsProps } from "src/components/Log/ViewRecipeLogs/ViewRecipeLogs"
import { LogType, NodeExecutionResult, RecipeDetails, RecipeExecutionLog } from "src/types"
import { LeftLogsNodeStateLabel } from "./LeftLogsNodeStateLabel"
import check from "src/static/images/check.svg"
import { useLogsWithTokenDecimals } from "src/hooks/useLogsWithTokenDecimals"
import { NodeElement } from "../helpers/types"
import { SideBarErrorBoundary } from "src/components/ErrorBoundary/ErrorBoundary"
import { useLiveLogsWithTokenDecimals } from "src/hooks/useLiveLogsWithTokenDecimals"

import "./SidebarViewLogs.sass";

interface SideBarViewDetailsProps {
  nodes: NodeElement[]
  recipeDetails: RecipeDetails
}

// In this case, it is necessary to make a relation between the recipeLogs and the diagramNodes
const SidebarViewDetails = ({ recipeDetails, nodes }: SideBarViewDetailsProps) => {
  const recipeLogs: RecipeExecutionLog[] = useLogsWithTokenDecimals()
  const liveRecipeLogs: RecipeExecutionLog[] = useLiveLogsWithTokenDecimals()
  const [nodesLogInfo, setNodesLogInfo] = useState<ViewRecipeLogsProps[]>([])

  useEffect(() => {
    const updatedNodesLogs = []
    for (const node of nodes) {
      const nodeDate: Date[] = []
      let eventsForNode: NodeExecutionResult[] = []
      const txHash: string[] = []
      let live: boolean = false
      let logType: LogType
      recipeLogs.forEach((log) => {
        const eventsInLog = log.events.filter((event) => event.nodeID === node.id)
        if (eventsInLog.length > 0) {
          logType = logType === "liquidated" ? logType : log.logtype
          eventsForNode = eventsForNode.concat(log.events.filter((event) => event.nodeID === node.id))
          nodeDate.push(log.date)
          txHash.push(log.trans)
        }
      })
      liveRecipeLogs.forEach((log) => {
        const eventsInLog = log.events.filter((event) => event.nodeID === node.id)
        if (eventsInLog.length > 0) {
          live = true
          logType = logType === "liquidated" ? logType : log.logtype
          eventsForNode = eventsForNode.concat(log.events.filter((event) => event.nodeID === node.id))
          nodeDate.push(log.date)
          txHash.push(log.trans)
        }
      })
      const info: ViewRecipeLogsProps = {
        id: node.id,
        date: nodeDate,
        type: node.type,
        trans: txHash,
        data: node.data,
        events: eventsForNode,
        recipeStatus: recipeDetails.status,
        logType,
        live
      }
      updatedNodesLogs.push(info)
    }
    setNodesLogInfo(updatedNodesLogs)
  }, [recipeLogs, nodes, liveRecipeLogs, recipeDetails])

  return (
    <aside className="diagram-sidebar">
      <div className="diagram-sidebar-viewdetails">
        <h3><FormattedMessage id="view-logs-recipe-details" /> </h3>
        <SideBarErrorBoundary>
          {nodesLogInfo.map((node) => (
            <div key={node.id} className="all-node-info-div" >
              <LeftLogsNodeStateLabel recipeDetails={recipeDetails} node={node} check={check} />
              <ViewRecipeLogs {...node} />
            </div>
          ))}
        </SideBarErrorBoundary>
      </div>
    </aside >
  )
}

export default SidebarViewDetails
