import React from "react"
import { FormattedMessage } from "react-intl"
import { FarmData, PairDayData } from "src/api/farms/types"
import { useGetLpPriceFromApi, useGetTokenPriceUSDFromApi } from "src/api/recipes"
import { tokenAddressToName } from "src/components/Diagram/nodes/nodesLogsHelper"
import { useFarmRewardsAmountFromStrategy, useLpEarned } from "src/hooks/useFarmsLogs"
import { LPTokenWithPrice } from "src/routes/RecipeDiagram/helpers/types"
import { useSelector } from "react-redux"

interface EarnedV3FarmModalLogProps {
  previousData: any
  executionSteps: number
  masterchefInfo: PairDayData
  lpDepositUSDValue: number
  withdrawEvent: any
}

export const EarnedCurrentV3FarmModalLog = ({ previousData, executionSteps, masterchefInfo, lpDepositUSDValue }: EarnedV3FarmModalLogProps) => {
  const networkId: string = useSelector((s: any) => (s.network.network))

  const farmAddress: string = masterchefInfo.pairAddress
  const rewardToken: string = tokenAddressToName(masterchefInfo.tokenReward.toLowerCase(), networkId)
  const DECIMAL_USD_ROUNDED: number = 3
  const DECIMAL_TOKEN_ROUNDED: number = 12
  const version: number = masterchefInfo.poolIdV3 || masterchefInfo.poolIdV3 === 0 ? 3 : 2
  const farmData: FarmData = useLpEarned(previousData.id, previousData.date, executionSteps) || { state: 'Pending', lpDeposited: 'No Info', earned: 'No Info' }
  const totalLpAmount: number = Number(farmData.lpDeposited.slice(0, -2)) + Number(farmData.earned.slice(0, -2))
  const lpDifference: number = totalLpAmount - Number(farmData.lpDeposited.slice(0, -2))

  const amountRewardWei: number[] = useFarmRewardsAmountFromStrategy(previousData.id, version)
  const amountRewardEther: number = amountRewardWei[0] / 10 ** 18

  const lpPriceActiveNode: LPTokenWithPrice = useGetLpPriceFromApi(farmAddress, totalLpAmount.toString())
  const tokenRewardPriceUSD: string = useGetTokenPriceUSDFromApi(masterchefInfo.tokenReward)

  const totalLPUSDValueAtOtherMoment: number = Number(lpPriceActiveNode?.totalValueUSD)

  const rewardPriceUsd: number = parseFloat(tokenRewardPriceUSD)
  const rewardUSDValue: number = amountRewardEther * rewardPriceUsd
  const totalUSDValue: number = totalLPUSDValueAtOtherMoment + rewardUSDValue
  const differenceEarnedValue: number = totalUSDValue - lpDepositUSDValue

  const firstRow: JSX.Element = <strong className="span-coin">${totalUSDValue.toFixed(DECIMAL_USD_ROUNDED)} </strong>

  const secondRow: JSX.Element = <div className="div-2column-lps">
    <span className="lp-amount-row first-column">{totalLpAmount.toFixed(DECIMAL_TOKEN_ROUNDED)} <strong>LPs</strong></span>
    <span className="label-lp-price second-column">${totalLPUSDValueAtOtherMoment.toFixed(DECIMAL_USD_ROUNDED)} </span>
  </div>

  const thirdRow: JSX.Element = <div className="div-2column-lps">
    <span className="lp-amount-row first-column">{amountRewardEther.toFixed(DECIMAL_TOKEN_ROUNDED)} <strong>{rewardToken}</strong> </span>
    <span className="label-lp-price second-column">$ {rewardUSDValue.toFixed(DECIMAL_USD_ROUNDED)}</span>
  </div>
  return (
    <>
      <div id="farm-log-field">
        <h3><FormattedMessage id="farm-modal-log.current-value" /></h3>
        {firstRow}
        <> {secondRow} </>
        <> {thirdRow} </>
      </div>
      <div id="farm-log-field">
        <h3><FormattedMessage id="farm-modal-log.earned" /></h3>
        <strong className="span-coin">$ {(differenceEarnedValue).toFixed(DECIMAL_USD_ROUNDED)} </strong>
        <div className="div-2column-lps">
          <span className="lp-amount-row first-column">{lpDifference.toFixed(12)} <strong>LPs</strong></span>
        </div>
      </div>
    </>
  )
}
