import React from 'react'
import { Link } from 'react-router-dom'
import { useAtTop } from '@gluedigital/scrollastic'
import AuditBarAdvice from '../AuditBarAdvice/AuditBarAdvice'
import NetworkSelector from '../NetworkSelector/NetworkSelector'
import LaunchButton from '../LaunchButton/LaunchButton'

import logo from 'src/static/images/logos/logo-3.png'
import './Header.sass'

const Header = ({ scrollable }: { scrollable?: any }) => {
  const activeClass = useAtTop(scrollable) ? '' : 'active'
  return (
    <>
      <header id="landing-masthead" className={`${activeClass}`}>
        <div className="container">
          <div className="left-side">
            <Link className="link header-logo" to="/">
              <img loading="lazy" className="logo-image" src={logo} alt="tortle logo" />
            </Link>
          </div>
          <div className="right-side">
            <NetworkSelector />
            <LaunchButton text="landing-hero-banner" />
          </div>
        </div>
      </header>
      <AuditBarAdvice />
    </>
  )
}

export default Header
