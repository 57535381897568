import React from 'react'
import { TableData } from './AprDetails'
// import { FormattedMessage } from 'react-intl'

interface TableEventsProps {
  data: TableData[]
  name: string
}

const TableEvents = ({ data, name = 'default' }: TableEventsProps) => {
  const headings = data?.[0] ? Object.keys(data[0]) : []
  return (
    <table cellPadding={10} id={`table-events-${name}`} className="table-events">
      <thead>
        <tr>
          {headings.map(heading =>
            <th key={heading}>
              {/* <FormattedMessage id={`table-events-${name}.${heading}`} /> */}
              {heading}
            </th>
          )}
        </tr>
      </thead>
      <tbody>
        {data.map((row, i) =>
          <tr key={i}>
            {headings.map((heading, j) => <td key={j}>{row[heading]}</td>)}
          </tr>
        )}
      </tbody>
    </table>
  )
}

export default TableEvents
