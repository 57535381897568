import React from "react"
import { YearnSummaryVaultsData } from "src/api/yearnVaults/yearnTypes"
import { ReaperSummaryVaultsData } from "src/api/reaperFinance/reaperTypes"
import ReaperExtraDataLog from "./Reaper/ReaperExtraDataLog"
import YearnExtraDataLog from "./Yearn/YearnExtraDataLog"
import { findNestedProvider } from "./nestedLogHelpers"
import { NodeExecutionResult } from "src/types"
import { NodeType } from "src/routes/RecipeDiagram/helpers/types"
import 'src/routes/RecipeDiagram/SideBarViewLogs/SidebarViewLogs.sass'

export interface PreviousDataNestedLogs {
  data: any
  date: Date[]
  events: NodeExecutionResult[]
  id: string
  live: boolean
  recipeStatus?: string
  trans?: string[]
  type?: NodeType
}
export interface NestedExtraDataLogProps {
  previousData: PreviousDataNestedLogs
}

const NestedExtraDataLog = ({ previousData }: NestedExtraDataLogProps) => {
const oldVaultData: YearnSummaryVaultsData | ReaperSummaryVaultsData = findNestedProvider(previousData.data, previousData.type)

  return (
    <>
    {oldVaultData.provider === "Yearn" && <YearnExtraDataLog previousData={previousData} />}
    {oldVaultData.provider === "Reaper" && <ReaperExtraDataLog previousData={previousData} />}
    </>
  )
}

export default NestedExtraDataLog
