import { AbiItem } from 'web3-utils'
import routerSpookyAbi from './abis/router-spooky.json'
import uniswapV2FactoryAbi from './abis/UniswapV2Factory.json'
import uniswapV2PairAbi from './abis/UniswapV2Pair.json'
import { tokenNameToAddress } from '../components/Diagram/nodes/nodesLogsHelper'
import { getEtherStringAmountFromAddressFixed15, getWeiNumberAmountDependOnDecimals } from './TokensDecimalsHelpers'
import web3 from "src/utils/web3"
import { BigNumber } from 'ethers'
import { fetchTokenDataBeets } from 'src/hooks/useBalancerPools'
import { factoryV2Address, routerV2Address } from 'src/constants'

export const getPrice = async (tokenInput: string, tokenOutput: string, networkId: string): Promise<string> => {
  const dexRouter = new web3.eth.Contract(routerSpookyAbi as AbiItem[], routerV2Address[networkId])
  const tokenInputAddress = tokenNameToAddress(tokenInput, networkId)
  const tokenOutputAddress = tokenNameToAddress(tokenOutput, networkId)
  const inputAmount = await getWeiNumberAmountDependOnDecimals(tokenInput, 1, networkId)
  const tokenInPool = await getTokenPool(tokenInputAddress, networkId)
  const tokenOutPool = await getTokenPool(tokenOutputAddress, networkId)

  if (tokenInput === tokenOutput) {
    return '1'
  } else if (tokenInputAddress === tokenOutPool || tokenOutputAddress === tokenInPool) {
    const price = await dexRouter.methods.getAmountsOut(BigInt(inputAmount), [tokenInputAddress, tokenOutputAddress]).call()
    return await getEtherStringAmountFromAddressFixed15(tokenOutputAddress, price[1])
  } else if (tokenInPool !== tokenOutPool) {
    const price = await dexRouter.methods.getAmountsOut(BigInt(inputAmount), [tokenInputAddress, tokenInPool, tokenOutPool, tokenOutputAddress]).call()
    return await getEtherStringAmountFromAddressFixed15(tokenOutputAddress, price[3])
  } else {
    const price = await dexRouter.methods.getAmountsOut(BigInt(inputAmount), [tokenInputAddress, tokenInPool, tokenOutputAddress]).call()
    return await getEtherStringAmountFromAddressFixed15(tokenOutputAddress, price[2])
  }
}

export const getPriceInUSDCFantomNetwork = async (tokenInput: string, networkId: string): Promise<string> => {
  let price: string = await getPrice(tokenInput, 'USDC_AXL', networkId)

  if (price === '0') {
    const response = await fetchTokenDataBeets(tokenNameToAddress(tokenInput, networkId), networkId)
    price = response.data?.tokenGetTokenDynamicData?.price
  }
  return price
}

export const getTokenPool = async (tokenAddress: string, networkId: string): Promise<string> => {
  const dexRouter = new web3.eth.Contract(routerSpookyAbi as AbiItem[], routerV2Address[networkId])
  const uniswapV2Factory = new web3.eth.Contract(uniswapV2FactoryAbi as AbiItem[], factoryV2Address[networkId])
  const FTMAddress: string = tokenNameToAddress("FTM", networkId)
  const USDCAddress = tokenNameToAddress("USDC_AXL", networkId)
  const ETHAddress = tokenNameToAddress("ETH", networkId)

  const wftmTokenLp = await uniswapV2Factory.methods.getPair(FTMAddress, tokenAddress).call()
  const usdcTokenLp = await uniswapV2Factory.methods.getPair(USDCAddress, tokenAddress).call()
  const ethTokenLp = await uniswapV2Factory.methods.getPair(ETHAddress, tokenAddress).call()
  const wtftmusdcLp = await uniswapV2Factory.methods.getPair(FTMAddress, USDCAddress).call()
  const wtftmwethp = await uniswapV2Factory.methods.getPair(FTMAddress, ETHAddress).call()
  const uniswapV2Pair1 = new web3.eth.Contract(uniswapV2PairAbi as AbiItem[], wftmTokenLp)
  const uniswapV2Pair2 = new web3.eth.Contract(uniswapV2PairAbi as AbiItem[], usdcTokenLp)
  const uniswapV2Pair3 = new web3.eth.Contract(uniswapV2PairAbi as AbiItem[], wtftmusdcLp)
  const uniswapV2Pair4 = new web3.eth.Contract(uniswapV2PairAbi as AbiItem[], ethTokenLp)
  const uniswapV2Pair5 = new web3.eth.Contract(uniswapV2PairAbi as AbiItem[], wtftmwethp)

  let reserveWftm: number[];
  let reserveUsdc: number[]
  let reserveEth: number[]
  let reserveWftmWeth: number[]
  let reserveWftmUsdc: number[]
  let USDCtoWTFAmount: number;
  let ETHToWTFAmount: number;

  let reserveUsdcNumber: BigNumber
  let reserveWtfNumber: BigNumber
  let reserveEthNumber: BigNumber
  let USDCtoWTFAmountNumber: BigNumber
  let ETHToWTFAmountNumber: BigNumber
  const zero = 0
  let token0: string

  if (wftmTokenLp !== "0x0000000000000000000000000000000000000000") {
    reserveWftm = await uniswapV2Pair1.methods.getReserves().call()
    token0 = await uniswapV2Pair1.methods.token0().call()

    if (FTMAddress.toLowerCase() === token0.toLowerCase()) {
      reserveWtfNumber = BigNumber.from(reserveWftm[0].toString())
    } else { reserveWtfNumber = BigNumber.from(reserveWftm[1].toString()) }
  } else { reserveWtfNumber = BigNumber.from(zero.toString()) }

  if (usdcTokenLp !== "0x0000000000000000000000000000000000000000") {
    reserveUsdc = await uniswapV2Pair2.methods.getReserves().call()
    token0 = await uniswapV2Pair2.methods.token0().call()

    if (USDCAddress.toLowerCase() === token0.toLowerCase()) {
      reserveUsdcNumber = BigNumber.from(reserveUsdc[0].toString())
    } else { reserveUsdcNumber = BigNumber.from(reserveUsdc[1].toString()) }
    reserveWftmUsdc = await uniswapV2Pair3.methods.getReserves().call()
    USDCtoWTFAmount = await dexRouter.methods.getAmountOut(reserveUsdcNumber, reserveWftmUsdc[0], reserveWftmUsdc[1]).call()
    USDCtoWTFAmountNumber = BigNumber.from(USDCtoWTFAmount.toString())
  } else { USDCtoWTFAmountNumber = BigNumber.from(zero.toString()) }

  if (ethTokenLp !== "0x0000000000000000000000000000000000000000") {
    reserveEth = await uniswapV2Pair4.methods.getReserves().call()
    reserveWftmWeth = await uniswapV2Pair5.methods.getReserves().call()
    token0 = await uniswapV2Pair4.methods.token0().call()

    if (ETHAddress.toLowerCase() === token0.toLowerCase()) {
      reserveEthNumber = BigNumber.from(reserveEth[0].toString())
    } else { reserveEthNumber = BigNumber.from(reserveEth[1].toString()) }

    ETHToWTFAmount = await dexRouter.methods.getAmountOut(reserveEthNumber, reserveWftmWeth[1], reserveWftmWeth[0]).call()
    ETHToWTFAmountNumber = BigNumber.from(ETHToWTFAmount.toString())
  } else { ETHToWTFAmountNumber = BigNumber.from(zero.toString()) }

  let tokenPool: string | PromiseLike<string>
  if ((reserveWtfNumber.gte(USDCtoWTFAmountNumber)) && (reserveWtfNumber.gte(ETHToWTFAmountNumber))) {
    tokenPool = tokenNameToAddress("FTM", networkId)
  } else if ((reserveWtfNumber.gte(USDCtoWTFAmountNumber))) {
    if (reserveWtfNumber.lt(ETHToWTFAmountNumber)) {
      tokenPool = tokenNameToAddress("ETH", networkId)
    } else { tokenPool = tokenNameToAddress("FTM", networkId) }
  } else if ((reserveWtfNumber.gte(ETHToWTFAmountNumber))) {
    if (reserveWtfNumber.lt(USDCtoWTFAmountNumber)) {
      tokenPool = tokenNameToAddress("USDC", networkId)
    } else {
      tokenPool = tokenNameToAddress("FTM", networkId)
    }
  } else {
    if (ETHToWTFAmountNumber.gte(USDCtoWTFAmountNumber)) {
      tokenPool = tokenNameToAddress("ETH", networkId)
    } else { tokenPool = tokenNameToAddress("USDC", networkId) }
  }
  return tokenPool
}
