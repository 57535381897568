
import React, { useEffect, useState } from "react"
import ViewRecipeLogs, { ViewRecipeLogsProps } from "src/components/Log/ViewRecipeLogs/ViewRecipeLogs";
import { NodeExecutionResult, RecipeDetails, RecipeExecutionLog } from "src/types";
import { LeftLogsNodeStateLabel } from "./LeftLogsNodeStateLabel";
import check from "src/static/images/check.svg"
import { useLogsWithTokenDecimals } from "src/hooks/useLogsWithTokenDecimals";
import { useRecipeLogsLive } from "src/context/SocketContext";
import { SideBarErrorBoundary } from "src/components/ErrorBoundary/ErrorBoundary";
import { NodeElement } from "src/routes/RecipeDiagram/helpers/types";

import "./ViewLogs.sass";

interface SideBarViewDetailsProps {
  nodes: NodeElement[]
  recipeDetails: RecipeDetails
}

// In this case, it is necessary to make a relation between the recipeLogs and the diagramNodes
const ViewLogs = ({ recipeDetails, nodes }: SideBarViewDetailsProps) => {
  const recipeLogs: RecipeExecutionLog[] = useLogsWithTokenDecimals()
  const liveRecipeLogs = useRecipeLogsLive(recipeDetails?.id?.toString())
  const [nodesLogInfo, setNodesLogInfo] = useState<ViewRecipeLogsProps[]>([])

  useEffect(() => {
    const updatedNodesLogs = []
    for (const node of nodes) {
      const nodeDate: Date[] = []
      let eventsForNode: NodeExecutionResult[] = []
      const txHash: string[] = []
      let live: boolean = false
      recipeLogs.forEach((log) => {
        const eventsInLog = log.events.filter((event) => event.nodeID === node.id)
        if (eventsInLog.length > 0) {
          eventsForNode = eventsForNode.concat(log.events.filter((event) => event.nodeID === node.id))
          nodeDate.push(log.date)
          txHash.push(log.trans)
        }
      })
      liveRecipeLogs.forEach((log) => {
        const eventsInLog = log.events.filter((event) => event.nodeID === node.id)
        if (eventsInLog.length > 0) {
          live = true
          eventsForNode = eventsForNode.concat(log.events.filter((event) => event.nodeID === node.id))
          nodeDate.push(log.date)
          txHash.push(log.trans)
        }
      })
      const info: ViewRecipeLogsProps = {
        id: node.id,
        date: nodeDate,
        type: node.type,
        trans: txHash,
        data: node.data,
        events: eventsForNode,
        recipeStatus: recipeDetails.status,
        live
      }
      updatedNodesLogs.push(info)
    }
    setNodesLogInfo(updatedNodesLogs)
  }, [recipeLogs, nodes, liveRecipeLogs, recipeDetails])

  return (
    <div className="view-logs">
      <SideBarErrorBoundary>
        {nodesLogInfo.length && nodesLogInfo?.map((node) => (
          <div key={node.id} className="all-node-info-div" >
            <LeftLogsNodeStateLabel recipeDetails={recipeDetails} node={node} check={check} />
            <ViewRecipeLogs {...node} />
          </div>
        ))}
      </SideBarErrorBoundary>
    </div>
  )
}

export default ViewLogs
