import React from "react"
import { FormattedMessage } from "react-intl"
import { ReaperSummaryVaultsData } from "src/api/reaperFinance/reaperTypes"
import { TokenWithdrawFromReaper, getWithdrawValueFromPoolReaper } from "src/components/Log/NestedExtraDataLog/nestedLogHelpers"
import { getEtherStringAmountFromAddressFixed15 } from "src/contracts/TokensDecimalsHelpers"
import usePromise from "src/hooks/usePromise"
import { NodeToken } from "src/routes/RecipeDiagram/helpers/types"
import { NodeExecutionResult } from "src/types"

interface EarnedReaperNestedModalLogProps {
  oldVaultData: ReaperSummaryVaultsData
  nodeEvents: NodeExecutionResult[]
  depositPriceUSD: string
  amountRFTokensDepositedWei: string
  infoDepositAmountWei: NodeToken
  tokenDepositedName: string
}

const EarnedFinalReaperNestedModalLog = ({ nodeEvents, amountRFTokensDepositedWei, tokenDepositedName, depositPriceUSD, infoDepositAmountWei, oldVaultData }: EarnedReaperNestedModalLogProps) => {
  const DECIMAL_USD_ROUNDED: number = 4
  const rfToken: string = "RFT"
  const amountRFTokensDeposited: string = usePromise(getEtherStringAmountFromAddressFixed15, oldVaultData.address, amountRFTokensDepositedWei)
  const depositedAmountEther: string = usePromise(getEtherStringAmountFromAddressFixed15, infoDepositAmountWei.token.toLowerCase(), infoDepositAmountWei.amount)
  const withdrawal: TokenWithdrawFromReaper = getWithdrawValueFromPoolReaper(nodeEvents, oldVaultData)
  const withdrawAmountEther: string = usePromise(getEtherStringAmountFromAddressFixed15, infoDepositAmountWei.token.toLowerCase(), withdrawal.amount)
  const earnedPriceUSD: number = parseFloat(withdrawal.totalValueUSD) - parseFloat(depositPriceUSD)
  const earnedTokensEther: number = parseFloat(withdrawAmountEther) - parseFloat(depositedAmountEther)
  return (
    <>
      {/* FINAL VALUE */}
      <div id="nested-log-field">
        <h3><FormattedMessage id="nested-modal-log.final-value" /></h3>
        <strong className="span-coin">$ {Number(withdrawal.totalValueUSD).toFixed(DECIMAL_USD_ROUNDED)} </strong>
        <div className="div-2column-lps">
          <> <span className="lp-amount-row first-column">{Number(withdrawAmountEther).toFixed(12)} <strong>{tokenDepositedName}</strong></span></>
          <> <span className="lp-amount-row first-column">{Number(amountRFTokensDeposited).toFixed(12)} <strong>{rfToken}</strong></span></>
        </div>
      </div>
      {/* EARNED VALUE */}
      <div id="nested-log-field">
        <h3><FormattedMessage id="nested-modal-log.earned" /></h3>
        <strong className="span-coin">$ {Number(earnedPriceUSD).toFixed(DECIMAL_USD_ROUNDED)} </strong>
        <div className="div-2column-lps">
          <> <span className="lp-amount-row first-column">{Number(earnedTokensEther).toFixed(12)} <strong>{tokenDepositedName}</strong></span></>
          <></>
        </div>
      </div>
    </>
  )
}

export default EarnedFinalReaperNestedModalLog
