import React from 'react'
import { CloseModal } from '@gluedigital/modal'
import { FormattedMessage } from 'react-intl'
import Loading from 'src/components/common/Loading/Loading'
import './DepositOnLPModal.sass'

export const LoadingDepositOnLPModal = () => {
  return (
    <div className="deposit-lp-modal modal-content">
      <div className="modal-wrapper">
        <header className="header-buttons">
          <CloseModal>
            <button className="modal-close-button">
              <span className="icon icon-close" />
              <span><FormattedMessage id="close" /></span>
            </button>
          </CloseModal>
          <h1><FormattedMessage id="deposit-lp-modal.title" /></h1>
          <CloseModal>
            <button className="filled">
              <FormattedMessage id="save" />
            </button>
          </CloseModal>
        </header>
        <section className="main-section">
          <header>
            <label className="search">
              <FormattedMessage id="deposit-lp-modal.placeholder">
                {(text: string) => (
                  <input
                    type="text"
                    placeholder={text}
                  />
                )}
              </FormattedMessage>
              <span className="icon icon-search" />
            </label>
            <div className="select-wrapper">
              <select>
                <FormattedMessage id="deposit-lp-modal.sort-by-apr">
                  {(text) => <option value="APR">{text}</option>}
                </FormattedMessage>
                <FormattedMessage id="deposit-lp-modal.sort-by-liquidity">
                  {(text) => <option value="LIQUIDITY">{text}</option>}
                </FormattedMessage>
                <FormattedMessage id="deposit-lp-modal.sort-by-volume">
                  {(text) => <option value="VOLUME">{text}(24h)</option>}
                </FormattedMessage>
                <FormattedMessage id="deposit-lp-modal.sort-by-provider">
                  {(text) => <option value="PROVIDER">{text}</option>}
                </FormattedMessage>
              </select>
            </div>
          </header>
          <div className="deposit-list" >
            <Loading />
          </div>
        </section>
      </div>
    </div>
  )
}
