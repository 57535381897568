import React, { Suspense } from 'react'
import { Recipe } from 'src/types'
import TokenList from '../TokenList/TokenList'
import Spinner from '../common/Loading/Spinner/Spinner'
import ErrorBoundary from '../ErrorBoundary/ErrorBoundary'
import RecipeInfo from '../RecipeCardInfo/RecipeCardInfo'
import StrategyInfo from '../RecipeCardInfo/StrategyInfo/StrategyInfo'

import './RecipeCardV2.sass'
import NoApr from '../RecipeCardInfo/NoApr/NoApr'
interface RecipeCardMobileProps {
  recipe: Recipe
  type?: 'strategy' | 'recipe'
  onlyInfo?: boolean
  children?: React.ReactNode
}

const RecipeCardV2 = ({ recipe, type = "recipe", onlyInfo = false, children }: RecipeCardMobileProps) => {
  const { title, short } = recipe
  return (
    <article className="v2-recipe-card">
      <h1 title={title}>{title.length > 53 ? `${title.slice(0, 50)}...` : title}</h1>
      <ErrorBoundary fallback={<NoApr />}>
        <Suspense fallback={<Spinner />}>
          {type === 'recipe' ? <RecipeInfo recipe={recipe} /> : <StrategyInfo recipe={recipe} />}
        </Suspense>
      </ErrorBoundary>
      {!onlyInfo && <p title={short}>{short.length > 73 ? `${short.slice(0, 70)}...` : short}</p>}
      <footer>
        <Suspense fallback={<Spinner />}>
          <TokenList tokenList={recipe.tokenList} />
        </Suspense>
        {children}
      </footer>
    </article>
  )
}

export default RecipeCardV2
