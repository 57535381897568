import { ReaperSummaryVaultsData } from "src/api/reaperFinance/reaperTypes"
import { YearnSummaryVaultsData } from "src/api/yearnVaults/yearnTypes"
import { NodeToken, NodeType } from "src/routes/RecipeDiagram/helpers/types"
import { NodeExecutionResult } from "src/types"

export interface TokenWithdrawFromReaper extends NodeToken {
  totalValueUSD?: string
}

// This permit makes de the difference between Yearn or Reaper
export const findNestedProvider = (data: any, type: NodeType = null): ReaperSummaryVaultsData | YearnSummaryVaultsData => {
  if (type && type !== "nestedStrategiesNode") return undefined
  if (data?.yearn) return data.yearn
  else if (data?.reaper) return data.reaper
}
// Inside the group of Reaper vaults we have 3 different pool types: Beets/Spooky/Single Token.
// With this functions we can get the amount of LP/BPT/Token which is withdraw when we take out all the tokens from the pool.
export const getWithdrawValueFromPoolReaper = (nodeEvents: NodeExecutionResult[], reaperVaultData: ReaperSummaryVaultsData): TokenWithdrawFromReaper => {
  const result = { amount: '', token: '', totalValueUSD: null }
  if (reaperVaultData.lpProvider === "Spooky" || reaperVaultData.lpProvider === "Beets") {
    const swapEvents: NodeExecutionResult[] = nodeEvents.filter((ev) => ev.functionName === 'swapTokens')
    const withdrawFromLpEvent: NodeExecutionResult = nodeEvents.find((event) => event.functionName === "withdrawFromLp")
    result.amount = withdrawFromLpEvent.input.amount
    result.token = withdrawFromLpEvent?.input?.token
    result.totalValueUSD = swapEvents.length > 1
      ? swapEvents[1].extraData?.inputTokenWithPrice.totalValueUSD // this means that we use Beets pool
      : swapEvents[0].extraData?.inputTokenWithPrice.totalValueUSD
  } else if (!reaperVaultData.lpProvider) {
    const swapEvents: NodeExecutionResult[] = nodeEvents.filter((event) => event.functionName === "swapTokens")
    result.amount = swapEvents[1].input?.amount
    result.token = swapEvents[1]?.input?.token
    result.totalValueUSD = swapEvents[1]?.extraData?.outputTokenWithPrice.totalValueUSD
  }

  return result
}
