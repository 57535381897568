import React, { useEffect, useState } from "react"
import { FormattedMessage } from "react-intl"
import { ViewRecipeLogsProps } from "src/components/Log/ViewRecipeLogs/ViewRecipeLogs"
import { useRecipeDetailsLive, useRecipeLogsLive } from "src/context/SocketContext"
import { NodeExecutionResult, RecipeDetails, RecipeExecutionLog } from "src/types"
import { nodesLogsNumber } from "src/constants"
import { NodeType } from "../helpers/types"

interface LeftLogsNodeStateLabelProps {
  recipeDetails: RecipeDetails
  node: ViewRecipeLogsProps
  check: any
}

export const LeftLogsNodeStateLabel = ({ recipeDetails, node, check }: LeftLogsNodeStateLabelProps) => {
  const events: NodeExecutionResult[] = node?.events
  const eventsLength: number = events.length
  const [recipeStatus, setRecipeStatus] = useState<string>(recipeDetails?.status)

  const done: JSX.Element = <span className="span-status done"><img className="logo" src={check} alt="check" /> <FormattedMessage id="view-log-node-done" />  </span>
  const aborted: JSX.Element = <span className="span-status aborted"><FormattedMessage id="view-log-node-aborted" /> </span>
  const failed: JSX.Element = <span className="span-status failed"><FormattedMessage id="view-log-node-failed" /> </span>
  const inProgress: JSX.Element = <span className="span-status inprogress"><FormattedMessage id="view-log-node-inprogress" />  </span>
  const inQueue: JSX.Element = <span className="span-status inqueue"><FormattedMessage id="view-log-node-inqueue" /> </span>
  const skipped: JSX.Element = <span className="span-status skipped"><FormattedMessage id="view-log-node-skipped" /> </span>
  const liquidated: JSX.Element = <span className="span-status liquidated"><FormattedMessage id="view-log-node-liquidated" /> </span>

  const liveRecipeDetails: RecipeDetails = useRecipeDetailsLive((recipeDetails?.id)?.toString())
  const liveRecipeLogs: RecipeExecutionLog[] = useRecipeLogsLive((recipeDetails?.id)?.toString())

  const nodeTypesWithMore1Event: NodeType[] = ["farmNode", "depositOnLPNode", "nestedStrategiesNode", "shortLongNode"]

  useEffect(() => {
    if (liveRecipeDetails) {
      setRecipeStatus(s => s === "finished" ? s : liveRecipeDetails?.status)
    } else {
      setRecipeStatus(s => s === "finished" ? s : recipeDetails?.status)
    }
  }, [recipeDetails, liveRecipeDetails])

  useEffect(() => {
    if (liveRecipeLogs) {
      const lastLiveLog: RecipeExecutionLog = liveRecipeLogs[liveRecipeLogs?.length - 1]
      if (lastLiveLog?.logtype === "aborted" && lastLiveLog?.status === "finished" && lastLiveLog?.trans !== "no-transaction") {
        setRecipeStatus("finished")
      }
    }
  }, [liveRecipeLogs])

  if (recipeStatus === "failed") return failed
  else if (liveRecipeLogs[liveRecipeLogs?.length - 1]?.events.some(ev => ev.nodeID === node.id) && node.logType === "skipped") return skipped
  else if (node.logType === "liquidated") return liquidated
  else if (eventsLength === nodesLogsNumber.SHORT_LONG_FINISHED_NODE && node.type === "shortLongNode") return done
  else if (node.events.some(ev => ev.nodeID === node.id) && node.logType === "skipped") return skipped
  else if ((eventsLength > nodesLogsNumber.NO_EXECUTED_NODE) && !nodeTypesWithMore1Event.includes(node.type) && events[0].functionName !== "executeClosePerpPosition") return done
  else if (recipeStatus !== "failed" && (eventsLength === nodesLogsNumber.FINISHED_NODE)) return done
  else if (recipeStatus !== "failed" && (eventsLength === nodesLogsNumber.REAPER_FINISHED_NODE)) return done
  else if (recipeStatus === "active" && (eventsLength === nodesLogsNumber.REAPER_ACTIVE_NODE)) return inProgress
  else if ((recipeStatus === "active") && (eventsLength === nodesLogsNumber.ACTIVE_NODE) && nodeTypesWithMore1Event.includes(node.type)) return inProgress
  else if ((recipeStatus === "active") && (eventsLength === nodesLogsNumber.NO_EXECUTED_NODE)) return inQueue
  else if ((eventsLength === nodesLogsNumber.ABORTED_NODE)) return aborted
  else if ((recipeStatus === "finished") && (eventsLength === nodesLogsNumber.NO_EXECUTED_NODE)) return aborted
  else if ((recipeStatus === "finished") && (eventsLength === nodesLogsNumber.WITHDRAW_AFTER_PERPETUAL_NODE) && events[0].functionName === "executeClosePerpPosition") return aborted
  else if ((eventsLength === nodesLogsNumber.REAPER_ABORTED_NODE)) return aborted
}
