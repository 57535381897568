import { FormattedMessage } from "react-intl"
import React from 'react'
interface SearchBarFarmModalProps {
  setSearch: (s: string) => void
}
const SearchBarFarmModal = ({ setSearch }: SearchBarFarmModalProps) => {
  return (
    <label className="search">
      <FormattedMessage id="deposit-lp-modal.placeholder">
        {(text: string) => (
          <input
            type="text"
            onChange={(e) => setSearch(e.target.value)}
            placeholder={text}
          />
        )}
      </FormattedMessage>
      <span className="icon icon-search" />
    </label>
  )
}
export default SearchBarFarmModal
