import React, { Suspense } from "react"
import { FormattedMessage } from 'react-intl'
import RecipeList from "src/components/RecipeList/RecipeList"
import { usePeriodicRecipes } from "src/api/recipes"
import NoRecipes from "../NoRecipes/NoRecipes"
import Loading from "src/components/common/Loading/Loading"

const ActiveRecipes = () => {
  const recipes = usePeriodicRecipes('active')
  if (recipes.length === 0) return <NoRecipes />

  return (
    <section id="active-recipes" className="recipes-section">
      <h1>
        <FormattedMessage id="recipes-section.active-recipes.title" values={{ n: recipes.length }} />
      </h1>
      <RecipeList recipes={recipes} type={"active"} />
    </section>
  )
}

const ActiveRecipesWrapper = (props) => (
  <Suspense fallback={<Loading />}>
    <ActiveRecipes {...props} />
  </Suspense>
)

export default ActiveRecipesWrapper
