import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Link } from 'react-router-dom'

import './MobileDepositHeader.sass'

interface MobileDepositHeaderProps {
  disabled: boolean
  comeBackToHome: boolean
  isComeback: boolean
  comeBack: (s: boolean) => void
}

const MobileDepositHeader = ({ disabled, comeBack, isComeback, comeBackToHome }: MobileDepositHeaderProps) => {
  let button =
    <Link className="button" to="/mobile/explore">
      <span className="icon icon-close" />
      <span>
        <FormattedMessage id="cancel" />
      </span>
    </Link>
  if (isComeback && !comeBackToHome) {
    button =
      <button disabled={disabled} onClick={() => comeBack(false)}>
        <span className="icon icon-angle_left" />
        <span>
          <FormattedMessage id="back" />
        </span>
      </button>
  }
  if (comeBackToHome) {
    <Link className="button" to="/mobile/'active">
      <span className="icon icon-angle_left" />
      <span>
        <FormattedMessage id="back" />
      </span>
    </Link>
  }
  return (
    <header id="mobile-deposit-masthead">
      <div className="mobile-container">
        {button}
      </div>
    </header>
  )
}

export default MobileDepositHeader
