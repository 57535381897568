import React, { useEffect, useState } from "react"
import { FormattedMessage } from 'react-intl'
import { useModal } from '@gluedigital/modal'
import { useScrollOver } from '@gluedigital/scrollastic'
import { useFeaturedTrendingRecipes } from "src/api/recipes"
import ExploreItem from "./ExploreItem"
import featuredRecipesCandidate from 'src/data/featuredRecipesCandidates.json'
import featuredRecipes from 'src/data/featuredRecipes.json'
import trendingRecipes from 'src/data/trendingRecipes.json'
import trendingRecipesCandidate from 'src/data/trendingRecipesCandidate.json'
import { ENV_ENVIROMENT } from "src/constants"
import sparks from 'src/static/images/landing/sparks.svg'
import thunder from 'src/static/images/landing/thunder.svg'
import { RecipeDetails } from "src/types"
import ExploreDefaultItem from "./ExploreDefaultItem/ExploreDefaultItem"
import { useSelector } from "react-redux"
import "./Explore.sass"

const Explore = ({ scrollable }: { scrollable?: any }) => {
  const modal = useModal()
  const START_SHOWING_COMPONENT: number = 0.3 // useScrollOver returns a number > 0 when the component chosen starts to be shown. 0.3 == 30%
  const networkId: string = useSelector((s: any) => s.network.network)
  const trendingToUse = ENV_ENVIROMENT === "MAINNET_PRO"
  ? trendingRecipes.filter(r => r.network === networkId)
  : trendingRecipesCandidate.filter(r => r.network === networkId)
const featuredToUse = ENV_ENVIROMENT === "MAINNET_PRO"
  ? featuredRecipes.filter(r => r.network === networkId)
  : featuredRecipesCandidate.filter(r => r.network === networkId)
  const [isInfoRequested, setIsInfoRequested] = useState<boolean>(false) // This state is used to verify that we only request info the first time that we scroll
  const section = useScrollOver('#landing-explore', scrollable)
  const featured: RecipeDetails[] = useFeaturedTrendingRecipes(isInfoRequested && featuredToUse?.map(f => f?.id))
  const trending: RecipeDetails[] = useFeaturedTrendingRecipes(isInfoRequested && trendingToUse?.map(t => t?.id))
  const featuredImages = featuredRecipes.map(f => f.image)
  const trendingImages = trendingToUse.map(t => t.image)
  const defaultCards = new Array(3)

  useEffect(() => {
    if (section > 0) setIsInfoRequested(true)
  }, [section])

  return (
    <section id="landing-explore" className={'landing-section ' + (section > START_SHOWING_COMPONENT ? 'enter' : '')}>
      <div className="container">
        <h2 className="tortle-subtitle"><FormattedMessage id="landing.subtitle" /></h2>
        <h1><FormattedMessage id="landing-explore.title" /></h1>
        <p><FormattedMessage id="landing-explore.text" /></p>
        <section id="featured-section">
          <h3>
            <span><FormattedMessage id="landing-explore.featured.title" /></span>
            <img loading="lazy" src={sparks} alt="sparks vector" />
          </h3>
          {isInfoRequested ?
            <div className="recipe-grid">
              {featured?.map((recipe, i) =>
                <ExploreItem imageUrl={featuredImages[i]} key={recipe.id} recipe={recipe} />
              )}
            </div>
            : <div className="recipe-grid">
              {defaultCards.map((_d, i) => <ExploreDefaultItem key={i} />)}
            </div>
          }
        </section>
        <section id="trending-section">
          <h3>
            <span><FormattedMessage id="landing-explore.trending.title" /></span>
            <img src={thunder} alt="thunder vector" />
          </h3>
          {isInfoRequested ? <div className="recipe-grid">
            {trending?.map((recipe, i) =>
              <ExploreItem imageUrl={trendingImages[i]} key={recipe.id} recipe={recipe} />
            )}
          </div>
            : <div className="recipe-grid">
              {defaultCards.map((_d, i) => <ExploreDefaultItem key={i} />)}
            </div>
          }
        </section>
        <button onClick={() => modal.show('select-wallet-modal')}>
          <FormattedMessage id="landing-explore.more" />
        </button>
      </div>
    </section>
  )
}

export default Explore
