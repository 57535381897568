import React from "react"
import { FormattedMessage } from "react-intl"
import { registerModal, CloseModal } from "@gluedigital/modal"
import { useHistory } from 'react-router-dom';
import "./WhiteListModal.sass"

const WhiteListModal = () => {
  const history = useHistory()

  const goBackHome = () => { history.push('/') }
  const waitListUrl: string = "https://gleam.io/mDtEF/tortle-ninja-whitelist"

  return (
    <div className="recipe-name-modal modal-content">
      <div className="modal-wrapper">
        <header className="header-buttons">
          <h2><FormattedMessage id="whitelist-modal.title" /></h2>
        </header>
        <div className="whitelist-modal-wrapper">
          <a
            className="join-a"
            target="_blank"
            rel="noreferrer noopener"
            href={waitListUrl}>
            <FormattedMessage id="whitelist-modal.join-waitlist" />
          </a>
          <CloseModal>
            <button onClick={goBackHome} className="back-button">
              <FormattedMessage id="whitelist-modal.back-home" />
            </button>
          </CloseModal>
        </div>
      </div>
    </div>
  )
}

registerModal("whitelist-modal", WhiteListModal)
