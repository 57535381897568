const initialState = {
  pools: {
    data: [],
    updated: null,
  },
  spookyData: {
    data: [],
    updated: null,
  },
}

export default (state = initialState, action) => {
  switch (action.type) {
    case 'set_pools':
      return { ...state, pools: action.payload }
    case 'set_spooky_data':
      return { ...state, spookyData: action.payload }
    default:
      return state
  }
}
