
import React from 'react'
import { FormattedMessage } from 'react-intl'

import "./ComboProfitModalLog.sass"

export interface ComboProfitModalLogProps {
  data: any
  isNodeExecuted: boolean
}
const ComboProfitModalLog = ({ data, isNodeExecuted }: ComboProfitModalLogProps) => {
  return (
    <>
      <div id="combo-trigger-profit-modal-log">
        {!isNodeExecuted &&
          <p className="exectuable-text"><FormattedMessage id="combo-type-profit.text" /> {' ' + data?.condition?.performanceType + ' '}
            <FormattedMessage id="combo-type-profit.text2" /> {' ' + data?.condition?.value + ' %'}
          </p>
        }
        <section className={isNodeExecuted ? "profit-grid already-executed" : "profit-grid"}>
          <div className="column-profit">
            <h2><FormattedMessage id="combo-type-profit.when" /></h2>
            <span className="text-type">{data?.condition?.performanceType}</span>
          </div>
          <div className="column-profit">
            <h2><FormattedMessage id="combo-type-profit.are" /></h2>
            <span className="text-higher"><FormattedMessage id="combo-type-profit.higher" /></span>
          </div>
          <div className="column-profit">
            <h2><FormattedMessage id="combo-type-profit.than" /></h2>
            <span className="text-value">{data?.condition?.value} %</span>
          </div>
        </section>
      </div>
    </>
  )
}

export default ComboProfitModalLog
