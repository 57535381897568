import React, { Suspense } from 'react'
import { FormattedMessage } from 'react-intl'
import { getNestedStrategyImage } from 'src/api/farms/helpers'
import { YearnSummaryVaultsData } from 'src/api/yearnVaults/yearnTypes'
import Loading from 'src/components/common/Loading/Loading'
import { nodesLogsNumber } from 'src/constants'
import { ApyYearnNestedModalLog } from './ApyYearnNestedModalLog'
import { DepositedYearnNestedModalLog } from './DepositedYearnNestedModalLog'
import { EarnedCurrentYearnNestedModalLog } from './EarnedCurrentYearnNestedModalLog'
import { EarnedFinalYearnNestedModalLog } from './EarnedFinalYearnNestedModalLog'
import { NodeExecutionResult } from 'src/types'
import { getEventsForNode } from 'src/api/yearnVaults/yearnHelpers'
import { useSelector } from 'react-redux'
import { getNetworkParams } from 'src/components/modals/SelectWalletModal/helpers'
import '../NestedStrategiesModalLog.sass'

interface YearnNestedModalLogProps {
  oldVaultData: YearnSummaryVaultsData
  previousData: any
}

export const YearnNestedModalLog = ({ oldVaultData, previousData }: YearnNestedModalLogProps) => {
  let executionSteps: number
  let depositAmountWei: string
  let depositPriceUSD: string
  let amountYTokensDepositedWei: string
  let nodeEvents: NodeExecutionResult[]
  const recipeLogs = previousData.recipeLogs

  const networkId: string = useSelector((s: any) => (s.network.network))
  const networkParams = getNetworkParams(networkId)

  if (recipeLogs !== null) {
    nodeEvents = getEventsForNode(recipeLogs, previousData.id)
    executionSteps = nodeEvents.length
    const swapDepositEvent: NodeExecutionResult = nodeEvents.find((event) => event.functionName === 'swapTokens')
    depositPriceUSD = swapDepositEvent?.extraData?.outputTokenWithPrice?.totalValueUSD === "0.000000000"
      ? swapDepositEvent?.extraData?.inputTokenWithPrice?.totalValueUSD
      : swapDepositEvent?.extraData?.outputTokenWithPrice?.totalValueUSD
    depositAmountWei = swapDepositEvent?.output?.amount
    const depositEvent: NodeExecutionResult = nodeEvents.find((event) => event.functionName === "depositOnNestedStrategy")
    amountYTokensDepositedWei = depositEvent?.output?.amount.toString()
  }
  return (
    <main id="main-nested-strategies-modal-log">
      <div className="farm-chosen-div">
        <div className="div-tokens">
          <span className="div-token-images">
            <img className="logo img-token-farm" src={getNestedStrategyImage(oldVaultData.symbol)} />
          </span>
          <span className="span-token-farm">{oldVaultData.name}</span>
        </div>
        <div className="div-link-ftmscan" >
          <a className="link-ftmscan"
            href={`${networkParams.blockExplorerUrls}/address/${oldVaultData.address}`}
            target="_blank"
            rel="noreferrer noopener">
            <FormattedMessage id="farm-modal.view-contract" /> <strong>ftmscan.com</strong></a>
        </div>
      </div>
      <section className="nested-strategies-modal-log-data">
        <DepositedYearnNestedModalLog oldVaultData={oldVaultData} depositAmount={depositAmountWei} depositPriceUSD={depositPriceUSD} amountYTokensDepositedWei={amountYTokensDepositedWei} />
        {executionSteps === nodesLogsNumber.ACTIVE_NODE ?
          <>
            <Suspense fallback={<Loading />} >
              <EarnedCurrentYearnNestedModalLog oldVaultData={oldVaultData} depositAmountWei={depositAmountWei} depositPriceUSD={depositPriceUSD} amountYTokensDepositedWei={amountYTokensDepositedWei} />
            </Suspense>
          </>
          :
          <>
            <Suspense fallback={<Loading />} >
              <EarnedFinalYearnNestedModalLog nodeEvents={nodeEvents} depositAmountWei={depositAmountWei} depositPriceUSD={depositPriceUSD} />
            </Suspense>
          </>
        }
        <ApyYearnNestedModalLog oldVaultData={oldVaultData} />
        <div id="nested-strategy-data-2">
          <h3><FormattedMessage id={"farm-modal-log.liquidity"} /></h3>
          <strong className="span-coin">{`$ ${oldVaultData?.tvl || 'Loading...'}`}</strong>
        </div>
      </section>
    </main>
  )
}
