import { BigNumber } from "ethers"
import { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { tokenNameToAddress } from "src/components/Diagram/nodes/nodesLogsHelper"
import { getPriceInUSDCFantomNetwork } from "src/contracts/GetPriceHelpers"
import { getEtherNumberAmountFromAddress } from "src/contracts/TokensDecimalsHelpers"
import { getAddFundsWarning, getComboWarning, getFarmNestedWarningInBranch, getMaxSameFarmWarning, getSplitNodeWarning } from "src/routes/RecipeDiagram/helpers/validationWarningHelper"
import { ConditionProvider, DiagramElement, BaseNodeData, NodeType, NodeElement } from "src/routes/RecipeDiagram/helpers/types"
import { validateDiagram } from "src/store/actions/validation"
import { RecipeDetails } from "src/types"
import { Networks } from "src/utils/networkHelper"
import { getTokenUSDPrice } from "./usePrices"
import { useRecipeDetailsLive } from "src/context/SocketContext"

export interface ValidationError {
  node: DiagramElement
  errorType: errorType
}

type errorType = 'motivationUndefined' | 'comboTriggerUndefined' | 'insufficientLiquidityLP' | 'missingAddFunds' | 'insufficientLiquidityAddFunds' | 'missingInput' | 'missingOutput' | 'oldExecutionTime' | 'insufficientAmount' | 'insufficientAmount.input-perpetual'
export interface ValidationWarning {
  nodeType: NodeType
  warningType: warningType
}

export type warningType = 'yellow' | 'red'

export const useDiagramValidator = (nodes: NodeElement[], edges, recipeDetails: RecipeDetails = null, recipeDetailsLive: RecipeDetails = null, networkId: string): [ValidationError[], ValidationWarning[]] => {
  const [validationErrors, setValidationErrors] = useState<ValidationError[]>([])
  const [validationWarnings, setValidationsWarnings] = useState<ValidationWarning[]>([])
  const recipeDetailsToUse: RecipeDetails = recipeDetailsLive ?? recipeDetails
  useEffect(() => {
    let updatedErrors: Array<Promise<ValidationError[]>> = []
    for (const node of nodes) {
      const elementErrors: Promise<ValidationError[]> = validateDiagramElement(node, networkId, recipeDetails)
      updatedErrors = updatedErrors.concat(elementErrors)
    }
    Promise.all(updatedErrors)
      .then((errors) => {
        setValidationErrors(errors.reduce((acc, val) => acc.concat(val), []))
      })
      .catch((e) => console.error('Error in validation: ', e))
    setValidationsWarnings(validateNumWarnings(nodes, edges, networkId))
  }, [nodes, edges, recipeDetails, recipeDetailsLive, recipeDetailsToUse, networkId])
  return [validationErrors, validationWarnings]
}

export const useNodeValidator = (id: string, data: BaseNodeData, type: NodeType, recipeDetails: RecipeDetails = null, networkId: string): boolean => {
  const [validationErrors, setValidationErrors] = useState<ValidationError[]>([])
  const recipeDetailsLive = useRecipeDetailsLive((recipeDetails?.id.toString()))
  const recipeDetailsToUse: RecipeDetails = recipeDetailsLive ?? recipeDetails
  const dispatch = useDispatch()
  useEffect(() => {
    const dataAsNode = { data, position: { x: 0, y: 0 }, id, type }
    validateDiagramElement(dataAsNode as NodeElement, networkId, recipeDetailsToUse)
      .then((updatedErrors) => {
        if (updatedErrors.length > 0) {
          dispatch(validateDiagram(false))
        }
        setValidationErrors(updatedErrors)
      })
      .catch((e) => console.error('Error in useNodeValidator: ', e))
  }, [data, type, recipeDetails, recipeDetailsLive, recipeDetailsToUse, dispatch, id, networkId])

  return validationErrors.length <= 0
}

export const validateDiagramElement = async (nodeToValidate: NodeElement, networkId: string, recipeDetails: RecipeDetails = null): Promise<ValidationError[]> => {
  const REFERENCE_TIMESTAMP: number = 1676035800 // Friday 10 February 2023 at 13:00:00
  const MINIMUN_USD_VALUE_MUMMY: number = 10
  const validationErrors: ValidationError[] = []

  if (!nodeToValidate.data.nextConnected) {
    validationErrors.push({ node: nodeToValidate, errorType: 'missingOutput' })
  }
  if (nodeToValidate.type !== 'addFundsNode' && !nodeToValidate.data.inputCoin) {
    validationErrors.push({ node: nodeToValidate, errorType: 'missingInput' })
  }
  if (nodeToValidate.type === "addFundsNode" && recipeDetails) {
    const nodes = recipeDetails?.code?.filter(node => node?.type !== "myCustomEdge")
    const edges = recipeDetails?.code?.filter(node => node.type === "myCustomEdge")
    const addFundsNode = nodes.find(node => node?.type === "addFundsNode")
    const nextEdge = edges.find(edge => edge?.source === addFundsNode.id)
    const nextNodeType = nodes.find(node => node?.id === nextEdge?.target)?.type
    if (nextNodeType === "shortLongNode") {
      let tokenInUSDC: string
      if (networkId === Networks.arbitrum) tokenInUSDC = await getTokenUSDPrice(nodeToValidate.data?.outputCoin, networkId, undefined)
      else if (networkId === Networks.fantom || networkId === Networks.hardhat) tokenInUSDC = await getPriceInUSDCFantomNetwork(nodeToValidate?.data?.outputCoin, networkId)
      const amountInEther = await getEtherNumberAmountFromAddress(tokenNameToAddress(nodeToValidate?.data?.outputCoin, networkId), nodeToValidate?.data?.amount)
      const amountInUSDC = Number(tokenInUSDC) * Number(amountInEther)
      if (!(Number(amountInUSDC) >= MINIMUN_USD_VALUE_MUMMY)) {
        validationErrors.push({ node: nodeToValidate, errorType: 'insufficientAmount.input-perpetual' })
      }
    }
  }
  switch (nodeToValidate?.type) {
    case 'addFundsNode': {
      const amountBN = BigNumber.from(nodeToValidate.data.amount.toString())
      if (amountBN.isZero() || amountBN.isNegative()) {
        validationErrors.push({ node: nodeToValidate, errorType: "insufficientLiquidityAddFunds" })
      }
      break
    }
    case 'comboTriggerNode': {
      const recipeDate: number = (Date.parse(recipeDetails?.date)) / 1000
      const isMotivationOptional: boolean = (!recipeDetails || !recipeDetails?.public)
      const { conditionProvider, motivation } = nodeToValidate.data
      if (!conditionProvider) {
        validationErrors.push({ node: nodeToValidate, errorType: 'comboTriggerUndefined' })
      }
      if (!isMotivationOptional && !motivation && recipeDate > REFERENCE_TIMESTAMP) {
        validationErrors.push({ node: nodeToValidate, errorType: 'motivationUndefined' })
      }
      if (nodeToValidate.data.conditionProvider === ConditionProvider.chainlink) {
        const { condition } = nodeToValidate.data
        if (!condition || (!conditionProvider && !condition.coinToCompare)) {
          validationErrors.push({ node: nodeToValidate, errorType: 'comboTriggerUndefined' })
        }
        if (condition && condition.value <= 0) {
          validationErrors.push({ node: nodeToValidate, errorType: 'comboTriggerUndefined' })
        }
      } else if (nodeToValidate.data.conditionProvider === ConditionProvider.spookyswap) {
        const { condition } = nodeToValidate.data
        if (!condition || (!conditionProvider && !condition.farmID)) {
          validationErrors.push({ node: nodeToValidate, errorType: 'comboTriggerUndefined' })
        }
        if (condition && condition.value <= 0) {
          validationErrors.push({ node: nodeToValidate, errorType: 'comboTriggerUndefined' })
        }
      } else if (nodeToValidate.data.conditionProvider === ConditionProvider.time) {
        const { condition } = nodeToValidate.data
        const currentUTCTime: number = Date.now()
        if (!condition || (!conditionProvider && !condition.timeComparison)) {
          validationErrors.push({ node: nodeToValidate, errorType: 'comboTriggerUndefined' })
        }
        if (condition && condition.value <= 0) {
          validationErrors.push({ node: nodeToValidate, errorType: 'comboTriggerUndefined' })
        }
        if ((recipeDetails?.status === "draft" || recipeDetails?.status === "ready") && condition && condition?.timeComparison === "exact" && condition?.value <= currentUTCTime) {
          validationErrors.push({ node: nodeToValidate, errorType: 'oldExecutionTime' })
        }
      }

      break
    }
    case 'depositOnLPNode':
      break
    case 'farmNode':
      break
    case 'nestedStrategiesNode':
      break
    case 'shortLongNode':
      break
    case 'splitNode':
      if (!nodeToValidate.data.secondNextConnected) {
        validationErrors.push({ node: nodeToValidate, errorType: 'missingOutput' })
      }
      break;
    default:
      break;
  }
  return validationErrors
}

export const validateNumWarnings = (nodes, edges, networkId: string): ValidationWarning[] => {
  const warnings: ValidationWarning[] = []
  const maxSameFarmWarning: any = getMaxSameFarmWarning(nodes)
  const farmNestedWarning: ValidationWarning = getFarmNestedWarningInBranch(nodes, edges, networkId)
  const addFundsWarning: ValidationWarning = getAddFundsWarning(nodes)
  const splitWarning: ValidationWarning = getSplitNodeWarning(nodes, networkId)
  const comboWarning: ValidationWarning = getComboWarning(nodes)
  warnings.push(maxSameFarmWarning)
  warnings.push(farmNestedWarning)
  warnings.push(addFundsWarning)
  warnings.push(splitWarning)
  warnings.push(comboWarning)

  return warnings
}
