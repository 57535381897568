import React from 'react'
interface DepositedFarmLeftLogProps {
  lpDeposited: number
  lpUSDValueAtDeposit: number
}
export const DepositedFarmLeftLog = ({ lpDeposited, lpUSDValueAtDeposit }: DepositedFarmLeftLogProps) => {
  return (
    <>
      <p className="main-text-logs">$ {(lpUSDValueAtDeposit).toFixed(3)}</p>
      <p>{lpDeposited.toFixed(12)} LPs</p>
    </>
  )
}

export const LoadingDepositFarmLeftLog = () => {
  return (
    <>
      <div className="logs-group-rows">
        <p>Loading deposited</p>
        <div className="left-empty-position"></div>
      </div>
    </>
  )
}
