import { Interface } from 'ethers/lib/utils'
import MulticallAbi from 'src/contracts/abis/multicall.json'
import web3 from "src/utils/web3"
import { AbiItem } from 'web3-utils'
import { multicallAddress } from 'src/constants'

export interface Call {
  address: string
  name: string
  params?: any[]
}
export interface MulticallOptions {
  requireSuccess?: boolean
}

export const multicallv2 = async <T = any>(chainId: string, abi: any[], calls: Call[], options: MulticallOptions = { requireSuccess: true }): Promise<T> => {
  const { requireSuccess } = options
  const multi = new web3.eth.Contract(MulticallAbi as AbiItem[], multicallAddress[chainId])
  const itf = new Interface(abi)
  const calldata = calls.map((call) => ({
    target: call.address.toLowerCase(),
    callData: itf.encodeFunctionData(call.name, call.params),
  }))
  const returnData = await multi.methods.tryAggregate(requireSuccess, calldata).call()
  return returnData.map((call, i) => {
    const [result, data] = call
    return result ? itf.decodeFunctionResult(calls[i].name, data) : null
  })
}
