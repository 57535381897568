
import { TemporaryExecutionStatus } from "src/types"

const initialState = {
  recipesState: new Map<number, TemporaryExecutionStatus>()
}

export default (state = initialState, action) => {
  switch (action.type) {
    case "socket-event":
      if (action.eventType === "execution-blocker") {
        if (action.data === "Failed" || action.data === "FinishedOperation") {
          state.recipesState.delete(Number(action.target))
          return state
        }
        return { ...state, recipesState: state.recipesState.set(Number(action.target), action.data) }
      }
      return state
    case "useFetch/success":
      if (action.key === `recipe/${action.value.id}` && action.value?.temporaryStatus) {
        return { ...state, recipesState: state.recipesState.set(action.value.id, action.value.temporaryStatus) }
      } else if (action.key === `recipe/${action.value.id}` && !action.value?.temporaryStatus) {
        if (state.recipesState.has(action.value.id)) {
          state.recipesState.delete(action.value.id)
          return state
        }
      }
      return state
    case "updateRecipe":
      return { ...state, recipesState: state.recipesState.set(action.payload.recipeId, action.payload.operation) }
    case "deleteRecipe":
      state.recipesState.delete(action.payload.recipeId)
      return state
    default:
      return state
  }
}
