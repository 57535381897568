import React from "react"
import { CloseModal } from "@gluedigital/modal"
import { FormattedMessage } from "react-intl"
import NodeStateAndDate from "../NodeStateAndDate/NodeStateAndDate"
import { RecipeDetails } from "src/types"
import { getTokenImage } from "src/api/farms/helpers"
import { TokensToolTip } from "../../nodeModals/DepositOnLPModal/TokensToolTip"
import { useSelector } from "react-redux"
import { getNetworkParams } from "../../SelectWalletModal/helpers"
import { Networks } from "src/utils/networkHelper"
import { DepositChosenPair } from "src/routes/RecipeDiagram/helpers/types"

interface DepositModalLogHeaderProps {
  isExecutionStarted: boolean
  showingDate: string
  recipeDetails: RecipeDetails
  executionSteps: number
}

export const DepositModalLogHeader = ({ isExecutionStarted, showingDate, recipeDetails, executionSteps }: DepositModalLogHeaderProps) => {
  return (
    <header className="header-buttons">
      <CloseModal>
        <button>
          <span className="icon icon-close" />
          <span><FormattedMessage id="close" /></span>
        </button>
      </CloseModal>
      <h1><FormattedMessage id="deposit-lp-modal.title" /></h1>
      <NodeStateAndDate isNodeExecuted={isExecutionStarted} showingDate={showingDate} recipeDetails={recipeDetails} numberOfLogs={executionSteps} />
    </header>

  )
}

export const DepositModalLogsPoolSelected = ({ pair }: { pair: DepositChosenPair }) => {
  const networkId: string = useSelector((s: any) => (s.network.network))
  const networkParams = getNetworkParams(networkId)
  const provider: string = pair.provider
  return (
    <div className="deposit-chosen-div">
      {pair?.tokens.length === 2 && (provider === "SpookySwap" || provider === "SushiSwap") &&
        <div className="div-tokens">
          <span className="div-token-images">
            <img className="logo img-token-deposit" src={getTokenImage(pair?.tokens[0].symbol)} />
            <img className="logo img-token-deposit" src={getTokenImage(pair?.tokens[1].symbol)} />
          </span>
          <span className="span-token-deposit">{pair?.tokens[0].symbol} - {pair?.tokens[1].symbol}</span>
        </div>}

      {pair?.tokens.length === 2 && (provider !== "SpookySwap" && provider !== "SushiSwap") &&
        <div className="div-tokens">
          <span className="div-token-images">
            <TokensToolTip tokens={[pair.tokens[0]]} />
            <TokensToolTip tokens={[pair.tokens[1]]} />
          </span>
          <span className="span-token-deposit">{pair?.tokens[0].symbol} - {pair?.tokens[1].symbol}</span>
        </div>}

      {pair?.tokens.length > 2 &&
        <div className="div-tokens">
          <span className="div-token-images">
            <img className="logo" src={getTokenImage(pair.tokens[0].symbol)} />
            <TokensToolTip tokens={pair.tokens} />
          </span>
          <span className="span-token-deposit">{pair?.name}</span>
        </div>}
      <div className="div-link-ftmscan" >
        <a className="link-ftmscan"
          href={`${networkParams.blockExplorerUrls}/address/${pair?.address}`}
          target="_blank"
          rel="noreferrer noopener">
          <FormattedMessage id="farm-modal.view-contract" />
          <strong> {Networks.fantom === networkId ? 'ftmscan.com' : 'arbiscan.io'}</strong>
        </a>
      </div>
    </div>
  )
}

export const DepositModalLogProvider = ({ pair }: { pair: DepositChosenPair }) => {
  // By default we use SpookySwap and BeethovenX as main providers to avoid make too much changes in API
  const networkId: string = useSelector((s: any) => (s.network.network))
  let provider: string
  if (Networks.fantom === networkId) provider = pair.provider
  else if (Networks.arbitrum === networkId) {
    if (pair.provider === "BeethovenX") provider = "Balancer"
    else if (pair.provider === "SpookySwap") provider = "SushiSwap"
    else provider = pair.provider
  }
  return (
    <div className="div-provider">
      <span className="span-title-provider"><FormattedMessage id="farm-modal-log.provider" /> </span>
      <span className="span-provider">{provider}</span>
    </div>)
}
