import React from 'react'
import { calculateFeeApr } from 'src/api/farms/aprHelpers'
import { ModalErrorBoundary } from 'src/components/ErrorBoundary/ErrorBoundary'
import { usePairDayDataByBlockNumber } from 'src/hooks/usePairInfoFromGraph'
import { RecipeDetails } from 'src/types'
import { InfoDepositModalLog } from './InfoDepositModalLog'
import { useSelector } from 'react-redux'
import { Networks } from 'src/utils/networkHelper'

interface SpookyDepositModalLogProps {
  logEvents: any
  myPoolId: string
  blockNumberAtDeposit: number
  blockNumberAtOtherMoment: number
  graphLastBlock: number
  executionSteps: number
  recipeDetails: RecipeDetails
}
export const SpookyDepositModalLog = ({ blockNumberAtDeposit, myPoolId, logEvents, blockNumberAtOtherMoment, graphLastBlock, executionSteps, recipeDetails }: SpookyDepositModalLogProps) => {
  const networkId: string = useSelector((s: any) => (s.network.network))
  const blockToUse: number = blockNumberAtOtherMoment > graphLastBlock ? graphLastBlock : blockNumberAtOtherMoment
  const pairInfo: any = usePairDayDataByBlockNumber(myPoolId.split('-')[0], blockToUse)
  const myPoolData = pairInfo.data.pairDayDatas[0]
  const splitDecimals: string[] = myPoolData.reserveUSD.split('.')
  const liquidity: string = `${splitDecimals[0]}.${splitDecimals[1].slice(0, 2)}`
  const dailyVolume = Networks.fantom === networkId ? myPoolData.dailyVolumeUSD : myPoolData.volumeUSD
  const feeApr: number = calculateFeeApr(dailyVolume, liquidity, networkId)
  return (
    <div>
      <ModalErrorBoundary>
        <InfoDepositModalLog
          logEvents={logEvents}
          myPoolId={myPoolId}
          blockNumberAtDeposit={blockNumberAtDeposit}
          blockNumberAtOtherMoment={blockNumberAtOtherMoment}
          graphLastBlock={graphLastBlock}
          executionSteps={executionSteps}
          recipeDetails={recipeDetails}
          poolAPR={feeApr.toFixed(2)}
          poolLiquidity={liquidity}
        />
      </ModalErrorBoundary>
    </div>
  )
}
