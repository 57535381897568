import React, { Suspense } from "react"
import { FormattedMessage } from "react-intl"
import { useHistory } from "react-router-dom"
import { RecipeDetails } from 'src/types'
import { useRecipeCRUD, useMyRecipesRefresh } from "src/api/recipes"
import { ROISideViewDescription } from "src/components/DnDFlow/APRSideViewDescription"
import ErrorBoundary from "src/components/ErrorBoundary/ErrorBoundary"
import Spinner from "src/components/common/Loading/Spinner/Spinner"
import { APRSideViewDescriptionFallback } from "src/components/DnDFlow/APRSideViewDescriptionFallback"

import "./SidebarViewDescription.sass"

interface SidebarViewDescriptionProps {
  recipeDetails: RecipeDetails
  userAddress: string
}

const SidebarViewDescription = ({ recipeDetails, userAddress }: SidebarViewDescriptionProps) => {
  const extendedDescription: string = recipeDetails?.extended
  const shortDescription: string = recipeDetails?.short
  const title: string = recipeDetails.title
  const owner: string = recipeDetails.owner
  const reducedOwner: string = "0x" + owner.slice(0, 9) + "..." + owner.slice(-3)
  const extendedByLines = extendedDescription.split('\n')

  const recipeCRUD = useRecipeCRUD()
  const history = useHistory()
  const refresh = useMyRecipesRefresh()

  const cloneRecipeHandler = e => {
    e.stopPropagation()
    recipeCRUD.fork(recipeDetails.id)
      .then(() => {
        history.push('/dashboard/your')
        refresh().catch(err => console.error(err.message))
      })
      .catch(err => console.error(err.message))
  }

  return (
    <aside className="diagram-sidebar">
      <div className="diagram-sidebar-viewDescription">
        <h2><FormattedMessage id="view-logs-recipe-details" /> </h2>
        <section id="title-apr-short">
          <h3>{title}</h3>
          <span className="span-by"><FormattedMessage id="view-description-by" /></span>
          <span className="span-owner">{reducedOwner}</span>
          <ErrorBoundary fallback={<APRSideViewDescriptionFallback />}>
            <Suspense fallback={<div style={{ marginTop: '20px' }}><Spinner /></div>}>
              <ROISideViewDescription recipeDetails={recipeDetails} />
            </Suspense>
          </ErrorBoundary>
          <div className="short">
            <span> {shortDescription} </span>
          </div>
        </section>
        <section className="div-extended">
          {extendedByLines.map((line, index) =>
            <p key={index}>
              <span className={`span-opacity-${index}`}>{line}</span>
            </p>
          )}
        </section>
        {userAddress && <button className="button-clone" onClick={cloneRecipeHandler}>
          <FormattedMessage id="recipe-item.clone-recipe" />
        </button>}
      </div>
    </aside>
  )
}

export default SidebarViewDescription
