import React, { useState, useEffect } from "react"
import RecipeList from "src/components/RecipeList/RecipeList"
import { usePaginatedPublic } from "src/api/recipes"
import { SortByEnum } from "src/routes/Mobile/components/Explore/MobileBrowse/types";

interface BrowseListProps { searchTerm: string, showOnlyVerified?: boolean, sortBy?: SortByEnum }

const BrowseList = ({ searchTerm, showOnlyVerified = false, sortBy }: BrowseListProps) => {
  const paginatedRecipes = usePaginatedPublic(searchTerm, showOnlyVerified, sortBy)
  const [recipes, setRecipes] = useState(paginatedRecipes.data)
  const infiniteScrollCb = () => {
    if (!paginatedRecipes.isLoadingMore) {
      paginatedRecipes.loadMore()
        .then((res) => setRecipes(res))
        .catch(err => err.message)
    }
  }
  useEffect(() => {
    setRecipes(paginatedRecipes.data)
  }, [paginatedRecipes])

  return (
    <RecipeList recipes={recipes} type={"public"} loadMoreCb={infiniteScrollCb} showLoadMoreButton={paginatedRecipes.hasNext} />
  );
};

export default BrowseList
