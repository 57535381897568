
import web3 from "src/utils/web3"
import { BN, currentAPR } from "src/api/farms/farmHelpers"
import { ethers } from "ethers"
import { EventData } from 'web3-eth-contract/types/index'
import batchAbi from 'src/contracts/abis/batch.json'
import ttVaultAbi from 'src/contracts/abis/TortleVault.json'
import strategyAbi from 'src/contracts/abis/TortleStrategy.json'
import strategyV3Abi from 'src/contracts/abis/TortleStrategyV3.json'
import strategyV2Arbitrum from 'src/contracts/abis/arbitrum/TortleSushiStrategy.json'
import { FarmData, PairDayData } from "src/api/farms/types"
import usePromise from "./usePromise"
import pairsAPIFantom from "src/api/farms/pairsApiFantom"
import { saneBigNumber } from "src/utils/bigNumberHelper"
import { filterPoolsForSpooky } from "src/components/modals/nodeModals/DepositOnLPModal/depositModalHelper"
import { getNetworkParams } from "src/components/modals/SelectWalletModal/helpers"
import { useSelector } from "react-redux"
import { getPairsAPI } from "src/api/farms/helpers"
import { Networks } from "src/utils/networkHelper"
import { useRecipeLogs } from "src/api/recipes"
import { NodeExecutionResult, RecipeExecutionLog } from "src/types"

export const useFarmPairs = (blockNumber = 0, pairId = null) => {
  const data = usePromise(pairsAPIFantom.getFarmPairs, blockNumber, pairId)
  return data
}

const getPoolsPairs = async (networkId: string) => {
  const pairsAPI = getPairsAPI(networkId)
  return await pairsAPI.getPoolPairs({})
}

export const useGetPoolsPairs = (networkId: string): PairDayData[] => {
  const result = usePromise(getPoolsPairs, networkId)
  const poolsFiltered = filterPoolsForSpooky(result.pools.data)
  return poolsFiltered
}

const lpEarned = async (id: string, date: Date, executionSteps: number, networkId: string): Promise<FarmData> => {
  if (executionSteps === 0) return undefined
  const data = { id, date }
  const ACTIVE_NODE: number = 2
  const FINISHED_NODE: number = 4
  const ABORTED_NODE: number = 5
  const options = {
    fromBlock: 0,
    toBlock: 'latest',
  }
  const networkParams = getNetworkParams(networkId)
  const batch = new web3.eth.Contract(batchAbi as any, networkParams.contracts.batch)
  const eventsTtDeposited: EventData[] = await batch.getPastEvents('ttDeposited', options)
  const event = (eventsTtDeposited.filter((event) => {
    return (event.returnValues.id === web3.utils.keccak256(data.id))
  }))[0]
  const vaultAddr: string = event?.returnValues?.ttVault
  const ttShares: string = event?.returnValues?.amount
  const lpDeposited = BN(event?.returnValues?.lpAmount)
  if (executionSteps === FINISHED_NODE || executionSteps === ABORTED_NODE) {
    const eventsTtWithdrawed: EventData[] = await batch.getPastEvents('ttWithdrawed', options)
    const eventWithdraw = (eventsTtWithdrawed.filter((e) => {
      return e.returnValues.id === web3.utils.keccak256(data.id)
    }))[0]
    const lpWithdrawed = BN(eventWithdraw?.returnValues?.lpAmount)
    const _earned = ethers.utils.formatUnits(lpWithdrawed.sub(lpDeposited), 'ether')
    const earned = `${(+_earned).toFixed(12)} lp`
    const lpDepositedEth = ethers.utils.formatUnits(lpDeposited, 'ether')
    const currentApr = currentAPR(+lpDepositedEth, +_earned, data.date).toFixed(2)
    return {
      state: 'finished',
      lpDeposited: `${(+lpDepositedEth).toFixed(12)} lp`,
      earned,
      currentApr
    }
  }
  if (executionSteps === ACTIVE_NODE) {
    const vault = new web3.eth.Contract(ttVaultAbi as any, vaultAddr)
    const ttPrice = await vault.methods.getPricePerFullShare().call()
    const lpNow = BN(ttPrice).mul(BN(ttShares)).div(BN(10 ** 18))
    const _earned = ethers.utils.formatUnits(lpNow.sub(lpDeposited), 'ether')
    const earned = `${(+_earned).toFixed(12)}`
    // if (lpNow.sub(lpDeposited).lte(BN(0))) earned = 'Not enough data'
    const lpDepositedEth = ethers.utils.formatUnits(lpDeposited, 'ether')
    const currentApr = currentAPR(+lpDepositedEth, +_earned, data.date).toFixed(2)
    return {
      state: 'running',
      lpDeposited: `${(+lpDepositedEth).toFixed(12)} lp`,
      earned,
      currentApr
    }
  }
}

export const useLpEarned = (id: string, date: Date, executionSteps: number): FarmData => {
  const networkId: string = useSelector((s: any) => s.network.network)
  const farmData = usePromise(lpEarned, id, date, executionSteps, networkId)
  return farmData
}

const getLastDepositEvent = async (nodeId: string, networkId: string): Promise<EventData> => {
  const LIMIT_BLOCK = 47500000
  const filter = {
    fromBlock: LIMIT_BLOCK,
    toBlock: 'latest',
  }
  const networkParams = getNetworkParams(networkId)
  const batch = new web3.eth.Contract(batchAbi as any, networkParams.contracts.batch)
  console.log(batch)
  const eventsTtDeposited: EventData[] = await batch.getPastEvents('ttDeposited', filter)
  console.log(eventsTtDeposited)
  const event = (eventsTtDeposited.filter((event) => {
    return (event.returnValues.id === web3.utils.keccak256(nodeId))
  }))[0]

  return event
}

export const useLastDepositEvent = (nodeId: string): EventData => {
  const networkId: string = useSelector((s: any) => s.network.network)
  const lastEvent: EventData = usePromise(getLastDepositEvent, nodeId, networkId)
  return lastEvent
}

const getFarmRewardsFantomFromStrategy = async (vaultStrategy: string, shares: number, version: number): Promise<number[]> => {
  if (version === 2) {
    const strategy = new web3.eth.Contract(strategyAbi as any, vaultStrategy)
    const booAmount: number = await strategy.methods.getBooPerFarmNode(shares).call()
    return [booAmount]
  }
  const strategy = new web3.eth.Contract(strategyV3Abi as any, vaultStrategy)
  const booAmount: number = await strategy.methods.getRewardPerFarmNode(shares).call()
  return [booAmount]
}

const getFarmRewardsArbitrumFromStrategy = async (vaultStrategy: string, shares: number): Promise<number[]> => {
    const strategy = new web3.eth.Contract(strategyV2Arbitrum as any, vaultStrategy)
    const rewardsAmount: number[] = await strategy.methods.getRewardsPerFarmNode(shares).call()
    return rewardsAmount
}

export const useFarmRewardsAmountFromStrategy = (nodeId: string, version: number = 2): number[] => {
  const networkId: string = useSelector((s: any) => s.network.network)
  const networkParams = getNetworkParams(networkId)
  let vaultsData
  if (networkId === Networks.arbitrum) vaultsData = networkParams.contracts.vaults
  else if (networkId === Networks.fantom || networkId === Networks.hardhat) vaultsData = version === 2 ? networkParams.contracts.vaults : networkParams.contracts.vaultsV3
  const recipeLogs: RecipeExecutionLog[] = useRecipeLogs(Number(nodeId.split('_')[0]))
  const events: NodeExecutionResult[] = []
  recipeLogs.forEach(log => log.events.forEach(ev => events.push(ev)))
  const depositEvent: NodeExecutionResult = events.find(ev => ev?.functionName === "depositOnFarm" && ev.nodeID === nodeId)
  const sharesAmount: number = depositEvent?.extraData?.ttAmount
  const vault = vaultsData.find(vault => (vault.address).toLowerCase() === (depositEvent.output.token).toLowerCase())
  const functionRewards = networkId === Networks.fantom || networkId === Networks.hardhat ? getFarmRewardsFantomFromStrategy : getFarmRewardsArbitrumFromStrategy
  const rewardAmount: number[] = usePromise(functionRewards, vault.strategy, sharesAmount, version)
  return rewardAmount
}

const getEndBooAmountFromWithdrawEvent = async (lpAddress: string, lpAddressesByIndex: string[], networkId: string, version: number): Promise<number> => {
  const LIMIT_BLOCK = 47500000
  const filter = {
    fromBlock: LIMIT_BLOCK,
    toBlock: 'latest',
  }
  const networkParams = getNetworkParams(networkId)
  const vaultsData = version === 2 ? networkParams.contracts.vaults : networkParams.contracts.vaultsV3
  const batch = new web3.eth.Contract(batchAbi as any, networkParams.contracts.batch)
  const eventsTtDeposited: EventData[] = await batch.getPastEvents('ttWithdrawed', filter)
  const eventsInDesiredFarm: EventData[] = []
  for (let i = 0; i < eventsTtDeposited.length; i++) {
    const vaultAddress: string = (eventsTtDeposited[i].returnValues.ttVault).toLowerCase()
    const vault = vaultsData.find((vault) => (vault.address).toLowerCase() === vaultAddress)
    if (vault !== undefined) {
      const vaultLPAddress: string = lpAddressesByIndex[Number(vault.poolId)]
      if (vaultLPAddress.toLowerCase() === lpAddress) eventsInDesiredFarm.push(eventsTtDeposited[i])
    }
  }
  if (eventsInDesiredFarm.length > 0) {
    const lastEvent: EventData = eventsInDesiredFarm[eventsInDesiredFarm.length - 1]
    const booAmount: number = lastEvent?.returnValues?.rewardAmount
    return booAmount
  }
  return null
}

export const useEndBooAmountFromWithdrawEvent = (lpAddress: string, lpAddressesByIndex: string[], version: number = 2): number => {
  const networkId: string = useSelector((s: any) => s.network.network)
  const booAmount = usePromise(getEndBooAmountFromWithdrawEvent, lpAddress, lpAddressesByIndex, networkId, version)
  return booAmount
}

const auxLPEarned = async (token0: string, token1: string, ttShares: string, lpDepositedInWei: string, executionSteps: number, networkId: string, strategyAddress?: string) => {
  if (executionSteps !== 2) return null
  const networkParams = getNetworkParams(networkId)
  const allVaultsV2 = networkParams.contracts.vaults
  const allVaultsV3 = networkParams.contracts.vaultsV3
  let selectedVault = allVaultsV2.find(v => (v.token0 === token0 && v.token1 === token1) || v.strategy.toLowerCase() === strategyAddress?.toLowerCase())
  if (!selectedVault) selectedVault = allVaultsV3.find(v => (v.token0 === token0 && v.token1 === token1) || v.strategy.toLowerCase() === strategyAddress?.toLowerCase())
  const vault = new web3.eth.Contract(ttVaultAbi as any, selectedVault.address.toLowerCase())
  const ttPrice = await vault.methods.getPricePerFullShare().call()
  const lpNowInWei = BN(ttPrice).mul(BN(ttShares)).div(BN(10 ** 18))
  const _earned = ethers.utils.formatUnits(lpNowInWei.sub(saneBigNumber(Number(lpDepositedInWei))), 'ether')
  const earnedInEther = `${(+_earned).toFixed(12)}`
  return earnedInEther
}

export const useAuxLPEarned = (token0: string, token1: string, ttShares: string, lpDeposited: string, executionSteps: number, networkId: string, strategyAddress?: string) => {
  const resultEarned = usePromise(auxLPEarned, token0, token1, ttShares, lpDeposited, executionSteps, networkId, strategyAddress)
  return resultEarned
}
