
import React, { useState } from "react"
import { CodeNodeRecipe } from "../../../helpers/getNodesOrderedMap"
import NodeFlowCard from "../NodeFlowCard/NodeFlowCard"

import "./ShowFlowNode.sass"

interface ShowNodeProps {
  node: CodeNodeRecipe
  childMap: WeakMap<CodeNodeRecipe, CodeNodeRecipe[]>
}

const ShowFlowNode = ({ node, childMap }: ShowNodeProps) => {
  const childrenNumber = childMap.get(node)?.length
  const accordion = childrenNumber > 1
  const [open, setOpen] = useState(true)
  const handleOpen = e => {
    e.stopPropagation();
    accordion && setOpen(!open)
  }
  return (
    <div className={`show-flow-node ${accordion ? 'accordion' : ''}`} onClick={e => handleOpen(e)}>
      <NodeFlowCard node={node} accordion={accordion && { open }} />
      {!!childrenNumber &&
        <div className={`children-content ${!open ? 'close' : 'open'}`}>
          {childMap.get(node)?.map(child => <ShowFlowNode key={child.id} node={child} childMap={childMap} />)}
        </div>
      }
    </div>
  )
}

export default ShowFlowNode
