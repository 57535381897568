import { FormattedMessage } from "react-intl"
import React from 'react'

export type NoExecutedDepositNodeType = "Liquidated" | "NoExecuted"
export const NoExecutedDepositModalLog = ({ text }: { text: NoExecutedDepositNodeType }) => {
  const messageId = text === "Liquidated" ? "view-log-node-liquidated" : "view-log-node-no-executed"
  return (
    <section className="deposit-modal-currency">
      <div id="first-slot">
        <h3><FormattedMessage id="farm-modal-log.deposited" /></h3>
        <strong className="span-coin"> {<FormattedMessage id={messageId} />} </strong>
      </div>
      <div id="second-slot">
        <h3><FormattedMessage id="farm-modal-log.current" /></h3>
        <strong className="span-coin"> {<FormattedMessage id={messageId} />} </strong>
      </div>
      <div id="third-slot">
        <h3><FormattedMessage id="farm-modal-log.apr" /></h3>
        <strong className="span-coin"> --- </strong>
      </div>

      <div id="fourth-slot">
        <h3><FormattedMessage id="farm-modal-log.liquidity" /></h3>
        <strong className="span-coin"> --- </strong>
      </div>
    </section>)
}
