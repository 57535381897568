import React, { useEffect, useState } from "react"
import { FormattedMessage } from "react-intl"
import { Recipe, RecipeStatus } from "src/types"
import { formatRecipeTitle } from "src/routes/routesHelper"
import RecipeCardV2 from "../RecipeCardV2/RecipeCardV2"
import { useHistory } from "react-router-dom"
import { useMyRecipesRefresh, useRecipeCRUD } from "src/api/recipes"
import { useModal } from "@gluedigital/modal"
import { useToast } from "src/hooks/useToast"
import verified from '../../static/images/verified.svg'
import { useSelector } from "react-redux"
import './RecipeItem.sass'

interface RecipeItemProps {
  recipe: Recipe
  type: string
  status: RecipeStatus
}

const RecipeItem = ({ recipe, type, status }: RecipeItemProps) => {
  const [showBack, setShowBack] = useState(false)
  const recipeCRUD = useRecipeCRUD()
  const history = useHistory()
  const refresh = useMyRecipesRefresh()
  const modal = useModal()
  const sendToast = useToast()
  const account = useSelector((s: any) => (s.user ? s.user.account : undefined))
  const userAddress: string = account ? account?.account.slice(2) : undefined
  const recipeOwner: string = recipe.owner.length === 42 ? recipe.owner.slice(2) : recipe.owner
  const handleFork = e => {
    e.stopPropagation()
    recipeCRUD.fork(recipe?.id)
      .then(async () => {
        history.push('/dashboard/your')
        await refresh()
        sendToast(recipe.title, 'fork')
      }).catch(err => console.error(err.message))
  }

  const handleRemove = e => {
    e.stopPropagation()
    const confirmHandler = async () => await recipeCRUD.delete(recipe.id)
      .then(async () => {
        await refresh('draft,ready')
        sendToast(recipe.title, 'delete')
        modal.hide('alert-modal')
      }).catch(err => console.error(err.message))
    const cancelHandler = () => modal.hide('alert-modal')
    modal.show('alert-modal', { bodyTextID: 'recipe-item.delete-confirm', confirmHandler, cancelHandler })
  }
  const [canAbort, setCanAbort] = useState<boolean>(false)

  useEffect(() => {
    if (status === 'failed') setCanAbort(true)
  }, [status])

  const handleAbort = async (e) => {
    e.stopPropagation()
    setCanAbort(false)
    await recipeCRUD.abort(recipe?.id).then(() => {
      sendToast(recipe.title, 'abort')
      setCanAbort(true)
    })
  }

  const handleRetry = async (e) => {
    e.stopPropagation()
    modal.show("retry-approval-modal", { recipeID: recipe.id })
  }

  const handleAPRDetails = (e) => {
    e.stopPropagation()
    history.push("/recipe/apr-details/" + recipe.id)
  }

  const viewRecipeHandler = () => {
    const adaptTitle: string = formatRecipeTitle(recipe.title)
    history.push("/recipe/" + recipe.id + '/' + adaptTitle)
  }

  return (
    <article
      className={`recipe-item ${showBack ? 'back' : 'front'}`}
      onClick={() => setShowBack(!showBack)}
    >
      <div className="content">
        <section className="recipe-item-front">
          <RecipeCardV2 type="strategy" key={recipe.id} recipe={recipe}>
            {/* <span className={`privacy ${recipe?.public ? 'public' : 'private'}`}>{recipe?.public ? 'Public' : 'Private'}</span> */}
            {recipe?.verified
              ?
              <div className="verified-box">
                <img src={verified} alt="Recipe is verified" className="verified-img" /> <span className="verified-text"><FormattedMessage id="recipe-item.verified" /></span>
              </div>
              : <></>}
          </RecipeCardV2>
        </section>
        <section className="recipe-item-back">
          {(type !== "public" && status !== 'active' && status !== 'failed') &&
            <button className="recipe-item-delete" onClick={handleRemove}>
              <FormattedMessage id="recipe-item.delete-recipe" />
            </button>}
          <div className="inner">
            <button onClick={viewRecipeHandler}>
              <FormattedMessage id="recipe-item.view-recipe" />
            </button>
            {status === 'failed' && userAddress === recipeOwner && <button onClick={handleRetry as (e: any) => void}>
              <FormattedMessage id="recipe-item.retry" />
            </button>}
            <button onClick={handleFork}>
              <FormattedMessage id="recipe-item.clone-recipe" />
            </button>
            {status === 'active' && <>
              {/* <button disabled={!canAbort} onClick={handleAbort as (e: any) => void}>
                <FormattedMessage id="recipe-item.abort" />
              </button> */}
              <button disabled={!canAbort} onClick={handleAPRDetails}>
                <FormattedMessage id="recipe-item.apr" />
              </button>
            </>}
            {status === 'failed' && <button disabled={!canAbort} onClick={handleAbort as (e: any) => void}>
              <FormattedMessage id="recipe-item.cancel" />
            </button>}
            {status === 'finished' &&
              <button
                onClick={handleAPRDetails}>
                <FormattedMessage id="recipe-item.apr" />
              </button>}
          </div>
        </section>
      </div>
    </article>
  )
}

export default RecipeItem
