import { TokenPrice, usePricesCombo } from "src/api/comboTrigger/comboApi"
import usePromise from "./usePromise"
import { useSelector } from "react-redux"
import { Networks } from "src/utils/networkHelper"
import fetchGraph from "src/api/farms/thegraph/fetchGraph"
import { getTokenPriceFromSushiGraph } from "src/api/farms/thegraph/pairsQueriesV2Sushi"
import { tokenAddressToName, tokenNameToAddress } from "src/components/Diagram/nodes/nodesLogsHelper"
import { masterchefTheGraphUriV3FromUniswap } from "src/constants"

export const getTokenUSDPrice = async (token: string, networkId: string, tokenInChainlink: TokenPrice): Promise<string> => {
  if (!token) return '0'
  if (tokenInChainlink) return tokenInChainlink?.price
  const tokenAddress: string = tokenNameToAddress(token, networkId)
  if (networkId === Networks.arbitrum) {
    const res = await fetchGraph(masterchefTheGraphUriV3FromUniswap[networkId], getTokenPriceFromSushiGraph(tokenAddress))
    const priceUSD: string = res?.data?.token?.tokenDayData[0]?.priceUSD
    return priceUSD
  }
}
export const usePrices = (token: string): string => {
  const networkId: string = useSelector((s: any) => s.network.network)
  const tokenSymbol: string = token?.length === 42 ? tokenAddressToName(token, networkId) : token
  const comboPrices: TokenPrice[] = usePricesCombo()
  const tokenInChainlink: TokenPrice = comboPrices?.find(t => t.token === formatWrappedTokenName(tokenSymbol))
  try {
    const priceUSD = usePromise(getTokenUSDPrice, tokenSymbol, networkId, tokenInChainlink)
    return priceUSD
  } catch (error) {
    if (error instanceof Promise) throw error
    return undefined
  }
}

const formatWrappedTokenName = (tokenSymbol: string): string => {
  const wrappedTokens = ["WETH", "WBTC"]
  if (wrappedTokens.includes(tokenSymbol)) return tokenSymbol.slice(1)
  else return tokenSymbol
}
