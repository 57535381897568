import { useCRUD as useCRUDInner, useFetch as useFetchInner, useFetchCb, useFetchUncachedCb, usePaginatedFetch, usePeriodicFetch as usePeriodicFetchInner, ExtendedRequestInit, CRUD } from '@gluedigital/ruse-fetch-extras'
import { useDispatch } from 'react-redux'
import { EstimateGasResponse } from 'src/components/RecipeSummary/EstimateGasLabel'
import { LPTokenWithPrice } from 'src/routes/RecipeDiagram/helpers/types'
import { Recipe, RecipeDetails, RecipeExecutionLog, RecipePatch, UserStats, RecipePerformance } from 'src/types'
import { FarmGraphData, SushiSwapFarmsData } from './farms/types'
import { YearnVaultsData } from './yearnVaults/yearnTypes'
import { SortByEnum } from 'src/routes/Mobile/components/Explore/MobileBrowse/types'
import useNetworkBaseUrl from 'src/hooks/useNetworkBaseUrl'
import { BalancerPoolsInfo } from './beets/balancerTypes'
import { camelotPoolsInfo } from './camelot/camelotTypes'

// --- Refactor hooks to get baseURL depending on networkId ---

const useFetch = <T,>(url: string, opts?: ExtendedRequestInit, key?: KeyType): T => {
  const base: string = useNetworkBaseUrl()
  return useFetchInner<T>(url && (base + url), opts, key)
}

const useCRUD = <Id, Entity,>(url: string): CRUD<Id, Entity> => useCRUDInner<Id, Entity>(useNetworkBaseUrl() + url)
const usePeriodicFetch = <T,>(period: number, url: string, opts?: ExtendedRequestInit, key?: KeyType): T => usePeriodicFetchInner<T>(period, useNetworkBaseUrl() + url, opts, key)

export const usePaginatedPublic = (query?: string, showOnlyVerified?: boolean, sortBy?: SortByEnum) => {
  const base: string = useNetworkBaseUrl()
  return usePaginatedFetch<Recipe[]>((base + '/recipes'), { params: { size: 24, query, showOnlyVerified: showOnlyVerified ? 1 : 0, sortBy } })
}

export const useStats = () => useFetch<UserStats>('/stats')
export const useRecipeDetails = (id: number) => useFetch<RecipeDetails>(id && ('/recipes/' + id), null, `recipe/${id}` as KeyType)
export const useRecipeLogs = (id: number) => useFetch<RecipeExecutionLog[]>(id && ('/recipes/' + id + '/logs'))
export const useRecipePerformance = (id: number) => useFetch<RecipePerformance>(id && ('/recipes/' + id + '/performance'))
export const useRecipeEstimation = (id: number) => useFetch<EstimateGasResponse>(id && ('/recipes/' + id + '/estimate'), { method: 'PUT', body: { id } })

export const useFeaturedTrendingRecipes = (ids: number[]) => useFetch<RecipeDetails[]>(ids && ids?.length > 0 && ('/recipes/featured-trending/' + ids))

// --- Routes to obtain all the Masterchefv2 and Masterchefv3 info ---
export const useAllFarmsIdFromApi = (version: number) => useFetch<string[]>(version && ('/masterchef/ids/' + version))
export const useAllFarmAllocPointsFromApi = (version: number) => useFetch<string[]>(version && ('/masterchef/farmsallocpoint/' + version))
export const useFarmsFiveDays = () => useFetch<FarmGraphData>('/farms/info/five')
export const useFarmsOneDay = () => useFetch<FarmGraphData & SushiSwapFarmsData[]>('/farms/info/single')
export const useLastSubgraphBlockFromApi = () => useFetch<number>('/farms/lastblock')
export const useGetLpPriceFromApi = (address: string, amount: string) => useFetch<LPTokenWithPrice>('/spooky/lpprice/' + address + '/' + amount)
export const useGetTokenPriceUSDFromApi = (address: string) => useFetch<string>('/spooky/tokenprice/' + address)
// --- Routes to obtain Yearn  info ---
export const useYearnVaultsFromApi = () => useFetch<YearnVaultsData[]>('/yearn/vaults')
export const useYearnTotalAssetsFromApi = () => useFetch<string[]>('/yearn/vaults/assets')

// Balancer pools
export const useBalancerPoolsFromApi = () => useFetch<BalancerPoolsInfo[]>('/pools/balancer')

// Camelot pools
export const useCamelotPoolsFromApi = () => useFetch<camelotPoolsInfo[]>('/pools/camelot')

// --- USE CRUD TO SAVE USERS ACCESS ---
export const useAccessCRUD = () => {
  const res = useCRUD('/access')
  return res
}
export const useRecipeCRUD = () => {
  const res = useCRUD<number, RecipePatch>('/recipes')
  const base = useNetworkBaseUrl()
  const dispatch = useDispatch()
  const reload = useFetchCb()
  return {
    ...res,
    fork: async (id: number) => await res.custom(`/${id}/fork`, { method: 'POST' }),
    abort: async (id: number) => await res.custom(`/${id}/abort`, { method: 'PUT', body: { id } })
      .then((res) => {
        dispatch({ type: 'useFetch/success', key: base + '/recipes/' + id, value: res })
        return res
      })
      .catch((err) => {
        reload(base + '/recipes/' + id).catch(err => console.error(err))
        throw new Error(err)
      }),
    execute: async (id: number) => await res.custom(`/${id}/execute`, { method: 'PUT', body: { id } })
      .then((res) => {
        dispatch({ type: 'useFetch/success', key: base + '/recipes/' + id, value: res })
        return res
      }),
    update: async (id: number, recipePatch: RecipePatch) => await res.update(id, recipePatch)
      .then((res) => {
        dispatch({ type: 'useFetch/success', key: base + '/recipes/' + id, value: res })
        return res
      }),

  }
}

// --- Utility methods ---

export const useFetchUpdater = () => {
  const dispatch = useDispatch()
  const fetch = useFetchUncachedCb()
  return async (url) => await fetch(url)
    .then(value => {
      dispatch({ type: 'useFetch/success', key: url, value })
      return value
    })
}

export const useMyRecipesRefresh = () => {
  const base = useNetworkBaseUrl()
  const update = useFetchUpdater()
  return async (status?: string) => await Promise.all([
    update(base + '/stats'),
    status && update(base + '/recipes/by-status/' + status)
  ])
}

export const useRecipePerformanceRefresh = () => {
  const base = useNetworkBaseUrl()
  const update = useFetchUpdater()
  return async (id: number) => await update(base + '/recipes/' + id + '/performance')
}
export const usePeriodicRecipes = (status: string, interval: number = 5000): Recipe[] =>
  usePeriodicFetch<Recipe[]>(interval, '/recipes/by-status/' + status)

export const usePeriodicStats = (interval: number = 5000): UserStats => usePeriodicFetch<UserStats>(interval, '/stats')

export const usePeriodicLiquidatedRecipes = (interval: number = 5000): Recipe[] =>
  usePeriodicFetch<Recipe[]>(interval, '/recipes/by-liquidationDescOrder/')
