import fetchGraph from "src/api/farms/thegraph/fetchGraph"
import { balancerSubgraph } from "src/constants"
import { FETCH_BALANCER_POOLS } from "src/api/beets/balancerQueries"
import { BalancerPoolsInfo } from "./balancerTypes"
import { getBeetsPoolsApr } from "./poolsHelpers"
import { BeetsPoolsInfo } from "./beetsTypes"

export const fetchAllBalancerPools = async (networkId: string) => {
    const result = await fetchGraph(balancerSubgraph[networkId], FETCH_BALANCER_POOLS())
    const data: BalancerPoolsInfo[] = result.data.poolGetPools
    const dataWithLiquidityFilter = data.filter((pool) => Number(pool.dynamicData.totalLiquidity24hAgo) >= 1000)
    const newData = dataWithLiquidityFilter.map((pool) => {
        const calculatedAPR: number = getBeetsPoolsApr(pool.dynamicData.volume24h, pool.dynamicData.swapFee, pool.dynamicData.totalLiquidity24hAgo)
        return { ...pool, calculatedAPR: calculatedAPR.toFixed(2), provider: "Balancer" }
    })
    const newPools = adaptBalancerInfo(newData)
    return newPools
}

export const adaptBalancerInfo = (poolsInfo: BalancerPoolsInfo[]) => {
    const newPools = poolsInfo.map(pool => {
        const newPool: BeetsPoolsInfo = {
            ...pool,
            dynamicData: {
                ...pool.dynamicData,
                apr: {
                    ...pool.dynamicData.apr,
                    swapApr: pool.dynamicData.apr.swapApr,
                    apr: {
                        ...pool.dynamicData.apr.apr,
                        total: pool.dynamicData.apr.apr.total
                    }
                }
            }
        }
        return newPool
    })

    return newPools
}
