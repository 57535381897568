import React, { Suspense } from "react"
import { useSelector } from "react-redux"
import { DepositChosenPair, DepositOnLPModalData } from "src/routes/RecipeDiagram/helpers/types"
import { LoadingDepositModalLog } from "./LoadingDepositModalLog"
import { registerModal } from "@gluedigital/modal"
import { generateShowingTime, getCurrentRowLog, getEndingLogForFarmsDeposit, getNumberOfLogsForDepositAndFarms } from "src/components/Diagram/nodes/nodesLogsHelper"
import { NodeExecutionResult, RecipeDetails, RecipeExecutionLog } from "src/types"
import { nodesLogsNumber } from 'src/constants'
import { useLastSyncGraphBlock } from "src/hooks/useGraph"
import { useDepositBlockNumberFromTxHash, useOtherMomentBlockNumberFromTxHash } from "src/hooks/useWeb3"
import { SpookyDepositModalLog } from "./SpookyDepositModalLog"
import { BeetsDepositModalLog } from "./Fantom/BeetsDepositModalLog"
import { NoExecutedDepositModalLog } from "./NoExecutedDepositModalLog"
import { DepositModalLogHeader, DepositModalLogProvider, DepositModalLogsPoolSelected } from "./DepositModalLogHelpers"
import "./DepositModalLog.sass"
import { Networks } from "src/utils/networkHelper"
import BalancerDepositModalLog from "./Arbitrum/BalancerDepositModalLog"
import Loading from "src/components/common/Loading/Loading"

export interface DepositLogModalProps {
  previousData: {
    data: DepositOnLPModalData
    id: string
    recipeLogs: RecipeExecutionLog[]
    recipeDetails: RecipeDetails
  }
}

const DepositModalLog = (props: DepositLogModalProps) => {
  const { previousData } = props
  const networkId: string = useSelector((s: any) => (s.network.network))
  const { recipeDetails, recipeLogs, id: nodeID } = previousData
  const pair: DepositChosenPair = previousData?.data?.pair
  console.log(pair)
  const provider: string = pair.provider
  const emptyNodeExecutionResult = []
  const { NO_EXECUTED_NODE, ACTIVE_NODE } = nodesLogsNumber
  let executionSteps: number
  let logEvents: NodeExecutionResult[] = emptyNodeExecutionResult
  let showingDate: string
  let depositRowLog: any
  let blockNumberAtDeposit: number = 0
  let blockNumberAtOtherMoment: number = 0

  const graphLastBlock: number = useLastSyncGraphBlock(networkId)

  if (recipeLogs !== null) {
    executionSteps = getNumberOfLogsForDepositAndFarms(recipeLogs, nodeID)
    recipeLogs.forEach((log) => {
      logEvents = logEvents.concat(log.events.filter((event) => event.nodeID === nodeID))
    })
    if (logEvents.length > 1) {
      depositRowLog = getCurrentRowLog(recipeLogs, nodeID)
      showingDate = generateShowingTime(depositRowLog)
    }
  }
  const endingLog = getEndingLogForFarmsDeposit(nodeID, recipeLogs)
  blockNumberAtDeposit = useDepositBlockNumberFromTxHash(depositRowLog?.trans)
  blockNumberAtOtherMoment = useOtherMomentBlockNumberFromTxHash(depositRowLog?.trans, endingLog?.trans, executionSteps)
  if (executionSteps === ACTIVE_NODE || executionSteps === NO_EXECUTED_NODE) blockNumberAtOtherMoment = graphLastBlock

  return (
    <div className="swap-modal modal-content">
      <div className="modal-wrapper">
        <DepositModalLogHeader isExecutionStarted={executionSteps > 0} showingDate={showingDate} recipeDetails={recipeDetails} executionSteps={executionSteps} />
        <div className="deposit-modal-log-wrapper">
          <div className="main-div">
            <DepositModalLogsPoolSelected pair={pair} />
            {logEvents.length === nodesLogsNumber.LIQUIDATED_NODE ? <NoExecutedDepositModalLog text="Liquidated" />
              : <>
                <Suspense fallback={<section className="farm-modal-currency"><Loading /></section>}>
                  {(provider === 'SpookySwap' || provider === 'SushiSwap') &&
                    <SpookyDepositModalLog
                      logEvents={logEvents}
                      myPoolId={pair.addressTimestamp}
                      blockNumberAtDeposit={blockNumberAtDeposit}
                      blockNumberAtOtherMoment={blockNumberAtOtherMoment}
                      graphLastBlock={graphLastBlock}
                      executionSteps={executionSteps}
                      recipeDetails={recipeDetails}
                    />}
                </Suspense>

                {provider === 'BeethovenX' && networkId === Networks.fantom && <BeetsDepositModalLog events={logEvents} data={previousData.data} />}
                {(provider === 'BeethovenX' || provider === 'Balancer') && networkId === Networks.arbitrum && <BalancerDepositModalLog events={logEvents} data={previousData.data} />}
              </>}
          </div>
          <DepositModalLogProvider pair={pair} />
        </div>
      </div>
    </div>
  )
}

const DepositModalLogWrapper = (props: DepositLogModalProps) =>
  <Suspense fallback={<LoadingDepositModalLog {...props} />}>
    <DepositModalLog {...props} />
  </Suspense>
registerModal("deposit-modal-log", DepositModalLogWrapper)
