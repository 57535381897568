import { mainnetFantomDevLocal, mainnetFantomPro, localFantomDev, localFantomPro, mainnetArbitrumDev, mainnetArbitrumPro, ENV_ENVIROMENT, mainnetArbitrumDevLocal, mainnetFantomDev } from 'src/constants'
import Web3 from 'web3'
import web3 from 'src/utils/web3'
import { Environments, NetworkParamsData, Networks } from 'src/utils/networkHelper'
import getSigningParams from './getSigningParams'

export const getNetworkId = async (): Promise<string> => {
  const provider = web3.currentProvider
  if (!provider) return undefined
  else return (provider as any).request({ method: 'eth_chainId' })
}

export const checkNetwork = (networkId: Networks): Boolean => {
  if (networkId === Networks.fantom || networkId === Networks.arbitrum) return true
  return false
}

export const getNetworkParams = (networkId: string): NetworkParamsData => {
  let networkParams: NetworkParamsData
  if (ENV_ENVIROMENT === Environments.fantomLocalDev) return mainnetFantomDevLocal
  else if (ENV_ENVIROMENT === Environments.arbitrumLocalDev) return mainnetArbitrumDevLocal
  switch (networkId) {
    case Networks.fantom:
      if (ENV_ENVIROMENT === Environments.mainnetPro) networkParams = mainnetFantomPro
      else networkParams = mainnetFantomDev
      break

    case Networks.hardhat:
      if (ENV_ENVIROMENT === Environments.mainnetPro) networkParams = localFantomPro
      else networkParams = localFantomDev
      break

    case Networks.arbitrum:
      if (ENV_ENVIROMENT === Environments.mainnetPro) networkParams = mainnetArbitrumPro
      else networkParams = mainnetArbitrumDev
      break
    default:
      if (ENV_ENVIROMENT === Environments.mainnetPro) networkParams = mainnetArbitrumPro
      else networkParams = mainnetArbitrumDev
      break
  }
  return networkParams
}

export const signLogin = async (address: string, networkId: string) => {
  const networkParams = getNetworkParams(networkId)
  const timestamp = Date.now()
  const signingParams = getSigningParams(networkParams, timestamp, address)
  for (const option of signingParams) {
    try {
      const params = [
        option.params,
        address,
      ]
      const method = option.method
      const res = await (web3.currentProvider as any).request({ method, params, account: address })
      console.log(`Signing success using ${option.method}`)
      return {
        signature: res,
        ts: timestamp,
        version: option.id,
      }
    } catch (e) {
      console.warn(`Signing error using ${option.method}:`, e)
      const msg = e.message?.toLowerCase()
      if (msg.includes('denied') || msg.includes('reject') || msg.includes('decline')) throw new Error('Sign in declined') // Cancelled; can't continue trying
    }
  }
  throw new Error('No valid signing methods found.')
}

export const changeChain = async (networkId: string = Networks.arbitrum) => {
  const networkParams = getNetworkParams(networkId)
  const chainParams = {
    chainId: networkParams.chainId,
    chainName: networkParams.chainName,
    rpcUrls: networkParams.rpcUrls,
    blockExplorerUrls: networkParams.blockExplorerUrls,
    nativeCurrency: networkParams.nativeCurrency
  }
  return (web3.currentProvider as any).request({
    method: "wallet_addEthereumChain",
    params: [chainParams],
  })
}

export const getAddressAndBalance = async () => {
  const provider: any = web3.currentProvider
  const accounts = await provider.request({ method: "eth_requestAccounts" })
  const account = accounts[0]
  const balance = await provider.request({
    method: "eth_getBalance",
    params: [account, "latest"],
  });
  const humanReadable = Web3.utils.fromWei(balance, 'ether')
  return { account, balance: humanReadable }
}

export const web3Login = async (networkId: string) => {
  const account = await getAddressAndBalance()
  const address = web3.utils.toChecksumAddress(account.account)
  const signature = await signLogin(address, networkId)
  return {
    address,
    balance: account.balance,
    signature: signature.signature,
    ts: signature.ts,
    version: signature.version,
  }
}
