import dayjs from 'dayjs'

export const getPairDayDataBulk = (allPairs, dateInit: number) => {
  let idFilter = ''
  let pairsString = `[`
  allPairs.map((pair) => {
    return (pairsString += `"${pair}"`)
  })
  pairsString += ']'

  if (allPairs.length > 0) idFilter = `pairAddress_in: ${pairsString}, `

  return `
    query days {
      pairDayDatas(first: 1000, where: {reserveUSD_gte: 10000, ${idFilter}date_gt: ${dateInit}}, orderBy: volumeUSD, orderDirection: desc) {
        id
        pair {
          id
        }
        date
        totalSupply
        volumeUSD
        reserveUSD
        reserve0
        reserve1
        token0 {
          id
          symbol
          name
          derivedETH
        }
        token1 {
          id
          symbol
          name
          derivedETH
        }
      }
    }
  `
}

export const PAIR_DAY_DATA_BULK_NO_TOKENS = (dateInit: number) => {
  const reserveUSDMin: number = 10000
  return `
  query days {
    pairDayDatas(first: 100, where: {date_gt: ${dateInit}, reserveUSD_gte: ${reserveUSDMin}}, orderBy: volumeUSD, orderDirection: desc) {
      id
      pair {
        id
      }
      date
      totalSupply
      volumeUSD
      reserveUSD
      id
    }
  }
`
}

export const PAIR_5DAY_DATA_BULK = (pairs: any[]): string => {
  let idFilter = ''
  let pairsString = `[`
  pairs.map((pair) => {
    pairsString += `"${pair}"`
    return pairsString
  })
  pairsString += ']'

  if (pairs.length > 0) idFilter = `pairAddress_in: ${pairsString}, `
  const fivedaysago = (Math.floor(dayjs().unix() / 86400) - 5) * 86400

  return `
    query days {
      pairDayDatas(first: 400, where: { ${idFilter}, date_gt: ${fivedaysago}}) {
        id
        pair {
          id
        }
        date
        volumeUSD
        reserveUSD
        totalSupply
        reserve0
        reserve1
        token0 {
          id
          symbol
          name
          derivedETH
        }
        token1 {
          id
          symbol
          name
          derivedETH
        }
      }
    }
`
}

export const PAIR_DATA_IN_SPECIFIC_TIMESTAMP = (pairId: string, timestamp: number): string => {
  const segInOneDay: number = 86400
  const oneDayAgo = (Math.floor(dayjs().unix() / segInOneDay) - 1) * segInOneDay
  const date: number = (timestamp - segInOneDay) > oneDayAgo ? oneDayAgo : (timestamp - segInOneDay)
  return `
    query days {
      pairDayDatas(first: 1, where: { pair_: {id: "${pairId}"}, date_gt: ${date} }) {
        pair {
          id
        }
        date
        volumeUSD
        reserveUSD
        token0 {
          symbol
        }
        token1 {
          symbol
        }
      }
    }
  `
}

export const CURRENT_ALL_FARMS_DATA = (timestamp: number, lpsAddresses: string[] | string) => {
  let idFilter = ''
  let pairsString = `[`
  if (Array.isArray(lpsAddresses)) {
    lpsAddresses.map((pair) => {
      pairsString += `"${pair}"`
      return pairsString
    })
  } else {
    pairsString += `"${lpsAddresses}"`
  }
  pairsString += ']'

  if (lpsAddresses.length > 0) idFilter = `pairAddress_in: ${pairsString}, `
  const segInOneDay: number = 86400
  const lastDateWithData = (Math.floor(timestamp / segInOneDay) - 1) * segInOneDay
  return `
  query days {
    pairDayDatas(first: ${lpsAddresses.length}, where: { ${idFilter} date_gt: ${lastDateWithData}}) {
      id
      pairAddress
      date
      totalSupply
      dailyVolumeUSD
      reserveUSD
      token0 {
        symbol
      }
      token1 {
        symbol
      }
    }
  }
`
}

export const getTokenPriceFromSushiGraph = (tokenAddress: string, dateToGetPrice?: number): string => {
  const currentTimestamp: number = Math.floor(new Date().getTime() / 1000)
  const secondInDay: number = 86400
  const previousDayTimestamp: number = currentTimestamp - secondInDay
  const dateToUse: number = dateToGetPrice ?? previousDayTimestamp
  return (
    `query token {
  token(id:"${tokenAddress.toLowerCase()}") {
    id
    symbol
    tokenDayData(where: {date_gt: ${dateToUse}}) {
      priceUSD
    }
  }
}`)
}
