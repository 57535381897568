import React, { Suspense, useMemo, useState } from 'react'
import { registerModal } from '@gluedigital/modal'
import { FormattedMessage } from 'react-intl'
import { NestedStrategiesModalData } from 'src/routes/RecipeDiagram/helpers/types'
import { BaseNodeModal } from '../BaseNodeModal'
import Loading from 'src/components/common/Loading/Loading'
import useInfiniteScroll from 'src/hooks/useInfiniteScroll'
import { ListNestedStrategiesModal } from './ListNestedStrategiesModal'
import { useFetchYearnDaemonAllVaults } from 'src/hooks/useYearn'
import { YearnSummaryVaultsData, YearnYDaemonVaultsData } from 'src/api/yearnVaults/yearnTypes'
import { LoadingNestedStrategiesModal } from './LoadingNestedStrategiesModal'
import { useReaperFinanceAllVaults } from 'src/hooks/useReaperFinance'
import { ReaperNestedData, ReaperSummaryVaultsData, ReaperVaultsData } from 'src/api/reaperFinance/reaperTypes'
import { adaptReaperV2Info, combineReaperAndYearn, ftmNestedFilter, NestedVaultsInfo } from './nestedModalHelper'
import { useFetchAllBeetsPoolsOnlyWithTokens } from 'src/hooks/useBalancerPools'
import { BeetsPoolsInfo } from 'src/api/beets/beetsTypes'
import { useSelector } from 'react-redux'
import { Networks } from 'src/utils/networkHelper'
import { HeaderNestedStrategiesModal, SortSelectorNestedStrategies } from './NestedStrategiesHelperModal'
import SearchBarFarmModal from '../FarmModal/SearchBarFarmModal'
import './NestedStrategiesModal.sass'

interface NestedStrategiesModalProps extends BaseNodeModal<NestedStrategiesModalData> { }

const NestedStrategiesFantomModal = (props: NestedStrategiesModalProps) => {
  const { previousData } = props
  const networkId: string = useSelector((s: any) => (s.network.network))
  const [search, setSearch] = useState<string>('')
  const [sort, setSort] = useState<string>('APY')
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [showLoadMoreButton, setLoadMoreButton] = useState<boolean>(true)
  const [chosenOption, setChosenOption] = useState<YearnSummaryVaultsData | ReaperSummaryVaultsData>(previousData ? (previousData?.yearn ? previousData?.yearn : previousData?.reaper) : null)

  const PAGE_SIZE: number = 9

  const loadMoreHandler = () => {
    if (PAGE_SIZE * currentPage <= readyNestedStrategiesData.length) {
      setCurrentPage(currentPage => currentPage + 1)
    } else {
      setLoadMoreButton(false)
    }
  }
  const yearnAllVaults: YearnYDaemonVaultsData[] = useFetchYearnDaemonAllVaults(networkId)
  const reaperAllVaults: ReaperVaultsData[] = useReaperFinanceAllVaults(networkId)
  const beetsVaultsWithTokens: BeetsPoolsInfo[] = useFetchAllBeetsPoolsOnlyWithTokens(networkId)
  const combinedVaults: NestedVaultsInfo[] = combineReaperAndYearn(reaperAllVaults, yearnAllVaults, beetsVaultsWithTokens)
  const readyNestedStrategiesData = useMemo(() => {
    const searchText = search.toLowerCase()
    const allVaults: YearnSummaryVaultsData[] | ReaperSummaryVaultsData[] = combinedVaults.map((vaults) => vaults?.reaper ? vaults?.reaper : vaults.yearn)
    const updatedStrategies: YearnSummaryVaultsData[] | ReaperSummaryVaultsData[] = [...allVaults].filter((vault) => vault.name.toLowerCase().includes(searchText))
    const filteredNested = ftmNestedFilter(updatedStrategies)
    switch (sort) {
      case 'APY':
        filteredNested.sort((a, b) => Number(b.apy) - Number(a.apy))
        break
      case 'TOTAL ASSETS':
        filteredNested.sort((a, b) => Number(b.tvl) - Number(a.tvl))
        break
      case 'PROVIDER':
        filteredNested.sort((a, b) => (a.provider > b.provider) ? 1 : ((b.provider > a.provider) ? -1 : 0))
    }
    return filteredNested.slice(0, PAGE_SIZE * currentPage)
  }, [combinedVaults, currentPage, search, sort])

  const ref: any = useInfiniteScroll(loadMoreHandler)

  const checkIsChosen = (vault: YearnSummaryVaultsData | ReaperSummaryVaultsData): boolean => {
    let isChosen: boolean = false
    if (chosenOption) isChosen = chosenOption.name === vault.name
    else {
      if (!previousData) return isChosen
      if (previousData.yearn) isChosen = previousData.yearn.name === vault.name
      if (previousData.reaper) isChosen = previousData.reaper.name === vault.name
    }
    return isChosen
  }

  return (
    <div className="nested-strategies-modal modal-content">
      <div className="modal-wrapper" ref={ref}>
        <HeaderNestedStrategiesModal previousData={previousData} chosenOption={chosenOption} onSave={props.onSave} />
        <section className="main-section">
          <header>
            <SearchBarFarmModal setSearch={setSearch} />
            <SortSelectorNestedStrategies sort={sort} setSort={setSort} />
          </header>
          {readyNestedStrategiesData?.length !== 0 ? (
            <ul className="farm-list">
              {readyNestedStrategiesData.map((vault, i) =>
                <ListNestedStrategiesModal
                  key={i}
                  vault={vault}
                  isChosen={checkIsChosen(vault)}
                  setChosenOption={(vault: YearnSummaryVaultsData | ReaperSummaryVaultsData) => setChosenOption(vault)} />
              )}
              {showLoadMoreButton &&
                <li className="load-more-wrapper">
                  <button className="load-more-button" onClick={loadMoreHandler}>
                    <FormattedMessage id="load-more" />
                  </button>
                </li>}
            </ul>
          ) : (
            <div className="table-body loading">
              <Loading />
            </div>)}
        </section>
      </div>
    </div>

  )
}

const NestedStrategiesArbitrumModal = (props: NestedStrategiesModalProps) => {
  const { previousData } = props
  const networkId: string = useSelector((s: any) => (s.network.network))
  const [search, setSearch] = useState<string>('')
  const [sort, setSort] = useState<string>('APY')
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [showLoadMoreButton, setLoadMoreButton] = useState<boolean>(true)
  const [chosenOption, setChosenOption] = useState<ReaperSummaryVaultsData>(previousData ? (previousData?.yearn ? previousData?.yearn : previousData?.reaper) : null)

  const PAGE_SIZE: number = 9

  const loadMoreHandler = () => {
    if (PAGE_SIZE * currentPage <= readyNestedStrategiesData.length) {
      setCurrentPage(currentPage => currentPage + 1)
    } else {
      setLoadMoreButton(false)
    }
  }
  const reaperAllVaults: ReaperNestedData[] = useReaperFinanceAllVaults(networkId)
  const readyNestedStrategiesData = useMemo(() => {
    const searchText = search.toLowerCase()
    const combinedVaults: NestedVaultsInfo[] = adaptReaperV2Info(reaperAllVaults)
    const allVaults: ReaperSummaryVaultsData[] = combinedVaults.map((vaults) => vaults?.reaper)
    const updatedStrategies: ReaperSummaryVaultsData[] = [...allVaults].filter((vault) => vault.name.toLowerCase().includes(searchText))
    switch (sort) {
      case 'APY':
        updatedStrategies.sort((a, b) => Number(b.apy) - Number(a.apy))
        break
      case 'TOTAL ASSETS':
        updatedStrategies.sort((a, b) => Number(b.tvl) - Number(a.tvl))
        break
      case 'PROVIDER':
        updatedStrategies.sort((a, b) => (a.provider > b.provider) ? 1 : ((b.provider > a.provider) ? -1 : 0))
    }
    return updatedStrategies.slice(0, PAGE_SIZE * currentPage)
  }, [reaperAllVaults, currentPage, search, sort])

  const ref: any = useInfiniteScroll(loadMoreHandler)

  const checkIsChosen = (vault: ReaperSummaryVaultsData): boolean => {
    let isChosen: boolean = false
    if (chosenOption) isChosen = chosenOption.name === vault.name
    else {
      if (!previousData) return isChosen
      if (previousData.reaper) isChosen = previousData.reaper.name === vault.name
    }
    return isChosen
  }

  return (
    <div className="nested-strategies-modal modal-content">
      <div className="modal-wrapper" ref={ref}>
        <HeaderNestedStrategiesModal previousData={previousData} chosenOption={chosenOption} onSave={props.onSave} />
        <section className="main-section">
          <header>
            <SearchBarFarmModal setSearch={setSearch} />
            <SortSelectorNestedStrategies sort={sort} setSort={setSort} />
          </header>
          {readyNestedStrategiesData?.length !== 0 ? (
            <ul className="farm-list">
              {readyNestedStrategiesData.map((vault, i) =>
                <ListNestedStrategiesModal
                  key={i}
                  vault={vault}
                  isChosen={checkIsChosen(vault)}
                  setChosenOption={(vault: ReaperSummaryVaultsData) => setChosenOption(vault)} />
              )}
              {showLoadMoreButton &&
                <li className="load-more-wrapper">
                  <button className="load-more-button" onClick={loadMoreHandler}>
                    <FormattedMessage id="load-more" />
                  </button>
                </li>}
            </ul>
          ) : (
            <div className="table-body loading">
              <Loading />
            </div>)}
        </section>
      </div>
    </div>

  )
}

const NestedStrategiesModalWrapper = (props: NestedStrategiesModalProps) => {
  const networkId: string = useSelector((s: any) => (s.network.network))
  return (
    <Suspense fallback={<LoadingNestedStrategiesModal />}>
      {networkId === Networks.fantom && <NestedStrategiesFantomModal {...props} />}
      {networkId === Networks.arbitrum && <NestedStrategiesArbitrumModal {...props} />}
    </Suspense>
  )
}

registerModal('nested-strategies-modal', NestedStrategiesModalWrapper)
