import usePromise from "./usePromise"
import { GET_LAST_BLOCK } from "src/api/farms/thegraph/pairsQueriesHelper"
import fetchGraph from "src/api/farms/thegraph/fetchGraph"
import { masterchefTheGraphUriFromUniswap } from "src/constants"

export const useLastSyncGraphBlock = (networkId: string): number => {
    const graphUrl = masterchefTheGraphUriFromUniswap[networkId]
    const lastBlockInfo = usePromise(fetchGraph, graphUrl, GET_LAST_BLOCK)
    return lastBlockInfo.data._meta.block.number
}

export const useGraph = (graphUrl: string, query: string) => {
    return usePromise(fetchGraph, graphUrl, query)
}
