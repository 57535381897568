import React from "react"
import { FormattedMessage } from "react-intl"

import "./TokenSearch.sass"

interface TokenSearchProps {
  setSearch: Function
  search?: string
}

const TokenSearch = ({ search, setSearch }: TokenSearchProps) => {
  return (
    <label className="token-selector-search">
      <span className="icon icon-search" />
      <FormattedMessage id="placeholder.token-selector">
        {(text) => (
          <input
            type="search"
            onChange={e => {
              e.preventDefault();
              setSearch(e.target.value)
            }}
            placeholder={text as string}
            value={search || ''}
          />
        )}
      </FormattedMessage>
    </label>
  )
}

export default TokenSearch
