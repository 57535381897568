import { ApolloQueryResult } from "@apollo/client"
import { LP_PAIR_INFO } from "src/api/farms/thegraph/lpQueries"
import { GET_PAIR_TOKEN_BY_ADDRESS, ONE_LP_INFO_FROM_BLOCK_NUMBER } from '../api/farms/thegraph/lpQueries'
import usePromise from "./usePromise"
import { useFTMPriceUSDByBlock } from "./useTokensLogsFromGraph"
import web3 from "src/utils/web3"
import { PairInfoSpooky } from "src/api/farms/types"
import fetchGraph from "src/api/farms/thegraph/fetchGraph"
import { masterchefTheGraphUriFromUniswap } from "src/constants"

const getLPLiquidityAndTotalSupply = async (pairAddress: string, blockNumber: number, graphLastBlock: number, networkId: string): Promise<ApolloQueryResult<any>> => {
  const usefulBlockNumber: number = blockNumber > graphLastBlock ? graphLastBlock : blockNumber
  if (usefulBlockNumber !== 0 && usefulBlockNumber !== null && usefulBlockNumber !== undefined) {
    if (pairAddress.length !== 42) pairAddress = pairAddress.slice(0, -6)
    const graphUrl = masterchefTheGraphUriFromUniswap[networkId]
    const query = ONE_LP_INFO_FROM_BLOCK_NUMBER(pairAddress, usefulBlockNumber)
    const res = await fetchGraph(graphUrl, query)
    return res
  }
  return undefined
}

export const useLPLiquidityAndTotalSupply = (pairAddress: string, blockNumber: number, graphLastBlock: number, networkId: string) => {
  const lpInfo = usePromise(getLPLiquidityAndTotalSupply, pairAddress, blockNumber, graphLastBlock, networkId)
  return [lpInfo?.data.pair?.reserveETH, lpInfo?.data.pair?.totalSupply]
}

export const useLPPriceUSD = (pairAddress: string, blockNumber: number, graphLastBlock: number, networkId: string) => {
  const LpData: number[] = useLPLiquidityAndTotalSupply(pairAddress, blockNumber, graphLastBlock, networkId)
  const ftmPrice: number = useFTMPriceUSDByBlock(blockNumber, graphLastBlock, networkId)
  const lpPrice: number = (LpData[0] / LpData[1]) * ftmPrice
  return lpPrice
}

const getPairTokensByAddress = async (pairAddress: string, networkId: string) => {
  const graphUrl = masterchefTheGraphUriFromUniswap[networkId]
  const query = GET_PAIR_TOKEN_BY_ADDRESS(pairAddress)
  const result = await fetchGraph(graphUrl, query)
  return result
}

export const usePairTokensByAddress = (pairAddress: string, networkId: string) => {
  return usePromise(getPairTokensByAddress, pairAddress, networkId)
}

const getLPInfo = async (pairAddress: string, blockNumber: number = undefined, networkId: string, timestamp: number = undefined) => {
  if (pairAddress.length !== 42) pairAddress = pairAddress.slice(0, -6)
  const graphUrl = masterchefTheGraphUriFromUniswap[networkId]
  let query: string

  if (timestamp) {
    query = LP_PAIR_INFO(pairAddress, timestamp)
    const res = await fetchGraph(graphUrl, query)
    return res
  } else if (blockNumber) {
    const block = await web3.eth.getBlock(blockNumber)
    const blockTimestamp: number = block.timestamp as number
    query = LP_PAIR_INFO(pairAddress, blockTimestamp)
    const res = await fetchGraph(graphUrl, query)
    return res
  } else if (!blockNumber && !timestamp) return undefined
}

export const useGetLPInfo = (pairAddress: string, blockNumber: number = undefined, networkId: string, timestamp: number = undefined): PairInfoSpooky => {
const res = usePromise(getLPInfo, pairAddress, blockNumber, networkId, timestamp)
const pair: PairInfoSpooky = res.data.pairDayDatas[0]
return pair
}

export const useLPUsdPrice = (pairAddress: string, blockNumber: number = undefined, networkId: string, timestamp: number = undefined): number => {
  const pair: PairInfoSpooky = useGetLPInfo(pairAddress, blockNumber, networkId, timestamp)
  const lpPriceUSD: number = parseFloat(pair.reserveUSD) / parseFloat(pair.totalSupply)
  return lpPriceUSD
}
