import React from "react"
import { FormattedMessage } from "react-intl"
import { ComboTriggerModalData, ConditionProvider, ProfitTriggerData } from "src/routes/RecipeDiagram/helpers/types"
import { useTriggerProfitInput } from "src/components/modals/nodeModals/ComboTriggerModal/hooks/useTriggerProfitInput"

import "./TortlePerformanceType.sass"

interface TortlePerformanceTypeProps {
  triggerData: ProfitTriggerData
  setTriggerData: (data: ComboTriggerModalData) => void
}

const TortlePerformanceType = ({ setTriggerData, triggerData }: TortlePerformanceTypeProps) => {
  const condition = triggerData ? triggerData.condition : {}
  const conditionProvider = ConditionProvider.tortle
  const {
    getValue,
    inputCondition,
    inputConditionValue
  } = useTriggerProfitInput(triggerData)

  const handleInput = () => {
    const value = getValue()
    setTriggerData({ condition: { ...condition, value }, conditionProvider })
  }

  const handleConditionType = type => {
    setTriggerData({ condition: { ...condition, performanceType: type }, conditionProvider })
  }

  return (
    <div className="performance-type performance-type-tortle">
      <section>
        <h2><FormattedMessage id="combo-type-profit.when" /></h2>
        <select
          value={condition?.performanceType || 'profits'}
          onChange={(e) => {
            const type: any = e.target.value
            handleConditionType(type)
          }}
        >
          <FormattedMessage id="combo-type-profit.profits">
            {(text: string) => <option value="profits">{text}</option>}
          </FormattedMessage>
          <FormattedMessage id="combo-type-profit.losses">
            {(text: string) => <option value="losses">{text}</option>}
          </FormattedMessage>
        </select>
      </section>
      <section>
        <h2><FormattedMessage id="combo-type-profit.are" /></h2>
        <h2><FormattedMessage id="combo-type-profit.higher" /></h2>
      </section>
      <section>
        <h2><FormattedMessage id="combo-trigger-modal.than" /></h2>
        <label>
          <input
            ref={inputCondition}
            value={inputConditionValue}
            onChange={() => { handleInput() }}
            placeholder="Value..."
            min={1}
            step={0.1}
            pattern="\d*"
            inputMode="decimal"
          />
          <span>%</span>
        </label>
      </section>
    </div>
  )
}

export default TortlePerformanceType
