import React from 'react'
import { Link } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'
import MarkdownMessage from "@gluedigital/markdown-message"
import LanguageSelector from 'src/components/Landing/LanguageSelector/LanguageSelector'
import LaunchButton from '../LaunchButton/LaunchButton'

import logo from 'src/static/images/logos/white-logo.svg'
import psh from 'src/static/images/psh.svg'
import './Footer.sass'

const Footer = () => {
  return (
    <footer id="colophon">
      <div className="container">
        <section className="column logo">
          <Link className="link footer-logo" to="/">
            <img loading="lazy" src={logo} alt="tortle logo" />
          </Link>
          <div className="psh-card only-desktop">
            <span className="audited-by"><FormattedMessage id="footer.audited-by" /></span>
            <img loading="lazy" src={psh} alt="peckShield logo" />
            <a
              href="https://github.com/peckshield/publications/blob/master/audit_reports/PeckShield-Audit-Report-TortleNinja-v1.0.pdf"
              target="_blank"
              className="button"
              rel="noopener noreferrer"
            >
              <MarkdownMessage id="footer.peckshield" />
            </a>
          </div>
        </section>
        <section className="column info">
          <h1>
            <FormattedMessage id="footer.info.title" />
          </h1>
          <a rel="noopener noreferrer" className="link" target="_blank" href="https://docs.tortle.ninja/">
            <FormattedMessage id="footer.info.docs" />
          </a>
          <a rel="noopener noreferrer" className="link" target="_blank" href="https://tortle-ninja.medium.com/">
            <FormattedMessage id="footer.info.medium" />
          </a>
          <a rel="noopener noreferrer" className="link" target="_blank" href="https://github.com/Tortle-Ninja/">
            <FormattedMessage id="footer.info.github" />
          </a>
          <a
            rel="noopener noreferrer"
            className="link"
            target="_blank"
            href="https://docs.tortle.ninja/what-is-tortle-ninja/audit-report"
          >
            <FormattedMessage id="footer.info.audit" />
          </a>
          <a
            rel="noopener noreferrer"
            className="link"
            target="_blank"
            href="https://drive.google.com/file/d/1hs_y7zih-5UiyRVt0gbk397dP7S5gJ3_/view?usp=sharing"
          >
            <FormattedMessage id="footer.info.media" />
          </a>
        </section>
        <section className="column community">
          <h1>
            <FormattedMessage id="footer.community.title" />
          </h1>
          {/* <a rel="noopener noreferrer" className="link" target="_blank" href="https://t.me/tortlenews">
            <FormattedMessage id="footer.community.announcements" />
          </a> */}
          <a rel="noopener noreferrer" className="link" target="_blank" href="https://t.me/tortleninjaTG">
            <FormattedMessage id="footer.community.telegram" />
          </a>
          <a rel="noopener noreferrer" className="link" target="_blank" href="https://discord.gg/tortleninja">
            <FormattedMessage id="footer.community.discord" />
          </a>
          <a rel="noopener noreferrer" className="link" target="_blank" href="https://guild.xyz/tortleninja">
            <FormattedMessage id="footer.community.guild" />
          </a>
          <a rel="noopener noreferrer" className="link" target="_blank" href="https://twitter.com/tortle_ninja">
            <FormattedMessage id="footer.community.twitter" />
          </a>
          <a rel="noopener noreferrer" className="link" target="_blank" href="https://coinmarketcap.com/community/profile/TortleNinja/">
            <FormattedMessage id="footer.community.coinmarketcap" />
          </a>
          <a rel="noopener noreferrer" className="link" target="_blank" href="https://www.youtube.com/@TortleNinja">
            <FormattedMessage id="footer.community.youtube" />
          </a>
        </section>
        <section className="column help">
          <h1>
            <FormattedMessage id="footer.help.title" />
          </h1>
          <a
            rel="noopener noreferrer"
            className="link"
            target="_blank"
            href="https://discord.com/channels/899973803239686174/1004389347790946385"
          >
            <FormattedMessage id="footer.help.talk-support" />
          </a>
        </section>
        <section className="column actions">
          <LanguageSelector />
          <LaunchButton />
        </section>
      </div>
      <div className="only-mobile">
        <div className="psh-card">
          <span className="audited-by"><FormattedMessage id="footer.audited-by" /></span>
          <img loading="lazy" src={psh} alt="peckShield logo" />
          <a
            href="https://github.com/peckshield/publications/blob/master/audit_reports/PeckShield-Audit-Report-TortleNinja-v1.0.pdf"
            target="_blank"
            className="button"
            rel="noopener noreferrer"
          >
            <MarkdownMessage id="footer.peckshield" />
          </a>
        </div>
      </div>

    </footer>
  )
}

export default Footer
