import React from "react"
import { FormattedMessage } from "react-intl"

export interface OrderBySelectorProps {
  sort: string
  setSort: (s: string) => void
}
const OrderBySelector = ({ sort, setSort }: OrderBySelectorProps) => {
  return (
    <div className="select-wrapper">
      <select defaultValue={sort} onChange={(e) => setSort(e.target.value)}>
        <FormattedMessage id="deposit-lp-modal.sort-by-apr">
          {(text) => <option value="APR">{text}</option>}
        </FormattedMessage>
        <FormattedMessage id="deposit-lp-modal.sort-by-liquidity">
          {(text) => <option value="LIQUIDITY">{text}</option>}
        </FormattedMessage>
        <FormattedMessage id="deposit-lp-modal.sort-by-volume">
          {(text) => <option value="VOLUME">{text}(24h)</option>}
        </FormattedMessage>
        <FormattedMessage id="deposit-lp-modal.sort-by-provider">
          {(text) => <option value="PROVIDER">{text}</option>}
        </FormattedMessage>
      </select>
    </div>
  )
}

export default OrderBySelector
