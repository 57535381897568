import React, { Suspense } from "react"
import { FormattedMessage } from "react-intl"
import { usePeriodicRecipes } from "src/api/recipes"
import Loading from "src/components/common/Loading/Loading"
import RecipeList from "src/components/RecipeList/RecipeList"
import NoRecipes from "../NoRecipes/NoRecipes"

const FailedRecipes = () => {
  const recipes = usePeriodicRecipes("failed")
  if (recipes.length === 0) return <NoRecipes />

  return (
    <section id="failed-recipes" className="recipes-section">
      <h1>
        <FormattedMessage id="recipes-section.failed-recipes.title" values={{ n: recipes.length }} />
      </h1>
      <RecipeList recipes={recipes} type={"failed"} />
    </section>
  )
}

const FailedRecipesWrapper = (props) => {
  return <Suspense fallback={<Loading />}>
    <FailedRecipes {...props} />
  </Suspense>
}
export default FailedRecipesWrapper
