import { filterPoolsForBeets } from "src/components/modals/nodeModals/DepositOnLPModal/depositModalHelper"
import { BeetsPoolsInfo, SubgraphBeetsPoolsInfo } from "./beetsTypes"
import { FETCH_BEETS_POOLS } from "./beetsQueries"
import { balancerSubgraph } from "src/constants"
import fetchGraph from "../farms/thegraph/fetchGraph"
import { getBeetsPoolsApr } from "./poolsHelpers"

export const fetchAllBeetsPools = async (networkId: string): Promise<BeetsPoolsInfo[]> => {
  const result: SubgraphBeetsPoolsInfo = await fetchGraph(balancerSubgraph[networkId], FETCH_BEETS_POOLS())
  const data: BeetsPoolsInfo[] = result.data.poolGetPools
  const dataWithLiquidityFilter: BeetsPoolsInfo[] = data.filter((pool) => Number(pool.dynamicData.totalLiquidity24hAgo) >= 1000)
  const dataFiltered: BeetsPoolsInfo[] = filterPoolsForBeets(dataWithLiquidityFilter)
  const newData: BeetsPoolsInfo[] = dataFiltered.map((pool) => {
    const calculatedAPR: number = getBeetsPoolsApr(pool.dynamicData.volume24h, pool.dynamicData.swapFee, pool.dynamicData.totalLiquidity24hAgo)
    return { ...pool, calculatedAPR: calculatedAPR.toFixed(2), provider: "BeethovenX" }
  })
  return newData
}
