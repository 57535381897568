import { BigNumber } from "ethers";
import { saneBigNumber } from "src/utils/bigNumberHelper";
import { getLiquidationPriceFromDelta } from "./getLiquidationPriceFromDelta";
import { BASIS_POINTS_DIVISOR, LIQUIDATION_FEE, MAX_LEVERAGE, getMarginFee } from "./liquidationPriceHelpers";

const getLiquidationPrice = (isLong: boolean, size: string, collateralAmount: string, averagePrice: string): BigNumber => {
  const nextSize: BigNumber = size ? saneBigNumber(parseFloat(size)) : BigNumber.from(0);
  const remainingCollateral: BigNumber = saneBigNumber(parseFloat(collateralAmount));

  const positionFee: BigNumber = getMarginFee(saneBigNumber(parseFloat(size))).add(LIQUIDATION_FEE);
  // if (entryFundingRate && cumulativeFundingRate) {
  //   // const fundingFee = size.mul(cumulativeFundingRate.sub(entryFundingRate)).div(FUNDING_RATE_PRECISION);
  //   positionFee = positionFee.add(fundingFee);
  // }

  const liquidationPriceForFees: BigNumber = getLiquidationPriceFromDelta({
    liquidationAmount: positionFee,
    size: nextSize,
    collateral: remainingCollateral,
    averagePrice,
    isLong,
  });
  const liquidationPriceForMaxLeverage: BigNumber = getLiquidationPriceFromDelta({
    liquidationAmount: nextSize.mul(BASIS_POINTS_DIVISOR).div(MAX_LEVERAGE),
    size: nextSize,
    collateral: remainingCollateral,
    averagePrice,
    isLong,
  });

  if (!liquidationPriceForFees) {
    return liquidationPriceForMaxLeverage;
  }
  if (!liquidationPriceForMaxLeverage) {
    return liquidationPriceForFees;
  }

  if (isLong) {
    // return the higher price
    return liquidationPriceForFees.gt(liquidationPriceForMaxLeverage)
      ? liquidationPriceForFees
      : liquidationPriceForMaxLeverage;
  }

  // return the lower price
  return liquidationPriceForFees.lt(liquidationPriceForMaxLeverage)
    ? liquidationPriceForFees
    : liquidationPriceForMaxLeverage;
}

export default getLiquidationPrice
