import React from "react"
import { FormattedMessage } from "react-intl"
import 'src/routes/RecipeDiagram/SideBarViewLogs/SidebarViewLogs.sass'
import { registerModal, CloseModal } from "@gluedigital/modal"
import './FarmExplanationLog.sass'

export const ExplanationButton = () => {
  return (
    <div className="more-info">
      <FormattedMessage id="farm-more-info" />
    </div>
  )
}

export const FarmExplanationLog = () => {
  return (
    <div className="farm-details-message">
      <header className="header-buttons">
        <h1 className="more-info-text"><FormattedMessage id="farm-more-info" /></h1>
        <CloseModal>
          <button className="back-button">
            <span className="icon icon-close" />
            <span><FormattedMessage id="close" /></span>
          </button>
        </CloseModal>
      </header>
      <p className="farm-details-message-text">
        <span className="pre-text">
          <FormattedMessage id="farm-details-message-pre01" />
        </span>
        <FormattedMessage id="farm-details-message01" />
      </p>
      <p className="farm-details-message-text">
        <span className="pre-text">
          <FormattedMessage id="farm-details-message-pre02" />
        </span>
        <FormattedMessage id="farm-details-message02" />
      </p>
      <p className="farm-details-message-text">
        <span className="pre-text">
          <FormattedMessage id="farm-details-message-pre03" />
        </span>
        <FormattedMessage id="farm-details-message03" />
      </p>
      <p className="farm-details-message-text">
        <span className="pre-text">
          <FormattedMessage id="farm-details-message-pre04" />
        </span>
        <FormattedMessage id="farm-details-message04" />
      </p>
      <p className="farm-details-message-text">
        <span className="pre-text">
          <FormattedMessage id="farm-details-message-pre05" />
        </span>
        <FormattedMessage id="farm-details-message05" />
      </p>
      <p className="farm-details-message-text">
        <span className="pre-text">
          <FormattedMessage id="farm-details-message-pre06" />
        </span>
        <FormattedMessage id="farm-details-message06" />
      </p>
      <p className="farm-details-message-text">
        <span className="pre-text">
          <FormattedMessage id="farm-details-message-pre07" />
        </span>
        <FormattedMessage id="farm-details-message07" />
      </p>
    </div>
  )
}

registerModal("farm-explanation-modal", FarmExplanationLog)
