
import React from "react"
import { SushiSwapFarmsData, rewardsSushiData } from "src/api/farms/types"
import { useFarmRewardsAmountFromStrategy } from "src/hooks/useFarmsLogs"
import { usePrices } from "src/hooks/usePrices"
import web3 from "src/utils/web3"
interface EarnedFarmInfoLeftLogProps {
  nodeId: string
  depositedSLPValueUSD: number
  depositedSLPEther: number
  totalSLPAmount: number
  myFarm: SushiSwapFarmsData
}

const EarnedFarmInfoLeftLog = ({ nodeId, depositedSLPEther, depositedSLPValueUSD, totalSLPAmount, myFarm }: EarnedFarmInfoLeftLogProps) => {
  const lpPriceActiveNode: number = Number(myFarm?.reserveUSD) / Number(web3.utils.fromWei(myFarm?.totalSupply))
  const totalSLPUSDValue: number = lpPriceActiveNode * totalSLPAmount
  const amountRewardsWei: number[] = useFarmRewardsAmountFromStrategy(nodeId)
  const rewardsUSDValue: number[] = []
  let rewardTokensNames: string[] = []
  let amountRewardsEther: number[] = []
  let totalRewardUSDValue: number
  const rewards: rewardsSushiData[] = myFarm?.rewards
  if (rewards.length === 1) {
    rewardTokensNames.push(rewards[0].name)
    amountRewardsEther.push(amountRewardsWei[0] / 10 ** 18)
  } else {
    if (rewards[1].name === "SUSHI") {
      rewardTokensNames = rewards.map(r => r.name)
      amountRewardsEther = amountRewardsWei.map(amount => amount / (10 ** 18))
    } else { // from useFarmRewardsAmountFromStrategy SUSHI reward is always the second in case there are two rewards
      rewardTokensNames.push(rewards[1].name)
      amountRewardsEther.push(amountRewardsWei[0] / 10 ** 18)
      rewardTokensNames.push(rewards[0].name)
      amountRewardsEther.push(amountRewardsWei[1] / 10 ** 18)
    }
  }

  const reward0PriceUSD: string = (usePrices(rewards[0].name))
  const reward1PriceUSD: string = usePrices(rewards[1]?.name)
  rewardsUSDValue.push(parseFloat(reward0PriceUSD) * amountRewardsEther[0])

  if (reward1PriceUSD && reward1PriceUSD !== '0') {
    rewardsUSDValue.push(parseFloat(reward1PriceUSD) * amountRewardsEther[1])
    totalRewardUSDValue = rewardsUSDValue[1] + rewardsUSDValue[0]
  } else totalRewardUSDValue = rewardsUSDValue[0]

  const totalUSD: number = totalSLPUSDValue + totalRewardUSDValue

  let earnedUSDTotal: string = (totalUSD - depositedSLPValueUSD).toFixed(3)
  if (earnedUSDTotal === "-0.000") earnedUSDTotal = "0.000"

  return (
    <>
      {/* CURRENT VALUE */}
      <div className="logs-group-rows">
        <p className="main-text-logs">$ {totalUSD.toFixed(3)} </p>
        <p>{totalSLPAmount.toFixed(12)}  LPs<span className="left-logs-usd-price">$ {(totalSLPUSDValue).toFixed(3)}</span></p>
       {rewards.map((r, i) => <p key={r.name}>{amountRewardsEther[i].toFixed(10)} {rewardTokensNames[i]} <span className="left-logs-usd-price">$ {(rewardsUSDValue[i]).toFixed(3)}</span></p>)}
      </div>
      {/* EARNED */}
      <div className="logs-group-rows">
        <p className="main-text-logs">$ {earnedUSDTotal}</p>
        <p>{(totalSLPAmount - depositedSLPEther).toFixed(12)} LPs</p>
      </div>
    </>
  )
}

export default EarnedFarmInfoLeftLog
