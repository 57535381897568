import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { setLang } from 'universal-scripts'
import langChoices from 'src/locales/choices.json'
import languageImages from './languageImages.json'

import './LanguageSelectorDialog.sass'
import { FormattedMessage } from 'react-intl'

const LanguageSelectorDialog = ({ chooseLanguage, setChooseLanguage }: { chooseLanguage: boolean, setChooseLanguage: (l) => void }) => {
  const dispatch = useDispatch()
  const languages = Object.keys(langChoices)
  const currentLang = useSelector((s: any) => s.intl.locale)
  const handleLang = (language) => dispatch(setLang(language))

  return (
    <dialog
      className="language-selector-dialog"
      open={chooseLanguage}
    >
      <header>
        <h1><FormattedMessage id="language-selector.title" /></h1>
        <span className="icon icon-close" onClick={() => setChooseLanguage(false)} />
      </header>
      <ul className="language-selector-list">
        {languages.map((language) => (
          <li
            key={language}
            className={`language-item ${currentLang === language ? 'selected' : ''}`}
            onClick={() => handleLang(language)}
          >
            <img loading="lazy" src={languageImages[language]} alt={`${language} flag`} />
            <span className="text">{langChoices[language]}</span>
          </li>
        ))}
      </ul>
    </dialog>
  )
}

export default LanguageSelectorDialog
