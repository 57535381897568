import { gql } from '@apollo/client'
import dayjs from 'dayjs'

export const ONE_LP_INFO_CURRENT = (pairAddress: string) => {
  return gql`
    query pair{
      pair (id:"${pairAddress}") {
        reserveETH
        totalSupply
        __typename @skip(if: true)
      }
    }`
}

export const ONE_LP_INFO_FROM_BLOCK_NUMBER = (pairAddress: string, blockNumber: number): string => {
  return `
    query pair{
      pair(block: {number: ${blockNumber}}, id:"${pairAddress}") {
        reserveETH
        totalSupply
        __typename @skip(if: true)
      }
    }`
  }

  export const LP_RESERVE_TOTAL_SUPPLY_FROM_BLOCK_NUMBER = (pairAddress: string, blockNumber: number) => {
    return gql`
      query pair{
        pair(block: {number: ${blockNumber}}, id:"${pairAddress}") {
          reserveETH
          reserve0
          reserve1
          totalSupply
          token0 {
            symbol
          }
          token1 {
            symbol
            }
        }
      }`
    }

  export const GET_PAIR_TOKEN_BY_ADDRESS = (pairAddress: string): string => {
    return `
    query pair{
      pair(id:"${pairAddress}") {
        token0 {
          symbol
        }
        token1 {
          symbol
          }
        __typename @skip(if: true)
      }
    }`
  }

  export const LP_PAIR_INFO = (pairAddress: string, timestamp: number): string => {
    const segInOneDay: number = 86400
    const oneDayAgo = (Math.floor(dayjs().unix() / segInOneDay) - 1) * segInOneDay
    const date: number = (timestamp - segInOneDay) > oneDayAgo ? oneDayAgo : (timestamp - segInOneDay)
    return `
    query days {
      pairDayDatas(first: 1, where: { pairAddress_in: ["${pairAddress}"], date_gt: ${date}}) {
        pairAddress
        date
        reserve0
        reserve1
        dailyVolumeUSD
        reserveUSD
        totalSupply
        token0 {
            symbol
        }
        token1 {
            symbol
        }
      }
    }
  `
  }
