import React, { useState, useEffect, useMemo, Suspense } from 'react'
import { registerModal } from '@gluedigital/modal'
import { FormattedMessage } from 'react-intl'
import { FarmModalData, FarmMode, LPPair, FarmPeriod } from 'src/routes/RecipeDiagram/helpers/types'
import Loading from 'src/components/common/Loading/Loading'
import { BaseNodeModal } from "../BaseNodeModal"
import FarmInfo from './FarmInfo'
import featuredFarms from 'src/data/featuredFarm.json'
import useInfiniteScroll from 'src/hooks/useInfiniteScroll'
import { obtainAvg5DaysFarmsData } from 'src/api/farms/farmHelpers'
import { FarmGraphData, PairDayData, SushiSwapFarmsData } from 'src/api/farms/types'
import { useFarmsFiveDays, useFarmsOneDay } from 'src/api/recipes'
import { useSelector } from 'react-redux'
import { fantomFarmModalFilters, farmValidationCondition } from './farmModalHelper'
import { Networks } from 'src/utils/networkHelper'
import OrderBySelector from './OrderBySelector'
import HeaderFarmModal from './HeaderFarmModal'
import SearchBarFarmModal from './SearchBarFarmModal'

import './FarmModal.sass'

interface FarmModalPropTypes extends BaseNodeModal<FarmModalData> { }

const PAGE_SIZE = 9

const resumePair = (pair: any) => {
  return ({
    id: pair.id,
    symbols: `${pair.token0.symbol}-${pair.token1.symbol}`,
    token0: pair.token0,
    token1: pair.token1,
    provider: pair.provider,
    apr: pair.aprFarm + pair.aprFees
  })
}

const FarmModal = (props: FarmModalPropTypes) => {
  const [currentPage, setCurrentPage] = useState(1)
  const [showLoadMoreButton, setLoadMoreButton] = useState(true)
  const [chosenOption, setChosenOption] = useState(null)
  const [pairsFiveDaysAvg, setPairsFiveDaysAvg] = useState<PairDayData[]>([])
  const [sort, setSort] = useState<string>('APR')
  const [search, setSearch] = useState('')
  const [featuredPairs, setFeaturedPairs] = useState([])
  const [farmMode, setFarmMode] = useState<FarmMode>('autocompound')
  const [farmPeriod, setFarmPeriod] = useState<FarmPeriod>()
  const networkId: string = useSelector((s: any) => (s.network.network))
  const readyPairs: PairDayData[] = useMemo(() => {
    const searchText = search.toLowerCase()
    const updatedPairs: PairDayData[] = [...pairsFiveDaysAvg].filter((p) => {
      return (
        p.token0.id.toLowerCase().includes(searchText) ||
        p.token1.id.toLowerCase().includes(searchText) ||
        p.token0.symbol.toLowerCase().includes(searchText) ||
        p.token1.symbol.toLowerCase().includes(searchText)
      )
    })
    switch (sort) {
      case 'APR':
        updatedPairs.sort((a, b) => b.aprFarm + b.aprFees - (a.aprFarm + a.aprFees))
        break
      case 'LIQUIDITY':
        updatedPairs.sort((a, b) => Number(b.reserveUSD) - Number(a.reserveUSD))
        break
      case 'VOLUME':
        updatedPairs.sort((a, b) => Number(b.dailyVolumeUSD) - Number(a.dailyVolumeUSD))
        break
      case 'PROVIDER':
        updatedPairs.sort((a, b) => (a.provider > b.provider) ? 1 : ((b.provider > a.provider) ? -1 : 0))
    }
    return updatedPairs.slice(0, PAGE_SIZE * currentPage)
  }, [currentPage, pairsFiveDaysAvg, search, sort])

  const loadMoreHandler = () => {
    if (PAGE_SIZE * currentPage < pairsFiveDaysAvg.length) {
      setCurrentPage(currentPage => currentPage + 1)
    } else {
      setLoadMoreButton(false)
    }
  }
  const ref: any = useInfiniteScroll(loadMoreHandler)

  const fiveDaysPairsInfo: FarmGraphData = useFarmsFiveDays()
  useEffect(() => {
    if (fiveDaysPairsInfo !== undefined) {
      if (props?.previousData?.pair?.id) setChosenOption(props?.previousData?.pair?.id)
      const avgFarmsInfo: FarmGraphData = obtainAvg5DaysFarmsData(fiveDaysPairsInfo)
      const normalPairs: PairDayData[] = avgFarmsInfo.pools.data.filter((pair) => !featuredFarms.includes(pair.pairAddress))
      const securePairFiveDaysAvg: PairDayData[] = normalPairs.filter((pair) => farmValidationCondition(pair))
      let pairs: PairDayData[]
      if (networkId === Networks.fantom) pairs = fantomFarmModalFilters(securePairFiveDaysAvg)
      else pairs = securePairFiveDaysAvg
      setFeaturedPairs(avgFarmsInfo.pools.data.filter((pair) => featuredFarms.includes(pair.pairAddress)))
      setPairsFiveDaysAvg(pairs)
    }
  }, [fiveDaysPairsInfo, props?.previousData?.pair?.id, networkId])

  const saveHandler = () => {
    const pair: LPPair = {
      id: chosenOption.pair?.id,
      token0: chosenOption.pair?.token0.symbol,
      token1: chosenOption.pair?.token1.symbol,
      provider: chosenOption.pair?.provider
    }
    props.onSave({ pair, mode: farmMode, period: farmPeriod, expectedAPR: chosenOption.pair.apr })
  }

  return (
    <div className="farm-modal modal-content">
      <div className="modal-wrapper" ref={ref}>
        <HeaderFarmModal chosenOption={chosenOption} farmMode={farmMode} saveHandler={saveHandler} />
        <section className="main-section">
          <header>
            <SearchBarFarmModal setSearch={setSearch} />
            <OrderBySelector sort={sort} setSort={setSort} />
          </header>
          {pairsFiveDaysAvg.length !== 0 ? (
            <ul className="farm-list">
              {featuredPairs.map((pair, i) =>
                <FarmInfo
                  key={i}
                  pair={pair}
                  isChosen={chosenOption?.pair?.id === pair?.id || chosenOption === pair?.id}
                  farmMode={farmMode} setFarmMode={setFarmMode} farmPeriod={farmPeriod} setFarmPeriod={setFarmPeriod}
                  setChosenOption={(pair) => setChosenOption({ pair: resumePair(pair), autochoose: false })}
                  featured
                />
              )}
              {readyPairs.map((pair, i) =>
                <FarmInfo
                  key={i}
                  pair={pair}
                  isChosen={chosenOption?.pair?.id === pair?.id || chosenOption === pair?.id}
                  farmMode={farmMode} setFarmMode={setFarmMode} farmPeriod={farmPeriod} setFarmPeriod={setFarmPeriod}
                  setChosenOption={(pair) => setChosenOption({ pair: resumePair(pair), autochoose: false })}
                />
              )}
              {showLoadMoreButton &&
                <li className="load-more-wrapper">
                  <button className="load-more-button" onClick={loadMoreHandler}>
                    <FormattedMessage id="load-more" />
                  </button>
                </li>}
            </ul>
          ) : (
            <div className="table-body loading">
              <Loading />
            </div>
          )}
        </section>
      </div>
    </div>
  )
}

const FarmModalSushi = (props: FarmModalPropTypes) => {
  const [currentPage, setCurrentPage] = useState(1)
  const [showLoadMoreButton, setLoadMoreButton] = useState(true)
  const [chosenOption, setChosenOption] = useState(null)
  const [sort, setSort] = useState<string>('APR')
  const [search, setSearch] = useState('')
  const [farmMode, setFarmMode] = useState<FarmMode>('autocompound')
  const [farmPeriod, setFarmPeriod] = useState<FarmPeriod>()

  const farmsInfo: SushiSwapFarmsData[] = useFarmsOneDay()
  const readyPairs: SushiSwapFarmsData[] = useMemo(() => {
    const searchText = search.toLowerCase()
    const updatedPairs: SushiSwapFarmsData[] = [...farmsInfo].filter((p) => {
      return (
        p.token0.id.toLowerCase().includes(searchText) ||
        p.token1.id.toLowerCase().includes(searchText) ||
        p.token0.symbol.toLowerCase().includes(searchText) ||
        p.token1.symbol.toLowerCase().includes(searchText)
      )
    })
    switch (sort) {
      case 'APR':
        updatedPairs.sort((a, b) => b.aprFarmCurrent + b.aprFeesCurrent - (a.aprFarmCurrent + a.aprFeesCurrent))
        break
      case 'LIQUIDITY':
        updatedPairs.sort((a, b) => Number(b.reserveUSD) - Number(a.reserveUSD))
        break
      case 'VOLUME':
        updatedPairs.sort((a, b) => Number(b.dailyVolumeUSD) - Number(a.dailyVolumeUSD))
        break
      case 'PROVIDER':
        updatedPairs.sort((a, b) => (a.provider > b.provider) ? 1 : ((b.provider > a.provider) ? -1 : 0))
    }
    return updatedPairs.slice(0, PAGE_SIZE * currentPage)
  }, [currentPage, farmsInfo, search, sort])

  const loadMoreHandler = () => {
    if (PAGE_SIZE * currentPage < farmsInfo.length) {
      setCurrentPage(currentPage => currentPage + 1)
    } else {
      setLoadMoreButton(false)
    }
  }
  const ref: any = useInfiniteScroll(loadMoreHandler)

  const saveHandler = () => {
    const pair: LPPair = {
      id: chosenOption.pair?.id,
      token0: chosenOption.pair?.token0.symbol,
      token1: chosenOption.pair?.token1.symbol,
      provider: chosenOption.pair?.provider
    }
    props.onSave({ pair, mode: farmMode, period: farmPeriod, expectedAPR: chosenOption.pair.totalApr })
  }

  return (
    <div className="farm-modal modal-content">
      <div className="modal-wrapper" ref={ref}>
        <HeaderFarmModal chosenOption={chosenOption} farmMode={farmMode} saveHandler={saveHandler} />
        <section className="main-section">
          <header>
            <SearchBarFarmModal setSearch={setSearch} />
            <OrderBySelector sort={sort} setSort={setSort} />
          </header>
          {farmsInfo.length !== 0 ? (
            <ul className="farm-list">
              {readyPairs.map((pair, i) =>
                <FarmInfo
                  key={i}
                  pair={pair}
                  isChosen={chosenOption?.pair?.id === pair?.id || chosenOption === pair?.id}
                  farmMode={farmMode} setFarmMode={setFarmMode} farmPeriod={farmPeriod} setFarmPeriod={setFarmPeriod}
                  setChosenOption={(pair) => setChosenOption({ pair: resumePair(pair), autochoose: false })}
                />
              )}
              {showLoadMoreButton &&
                <li className="load-more-wrapper">
                  <button className="load-more-button" onClick={loadMoreHandler}>
                    <FormattedMessage id="load-more" />
                  </button>
                </li>}
            </ul>
          ) : (
            <div className="table-body loading">
              <Loading />
            </div>
          )}
        </section>
      </div>
    </div>
  )
}

const FarmModalWrapper = (props: FarmModalPropTypes) => {
  const networkId: string = useSelector((s: any) => (s.network.network))
  return (
    <Suspense fallback={<Loading />}>
      {networkId === Networks.fantom && <FarmModal {...props} />}
      {networkId === Networks.arbitrum && <FarmModalSushi {...props} />}
    </Suspense>
  )
}

registerModal('farm-modal', FarmModalWrapper)
