import React from "react"
import { FormattedMessage } from "react-intl"
import { useSelector } from "react-redux"
import { tokenAddressToName } from "src/components/Diagram/nodes/nodesLogsHelper"
import { getEtherStringAmountFromAddressFixed15, getTokenDecimals } from "src/contracts/TokensDecimalsHelpers"
import usePromise from "src/hooks/usePromise"
import { NodeExecutionResult } from "src/types"

interface EarnedYearnNestedModalLogProps {
  nodeEvents: NodeExecutionResult[]
  depositAmountWei: string
  depositPriceUSD: string
}

export const EarnedFinalYearnNestedModalLog = ({ nodeEvents, depositAmountWei, depositPriceUSD }: EarnedYearnNestedModalLogProps) => {
  const networkId: string = useSelector((s: any) => (s.network.network))
  const DECIMAL_USD_ROUNDED: number = 4

  const withdrawEvent: NodeExecutionResult = nodeEvents.find((event) => event.functionName === "withdrawFromNestedStrategy")
  const finalTokenAddress: string = withdrawEvent?.output?.token
  const vaultTokenDecimals: number = Number(usePromise(getTokenDecimals, finalTokenAddress))
  const finalTokenName: string = tokenAddressToName(finalTokenAddress, networkId)
  const depositAmountEther: string = usePromise(getEtherStringAmountFromAddressFixed15, finalTokenAddress, depositAmountWei)

  const endSwapEvent: NodeExecutionResult = nodeEvents.find((ev) => ev.functionName === 'swapTokens' && ev.input.token.toLowerCase() === finalTokenAddress.toLowerCase())
  const finalTotalInUSD: number = endSwapEvent.extraData?.outputTokenWithPrice.totalValueUSD
  const finalTokensAmountWei: number = endSwapEvent?.input?.amount
  const finalTokensAmountEth: string = usePromise(getEtherStringAmountFromAddressFixed15, finalTokenAddress, finalTokensAmountWei.toString())

  const earnedPriceUSD: number = finalTotalInUSD - parseFloat(depositPriceUSD)
  const earnedTokensAmountEth: number = parseFloat(finalTokensAmountEth) - parseFloat(depositAmountEther)

  return (
    <>
      {/* FINAL VALUE */}
      <div id="nested-log-field">
        <h3><FormattedMessage id="nested-modal-log.final-value" /></h3>
        <strong className="span-coin">$ {Number(finalTotalInUSD).toFixed(DECIMAL_USD_ROUNDED)} </strong>
        <div className="div-2column-lps">
          <span className="lp-amount-row first-column">{Number(finalTokensAmountEth).toFixed(vaultTokenDecimals === 18 ? 12 : vaultTokenDecimals)} <strong>{finalTokenName}</strong></span>
        </div>
      </div>
      {/* EARNED VALUE */}
      <div id="nested-log-field">
        <h3><FormattedMessage id="nested-modal-log.earned" /></h3>
        <strong className="span-coin">$ {Number(earnedPriceUSD).toFixed(DECIMAL_USD_ROUNDED)} </strong>
        <div className="div-2column-lps">
          <span className="lp-amount-row first-column">{Number(earnedTokensAmountEth).toFixed(vaultTokenDecimals === 18 ? 12 : vaultTokenDecimals)} <strong>{finalTokenName}</strong></span>
        </div>
      </div>
    </>
  )
}
