import React, { Suspense, useEffect, useMemo, useState } from "react"
import web3 from "src/utils/web3"
import { useSelector } from "react-redux"
import { useParams, useHistory, Redirect } from "react-router-dom"
import { FormattedMessage } from "react-intl"
import { useRecipeCRUD, useRecipeDetails, useRecipeEstimation } from "src/api/recipes"
import { useModal } from "@gluedigital/modal"
import { getRecipeSummary } from "src/routes/RecipeDiagram/helpers/nodeHelpers"
import SummaryList from "./SummaryList/SummaryList"
import OnlyDesktopVeil from "src/components/OnlyDesktopVeil/OnlyDesktopVeil"
import ApprovesList from "src/components/RecipeSummary/ApprovesList"
import DepositList from "src/components/RecipeSummary/DepositList"
import { ApprovalStatus, TokenForApproval } from "../RecipeDiagram/helpers/types"
import { checkEnoughTokenBalance, checkFantomBalance } from "src/contracts/ApproveHelpers"
import EstimateGasLabel from "src/components/RecipeSummary/EstimateGasLabel"
import { useRecipeLogsLive } from "src/context/SocketContext"
import LoadingBar from "src/components/common/Loading/LoadingBar"
import LoadingRecipeSummary from "./LoadingRecipeSummary"
import { formatRecipeTitle } from "../routesHelper"
import useTokensForApprove from "src/hooks/useTokensForApprove"
import ErrorBoundary from "src/components/ErrorBoundary/ErrorBoundary"
import { getNetworkParams } from "src/components/modals/SelectWalletModal/helpers"
import { Networks } from "src/utils/networkHelper"
import "src/components/modals/RecipeWorkingModal"
import "src/components/modals/WaitRecipeWorkingModal"
import "src/components/modals/RetryApprovalModal/RetryApprovalModal"
import "src/components/modals/CompleteModal"
import "./RecipeSummary.sass"

const RecipeSummary = () => {
  const { execute } = useRecipeCRUD()
  const user = useSelector((s: any) => s.user)
  const networkId: string = useSelector((s: any) => (s.network.network))
  const networkParams = getNetworkParams(networkId)
  const history = useHistory()
  const modal = useModal()
  const { id, recipeName } = useParams()
  const recipeDetails = useRecipeDetails(id)
  const summary: any[] = getRecipeSummary(recipeDetails.code)

  const [showBar, setShowBar] = useState<boolean>(false)
  const [completionPercentage, setCompletion] = useState<number>(0)
  const liveRecipeLogs = useRecipeLogsLive(id)

  const [fantomsForDeposit, initialTokensForApproval] = useTokensForApprove(id)
  const [hasInsufficientBalance, setTokensWithInsufficientBalance] = useState<TokenForApproval[]>([])
  const [tokensApprovalState, setTokensApprovalState] = useState<Map<String, ApprovalStatus>>(new Map())

  useEffect(() => {
    initialTokensForApproval.forEach((token) => {
      setTokensApprovalState(s => new Map(s.set(token.address, "ready")))
    })
  }, [initialTokensForApproval])

  const isAllApproved: boolean = useMemo(() => {
    const tokensNotApproved: TokenForApproval[] = initialTokensForApproval.filter((token) => tokensApprovalState?.get(token?.address) !== "approved")
    return tokensNotApproved.length <= 0
  }, [initialTokensForApproval, tokensApprovalState])

  // Used to know the token which the user hasn´t enough balance to approve them
  useEffect(() => {
    initialTokensForApproval.forEach((token: TokenForApproval) => {
      checkEnoughTokenBalance(user.account.account, token)
        .then((hasEnoughBalance: boolean) => {
          if (!hasEnoughBalance) {
            setTokensWithInsufficientBalance((insufficientBalanceTokens: TokenForApproval[]) => {
              const filteredTokens = insufficientBalanceTokens
                .filter((insufficientBalanceToken) => insufficientBalanceToken?.address !== token?.address)
                .concat(token)
              return [...filteredTokens]
            })
          }
        })
        .catch((e) => console.error('Error while checking token balance: ', e))
    })
  }, [user.account.account, initialTokensForApproval])

  useEffect(() => {
    if (fantomsForDeposit) {
      const native_token = networkParams.nativeCurrency.symbol
      checkFantomBalance(user.account.balance, fantomsForDeposit).then((hasEnoughBalance) => {
        if (!hasEnoughBalance) {
          setTokensWithInsufficientBalance((insufficientBalanceTokens) => {
            const filteredTokens = insufficientBalanceTokens
              .filter((insufficientBalanceToken) => insufficientBalanceToken.id !== native_token)
              .concat(fantomsForDeposit)
            return [...filteredTokens]
          })
        }
      })
        .catch((e) => console.error('Error while checking fantom balance: ', e))
    }
  }, [fantomsForDeposit, user.account.balance, networkParams])

  const goBackHandler = () => {
    const adaptTitle: string = formatRecipeTitle(recipeDetails.title)
    if (fantomsForDeposit) {
      modal.show('alert-modal', {
        bodyTextID: 'recipe-summary.pending-deposit',
        cancelHandler: () => {
          modal.hide()
        },
        confirmHandler: () => {
          modal.hide()
          history.push("/recipe/" + id + '/' + adaptTitle)
        }
      })
    } else {
      history.push("/recipe/" + id + '/' + adaptTitle)
    }
  }

  // Show the Progress bar in the modals of recipe working and recipe not working
  const [transactionHash, setTransactionHash] = useState<string>()

  useEffect(() => {
    if (liveRecipeLogs.length <= 0) {
      return
    }
    let loadBarTimeout
    let modalTimeout
    if (liveRecipeLogs[liveRecipeLogs.length - 1].logtype === 'fail') {
      setShowBar(false)
      modal.show('recipe-not-working-modal', { recipeID: id })
    } else if (liveRecipeLogs.length > 0) {
      setTransactionHash(liveRecipeLogs[0].trans)
      const eventCount = liveRecipeLogs.reduce((totalCount, currentLog) => totalCount + currentLog.events.length, 0)
      if (eventCount > 0) {
        loadBarTimeout = setTimeout(() => setCompletion(100), 1000)
        modalTimeout = setTimeout(() => {
          modal.show('recipe-working-modal', { recipeID: id, transactionHash })
          setShowBar(false)
        }, 4000)
      } else {
        setTransactionHash(liveRecipeLogs[0].trans)
      }
    }
    return () => {
      clearTimeout(loadBarTimeout)
      clearTimeout(modalTimeout)
    }
  }, [id, liveRecipeLogs, modal, transactionHash])

  // Show the Progress bar in the modals of recipe working and recipe not working
  useEffect(() => {
    if (!transactionHash) {
      return
    }
    let modalTimeout
    web3.eth.getTransactionReceipt(transactionHash) // returns null if tx is pending
      .then(res => {
        if (!res) {
          console.log('Transaction is pending')
          return
        }
        setCompletion(100)
        modalTimeout = setTimeout(() => {
          modal.show('recipe-working-modal', { recipeID: id })
          setShowBar(false)
        }, 3000)
      })
      .catch(err => {
        console.error('Error on transaction: ', err)
        modal.show('recipe-not-working-modal', { recipeID: id })
      })
    return () => clearTimeout(modalTimeout)
  }, [id, modal, transactionHash])
  const handleDeposit = () => {
    setShowBar(true)
    execute(id).catch(() => setShowBar(false))
  }

  const gasEstimation = useRecipeEstimation(isAllApproved ? id : '')

  const adaptTitle: string = formatRecipeTitle(recipeDetails.title)
  if (recipeDetails) {
    const canonical: string = `/recipe-summary/${recipeDetails?.id}/${adaptTitle}`
    if (recipeName && recipeName !== adaptTitle) return <Redirect to={canonical} />
  }

  return (
    <div id="recipe-summary" className="page">
      <a className="back" onClick={goBackHandler}>
        <span className="icon icon-arrow-right" />
        <span>
          <FormattedMessage id="recipe-summary.back" />
        </span>
      </a>
      {!showBar && <h1>
        <FormattedMessage id="recipe-summary.title" />
      </h1>}
      {showBar && <LoadingBar completionPercentage={completionPercentage} />}
      {transactionHash &&
        <span className="transaction-link-container"><FormattedMessage id="recipe-summary.transaction-link" /><a
          className="transaction-link"
          href={`${networkParams.blockExplorerUrls}tx/${transactionHash}`}
          target="_blank"
           rel="noreferrer noopener">{networkId === Networks.fantom ? "FTMScan" : "ARBScan"}</a>
          <span className="transaction-status-container">
            <FormattedMessage id="recipe-summary.transaction-pending" />
            <span className="status-icon" />
          </span>
        </span>
      }
      {!fantomsForDeposit && <EstimateGasLabel result={gasEstimation} />}
      <SummaryList summary={summary} />
      {isAllApproved && !fantomsForDeposit && (gasEstimation && gasEstimation.estimationAmount !== 'error')
        ? <div className="complete-card">
          <h2>
            <FormattedMessage id="recipe-summary.complete-card.title" />
          </h2>
          <p>
            <FormattedMessage id="recipe-summary.complete-card.text" />
          </p>
          <button disabled={showBar} onClick={() => {
            setShowBar(true)
            execute(id).catch(() => setShowBar(false))
          }}>
            <FormattedMessage id="recipe-summary.complete-card.complete" />
          </button>
        </div>
        : <ErrorBoundary>
          <div className="recipe-summary-approvals">
            {initialTokensForApproval.length > 0
              && <ApprovesList
                tokens={initialTokensForApproval}
                tokensState={tokensApprovalState}
                isAllApproved={isAllApproved}
                setTokensState={setTokensApprovalState}
                hasInsufficientBalance={hasInsufficientBalance.length > 0}
              />
            }
            {fantomsForDeposit
              && <DepositList
                amount={fantomsForDeposit.amount}
                isAllApproved={isAllApproved}
                hasInsufficientBalance={hasInsufficientBalance.length > 0}
                handleDeposit={handleDeposit}
                // estimationSuccess={gasEstimation !== 'loading' && gasEstimation !== 'failed'}
                estimationSuccess={true}
                recipeId={recipeDetails?.id}
              />
            }
          </div>
        </ErrorBoundary>
      }
      <OnlyDesktopVeil />
    </div>
  )
}

const RecipeSummaryWrapper = (props) => (
  <Suspense fallback={<LoadingRecipeSummary {...props} />}>
    <RecipeSummary {...props} />
  </Suspense>
)

export default RecipeSummaryWrapper
