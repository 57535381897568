import { FormattedMessage } from "react-intl"
import React from "react"
import { YearnSummaryVaultsData } from "src/api/yearnVaults/yearnTypes"
import { ReaperSummaryVaultsData } from "src/api/reaperFinance/reaperTypes"
import { NestedStrategiesModalData } from "src/routes/RecipeDiagram/helpers/types"
import { CloseModal } from '@gluedigital/modal'

export const HeaderNestedStrategiesModal = ({ chosenOption, previousData, onSave }: { chosenOption: any, previousData: NestedStrategiesModalData, onSave: (any) => void }) => {
  return (
    <header className="header-buttons">
      <CloseModal>
        <button className="modal-close-button">
          <span className="icon icon-close" />
          <span><FormattedMessage id="close" /></span>
        </button>
      </CloseModal>
      <h1><FormattedMessage id="nested-strategies-modal.title" />
      </h1>
      <CloseModal>
        <button
          className="filled"
          onClick={() => {
            if (chosenOption.provider === "Yearn") {
              if (previousData?.reaper) delete previousData.reaper
              onSave({ yearn: chosenOption as YearnSummaryVaultsData })
            }
            if (chosenOption.provider === "Reaper") {
              if (previousData?.yearn) delete previousData.yearn
              onSave({ reaper: chosenOption as ReaperSummaryVaultsData })
            }
          }

          }>
          <FormattedMessage id="save" />
        </button>
      </CloseModal>
    </header>
  )
}

export const SortSelectorNestedStrategies = ({ sort, setSort }: { sort: string, setSort: (s: string) => void }) => {
  return (
    <div className="select-wrapper">
      <select defaultValue={sort} onChange={(e) => setSort(e.target.value)}>
        <FormattedMessage id="nested-strategies-modal.sort-by-apy">
          {(text) => <option value="APY">{text}</option>}
        </FormattedMessage>
        <FormattedMessage id="nested-strategies-modal.sort-by-total-assets">
          {(text) => <option value="TOTAL ASSETS">{text}</option>}
        </FormattedMessage>
        <FormattedMessage id="deposit-lp-modal.sort-by-provider">
          {(text) => <option value="PROVIDER">{text}</option>}
        </FormattedMessage>
      </select>
    </div>
  )
}
