import React, { Suspense } from "react"
import { FormattedMessage } from "react-intl"
import { useFarmsOneDay, useLastSubgraphBlockFromApi } from "src/api/recipes"
import { FarmNodeData } from "src/routes/RecipeDiagram/helpers/types"
import { ViewRecipeLogsProps } from "../ViewRecipeLogs/ViewRecipeLogs"
import { useAuxLPEarned } from "src/hooks/useFarmsLogs"
import { FarmGraphData, PairDayData, PairInfoSpooky, SushiSwapFarmsData } from "src/api/farms/types"
import { useGetLPInfo } from "src/hooks/useLPsLogs"
import { useBlockNumberFromTxHash } from "src/hooks/useWeb3"
import { DepositedFarmLeftLog, LoadingDepositFarmLeftLog } from './DepositedFarmLeftLog'
import { EarnedFarmLeftLog, LoadingEarnedLeftLog } from "./FarmMCV2LeftLog/EarnedFarmLeftLog"
import { TokenEndFarmLeftLog } from "./TokenEndFarmLeftLog"
import { FinishedFarmLeftLog } from "./FarmMCV2LeftLog/FinishedFarmLeftLog"
import { EarnedFarmV3LeftLog } from "./FarmMCV3LeftLog/EarnedFarmV3LeftLog"
import { FinishedFarmV3LeftLog } from "./FarmMCV3LeftLog/FinishedFarmV3LeftLog"
import { AprFarmV3LeftLog } from "./FarmMCV3LeftLog/AprFarmV3LeftLog"
import { FarmLeftLogErrorBoundary } from "src/components/ErrorBoundary/FarmLeftLogErrorBoundary"
import web3 from "src/utils/web3"
import { saneBigNumber } from "src/utils/bigNumberHelper"
import { useSelector } from "react-redux"
import { Networks } from "src/utils/networkHelper"
import { getDepositFarmInfo, getWithdrawFarmInfo } from "./farmLogsHelper"
import { nodesLogsNumber } from "src/constants"
import FinishedFarmInfoLeftLog from "./Arbitrum/FinishedFarmInfoLeftLog"
import EarnedFarmInfoLeftLog from "./Arbitrum/EarnedFarmInfoLeftLog"
import 'src/routes/RecipeDiagram/SideBarViewLogs/SidebarViewLogs.sass'

interface FarmExtraDataLogProps extends ViewRecipeLogsProps {
  data: FarmNodeData
}

const FarmExtraDataLogFantom = (props: FarmExtraDataLogProps) => {
  const { events, data, trans, recipeStatus } = props
  const networkId: string = useSelector((s: any) => (s.network.network))
  const { ACTIVE_NODE, FINISHED_NODE, ABORTED_NODE } = nodesLogsNumber
  const myFarmId: string = data.pair.id
  const myFarmAddress = myFarmId.slice(0, -6)

  const graphLastBlock: number = useLastSubgraphBlockFromApi()
  const { token0, token1, depositEvent, executionSteps } = getDepositFarmInfo(events, networkId)
  const blockNumberAtOtherMoment: number = useBlockNumberFromTxHash(trans, executionSteps)
  const { withdrawEvent, blockNumberAtOtherMoment: block } = getWithdrawFarmInfo(recipeStatus, events, executionSteps, graphLastBlock)
  const blockToUse: number = block > graphLastBlock ? graphLastBlock : block
  const allFarms: FarmGraphData = useFarmsOneDay()
  const masterchefInfo: PairDayData = allFarms.pools.data.find((pair) => pair.pairAddress === myFarmAddress)
  const myFarm: PairInfoSpooky = useGetLPInfo(myFarmAddress, blockToUse, networkId)
  const liquidity = `${myFarm.reserveUSD.split('.')[0]}.${myFarm.reserveUSD.split('.')[1].slice(0, 2)}`
  const shares: string = depositEvent?.extraData?.ttAmount
  const lpDepositedWei: number = Number(depositEvent?.extraData?.outputTokenWithPrice?.amount)
  const lpEarnedEther = useAuxLPEarned(myFarm?.token0?.symbol, myFarm?.token1?.symbol, shares, lpDepositedWei.toString(), executionSteps, networkId, data?.contracts?.strategy)
  const lpDepositedEther: string = web3.utils.fromWei(saneBigNumber(lpDepositedWei).toString(), 'ether')
  const totalLPAmount: number = Number(lpDepositedEther) + Number(lpEarnedEther)
  const lpDepositUSDValue: number = Number(depositEvent?.extraData?.outputTokenWithPrice?.totalValueUSD)

  return (
    <div className="div-extra-info">
      <div className="first-column">
        <div className="logs-group-rows">
          <p><FormattedMessage id="farm-modal-log.deposited" /></p>
          <div className="left-empty-position"></div>
        </div>
        {executionSteps === ACTIVE_NODE &&
          <div className="logs-group-rows">
            <p><FormattedMessage id="farm-modal-log.current-value" /></p>
            <div className="left-empty-position"></div>
            <div className="left-empty-position"></div>
          </div>
        }
        {(executionSteps === FINISHED_NODE || executionSteps === ABORTED_NODE) &&
          <div className="logs-group-rows">
            <p><FormattedMessage id="farm-modal-log.final-value" /></p>
            <div className="left-empty-position"></div>
          </div>
        }
        <div className="logs-group-rows">
          <p><FormattedMessage id="farm-modal-log.earned" /></p>
          <div className="left-empty-position"></div>
        </div>
        <p><FormattedMessage id="farm-modal-log.token0" /></p>
        <p><FormattedMessage id="farm-modal-log.token1" /></p>
        <p><FormattedMessage id="farm-modal-log.apr" /></p>
        <p><FormattedMessage id="farm-modal-log.liquidity" /></p>
      </div>
      <Suspense fallback={"Loading ..."}>
        <div className="second-column">
          <FarmLeftLogErrorBoundary type="deposited" >
            <div className="logs-group-rows">
              <Suspense fallback={<LoadingDepositFarmLeftLog />}>
                <DepositedFarmLeftLog lpUSDValueAtDeposit={lpDepositUSDValue} lpDeposited={Number(lpDepositedEther)} />
              </Suspense>
            </div>
          </FarmLeftLogErrorBoundary>
          <FarmLeftLogErrorBoundary type="current-earned" >
            <Suspense fallback={<LoadingEarnedLeftLog recipeStatus={recipeStatus} />}>
              {executionSteps === ACTIVE_NODE &&
                (masterchefInfo.poolIdV3 !== null || masterchefInfo.poolIdV3 === 0 ?
                  <EarnedFarmV3LeftLog
                    nodeId={events[0].nodeID}
                    shares={shares}
                    masterchefInfo={masterchefInfo}
                    lpUSDValueAtDeposit={lpDepositUSDValue}
                    lpDeposited={Number(lpDepositedEther)}
                    totalLpAmount={totalLPAmount}
                  /> :
                  <EarnedFarmLeftLog
                    lpUSDValueAtDeposit={lpDepositUSDValue}
                    masterchefInfo={masterchefInfo}
                    lpDeposited={Number(lpDepositedEther)}
                    totalLpAmount={totalLPAmount}
                    nodeId={events[0].nodeID}
                  />
                )
              }
            </Suspense>
          </FarmLeftLogErrorBoundary>
          <FarmLeftLogErrorBoundary type="final-earned" >
            <Suspense fallback={<LoadingEarnedLeftLog recipeStatus={recipeStatus} />}>
              {(executionSteps === FINISHED_NODE || executionSteps === ABORTED_NODE) &&
                (masterchefInfo.poolIdV3 || masterchefInfo.poolIdV3 === 0 ?
                  <FinishedFarmV3LeftLog
                    lpUSDValueAtDeposit={lpDepositUSDValue}
                    lpDeposited={Number(lpDepositedEther)}
                    totalLPAmount={totalLPAmount}
                    withdrawEvent={withdrawEvent}
                  /> :
                  <FinishedFarmLeftLog
                    withdrawEvent={withdrawEvent}
                    lpDeposited={Number(lpDepositedEther)}
                    lpUSDValueAtDeposit={lpDepositUSDValue}
                    totalLPAmount={totalLPAmount}
                  />
                )
              }
            </Suspense>
          </FarmLeftLogErrorBoundary>
          <Suspense fallback="Loading token0 amount" >
            <TokenEndFarmLeftLog blockNumberAtOtherMoment={blockNumberAtOtherMoment} myFarmId={myFarmId} tokenName={token0?.token} totalLP={totalLPAmount} />
          </Suspense>
          <Suspense fallback="Loading token1 amount" >
            <TokenEndFarmLeftLog blockNumberAtOtherMoment={blockNumberAtOtherMoment} myFarmId={myFarmId} tokenName={token1?.token} totalLP={totalLPAmount} />
          </Suspense>
          <FarmLeftLogErrorBoundary type="apr" >
            <Suspense fallback="Loading APR">
              <AprFarmV3LeftLog masterchefInfo={masterchefInfo} blockNumberAtOtherMoment={blockNumberAtOtherMoment} myFarm={myFarm} />
            </Suspense>
          </FarmLeftLogErrorBoundary>
          <p>{parseFloat(liquidity).toLocaleString()} $</p>
        </div>
      </Suspense>
    </div>
  )
}

const FarmExtraDataLogArbitrum = (props: FarmExtraDataLogProps) => {
  const { events, data, recipeStatus } = props
  const { ACTIVE_NODE, FINISHED_NODE, ABORTED_NODE } = nodesLogsNumber
  const networkId: string = useSelector((s: any) => (s.network.network))
  const myFarmAddress: string = data.pair.id
  const { token0, token1, depositEvent, executionSteps } = getDepositFarmInfo(events, networkId)
  const { withdrawEvent } = getWithdrawFarmInfo(recipeStatus, events, executionSteps)
  const farmsInfo: SushiSwapFarmsData[] = useFarmsOneDay()
  const myFarm: SushiSwapFarmsData = farmsInfo.find(f => f.id === myFarmAddress)
  const { reserveUSD: liquidity, totalApr: apr } = myFarm

  const shares: string = depositEvent?.extraData?.ttAmount
  const slpDepositedWei: number = Number(depositEvent?.extraData?.outputTokenWithPrice?.amount)
  const slpDepositUSDValue: number = Number(depositEvent?.extraData?.outputTokenWithPrice?.totalValueUSD)
  const slpDepositedEther: number = parseFloat(web3.utils.fromWei(saneBigNumber(slpDepositedWei).toString(), 'ether'))
  const slpEarnedEther = useAuxLPEarned(myFarm?.token0?.symbol, myFarm?.token1?.symbol, shares, slpDepositedWei.toString(), executionSteps, networkId, data?.contracts?.strategy)
  const totalSLPAmount: number = slpDepositedEther + Number(slpEarnedEther)

  return (
    <div className="div-extra-info">
      <div className="first-column">
        <div className="logs-group-rows">
          <p><FormattedMessage id="farm-modal-log.deposited" /></p>
          <div className="left-empty-position"></div>
        </div>
        {executionSteps === ACTIVE_NODE &&
          <div className="logs-group-rows">
            <p><FormattedMessage id="farm-modal-log.current-value" /></p>
            <div className="left-empty-position"></div>
            {myFarm.rewards.map(r => <div key={r.name} className="left-empty-position"></div>)}
          </div>
        }
        {(executionSteps === FINISHED_NODE || executionSteps === ABORTED_NODE) &&
          <div className="logs-group-rows">
            <p><FormattedMessage id="farm-modal-log.final-value" /></p>
            <div className="left-empty-position"></div>
          </div>
        }
        <div className="logs-group-rows">
          <p><FormattedMessage id="farm-modal-log.earned" /></p>
          <div className="left-empty-position"></div>
        </div>
        <p><FormattedMessage id="farm-modal-log.token0" /></p>
        <p><FormattedMessage id="farm-modal-log.token1" /></p>
        <p><FormattedMessage id="farm-modal-log.apr" /></p>
        <p><FormattedMessage id="farm-modal-log.liquidity" /></p>
      </div>
      <Suspense fallback={"Loading ..."}>
        <div className="second-column">
          <FarmLeftLogErrorBoundary type="deposited" >
            <div className="logs-group-rows">
              <p className="main-text-logs">$ {(slpDepositUSDValue).toFixed(3)}</p>
              <p>{slpDepositedEther.toFixed(12)} SLPs</p>
            </div>
          </FarmLeftLogErrorBoundary>
          {executionSteps === ACTIVE_NODE &&
            <FarmLeftLogErrorBoundary type="current-earned" >
              <Suspense fallback={<LoadingEarnedLeftLog recipeStatus={recipeStatus} />}>
                <EarnedFarmInfoLeftLog nodeId={events[0].nodeID} depositedSLPValueUSD={slpDepositUSDValue} depositedSLPEther={slpDepositedEther} totalSLPAmount={totalSLPAmount} myFarm={myFarm} />
              </Suspense>
            </FarmLeftLogErrorBoundary>
          }
          {(executionSteps === FINISHED_NODE || executionSteps === ABORTED_NODE) &&
            <FarmLeftLogErrorBoundary type="final-earned" >
              <Suspense fallback={<LoadingEarnedLeftLog recipeStatus={recipeStatus} />}>
                <FinishedFarmInfoLeftLog depositedSLPValueUSD={slpDepositUSDValue} depositedSLPEther={slpDepositedEther} totalSLPAmount={totalSLPAmount} withdrawEvent={withdrawEvent} />
              </Suspense>
            </FarmLeftLogErrorBoundary>
          }
          <Suspense fallback="Loading token0 amount" >
            <p>{token0?.amount} {token0?.token}</p>
          </Suspense>
          <Suspense fallback="Loading token1 amount" >
            <p>{token1?.amount} {token1?.token}</p>
          </Suspense>
          <FarmLeftLogErrorBoundary type="apr" >
            <>
              <p>{apr.toFixed(2) || 'Loading...'} % </p>
            </>
          </FarmLeftLogErrorBoundary>
          <p>{parseFloat(liquidity).toLocaleString()} $</p>
        </div>
      </Suspense>
    </div>
  )
}

const FarmExtraDataLog = (props: FarmExtraDataLogProps) => {
  const networkId: string = useSelector((s: any) => s.network.network)
  if (networkId === Networks.fantom || networkId === Networks.hardhat) return <FarmExtraDataLogFantom {...props} />
  else if (networkId === Networks.arbitrum) return <FarmExtraDataLogArbitrum {...props} />
}

export default FarmExtraDataLog
