import React, { Suspense } from 'react'
import { useSelector } from 'react-redux'
import { registerModal, useModal } from '@gluedigital/modal'
import { FormattedMessage } from 'react-intl'
import { Recipe, RecipeDetails, RecipeExecutionLog, TemporaryExecutionStatus } from 'src/types'
import { useRecipeCRUD, useRecipeLogs, useRecipePerformance } from 'src/api/recipes'
import TokenList from 'src/components/TokenList/TokenList'
import Spinner from 'src/components/common/Loading/Spinner/Spinner'
import ErrorBoundary from 'src/components/ErrorBoundary/ErrorBoundary'
import Loading from 'src/components/common/Loading/Loading'
import ExecutionBlocker from 'src/components/ExecutionBlocker/ExecutionBlocker'
import { useRecipeDetailsLive } from 'src/context/SocketContext'

import './WithdrawModal.sass'
import WithdrawOutputTable from './WithdrawOutputTable/WithdrawOutputTable'

interface WithdrawModalProps {
  recipe: Recipe
}

const MiniApr = ({ id }: { id: number }) => {
  const { performance } = useRecipePerformance(id)
  const apr: number = +performance?.apr?.toFixed(2)
  return (
    <div className={`apr ${apr > 10 ? 'success' : 'faliure'}`}>
      <em><FormattedMessage id="mobile-card.strategy-info.vapy" values={{ n: apr }} /></em>
    </div>
  )
}

const MiniEarned = ({ id }: { id: number }) => {
  const { performance } = useRecipePerformance(id)
  const earned: string = performance?.earned?.toFixed(3)
  return (
    <div className={`earned ${+earned > 0 ? 'success' : 'faliure'}`}>
      <em><FormattedMessage id="mobile-card.recipe-info.earned" values={{ n: earned }} /></em>
    </div>
  )
}

const MiniAmount = ({ id }: { id: number }) => {
  const { performance } = useRecipePerformance(id)
  let totalAmountUSD: number = 0
  performance.blockedTokens.forEach((branch) => { totalAmountUSD += Number(branch.totalValueUSD) })
  const stringTotalAmount = totalAmountUSD.toFixed(3)

  return (
    <div className={`amount ${stringTotalAmount.length > 7 ? 'mini' : ''}`}>
      <span>$</span>
      <strong>{stringTotalAmount}</strong>
    </div>
  )
}

const WithdrawModal = ({ recipe }: WithdrawModalProps) => {
  const { title, id } = recipe
  const modal = useModal()
  const recipeCRUD = useRecipeCRUD()
  const handleConfirm = async () => {
    await recipeCRUD.abort(id)
  }

  return (
    <>
      <header>
        <button onClick={() => modal.hide()} className="cancel-button">
          <span className="icon icon-close" />
          <span><FormattedMessage id="cancel" /></span>
        </button>
        <h1><FormattedMessage id="mobile-withdraw-modal.title" /></h1>
      </header>
      <section id="info-section">
        <div className="wrapper">
          <h2>{title}</h2>
          <div className="total-amount-card">
            <h3><FormattedMessage id="mobile-withdraw-modal.total-amount-title" /></h3>
            <ErrorBoundary fallback={<div className="amount" />}>
              <Suspense fallback={<Spinner />}>
                <MiniAmount id={id} />
              </Suspense>
            </ErrorBoundary>
            <ErrorBoundary fallback={<div className="earned" />}>
              <Suspense fallback={<Spinner />}>
                <MiniEarned id={id} />

              </Suspense>
            </ErrorBoundary>
          </div>
        </div>
        <div className="row">
          <ErrorBoundary fallback={<div className="apr" />}>
            <Suspense fallback={<Spinner />}>
              <MiniApr id={id} />
            </Suspense>
          </ErrorBoundary>
          <ErrorBoundary fallback={<div />}>
            <Suspense fallback={<Spinner />}>
              <TokenList tokenList={recipe.tokenList} />
            </Suspense>
          </ErrorBoundary>
        </div>
      </section>
      <ErrorBoundary fallback={<div className="withdraw-output-table" />}>
        <Suspense fallback={<div className="withdraw-output-table"><Spinner /></div>}>
          <WithdrawOutputTable id={+id} />
        </Suspense>
      </ErrorBoundary>

      <footer>
        {/* <button className="execute-button" onClick={() => alert('Coming soon')}>
          <FormattedMessage id="execute-all" />
        </button> */}
        <button className="confirm-button" onClick={() => handleConfirm() as any}>
          <FormattedMessage id="confirm" />
        </button>
      </footer>
    </>
  )
}

const WithdrawalBlocker = ({ id }: { id: any }) => {
  const modal = useModal()
  const logs: RecipeExecutionLog[] = useRecipeLogs(+id)
  return (
    <>
      <header>
        <button onClick={() => modal.hide()} className="cancel-button">
          <span className="icon icon-close" />
          <span><FormattedMessage id="close" /></span>
        </button>
      </header>
      <ErrorBoundary fallback={<div id="execution-blocker" />}>
        <Suspense fallback={<Loading />}>
          <ExecutionBlocker recipeId={id} startingAction="Aborting" staticLogs={logs} />
        </Suspense>
      </ErrorBoundary>
    </>
  )
}

const WithdrawModalWrapper = ({ recipe }: WithdrawModalProps) => {
  const modal = useModal()
  const temporaryStatusFromMap: TemporaryExecutionStatus = useSelector((s: any) => s.blockers.recipesState.get(recipe?.id))
  const recipeDetailsLive: RecipeDetails = useRecipeDetailsLive(recipe.id.toString())

  return (
    <div className="mobile-withdraw-modal modal-content">
      <ErrorBoundary fallback={<header>
        <button onClick={() => modal.hide()} className="cancel-button">
          <span className="icon icon-close" />
          <span><FormattedMessage id="close" /></span>
        </button>
      </header>}>
        <Suspense fallback={<Loading />}>
          {(temporaryStatusFromMap === 'Aborting' || recipeDetailsLive?.status === "finished") && <WithdrawalBlocker id={recipe.id} />}
          {!temporaryStatusFromMap && !recipeDetailsLive && <WithdrawModal recipe={recipe} />}
        </Suspense>
      </ErrorBoundary>
    </div>
  )
}

registerModal('mobile-withdraw-modal', WithdrawModalWrapper)
