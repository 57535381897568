import React from "react"
import { MobileBrowseFilterProps } from "../types"
import MarkdownMessage from '@gluedigital/markdown-message'
import VerifiedCheck from "src/components/VerifiedCheck/VerifiedCheck"

import './MobileFilterBrowse.sass'

const MobileFilterBrowse = ({ showOnlyVerified, handleShowOnlyVerified }: MobileBrowseFilterProps) => {
  const handleFilter = () => handleShowOnlyVerified(!showOnlyVerified)
  return (
    <div
      onClick={handleFilter}
      className={`mobile-filter-browse ${showOnlyVerified ? 'active' : ''}`}
    >
      <span><VerifiedCheck colors={!showOnlyVerified ? { check: 'white', bg: '#C2BECC' } : { check: '#00B412', bg: 'white' }} /></span>
      <span><MarkdownMessage id="mobile-filter-browse" /></span>
    </div>
  )
}

export default MobileFilterBrowse
