import React from 'react'
import { FormattedMessage } from 'react-intl'
import { useModal } from "@gluedigital/modal";
import { BaseNodeData, NodeModalData, NodeType } from 'src/routes/RecipeDiagram/helpers/types';

import './Buttons.sass'
import { RecipeDetails } from 'src/types';
import { useNodesDispatch } from 'src/routes/RecipeDiagram/helpers/nodesAndEdgesContext';

interface ButtonsProps {
  data?: BaseNodeData
  id?: string
  editModalType?: string
  viewDetailsModalType?: string
  recipeLogs?: any
  recipeDetails?: RecipeDetails
  compareAddress?: boolean
  nodeType?: NodeType
}

const Buttons = ({ data, id, editModalType, viewDetailsModalType, recipeLogs, recipeDetails, compareAddress, nodeType }: ButtonsProps) => {
  const modal = useModal();
  const nodesDispatch = useNodesDispatch()
  // Case 1: edit & delete --> the owner or not combo.
  // RecipeDetails === undefined
  // Recipe status === draft || recipe status === ready
  // Case 2: view details
  // Recipe Logs length > 0
  // Recipe status === active || recipe status === finished
  // Case 3: not the owner but is a comboTrigger --> We can see the viewDetails info
  // Rest of the scenarios

   if ((compareAddress || nodeType !== "comboTriggerNode") && editModalType && (!recipeDetails || recipeDetails.status === 'draft' || recipeDetails.status === 'ready')) {
    return <div className="node-buttons">
      <button className="delete" onClick={() => nodesDispatch({ type: 'removeNode', nodeID: id })}>
        <span className="icon icon-close" />
        <span className="text"> <FormattedMessage id="delete" /></span>
      </button>
      <div>
        <button onClick={() => modal.show(editModalType, { onSave: (extradata: NodeModalData) => nodesDispatch({ type: 'editNode', nodeID: id, updatedData: extradata }), previousData: data })}>
          <span className="text"> <FormattedMessage id="edit" /></span>
        </button>
      </div>
    </div>
  } else if (viewDetailsModalType && recipeLogs.length > 0 && (recipeDetails.status === 'active' || recipeDetails.status === 'finished' || recipeDetails.status === 'failed')) {
    return <div className="node-buttons">
      <div>
        <button onClick={() => modal.show(viewDetailsModalType, { previousData: { data, id, recipeLogs, recipeDetails } })}>
          <span className="text"> <FormattedMessage id="node-buttons.view-details" /></span>
        </button>
      </div>
    </div>
  } else if (!compareAddress && nodeType === "comboTriggerNode") {
    return <div className="node-buttons">
      <div>
        <button onClick={() => modal.show(viewDetailsModalType, { previousData: { data, id, recipeLogs, recipeDetails } })}>
          <span className="text"> <FormattedMessage id="node-buttons.view-details" /></span>
        </button>
      </div>
    </div>
  } else {
    return <></>
  }
}

export default Buttons
