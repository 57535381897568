export const setInput = (inputCondition, inputRange, setInputRange, inputConditionValue, setInputConditionValue) => {
  const formatter = new Intl.NumberFormat('en-EN', { maximumFractionDigits: 16 })
  let _value = inputCondition.current.value
  if (_value.slice(_value.length - 1) === '.') {
    for (let x = 0; x < _value.length - 1; x++) if (_value.substring(x, x + 1) === '.') return
    setInputConditionValue(_value)
    setInputRange([inputCondition.current.selectionStart, inputCondition.current.selectionEnd])
    return
  }
  _value = _value.replace(/,/g, '')
  const isDec = _value.split(".").length > 1
  if (isNaN(parseFloat(_value))) {
    _value = '0'
    setInputRange([inputRange[0] + 1, inputRange[1] + 1])
  } else if ((parseInt(_value).toString().length - 1) % 3 === 0) {
    setInputRange([inputCondition.current.selectionStart + 1, inputCondition.current.selectionEnd + 1])
  } else {
    setInputRange([inputCondition.current.selectionStart, inputCondition.current.selectionEnd])
  }
  setInputConditionValue(formatter.format(parseFloat(_value)))
  if (isDec && _value.length > 2 && _value.slice(_value.length - 1) === '0') {
    setInputConditionValue(_value)
  }
  const value = parseFloat(_value)
  const dif = formatter.format(parseFloat(_value)).length - inputConditionValue.toString().length - 1
  if (dif > 0) {
    setInputRange([inputCondition.current.selectionStart + dif, inputCondition.current.selectionEnd + dif])
  }
  return value
}
