import { format } from "date-fns"
import React from "react"
import { FormattedMessage } from "react-intl"
import { SECONDS_IN_A_DAY, SECONDS_IN_A_MONTH, SECONDS_IN_A_WEEK } from "src/constants"
import { ComboTriggerModalData, ConditionProvider, TimeTriggerData } from "src/routes/RecipeDiagram/helpers/types"
import { useTriggerTimeInput } from "src/components/modals/nodeModals/ComboTriggerModal/hooks/useTriggerTimeInput"

import "./TimePerformanceType.sass"

interface TimePerformanceTypeProps {
  triggerData: TimeTriggerData
  setTriggerData: (data: ComboTriggerModalData) => void
}

const TimePerformanceType = ({ setTriggerData, triggerData }: TimePerformanceTypeProps) => {
  const value: number = triggerData?.condition?.value
  const timeComparison = triggerData?.condition?.timeComparison
  const conditionProvider = ConditionProvider.time
  const isExact = !timeComparison || timeComparison === "exact"
  const isPeriod = !timeComparison || timeComparison === "period"

  const {
    getCurrentDate,
    dateChange,
    hourChange,
    dateToShowForExactType
  } = useTriggerTimeInput(value)

  const handleDate = e => {
    const date = dateChange(e)
    setTriggerData({ ...triggerData, condition: { timeComparison: "exact", value: date.getTime() }, conditionProvider })
  }
  const handleHour = e => {
    const date = hourChange(e)
    setTriggerData({ ...triggerData, condition: { timeComparison: "exact", value: date.getTime() }, conditionProvider })
  }
  const handlePeriod = data => {
    setTriggerData({ ...triggerData, condition: { timeComparison: "period", value: data }, conditionProvider })
  }

  return (
    <div className="performance-type performance-type-time">
      <div className="wrapper">
        <section className={`time-section ${isPeriod ? 'selected' : ''}`}>
          <h2><FormattedMessage id="timeframe-after" /></h2>
          <div className="time-frames">
            <button
              className={`timeframe-24h  ${value === SECONDS_IN_A_DAY ? 'selected' : ''}`}
              onClick={() => handlePeriod(SECONDS_IN_A_DAY)}
            >
              <FormattedMessage id="timeframe-24h" />
            </button>
            <button
              className={`timeframe-7d  ${value === SECONDS_IN_A_WEEK ? 'selected' : ''}`}
              onClick={() => handlePeriod(SECONDS_IN_A_WEEK)}
            >
              <FormattedMessage id="timeframe-7d" />
            </button>
            <button
              className={`timeframe-1m  ${value === SECONDS_IN_A_MONTH ? 'selected' : ''}`}
              onClick={() => handlePeriod(SECONDS_IN_A_MONTH)}
            >
              <FormattedMessage id="timeframe-1m" />
            </button>
          </div>
        </section>
        <section className={`time-section ${isExact ? 'selected' : ''}`}>
          <h2><FormattedMessage id="timeframe-on" /></h2>
          <div className="time-frames">
            <label>
              <input
                type="date"
                name="date"
                value={format(dateToShowForExactType, 'yyyy-MM-dd')}
                onChange={handleDate}
                min={getCurrentDate()}
              />
            </label>
            <label>
              <input
                type="time"
                name="time"
                value={format(dateToShowForExactType, 'HH:mm')}
                onChange={handleHour}
                placeholder="00:00"
              />
            </label>
          </div>
        </section>
      </div>
    </div>
  )
}

export default TimePerformanceType
