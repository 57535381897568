import React from 'react'
import { FormattedMessage } from 'react-intl'
import { useRecipePerformance } from 'src/api/recipes'
import { Recipe, RecipePerformance, PerformanceInfo } from 'src/types'

import './StrategyInfo.sass'

const StrategyInfo = ({ recipe }: {recipe: Recipe}) => {
  const id: number = recipe?.id
  const recipeIdIfNotApr: number = recipe?.performance?.apr ? null : id
  const resultPerformance: RecipePerformance = useRecipePerformance(recipeIdIfNotApr)
  const performance: PerformanceInfo = !resultPerformance ? recipe?.performance : resultPerformance?.performance
  const roi: number = +((performance?.roi * 100).toFixed(2))

  return (
    <section className="strategy-info">
      <div className={`item item-apr ${roi > 0 ? 'success' : 'faliure'}`}>
        <FormattedMessage id="mobile-card.strategy-info.pnl" values={{ n: roi }} />
      </div>
    </section>
  )
}

export default StrategyInfo
