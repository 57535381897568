import { RecipeStatus } from "src/types"

export type StepMobile = "compose" | "check-balance" | "approve" | "approve-confirmation" | "ftm-deposit" | "execution" | "quoting"
export type StepMobileState = "done" | "running" | "failed" | "retrying"

export enum DepositSteps {
  composed = 1,
  balanceChecked = 2,
  approved = 3,
  executed = 4
}

export enum FailSteps {
  insufficientBalance = 2,
  rejectedApprove = 3,
  estimationFailed = 3.5,
  executionReverted = 4
}
export interface StepExecutionMobile {
  step: StepMobile
  state: StepMobileState
}

export interface ShowStepMessageProps {
  stepName: StepMobile
  state: StepMobileState
  failStep?: FailSteps
}

export interface ShowMessageExecutionRevertedProps {
  recipeStatus: RecipeStatus
  retryCount: number
}
