import React, { Suspense, useState } from 'react'
import ErrorBoundary from "src/components/ErrorBoundary/ErrorBoundary"
import { FormattedMessage } from "react-intl"
import MobileDepositHeader from './MobileDepositHeader/MobileDepositHeader'
import TokenSelector from 'src/components/TokenSelector/TokenSelector'
import ConfigComboTrigger from 'src/components/ConfigComboTrigger/ConfigComboTrigger'
import { useParams, useHistory } from 'react-router-dom'
import Loading from 'src/components/common/Loading/Loading'
import { useRecipeCRUD, useRecipeDetails } from 'src/api/recipes'
import { RecipeDetails } from "src/types"
import { getWeiNumberAmountDependOnDecimals } from 'src/contracts/TokensDecimalsHelpers'
import { addNewAddFundsMobile, getAddFundsAmount, propagateAddFundsCoinChanges, removeOldAddFundsMobile, updateContractAddress } from '../helpers/mobileDepositHelper'
import { checkEnoughTokenBalance, checkFantomBalance, depositFTM } from 'src/contracts/ApproveHelpers'
import { useSelector } from 'react-redux'
import { tokenNameToAddress } from 'src/components/Diagram/nodes/nodesLogsHelper'
import { TokenForApproval } from 'src/routes/RecipeDiagram/helpers/types'
import DepositStepList from '../../../components/DepositStepList/DepositStepList'
import { checkIfTokenIsApproved, simpleTokenApprove } from '../helpers/simpleAddFundsApproveHelpers'
import { addNewComboConfig } from '../helpers/addNewComboConfig'
import MobileDepositFooter from './MobileDepositFooter/MobileDepositFooter'
import { getNetworkParams } from 'src/components/modals/SelectWalletModal/helpers'
import { Networks } from 'src/utils/networkHelper'
import { DepositSteps, FailSteps } from 'src/components/DepositStepList/types'
import './MobileDeposit.sass'
import Spinner from 'src/components/common/Loading/Spinner/Spinner'

interface MobileDepositFormProps {
  previousData
  combosInfo: Map<string, any>
  tokenSelected: boolean
  setTokenSelected: (s: boolean) => void
  setSearchMode: (s: boolean) => void
  searchMode: boolean
  setStep: (s: number) => void
  step: number
  setFailStep: (s: number) => void
  setExecutionClicked: (e: boolean) => void
  setApprovedClicked: (a: boolean) => void
  executionClicked: boolean
  networkCoin: string
  quoted: boolean
  failStep: number
}

const MobileDepositForm = ({
  previousData,
  combosInfo,
  tokenSelected,
  setTokenSelected,
  setSearchMode,
  searchMode,
  setStep,
  step,
  setFailStep,
  setExecutionClicked,
  setApprovedClicked,
  executionClicked,
  networkCoin,
  quoted,
  failStep
}: MobileDepositFormProps) => {
  const user = useSelector((s: any) => s.user)
  const userAddress = user.account.account
  const networkId: string = useSelector((s: any) => (s.network.network))
  const networkParams = getNetworkParams(networkId)
  const recipeCRUD = useRecipeCRUD()
  const history = useHistory()
  const { id } = useParams()
  const recipeDetails = useRecipeDetails(id)

  const forkAndUpdateRecipeModel = async () => {
    let recipeToUse = recipeDetails
    if (userAddress !== '0x' + recipeDetails.owner || !(recipeDetails.status === 'ready' || recipeDetails.status === 'draft')) {
      const forkedRecipe: RecipeDetails = await recipeCRUD.fork(id)
      recipeToUse = forkedRecipe
    }
    const code = recipeToUse.code
    const oldCode = [...code]
    const originalAddFundsToken: string = code.find((el) => el.type === "addFundsNode").data?.outputCoin
    const weiAmount = await getWeiNumberAmountDependOnDecimals(previousData?.outputCoin, previousData?.amount, networkId)
    const codeWithoutAddFunds = removeOldAddFundsMobile(oldCode)
    const newCode = addNewAddFundsMobile(code, codeWithoutAddFunds, weiAmount, previousData?.outputCoin)
    const newCodeWithContracts: any[] = updateContractAddress(newCode, networkParams)
    const newCodeWithCombos: any[] = addNewComboConfig(combosInfo, newCodeWithContracts)
    const updatedNewCode: any[] = originalAddFundsToken !== previousData.outputCoin
      ? propagateAddFundsCoinChanges(newCodeWithCombos)
      : newCodeWithCombos

    const newRecipeUpdated = await recipeCRUD.update(recipeToUse.id, {
      title: "Unnamed Recipe",
      short: recipeToUse.short,
      extended: recipeToUse.extended,
      code: updatedNewCode,
      public: recipeToUse.public,
      status: 'ready',
    })
    return newRecipeUpdated
  }
  const handleNetWorkToken = async (e) => {
    e.preventDefault()
    if (!tokenSelected) {
      setTokenSelected(true)
      setSearchMode(false)
    } else {
      const updatedRecipe = await forkAndUpdateRecipeModel()
      history.push('/mobile/deposit/' + updatedRecipe?.id)
      setStep(DepositSteps.composed)
      const tokenForApproval: TokenForApproval = {
        amount: await getWeiNumberAmountDependOnDecimals(previousData?.outputCoin, previousData?.amount, networkId),
        address: tokenNameToAddress(previousData.outputCoin, networkId),
        id: previousData.outputCoin
      }
      const enoughFTMBalance: boolean = await checkFantomBalance(user.account.balance, tokenForApproval)
      if (!enoughFTMBalance) {
        setFailStep(FailSteps.insufficientBalance)
        return
      }
      setStep(DepositSteps.balanceChecked)
      try {
        await depositFTM(userAddress, updatedRecipe?.id, getAddFundsAmount(updatedRecipe), networkParams)
        setStep(DepositSteps.approved)
      } catch (e) {
        setFailStep(FailSteps.rejectedApprove)
        console.error(e.message)
        return
      }
      await recipeCRUD.execute(+updatedRecipe?.id)
    }
  }
  const handleToken = async (e) => {
    e.preventDefault()
    if (!tokenSelected) {
      setTokenSelected(true)
      setSearchMode(false)
    } else {
      const updatedRecipe = await forkAndUpdateRecipeModel()
      history.push('/mobile/deposit/' + updatedRecipe?.id)
      setStep(DepositSteps.composed)
      const tokenForApproval: TokenForApproval = {
        amount: await getWeiNumberAmountDependOnDecimals(previousData?.outputCoin, previousData?.amount, networkId),
        address: tokenNameToAddress(previousData.outputCoin, networkId),
        id: previousData.outputCoin
      }
      const enoughTokenBalance: boolean = await checkEnoughTokenBalance(userAddress, tokenForApproval)
      if (!enoughTokenBalance) {
        setFailStep(FailSteps.insufficientBalance)
        return
      }
      setStep(DepositSteps.balanceChecked)
      const isApproved: boolean = await checkIfTokenIsApproved(userAddress, tokenForApproval, networkParams)
      if (isApproved) setStep(DepositSteps.approved)
      else await simpleTokenApprove(userAddress, tokenForApproval, setStep, setFailStep, setApprovedClicked, networkParams)
    }
  }

  const executeRecipe = async (e) => {
    e.preventDefault()
    if (step === DepositSteps.approved) {
      setExecutionClicked(true)
      await recipeCRUD.execute(+id)
    }
  }
  const chooseFunction = async (e) => {
    if (previousData.outputCoin === networkCoin) return await handleNetWorkToken(e)
    else {
      if (step < 3) return await handleToken(e)
      else return await executeRecipe(e)
    }
  }
  return (
    <form id="mobile-deposit-form" onSubmit={e => chooseFunction(e) as any}>
      <ErrorBoundary fallback={<FormattedMessage id="error" />}>
        <Suspense fallback={
          <footer id="mobile-deposit-colophon">
            <Spinner />
          </footer>
        }
        >
          <MobileDepositFooter
            step={step}
            searchMode={searchMode}
            previousData={previousData}
            tokenSelected={tokenSelected}
            quoted={quoted}
            executionClicked={executionClicked}
            failStep={failStep}
          />
        </Suspense>
      </ErrorBoundary>
    </form>
  )
}

const MobileDeposit = () => {
  const networkId: string = useSelector((s: any) => (s.network.network))
  const networkCoin: string = networkId === Networks.fantom ? "FTM" : "ETH"
  const defaultData = { outputCoin: networkCoin, amount: null }
  const [tokenSelected, setTokenSelected] = useState<boolean>(false)
  const [previousData, setPreviousData] = useState(defaultData)
  const [searchMode, setSearchMode] = useState<boolean>(!tokenSelected && previousData?.amount && !!previousData?.outputCoin)
  const [step, setStep] = useState<number>(0)
  const [failStep, setFailStep] = useState<number>(undefined)
  const [approvedClicked, setApprovedClicked] = useState<boolean>(false)
  const [executionClicked, setExecutionClicked] = useState<boolean>(false)
  const [combosInfo, setCombosInfo] = useState<Map<string, any>>(new Map())
  const [quoted, setQuoted] = useState(false)

  const handleMode = (mode: boolean) => {
    tokenSelected && setTokenSelected(false)
    if (step > 0 || failStep) {
      setStep(0)
      setFailStep(undefined)
      setQuoted(false)
    }
    return setSearchMode(mode)
  }
  return (
    <div id="mobile-deposit" className="page">
      <MobileDepositHeader
        comeBackToHome={failStep === 4 || step === 4}
        disabled={step > 0 && !failStep}
        comeBack={handleMode}
        isComeback={tokenSelected}
      />
      <div className="mobile-container">
        <div className={`mobile-deposit-wrapper ${tokenSelected ? 'token-selected' : ''}`}>
          <TokenSelector
          blocked={step > 0 && !failStep}
          setSearchMode={handleMode}
          searchMode={searchMode}
          previousData={previousData}
          onChange={setPreviousData}
        />
        {tokenSelected &&
          <div className="mobile-deposit-steps">
            {step > 0
              ? <DepositStepList
                step={step}
                failStep={failStep}
                setStep={setStep}
                setFailStep={setFailStep}
                addFundsCoin={previousData.outputCoin}
                approvedClicked={approvedClicked}
                executionClicked={executionClicked}
                setQuoted={setQuoted}
              />
              : <ErrorBoundary fallback={<FormattedMessage id="error" />}>
                <Suspense fallback={<Loading />}>
                  <ConfigComboTrigger combosInfo={combosInfo} setCombosInfo={setCombosInfo} />
                </Suspense>
              </ErrorBoundary>
            }
          </div>
        }
        <ErrorBoundary fallback={<FormattedMessage id="error" />}>
          <Suspense fallback={
            <form id="mobile-deposit-form">
              <Spinner />
            </form>
          }
          >
            <MobileDepositForm
              step={step}
              searchMode={searchMode}
              previousData={previousData}
              tokenSelected={tokenSelected}
              quoted={quoted}
              executionClicked={executionClicked}
              failStep={failStep}
              setApprovedClicked={setApprovedClicked}
              setExecutionClicked={setExecutionClicked}
              combosInfo={combosInfo}
              setTokenSelected={setTokenSelected}
              setSearchMode={setSearchMode}
              setStep={setStep}
              setFailStep={setFailStep}
              networkCoin={networkCoin}
            />
          </Suspense>
        </ErrorBoundary>
        </div>
      </div>
    </div>
  )
}

export default MobileDeposit
