import React from 'react'
import { FormattedMessage } from 'react-intl'

import './APRSideViewDescription.sass'

export const APRSideViewDescriptionFallback = () => {
  return (
    <div className="apr-side-descrption negative apr-side-fallback">
      <span><FormattedMessage id="apr-details.apr-info" />
        <FormattedMessage id="recipe-item.apr-error" />
      </span>
    </div>
  )
}
