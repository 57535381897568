import React, { Suspense } from 'react'
import { FormattedMessage } from 'react-intl'
import { getTokenImage } from 'src/api/farms/helpers'
import { ReaperSummaryVaultsData, lpProviderReaper } from 'src/api/reaperFinance/reaperTypes'
import Loading from 'src/components/common/Loading/Loading'
import { nodesLogsNumber } from 'src/constants'
import DepositedReaperNestedModalLog from './DepositedReaperNestedModalLog'
import EarnedCurrentReaperNestedModalLog from './EarnedCurrentReaperNestedModalLog'
import EarnedFinalReaperNestedModalLog from './EarnedFinalReaperNestedModalLog'
import { NodeExecutionResult } from 'src/types'
import { getEventsForNode } from 'src/api/yearnVaults/yearnHelpers'
import { tokenAddressToName } from 'src/components/Diagram/nodes/nodesLogsHelper'
import { NodeToken } from 'src/routes/RecipeDiagram/helpers/types'
import { useSelector } from 'react-redux'
import { getNetworkParams } from 'src/components/modals/SelectWalletModal/helpers'

import '../NestedStrategiesModalLog.sass'
import { Networks } from 'src/utils/networkHelper'

interface ReaperNestedModalLogProps {
  oldVaultData: ReaperSummaryVaultsData
  previousData: any
}

const ReaperNestedModalLog = ({ oldVaultData, previousData }: ReaperNestedModalLogProps) => {
  const networkId: string = useSelector((s: any) => (s.network.network))
  const networkParams = getNetworkParams(networkId)
  const scanName: string = networkId === Networks.fantom ? "ftmscan.com" : "arbscan.com"

  let executionSteps: number
  const infoDepositAmountWei: NodeToken = { token: '', amount: '' }
  let depositPriceUSD: string
  let rfTokensDepositedWei: string
  let tokenDepositedName: string
  let nodeEvents: NodeExecutionResult[]
  const recipeLogs = previousData.recipeLogs
  const lpProvider: lpProviderReaper = oldVaultData?.lpProvider
  if (recipeLogs !== null) {
    nodeEvents = getEventsForNode(recipeLogs, previousData.id)
    executionSteps = nodeEvents.length
    if (lpProvider === "Beets") {
      const swapDepositEvent: NodeExecutionResult = nodeEvents.find((event) => event.functionName === 'swapTokens')
      depositPriceUSD = swapDepositEvent?.extraData?.outputTokenWithPrice?.totalValueUSD === "0.000000000"
        ? swapDepositEvent?.extraData?.inputTokenWithPrice?.totalValueUSD
        : swapDepositEvent?.extraData?.outputTokenWithPrice?.totalValueUSD
      const BeetsPoolDepositEvent: NodeExecutionResult = nodeEvents.find((event) => event.functionName === "depositOnLp")
      const nestedDepositEvent: NodeExecutionResult = nodeEvents.find((event) => event.functionName === "depositOnNestedStrategy")
      rfTokensDepositedWei = nestedDepositEvent?.output?.amount.toString()
      tokenDepositedName = "BPT"
      infoDepositAmountWei.amount = BeetsPoolDepositEvent?.output?.amount
      infoDepositAmountWei.token = BeetsPoolDepositEvent?.output?.token
    } else if (lpProvider === "Spooky") {
      const splitDepositEvent: NodeExecutionResult = nodeEvents.find((event) => event.functionName === 'split')
      depositPriceUSD = splitDepositEvent.extraData?.inputTokenWithPrice?.totalValueUSD
      const depositEvent: NodeExecutionResult = nodeEvents.find((event) => event.functionName === "depositOnNestedStrategy")
      rfTokensDepositedWei = depositEvent?.output?.amount.toString()
      const spookyPoolDepositEvent: NodeExecutionResult = nodeEvents.find((event) => event.functionName === "depositOnLp")
      tokenDepositedName = "LP"
      infoDepositAmountWei.amount = spookyPoolDepositEvent?.output?.amount
      infoDepositAmountWei.token = spookyPoolDepositEvent?.output?.token
    } else if (!lpProvider) {
      if (executionSteps === nodesLogsNumber.ACTIVE_NODE) executionSteps += 1
      else if (executionSteps === nodesLogsNumber.FINISHED_NODE || executionSteps === nodesLogsNumber.ABORTED_NODE) executionSteps += 2
      const swapDepositEvent: NodeExecutionResult[] = nodeEvents.filter((event) => event.functionName === 'swapTokens')
      depositPriceUSD = swapDepositEvent[0]?.extraData?.outputTokenWithPrice?.totalValueUSD === "0.000000000"
        ? swapDepositEvent[0]?.extraData?.inputTokenWithPrice?.totalValueUSD
        : swapDepositEvent[0]?.extraData?.outputTokenWithPrice?.totalValueUSD
      infoDepositAmountWei.amount = swapDepositEvent[0]?.output?.amount
      infoDepositAmountWei.token = swapDepositEvent[0]?.output?.token
      const depositEvent: NodeExecutionResult = nodeEvents.find((event) => event.functionName === "depositOnNestedStrategy")
      rfTokensDepositedWei = depositEvent?.output?.amount
      tokenDepositedName = tokenAddressToName(swapDepositEvent[0]?.output?.token.toLowerCase(), networkId)
    }
  }
  return (
    <main id="main-nested-strategies-modal-log">
      <div className="farm-chosen-div">
        <div className="div-tokens">
          {oldVaultData.token.map((token) =>
            <span className="div-token-images" key={token?.name}>
              <img className="logo" src={getTokenImage(token?.name)} />
            </span>)
          }
          <span className="span-token-deposit">{oldVaultData.name}</span>
        </div>
        <div className="div-link-ftmscan" >
          <a className="link-ftmscan"
            href={`${networkParams.blockExplorerUrls}/address/${oldVaultData.address}`}
            target="_blank"
            rel="noreferrer noopener">
            <FormattedMessage id="farm-modal.view-contract" /> <strong>{scanName}</strong></a>
        </div>
      </div>
      <section className="nested-strategies-modal-log-data">
        <Suspense fallback={<Loading />} >
          <DepositedReaperNestedModalLog
            oldVaultData={oldVaultData}
            depositPriceUSD={depositPriceUSD}
            amountRFTokensDepositedWei={rfTokensDepositedWei}
            tokenDepositedName={tokenDepositedName}
            infoDepositAmountWei={infoDepositAmountWei} />
        </Suspense>

        {executionSteps === nodesLogsNumber.REAPER_ACTIVE_NODE ?
          <>
            <Suspense fallback={<Loading />} >
              <EarnedCurrentReaperNestedModalLog
                oldVaultData={oldVaultData}
                tokenDepositedName={tokenDepositedName}
                depositPriceUSD={depositPriceUSD}
                rfTokensDepositedWei={rfTokensDepositedWei}
                infoDepositAmountWei={infoDepositAmountWei}
              />
            </Suspense>
          </>
          :
          <>
            <Suspense fallback={<Loading />} >
              <EarnedFinalReaperNestedModalLog
                oldVaultData={oldVaultData}
                nodeEvents={nodeEvents}
                depositPriceUSD={depositPriceUSD}
                amountRFTokensDepositedWei={rfTokensDepositedWei}
                infoDepositAmountWei={infoDepositAmountWei}
                tokenDepositedName={tokenDepositedName}
              />
            </Suspense>
          </>
        }
        <div id="nested-strategy-data-1">
          <h3><FormattedMessage id="nested-strategies-modal.APY" /></h3>
          <strong className="span-coin">{(oldVaultData.apy).toFixed(2)} %</strong>
        </div>
        <div id="nested-strategy-data-2">
          <h3><FormattedMessage id={"farm-modal-log.liquidity"} /></h3>
          <strong className="span-coin"> $ {oldVaultData?.tvl.toLocaleString()}</strong>
        </div>
      </section>
    </main>
  )
}

export default ReaperNestedModalLog
