import { BigNumber, BigNumberish } from 'ethers'
import { saneBigNumber } from 'src/utils/bigNumberHelper'
import { PositionDataInContract } from './mummyTypes'
import web3 from 'src/utils/web3'
import mummyVaultABI from 'src/contracts/abis/mummyFinance/Vault.json'
import mummyVaultUtilsABI from 'src/contracts/abis/mummyFinance/VaultUtils.json'
import { AbiItem } from 'web3-utils'
import { mummyVaultAddress, mummyVaultUtilsAddress } from 'src/constants'

// FUNCTIONS OBTAINED HERE:
// https://github.com/gmx-io/gmx-interface/blob/180ec836ece56457301f62e7cdf8fcd0dfec5f6f/src/lib/numbers.ts#L3
// https://github.com/gmx-io/gmx-interface/blob/180ec836ece56457301f62e7cdf8fcd0dfec5f6f/src/lib/legacy.ts#L96
// https://github.com/gmx-io/gmx-interface/blob/180ec836ece56457301f62e7cdf8fcd0dfec5f6f/src/lib/positions/getLiquidationPrice.ts#L12

export const expandDecimals = (n: BigNumberish, decimals: number): BigNumber => {
  return BigNumber.from(n).mul(BigNumber.from(10).pow(decimals));
}
export const BASIS_POINTS_DIVISOR = 10000
export const USD_DECIMALS = 30
export const LIQUIDATION_FEE = expandDecimals(5, USD_DECIMALS) // $5
export const FUNDING_RATE_PRECISION = 1000000
export const MAX_LEVERAGE = 125 * BASIS_POINTS_DIVISOR

export const MARGIN_FEE_BASIS_POINTS = 10;

export const getMarginFee = (sizeDelta) => {
  if (!sizeDelta) {
    return BigNumber.from(0);
  }
  const afterFeeUsd = sizeDelta.mul(BASIS_POINTS_DIVISOR - MARGIN_FEE_BASIS_POINTS).div(BASIS_POINTS_DIVISOR);
  return sizeDelta.sub(afterFeeUsd);
}
// WIP
export const getEntryFundingRate = async (collateralTokenAddress: string, indexTokenAddress: string, isLong: boolean, mummyVaultUtils) => {
  const entryFundingRate: number = await mummyVaultUtils.methods.getEntryFundingRate(collateralTokenAddress, indexTokenAddress, isLong).call()
  return entryFundingRate
}

export const getFundingFee = async (collateralTokenAddress: string, indexTokenAddress: string, isLong: boolean, size: number, networkId: string): Promise<number> => {
  const mummyVaultUtils = new web3.eth.Contract(mummyVaultUtilsABI as AbiItem[], mummyVaultUtilsAddress[networkId])
  const entryFundingRate = await getEntryFundingRate(collateralTokenAddress, indexTokenAddress, isLong, mummyVaultUtils)
  const saneSize = saneBigNumber(size * 1e30)
  const fundingFee: number = await mummyVaultUtils.methods.getFundingFee(collateralTokenAddress, collateralTokenAddress, collateralTokenAddress, isLong, saneSize, entryFundingRate).call()
  return fundingFee
}

export const getPositionFromMummyVault = async (firstTypePerpContract: string, collateralTokenAddress: string, indexTokenAddress: string, isLong: boolean, networkId: string): Promise<PositionDataInContract> => {
  const mummyVault = new web3.eth.Contract(mummyVaultABI as AbiItem[], mummyVaultAddress[networkId])
  const position = await mummyVault.methods.getPosition(firstTypePerpContract, collateralTokenAddress, indexTokenAddress, isLong).call()
  const size: string = position["0"]
  const collateral: string = position["1"]
  const averagePrice: string = position["2"]
  const entryFundingRate: string = position["3"]
  const reserveAmount: string = position["4"]
  const realisedPNL: string = position["5"]
  const realisedPNLHigherEqual0: boolean = position["6"]
  const lastIncreasedTime: string = position["7"]
  const positionData = { size, collateral, averagePrice, entryFundingRate, reserveAmount, realisedPNL, realisedPNLHigherEqual0, lastIncreasedTime }
  return positionData
}
