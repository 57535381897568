import { useState, useEffect } from "react"
import { useParams } from "react-router-dom"
import { useRecipeLogsLive } from "src/context/SocketContext"
import { NodeExecutionResult, RecipeExecutionLog } from "src/types"
import { auxEvents } from "./useLogsWithTokenDecimals"

export const useLiveLogsWithTokenDecimals = (): RecipeExecutionLog[] => {
  const [recipeLiveLogsDecimal, setRecipeLiveLogsDecimals] = useState<RecipeExecutionLog[]>([])

  const { id } = useParams()
  const recipeLiveLogs = useRecipeLogsLive(id)

  useEffect(() => {
    const logsPromises = recipeLiveLogs.map(async (log: RecipeExecutionLog): Promise<RecipeExecutionLog> => {
      const events: NodeExecutionResult[] = await Promise.all(log.events.map(auxEvents))
      return { ...log, events }
    })
    Promise.all(logsPromises.map(async (p: Promise<RecipeExecutionLog>) => await p.catch((e) => {
      console.log("Error in sidebar liveLogs promise", e.message)
      return e
    })))
      .then((results) => {
        const liveLogsFullFilled = results.filter(r => !(r instanceof Error));
        // const rejected = results.filter(r => r instanceof Error);
        setRecipeLiveLogsDecimals(liveLogsFullFilled)
      })
      .catch(e => console.log("Final error in sidebarLogs with live logs", e.message))
  }, [recipeLiveLogs])
  return recipeLiveLogsDecimal
}
