import { changeChain, checkNetwork } from '../helpers'
import web3 from 'src/utils/web3'

declare const ethereum: any

export const setup = async () => {
  const hasWallet = typeof ethereum !== "undefined"
  if (!hasWallet) {
    throw new Error('MetaMask is not installed!')
  } else {
    if (!checkNetwork(ethereum.chainId)) {
      await changeChain()
    }
    web3.setProvider(ethereum)
  }
  return ethereum
}
