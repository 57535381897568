import React, { useEffect, useRef } from "react"
import { FormattedMessage } from 'react-intl'
import { MobileBrowseInputProps } from "../types"

import './MobileInputBrowse.sass'

const MobileInputBrowse = ({ handleSearch, searchTerm }: MobileBrowseInputProps) => {
  const inputRef = useRef<HTMLInputElement>(null)
  useEffect(() => {
    inputRef.current.focus()
  }, [searchTerm])

  return (
    <div className="mobile-input-browse">
      <FormattedMessage id="placeholder.search">
        {(text: string) => (
          <input
            ref={inputRef}
            type="search"
            onChange={handleSearch}
            placeholder={text}
          />
        )}
      </FormattedMessage>
      <span className="icon icon-search" />
    </div>
  )
}

export default MobileInputBrowse
