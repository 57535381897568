interface SigningParams {
  id: string
  method: string
  params: any
}

const getSigningParams = (networkParams: any, timestamp: number, address: string): SigningParams[] => {
  const domain = window.location.host
  const proto = window.location.protocol

  return [
    // --- Sign-In With Ethereum ---
    {
      id: 'siwe',
      method: 'personal_sign',
      params: [
        domain + ' wants you to sign in with your Ethereum account:',
        address,
        '',
        'This will verify you as the account holder. This operation does not cost anything.',
        '',
        'URI: ' + proto + '//' + domain,
        'Version: 1',
        'Chain ID: ' + parseInt(networkParams.chainId),
        'Nonce: 12345678',
        'Issued At: ' + new Date(timestamp).toISOString(),
      ].join('\n')
    },

    // --- signTypedData_v4 ---
    {
      id: 'v4',
      method: 'eth_signTypedData_v4',
      params: JSON.stringify({
        domain: {
          chainId: networkParams.chainId,
          name: networkParams.chainName,
          version: "1",
        },
        message: {
          logints: "Tortle-Login-" + timestamp,
        },
        primaryType: "LoginTs",
        types: {
          EIP712Domain: [
            { name: "name", type: "string" },
            { name: "version", type: "string" },
            { name: "chainId", type: "uint256" },
          ],
          LoginTs: [{ name: "logints", type: "string" }],
        },
      })
    },
  ]
}

export default getSigningParams
