import React from "react"
import { FormattedMessage } from "react-intl"
import { useSelector } from "react-redux"
import { DepositShortLongDataInfo } from "src/api/mummyFinance/mummyTypes"
import { tokenNameToAddress } from "src/components/Diagram/nodes/nodesLogsHelper"
import { useLiquidationPrice } from "src/hooks/usePerpetuals"
import { ShortLongNodeData } from "src/routes/RecipeDiagram/helpers/types"
import { Networks } from "src/utils/networkHelper"

interface LiquidationPriceLeftLogProps {
    isLong: boolean
    depositShortLongData: DepositShortLongDataInfo
    data: ShortLongNodeData
}
const LiquidationPriceLeftLog = ({ isLong, depositShortLongData, data }: LiquidationPriceLeftLogProps) => {
    const networkId: string = useSelector((s: any) => (s.network.network))
    const tokenForCollateralInShortByNetwork: string = networkId === Networks.arbitrum ? "USDT" : "USDC_AXL"
    const collateralTokenAddress: string = !isLong ? tokenNameToAddress(tokenForCollateralInShortByNetwork, networkId) : tokenNameToAddress(data?.operationCoin, networkId)
    const liquidationPrice: string = useLiquidationPrice(isLong, depositShortLongData.firstTypePerpContractAddress, collateralTokenAddress, tokenNameToAddress(data?.operationCoin, networkId))
    const liqPrice: number = parseFloat(liquidationPrice)

    return <p className="main-text-logs">  {!isNaN(liqPrice) ? liqPrice?.toFixed(3) : <FormattedMessage id="waiting-for-data" />} $</p>
}

export default LiquidationPriceLeftLog
