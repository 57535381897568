import React from "react";
import { FormattedMessage } from "react-intl"
import { CloseModal } from "@gluedigital/modal"
import { ModalErrorBoundary } from "src/components/ErrorBoundary/ErrorBoundary"
import Loading from "src/components/common/Loading/Loading"
import { DepositLogModalProps } from "./DepositModalLog"
import { DepositModalLogProvider, DepositModalLogsPoolSelected } from "./DepositModalLogHelpers";
import "./DepositModalLog.sass"

export const LoadingDepositModalLog = (props: DepositLogModalProps) => {
  const { previousData } = props
  const pair = previousData?.data?.pair
  return (
    <div className="swap-modal modal-content">
      <div className="modal-wrapper">
        <header className="header-buttons">
          <CloseModal>
            <button>
              <span className="icon icon-close" />
              <span><FormattedMessage id="close" /></span>
            </button>
          </CloseModal>
          <h1><FormattedMessage id="deposit-lp-modal.title" /></h1>
          <h4><FormattedMessage id="loading" /></h4>
        </header>
        <div className="deposit-modal-log-wrapper">
          <div className="main-div">
            <DepositModalLogsPoolSelected pair={pair} />
            <div>
              <ModalErrorBoundary>
                <section className="farm-modal-currency">
                  <Loading />
                </section>
              </ModalErrorBoundary>
            </div>
          </div>
          <DepositModalLogProvider pair={pair} />
        </div>
      </div>
    </div>
  )
}
