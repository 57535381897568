import React from "react"
import { FormattedMessage } from 'react-intl'
import Browse from "./Browse/Browse"
import verified from "../../../static/images/verified.svg"
import { SortByEnum } from "src/routes/Mobile/components/Explore/MobileBrowse/types"

import './HeaderBrowse.sass'
import filter from "src/static/images/svg/filter.svg"

interface HeaderBrowseProps {
  searchTerm: string
  showOnlyVerified?: boolean
  sortBy?: SortByEnum
  setSortBy
  handleSearch
  handleToggleShowVerified
}

const HeaderBrowse = ({ searchTerm, showOnlyVerified = false, sortBy, handleSearch, handleToggleShowVerified, setSortBy }: HeaderBrowseProps) => {
  const options = Object.values(SortByEnum)
  return (
    <header id="header-browse">
      <Browse searchTerm={searchTerm} handleSearch={handleSearch} />
      <div className="verified-only-box" >
        <input
        type="checkbox"
        checked={showOnlyVerified}
        className="verified-checkbox"
        onChange={handleToggleShowVerified}
        />
        <span><FormattedMessage id="recipe-item.show-only-verified" /></span>
        <img src={verified} alt="Recipe is verified" className="verified-img" />
      </div>
      <label className="sort-select">
        <img className="filter-image" src={filter} alt="filter vector" />
        <select
          name="sort"
          value={sortBy}
          onChange={(e) => {
            const sort = e.target.value as SortByEnum
            setSortBy(sort)
          }}>
          <FormattedMessage id="select">
            {text => <option value="" hidden>{text}</option>}
          </FormattedMessage>
          {options?.map((o: SortByEnum) => (
            <FormattedMessage key={o} id={`mobile-sort-browse.${o}`}>
              {text => <option value={o}>{text}</option>}
            </FormattedMessage>
          ))}
        </select>
      </label>
    </header>
  )
}

export default HeaderBrowse
