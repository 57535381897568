import React, { Suspense } from 'react'
import { Helmet } from 'react-helmet-async'
import { useIntl } from 'react-intl'
import { useParams } from 'react-router-dom'
import { useRecipeDetails, useRecipePerformance } from 'src/api/recipes'
import { formatRecipeTitle } from 'src/routes/routesHelper'
import { RecipeDetails } from 'src/types'
import ErrorBoundary from '../ErrorBoundary/ErrorBoundary'

const MetaRecipe = () => {
  const intl = useIntl()
  const { id } = useParams()
  const recipeDetails: RecipeDetails = useRecipeDetails(+id)
  const title: string = recipeDetails?.title
  const auxDescription: string = intl.formatMessage({ id: 'meta.description' })
  const description: string = recipeDetails?.short ? recipeDetails.short : auxDescription
  const url: string = "https://tortle.ninja/recipe/" + id + "/" + formatRecipeTitle(title)

  return (
    <Helmet>
      <title>{`Tortle Ninja: ${title}`}</title>
      <meta property="og:url" content={url} />
      <meta property="og:type" content="website" />
      <meta property="og:title" content={`Tortle Ninja: ${title}`} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content="https://i.imgur.com/dCiuZgb.png" />
      <meta property="og:image:width" content="1200" />
      <meta property="og:image:height" content="630" />
      <meta property="og:type" content="website" />

      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:type" content="website" />
      <meta name="twitter:title" content={`Tortle Ninja: ${title}`} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:url" content={url} />
      <meta name="twitter:image" content="https://i.imgur.com/dCiuZgb.png" />
    </Helmet>
  )
}

const MetaRecipeApr = () => {
  const intl = useIntl()
  const { id } = useParams()
  const recipeDetails: RecipeDetails = useRecipeDetails(+id)
  const { performance } = useRecipePerformance(id)
  const roi: number = +performance?.roi

  const roiPercent = (roi * 100).toFixed(2)
  const title: string = `${recipeDetails?.title}${roi ? ' [PnL: ' + roiPercent + '%]' : ''}`
  const auxDescription: string = intl.formatMessage({ id: 'meta.description' })
  const description: string = recipeDetails?.short ? recipeDetails.short : auxDescription

  return (
    <Helmet>
      <title>{`Tortle Ninja: ${title}`}</title>
      <meta property="og:title" content={`Tortle Ninja: ${title}`} />
      <meta property="og:description" content={description} />
      <meta name="twitter:title" content={`Tortle Ninja: ${title}`} />
      <meta name="twitter:description" content={description} />
    </Helmet>
  )
}

const MetaRecipeWrapper = () => (

    <Suspense fallback>
      <MetaRecipe />
      <Suspense fallback>
        <ErrorBoundary fallback>
          <MetaRecipeApr />
        </ErrorBoundary>
      </Suspense>
    </Suspense>
)
export default MetaRecipeWrapper
