import { getUnixTime, subDays, subWeeks, startOfMinute, subHours } from 'date-fns'

interface DeltaTimestamps {
  t1h: number
  t24h: number
  t48h: number
  t7d: number
  t14d: number
}

export const getDeltaTimestamps = (): DeltaTimestamps => {
  const utcCurrentTime = getUnixTime(new Date()) * 1000
  const t1h = getUnixTime(startOfMinute(subHours(utcCurrentTime, 1)))
  const t24h = getUnixTime(startOfMinute(subDays(utcCurrentTime, 1)))
  const t48h = getUnixTime(startOfMinute(subDays(utcCurrentTime, 2)))
  const t7d = getUnixTime(startOfMinute(subWeeks(utcCurrentTime, 1)))
  const t14d = getUnixTime(startOfMinute(subWeeks(utcCurrentTime, 2)))
  return { t1h, t24h, t48h, t7d, t14d }
}
