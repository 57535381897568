import React from 'react'
import { FormattedMessage } from 'react-intl'
import { BeetsPoolsInfo, ExtraDataBeetsPools } from 'src/api/beets/beetsTypes'
import { DepositOnLPNodeData } from 'src/routes/RecipeDiagram/helpers/types'
import { NodeExecutionResult } from 'src/types'
import { nodesLogsNumber } from 'src/constants'
import { BalancerPoolsInfo } from 'src/api/beets/balancerTypes'
import { adaptBalancerInfo } from 'src/api/beets/balancerHelper'
import { useBalancerPoolsFromApi } from 'src/api/recipes'

interface BeetsDepositLeftLogProps {
  events: NodeExecutionResult[]
  data: DepositOnLPNodeData

}
export const BalancerArbDepositLeftLog = ({ events, data }: BeetsDepositLeftLogProps) => {
  const { FINISHED_NODE, ABORTED_NODE, ACTIVE_NODE, NO_EXECUTED_NODE } = nodesLogsNumber

  let amountDeposited: string = '?'
  let lpUSDPriceAtDeposit: number = 0
  let executionSteps: number

  if (events !== undefined && events !== null) {
    executionSteps = events.length
    amountDeposited = events[1].output.amount
    const extraData: ExtraDataBeetsPools = events[1]?.extraData
    lpUSDPriceAtDeposit = Number(extraData?.totalLiquidity) / Number(extraData?.totalShares)
  } else executionSteps = 0

  const balancerPoolsFromApi: BalancerPoolsInfo[] = useBalancerPoolsFromApi()
  const adaptedBalancerPools: BeetsPoolsInfo[] = adaptBalancerInfo(balancerPoolsFromApi)
  const currentBalancerPool: BeetsPoolsInfo = adaptedBalancerPools.find(p => p.address.toLowerCase() === data.pair.address)

  const apr: string = executionSteps !== FINISHED_NODE
  ? (Number(currentBalancerPool.dynamicData?.apr?.apr?.total) * 100).toFixed(2)
  : (Number(events[2].extraData.apr) * 100).toFixed(2)
  const liquidity: string = executionSteps !== FINISHED_NODE
   ? (Number(currentBalancerPool.dynamicData?.totalLiquidity24hAgo)).toFixed(2)
   : (Number(events[2]?.extraData?.totalLiquidity)).toFixed(2)

  let lpUSDPriceAtOtherMoment: number = 0
  if (executionSteps === ACTIVE_NODE) {
    lpUSDPriceAtOtherMoment = Number(currentBalancerPool?.dynamicData?.totalLiquidity24hAgo) / Number(currentBalancerPool?.dynamicData?.totalShares)
  } else if (executionSteps === FINISHED_NODE || executionSteps === ABORTED_NODE) {
    const extraData: ExtraDataBeetsPools = events[2]?.extraData
    lpUSDPriceAtOtherMoment = Number(extraData?.totalLiquidity) / Number(extraData?.totalShares)
  }

  let earnedUSDValue: string = (Number(amountDeposited) * lpUSDPriceAtOtherMoment - Number(amountDeposited) * lpUSDPriceAtDeposit).toFixed(3)
  if (earnedUSDValue === "-0.000") earnedUSDValue = "0.000"
  return (
    <>
      <div className="first-column">
        <div className="logs-group-rows">
          <p><FormattedMessage id="farm-modal-log.deposited" />  </p>
          <div className="left-empty-position"></div>
        </div>
        <div className="logs-group-rows">
          {executionSteps === NO_EXECUTED_NODE && <p><FormattedMessage id="farm-modal-log.earned" />  </p>}
          {executionSteps === ACTIVE_NODE && <p><FormattedMessage id="farm-modal-log.current" />  </p>}
          {(executionSteps === FINISHED_NODE || executionSteps === ABORTED_NODE) && <p><FormattedMessage id="farm-modal-log.earned" />  </p>}
          <div className="left-empty-position"></div>
        </div>
        <p><FormattedMessage id="farm-modal-log.apr" />   </p>
        <p><FormattedMessage id="farm-modal-log.liquidity" />  </p>
      </div>
      <div className="second-column">
        <div className="logs-group-rows">
          <p className="main-text-logs"> $ {(Number(amountDeposited) * lpUSDPriceAtDeposit).toFixed(3)}</p>
          <p>{amountDeposited} BPT</p>
        </div>
        <div className="logs-group-rows">
          {executionSteps === NO_EXECUTED_NODE && <p className="main-text-logs">$ --- </p>}
          {executionSteps === ACTIVE_NODE && <p className="main-text-logs">$ {(Number(amountDeposited) * lpUSDPriceAtOtherMoment).toFixed(3)}</p>}
          {(executionSteps === FINISHED_NODE || executionSteps === ABORTED_NODE) && <p className="main-text-logs">$ {earnedUSDValue}</p>}
          <p>{amountDeposited} BPT</p>
        </div>
        <p>{apr} % </p>
        <p> $ {Number(liquidity).toLocaleString()}</p>
      </div>
    </>

  )
}
