import React, { Suspense } from "react"
import { FormattedMessage } from "react-intl"
import { APRCondition, ComboTriggerModalData, ConditionProvider } from "src/routes/RecipeDiagram/helpers/types"
import APRTriggerLabel from "./APRTriggerLabel"
import APRTriggerOptions from "./APRTriggerOptions"
import { useTriggerAPRInput } from "../../hooks/useTriggerAPRInput"

interface APRTriggerInputProps {
  triggerData: ComboTriggerModalData
  setTriggerData: (data: ComboTriggerModalData) => void
  avoidLiquidation: boolean
}

export interface CurrentFarmInfo {
  label: string
  apr: string
}

const APRTriggerInput = ({ triggerData, setTriggerData, avoidLiquidation }: APRTriggerInputProps) => {
  const condition = triggerData ? triggerData.condition as APRCondition : {}
  const conditionProvider = ConditionProvider.spookyswap
  const {
    getValue,
    inputCondition,
    inputConditionValue,
    farmInfo
  } = useTriggerAPRInput(triggerData)

  const handleInput = () => {
    const value = getValue()
    setTriggerData({ condition: { ...condition, value }, conditionProvider, avoidLiquidation })
  }
  const handleConditionType = type => {
    setTriggerData({ condition: { ...condition, type }, conditionProvider, avoidLiquidation })
  }
  const handleFarmId = farmID => {
    setTriggerData({ condition: { ...condition, farmID }, conditionProvider, avoidLiquidation })
  }

  return <>
    <div className="combo-trigger-grid">
      <section>
        <h2><FormattedMessage id="deposit-lp-modal.provider" /></h2>
        <div className="spookyswap-provider">
          <FormattedMessage id="spookyswap" />
        </div>
      </section>
      <Suspense fallback={""}>
        <section>
          <h2><FormattedMessage id="combo-trigger-modal.says" /></h2>
          <select value={condition?.farmID} onChange={(e) => handleFarmId(e.target.value)}>
            <FormattedMessage id="select.currency">
              {text => <option value="" hidden>{text}</option>}
            </FormattedMessage>
            {farmInfo && <APRTriggerOptions pairs={farmInfo} />}
          </select>
        </section>
      </Suspense>
    </div>
    <section>
      <h2><FormattedMessage id="combo-trigger-modal.is" /></h2>
      <select
        value={condition?.type}
        onChange={(e) => {
          const type: any = e.target.value
          handleConditionType(type)
        }}
      >
        <FormattedMessage id="select.currency">
          {text => <option value="" hidden>{text}</option>}
        </FormattedMessage>
        <FormattedMessage id="lower">
          {(text: string) => <option value="lower">{text}</option>}
        </FormattedMessage>
        <FormattedMessage id="higher">
          {(text: string) => <option value="higher">{text}</option>}
        </FormattedMessage>
      </select>
      <div className="input-value">
        <FormattedMessage id="placeholder.amount">
          {() => (
            <input
              ref={inputCondition}
              value={inputConditionValue}
              onChange={() => {
                handleInput()
              }}
              placeholder="Value..."
              min={0}
            />
          )}
        </FormattedMessage>
        <span>%</span>
      </div>
      <Suspense fallback="">
        {farmInfo && <APRTriggerLabel pairs={farmInfo} farmID={condition?.farmID} />}
      </Suspense>
    </section>
  </>
}

export default APRTriggerInput
