import React, { CSSProperties } from 'react'
import { getSmoothStepPath, Position } from 'react-flow-renderer'
import { useSelector } from 'react-redux'

interface CustomEdgeProps {
  id: string
  sourceX: number
  sourceY: number
  targetX: number
  targetY: number
  sourcePosition: Position
  targetPosition: Position
  style?: CSSProperties
}

export default function CustomEdge ({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  style = {},
}: CustomEdgeProps) {
  const edgePath = getSmoothStepPath({ sourceX, sourceY, sourcePosition, targetX, targetY, targetPosition })

  const xDifference = targetX - sourceX
  const yDifference = targetY - sourceY
  const halfPathX = sourceX + (xDifference) / 2
  const halfPathY = sourceY + (yDifference) / 2

  let yOffset = 1
  let xOffset = 1
  let isHorizontal = false
  if (xDifference > 20) { // right direction
    isHorizontal = true
  } else if (xDifference < -30) { // left direction
    xOffset = -1
    isHorizontal = true
  }
  if (yDifference > 20) { // up
    isHorizontal = false
  } else if (yDifference < -20) { // down
    yOffset = -1
    isHorizontal = false
  }
  const showArrow = Math.abs(xDifference) + Math.abs(yDifference) > 60

  const focusedNode = useSelector((state: any) => state.diagram.focused)

  const midArrowPathHorizontal = `m ${halfPathX - 20 * xOffset} ${halfPathY - 20} l ${xOffset * 20} 20 l ${-xOffset * 20} 20`
  const midArrowPathVertical = `m ${halfPathX - 20} ${halfPathY + (20 * -yOffset)} l 20 ${yOffset * 20} l 20 ${-yOffset * 20}`
  return (
    <>
      <path id={id} style={style} strokeDasharray="2 4" className={`react-flow__edge-path ${focusedNode ? 'edge-unfocused' : ''}`} stroke="yellow" d={edgePath} />
      <path d={`m ${targetX} ${targetY} l ${-xOffset * 5} 5 l ${xOffset * 5} -5 l ${-xOffset * 5} -5`} style={{ stroke: '#FFD77C', ...style, strokeWidth: 1.5 }} strokeLinecap="round" />
      {showArrow && <path d={isHorizontal ? midArrowPathHorizontal : midArrowPathVertical} style={{ stroke: '#FFD77C', ...style, strokeWidth: 15, opacity: 0.5 }} fill="transparent" strokeLinecap="round" />}
    </>
  )
}
