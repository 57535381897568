import React from "react"
import { SECONDS_IN_A_MONTH } from "src/constants"

export const useTriggerTimeInput = (value) => {
  const dateToShowForExactType: Date = value && value > SECONDS_IN_A_MONTH ? new Date(value) : new Date()

  const getCurrentDate = () => {
    const myCurrentDate = new Date()
    const day = myCurrentDate.getDate();
    const month = myCurrentDate.getMonth() + 1
    const year = myCurrentDate.getFullYear()
    return `${year} - ${month} - ${day}`
  }

  const isValidDate = (dateToValidate: Date) => {
    return dateToValidate.getTime() > Date.now()
  }

  const dateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const splitInput = e.target.value.split('-')
    const updatedDate = new Date(dateToShowForExactType.getTime())
    updatedDate.setFullYear(+splitInput[0])
    updatedDate.setMonth(+splitInput[1] - 1)
    updatedDate.setDate(+splitInput[2])
    if (isValidDate(updatedDate)) {
      return updatedDate
    }
  }

  const hourChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const splitInput = e.target.value.split(':')
    const updatedDate = new Date(dateToShowForExactType.getTime())
    updatedDate.setHours(+splitInput[0])
    updatedDate.setMinutes(+splitInput[1])
    if (isValidDate(updatedDate)) {
      return updatedDate
    }
  }

  return {
    getCurrentDate,
    dateChange,
    hourChange,
    dateToShowForExactType
  }
}
