import React, { Suspense, useEffect, useState } from "react"
import { FormattedMessage } from "react-intl"
import { useSelector } from "react-redux"
import { registerModal, CloseModal, useModal } from '@gluedigital/modal';
import { AddFundsModalData } from "src/routes/RecipeDiagram/helpers/types"
import { BaseNodeModal } from "../BaseNodeModal"
import { getEtherNumberAmountFromAddress, getWeiNumberAmountDependOnDecimals } from "src/contracts/TokensDecimalsHelpers"
import { getNetworkParams } from "../../SelectWalletModal/helpers"
import TokenSelector from "src/components/TokenSelector/TokenSelector";
import { tokenNameToAddress } from "src/components/Diagram/nodes/nodesLogsHelper";
import usePromise from "src/hooks/usePromise";
import { getTokenBalanceList } from "src/utils/balanceHelper";
import ErrorBoundary from "src/components/ErrorBoundary/ErrorBoundary";
import { getBalance } from "./GetBalances";
import web3 from "src/utils/web3";

import "./AddFundsModal.sass"
import "src/components/modals/Modals.sass"

interface AddFundsModalPropTypes extends BaseNodeModal<AddFundsModalData> { }

const Button = ({ onSave, data, networkId }: {onSave: Function, data: any, networkId: any}) => {
  const modal = useModal()
  const userInfoAccount = useSelector((s: any) => s.user.account)
  const balanceCoins: any[] = usePromise(getTokenBalanceList, userInfoAccount, networkId)
  const hasBalance = data?.amount && (data.amount <= (balanceCoins.find(c => data?.outputCoin === c.id)?.balance))
  return (
    <button
      disabled={!hasBalance}
      onClick={() => {
        if (data.amount > 0) {
          getWeiNumberAmountDependOnDecimals(data.outputCoin, data.amount, networkId)
            .then((amount) => {
              onSave({ amount, outputCoin: data.outputCoin })
              modal.hide()
            })
            .catch(err => console.error(err.message))
        }
      }}
      className="filled"
    >
      <FormattedMessage id="save" />
    </button>
  )
}

const AddFundsModal = (props: AddFundsModalPropTypes) => {
  const { onSave, previousData } = props
  const networkId: string = useSelector((s: any) => (s.network.network))
  const userInfoAccount = useSelector((s: any) => s.user.account)
  const networkParams = getNetworkParams(networkId)
  const coins = networkParams.tokens
  const defaultData = { amount: +previousData?.amount || null, outputCoin: previousData?.outputCoin || coins[0].id }
  const [data, setdata] = useState(defaultData)

  useEffect(() => {
    if (previousData?.amount) {
      getEtherNumberAmountFromAddress(tokenNameToAddress(previousData?.outputCoin, networkId), previousData?.amount.toString())
        .then((amount) => setdata({ outputCoin: previousData?.outputCoin, amount: Number(amount) }))
        .catch(err => console.error(err.message))
    }
  }, [previousData?.amount, previousData?.outputCoin, networkId])

  const handleBalance = () => {
    getBalance(web3, userInfoAccount, data.outputCoin, networkId)
      .then((res: number) => setdata({ ...data, amount: Number(res) }))
      .catch(err => console.error(err.message))
  }

  return (
    <div className="add-funds-modal modal-content">
      <div className="modal-wrapper">
        <header className="header-buttons">
          <CloseModal>
            <button className="modal-close-button">
              <span className="icon icon-close" />
              <span><FormattedMessage id="close" /></span>
            </button>
          </CloseModal>
          <h1 className="title"><FormattedMessage id="add-funds-modal.title" /></h1>
          <ErrorBoundary fallback={<></>}>
            <Suspense fallback={<button disabled className="filled"><FormattedMessage id="save" /></button>}>
              <Button data={data} onSave={onSave} networkId={networkId} />
            </Suspense>
          </ErrorBoundary>
        </header>
        <section>
          <TokenSelector
            blocked={false}
            previousData={data}
            onChange={setdata}
            searchMode={true}
          />
        </section>
        <button className="max-button" onClick={handleBalance}>
          <FormattedMessage id="max" />
        </button>
      </div>
    </div>
  )
}

registerModal("add-funds-modal", AddFundsModal)
