
import { PositionDataInContract } from "src/api/mummyFinance/mummyTypes"
import usePromise from "./usePromise"
import { getPositionFromMummyVault } from "src/api/mummyFinance/liquidationPriceHelpers"
import getLiquidationPrice from "src/api/mummyFinance/getLiquidationPrice"
import { BigNumber } from "ethers"
import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { perpetualsAPIPrices } from "src/constants"

// const MUMMY_TOKEN_STATS_URL: string = "https://api.mummy.finance/api/token-stats"

const getPricesFromPerpetualsAPI = async (networkId: string): Promise<any> => {
  const url: string = perpetualsAPIPrices[networkId]
  const res = await fetch(url)
  const prices = await res.json()
  return prices
}

export const useGetPricesFromPerpetualsAPI = (): {} => {
  const networkId: string = useSelector((s: any) => s.network.network)
  return usePromise(getPricesFromPerpetualsAPI, networkId)
}

const calculateLiquidationPrice = async (isLong: boolean, firstTypePerpContract: string, collateralTokenAddress: string, indexTokenAddress: string, networkId: string): Promise<string> => {
  const MUMMY_MULTIPLIER_FACTOR: number = 1e30
  const position: PositionDataInContract = await getPositionFromMummyVault(firstTypePerpContract, collateralTokenAddress, indexTokenAddress, isLong, networkId)
  const { size, collateral, averagePrice } = position
  const liqPriceWithFactor: BigNumber = getLiquidationPrice(isLong, size, collateral, averagePrice)
  const liquidationPrice: string = (parseFloat(liqPriceWithFactor?.toString()) / MUMMY_MULTIPLIER_FACTOR)?.toString()
  return liquidationPrice
}

export const useLiquidationPrice = (isLong: boolean, firstTypePerpContract: string, collateralTokenAddress: string, indexTokenAddress: string): string => {
  const networkId: string = useSelector((s: any) => (s.network.network))
  const [result, setResult] = useState<string>(undefined)
  const TIME_TO_UPDATE_LIQ_PRICE_MS: number = 5000
  useEffect(() => {
    if (!result || result === NaN.toString()) {
      const interval = setInterval(() => {
        calculateLiquidationPrice(isLong, firstTypePerpContract, collateralTokenAddress, indexTokenAddress, networkId)
          .then(res => setResult(res))
          .catch(error => console.log(error))
      }, TIME_TO_UPDATE_LIQ_PRICE_MS);
      return () => {
        clearInterval(interval)
      }
    }
  }, [isLong, firstTypePerpContract, collateralTokenAddress, indexTokenAddress, result, networkId])

  return result
}

export const usePerpetualsCurrentPrice = (operationCoinAddress: string): number => {
  const [result, setResult] = useState<number>(undefined)
  const networkId: string = useSelector((s: any) => s.network.network)
  const MUMMY_MULTIPLIER_FACTOR: number = 1e30
  const TIME_TO_UPDATE_CURRENT_PRICE_MS: number = 5000
  useEffect(() => {
      const interval = setInterval(() => {
        getPricesFromPerpetualsAPI(networkId)
          .then(res => {
            setResult((res[operationCoinAddress]) / MUMMY_MULTIPLIER_FACTOR)
           })
          .catch(error => console.log(error))
      }, TIME_TO_UPDATE_CURRENT_PRICE_MS);
      return () => {
        clearInterval(interval)
      }
  }, [operationCoinAddress, networkId])
  return result
}
