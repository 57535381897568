import React, { Suspense, useMemo, useState } from "react"
import { FormattedMessage } from "react-intl"
import { useModal, registerModal, CloseModal } from "@gluedigital/modal"
import { ComboTriggerModalPropTypes, ComboTriggerModalData, ConditionProvider } from "src/routes/RecipeDiagram/helpers/types"
import ProfitTriggerInput from "./inputs/ProfitTriggerInput"
import Loading from "src/components/common/Loading/Loading"
import Motivation from "./Motivation/Motivation"

import "./ComboTriggerProfitModal.sass"
import "./ComboTriggerModal"

const ComboTriggerProfitModal = (props: ComboTriggerModalPropTypes) => {
  const { onSave, previousData, avoidLiquidation } = props

  const [triggerData, setTriggerData] = useState<ComboTriggerModalData>(previousData)
  const modal = useModal();
  const InputComponent = ProfitTriggerInput

  const isFormValid = useMemo(() => {
    if (!triggerData) {
      return false
    }
    if (triggerData.conditionProvider === ConditionProvider.tortle) {
      return triggerData.condition?.value >= 0 && triggerData.condition.performanceType
    }
  }, [triggerData])

  const handleClickBackButton = () => { modal.show('combo-trigger-modal', props) }

  return (
    <div className="combo-trigger-modal modal-content">
      <div className="modal-wrapper">
        <header className="header-buttons">
          <CloseModal>
            <div className="buttons">
              <button className="modal-close-button">
                <span className="icon icon-close" />
              </button>
            </div>
          </CloseModal>
          <button className="back-button" onClick={handleClickBackButton}>
            <span className="icon icon-angle_left" />
            <span><FormattedMessage id="back" /></span>
          </button>
          <h1><FormattedMessage id="combo-type-profit" /></h1>
          <CloseModal>
            <button
              disabled={!isFormValid}
              onClick={() => onSave(triggerData)}
              className="buttom-filled"
            >
              <FormattedMessage id="save" />
            </button>
          </CloseModal>
        </header>
        <Suspense fallback={<Loading />}>
          <InputComponent triggerData={triggerData} setTriggerData={setTriggerData} avoidLiquidation={avoidLiquidation} />
          <Motivation triggerData={triggerData} setTriggerData={setTriggerData} />
        </Suspense>
      </div>
    </div >
  )
}

registerModal("combo-trigger-modal-profit", ComboTriggerProfitModal)
