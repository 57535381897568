
import React from "react"
import { FormattedMessage } from "react-intl"
import { tokenNameToAddress } from "src/components/Diagram/nodes/nodesLogsHelper"
import { getEtherNumberAmountFromAddress } from "src/contracts/TokensDecimalsHelpers"
import usePromise from "src/hooks/usePromise"
import { CodeNodeRecipe } from "src/routes/Mobile/helpers/getNodesOrderedMap"
import SummaryListSpookyLog from "src/routes/RecipeSummary/SummaryList/SummaryListSpookyLog"

import "./NodeFlowCard.sass"
import { useSelector } from "react-redux"

interface NodeFlowCardProps {
  node: CodeNodeRecipe
  accordion?: { open: boolean }
}

const NodeFlowCard = ({ node, accordion }: NodeFlowCardProps) => {
  const networkId: string = useSelector((s: any) => (s.network.network))
  const addFundsEtherAmount: string = usePromise(getEtherNumberAmountFromAddress, tokenNameToAddress(node?.data?.outputCoin, networkId), node?.data?.amount)

  return (
    <article className={`node-flow-card type-${node.type}`}>
      <div className={`node-image type-${node.type}`} />
      <section className="node-info">
        <h1><FormattedMessage id={"node." + node.type} />: </h1>
        <p>
          {node.type === "splitNode" && <span className="coin">{node.data.inputCoin} - {node.data.firstCoin} & {node.data.secondCoin}</span>}
          {node.type === "addFundsNode" && (<><span className="amount">{addFundsEtherAmount}</span><span className="coin"> {node.data.outputCoin}</span></>)}
          {(node.type === "swapNode" || node.type === "liquidateNode") && <span className="coin">{node.data.inputCoin} - {node.data.outputCoin}</span>}
          {node.type === "sendToWalletNode" && <span className="coin">{node.data.inputCoin}</span>}
          {node.type === "farmNode" && <span className="coin">{node.data.pair.token0} -{node.data.pair.token1} </span>}
          {node.type === "depositOnLPNode" && (
            node.data?.pair?.tokens.length === 2
              ? <span className="coin">{node.data?.pair?.tokens[0].symbol} -{node.data.pair?.tokens[1].symbol} </span>
              : <span className="coin">{node.data?.pair?.name} </span>
          )}
          {node.type === "comboTriggerNode" &&
            ((node.data.conditionProvider === "CHAINLINK" && <span className="coin">{node.data.condition.coinToCompare} {node.data.condition?.type} {node.data.condition?.value} USD </span>)
              || (node.data.conditionProvider === "SPOOKYSWAP" && <SummaryListSpookyLog short summaryInfo={node} />)
              || (node.data.conditionProvider === "USER" && <span className="coin"> <FormattedMessage id="combo-trigger-modal.manual-execution" /></span>)
              // || (node.data.conditionProvider === "TIME" && (
              //   <span>
              //     <FormattedMessage id="combo-type-DCA" /> {node.data.condition.type === "exact"
              //       ? <>
              //         <FormattedMessage id="combo-type-DCA.at" /> {' '}
              //         {node.data.condition.value}
              //       </>
              //       : <>
              //         <FormattedMessage id="combo-type-DCA.after" /> {' '}
              //         {node.data.condition.value}
              //       </>}
              //   </span>))
              || (node.data.conditionProvider === "TORTLE" &&
                (<span>
                  <FormattedMessage id="combo-type-profit.profit" /> {node.data.condition.type} <FormattedMessage id="combo-trigger-modal.than" /> {node.data.condition.value}%
                </span>))
            )}
          {node.type === "nestedStrategiesNode" && <span className="coin">{node.data?.vault?.formated_name}</span>}
          {node.type === "shortLongNode" && <span className="coin">{node.data?.outputCoin}</span>}
        </p>
      </section>
      {accordion && <span className={`icon ${!accordion.open ? 'icon-angle_right' : 'icon-angle_down'}`} />}
    </article>
  )
}

export default NodeFlowCard
