import { BigNumber } from "ethers"
import { tokenNameToAddress } from "src/components/Diagram/nodes/nodesLogsHelper"
import { tokensABI } from "src/data/ABIs"
import { ApprovalStatus, TokenForApproval } from "src/routes/RecipeDiagram/helpers/types"
import web3 from "src/utils/web3"
import { EventData } from 'web3-eth-contract/types/index'
import { AbiItem } from 'web3-utils'

export const newAllowance = async (userAddress: string, tokens: TokenForApproval[], tokensState: Map<String, ApprovalStatus>, setTokensState, networkParams: any) => {
  for (let i = 0; i < tokens.length; i++) {
    if (tokensState.get(tokens[i].address) === "approved") {
      continue
    }
    const tokenAddress: string = tokens[i].address
    setTokensState(s => new Map(s.set(tokenAddress, "pending")))

    const token: any = new web3.eth.Contract(tokensABI as AbiItem[], tokens[i].address)
    const result = await token.methods.allowance(userAddress, networkParams.contracts.nodes).call()
    if (BigNumber.from(result).lt(BigNumber.from(tokens[i].amount))) {
      await token.methods.approve(networkParams.contracts.nodes, tokens[i].amount.toString()).send({
        from: userAddress,
        maxPriorityFeePerGas: null,
        maxFeePerGas: null,
      })
        .once('confirmation', () => {
          setTokensState(s => new Map(s.set(tokenAddress, "approved")))
        })
        .once('error', () => {
          setTokensState(s => new Map(s.set(tokenAddress, "rejected")))
        })
    } else {
      setTokensState(s => new Map(s.set(tokenAddress, "approved")))
    }
  }
}

export const allowanceForRetry = async (userAddress: string, tokens: TokenForApproval[], setTokenStatus: (tokenID: string, status: ApprovalStatus,) => void, networkParams: any) => {
  for (let i = 0; i < tokens.length; i++) {
    if (tokens[i].status === "approved") {
      continue
    }

    setTokenStatus(tokens[i].address, 'pending')
    const token: any = new web3.eth.Contract(tokensABI as AbiItem[], tokens[i].address)
    const result = await token.methods.allowance(userAddress, networkParams.contracts.nodes).call()

    if (BigNumber.from(result).lt(BigNumber.from(tokens[i].amount))) {
      console.log("Before approve")
      const tokenAddress = tokens[i].address
      await token.methods.approve(networkParams.contracts.nodes, tokens[i].amount.toString()).send({
        from: userAddress,
        maxPriorityFeePerGas: null,
        maxFeePerGas: null,
      })
        .once('confirmation', () => {
          setTokenStatus(tokenAddress, "approved")
        })
        .once('error', () => {
          console.log("error")
          setTokenStatus(tokenAddress, "rejected")
        })
    } else {
      setTokenStatus(tokens[i].address, "approved")
    }
  }
}

export const amountIsAlreadyApproved = async (userAddress: string, tokens: TokenForApproval[], setTokenStatus: (tokenID: string, status: ApprovalStatus) => void, networkParams: any, setTokensState?) => {
  for (let i = 0; i < tokens.length; i++) {
    const token: any = new web3.eth.Contract(tokensABI as AbiItem[], tokens[i].address)
    await token.methods.allowance(userAddress, networkParams.contracts.nodes).call()
      .then((result: number) => {
        if (BigNumber.from(result).gte(BigNumber.from(tokens[i].amount))) {
          if (setTokensState) setTokensState(s => new Map(s.set(token.address, "approved")))
          else setTokenStatus(tokens[i].address, "approved")
        }
      })
      .catch((error) => {
        console.log(error.message)
        if (setTokensState) setTokensState(s => new Map(s.set(token.address, "rejected")))
        else setTokenStatus(tokens[i].address, "rejected")
      })
  }
}

export const depositFTM = (userAddress: string, recipeId: number, amount: string, networkParams: any) => {
  const nodes = new web3.eth.Contract(networkParams.contracts.nodesAbi, networkParams.contracts.nodes)
  return nodes.methods.addFundsForFTM(userAddress, recipeId.toString())
    .send({
      from: userAddress,
      value: amount,
      maxPriorityFeePerGas: null,
      maxFeePerGas: null
    })
    .once('confirmation', async () => {
      return await checkIfRecipeIsFunded(recipeId, amount, nodes)
    })
    .once('error', () => {
      return false
    })
}

export const checkEnoughTokenBalance = async (userAddress: string, token: TokenForApproval): Promise<boolean> => {
  const tokenObj = new web3.eth.Contract(tokensABI as AbiItem[], token.address)
  const balance: number = await tokenObj.methods.balanceOf(userAddress).call()
  return BigNumber.from(token.amount).lt(BigNumber.from(balance))
}

export const checkFantomBalance = async (userBalance: string, fantoms: TokenForApproval) => {
  const balanceToWei = web3.utils.toWei(userBalance, 'ether')
  return BigNumber.from(fantoms.amount).lt(BigNumber.from(balanceToWei))
}

export const checkIfRecipeIsFunded = async (recipeId: number, amount: string, nodes): Promise<boolean> => {
  const filter = { fromBlock: 0, toBlock: 'latest' }
  const addFundsForFTMEvents: EventData[] = await nodes.getPastEvents('AddFundsForFTM', filter)
  const event = (addFundsForFTMEvents.filter((event) => {
    return (event.returnValues.recipeId === web3.utils.keccak256(recipeId.toString()))
  }))[0]
  const eventAmount: string = web3.utils.fromWei(event.returnValues.amount, 'ether')

  const feeAmount: number = Number(web3.utils.fromWei(amount, 'ether')) * 0.005
  const amountWithoutFees: number = Number(web3.utils.fromWei(amount, 'ether')) - feeAmount

  if (eventAmount === amountWithoutFees.toString()) return true
  else return false
}

export const getNodesToApprove = (recipeCode: any, networkId: string): TokenForApproval[] => {
  const nodesToApprove = recipeCode.filter((node) => node.type === 'addFundsNode').map((node) => {
    const tokenAddress = tokenNameToAddress(node.data.outputCoin, networkId)
    return {
      id: node.data.outputCoin,
      address: tokenAddress,
      amount: node.data.amount.toString(),
      status: 'pending'
    }
  })
  return nodesToApprove
}
