import React from "react"
import { FormattedMessage } from "react-intl"
import { useSelector } from "react-redux"
import { YearnSummaryVaultsData } from "src/api/yearnVaults/yearnTypes"
import { tokenAddressToName } from "src/components/Diagram/nodes/nodesLogsHelper"
import { getEtherStringAmountFromAddressFixed15 } from "src/contracts/TokensDecimalsHelpers"
import usePromise from "src/hooks/usePromise"

interface DepositedYearnNestedModalLogProps {
  oldVaultData: YearnSummaryVaultsData
  depositAmount: string
  depositPriceUSD: string
  amountYTokensDepositedWei: string

}

export const DepositedYearnNestedModalLog = ({ oldVaultData, depositAmount, depositPriceUSD, amountYTokensDepositedWei }: DepositedYearnNestedModalLogProps) => {
  const networkId: string = useSelector((s: any) => (s.network.network))
  const DECIMAL_USD_ROUNDED: number = 4

  const vaultTokenAddress: string = oldVaultData?.token.address
  const vaultTokenName: string = tokenAddressToName(vaultTokenAddress, networkId)
  const yVaultName: string = oldVaultData?.name
  const yVaultAddress: string = oldVaultData?.address

  const depositAmountEther: string = usePromise(getEtherStringAmountFromAddressFixed15, vaultTokenAddress, depositAmount)
  const depositAmountYTokens: string = usePromise(getEtherStringAmountFromAddressFixed15, yVaultAddress, amountYTokensDepositedWei)
  return (
    <div id="nested-log-field">
      <h3><FormattedMessage id="nested-modal-log.deposited" /></h3>
      <strong className="span-coin">$ {Number(depositPriceUSD).toFixed(DECIMAL_USD_ROUNDED)}</strong>
      <div><span className="lp-amount-row">{depositAmountYTokens} <strong>{yVaultName}</strong></span></div>
      <div><span className="lp-amount-row">{depositAmountEther} <strong>{vaultTokenName}</strong></span></div>
    </div>
  )
}
