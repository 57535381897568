import React from "react"
import { FormattedMessage } from "react-intl"
import './DeprecatedVault.sass'

export const DeprecatedVault = () => {
  return (
    <div id="deprecated-vault">
      <span className="icon icon-exclamation" />
      <span className="deprecated-text"><FormattedMessage id="deprecated-vault" /></span>
    </div>
  )
}
