import React from "react"
import { FormattedMessage } from "react-intl"
import { ComboTriggerModalData, ConditionProvider, ProfitCondition } from "src/routes/RecipeDiagram/helpers/types"
import { useTriggerProfitInput } from "../hooks/useTriggerProfitInput"

interface ProfitTriggerInputProps {
  triggerData: ComboTriggerModalData
  setTriggerData: (data: ComboTriggerModalData) => void
  avoidLiquidation: boolean
}

const ProfitTriggerInput = ({ triggerData, setTriggerData, avoidLiquidation }: ProfitTriggerInputProps) => {
  const condition = triggerData ? triggerData.condition as ProfitCondition : {}
  const conditionProvider = ConditionProvider.tortle
  const {
    getValue,
    inputCondition,
    inputConditionValue
  } = useTriggerProfitInput(triggerData)

  const handleInput = () => {
    const value = getValue()
    setTriggerData({ condition: { ...condition, value }, conditionProvider, avoidLiquidation })
  }

  const handleConditionType = type => {
    setTriggerData({ condition: { ...condition, performanceType: type }, conditionProvider, avoidLiquidation })
  }

  return (
    <>
      <section className="combo-trigger-grid">
        <section className="profit-grid">
          <div className="column-profit">
            <h2><FormattedMessage id="combo-type-profit.when" /></h2>
            <div className="condition-type">
              <select
                value={condition?.performanceType}
                onChange={(e) => {
                  const type: any = e.target.value
                  handleConditionType(type)
                }}
              >
                <FormattedMessage id="select.currency">
                  {text => <option value="" hidden>{text}</option>}
                </FormattedMessage>
                <FormattedMessage id="combo-type-profit.profits">
                  {(text: string) => <option value="profits">{text}</option>}
                </FormattedMessage>
                <FormattedMessage id="combo-type-profit.losses">
                  {(text: string) => <option value="losses">{text}</option>}
                </FormattedMessage>
              </select>
            </div>
          </div>
          <div className="column-profit">
            <h2><FormattedMessage id="combo-type-profit.are" /></h2>
            <div className="condition-provider">
              <FormattedMessage id="combo-type-profit.higher" />
            </div>
          </div>
          <div className="column-profit">
            <h2><FormattedMessage id="combo-type-profit.than" /></h2>
            <div className="input-value">
              <FormattedMessage id="placeholder.amount">
                {() => (
                  <input
                    ref={inputCondition}
                    value={inputConditionValue}
                    onChange={() => { handleInput() }}
                    placeholder="Value..."
                    min={1}
                  />
                )}
              </FormattedMessage>
              <span>%</span>
            </div>
          </div>
        </section>
      </section>
    </>
  )
}
export default ProfitTriggerInput
