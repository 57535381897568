import React from "react"
import { Recipe } from "src/types"
import RecipeItem from "src/components/RecipeItem/RecipeItem"
import useInfiniteScroll from "src/hooks/useInfiniteScroll"
import { FormattedMessage } from "react-intl"

import './RecipeList.sass'

interface RecipeListProps {
  recipes: Recipe[]
  type: string
  loadMoreCb?: () => void
  showLoadMoreButton?: boolean
}

const RecipeList = ({ recipes, type, loadMoreCb, showLoadMoreButton }: RecipeListProps) => {
  const ref: any = useInfiniteScroll(loadMoreCb, false)

  return (
    <>
      <div id="recipe-list" className={`${type === 'failed' ? 'extended' : ''}`} ref={ref}>
        {recipes.map((recipe) => <RecipeItem key={recipe.id} recipe={recipe} type={type} status={recipe.status} />)}
        {showLoadMoreButton
          && <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <button onClick={() => loadMoreCb()}>
              <FormattedMessage id="load-more" />
            </button>
          </div>
        }
      </div>
    </>
  )
}

export default RecipeList
