import React from 'react'
import { FormattedMessage } from 'react-intl'
import { useModal } from '@gluedigital/modal'

import './LaunchButton.sass'

const LaunchButton = ({ text = 'default-text' }: {text?: string}) => {
  const modal = useModal()
  return (
    <button className="launch-button" onClick={() => modal.show('select-wallet-modal')}>
      <FormattedMessage id={`launch-button.${text}`} />
    </button>
  )
}

export default LaunchButton
