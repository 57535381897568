import React from 'react'
import { useSelector } from 'react-redux'
import { PairDayData } from 'src/api/farms/types'
import { useGetLpPriceFromApi, useGetTokenPriceUSDFromApi } from 'src/api/recipes'
import { tokenAddressToName } from 'src/components/Diagram/nodes/nodesLogsHelper'
import { useFarmRewardsAmountFromStrategy } from 'src/hooks/useFarmsLogs'
import { LPTokenWithPrice } from 'src/routes/RecipeDiagram/helpers/types'

interface EarnedFarmLeftLogProps {
  nodeId: string
  shares: string
  lpDeposited: number
  totalLpAmount: number
  lpUSDValueAtDeposit: number
  masterchefInfo: PairDayData
}
export const EarnedFarmV3LeftLog = (props: EarnedFarmLeftLogProps) => {
  const { lpDeposited, totalLpAmount, lpUSDValueAtDeposit, masterchefInfo, nodeId } = props
  const networkId: string = useSelector((s: any) => (s.network.network))
  const tokeRewardName: string = tokenAddressToName(masterchefInfo?.tokenReward, networkId)

  // const amountBOOWei: number = useAuxGetBooFromStrategy(shares, masterchefInfo?.token0?.symbol, masterchefInfo?.token1?.symbol, 3)
  const amountBOOWei: number[] = useFarmRewardsAmountFromStrategy(nodeId, 3)
  const amountRewardEther = amountBOOWei[0] / 10 ** 18
  const lpPriceActiveNode: LPTokenWithPrice = useGetLpPriceFromApi(masterchefInfo?.pairAddress, totalLpAmount.toString())

  const tokenRewardPriceUSD: string = useGetTokenPriceUSDFromApi(masterchefInfo.tokenReward)

  const booUSDValueAtOtherMoment: number = parseFloat(tokenRewardPriceUSD) * amountRewardEther
  const totalUSD: number = parseFloat((lpPriceActiveNode?.totalValueUSD)) + booUSDValueAtOtherMoment
  let earnedUSDTotal: string = (totalUSD - lpUSDValueAtDeposit).toFixed(3)
  if (earnedUSDTotal === "-0.000") earnedUSDTotal = "0.000"

  return (
    <>
       {/* CURRENT VALUE */}
        <div className="logs-group-rows">
          <p className="main-text-logs">$ {totalUSD.toFixed(3)} </p>
          <p>{totalLpAmount.toFixed(12)}  LPs<span className="left-logs-usd-price">$ {(parseFloat(lpPriceActiveNode?.totalValueUSD)).toFixed(3)}</span></p>
          <p>{amountRewardEther.toFixed(10)} {tokeRewardName} <span className="left-logs-usd-price">$ {(booUSDValueAtOtherMoment).toFixed(3)}</span></p>
        </div>
       {/* EARNED */}
      <div className="logs-group-rows">
        <p className="main-text-logs">$ {earnedUSDTotal}</p>
        <p>{(totalLpAmount - lpDeposited).toFixed(12)} LPs</p>
      </div>
    </>
  )
}

interface LoadingEarnedLeftLogProps {
  recipeStatus: string
}

export const LoadingEarnedLeftLog = ({ recipeStatus }: LoadingEarnedLeftLogProps) => {
  return (
    <>
      {recipeStatus === "active"
        ?
        <div className="logs-group-rows">
          <p>Loading current value</p>
          <div className="left-empty-position"></div>
          <div className="left-empty-position"></div>
        </div>
        :
        <div className="logs-group-rows">
          <p>Loading final value</p>
          <div className="left-empty-position"></div>
        </div>}

      <div className="logs-group-rows">
        <p>Loading earned</p>
        <div className="left-empty-position"></div>
      </div>
    </>
  )
}
