import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { logout, updateBalance } from 'src/store/actions/user'
import { useHistory, Redirect, useLocation } from 'react-router-dom'
import web3 from 'src/utils/web3'
import { getWindowDimensions } from 'src/hooks/useWindowDimensions'

interface AppProps {
  children?: React.ReactNode
}

const App = ({ children }: AppProps) => {
  const user = useSelector((s: any) => s.user)
  const ready = useSelector((s: any) => s.ready)
  const networkId: string = useSelector((s: any) => (s.network.network))
  const dispatch = useDispatch();
  const history = useHistory()
  const location = useLocation()
  const BALANCE_UPDATE_TIME_MS: number = 60000

  useEffect(() => {
    const handleAccountsChange = async (accounts: string[]) => {
      if (accounts.length === 0) {
        dispatch(logout())
      }
    }
    const handleChainChange = async (chainId: string) => {
      if (chainId !== networkId) {
        history.push('/')
        dispatch(logout())
      }
    }

    const provider = web3.currentProvider as any
    if (provider) {
      provider.on("accountsChanged", handleAccountsChange);
      provider.on("chainChanged", handleChainChange);
      return () => {
        provider.removeListener("accountsChanged", handleAccountsChange);
        provider.removeListener("chainChanged", handleChainChange);
      }
    }
  }, [dispatch, history, networkId])

  useEffect(() => {
    const interval = setInterval(() => {
      if (user.token) {
         web3.eth.getBalance(`0x${user.address}`)
        .then(balance => dispatch(updateBalance(web3.utils.fromWei(balance))))
        .catch((error) => console.log(error.message))
      }
    }, BALANCE_UPDATE_TIME_MS)

    return () => clearInterval(interval);
  }, [user.token, user.address, dispatch]);

  const locationArray: string[] = (location.pathname.slice(1)).split('/')
  const id: string = locationArray[1]
  const reg: RegExp = /^\d+$/

  if (!user?.token) {
    if (!ready) return null
    if (!reg.test(id) || !(location.pathname.startsWith(`/recipe/${id}/`))) {
      return <Redirect to="/" />
    }
  }

  const { width, height } = getWindowDimensions()
  const isMobile = width < 620 && height < 1024

  if (location.pathname.startsWith("/dashboard") || location.pathname.startsWith("/recipe")) {
    if (isMobile) {
      return <Redirect to="/mobile/explore" />
    }
  }

  if (location.pathname.startsWith("/mobile")) {
    if (!isMobile) {
      return <Redirect to="/dashboard/home" />
    }
  }

  return <>{children}</>
}

export default App
