import React from 'react'
import { FormattedMessage } from 'react-intl'
import { getTokenImage } from 'src/api/farms/helpers'
import { TokensToolTip } from './TokensToolTip'
import Tooltip from 'src/components/common/ToolTip/Tooltip'
import { depositPoolsData } from './depositModalHelper'
import checkWhite from 'src/static/images/check1.png'
import checkGrey from 'src/static/images/check2.png'

import './PoolInfo.sass'
import { useSelector } from 'react-redux'
import { Networks } from 'src/utils/networkHelper'
import { PoolProvider } from 'src/routes/RecipeDiagram/helpers/types'
interface PoolInfoProps {
  pair: depositPoolsData
  isChosen: boolean
  setChosenOption: (option: depositPoolsData) => void
  featured?: boolean
}

const PoolInfo = ({ pair, isChosen, setChosenOption, featured }: PoolInfoProps) => {
  const networkId: string = useSelector((s: any) => s.network.network)
  if (pair.provider === 'SpookySwap' || pair.provider === 'SushiSwap') {
    const longPairNames: boolean = pair.tokens[0].symbol.length + pair.tokens[1].symbol.length > 9
    return (
      <li className={`deposit-pool-info-card ${featured ? 'featured' : ''}`}>
        {featured && <span className="featured-pool-tag"><FormattedMessage id="farm-modal.featured" /></span>}
        <section className="token-container">
          <div className="logos">
            <img className="logo" src={getTokenImage(pair.tokens[0].symbol)} />
            <img className="logo" src={getTokenImage(pair.tokens[1].symbol)} />
          </div>
          <h1 className={`pair-names-container ${longPairNames ? 'long' : ''}`}>
            {pair.tokens[0].symbol} - {pair.tokens[1].symbol}
          </h1>
        </section>
        <section className="section-data">
          <h2 className="data-title">
            <span>
              <FormattedMessage id="deposit-lp-modal.apr" />
              <Tooltip tooltipText={[`Fees APR: ${pair.apr}%`]} />
            </span>
          </h2>
          <strong>{(pair.apr)} %</strong>
        </section>
        <section className="section-data">
          <h2 className="data-title">
            <span><FormattedMessage id="deposit-lp-modal.liquidity" /></span>
          </h2>
          <strong >{'$' + parseInt(pair.liquidity).toLocaleString()}</strong>
        </section>
        <section className="section-data">
          <h2 className="data-title">
            <span><FormattedMessage id="deposit-lp-modal.daily-volume-USD" /></span>
          </h2>
          <strong>{'$' + parseInt(pair.volume).toLocaleString()}</strong>
        </section>
        <section className="section-data">
          <h2 className="data-title">
            <span><FormattedMessage id="deposit-lp-modal.provider" /></span>
          </h2>
          <strong>{networkId === Networks.arbitrum && pair.provider === PoolProvider.spooky ? PoolProvider.sushi : pair.provider}</strong>
        </section>
        <section className="button-section">
          <button
            className={isChosen ? 'chosen' : ''}
            onClick={() => {
              setChosenOption(pair)
            }}
          >
            {!isChosen ? <img src={checkGrey} /> : <img src={checkWhite} />}
          </button>
        </section>
      </li>
    )
  } else {
    return (
      <li className={`deposit-pool-info-card ${featured ? 'featured' : ''}`}>
        {featured && <span className="featured-pool-tag"><FormattedMessage id="farm-modal.featured" /></span>}
        <section className="token-container">
          {pair.tokens.length === 2 &&
            <div className="logos">
              <TokensToolTip tokens={[pair.tokens[0]]} />
              <TokensToolTip tokens={[pair.tokens[1]]} />
            </div>}
          {pair.tokens.length > 2 &&
            <div className={`logos`}>
              <img className="logo" src={getTokenImage(pair.tokens[0].symbol)} />
              <TokensToolTip tokens={pair.tokens} />
            </div>}
          <h1 className={`pair-names-container ${pair?.name?.length > 16 ? 'long' : ''}`}>{pair?.name}</h1>
        </section>
        <section className="section-data">
          <h2 className="data-title">
            <span>
              <FormattedMessage id="deposit-lp-modal.apr" />
              <Tooltip tooltipText={[`Fees APR: ${pair.apr}%`]} />
            </span>
          </h2>
          <strong>{(pair.apr)} %</strong>
        </section>
        <section className="section-data">
          <h2 className="data-title">
            <span><FormattedMessage id="deposit-lp-modal.liquidity" /></span>
          </h2>
          <strong >{'$' + parseInt(pair.liquidity).toLocaleString()}</strong>
        </section>
        <section className="section-data">
          <h2 className="data-title">
            <span><FormattedMessage id="deposit-lp-modal.daily-volume-USD" /></span>
          </h2>
          <strong>{'$' + parseInt(pair.volume).toLocaleString()}</strong>
        </section>
        <section className="section-data">
          <h2 className="data-title">
            <span><FormattedMessage id="deposit-lp-modal.provider" /></span>
          </h2>
          <strong>{networkId === Networks.arbitrum && pair.provider === "BeethovenX" ? "Balancer" : pair.provider}</strong>
        </section>
        <section className="button-section">
          <button
            className={isChosen ? 'chosen' : ''}
            onClick={() => {
              setChosenOption(pair)
            }}
          >
            {!isChosen ? <img src={checkGrey} /> : <img src={checkWhite} />}
          </button>
        </section>
      </li>
    )
  }
}
export default PoolInfo
