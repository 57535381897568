import React, { useState } from "react";
import { usePopperTooltip } from "react-popper-tooltip";
import './Tooltip.sass'

interface TooltipProps {
  tooltipText: any
}

const Tooltip = ({ tooltipText }: TooltipProps) => {
  const [isVisible, setIsVisible] = useState(false)
  const {
    getArrowProps,
    getTooltipProps,
    setTooltipRef,
    setTriggerRef,
    visible,
  } = usePopperTooltip({
    trigger: 'hover',
    placement: 'right',
    closeOnOutsideClick: false,
    visible: isVisible,
    onVisibleChange: setIsVisible
  })
  return (
    <div className="tooltip-component">
      <div className="tooltip-icon" ref={setTriggerRef}>
        <div className="question-container">
          <div className="question-content">?</div>
        </div>
      </div>
      {visible && (
        <div
          ref={setTooltipRef}
          {...getTooltipProps({ className: 'tooltip-container' })}
        >
          {tooltipText.map((text, i) => (
            <div key={i}>
              {text}
              {i !== tooltipText.length - 1 ? <br></br> : <></>}
            </div>
          ))}
          <div {...getArrowProps({ className: 'tooltip-arrow' })} />
        </div>
      )}
    </div>
  )
}

export default Tooltip
