import React from "react"
import { FormattedMessage } from "react-intl"
import { registerModal, CloseModal, useModal } from "@gluedigital/modal"
import { useHistory } from 'react-router-dom';
import "./TermsConditionsModal.sass"

const TermsConditionsModal = () => {
  const history = useHistory()
  const modal = useModal()
  const goBackHome = () => { history.push('/') }

  const acceptHandler = () => {
    localStorage.setItem("deviceAccepted", "true")
    history.push('/dashboard/home')
    modal.hide()
  }
  return (
    <div className="terms-and-conditions modal-content">
      <div className="modal-wrapper">
        <header className="header-buttons">
          <CloseModal>
            <button onClick={goBackHome} className="back-button">
              <span className="icon icon-close" />
              <span><FormattedMessage id="close" /></span>
            </button>
          </CloseModal>
        </header>
        <h1 className="terms-and-conditions"><FormattedMessage id="terms-and-conditions.tittle" /></h1>
        <div className="text-wrapper-terms-and-conditions">
          <CloseModal>
            <p className="terms-text"> <FormattedMessage id="terms-and-conditions-text01" /> </p>
            <p className="terms-text"> <FormattedMessage id="terms-and-conditions-text02" /> </p>
            <p className="terms-text"> <FormattedMessage id="terms-and-conditions-text03" /> </p>
            <p className="terms-text"> <FormattedMessage id="terms-and-conditions-text04" /> </p>
            <p className="terms-text"> <FormattedMessage id="terms-and-conditions-text05" /> </p>
            <p className="terms-text"> <FormattedMessage id="terms-and-conditions-text06" /> </p>
          </CloseModal>
          <button onClick={acceptHandler}><FormattedMessage id="accept-terms-and-conditions.apply" /></button>
        </div>
      </div>
    </div>
  )
}

registerModal("terms-and-conditions-modal", TermsConditionsModal)
