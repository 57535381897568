import React from "react"
import { FormattedMessage } from "react-intl"
import { useSelector } from "react-redux"
import { calculateFarmApr, calculateFeeApr } from "src/api/farms/aprHelpers"
import { PairDayData, TokenInfoSpooky } from "src/api/farms/types"
import { useGetTokenPriceUsd } from "src/hooks/useTokensLogsFromGraph"

interface AprV3LiquidityFarmModalLogProps {
    title: string
    myFarm: any
    masterchefInfo: PairDayData
    blockNumberAtOtherMoment: number
}
export const AprV3FarmModalLog = ({ title, myFarm, masterchefInfo, blockNumberAtOtherMoment }: AprV3LiquidityFarmModalLogProps) => {
    const networkId: string = useSelector((s: any) => (s.network.network))

    const splitDecimals = myFarm.reserveUSD.split('.')
    const liquidity = `${splitDecimals[0]}.${splitDecimals[1].slice(0, 2)}`
    const allocPoint: number = Number(masterchefInfo.allocPoint)
    const rewardToken: string = masterchefInfo.tokenReward ? masterchefInfo.tokenReward.toLowerCase() : null
    const rewardTokenData: TokenInfoSpooky = useGetTokenPriceUsd(networkId, rewardToken, blockNumberAtOtherMoment)
    const booPerSecond: number = Number(masterchefInfo.rewardPerSecond)
    const totalAllocPoint: number = Number(masterchefInfo.totalAllocPoint)
    const feeApr: number = calculateFeeApr(myFarm.dailyVolumeUSD, liquidity, networkId)
    let farmApr: number = 0
    if (totalAllocPoint !== 0) {
        farmApr = calculateFarmApr(booPerSecond, allocPoint, totalAllocPoint, parseFloat(rewardTokenData.priceUSD), liquidity)
    }

    const apr: string = (feeApr + farmApr).toFixed(2)
    return (
        <div id="farm-log-field">
            <h3><FormattedMessage id={title} /></h3>
            <strong className="span-coin">{apr} %</strong>
        </div>
    )
}
