import React, { Suspense, useMemo, useState } from "react"
import { FormattedMessage } from "react-intl"
import { useModal, registerModal, CloseModal } from "@gluedigital/modal"
import { ComboTriggerModalPropTypes, ComboTriggerModalData, ConditionProvider } from "src/routes/RecipeDiagram/helpers/types"
import Loading from "src/components/common/Loading/Loading"
import Motivation from "./Motivation/Motivation"

import "./ComboTriggerSentimentModal.sass"
import "./ComboTriggerModal"
import SentimentTriggerInput from "./inputs/SentimentTriggerInput"

const ComboTriggerModalTimeBased = (props: ComboTriggerModalPropTypes) => {
  const { onSave, previousData, avoidLiquidation } = props
  const [triggerData, setTriggerData] = useState<ComboTriggerModalData>(previousData)
  const modal = useModal();
  const InputComponent = SentimentTriggerInput

  const isFormValid = useMemo(() => {
    if (!triggerData) {
      return false
    }
    if (triggerData.conditionProvider === ConditionProvider.sensei) {
      return triggerData.condition?.composition && triggerData.condition?.token
        && triggerData.condition?.volume && triggerData.condition?.sentiment !== undefined
    }
  }, [triggerData])

  const handleClick = () => {
    modal.show('combo-trigger-modal', props)
  }

  return (
    <div className="combo-trigger-modal modal-content">
      <div className="modal-wrapper">
        <header className="header-buttons">
          <div className="back-and-close-buttons">
            <CloseModal className="modal-close-button">
              <button className="modal-close-button">
                <span className="icon icon-close" />
              </button>
            </CloseModal>
            <button className="back-button" onClick={handleClick}>
              <span className="icon icon-angle_left" />
              <span><FormattedMessage id="back" /></span>
            </button>
          </div>
          <span><FormattedMessage id="combo-type-sentiment" /></span>
          <CloseModal>
            <button
              disabled={!isFormValid}
              onClick={() => onSave(triggerData)}
              className="button-filled"
            >
              <FormattedMessage id="save" />
            </button>
          </CloseModal>
        </header>
        <Suspense fallback={<Loading />}>
          <InputComponent triggerData={triggerData} setTriggerData={setTriggerData} avoidLiquidation={avoidLiquidation} />
          <Motivation triggerData={triggerData} setTriggerData={setTriggerData} />
        </Suspense>
      </div>
    </div >
  )
}

registerModal("combo-trigger-modal-sentiment", ComboTriggerModalTimeBased)
