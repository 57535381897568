import abiBPT from './abis/beetsBPT.json'
import web3 from 'src/utils/web3'
import { AbiItem } from 'web3-utils'
import { BeetsPoolsInfo, SubgraphBeetsPoolsInfo } from 'src/api/beets/beetsTypes'
import { FETCH_SINGLE_BEETS_POOL } from 'src/api/beets/beetsQueries'
import fetchGraph from 'src/api/farms/thegraph/fetchGraph'
import { balancerSubgraph } from 'src/constants'

// Permite calculate the unitary price of the BPT token using beets back-end info --> Used for Reaper Nested strategies
export const calculateBPTPriceToReaperVaults = async (addressBPT: string, networkId: string): Promise<string> => {
    const bptContract = new web3.eth.Contract(abiBPT as AbiItem[], addressBPT)
    const pooldId: string = await bptContract.methods.getPoolId().call()
    if (!pooldId) return null

    const query = FETCH_SINGLE_BEETS_POOL(pooldId.toLowerCase())
    const graphInfo: SubgraphBeetsPoolsInfo = await fetchGraph(balancerSubgraph[networkId], query)
    const poolInfo: BeetsPoolsInfo = graphInfo.data.poolGetPool
    const liquidity: string = poolInfo?.dynamicData?.totalLiquidity
    const totalShares: string = poolInfo?.dynamicData?.totalShares
    const lpPrice: number = Number(liquidity) / Number(totalShares)
    return lpPrice.toString()
}
