import React, { useEffect } from "react"
import { FormattedMessage } from "react-intl"
import { registerModal, useModal } from "@gluedigital/modal"

import "./ItWorksModal.sass"

interface ItWorksModalProps {
  type: string
}

const ItWorksModal = ({ type }: ItWorksModalProps) => {
  const modal = useModal()

  useEffect(() => {
    setTimeout(() => modal.hide(), 1000)
  }, [modal])

  return (
    <div className="it-works-modal modal-content">
      <h1>
        <FormattedMessage id={`it-works-modal.title-${type}`} />
      </h1>
    </div>
  )
}

registerModal("it-works-modal", ItWorksModal)
