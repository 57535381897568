import React from 'react'
import MarkdownMessage from "@gluedigital/markdown-message"
import GasEstimationWrapper from './EstimationGasMessage'
import ShowStepMessage, { ShowMessageExecutionReverted } from './ShowStepMessage'
import { RecipeStatus } from 'src/types'
import { DepositSteps, FailSteps } from '../types'
import linkSvg from 'src/static/vectors/link-icon.svg'
import { useSelector } from 'react-redux'
import { getNetworkParams } from 'src/components/modals/SelectWalletModal/helpers'
import { getItemByNetwork } from 'src/utils/networkHelper'

import './DepositStepItem.sass'

export interface DepositStepItemProps {
  numberStepsDone: number
  failStep: number
  setFailStep: (step: number) => void
  setQuoted: (q: boolean) => void
  txHash?: string
  retryCount?: number
  addFundsCoin: string
  recipeStatus: RecipeStatus
  approvedClicked: boolean
  executionClicked: boolean
}

const DepositStepMessages = ({ numberStepsDone, txHash, retryCount, failStep, addFundsCoin, recipeStatus, approvedClicked, setFailStep, setQuoted, executionClicked }: DepositStepItemProps) => {
  const networkId: string = useSelector((s: any) => (s.network.network))
  const networkParams = getNetworkParams(networkId)
  const networkCoin: string = networkParams.nativeCurrency.symbol
  const scanLink: string = !txHash ? networkParams.blockExplorerUrls[0] : `${networkParams.blockExplorerUrls[0]}tx/${txHash}`

  const scanName: string = getItemByNetwork(networkId, "FTMScan", "ARBIScan", "FTMScan")

  return (
    <>
      {numberStepsDone === DepositSteps.composed &&
        <>
          <ShowStepMessage stepName="compose" state="done" />
          <ShowStepMessage stepName="check-balance" state="running" failStep={failStep} />
        </>
      }
      {numberStepsDone === DepositSteps.balanceChecked && !failStep &&
        <>
          <ShowStepMessage stepName="compose" state="done" />
          <ShowStepMessage stepName="check-balance" state="done" />
          {!approvedClicked
            ? <ShowStepMessage stepName="approve" state="running" />
            : <ShowStepMessage stepName="approve-confirmation" state="running" />}
        </>
      }
      {numberStepsDone === DepositSteps.approved && !failStep &&
        <>
          <ShowStepMessage stepName="compose" state="done" />
          <ShowStepMessage stepName="check-balance" state="done" />
          {addFundsCoin !== networkCoin && <ShowStepMessage stepName="approve" state="done" />}
          {addFundsCoin !== networkCoin && !executionClicked
            ? <GasEstimationWrapper setQuoted={setQuoted} setFailStep={setFailStep} step={numberStepsDone} />
            : <ShowStepMessage stepName="execution" state="running" />
          }
        </>
      }
      {numberStepsDone === DepositSteps.executed &&
        <>
          <ShowStepMessage stepName="compose" state="done" />
          <ShowStepMessage stepName="check-balance" state="done" />
          {addFundsCoin !== networkCoin && <ShowStepMessage stepName="approve" state="done" />}
          {retryCount === undefined && <ShowStepMessage stepName="execution" state="done" failStep={failStep} />}
          {(retryCount === undefined || txHash) &&
            <div className="deposit-step-item">
              <div className="row">
                <a href={scanLink} target="_blank" className="ftm-link" rel="noreferrer">
                  <img src={linkSvg} alt="link icon" />
                  <span><MarkdownMessage
                  id="deposit-step-item.state-execution.link"
                  values={{ scan: scanName }} /></span>
                </a>
              </div>
            </div>}
        </>
      }

      {(failStep === FailSteps.rejectedApprove || failStep === FailSteps.estimationFailed || failStep === FailSteps.executionReverted) &&
        <>
          <ShowStepMessage stepName="compose" state="done" />
          <ShowStepMessage stepName="check-balance" state="done" />
          {addFundsCoin !== networkCoin &&
            <>
              {failStep === FailSteps.rejectedApprove && <ShowStepMessage stepName="approve" state="failed" failStep={failStep} />}
              {failStep === FailSteps.estimationFailed &&
                <>
                  <ShowStepMessage stepName="approve" state="done" failStep={failStep} />
                  <ShowStepMessage stepName="quoting" state="failed" failStep={failStep} />
                </>
              }
              {failStep === FailSteps.executionReverted && <ShowStepMessage stepName="approve" state="done" />}
            </>
          }
          {addFundsCoin === networkCoin && <ShowStepMessage stepName="ftm-deposit" state="failed" failStep={failStep} />}
          {retryCount !== undefined && <ShowMessageExecutionReverted recipeStatus={recipeStatus} retryCount={retryCount} />}
        </>
      }
    </>
  )
}

export default DepositStepMessages
