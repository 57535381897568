import React from 'react'
import { FormattedMessage } from 'react-intl'
import Counter from '../Counter/Counter'
import LaunchButton from 'src/components/Landing/LaunchButton/LaunchButton'
import landigBannerInfo from 'src/data/landigBannerInfo.json'

import dragon from 'src/static/images/landing/dragon.svg'
import hanzo from 'src/static/images/landing/hanzo.png'
import ray from 'src/static/images/landing/ray.png'
import psh from 'src/static/images/psh.svg'
import './HeroBanner.sass'

const HeroBanner = () => {
  return (
    <section id="landing-hero-banner" className="landing-section">
      <div className="container">
        <div className="split">
          <div className="item left">
            <img loading="lazy" id="dragon" src={dragon} alt="dragon image" />
            <img loading="lazy" id="ray" src={ray} alt="lighting image" />
            <img loading="lazy" id="hanzo" src={hanzo} alt="hero banner hanzo image" />
          </div>
          <div className="item right">
            <section className="wrapper">
              <h1><FormattedMessage id="landing.hero-banner.title" /></h1>
              <div className="extras">
                <div className="extra">
                  <span className="audited-by"><FormattedMessage id="footer.audited-by" /></span>
                  <img loading="lazy" src={psh} alt="peckShield logo" />
                </div>
              </div>
              <LaunchButton text="landing-hero-banner" />
            </section>
          </div>
        </div>
      </div>
      <footer>
        <div className="container">
          {landigBannerInfo.map(info => (
            <article key={info.name}>
              <header className="hero-banner-amount">
                {info.prefix && <span>{info.prefix}</span>}
                <Counter decimals={info.decimals} amount={info.amount} />
                {info.sufix && <span>{info.sufix}</span>}
              </header>
              <h2>
                <FormattedMessage id={`landing.hero-banner.info-${info.name}`} />
              </h2>
            </article>
          ))}
        </div>
      </footer>
    </section>
  )
}

export default HeroBanner
