import React from 'react'
import { FormattedMessage } from 'react-intl'
import { useParams, Link } from 'react-router-dom'
import { TemporaryExecutionStatus } from 'src/types'
import { useSelector } from 'react-redux'
import { getTokenBalanceList } from 'src/utils/balanceHelper'
import usePromise from 'src/hooks/usePromise'
import { Networks } from 'src/utils/networkHelper'

import './MobileDepositFooter.sass'

interface MobileDepositFooterProps {
  step: number
  searchMode: boolean
  previousData
  tokenSelected: boolean
  quoted: boolean
  executionClicked: boolean
  failStep: number
}

const ButtonDeposit = ({ disabled, text }: { disabled: boolean, text: string }) => {
  return (
    <button disabled={disabled} className="filled">
      <FormattedMessage id={text} />
    </button>
  )
}

const MobileDepositFooter = ({
  step,
  searchMode,
  previousData,
  tokenSelected,
  quoted,
  executionClicked,
  failStep
}: MobileDepositFooterProps) => {
  const { id } = useParams()
  const temporaryStatusFromMap: TemporaryExecutionStatus = useSelector((s: any) => s.blockers.recipesState.get(id))
  const networkId: string = useSelector((s: any) => (s.network.network))
  const networkCoin: string = networkId === Networks.fantom ? "FTM" : "ETH"

  const userInfoAccount = useSelector((s: any) => s.user.account)
  const balanceCoins: any[] = usePromise(getTokenBalanceList, userInfoAccount, networkId)
  const hasBalance = previousData?.amount && (previousData.amount <= (balanceCoins.find(c => previousData?.outputCoin === c.id)?.balance))
  return (
    <footer id="mobile-deposit-colophon">
      {step === 4 &&
        <>
          <Link className="button button-details" to={`/mobile/details/${id}/log`}>
            <span>
              <FormattedMessage id="mobile-recipe-card.button.details" />
            </span>
          </Link>
          <Link className="button button-done" to="/mobile/active">
            <span>
              <FormattedMessage id="mobile-deposit.done" />
            </span>
          </Link>
        </>
      }
      {!searchMode && step === 0
        && <div className="check-balance-wrapper">
          {!hasBalance && <p><FormattedMessage id="deposit-step-item.state-check-balance.failed"/></p>}
           <ButtonDeposit disabled={!hasBalance} text="blocker-execution.continue" />
        </div>
      }
      {!searchMode && previousData?.outputCoin !== networkCoin && step < 3 && step !== 0
        && <ButtonDeposit disabled={!previousData.amount || (step !== 0)} text={tokenSelected ? "token-selector.deposit" : "deposit-step-item.state-approve"} />
      }
      {previousData?.outputCoin !== networkCoin && step === 3
        && <ButtonDeposit disabled={!previousData.amount || !!temporaryStatusFromMap || !quoted || executionClicked || failStep === 3.5} text={failStep === 3 ? "retry-recipe-modal.retry" : "blocker-execution.continue"} />
      }
    </footer>
  )
}

export default MobileDepositFooter
