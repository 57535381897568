import React from 'react'
import { FormattedMessage } from 'react-intl'
import { useLocation } from 'react-router-dom'
import { useRecipeDetails } from 'src/api/recipes'
import { ComboTriggerModalData } from 'src/routes/RecipeDiagram/helpers/types'
import { RecipeDetails } from 'src/types'

import './Motivation.sass'

interface MotivationProps {
  triggerData: ComboTriggerModalData
  setTriggerData: (triggerData: ComboTriggerModalData) => void
}

const Motivation = ({ triggerData, setTriggerData }: MotivationProps) => {
  const location = useLocation()
  const id: number = location.pathname.split("/")[2]
  const recipeDetails: RecipeDetails = useRecipeDetails(id)
  const isOptional: boolean = (!recipeDetails || !recipeDetails?.public)

  const changeHandler = (ev) => setTriggerData({ ...triggerData, motivation: ev.target.value })

  return (
    <div id="motivation-component">
      <section className={`motivation ${isOptional ? "optional-motivation" : "compulsory-motivation"}`}>
        <h2 className={`motivation-title ${isOptional ? "optional-title" : "compulsory-title"}`}><FormattedMessage id="combo-trigger-motivation" />
          {(isOptional) && <span className="optional-word"> <FormattedMessage id="new-recipe.description-optional" /></span>}
        </h2>
        <label>
          <FormattedMessage id="combo-trigger-motivation.placeholder">
            {(text) =>
              <input
                className={`motivation-input ${isOptional ? "optional-input" : "compulsory-input"}`}
                placeholder={text as string}
                type="text"
                onChange={changeHandler}
                value={triggerData?.motivation}
                maxLength={140} />}
          </FormattedMessage>
        </label>
      </section>
    </div>

  )
}

export default Motivation
