import React from "react"
import { FormattedMessage } from "react-intl"
import { ModalErrorBoundary } from "src/components/ErrorBoundary/ErrorBoundary"
import { DepositOnLPModalData } from "src/routes/RecipeDiagram/helpers/types"
import { NodeExecutionResult } from "src/types"
import { NoExecutedDepositModalLog } from "../NoExecutedDepositModalLog"
import { nodesLogsNumber } from "src/constants"
import { useBalancerPoolsFromApi } from "src/api/recipes"
import { adaptBalancerInfo } from "src/api/beets/balancerHelper"
import { BalancerPoolsInfo } from "src/api/beets/balancerTypes"
import { BeetsPoolsInfo, ExtraDataBeetsPools } from "src/api/beets/beetsTypes"
import web3 from "src/utils/web3"

const BalancerDepositModalLog = ({ events, data }: { events: NodeExecutionResult[], data: DepositOnLPModalData }) => {
  const { NO_EXECUTED_NODE, ACTIVE_NODE, FINISHED_NODE, ABORTED_NODE } = nodesLogsNumber
  const DECIMALS_ROUND_TOKEN: number = 12
  const executionSteps: number = events.length

  let amountDeposited: string = '?'
  let lpUSDPriceAtDeposit: number = 0

  if (events !== undefined && events !== null) {
    amountDeposited = events[1].output.amount
    const extraData: ExtraDataBeetsPools = events[1]?.extraData
    lpUSDPriceAtDeposit = Number(extraData?.totalLiquidity) / Number(extraData?.totalShares)
  }
  const amountDepositedEther: string = web3.utils.fromWei(amountDeposited)
  const balancerPoolsFromApi: BalancerPoolsInfo[] = useBalancerPoolsFromApi()
  const adaptedBalancerPools: BeetsPoolsInfo[] = adaptBalancerInfo(balancerPoolsFromApi)
  const currentBalancerPool: BeetsPoolsInfo = adaptedBalancerPools.find(p => p.address.toLowerCase() === data.pair.address)

  const apr: string = executionSteps !== FINISHED_NODE
    ? (Number(currentBalancerPool.dynamicData?.apr?.apr?.total) * 100).toFixed(2)
    : (Number(events[2].extraData.apr) * 100).toFixed(2)
  const liquidity: string = executionSteps !== FINISHED_NODE
    ? (Number(currentBalancerPool.dynamicData?.totalLiquidity24hAgo)).toFixed(2)
    : (Number(events[2]?.extraData?.totalLiquidity)).toFixed(2)

  let lpUSDPriceAtOtherMoment: number = 0
  if (executionSteps === ACTIVE_NODE) {
    lpUSDPriceAtOtherMoment = Number(currentBalancerPool?.dynamicData?.totalLiquidity24hAgo) / Number(currentBalancerPool?.dynamicData?.totalShares)
  } else if (executionSteps === FINISHED_NODE || executionSteps === ABORTED_NODE) {
    const extraData: ExtraDataBeetsPools = events[2]?.extraData
    lpUSDPriceAtOtherMoment = Number(extraData?.totalLiquidity) / Number(extraData?.totalShares)
  }

  let earnedUSDValue: string = (Number(amountDepositedEther) * lpUSDPriceAtOtherMoment - Number(amountDepositedEther) * lpUSDPriceAtDeposit).toFixed(3)
  if (earnedUSDValue === "-0.000") earnedUSDValue = "0.000"

  return (
    <ModalErrorBoundary>
      <section className="deposit-modal-currency">
        {executionSteps === NO_EXECUTED_NODE ? <NoExecutedDepositModalLog text="NoExecuted" />
          :
          <>
            <div id="first-slot">
              <h3><FormattedMessage id="farm-modal-log.deposited" /></h3>
              <strong className="span-coin"> $ {(Number(amountDepositedEther) * lpUSDPriceAtDeposit).toFixed(3)}</strong>
              <span className="span-amount-lp">{Number(amountDepositedEther).toFixed(DECIMALS_ROUND_TOKEN)} <strong>BPT</strong></span>
            </div>

            <div id="second-slot">
              {executionSteps === ACTIVE_NODE &&
                <>
                  <h3><FormattedMessage id="farm-modal-log.current" /></h3>
                  <strong className="span-coin">$ {(Number(amountDepositedEther) * lpUSDPriceAtOtherMoment).toFixed(3)}</strong>
                </>}
              {(executionSteps === FINISHED_NODE || executionSteps === ABORTED_NODE) &&
                <>
                  <h3><FormattedMessage id="farm-modal-log.earned" /></h3>
                  <strong className="span-coin">$ {earnedUSDValue}</strong>
                </>
              }
              <span className="span-amount-lp">{Number(amountDepositedEther).toFixed(DECIMALS_ROUND_TOKEN)} <strong>BPT</strong></span>
            </div>

            <div id="third-slot">
              <h3><FormattedMessage id="farm-modal-log.apr" /></h3>
              <strong className="span-coin">{apr} %</strong>
            </div>

            <div id="fourth-slot">
              <h3><FormattedMessage id="farm-modal-log.liquidity" /></h3>
              <strong className="span-coin">$ {Number(liquidity).toLocaleString()}</strong>
            </div>
          </>}
      </section>
    </ModalErrorBoundary>)
}

export default BalancerDepositModalLog
