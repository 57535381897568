import React, { useEffect, useState } from "react"
import { FormattedMessage } from "react-intl"
import { registerModal, CloseModal } from "@gluedigital/modal"
import { findNodeInActiveRecipe, generateShowingTime, getCurrentEventForModals, getCurrentRowLog } from "src/components/Diagram/nodes/nodesLogsHelper"
import { LiquidateModalData } from "src/routes/RecipeDiagram/helpers/types"
import NodeStateAndDate from "../NodeStateAndDate/NodeStateAndDate"
import { RecipeDetails, RecipeExecutionLog } from "src/types"
import { getEtherNumberAmountFromAddress } from "src/contracts/TokensDecimalsHelpers"

import "./LiquidateModalLog.sass"
import PriceModalLogs from "../../nodeModals/PriceModalLogs"
import { ModalErrorBoundary } from "src/components/ErrorBoundary/ErrorBoundary"

interface LiquidateLogModalProps {
  previousData: {
    data: LiquidateModalData
    id: string
    recipeLogs: RecipeExecutionLog[]
    recipeDetails: RecipeDetails
  }
}

const LiquidateModalLog = (props: LiquidateLogModalProps) => {
  const DECIMALS_FOR_TOKEN_AMOUNT: number = 10
  let isNodeExecuted: boolean
  let currentRowLog: any
  let showingDate: string
  const [resultAmount, setResultAmount] = useState<string>('')
  const { previousData } = props
  const inputCoin = previousData?.data.inputCoin || "BTC"
  const outputCoin = previousData?.data.outputCoin || "BTC"
  const recipeLogs = previousData.recipeLogs
  const nodeId = previousData.id
  const recipeDetails = previousData?.recipeDetails
  const currentEvent = getCurrentEventForModals(recipeLogs, nodeId)

  if (recipeLogs !== null) isNodeExecuted = findNodeInActiveRecipe(recipeLogs, nodeId)
  if (isNodeExecuted) {
    currentRowLog = getCurrentRowLog(recipeLogs, nodeId)
    showingDate = generateShowingTime(currentRowLog)
  }

  const [fee, setFee] = useState(0)

  useEffect(() => {
    if (currentEvent !== undefined) {
      getEtherNumberAmountFromAddress(currentEvent?.output.token, currentEvent?.output?.amount)
        .then((amount) => setResultAmount(Number(amount).toFixed(DECIMALS_FOR_TOKEN_AMOUNT)))
        .catch(err => console.error(err.message))
    }
    getEtherNumberAmountFromAddress(currentEvent?.output.token, currentEvent?.input?.amount.split('.').join('')).then((amount) => setFee(Number(amount))).catch(err => console.error(err.message));
  }, [currentEvent, inputCoin, outputCoin, previousData?.data.inputCoin, previousData?.data.outputCoin])

  return (
    <div className="liquidate-modal modal-content">
      <div className="modal-wrapper">
        <header className="header-buttons">
          <CloseModal>
            <button>
              <span className="icon icon-close" />
              <span><FormattedMessage id="close" /></span>
            </button>
          </CloseModal>
          <h1><FormattedMessage id="liquidate-modal.title" /></h1>
          <NodeStateAndDate isNodeExecuted={isNodeExecuted} showingDate={showingDate} recipeDetails={recipeDetails} />
        </header>
        <section>

          <div className="currency-wrapper">
            <span className="span-selected"><FormattedMessage id="liquidate-modal-log.currency" /></span>
            <div className="div-select-coin">
              <span className="span-coin">Coin</span>
              <span className="span-outputcoin"> {outputCoin}</span>
            </div>
          </div>
        </section>
        <section>
          {currentEvent === undefined ?
            <div className="div-received">
              <span className="span-received"><FormattedMessage id="view-logs-waiting" />   {outputCoin}</span>
            </div>
            :
            <div className="div-received">
              <span className="span-received"><FormattedMessage id="view-logs-received" />   {resultAmount} {outputCoin}</span>
            </div>
          }
          <ModalErrorBoundary>
            {isNodeExecuted &&
              <div className="second-column">
                <PriceModalLogs
                  tokenInput={inputCoin}
                  tokenOutput={outputCoin}
                  inputFeeAmount={fee}
                  inputAmount={currentEvent?.extraData?.inputTokenWithPrice?.priceUSD}
                  outputAmount={currentEvent?.extraData?.outputTokenWithPrice?.priceUSD}
                  events={currentEvent} />
              </div>
            }
          </ModalErrorBoundary>
        </section>
      </div>
    </div>
  )
}

registerModal("liquidate-modal-log", LiquidateModalLog)
