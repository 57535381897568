import React from 'react'
import { getTokenImage } from 'src/api/farms/helpers'

import './TokenList.sass'

const TokenList = ({ tokenList, lite }: {tokenList: string[], lite?: boolean}) => {
  const slicedTokenList = tokenList?.slice(0, 4) || []
  const length = tokenList?.length
  if (!length) return <section />
  return (
    <section className={`token-list-component ${lite ? 'lite' : ''}`}>
      <div className="token-list">
        {slicedTokenList.map(symbol => <img key={symbol} title={symbol} className="logo" src={getTokenImage(symbol)} />)}
      </div>
      {length > 4
        && <div className="rest-tokens">
          <span>{`+${length - 4}`}</span>
        </div>
      }
    </section>
  )
}

export default TokenList
