import React, { Suspense, useReducer, useContext, useMemo } from "react"
import { Connection, getConnectedEdges, XYPosition } from "react-flow-renderer"
import { EdgeElement, NodeElement, NodeModalData } from "./types"
import { useParams } from "react-router-dom";
import { useRecipeDetails } from "src/api/recipes";
import { RecipeDetails } from "src/types";
import { propagateChanges } from "./editNodeHelpers";
import { isConnectionValid } from "./arrowsHelpers";
import { modifyCurrentElementsBeforeAddingEdge } from "./connectionHelpers";
import { modifyElementsBeforeRemove } from "./nodeHelpers";
import { handleNewNodeColor, propagateColor, useColorsMap } from "./useColorMap";

export interface NodesAndEdgesState {
  edges: EdgeElement[]
  nodes: NodeElement[]
  colorsMap: Map<string, string[]>
  hasUnsavedChanges: boolean
}

type DiagramAction = { type: 'addNode', node: NodeElement }
  | { type: 'addEdge', edge: EdgeElement }
  | { type: 'removeNode', nodeID: string }
  | { type: 'editNode', nodeID: string, updatedData: NodeModalData }
  | { type: 'connectNodes', connection: Connection }
  | { type: 'moveNode', nodeID: string, newPosition: XYPosition }
  | { type: 'saveChanges' }

type NodesAndEdgesReducer = (state: NodesAndEdgesState, action: DiagramAction) => NodesAndEdgesState

interface NodesAndEdgesContext {
  nodesState: NodesAndEdgesState
  recipeDetails: RecipeDetails
  dispatch: React.Dispatch<DiagramAction>
}
const NodesAndEdges = React.createContext<NodesAndEdgesContext>(null)

const nodesAndEdgesReducer = (state: NodesAndEdgesState, action: DiagramAction): NodesAndEdgesState => {
  switch (action.type) {
    case 'addNode':
      handleNewNodeColor(state.colorsMap, action.node.type, action.node.id)
      return { ...state, nodes: state.nodes.concat(action.node), hasUnsavedChanges: true }
    case 'addEdge':
      return { ...state, edges: state.edges.concat(action.edge), hasUnsavedChanges: true }
    case 'removeNode': {
      const { nodes, edges, colorsMap } = state
      const nodeToRemove = nodes.find((node) => node.id === action.nodeID)
      const connectedEdgesIDs = getConnectedEdges([nodeToRemove], edges).map((edge) => edge.id)

      colorsMap.delete(nodeToRemove.id)
      let updatedEdges = propagateColor(nodeToRemove, nodes, edges, colorsMap)
      const updatedNodes = modifyElementsBeforeRemove(nodeToRemove, nodes, updatedEdges)
        .filter((node) => node.id !== nodeToRemove.id)
      updatedEdges = updatedEdges.filter((edge) => !connectedEdgesIDs.includes(edge.id))
      return { ...state, nodes: updatedNodes, edges: updatedEdges, hasUnsavedChanges: true }
    }
    case 'editNode': {
      const targetIndex = state.nodes.findIndex((node) => node.id === action.nodeID)
      const nodeToUpdate = state.nodes[targetIndex]
      nodeToUpdate.data = { ...nodeToUpdate.data, ...action.updatedData }
      const updatedNodes = propagateChanges(nodeToUpdate, state.nodes, state.edges)
      updatedNodes[targetIndex] = nodeToUpdate
      return { ...state, nodes: updatedNodes, hasUnsavedChanges: true }
    }
    case 'connectNodes': {
      const { connection } = action
      const { nodes, edges, colorsMap } = state
      try {
        if (!isConnectionValid(state.edges, connection)) {
          return { ...state }
        }
        const newEdge: EdgeElement = {
          id: `reactflow__edge-${connection.source}${connection.sourceHandle}-${connection.target}${connection.targetHandle}`,
          type: "myCustomEdge",
          style: { stroke: '', strokeWidth: 1.5 },
          ...connection
        }
        let updatedEdges = edges.concat(newEdge)
        const sourceNode = nodes.find((node) => node.id === connection.source)
        updatedEdges = propagateColor(sourceNode, nodes, updatedEdges, colorsMap)
        const updatedNodes = modifyCurrentElementsBeforeAddingEdge(nodes, edges, connection)
        return { ...state, nodes: updatedNodes, edges: updatedEdges, hasUnsavedChanges: true }
      } catch (e) {
        console.error('Error detected on connection, edge will not be added')
        return { ...state }
      }
    }
    case 'moveNode': {
      const updatedNodes = state.nodes.map((node) => {
        if (node.id === action.nodeID) {
          return { ...node, position: action.newPosition }
        }
        return node
      })
      return { ...state, nodes: updatedNodes }
    }
    case 'saveChanges':
      return { ...state, hasUnsavedChanges: false }
  }
}

const nodesAndEdgesInitialiser = (colorsMap: Map<string, string[]>, recipeDetails?: RecipeDetails): NodesAndEdgesState => {
  if (!recipeDetails?.code) {
    return { edges: [], nodes: [], colorsMap, hasUnsavedChanges: false }
  }
  const [nodes, edges]: [NodeElement[], EdgeElement[]] = recipeDetails.code.reduce((result, currentElement) => {
    result[currentElement.type === 'myCustomEdge' ? 1 : 0].push(currentElement)
    return result
  }, [[], []])
  let updatedEdges = [...edges]
  const colorSourceNodes = nodes.filter((node) => node.type === 'addFundsNode' || node.type === 'splitNode')
  colorSourceNodes.forEach((colorSourceNode) => handleNewNodeColor(colorsMap, colorSourceNode.type, colorSourceNode.id))
  updatedEdges = colorSourceNodes.reduce((accumulatedEdgeChanges, node) => {
    return propagateColor(node, nodes, accumulatedEdgeChanges, colorsMap)
  }, updatedEdges)
  return {
    nodes,
    edges: updatedEdges,
    colorsMap,
    hasUnsavedChanges: false
  }
}
export const NodesContext: React.FunctionComponent = ({ children }) => {
  const { id } = useParams();

  const recipeDetails = useRecipeDetails(id);
  const { colorsMap } = useColorsMap()

  const initialState = useMemo(() => nodesAndEdgesInitialiser(colorsMap, recipeDetails), [recipeDetails, colorsMap])

  const [nodesState, dispatch] = useReducer<NodesAndEdgesReducer>(nodesAndEdgesReducer, initialState)
  return <Suspense fallback={<div />}>
    <NodesAndEdges.Provider value={{ dispatch, nodesState, recipeDetails }}>
      {children}
    </NodesAndEdges.Provider>
  </Suspense>
}

export const useNodesDispatch = () => useContext(NodesAndEdges).dispatch
export const useNodesState = () => useContext(NodesAndEdges).nodesState
export const useNodesRecipe = () => useContext(NodesAndEdges).recipeDetails
