import React from "react"
import { FormattedMessage } from "react-intl"
import { ReaperSummaryVaultsData } from "src/api/reaperFinance/reaperTypes"
import { getEtherStringAmountFromAddressFixed15 } from "src/contracts/TokensDecimalsHelpers"
import usePromise from "src/hooks/usePromise"
import { NodeToken } from "src/routes/RecipeDiagram/helpers/types"

interface DepositedReaperNestedModalLogProps {
  oldVaultData: ReaperSummaryVaultsData
  depositPriceUSD: string
  amountRFTokensDepositedWei: string
  tokenDepositedName: string
  infoDepositAmountWei: NodeToken

}

const DepositedReaperNestedModalLog = ({ oldVaultData, depositPriceUSD, amountRFTokensDepositedWei, tokenDepositedName, infoDepositAmountWei }: DepositedReaperNestedModalLogProps) => {
  const DECIMAL_USD_ROUNDED: number = 3
  const rfToken: string = "RFT"
  const reaperVaultAddress: string = oldVaultData?.address
  const depositedAmountRFTokens: string = usePromise(getEtherStringAmountFromAddressFixed15, reaperVaultAddress, amountRFTokensDepositedWei)
  const depositedAmountEther: string = usePromise(getEtherStringAmountFromAddressFixed15, infoDepositAmountWei.token.toLowerCase(), infoDepositAmountWei.amount)
  return (
    <div id="nested-log-field">
      <h3><FormattedMessage id="nested-modal-log.deposited" /></h3>
      <strong className="span-coin">$ {Number(depositPriceUSD).toFixed(DECIMAL_USD_ROUNDED)}</strong>
      <div><span className="lp-amount-row">{Number(depositedAmountEther).toFixed(12)} <strong>{tokenDepositedName}</strong></span></div>
      <div><span className="lp-amount-row">{Number(depositedAmountRFTokens).toFixed(12)} <strong>{rfToken}</strong></span></div>
    </div>
  )
}

export default DepositedReaperNestedModalLog
