import React, { useEffect, useState } from "react"
import { FormattedMessage } from "react-intl"
import { useSelector } from "react-redux"
import { useParams } from "react-router-dom"
import { useRecipeDetails, useRecipeLogs } from "src/api/recipes"
import { useRecipeDetailsLive, useRecipeLogsLive } from "src/context/SocketContext"
import { NodeExecutionResult, RecipeExecutionLog } from "src/types"
import { getNumberOfLogsForDepositAndFarms } from "./nodesLogsHelper"
import { nodesLogsNumber } from "src/constants"
import { isPreviousShortLongNode } from "src/routes/Mobile/helpers/getNodesOrderedMap"
import { NodeType } from "src/routes/RecipeDiagram/helpers/types"

interface NodeExecutionTagProps {
  nodeID: string
  maxExecutionSteps?: number
}
enum NodeExecutionStatus {
  failed = 'failed',
  aborted = 'aborted',
  inProgress = 'inprogress',
  inQueue = 'inqueue',
  done = 'done',
  liquidated = "liquidated",
  skipped = "skipped"
}

const NodeExecutionTag = (props: NodeExecutionTagProps) => {
  const { nodeID } = props
  const maxExecutionSteps = props?.maxExecutionSteps
  const { id } = useParams()
  const [hasLogs, setHasLogs] = useState<boolean>(false)
  const [nodeStatus, setNodeStatus] = useState<NodeExecutionStatus>(NodeExecutionStatus.inQueue)
  const [isOwner, setIsOwner] = useState<boolean>(false)
  const account = useSelector((s: any) => (s.user ? s.user.account : undefined))

  const logs = useRecipeLogs(id)
  const recipeDetails = useRecipeDetails(id)
  const liveRecipeLogs = useRecipeLogsLive(id)
  const liveRecipeDetails = useRecipeDetailsLive(id)

  const node = recipeDetails.code.find(node => node.id === nodeID)
  const nodeType: NodeType = node.type

  // Static labels using log info
  useEffect(() => {
    if (liveRecipeLogs.length > 0) {
      const logsFound = liveRecipeLogs.filter((log) => log.events.some((event) => event.nodeID === nodeID))
      if (logsFound) {
        if (maxExecutionSteps && logsFound?.length > 0) {
          const farmAndDepositNumberOfLogs: number = getNumberOfLogsForDepositAndFarms(liveRecipeLogs, nodeID)
          if (farmAndDepositNumberOfLogs === nodesLogsNumber.ACTIVE_NODE) setNodeStatus(NodeExecutionStatus.inProgress)
          if (farmAndDepositNumberOfLogs === nodesLogsNumber.REAPER_ACTIVE_NODE) setNodeStatus(NodeExecutionStatus.inProgress)
          else if (farmAndDepositNumberOfLogs === nodesLogsNumber.FINISHED_NODE) setNodeStatus(NodeExecutionStatus.done)
          else if (farmAndDepositNumberOfLogs === nodesLogsNumber.REAPER_FINISHED_NODE) setNodeStatus(NodeExecutionStatus.done)
          else if (farmAndDepositNumberOfLogs === nodesLogsNumber.SHORT_LONG_FINISHED_NODE && nodeType === "shortLongNode") setNodeStatus(NodeExecutionStatus.done)
          else if (farmAndDepositNumberOfLogs === nodesLogsNumber.ABORTED_NODE) setNodeStatus(NodeExecutionStatus.aborted)
          else if (farmAndDepositNumberOfLogs === nodesLogsNumber.REAPER_ABORTED_NODE && nodeType === "nestedStrategiesNode") setNodeStatus(NodeExecutionStatus.aborted)
        } else if (logsFound?.length > 0) {
          let events: NodeExecutionResult[] = []
          logsFound.forEach(log => { events = events.concat(log.events) })
          const executeClosePerEvent: NodeExecutionResult = events.find(ev => ev.nodeID === nodeID && ev.functionName === "executeClosePerpPosition")
          if (executeClosePerEvent) setNodeStatus(NodeExecutionStatus.aborted)
          else setNodeStatus(NodeExecutionStatus.done)
        }
      }
    }
    if (logs?.length > 0) {
      setHasLogs(logs?.length > 0)
      const staticLogsFound = logs.filter((log) => log.events.some((event) => event.nodeID === nodeID))
      if (staticLogsFound) {
        if (maxExecutionSteps && staticLogsFound?.length > 0) {
          const farmAndDepositNumberOfLogs: number = getNumberOfLogsForDepositAndFarms(logs, nodeID)
          if (farmAndDepositNumberOfLogs === nodesLogsNumber.ACTIVE_NODE) setNodeStatus(NodeExecutionStatus.inProgress)
          if (farmAndDepositNumberOfLogs === nodesLogsNumber.REAPER_ACTIVE_NODE) setNodeStatus(NodeExecutionStatus.inProgress)
          else if (farmAndDepositNumberOfLogs === nodesLogsNumber.FINISHED_NODE) setNodeStatus(NodeExecutionStatus.done)
          else if (farmAndDepositNumberOfLogs === nodesLogsNumber.REAPER_FINISHED_NODE) setNodeStatus(NodeExecutionStatus.done)
          else if (farmAndDepositNumberOfLogs === nodesLogsNumber.SHORT_LONG_FINISHED_NODE && nodeType === "shortLongNode") setNodeStatus(NodeExecutionStatus.done)
          else if (farmAndDepositNumberOfLogs === nodesLogsNumber.ABORTED_NODE) setNodeStatus(NodeExecutionStatus.aborted)
          else if (farmAndDepositNumberOfLogs === nodesLogsNumber.REAPER_ABORTED_NODE && nodeType === "nestedStrategiesNode") setNodeStatus(NodeExecutionStatus.aborted)
        } else if (staticLogsFound?.length > 0) {
          let events: NodeExecutionResult[] = []
          staticLogsFound.forEach(log => { events = events.concat(log.events) })
          const executeClosePerEvent: NodeExecutionResult = events.find(ev => ev.nodeID === nodeID && ev.functionName === "executeClosePerpPosition")
          if (executeClosePerEvent) setNodeStatus(NodeExecutionStatus.aborted)
          else setNodeStatus(NodeExecutionStatus.done)
        }
      }
    }
  }, [logs, liveRecipeLogs, maxExecutionSteps, nodeID, nodeType])

  useEffect(() => {
    if (liveRecipeLogs) {
      const eventFoundForNode: RecipeExecutionLog[] = liveRecipeLogs.filter((log) => log?.events.some((event) => event.nodeID === nodeID)) // this is for the ComboTriggerNodes.
      const lastLiveLog: RecipeExecutionLog = liveRecipeLogs[liveRecipeLogs?.length - 1]
      if (lastLiveLog?.logtype === "aborted" && lastLiveLog?.status === "finished" && lastLiveLog?.trans !== "no-transaction") {
        setNodeStatus(s => s === NodeExecutionStatus.done ? s : NodeExecutionStatus.aborted)
      } else if (eventFoundForNode?.length > 0 && lastLiveLog?.logtype === "execution" && lastLiveLog?.trans !== "no-transaction" && !maxExecutionSteps) {
        setNodeStatus(NodeExecutionStatus.done)
      }
    }
  }, [liveRecipeLogs, nodeID, maxExecutionSteps])

  // Used for Aborted state
  useEffect(() => {
    if (recipeDetails?.status === "finished" || liveRecipeDetails?.status === "finished") {
      if (nodeStatus === NodeExecutionStatus.inQueue || nodeStatus === NodeExecutionStatus.inProgress) {
        if (nodeType === "shortLongNode" && getNumberOfLogsForDepositAndFarms(logs, nodeID) === nodesLogsNumber.SHORT_LONG_FINISHED_NODE) setNodeStatus(NodeExecutionStatus.done)
        else setNodeStatus(s => s === NodeExecutionStatus.done ? s : NodeExecutionStatus.aborted)
      }
    }
  }, [recipeDetails, liveRecipeDetails, logs, nodeID, nodeType, nodeStatus])

  // To add liquidated tag
  useEffect(() => {
    if (logs.length > 0) {
      const liquidatedLog: RecipeExecutionLog = logs.find(log => log.logtype === "liquidated")
      if (liquidatedLog) {
        const isLogForNode: boolean = liquidatedLog?.events?.some(ev => ev.nodeID === nodeID)
        if (isLogForNode) {
          if (nodeType === "shortLongNode") setNodeStatus(NodeExecutionStatus.liquidated)
          const isShortLong: boolean = isPreviousShortLongNode(recipeDetails, nodeID)
          if (isShortLong) setNodeStatus(NodeExecutionStatus.liquidated)
        }
      }
    }
    if (liveRecipeLogs.length > 0) {
      const liquidatedLog: RecipeExecutionLog = liveRecipeLogs.find(log => log.logtype === "liquidated")
      if (liquidatedLog) {
        const isLogForNode: boolean = liquidatedLog?.events?.some(ev => ev.nodeID === nodeID)
        if (isLogForNode) {
          if (nodeType === "shortLongNode") setNodeStatus(NodeExecutionStatus.liquidated)
          const isShortLong: boolean = isPreviousShortLongNode(recipeDetails, nodeID)
          if (isShortLong) setNodeStatus(NodeExecutionStatus.liquidated)
        }
      }
    }
  }, [liveRecipeLogs, logs, nodeID, nodeType, recipeDetails])

  // To add skipped tag
  useEffect(() => {
    if ((liveRecipeLogs.length > 0 || logs.length > 0)) {
      const skippedLog: RecipeExecutionLog[] = liveRecipeLogs.filter(log => log.logtype === "skipped")
      const staticSkippedLog: RecipeExecutionLog[] = logs.filter(log => log.logtype === "skipped")
      if (staticSkippedLog.length > 0) {
        staticSkippedLog.forEach(log => {
          const event: NodeExecutionResult = log.events.find(ev => ev.nodeID === nodeID)
          if (event) setNodeStatus(NodeExecutionStatus.skipped)
        })
      } else if (skippedLog.length > 0) {
        skippedLog.forEach(log => {
          const event: NodeExecutionResult = log.events.find(ev => ev.nodeID === nodeID)
          if (event) setNodeStatus(NodeExecutionStatus.skipped)
        })
      }
    }
  }, [liveRecipeLogs, logs, nodeID])

  useEffect(() => {
    if (recipeDetails?.status === 'failed' || liveRecipeDetails?.status === "failed") {
      setNodeStatus(NodeExecutionStatus.failed)
    }
  }, [recipeDetails, liveRecipeDetails])

  useEffect(() => {
    setIsOwner(account?.account.slice(2) === recipeDetails?.owner)
  }, [account, recipeDetails])

  if ((!hasLogs && recipeDetails?.status !== "failed") || !isOwner) return <></>
  return <span className={`node-upper-tag ${nodeStatus}-tag`}> <FormattedMessage id={`view-log-node-${nodeStatus}`} /></span>
}

export default NodeExecutionTag
