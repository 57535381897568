import { ValidationWarning, warningType } from "src/hooks/useValidator"
import { getPreviousNode } from "../../Mobile/helpers/getNodesOrderedMap"
import { Networks } from "src/utils/networkHelper"

export const getMaxSameFarmWarning = (nodes) => {
  const pairCountMap: Map<string, number> = new Map();

  let warning
  for (const node of nodes) {
    const pairValue = node.data?.pair?.id
    if (pairValue) {
    const currentCount = pairCountMap.get(pairValue) || 0
    pairCountMap.set(pairValue, currentCount + 1)

    if (currentCount + 1 > 1) {
      warning = 'red'
    } else {
      warning = undefined
    }
  }
}
  return { warningType: warning, nodeType: "farmNode" }
}

export const getFarmNestedWarningInBranch = (nodes, edges, networkId: string): ValidationWarning => {
  if (!nodes || !edges) { return null }
  const WARNINGS = {
    fantom_yellow: 3,
    fantom_red: 9,
    arbitrum_yellow: 7,
    arbitrum_red: 10
  }
  const MAXS_FARMS_BEFORE_YELLOW_WARNING: number = networkId === Networks.arbitrum ? WARNINGS.arbitrum_yellow : WARNINGS.fantom_yellow
  const MAXS_FARMS_BEFORE_RED_WARNING: number = networkId === Networks.arbitrum ? WARNINGS.arbitrum_red : WARNINGS.fantom_red
  const endNodes = nodes.filter((node) => node.data.nextConnected === false || node.type === 'sendToWalletNode' || nodes.type === 'liquidateNode')
  let warning: warningType
  let numFarms = 0
  let numNested = 0
  endNodes.forEach((ending) => {
    const temp: any[] = []
    temp.push(ending)
    let hasFarm = false
    let hasNested = false
    let nextPrevious = ending

    while (nextPrevious !== undefined) {
      nextPrevious = getPreviousNode(nodes, edges, temp[temp.length - 1].id)
      if (nextPrevious) {
        temp.push(nextPrevious)
        if (nextPrevious.type === "farmNode") hasFarm = true
        else if (nextPrevious.type === "nestedStrategiesNode") hasNested = true
      } else nextPrevious = undefined
    }
    if (hasFarm) numFarms++
    if (hasNested) numNested++
  })

  if (numFarms > MAXS_FARMS_BEFORE_YELLOW_WARNING && numFarms < MAXS_FARMS_BEFORE_RED_WARNING) warning = 'yellow'
  else if (numFarms >= MAXS_FARMS_BEFORE_RED_WARNING) warning = 'red'
  else warning = undefined

  if (numNested !== 0) {
    if (numFarms + numNested > MAXS_FARMS_BEFORE_YELLOW_WARNING && numFarms + numNested < MAXS_FARMS_BEFORE_RED_WARNING) warning = 'yellow'
    else if (numFarms + numNested >= MAXS_FARMS_BEFORE_RED_WARNING) warning = 'red'
    else warning = undefined
  }
  return { warningType: warning, nodeType: "farmNode" }
}

export const getAddFundsWarning = (nodes): ValidationWarning => {
  const MIN_ADDFUNDS_TO_SHOW_WARNING: number = 2
  const addFundsWarning: ValidationWarning = { warningType: undefined, nodeType: "addFundsNode" }
  if (!nodes) { return addFundsWarning }
  const addFundsNodes = nodes.filter(node => node.type === "addFundsNode")
  if (addFundsNodes.length >= MIN_ADDFUNDS_TO_SHOW_WARNING) {
    addFundsWarning.warningType = "yellow"
    return addFundsWarning
  }
  return addFundsWarning
}

export const getSplitNodeWarning = (nodes, networkId: string): ValidationWarning => {
  const FANTOM_MIN_SPLIT: number = 3
  const ARBITRUM_MIN_SPLIT: number = 10
  const MIN_SPLIT_TO_SHOW_WARNING: number = networkId === Networks.arbitrum ? ARBITRUM_MIN_SPLIT : FANTOM_MIN_SPLIT
  const splitWarning: ValidationWarning = { warningType: undefined, nodeType: "splitNode" }
  if (!nodes) { return splitWarning }
  const splitNodes = nodes.filter(node => node.type === "splitNode")
  if (splitNodes.length >= MIN_SPLIT_TO_SHOW_WARNING) {
    splitWarning.warningType = "yellow"
    return splitWarning
  }
  return splitWarning
}

export const getComboWarning = (nodes): ValidationWarning => {
  const MIN_COMBO_TO_SHOW_WARNING: number = 10
  const comboWarning: ValidationWarning = { warningType: undefined, nodeType: "comboTriggerNode" }
  if (!nodes) { return comboWarning }
  const comboNodes = nodes.filter(node => node.type === "comboTriggerNode")
  if (comboNodes.length >= MIN_COMBO_TO_SHOW_WARNING) {
    comboWarning.warningType = "yellow"
    return comboWarning
  }
  return comboWarning
}

export const isRedWarning = (warnings: ValidationWarning[]): boolean => {
  const isRedWarning: boolean = warnings.some((warning) => warning.warningType === "red")
  return isRedWarning
}

// True if all the warnings are undefined. False if some of them exists
export const isNotWarning = (warnings: ValidationWarning[]): boolean => {
  const isNotWarning: boolean = warnings.every((warning) => warning.warningType === undefined)
  return isNotWarning
}
