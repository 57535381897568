import { useSelector } from "react-redux"
import usePromise from "./usePromise"
import { useTimestampFromBlockNumber } from "./useWeb3"
import pairsAPIFantom from "src/api/farms/pairsApiFantom"
import { getPairsAPI } from "src/api/farms/helpers"

export const usePairDayDataByBlockNumber = (pairId: string, blockNumber: number): any => {
    const networkId: string = useSelector((s: any) => (s.network.network))
    const pairsAPI = getPairsAPI(networkId)
    const timestamp = useTimestampFromBlockNumber(blockNumber)
    const result = usePromise(pairsAPI.getPairDataByBlockNumber, pairId, timestamp)
    return result
}

export const useFarmDataByTimestamp = (timestamp: number, allFarmsLps: string[] | string) => {
    const data = usePromise(pairsAPIFantom.getCurrentFarmsData, timestamp, allFarmsLps)
    return data
}
