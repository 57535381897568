
import React from "react"
import { NodeElement } from "src/routes/RecipeDiagram/helpers/types"
import { RecipeDetails } from "src/types"
import { getNodesOrderedMap, getRootNodes } from "../../helpers/getNodesOrderedMap"
import ShowFlowNode from "./ShowFlowNode/ShowFlowNode"

import "./ViewFlow.sass"

interface ViewFlowProps {
  nodes: NodeElement[]
  recipeDetails: RecipeDetails
}

const ViewFlow = ({ nodes, recipeDetails }: ViewFlowProps) => {
  const rootNodes = getRootNodes(nodes)
  const childMap = getNodesOrderedMap(recipeDetails)
  return (
    <div className="view-flow">
      <div className="mobile-container">
        {rootNodes.map((node) => <ShowFlowNode key={node.id} node={node} childMap={childMap} />)}
      </div>
    </div>
  )
}

export default ViewFlow
