
const initialState = {
  showOnlyVerified: false
}

export default (state = initialState, action) => {
  switch (action.type) {
    case "toggleVerifiedView":
      return { showOnlyVerified: action.payload.showOnlyVerified }
    default:
      return state
  }
}
