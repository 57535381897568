import React from "react"
import { FormattedMessage } from 'react-intl'
import { MobileBrowseSortProps, SortByEnum } from "../types"

import './MobileSortBrowse.sass'

const MobileSortBrowse = ({ sortBy, handleSortBy }: MobileBrowseSortProps) => {
  const options = Object.values(SortByEnum)

  return (
    <div className="mobile-sort-browse">
      <label>
        <strong><FormattedMessage id="mobile-sort-browse" /></strong>
        <select
          name="sort"
          value={sortBy}
          onChange={(e) => {
            const sort = e.target.value as SortByEnum
            handleSortBy(sort)
          }}>
          <FormattedMessage id="select">
            {text => <option value="" hidden>{text}</option>}
          </FormattedMessage>
          {options?.map((o: SortByEnum) => (
            <FormattedMessage key={o} id={`mobile-sort-browse.${o}`}>
              {text => <option value={o}>{text}</option>}
            </FormattedMessage>
          ))}
        </select>
        <span className="icon icon-angle_right" />
      </label>
    </div>
  )
}

export default MobileSortBrowse
