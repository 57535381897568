import React, { Suspense } from "react"
import { FormattedMessage } from "react-intl"
import { APRCondition, ComboTriggerModalData, ConditionProvider, ProfitTriggerData } from "src/routes/RecipeDiagram/helpers/types"
import { useTriggerAPRInput } from "src/components/modals/nodeModals/ComboTriggerModal/hooks/useTriggerAPRInput"
import APRTriggerOptions from "src/components/modals/nodeModals/ComboTriggerModal/inputs/APRTriggerInput/APRTriggerOptions"

import "./SpookyPerformanceType.sass"
// import APRTriggerLabel from "src/components/modals/nodeModals/ComboTriggerModal/inputs/APRTriggerInput/APRTriggerLabel"

interface SpookyPerformanceTypeProps {
  triggerData: ProfitTriggerData
  setTriggerData: (data: ComboTriggerModalData) => void
}

const SpookyPerformanceType = ({ setTriggerData, triggerData }: SpookyPerformanceTypeProps) => {
  const condition = triggerData ? triggerData.condition as APRCondition : {}
  const conditionProvider = ConditionProvider.spookyswap
  const {
    getValue,
    inputCondition,
    inputConditionValue,
    farmInfo
  } = useTriggerAPRInput(triggerData)

  const handleInput = () => {
    const value = getValue()
    setTriggerData({ condition: { ...condition, value }, conditionProvider })
  }
  const handleConditionType = type => {
    setTriggerData({ condition: { ...condition, type }, conditionProvider })
  }
  const handleFarmId = farmID => {
    setTriggerData({ condition: { ...condition, farmID }, conditionProvider })
  }
  return (
    <div className="performance-type performance-type-spooky">
      <div className="row">
        <section>
          <h2>
            {/* <FormattedMessage id="deposit-lp-modal.provider" />
            <FormattedMessage id="spookyswap" />
            <FormattedMessage id="combo-trigger-modal.says" /> */}
            <FormattedMessage id="if" />
          </h2>
          <Suspense fallback={""}>
            <select value={condition?.farmID} onChange={(e) => handleFarmId(e.target.value)}>
              <FormattedMessage id="select.currency">
                {text => <option value="" hidden>{text}</option>}
              </FormattedMessage>
              {farmInfo && <APRTriggerOptions pairs={farmInfo} />}
            </select>
          </Suspense>
        </section>
      </div>
      <div className="row">
        <section>
          <h2><FormattedMessage id="combo-trigger-modal.is" /></h2>
          <select
            value={condition?.type}
            onChange={(e) => {
              const type: any = e.target.value
              handleConditionType(type)
            }}
          >
            <FormattedMessage id="select.currency">
              {text => <option value="" hidden>{text}</option>}
            </FormattedMessage>
            <FormattedMessage id="combo-trigger-modal.lower">
              {(text: string) => <option value="lower">{text}</option>}
            </FormattedMessage>
            <FormattedMessage id="combo-trigger-modal.higher">
              {(text: string) => <option value="higher">{text}</option>}
            </FormattedMessage>
          </select>
        </section>
        <section>
          <h2><FormattedMessage id="combo-trigger-modal.than" /></h2>
          <label>
            <input
              ref={inputCondition}
              value={inputConditionValue}
              onChange={() => {
                handleInput()
              }}
              placeholder="Value..."
              min={0}
              step={0.1}
              pattern="\d*"
              inputMode="decimal"
            />
            <span>%</span>
          </label>
        </section>
      </div>
      {/* <Suspense fallback="">
        {farmInfo && <APRTriggerLabel pairs={farmInfo} farmID={condition?.farmID} />}
      </Suspense> */}
    </div>
  )
}

export default SpookyPerformanceType
