import { useMemo } from "react"
import { depositPoolsData } from "../depositModalHelper"

export const useSearchFilterDepositOnLp = (allPairs: depositPoolsData[], search: string, sort: string, currentPage: number): depositPoolsData[] => {
  const PAGE_SIZE = 9
  const readyPairs = useMemo(() => {
    const updatedPairs = [...allPairs].filter((p) => {
      if (p.provider === "SpookySwap" || p.provider === "SushiSwap") {
        const indexInTokensArray: number = p.tokens.findIndex(token => token.symbol.includes(search) || token.symbol.includes(search.toUpperCase()))
        return (indexInTokensArray !== -1)
      }
      const indexInTokensArray: number = p.tokens.findIndex(token => token.symbol.includes(search) || token.symbol.includes(search.toUpperCase()))
      const name: string = p?.name.toLowerCase()
      const includedInName: boolean = name.includes(search.toLowerCase())
      return (indexInTokensArray !== -1 || includedInName)
    })
    switch (sort) {
      case 'APR':
        updatedPairs.sort((a, b) => Number(b.apr) - Number(a.apr))
        break
      case 'LIQUIDITY':
        updatedPairs.sort((a, b) => Number(b.liquidity) - Number(a.liquidity))
        break
      case 'VOLUME':
        updatedPairs.sort((a, b) => Number(b.volume) - Number(a.volume))
        break
      case 'PROVIDER':
        updatedPairs.sort((a, b) => (a.provider > b.provider) ? 1 : ((b.provider > a.provider) ? -1 : 0))
    }
    return updatedPairs.slice(0, PAGE_SIZE * currentPage)
  }, [currentPage, allPairs, search, sort])
  return readyPairs
}
