import dayjs from 'dayjs'
const BUNDLE_ID = 1

export const NATIVE_TOKEN_PRICE_CURRENT = (): string => {
  return `query bundles {
      bundles(where: { id: ${BUNDLE_ID} }) {
        id
        ethPrice
      }
    }
  `
}

export const FTM_PRICE_BY_BLOCK_NUMBER = (blockNumber: number): string => {
  return `
    query bundle {
        bundle(block: {number: ${blockNumber}},  id: 1 ) {
          id
          ethPrice
          __typename @skip(if: true)
        }
      }
    `
}
export const TOKEN_DERIVED_ETH_BY_BLOCK_NUMBER = (address: string, blockNumber: number): string => {
  return `
  query token{
    token(block: {number: ${blockNumber}}, id:"${address}") {
      symbol
      derivedETH
    }
  }`
}

export const TOKEN_PRICE_USD = (address: string, timestamp: number) => {
  const oneDayAgo = (Math.floor(dayjs().unix() / 86400) - 1) * 86400
  const date: number = timestamp > oneDayAgo ? oneDayAgo : timestamp
  return `
    query tokenDay {
      tokenDayDatas(first: 1, where: {date_gt: ${date}, token:"${address}" }) {
        id
        date
        priceUSD
      }
    }
  `
}

export const TOKEN_DAY_DATA_FIVE_DAYS_AGO = (address: string): string => {
  const fivedaysago = (Math.floor(dayjs().unix() / 86400) - 5) * 86400
  return `
    query tokenDay {
      tokenDayDatas(first: 100, where: {date_gt: ${fivedaysago}, token:"${address}" }) {
        id
        date
        priceUSD
      }
    }
  `
}
