import React, { useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { setNetwork } from 'src/store/actions/network'
import LanguageSelectorDialog from '../LanguageSelectorDialog/LanguageSelectorDialog'
import networkImages from './networkImages.json'
import networkChoice from './networkChoice.json'
import { changeChain } from 'src/components/modals/SelectWalletModal/helpers'
import language from 'src/static/images/network-selector/global.png'

import './NetworkSelector.sass'
import { Networks } from 'src/utils/networkHelper'

const NetworkSelector = () => {
  const dispatch = useDispatch()
  const [chooseLanguage, setChooseLanguage] = useState<boolean>(false)
  const networks = Object.keys(networkChoice)
  const { network } = useSelector((s: any) => (s))
  const selectedNetworkName = Object.keys(Networks).find(key => Networks[key] === network?.network) || networkChoice.Arbitrum.toLocaleLowerCase()

  const handleNetwork = (networkName) => {
    const change = async () => {
      await changeChain(Networks[networkName])
    }
    change().then(() => {
      dispatch(setNetwork(Networks[networkName]))
    }).catch((err) => console.error(err))
  }

  return (
    <>
      <details className="network-selector">
        <summary>
          <img loading="lazy" src={selectedNetworkName && networkImages[selectedNetworkName]} alt={selectedNetworkName + ' symbol'} />
          <span className="icon icon-angle_down" />
        </summary>
        <section>
          <h1>
            <FormattedMessage id="network" />
          </h1>
          <ul className="network-list">
            {networks.map((network) => {
              return (
                <li
                  className={`network-item ${selectedNetworkName === network.toLowerCase() ? 'selected' : ''}`}
                  key={network}
                  onClick={() => handleNetwork(network.toLowerCase())}
                >
                  <img loading="lazy" src={networkImages[network.toLowerCase()]} alt="" />
                  <span>{networkChoice[network]}</span>
                </li>
              )
            })}
          </ul>
          <ul className="language-list">
            <li onClick={() => setChooseLanguage(!chooseLanguage)}>
              <img loading="lazy" src={language} alt="globe symbol" />
              <span><FormattedMessage id="language-selector.text"/></span>
            </li>
          </ul>
        </section>
      </details>
      <LanguageSelectorDialog chooseLanguage={chooseLanguage} setChooseLanguage={setChooseLanguage} />
    </>
  )
}

export default NetworkSelector
