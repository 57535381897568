
import masterchefV2SpookyAbi from 'src/contracts/abis/masterchefV2-spooky.json'
import batchAbi from 'src/contracts/abis/batch.json'
import ttVaultAbi from 'src/contracts/abis/TortleVault.json'
import complexRewarderV2Abi from 'src/contracts/abis/complexRewarderV2.json'
import masterChefSpookyV3Abi from 'src/contracts/abis/masterChefV3.json'
import { NATIVE_TOKEN_PRICE_CURRENT, TOKEN_DERIVED_ETH_BY_BLOCK_NUMBER, TOKEN_DAY_DATA_FIVE_DAYS_AGO, TOKEN_PRICE_USD, } from './thegraph/tokenQueries'
import { getPairDayDataBulk, PAIR_DAY_DATA_BULK_NO_TOKENS, PAIR_5DAY_DATA_BULK, PAIR_DATA_IN_SPECIFIC_TIMESTAMP, CURRENT_ALL_FARMS_DATA } from './thegraph/pairsQueriesV2Spooky'
import { GET_LAST_BLOCK } from './thegraph/pairsQueriesHelper'
import { getDeltaTimestamps } from 'src/utils/dates'
import { multicallv2 } from 'src/contracts/implementations/multicall'
import { BN, currentAPR, getFeesApr, organisedPoolsInfo, pairsTransformerSpooky, poolTransformer } from './farmHelpers'
import { REWARD_ADDRESS, masterchefV2Address, masterchefTheGraphUriFromUniswap, pairTokensFarms, pairTokensPools, SPOOKY_FEE, complexRewarderV2Address, masterchefV3Address } from 'src/constants'
import copy from 'fast-copy'
import { PoolProvider } from 'src/routes/RecipeDiagram/helpers/types'
import web3 from "src/utils/web3"
import { EventData } from 'web3-eth-contract/types/index'
import { FarmData, PoolsInfoObj, SpookyData } from './types'
import { ethers } from 'ethers'
import { getTimestampFromBlockNumber } from './helpers'
import fetchGraph from './thegraph/fetchGraph'
import { Networks } from 'src/utils/networkHelper'
import { tokenNameToAddress } from 'src/components/Diagram/nodes/nodesLogsHelper'
import { getNetworkParams } from 'src/components/modals/SelectWalletModal/helpers'
import { AbiItem } from 'web3-utils'

const pairsAPIFantom = {
  async getPairsDayData (ids: string[], graphUrl: string, isPools = false,) {
    let pairs = null
    let query: string
    let timestamp: number = getDeltaTimestamps().t24h
    let isTokensInfoIntheQuery: boolean = true
    const pairsIdentifier = !isPools ? pairTokensFarms : pairTokensPools
    const pairTokens = JSON.parse(localStorage.getItem(pairsIdentifier))

    if (
      !isPools && pairTokens && pairTokens.spooky &&
      new Date().getSeconds() - new Date(parseInt(pairTokens.spooky?.updated, 10)).getSeconds() < 259200
    ) {
      query = PAIR_DAY_DATA_BULK_NO_TOKENS(timestamp)
      pairs = await fetchGraph(graphUrl, query)
      isTokensInfoIntheQuery = false
    } else {
      query = getPairDayDataBulk(ids, timestamp)
      pairs = await fetchGraph(graphUrl, query)
    }
    if (pairs.data.pairDayDatas.length === 0) {
      const res = await fetchGraph(graphUrl, GET_LAST_BLOCK)
      const lastBlock = res.data._meta.block.number
      const lastBlockTimestamp = await getTimestampFromBlockNumber(lastBlock)
      timestamp = lastBlockTimestamp - 86400
      query = getPairDayDataBulk(ids, timestamp)
      pairs = await fetchGraph(graphUrl, query)
    }
    const result = { data: pairs.data.pairDayDatas, hasTokens: isTokensInfoIntheQuery }
    return result
  },
  // Obtiene la información de los ultimos 5 dias de los pares que mostramos en el modal de farms mediante una query al grafo de spooky.
  async get5DaysPairsDayData (ids: string[], graphUrl: string) {
    const tokensInfoInTheQuery: boolean = true
    const query = PAIR_5DAY_DATA_BULK(ids)
    const pairs = await fetchGraph(graphUrl, query)
    const result = { data: pairs.data.pairDayDatas, hasTokens: tokensInfoInTheQuery }
    return result
  },
  // Obtiene el precio actual en USD de Fantom. La fuente de informacion es el grafo.
  async getCurrentFtmPrice (graphUrl: string) {
    const result = await fetchGraph(graphUrl, NATIVE_TOKEN_PRICE_CURRENT())
    return result?.data?.bundles[0]?.ethPrice
  },
  // Obtiene el precio actual de un token expresado en derivedETH.
  // Se utiliza dentro de la function GetSpookyData para obtener el precio de los tokens de reward de las farms.
  async getCurrentTokenPrice (address: string, graphUrl: string): Promise<number> {
    const resBlock = await fetchGraph(graphUrl, GET_LAST_BLOCK)
    const query = TOKEN_DERIVED_ETH_BY_BLOCK_NUMBER(address, resBlock.data._meta.block.number)
    const result = await fetchGraph(graphUrl, query)
    return Number(result.data.token.derivedETH)
  },
  // Obtiene el precio actual de un token expresado en USD.
  // Esta funcion no se usa actualmente pero para un refactor que tengo en mente de las farms si. Permitiria reducir llamadas al grafo.
  async getTokenUSDPriceByTimestamp (address: string, timestamp: number) {
    const graphUrl = masterchefTheGraphUriFromUniswap[Networks.fantom]
    const query = TOKEN_PRICE_USD(address, timestamp)
    const result = await fetchGraph(graphUrl, query)
    return result
  },
  async getTokenDerivedETHByBlock (token: string, blockNumber: number, graphLastBlock: number) {
    const usefulBlockNumber: number = blockNumber > graphLastBlock ? graphLastBlock : blockNumber
    let address: string = null
    if (token.length === 42) {
      address = token
    } else {
      address = token !== '' ? tokenNameToAddress(token, Networks.fantom).toLowerCase() : null
    }
    if (usefulBlockNumber !== 0 && usefulBlockNumber !== null && usefulBlockNumber !== undefined) {
      const graphUrl = masterchefTheGraphUriFromUniswap[Networks.fantom]
      const query = TOKEN_DERIVED_ETH_BY_BLOCK_NUMBER(address, usefulBlockNumber)
      const tokenData = await fetchGraph(graphUrl, query)
      return tokenData
    }
    return undefined
  },
  // Obtiene el precio de un token en los ultimos 5 dias. Se utiliza para poder mostrar en el modal de las farms el APR correspondiente a los ultimos
  // 5 dias.
  async getFiveDaysTokenPrice (address: string, graphUrl: string) {
    const query = TOKEN_DAY_DATA_FIVE_DAYS_AGO(address)
    const result = await fetchGraph(graphUrl, query)
    return (result.data.tokenDayDatas)
  },
  // No tengo muy claro para que es esta funcion. Pero esta relacionada con MasterchefV2 y sacar las farms definidas ahi.
  async fetchMasterchefSpookyData (poolsIds: any, contractAbi): Promise<any[]> {
    const masterChefAggregatedCalls = poolsIds
      .filter((masterChefCall: any) => masterChefCall[0] !== null && masterChefCall[1] !== null)
      .flat()
    return await multicallv2(Networks.fantom, contractAbi, masterChefAggregatedCalls)
  },
  async getPoolsInfo (poolsIds, contractAbi) {
    try {
      const prePoolsInfo = []
      const ids: string[] = []
      const result = await this.fetchMasterchefSpookyData(poolsIds, contractAbi)
      for (let x = 0; x < result.length; x += 3) prePoolsInfo.push(poolTransformer(result[x], result[x + 1], Networks.fantom))
      for (const i of prePoolsInfo) {
        prePoolsInfo[i.lpToken.toLowerCase()] = { allocPoint: i.allocPoint, }
        ids.push(i.lpToken.toLowerCase())
      }
      return [prePoolsInfo, ids]
    } catch (err) {
      return this.getPoolsInfoFromServer()
    }
  },
  async getPoolsByIds (contract, storedData, address: string) {
    const poolsInfoIds = []
    let poolsLength: number
    if (storedData.poolLength) poolsLength = storedData.poolsLength
    else poolsLength = await contract.methods.poolLength().call()
    for (let x = 0; x < poolsLength; x++) {
      poolsInfoIds.push([
        { params: [x], address, name: 'poolInfo' },
        { params: [x], address, name: 'lpToken' },
        { address, name: 'totalAllocPoint' },
      ])
    }
    return poolsInfoIds
  },
  // Obtiene un array con todos los tokens de recompensa de las farms ordenados segun el id de la farm.
  // Se utiliza a la hora de calcular el APR de las farms.
  // La info se saca de la blockchain, del contrato de masterchefV2
  async getTokensAddressForFarmsRewards (contract): Promise<string[]> {
    const tokensAddressForFarmRewards: string[] = []
    const poolsLength = await contract.methods.poolLength().call()
    for (let i = 0; i < poolsLength; i++) {
      tokensAddressForFarmRewards.push((await contract.methods.rewarder([i]).call()).toLowerCase())
    }
    return tokensAddressForFarmRewards
  },
  // Obtiene las farms correspondientes al contrato de masterchefV3
  async getMasterChefV3Data (masterchefV2_complex, complexRewarder) {
    const poolsInfoV3: PoolsInfoObj = {}
    const poolsLength = await masterchefV2_complex.methods.poolLength().call()
    for (let i = 0; i < poolsLength; i++) {
      poolsInfoV3[(await masterchefV2_complex.methods.lpToken([i]).call()).toLowerCase()] = { allocPoint: (await complexRewarder.methods.poolInfo([i]).call()).allocPoint, }
    }
    return poolsInfoV3
  },
  async getSpookyData (_spookyData, blockNumber = 0, isPools = false, pairID) {
    const masterchefSpookyV2 = new web3.eth.Contract(masterchefV2SpookyAbi as AbiItem[], masterchefV2Address[Networks.fantom])
    const complexRewarder = new web3.eth.Contract(complexRewarderV2Abi as AbiItem[], complexRewarderV2Address[Networks.fantom])
    const masterChefSpookyV3 = new web3.eth.Contract(masterChefSpookyV3Abi as AbiItem[], masterchefV3Address[Networks.fantom])
    const poolsInfoV2ById = await this.getPoolsByIds(masterchefSpookyV2, _spookyData, masterchefV2Address[Networks.fantom])
    const poolsInfoDataV2: any = await this.getPoolsInfo(poolsInfoV2ById, masterchefV2SpookyAbi)
    const poolsInfoV3: PoolsInfoObj = await this.getMasterChefV3Data(masterChefSpookyV3, complexRewarder)
    const poolsInfoV2: PoolsInfoObj = poolsInfoDataV2[0]
    let farmAddressOrderedByPoolId: string[] = poolsInfoDataV2[1]
    const idsWithNullAllocPoint: string[] = []
    const newLpTokenIdsToAdd: string[] = []
    const graphUrl = masterchefTheGraphUriFromUniswap[Networks.fantom]

    for (const lpToken in poolsInfoV2) {
      if (poolsInfoV2[lpToken].allocPoint === '0' && lpToken.length === 42) {
        idsWithNullAllocPoint.push(lpToken)
      }
    }
    for (const newLp in poolsInfoV3) {
      if (newLp.length === 42) {
        if (poolsInfoV3[newLp].allocPoint !== '0') {
          if (!idsWithNullAllocPoint.includes(newLp)) newLpTokenIdsToAdd.push(newLp)
        }
      }
    }
    farmAddressOrderedByPoolId = farmAddressOrderedByPoolId.concat(newLpTokenIdsToAdd)
    let spookyData: SpookyData = {
      booPerSecond: null,
      otherTokenPerSecond: null,
      totalAllocPointBOO: null,
      totalAllocPointOther: null,
      ethPrice: null,
      booData: null,
      pairsDayData: null,
      booPriceUsd: null,
      otherTokenAddress: null,
      otherTokenPriceUsd: null,
      tokensAddressForFarmRewards: null
    }
    if (new Date().getSeconds() - _spookyData.update?.getSeconds() < 180) spookyData = _spookyData
    else {
      if (!_spookyData.booPerSecond) {
        spookyData.booPerSecond = await masterchefSpookyV2.methods.booPerSecond().call()
      }
      if (!_spookyData.otherTokenPerSecond) {
        spookyData.otherTokenPerSecond = await complexRewarder.methods.rewardPerSecond().call()
      }
      if (!_spookyData.otherTokenAddress) {
        spookyData.otherTokenAddress = (await complexRewarder.methods.rewardToken().call()).toLowerCase()
      }
      if (!_spookyData.totalAllocPointBOO) {
        spookyData.totalAllocPointBOO = await masterchefSpookyV2.methods.totalAllocPoint().call()
      }
      if (!_spookyData.totalAllocPointOther) {
        spookyData.totalAllocPointOther = await complexRewarder.methods.totalAllocPoint().call()
      }
      if (!_spookyData.tokensAddressForFarmRewards) {
        spookyData.tokensAddressForFarmRewards = await this.getTokensAddressForFarmsRewards(masterchefSpookyV2)
      }
      if (blockNumber !== 0) {
        spookyData.ethPrice = await this.getCurrentFtmPrice(graphUrl)
        spookyData.booData = await this.getCurrentTokenPrice(REWARD_ADDRESS[Networks.fantom].toLowerCase(), graphUrl)
        spookyData.otherTokenPriceUsd = (await this.getCurrentTokenPrice(spookyData.otherTokenAddress, graphUrl)) * spookyData.ethPrice
        spookyData.booPriceUsd = spookyData.booData * spookyData.ethPrice
        spookyData.pairsDayData = await this.getPairDataByBlockNumber(pairID, await getTimestampFromBlockNumber(blockNumber))
        console.log(spookyData.pairsDayData)
        const poolsData = pairsTransformerSpooky(spookyData, poolsInfoV2, farmAddressOrderedByPoolId, poolsInfoV3, isPools, Networks.fantom, blockNumber)
        return { poolsData, data: spookyData }
      } else {
        spookyData.otherTokenPriceUsd = await this.getFiveDaysTokenPrice(spookyData.otherTokenAddress, graphUrl)
        spookyData.booPriceUsd = await this.getFiveDaysTokenPrice(REWARD_ADDRESS[Networks.fantom].toLowerCase(), graphUrl)
        spookyData.pairsDayData = await this.get5DaysPairsDayData(farmAddressOrderedByPoolId, graphUrl)
        const poolsData = pairsTransformerSpooky(spookyData, poolsInfoV2, farmAddressOrderedByPoolId, poolsInfoV3, isPools, Networks.fantom)
        return { poolsData, data: spookyData }
      }
    }
  },
  // Es la funcion que se llama desde el modal de farms para que se ejecuten el resto de funciones que permiten obtener toda la informacion
  // los parametros de _pools y _spookyData son estos que habiamos visto que se cogian con el useSelector y demas.
  async getFarmPairs (blockNumber = 0, pairId = null) {
    const spookyData = await this.getSpookyData({}, blockNumber, false, pairId)
    const result = organisedPoolsInfo([...spookyData.poolsData])
    result.sort((a, b) => b.aprFarm + b.aprFees - (a.aprFarm + a.aprFees))
    return {
      spookyData: {
        data: spookyData.data,
        updated: new Date(),
      },
      pools: {
        data: result,
        updated: new Date(),
      },
    }
  },
  // Es la funcion que se llama desde el modal de deposit para obtener la informacion de todas las pools que se muestran.
  // Actualmente podriamos quitarlos parametros de entrada porque realmente son valores vacios que no hacen nada
  async getPoolPairs (pools) {
    if (new Date().getSeconds() - pools.update?.getSeconds() < 180) return pools

    const graphUrl = masterchefTheGraphUriFromUniswap[Networks.fantom]
    const pairsData = await this.getPairsDayData([], graphUrl, true)
    const pairsWithProvider = []
    for (const pair of pairsData.data) {
      const pairWithProvider = copy(pair)
      pairWithProvider.provider = PoolProvider.spooky
      pairWithProvider.aprFees = getFeesApr(pair, SPOOKY_FEE)
      pairsWithProvider.push(pairWithProvider)
    }
    return {
      pools: {
        data: pairsWithProvider,
        updated: new Date(),
      },
    }
  },
  // Permite obtener informacion de un pair en un determinado instante de tiempo a traves del timestamp.
  // Se utiliza para los logs de las farms y los nodos de deposit. Para mostrar el apr y liquidez en el instante de tiempo cuando termino la ejecucion.
  async getPairDataByBlockNumber (pairId: string, timestamp: number) {
    const graphUrl = masterchefTheGraphUriFromUniswap[Networks.fantom]
    const query = PAIR_DATA_IN_SPECIFIC_TIMESTAMP(pairId, timestamp)
    const result = await fetchGraph(graphUrl, query)
    return result
  },
  // Function utiliza para mostrar la info de los logs del pair que estamos utilizadno en un nodo de deposit. En este caso solo obtiene la info de este par en concreto.
  async getSinglePairForLogs (pairID: string, blockNumber: number) {
    const graphUrl = masterchefTheGraphUriFromUniswap[Networks.fantom]

    if (blockNumber === null) {
      const res = await fetchGraph(graphUrl, GET_LAST_BLOCK)
      blockNumber = res.data._meta.block.number
    }
    let timestamp = await getTimestampFromBlockNumber(blockNumber)
    let pairData = await this.getPairDataByBlockNumber(pairID, timestamp)
    while (pairData.data.pairDayDatas.length === 0) {
      const res = await fetchGraph(graphUrl, GET_LAST_BLOCK)
      const lastBlock = res.data._meta.block.number
      const lastBlockTimestamp = await getTimestampFromBlockNumber(lastBlock)
      timestamp = lastBlockTimestamp - 86400
      pairData = await this.getPairDataByBlockNumber(pairID, timestamp)
    }
    const pair = pairData.data.pairDayDatas[0]
    const poolAPR = getFeesApr(pair, SPOOKY_FEE)
    return { ...pair, poolAPR }
  },
  async getCurrentFarmsData (timestamp: number, allFarmsLps: string[] | string) {
    const graphUrl = masterchefTheGraphUriFromUniswap[Networks.fantom]
    const query = CURRENT_ALL_FARMS_DATA(timestamp, allFarmsLps)
    const result = await fetchGraph(graphUrl, query)
    return result
  },
  // Es la funcion que se utiliza para los logs de las farms para mostrar la cantidad de LP depositada y ganada.
  // La idea de esta funcion es leer los eventos directamente de la blockchain y devolver esos valores de LPs.
  async lpEarned (data: any, executionSteps: number): Promise<FarmData> {
    const ACTIVE_NODE: number = 2
    const FINISHED_NODE: number = 4
    const filter = {
      fromBlock: 0,
      toBlock: 'latest',
    }
    const abiEv = [
      {
        indexed: false,
        internalType: "address",
        name: "user",
        type: "address"
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "amount",
        type: "uint256"
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "total",
        type: "uint256"
      }
    ]
    const nodesList = { ...data.recipeDetails.code }
    const nodes = {}
    for (let x = 0; x < nodesList.length; x++) {
      nodes[nodesList[x].id] = nodesList[x]
      nodes[nodesList[x].id].position = x
    }

    const networkParams = getNetworkParams(Networks.fantom)
    const batch = new web3.eth.Contract(batchAbi as any, networkParams.contracts.batch)
    const eventsTtDeposited: EventData[] = await batch.getPastEvents('ttDeposited', filter)
    const event = (eventsTtDeposited.filter((event) => {
      return (event.returnValues.id === web3.utils.keccak256(data.id))
    }))[0]
    const vaultAddr = event?.returnValues?.ttVault
    const ttShares = event?.returnValues?.amount
    const logsDeposit = (await web3.eth.getTransactionReceipt(event?.transactionHash)).logs
    const logsVaultDeposit = logsDeposit.filter((log) => { return log.address.toLowerCase() === vaultAddr.toLowerCase() })
    const decodedEvDeposit = web3.eth.abi.decodeLog(abiEv, logsVaultDeposit[1].data, logsVaultDeposit[1].topics);
    const lpDeposited = BN(decodedEvDeposit[1])

    if (executionSteps === FINISHED_NODE) {
      const eventsTtWithdrawed: EventData[] = await batch.getPastEvents('ttWithdrawed', filter)
      const eventWithdraw = (eventsTtWithdrawed.filter((e) => {
        return e.returnValues.id === web3.utils.keccak256(data.id)
      }))[0]
      const logs = (await web3.eth.getTransactionReceipt(eventWithdraw?.transactionHash)).logs
      const logsVault = logs.filter((log) => { return log.address.toLowerCase() === vaultAddr.toLowerCase() })
      const decodedEvWithdraw = web3.eth.abi.decodeLog(abiEv,
        logsVault[1].data,
        logsVault[1].topics);
      const lpWithdrawed = BN(decodedEvWithdraw[1])
      const _earned = ethers.utils.formatUnits(lpWithdrawed.sub(lpDeposited), 'ether')
      const earned = `${(+_earned).toFixed(12)} lp`
      const lpDepositedEth = ethers.utils.formatUnits(lpDeposited, 'ether')
      const currentApr = currentAPR(+lpDepositedEth, +_earned, data.date).toFixed(2)
      return {
        state: 'finished',
        lpDeposited: `${(+lpDepositedEth).toFixed(12)} lp`,
        earned,
        currentApr
      }
    }
    if (executionSteps === ACTIVE_NODE) {
      const vault = new web3.eth.Contract(ttVaultAbi as any, vaultAddr)
      const ttPrice = await vault.methods.getPricePerFullShare().call()
      const lpNow = BN(ttPrice).mul(BN(ttShares)).div(BN(10 ** 18))
      const _earned = ethers.utils.formatUnits(lpNow.sub(lpDeposited), 'ether')
      const earned = `${(+_earned).toFixed(12)}`
      // if (lpNow.sub(lpDeposited).lte(BN(0))) earned = 'Not enough data'
      const lpDepositedEth = ethers.utils.formatUnits(lpDeposited, 'ether')
      const currentApr = currentAPR(+lpDepositedEth, +_earned, data.date).toFixed(2)
      return {
        state: 'running',
        lpDeposited: `${(+lpDepositedEth).toFixed(12)} lp`,
        earned,
        currentApr
      }
    }
  }
}

export default pairsAPIFantom
