
export const addNewComboConfig = (combosInfo: Map<string, any>, code) => {
    const newCode = code.map((node) => {
        if (!combosInfo.has(getOriginalNodeId(node.id))) return node
        else {
            const newComboInfo = combosInfo.get(getOriginalNodeId(node.id))
            return {
                ...node,
                data: {
                    ...node.data,
                    condition: newComboInfo.condition,
                    conditionProvider: newComboInfo.conditionProvider
                }
            }
        }
    })
    return newCode
}

export const getOriginalNodeId = (nodeId: string): string => {
    const positionWhereIdStarted: number = nodeId.indexOf('_') + 1
    const cleanNodeId: string = nodeId.substring(positionWhereIdStarted)
    return cleanNodeId
}
