import React, { Suspense } from "react"
import RecipeList from "src/components/RecipeList/RecipeList"
import { FormattedMessage } from 'react-intl'
import { usePeriodicRecipes } from "src/api/recipes"
import NoRecipes from "../NoRecipes/NoRecipes"
import Loading from "src/components/common/Loading/Loading"

const YourRecipes = () => {
  const recipes = usePeriodicRecipes("draft,ready")
  if (recipes.length === 0) return <NoRecipes />

  return (
    <section id="your-recipes" className="recipes-section">
      <h1>
        <FormattedMessage id="recipes-section.your-recipes.title" values={{ n: recipes.length }} />
      </h1>
      <RecipeList recipes={recipes} type={"your"} />
    </section>
  )
}

const YourRecipesWrapper = (props) => {
  return (
    <Suspense fallback={<Loading />}>
      <YourRecipes {...props} />
    </Suspense>
  )
}

export default YourRecipesWrapper
