import React from 'react'

import './ProgressBarBlocker.sass'

interface ProgressProps {
  complete: boolean
}

const ProgressBarBlocker = ({ complete = false }: ProgressProps) => {
  return (
    <div id="progress-blocker">
      <div className="bar">
        <div className={`fill ${complete ? 'complete' : 'progressing'}`} />
      </div>
    </div>
  )
}

export default ProgressBarBlocker
