import React, { Suspense } from "react"
import { FormattedMessage } from "react-intl"
import { tokenAddressToName } from "src/components/Diagram/nodes/nodesLogsHelper"
import { NodeExecutionResult } from "src/types"
import { ActiveYearnLeftLog } from "./ActiveYearnLeftLog"
import { EndYearnLeftLog } from "./EndYearnLeftLog"
import { nodesLogsNumber } from "src/constants"
import { NestedExtraDataLogProps } from "../NestedExtraDataLog"
import { findNestedProvider } from "../nestedLogHelpers"
import { YearnSummaryVaultsData } from "src/api/yearnVaults/yearnTypes"
import { ReaperSummaryVaultsData } from "src/api/reaperFinance/reaperTypes"

import 'src/routes/RecipeDiagram/SideBarViewLogs/SidebarViewLogs.sass'
import { useSelector } from "react-redux"

const YearnExtraDataLog = ({ previousData }: NestedExtraDataLogProps) => {
  const networkId: string = useSelector((s: any) => (s.network.network))

  const DECIMAL_USD_ROUNDED: number = 4

  let executionSteps: number = 0
  let depositAmount: number
  let depositPriceUSD: string
  let amountYTokensDeposited: string
  const oldVaultData: YearnSummaryVaultsData | ReaperSummaryVaultsData = findNestedProvider(previousData.data, previousData.type)
  const nodeEvents: NodeExecutionResult[] = previousData.events
  const yVaultAddress: string = oldVaultData.address
  const vaultTokenAddress: string = oldVaultData.token?.address
  const vaultTokenName: string = tokenAddressToName(vaultTokenAddress, networkId)

  if (nodeEvents.length > nodesLogsNumber.NO_EXECUTED_NODE) {
    executionSteps = nodeEvents.length
    const depositEvent: NodeExecutionResult = nodeEvents.find((event) => event.functionName === "depositOnNestedStrategy")
    amountYTokensDeposited = depositEvent?.output?.amount
    const swapDepositEvent: NodeExecutionResult = nodeEvents.find((event) => event.functionName === 'swapTokens' && event.nodeID === previousData?.id)
    depositAmount = swapDepositEvent?.output?.amount
    depositPriceUSD = swapDepositEvent?.extraData?.outputTokenWithPrice?.totalValueUSD === "0.000000000"
      ? swapDepositEvent?.extraData?.inputTokenWithPrice?.totalValueUSD
      : swapDepositEvent?.extraData?.outputTokenWithPrice?.totalValueUSD
  }

  return (
    <div className="div-extra-info">
      <div className="first-column">
        <div className="logs-group-rows">
          <p><FormattedMessage id="farm-modal-log.deposited" />  </p>
          <div className="left-empty-position"></div>
          <div className="left-empty-position"></div>
        </div>
        {executionSteps === nodesLogsNumber.ACTIVE_NODE && <div className="logs-group-rows">
          <p><FormattedMessage id="farm-modal-log.current-value" />  </p>
          <div className="left-empty-position"></div>
        </div>}
        {(executionSteps === nodesLogsNumber.FINISHED_NODE || executionSteps === nodesLogsNumber.ABORTED_NODE) && <div className="logs-group-rows">
          <p><FormattedMessage id="farm-modal-log.final-value" />  </p>
          <div className="left-empty-position"></div>
        </div>}
        <div className="logs-group-rows">
          {executionSteps === nodesLogsNumber.NO_EXECUTED_NODE && <p><FormattedMessage id="farm-modal-log.earned" />  </p>}
          {(executionSteps === nodesLogsNumber.FINISHED_NODE || executionSteps === nodesLogsNumber.ABORTED_NODE || executionSteps === nodesLogsNumber.ACTIVE_NODE) && <p><FormattedMessage id="farm-modal-log.earned" />  </p>}
          <div className="left-empty-position"></div>
        </div>
      </div>

      <div className="second-column">
        <div className="logs-group-rows">
          <p className="main-text-logs">$ {(parseFloat(depositPriceUSD)).toFixed(DECIMAL_USD_ROUNDED)} </p>
          <p> {(Number(amountYTokensDeposited))} {oldVaultData.symbol}</p>
          <p> {(Number(depositAmount))} {vaultTokenName}</p>
        </div>
        {executionSteps === nodesLogsNumber.ACTIVE_NODE &&
          <Suspense fallback={"Loading values..."}>
            <ActiveYearnLeftLog yVaultAddress={yVaultAddress} vaultTokenName={vaultTokenName} depositPriceUSD={depositPriceUSD} amountYTokensDepositedEth={amountYTokensDeposited} depositTokenAmountEth={depositAmount} />
          </Suspense>}
        {(executionSteps === nodesLogsNumber.FINISHED_NODE || executionSteps === nodesLogsNumber.ABORTED_NODE) &&
          <Suspense fallback={"Loading values..."}>
            <EndYearnLeftLog yVaultAddress={yVaultAddress} vaultTokenName={vaultTokenName} depositPriceUSD={depositPriceUSD} depositTokenAmountEth={depositAmount} amountYTokensDepositedEth={amountYTokensDeposited} previousData={previousData} nodeEvents={nodeEvents} />
          </Suspense>}
      </div>
    </div>
  )
}

export default YearnExtraDataLog
