const fetchGraph = async (graphUrl: string, query: string): Promise<any> => {
  const options = {
    method: "POST",
    headers: { 'Content-Type': 'application/json; charset=utf-8' },
    body: JSON.stringify({ query })
  }
  try {
    const res = await fetch(graphUrl, options)
    if (res.ok) {
      const data = await res.json()
      return data
    } else return undefined
  } catch (error) {
    console.error("Error fetching graph:", error.message)
    return undefined
  }
}

export default fetchGraph
