import { SpookyVaultsInfo } from "src/api/farms/types"
import { propagateChanges } from "src/routes/RecipeDiagram/helpers/editNodeHelpers"
import { NodeElement } from "src/routes/RecipeDiagram/helpers/types"

export const removeOldAddFundsMobile = (code) => {
  const addFundsNode = code.find((node) => node.type === "addFundsNode")
  const indexOfAddFunds: number = code.indexOf(addFundsNode)
  if (indexOfAddFunds !== -1) {
    code.splice(indexOfAddFunds, 1)
  }
  return code
}

export const addNewAddFundsMobile = (code, newCode, weiAmount, outputCoin) => {
  const addFundsNode = code.find((node) => node.type === "addFundsNode")
  const newAddFunds = {
    ...addFundsNode,
    data:
    {
      ...addFundsNode.data,
      amount: weiAmount,
      outputCoin
    }
  }
  newCode.push(newAddFunds)
  return newCode
}

export const getAddFundsAmount = (recipe): string => {
  const addFundsNode = recipe.code.find((node) => node.type === "addFundsNode")
  return addFundsNode.data.amount
}

export const updateContractAddress = (newCode, networkParams) => {
  const codeUpdated = newCode.map((element) => {
    const { data, id, type, position } = element
    if (element.type !== "farmNode" && element.type !== "myCustomEdge") {
      const newData = {
        ...data,
        contracts: {
          batch: networkParams.contracts.batch,
          nodes: networkParams.contracts.nodes
        }
      }
      const newNode = { data: newData, id, type, position }
      return newNode
    } else if (element?.type === "myCustomEdge") return element
    else {
      let desiredVault: SpookyVaultsInfo
      const vaultsV2: SpookyVaultsInfo[] = networkParams.contracts.vaults
      desiredVault = vaultsV2.find((vault) => vault.token0 === data?.pair?.token0 && vault.token1 === data?.pair?.token1)
      if (desiredVault === undefined) {
        const vaultsV3: SpookyVaultsInfo[] = networkParams.contracts.vaultsV3
        desiredVault = vaultsV3.find((vault) => vault.token0 === data?.pair?.token0 && vault.token1 === data?.pair?.token1)
      }
      const newData = {
        ...data,
        contracts: {
          batch: networkParams.contracts.batch,
          nodes: networkParams.contracts.nodes,
          vault: desiredVault.address,
          strategy: desiredVault.strategy
        }
      }
      const newNode = { data: newData, id, type, position }
      return newNode;
    }
  })
  return codeUpdated
}

export const propagateAddFundsCoinChanges = (newCodeWithContracts: any[]): any[] => {
  const nodes: NodeElement[] = newCodeWithContracts.filter((el) => el.type !== "myCustomEdge")
  const edges: any[] = newCodeWithContracts.filter((el) => el.type === "myCustomEdge")
  const updatedNodes: NodeElement[] = propagateChanges(nodes[nodes.length - 1], nodes, edges) // the first parameter is the AddFundsNode which has been added at last position
  const updatedNewCode = updatedNodes.concat(edges)
  return updatedNewCode
}
