import React, { useEffect, useState } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import { registerModal, CloseModal } from "@gluedigital/modal"
import { findNodeInActiveRecipe, generateShowingTime, getCurrentEventForModals, getCurrentRowLog } from "src/components/Diagram/nodes/nodesLogsHelper"
import { SwapModalData } from "src/routes/RecipeDiagram/helpers/types"
import NodeStateAndDate from "../NodeStateAndDate/NodeStateAndDate"
import { RecipeDetails, RecipeExecutionLog } from "src/types"
import { getEtherNumberAmountFromAddress } from "src/contracts/TokensDecimalsHelpers"
import PriceModalLogs from "../../nodeModals/PriceModalLogs"
import "./SwapModalLog.sass"
import { ModalErrorBoundary } from "src/components/ErrorBoundary/ErrorBoundary"
interface SwapLogModalProps {
  previousData: {
    data: SwapModalData
    id: string
    recipeLogs: RecipeExecutionLog[]
    recipeDetails: RecipeDetails
  }
}

const SwapModalLog = (props: SwapLogModalProps) => {
  const DECIMALS_FOR_TOKEN_AMOUNT: number = 10
  let isNodeExecuted: boolean;
  const [resultAmount, setResultAmount] = useState<string>('')
  let currentRowLog: any
  let showingDate: string
  const { previousData } = props
  const recipeLogs = previousData.recipeLogs
  const nodeId = previousData.id
  const intl = useIntl()
  const inputCoin = previousData?.data.inputCoin || intl.formatMessage({ id: 'swap-modal.connect' })
  const outputCoin = previousData?.data.outputCoin
  const recipeDetails = previousData?.recipeDetails
  const currentEvent = getCurrentEventForModals(recipeLogs, nodeId)

  if (recipeLogs !== null) isNodeExecuted = findNodeInActiveRecipe(recipeLogs, nodeId)
  if (isNodeExecuted) {
    currentRowLog = getCurrentRowLog(recipeLogs, nodeId)
    showingDate = generateShowingTime(currentRowLog)
  }

  const [fee, setFee] = useState(0)

  useEffect(() => {
    if (currentEvent !== undefined) {
      getEtherNumberAmountFromAddress(currentEvent?.output.token, currentEvent?.output?.amount)
        .then((amount) => setResultAmount(Number(amount).toFixed(DECIMALS_FOR_TOKEN_AMOUNT)))
        .catch(err => console.error(err.message))
    }
    getEtherNumberAmountFromAddress(currentEvent?.output.token, currentEvent?.input?.amount.split('.').join('')).then((amount) => setFee(Number(amount))).catch(err => console.error(err.message));
  }, [currentEvent, inputCoin, outputCoin, previousData?.data.inputCoin, previousData?.data.outputCoin])

  return (
    <div className="swap-modal modal-content">
      <div className="modal-wrapper">
        <header className="header-buttons">
          <CloseModal>
            <button>
              <span className="icon icon-close" />
              <span><FormattedMessage id="close" /></span>
            </button>
          </CloseModal>
          <h1><FormattedMessage id="swap-modal.title" /></h1>
          <NodeStateAndDate isNodeExecuted={isNodeExecuted} showingDate={showingDate} recipeDetails={recipeDetails} />
        </header>

        <div className="swap-modal-wrapper">
          <section className="swap-modal-currency">
            <div>
              <h2><FormattedMessage id="swap-modal.amount.from" /></h2>
              <strong className="span-coin">{inputCoin}</strong>
            </div>
            <div>
              <h2 className="right-coin"><FormattedMessage id="swap-modal.amount.to" /></h2>
              <strong className="span-coin right-coin">{outputCoin}</strong>
            </div>
          </section>
          {currentEvent === undefined ?
            <div className="div-received">
              <span className="span-received"><FormattedMessage id="view-logs-waiting" /> {outputCoin}</span>
            </div>
            :
            <div className="div-received">
              <span className="span-received"><FormattedMessage id="view-logs-received" />  {resultAmount} {outputCoin}</span>
            </div>
          }
          <ModalErrorBoundary>
            {isNodeExecuted &&
              <div className="second-column">
                <PriceModalLogs
                  tokenInput={inputCoin}
                  tokenOutput={outputCoin}
                  inputFeeAmount={fee}
                  inputAmount={currentEvent?.extraData?.inputTokenWithPrice?.priceUSD}
                  outputAmount={currentEvent?.extraData?.outputTokenWithPrice?.priceUSD}
                  events={currentEvent} />
              </div>
            }
          </ModalErrorBoundary>
        </div>
      </div>
    </div>
  )
}

registerModal("swap-modal-log", SwapModalLog)
