import React from 'react'
import { useSelector } from 'react-redux'
import { FormattedMessage } from 'react-intl'
import { Link } from 'react-router-dom'
import { Recipe, TemporaryExecutionStatus } from 'src/types'
import { useModal } from '@gluedigital/modal'

import './ButtonsCardMobile.sass'
import '../modals/WithdrawModal/WithdrawModal'

interface ButtonsCardMobileProps {
  recipe: Recipe
  mode?: 'deposit' | 'withdraw' | 'again'
}

const ButtonsCardMobile = ({ recipe, mode = 'deposit' }: ButtonsCardMobileProps) => {
  const temporaryStatusFromMap: TemporaryExecutionStatus = useSelector((s: any) => s.blockers.recipesState.get(recipe?.id))
  const modal = useModal();
  const handleWidthdraw = () => modal.show('mobile-withdraw-modal', { recipe })
  return (

    <div className="buttons-card-mobile">
      <Link to={`/mobile/details/${recipe.id}/log`} className="button button-details">
        <FormattedMessage id="mobile-recipe-card.button.details" />
      </Link>
      {mode !== 'withdraw'
        ? <Link className="button-deposit button" to={`/mobile/deposit/${recipe.id}`}>
            <FormattedMessage id={`mobile-recipe-card.button.${mode}`} />
          </Link>
        : <button disabled={!!temporaryStatusFromMap} onClick={handleWidthdraw} className="button-withdraw">
        <FormattedMessage id="mobile-recipe-card.button.withdraw" />
      </button>
      }
    </div>
  )
}

export default ButtonsCardMobile
