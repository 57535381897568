import providers from 'src/components/modals/SelectWalletModal/providers'

/**
 * Sets the Web3 provider according to the saved session from sessionStorage
 */
export default () => {
  return (next) => (action) => {
    if (action.type === 'loadUserData') {
      // When a session is loaded, set the provider...
      const providerName: string = action.payload?.provider
      if (providers[providerName]) {
        (async () => {
          const providerLoader = providers[providerName]
          const provider = await providerLoader()
          await provider.setup()
        })().catch(() => {})
      }
    }
    return next(action)
  }
}
